import { createSelector } from "@reduxjs/toolkit";
import { ConversationAttribute, MessageInitialState } from "@store/slices/message.slice";

export const MessageState = createSelector((state: { message: MessageInitialState }) => state.message, (result) => result);
export const GetConversations = createSelector(MessageState, (({ conversations }) => {
	return [...conversations].sort((a, b) => {
		return b.lastMessageCreated - a.lastMessageCreated;
	});
}));
export const GetSelectedConversationSid = createSelector(MessageState, ({ selectedConversation }) => selectedConversation)
export const GetSelectedConversation = createSelector([GetConversations, GetSelectedConversationSid], (conversations, selectedSid) => {
	return conversations.find(({ sid }) => sid === selectedSid);
});
export const GetMessages = createSelector(MessageState, ({ messages }) => messages);
export const GetConversationAttributes = createSelector(MessageState, ({ conversationAttributes }) => conversationAttributes);
export const GetParticipants = createSelector(MessageState, ({ conversationAttributes }) => {
	const uniqueParticipants = conversationAttributes.reduce((uniqueParticipants, attr) => {
		const participants = attr.participants.reduce((participants, participant) => {
			const uniqueParticipant = uniqueParticipants.find(uniqueParticipant => uniqueParticipant.identity === participant.identity);
			if (uniqueParticipant) return participants;
			return [...participants, participant];
		}, [] as ConversationAttribute['participants']);

		return [...uniqueParticipants, ...participants];
	}, [] as ConversationAttribute['participants']);

	return uniqueParticipants;
});
export const GetParticipantTyping = createSelector(MessageState, ({ participantTyping }) => participantTyping);
export const GetLastMessages = createSelector(MessageState, ({ lastMessages }) => lastMessages);
export const GetLastMessage = (conversation_sid?: string) =>
	createSelector(GetLastMessages, (lastMessages) => {
		return lastMessages.find((lastMessage) => lastMessage.conversation_sid === conversation_sid)?.message;
	});