import React, { useEffect, useState } from "react";
import "./currentListing.scss";
import Table from "react-bootstrap/Table";
import EditIcon from "@mui/icons-material/Edit";
import {
  CloseListing,
  RefreshCurrentListing,
  currentListingProperty,
  currentListingPropertyExpire,
  deleteCurrentListingProperty,
} from "./server";
import { Hosturl, getToken } from "../../../appVariable/variable";
import Loader from "../Loader/Loader";
import { getuserSubscription } from "../agent-dash-sales/server";
import { Link } from "react-router-dom";
import moment from "moment";
import { SpeedDialAction, Tooltip } from "@mui/material";
import SpeedDial from "@mui/material/SpeedDial";
import IosShareIcon from "@mui/icons-material/IosShare";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import { updateTotalListing } from "../dashboard/DashboardSlice";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import CopyToClipboard from "react-copy-to-clipboard";
import Switch from "@mui/material/Switch";
import { alpha, styled } from "@mui/material/styles";
import { totalListingcount } from "../expiredListing/server";
import { useDispatch } from "react-redux";
import { useEndlessPagination } from "../../../hooks/useEndlessPagination";
import { getTypeOfProperties } from '@components/pages/homepage/components/hero/server';

const PinkSwitch = styled(Switch)(({ theme }) => ({
  ".MuiSwitch-track": {
    backgroundColor: "#315EFB",
    opacity: "1",
    height: "120%",
    transform: "translate(0px, -1px)",
  },
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#315EFB",
    "&:hover": {
      backgroundColor: alpha("#315EFB", theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#315EFB",
    height: "120%",
    transform: "translate(0px, -1px)",
  },
  "& .MuiSwitch-thumb": {
    color: "white",
    border: "1px solid rgba(88, 86, 86, 1)",
  },
}));
const label = { inputProps: { "aria-label": "Switch demo" } };

const CurrentListing = () => {
  const dispatch = useDispatch();
  let currentDate = new Date();
  const [loading, setLoading] = useState(false);
  const [checkCurrentList, setCheckCurrentList] = useState([]);
  const [selectedId, setSelectedId] = useState([]);
  const [viewType, setViewType] = useState(false);
  const [userType, setUserType] = useState(null);
  const [commercialList, setCommercialList] = useState([]);
  const [residentialList, setResidentialList] = useState([]);
  const [commselectedId, setCommselectedId] = useState([]);
  const [ressselectedId, setRessselectedId] = useState([]);
  const [copiedIndex, setCopiedIndex] = useState(null);
  const [currentType, setCurrentType] = useState(0);
  const [types, setTypes] = useState(null);
  const currentURL = window.location.href;
  const url = new URL(currentURL);
  const path = url.pathname;
  const segments = path.split("/"); // Split the path by slashes
  let currentUser = "";
  if (segments.length > 1) {
    currentUser = segments[1];
  }
  const token = getToken();
  const agentSlug = token?.profile.slug;
  const paramObj = agentSlug;
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };
  const { nextRef, previousRef, handleScroll } = useEndlessPagination(
    () => {
      if (nextRef.current) {
        setLoading(true);
        getCurrentListing(nextRef.current);
      }
    }
  );

  const handleSearch = (e) => {
    const initialQuery = e.target.value.toLowerCase();
    getCurrentListing(undefined, initialQuery);
  };
  useEffect(() => {
    setLoading(true);
    getuserSubscription((success) => {
      setUserType(success[1].Subscription === 2);
    });
    !!currentType && getCurrentListing();
  }, [currentType]);

  const getCurrentListing = (url = undefined, query = undefined) => {
    currentListingProperty(
      { currentType, query, virtualOfficeId: null, url }, (success) => {
      setCheckCurrentList(success);
      let ResProperty = [];
      let CommProperty = [];
      nextRef.current = success.next;
      previousRef.current = success.previous;
      success?.results.forEach((resp) => {
        if (resp?.property_listing_type?.id === 1) {
          ResProperty.push(resp);
        }
        if (resp?.property_listing_type?.id === 2) {
          CommProperty.push(resp);
        }
      });
      setResidentialList((prev) => url ? [...prev, ...ResProperty] : ResProperty);
      setCommercialList((prev) => url ? [...prev, ...CommProperty] : CommProperty);
      setLoading(false);
      setRessselectedId([]);
      setCommselectedId([]);
    });
  };

  const handleChecked = (e, index) => {
    const { name, id, checked } = e.target;
    if (name === "selectAll") {
      if (checked) {
        let setallCheck = [];
        for (let list of checkCurrentList) {
          setallCheck = [...setallCheck, list.id];
        }
        setSelectedId(setallCheck);
      } else {
        setSelectedId([]);
      }
    } else {
      let setcheckItems = [];
      if (checked) {
        setcheckItems = [...selectedId, parseInt(id)];
      } else {
        setcheckItems = selectedId.filter((v) => v !== parseInt(id));
      }
      setSelectedId(setcheckItems);
    }

    if (name === "ResselectAll") {
      if (checked) {
        let setallCheck = [];
        for (let list of residentialList) {
          setallCheck = [...setallCheck, list.id];
        }
        setRessselectedId(setallCheck);
      } else {
        setRessselectedId([]);
      }
    }

    if (name === "Resonecheck") {
      let setcheckItems = [];
      if (checked) {
        setcheckItems = [...ressselectedId, parseInt(id)];
      } else {
        setcheckItems = ressselectedId.filter((v) => v !== parseInt(id));
      }
      setRessselectedId(setcheckItems);
    }

    if (name === "CommselectAll") {
      if (checked) {
        let setallCheck = [];
        for (let list of commercialList) {
          setallCheck = [...setallCheck, list.id];
        }
        setCommselectedId(setallCheck);
      } else {
        setCommselectedId([]);
        // console.log([])
      }
    }

    if (name === "Commonecheck") {
      let setcheckItems = [];
      console.log(commselectedId);
      if (checked) {
        setcheckItems = [...commselectedId, parseInt(id)];
      } else {
        setcheckItems = commselectedId.filter((v) => v !== parseInt(id));
      }
      // console.log("ahbax",setcheckItems)
      setCommselectedId(setcheckItems);
    }
  };

  useEffect(() => {
    getTypeOfProperties((success) => {
      setTypes(success);
      setCurrentType(viewType ? success[0].id : success[1].id);
    }).then().catch();
  }, []);

  useEffect(() => {
    if (types) {
      setCurrentType(viewType ? types[0].id : types[1].id);
    }
  }, [viewType]);

  // api call on expire button
  const expireClick = async () => {
    let userDetails = getToken();
    const data = { userid: userDetails.userId };
    // if (userType) {
    if (viewType) {
      // passing ress selcetd id
      data["propertyid"] = ressselectedId;
    } else {
      // passing commertial selcetd id
      data["propertyid"] = commselectedId;
    }
    // } else {
    //   if (commercialList.length > 0) {
    //     // passing commertial selcetd id
    //     data["propertyid"] = commselectedId;
    //   }
    //   if (residentialList.length > 0) {
    //     // passing ress selcetd id
    //     data["propertyid"] = ressselectedId;
    //   }
    // }

    if (data.propertyid?.length > 0) {
      setLoading(true);
      await currentListingPropertyExpire(data, (success) => {
        // console.log(success);
      });
      getCurrentListing();
    }
  };

  const RefreshListing = async () => {
    let userDetails = getToken();
    setLoading(true);
    const data = {
      user: userDetails.userId,
      current_date: moment(currentDate).local().format("YYYY-MM-DD"),
    };
    // if (userType) {
    if (viewType) {
      // passing ress selcetd id
      data["property_id"] = ressselectedId;
    } else {
      // passing commertial selcetd id
      data["property_id"] = commselectedId;
    }
    // } else {
    //   data["property_id"] = commselectedId;
    //   ressselectedId.forEach((ele) => {
    //     data["property_id"].push(ele);
    //   });
    // }
    if (data.property_id?.length > 0) {
      setLoading(true);
      await RefreshCurrentListing(data, (success) => {
        setLoading(false);
      });
    }
    getCurrentListing();
    setLoading(false);
  };

  const closeCurrentListing = async () => {
    let userDetails = getToken();
    const data = { userid: userDetails.userId };
    // if (userType) {
    if (viewType) {
      // passing ress selcetd id
      data["propertyid"] = ressselectedId;
    } else {
      // passing commertial selcetd id
      data["propertyid"] = commselectedId;
    }
    // } else {
    //   if (commercialList.length > 0) {
    //     // passing commertial selcetd id
    //     data["propertyid"] = commselectedId;
    //   }
    //   if (residentialList.length > 0) {
    //     // passing ress selcetd id
    //     data["propertyid"] = ressselectedId;
    //   }
    // }

    if (data.propertyid?.length > 0) {
      setLoading(true);

      await CloseListing(data, (success) => {
        // console.log("success", success);
        if (success.code === 200) {
          totalListingcount(paramObj, (success) => {
            dispatch(updateTotalListing(success.data.data));
          });
          getCurrentListing();
        }
      });
    }
    setLoading(false);
  };

  const deleteCurrentListingClick = async () => {
    let userDetails = getToken();
    const data = { userid: userDetails.userId };
    // if (userType) {
    if (viewType) {
      // passing ress selcetd id
      data["propertyid"] = ressselectedId;
    } else {
      // passing commertial selcetd id
      data["propertyid"] = commselectedId;
    }
    // } else {
    //   if (commercialList.length > 0) {
    //     // passing commertial selcetd id
    //     data["propertyid"] = commselectedId;
    //   }
    //   if (residentialList.length > 0) {
    //     // passing ress selcetd id
    //     data["propertyid"] = ressselectedId;
    //   }
    // }

    if (data.propertyid?.length > 0) {
      setLoading(true);

      await deleteCurrentListingProperty(data, (success) => {
        totalListingcount(paramObj, (success) => {
          dispatch(updateTotalListing(success.data.data));
        });
        // console.log(success);
      });
      // console.log(data)
      getCurrentListing();
    }
  };

  return (
    <>
      {loading ? <Loader /> : null}
      <div className="current_listing_page hide-scrollbar">
        <div className="curren_text pb-4">Current Listing</div>
        <div className="d-flex justify-content-between">
          <div className="center">
            <button className="blue__btn center longer_btN ">
              <div
                className=""
                onClick={() => {
                  RefreshListing();
                }}
              >
                Refresh
              </div>
            </button>
            <button
              className="blue__btn center longer_btN"
              onClick={() => {
                expireClick();
              }}
            >
              <div className="">Expire</div>
            </button>
            <button
              className="blue__btn center longer_btN"
              onClick={() => {
                closeCurrentListing();
              }}
            >
              <div className="">Close</div>
            </button>
            <button
              className="red__btn center remove_btn me-4"
              onClick={() => {
                deleteCurrentListingClick();
              }}
            >
              <div className=""> Delete</div>
            </button>
          </div>

          <div className=" d-flex align-items-center gap-3">
            <div className="quik-map center gap-3">
              <p>Commercial Listing</p>
              <div className="pt-0">
                <PinkSwitch
                  {...label}
                  onChange={(e) => {
                    setViewType(e.target.checked);
                  }}
                  checked={viewType}
                  style={{ color: "#315EFB" }}
                />
              </div>
              <p>Residential Listing</p>
            </div>

            <div className="listing-filter">
              <input
                type="text"
                name=""
                id=""
                placeholder="ex: 5000"
                onChange={(e) => handleSearch(e)}
              />
            </div>
          </div>
        </div>
        <div className="scrollS groupView">
          {viewType ? (
            <Table className="table-borderless ">
              <thead>
                <tr>
                  <th className="checkTh">
                    <input
                      type="checkbox"
                      name="ResselectAll"
                      value=""
                      id="all"
                      onChange={(e) => handleChecked(e, 1)}
                      // checked={ressselectedId.length === residentialList.length}
                      checked={
                        ressselectedId.length
                          ? ressselectedId.length === residentialList.length
                          : false
                      }
                    />
                  </th>
                  <th>Address</th>
                  <th>Town </th>
                  <th>Bedroom </th>
                  <th>Bathroom</th>
                  {/* <th>Type</th> */}
                  {/* <th>Property Type</th> */}
                  <th>Price</th>
                  <th>Category</th>
                  <th>Contract</th>
                  <th>List Date</th>
                  <th>Expire Date</th>
                  <th>Image</th>
                  <th>Share</th>
                  <th>Edit</th>
                </tr>
              </thead>

              <tbody
                className="hide-scrollbar pt-1"
                onScroll={handleScroll}
              >
                {residentialList.length > 0
                  ? residentialList
                      .map((data, i) => {
                        return (
                          <tr className="align-middle table_row_border" key={i}>
                            <td className=" checkTh">
                              <input
                                type="checkbox"
                                name="Resonecheck"
                                value=""
                                className="mt-2"
                                id={data.id}
                                onChange={(e) => handleChecked(e, data.id)}
                                checked={ressselectedId.includes(data.id)}
                              />
                            </td>
                            <td className="address_width">
                              {data.property_address_1}
                            </td>
                            <td className="table_body_text">
                              {data.property_city?.city_name}
                            </td>
                            <td className="table_body_text">{data.Bedrooms}</td>
                            <td className="table_body_text">
                              {data.Bathrooms}
                            </td>

                            <td className="table_body_text">
                              {data.property_listing_amount.toLocaleString(
                                "en-US"
                              )}
                            </td>
                            <td className="table_body_text">
                              {data.propertylisting_type?.user_listing_type}
                            </td>
                            <td className="table_body_text">
                              {data.is_property_exclusive
                                ? "Exclusive"
                                : "Open"}
                            </td>
                            <td className="table_body_text">
                              {data.start_date
                                ? new Date(
                                    data.start_date
                                      .toLocaleString()
                                      ?.toString()
                                  ).toLocaleDateString("en-US", options)
                                : "-"}
                            </td>

                            <td className="table_body_text">
                              {data.end_date
                                ? new Date(
                                    data.end_date?.toLocaleString()?.toString()
                                  ).toLocaleDateString("en-US", options)
                                : "-"}
                            </td>
                            {/* <td className='table_body_text'>{data.created_date}</td> */}
                            <td>
                              <img
                                src={`${data.property_main_image}`}
                                alt=""
                              />
                            </td>
                            <td className=" d-flex align-items-center gap-3">
                              <div className="copy-link pointer">
                                <CopyToClipboard
                                  text={`${Hosturl}property/${data.slug}`}
                                  onCopy={() => {
                                    setCopiedIndex(i);
                                  }}
                                >
                                  <Tooltip
                                    title={
                                      copiedIndex === i ? "Copied" : "Copy"
                                    }
                                    placement="top"
                                  >
                                    <InsertLinkIcon />
                                  </Tooltip>
                                </CopyToClipboard>
                              </div>

                              <SpeedDial
                                className="share-proprty-btn"
                                direction="right"
                                ariaLabel="SpeedDial basic example"
                                icon={<IosShareIcon />}
                              >
                                <SpeedDialAction
                                  icon={
                                    <LinkedinShareButton
                                      url={`${Hosturl}property/${data.slug}`}
                                      title="Afisado"
                                      summary=""
                                      source="Afisado.com"
                                    >
                                      <LinkedinIcon size={30} round={true} />
                                    </LinkedinShareButton>
                                  }
                                  tooltipTitle={`Linkedin`}
                                />
                                <SpeedDialAction
                                  icon={
                                    <WhatsappShareButton
                                      url={`${Hosturl}property/${data.slug}`}
                                      title="Afisado"
                                      summary=""
                                      source="Afisado.com"
                                    >
                                      <WhatsappIcon size={30} round={true} />
                                    </WhatsappShareButton>
                                  }
                                  tooltipTitle={`Whatsapp`}
                                />
                                <SpeedDialAction
                                  icon={
                                    <TwitterShareButton
                                      url={`${Hosturl}property/${data.slug}`}
                                      title="Afisado"
                                      summary=""
                                      source="Afisado.com"
                                    >
                                      {" "}
                                      <TwitterIcon size={30} round={true} />
                                    </TwitterShareButton>
                                  }
                                  tooltipTitle={`Twitter`}
                                />
                                <SpeedDialAction
                                  icon={
                                    <FacebookShareButton
                                      url={`${Hosturl}property/${data.slug}`}
                                      title="Afisado"
                                      summary=""
                                      source="Afisado.com"
                                    >
                                      {" "}
                                      <FacebookIcon size={30} round={true} />
                                    </FacebookShareButton>
                                  }
                                  tooltipTitle={`Facebook`}
                                />
                              </SpeedDial>
                            </td>

                            <td>
                              <Link
                                to={`/${currentUser}/edit-property/${data.slug}`}
                              >
                                <EditIcon className="edit-listing" />
                              </Link>
                            </td>
                          </tr>
                        );
                      })
                  : null}
              </tbody>
            </Table>
          ) : (
            <div>
              <Table className="table-borderless ">
                <thead>
                  <tr>
                    <th className=" checkTh">
                      <input
                        type="checkbox"
                        name="CommselectAll"
                        value=""
                        id="all"
                        onChange={(e) => handleChecked(e, 1)}
                        checked={
                          commselectedId.length
                            ? commselectedId.length === commercialList.length
                            : false
                        }
                      />
                    </th>
                    <th>Address</th>
                    <th>Town</th>
                    {/* <th>Unit</th>
                      <th>Rooms</th> */}
                    <th>Type</th>
                    <th>Sub-Type</th>
                    {/* <th>Property Type</th> */}
                    <th>Price</th>
                    <th>Category</th>
                    <th>Contract</th>
                    <th>List Date</th>
                    <th>Expire Date</th>
                    <th>Image</th>
                    <th>Share</th>
                    <th>Edit</th>
                  </tr>
                </thead>

                <tbody className="hide-scrollbar pt-1">
                  {commercialList.length > 0
                    ? commercialList
                        .map((data, i) => {
                          return (
                            <tr
                              className="align-middle table_row_border"
                              key={i}
                            >
                              <td className=" checkTh">
                                <input
                                  type="checkbox"
                                  name="Commonecheck"
                                  value=""
                                  className="mt-2"
                                  id={data.id}
                                  onChange={(e) => handleChecked(e, data.id)}
                                  checked={commselectedId.includes(data.id)}
                                />
                              </td>
                              <td className="address_width">
                                {data.property_address_1}
                              </td>
                              <td className="table_body_text">
                                {data.property_city?.city_name}
                              </td>
                              {/* <td className="table_body_text">
                                  {data.Units}
                                </td>
                                <td className="table_body_text">
                                  {data.Rooms}
                                </td> */}
                              <td className="table_body_text">
                                {data.property_main_category.Main_category}
                              </td>
                              <td className="table_body_text"></td>
                              <td className="table_body_text">
                                {data.property_listing_amount.toLocaleString(
                                  "en-US"
                                )}
                              </td>
                              <td className="table_body_text">
                                {data.propertylisting_type?.user_listing_type}
                              </td>
                              <td className="table_body_text">
                                {data.is_property_exclusive
                                  ? "Exclusive"
                                  : "Open"}
                              </td>

                              <td className="table_body_text">
                                {data.start_date
                                  ? new Date(
                                      data.start_date
                                        .toLocaleString()
                                        ?.toString()
                                    ).toLocaleDateString("en-US", options)
                                  : "-"}
                              </td>
                              <td className="table_body_text">
                                {data.end_date
                                  ? new Date(
                                      data.end_date?.toLocaleString()?.toString()
                                    ).toLocaleDateString("en-US", options)
                                  : "-"}
                              </td>
                              <td>
                                <img
                                  src={`${data.property_main_image}`}
                                  alt=""
                                />
                              </td>
                              <td className=" d-flex align-items-center gap-3">
                                <div className="copy-link pointer">
                                  <CopyToClipboard
                                    text={`${Hosturl}property/${data.slug}`}
                                    onCopy={() => {
                                      setCopiedIndex(i);
                                    }}
                                  >
                                    <Tooltip
                                      title={
                                        copiedIndex === i ? "Copied" : "Copy"
                                      }
                                      placement="top"
                                    >
                                      <InsertLinkIcon />
                                    </Tooltip>
                                  </CopyToClipboard>
                                </div>

                                <SpeedDial
                                  className="share-proprty-btn"
                                  direction="right"
                                  ariaLabel="SpeedDial basic example"
                                  icon={<IosShareIcon />}
                                >
                                  <SpeedDialAction
                                    icon={
                                      <LinkedinShareButton
                                        url={`${Hosturl}property/${data.slug}`}
                                        title="Afisado"
                                        summary=""
                                        source="Afisado.com"
                                      >
                                        <LinkedinIcon size={30} round={true} />
                                      </LinkedinShareButton>
                                    }
                                    tooltipTitle={`Linkedin`}
                                  />
                                  <SpeedDialAction
                                    icon={
                                      <WhatsappShareButton
                                        url={`${Hosturl}property/${data.slug}`}
                                        title="Afisado"
                                        summary=""
                                        source="Afisado.com"
                                      >
                                        {" "}
                                        <WhatsappIcon size={30} round={true} />
                                      </WhatsappShareButton>
                                    }
                                    tooltipTitle={`Whatsapp`}
                                  />
                                  <SpeedDialAction
                                    icon={
                                      <TwitterShareButton
                                        url={`${Hosturl}property/${data.slug}`}
                                        title="Afisado"
                                        summary=""
                                        source="Afisado.com"
                                      >
                                        {" "}
                                        <TwitterIcon size={30} round={true} />
                                      </TwitterShareButton>
                                    }
                                    tooltipTitle={`Twitter`}
                                  />
                                  <SpeedDialAction
                                    icon={
                                      <FacebookShareButton
                                        url={`${Hosturl}property/${data.slug}`}
                                        title="Afisado"
                                        summary=""
                                        source="Afisado.com"
                                      >
                                        {" "}
                                        <FacebookIcon size={30} round={true} />
                                      </FacebookShareButton>
                                    }
                                    tooltipTitle={`Facebook`}
                                  />
                                </SpeedDial>
                              </td>
                              <td>
                                <Link
                                  to={`/${currentUser}/edit-property/${data.slug}`}
                                >
                                  <EditIcon className="edit-listing" />
                                </Link>
                              </td>
                            </tr>
                          );
                        })
                    : null}
                </tbody>
              </Table>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CurrentListing;
