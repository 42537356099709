import { FC, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetMessages, GetSelectedConversation } from "../../../../store/selectors/message.selector";
import { prependMessages, setMessagesAndLastReadMessageIndex } from "../../../../store/slices/message.slice";
import { transformTwilioMessage } from "../../../../store/transformers/message.transformer";
import { ParticipantTyping } from "@components/pages/Message/components/ParticipantTyping";
import { useParticipantTyping } from "@components/pages/Message/hooks/useParticipantTyping";
import { useTwilioConversation } from "@components/pages/Message/hooks/useTwilioConversation";
import { useMessageGroupList } from "@components/pages/Message/hooks/useMessageGroupList";
import { Chip } from "@mui/material";

type ActiveChatBodyProps = {}

export const ActiveChatBody: FC<ActiveChatBodyProps> = () => {
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(false);
	const selectedConversation = useSelector(GetSelectedConversation);
	const conversation = useTwilioConversation(selectedConversation);
	const messages = useSelector(GetMessages);
	const containerRef = useRef<HTMLDivElement>(null);
	const hasPrevPage = useRef(true);
	const oldScrollTopPosition = useRef<number | null>(null);
	const participantTyping = useParticipantTyping(conversation!);
	const groupList = useMessageGroupList(messages);

	useEffect(() => {
		setIsLoading(true);
		conversation?.setAllMessagesRead();
		conversation?.getMessages()
			.then(({ items }) => {
				dispatch(
					setMessagesAndLastReadMessageIndex(
						items.map(transformTwilioMessage)
					)
				)
			})
			.finally(() => setIsLoading(false));
	}, [conversation]);

	useEffect(() => {
		if (!containerRef.current) return;
		let { scrollHeight } = containerRef.current;
		if (oldScrollTopPosition.current) {
			scrollHeight = scrollHeight - oldScrollTopPosition.current;
			oldScrollTopPosition.current = null;
		}
		containerRef.current.scrollTop = scrollHeight;
	}, [participantTyping, groupList, isLoading]);

	const handleScroll = () => {
		if ((messages[0]?.index || 0) - 1 < 0) return;
		const { scrollTop, scrollHeight } = containerRef.current!;
		if (scrollTop === 0 && hasPrevPage.current) {
			oldScrollTopPosition.current = scrollHeight;
			conversation?.getMessages(undefined, messages[0].index - 1).then((res) => {
				hasPrevPage.current = res.hasPrevPage;
				dispatch(
					prependMessages(
						res.items.map(transformTwilioMessage)
					)
				)
			});
		}
	}

	return (
		<div
			className="chat-main-box d-flex flex-column hide-scrollbar"
			onScroll={handleScroll}
			ref={containerRef}
		>
			{
				isLoading
					? (
						<>
							<Chip
								label={'Loading...'}
								size="small"
								sx={{ maxWidth: '100%', width: 'max-content', margin: '0 auto' }}
							/>
						</>
					)
					: (
						<>
							{ groupList }
							<ParticipantTyping participant={participantTyping} />
						</>
					)
			}
		</div>
	)
}