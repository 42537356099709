import { useSelector } from "react-redux";
import { Avatar } from "@mui/material";
import { stringToColor } from "./utils";
import {
  GetPersonalFullName,
  GetPersonalInitials,
  GetPersonalProfileImage
} from "../../../store/selectors";
import { MouseEventHandler, useMemo, FC } from "react";

type ProfileAvatarProps = {
  width?: number;
  height?: number;
  className?: string;
  sx?: Record<string, any>;
  onClick?: MouseEventHandler<HTMLDivElement>;
  profileInstance?: {
    fullName: string;
    image?: string;
    initials: string;
  }
}


export const ProfileAvatar: FC<ProfileAvatarProps> = ({ width = 55, height = 55, sx= {}, onClick, className = '', profileInstance }) => {
  const profileFullName = useSelector(GetPersonalFullName);
  const profileInitials = useSelector(GetPersonalInitials);
  const profileImage = useSelector(GetPersonalProfileImage);

  const avatar = useMemo(() => {
    if (profileInstance) return profileInstance;
    return {
      fullName: profileFullName,
      image: profileImage,
      initials: profileInitials,
    }
  }, [profileInstance, profileImage, profileInitials, profileFullName]);

  return (
    <Avatar
      className={className}
      sx={{
        bgcolor: stringToColor(avatar.fullName),
        width,
        height,
        ...sx,
      }}
      alt={avatar.fullName}
      src={avatar.image}
      onClick={onClick}
    >
      {avatar.initials}
    </Avatar>
  );
}