import {
  api,
} from '@REST/api';

import * as effects from 'redux-saga/effects';
import { salesPersonActions } from '@bus/salesPerson/actions';
import { PayloadActionWithPromiseMeta } from '@core/FinalForm/typedefs';
import { getToken } from '../../../../appVariable/variable';
import { SavePersonActionPayload } from '@bus/salesPerson/typedefs';

export function* savePerson({
 payload,
 meta: { resolve, reject },
}: PayloadActionWithPromiseMeta<SavePersonActionPayload>){
  try {
    const token = getToken();
    yield effects.put(salesPersonActions.startFetching());
    const body: string = yield effects.call(JSON.stringify, { ...payload, user_id: token.userId });
    const response: Response = yield effects.apply(api, api.post, [
      { endpoint: `api/user/SalespersonSavedbyGuest/`, body },
    ]);

    if (!response.ok) {
      reject();
      throw new Error('Unhandled error');
    }

    resolve();

    yield effects.put(salesPersonActions.addSalesPerson({ ids: payload.sales_person_id }));
    yield effects.put(salesPersonActions.fetchSavedAgents());
  } catch (e) {
  } finally {
    yield effects.put(salesPersonActions.stopFetching());
  }
}
