import ListingCard from "./Listing-card/ListingCard";
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import Box from '@mui/material/Box';
import { IconButton } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const CardSlider = ({ heading, listing }) => {

  return (
    <div className="card-slider-box d-flex flex-column gap-2">
      <p className="listing-subheading">{heading}</p>
      <Box position={'relative'}>
        <Swiper
          modules={[Navigation]}
          spaceBetween={47}
          slidesPerView={'auto'}
          navigation={{
            nextEl: `.suggestion-slider-next-${heading}`,
            prevEl: `.suggestion-slider-prev-${heading}`,
          }}>
          {listing.map((list, i) => (
            <SwiperSlide key={`cdc${i}`} style={{width: '190px'}}>
              <ListingCard data={list} />
            </SwiperSlide>
          ))}
        </Swiper>
        <IconButton
          sx={{ position: 'absolute', left: '-42px', top: '50%', transform: 'translateY(-50%)', borderRadius: '50%', border: '1px solid rgb(33, 37, 41)', width: '30px', height: '30px' }}
          className={`suggestion-slider-control suggestion-slider-prev-${heading}`}>
          <ChevronLeftIcon />
        </IconButton>
        <IconButton
          sx={{ position: 'absolute', right: '-42px', top: '50%', transform: 'translateY(-50%)', borderRadius: '50%', border: '1px solid rgb(33, 37, 41)', width: '30px', height: '30px' }}
          className={`suggestion-slider-control suggestion-slider-next-${heading}`}>
          <ChevronRightIcon />
        </IconButton>
      </Box>
    </div>
  );
};

export default CardSlider;
