import documentSlice from './slice';
import { createAction } from '@reduxjs/toolkit';
import {
  changeShowStatus,
  deleteSelectedDocuments, fetchCustomers,
  setDefaultDocument,
  shareDocuments,
} from '@bus/document/saga/workers';
import { getEndpointParams } from '@helpers/formating';
import { loadMoreDocuments } from '@bus/document/saga/workers/loadMoreDocuments';

export const documentActions = {
  ...documentSlice.actions,
  fetchDocuments: createAction('document/fetchDocuments',
    (values) => {
      return {
        payload: {
          query: getEndpointParams(
            values,
            new URLSearchParams(),
          ),
        },
      };
    }),
  fetchDocument: createAction('document/fetchDocument',
    ({ id }) => ({
      payload: { id },
    }),
  ),
  setDefaultDocument: createAction('document/setDefaultDocument',
    ({ ids, status }) => ({
      payload: { ids, status },
    }),
  ),
  deleteSelectedDocuments: createAction('document/deleteSelectedDocuments',
    ({ ids }) => ({
      payload: { ids },
    }),
  ),
  changeShowStatus: createAction('document/changeShowStatus',
    ({ ids, show }) => ({
      payload: { ids, show },
    }),
  ),
  shareDocuments: createAction('document/shareDocuments',
    ({ ids, share_emails }) => ({
      payload: { ids, share_emails },
    }),
  ),
  loadMoreDocuments: createAction('document/loadMoreDocuments',
    ({ url }) => ({
      payload: { url },
    }),
  ),
  fetchCustomers: createAction('document/fetchCustomers'),
};
