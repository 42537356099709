import { Theme } from '@mui/material';

export const styles = {
  timePicker: (theme: Theme) => ({
    width: '100%',
    '& .MuiFormLabel-root': {
      transform: 'translate(10px, 10px) scale(1)',
      fontSize: '12px',
      '&.Mui-focused': {
        color: 'rgba(0, 0, 0, 0.6)',
        transform: 'translate(-70px, 10px) scale(1)',
      },
      '&.MuiFormLabel-filled': {
        color: 'rgba(0, 0, 0, 0.6)',
        transform: 'translate(-70px, 10px) scale(1)',
      },
    },
    '& .MuiInputBase-root': {
      '& input': {
        padding: '4px 10px',
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
      borderRadius: 0,
    },
  }),
};
