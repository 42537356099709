import React, { useEffect, useRef, useState } from 'react';
// import { Autocomplete, TextField } from '@mui/material';
import "./marketingForm.scss";
import * as Yup from "yup";
import { Button, Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
// import { getLocations } from "../../../../../components/pages/homepage/components/hero/server";
import { Hosturl, getToken } from '../../../../../appVariable/variable';
import { AgentMarketingUrl, BoostMarkrtingForm } from '../../server';
import { toast } from 'react-toastify';
import Loader from '../../../Loader/Loader';
import CustumeFileInput from '../../../agent-dash-sales/components/custumeFileInput/CustumeFileInput';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

const MarketingForm = (props) => {
    let { id } = useParams();
    let token = getToken()
    let agentSlug = token?.profile.slug;
    let userId = token?.userId;
    const mainImage = useRef();
    const autocompleteRef = useRef();
    const Navigate = useNavigate()
    const [marketingUrls, setMarketingUrls] = useState([])
    // const [podcastLocationSearch, setPodcasrLocationSearch] = useState("");
    // const [optionsList, setOptionsList] = useState([]);
    const [loading, setLoading] = useState(false)
    const regex = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;

    // formik handler
    const formik = useFormik({
        initialValues: {
            campaignName: "",
            startDate: '',
            endDate: '',
            bioPage: '',
            Message: "",
            location: "",
            // location: {},
            category: "",
            type: "",
            address: "",
            creativeFile: null,
        },
        validationSchema: Yup.object({
            bioPage: Yup.string().matches(regex, "Link should be a valid URL").required("Enter Link here").nullable(),
            address: Yup.string().required("Enter Address").nullable(),
            // listingType: Yup.string().required("*required"),
            // Title: Yup.string().required("*required"),
            // creativeFile: Yup.mixed().required("Upload Image").nullable(),
            startDate: Yup.date().test('four-digits', 'Invalid Date', function (value) {
                if (!value) {
                    return true; // Let Yup handle empty values
                }
                const year = new Date(value).getFullYear();
                return year.toString().length === 4;
            }).required("Select Start Date"),

            endDate: Yup.date().min(
                Yup.ref('startDate'), "End date can't be before Start date").test('four-digits', 'Invalid Date', function (value) {
                    if (!value) {
                        return true; // Let Yup handle empty values
                    }
                    const year = new Date(value).getFullYear();
                    return year.toString().length === 4;
                }).required("Select End date"),
        }),
        onSubmit: async (values, action) => {
            let formData = new FormData();
            formData.append("user", userId);
            formData.append("boostbarkitingplan", id);
            formData.append("CampaignName", values.campaignName);
            formData.append("start_date", moment(values.startDate).format("YYYY-MM-DD"));
            formData.append("end_date", moment(values.endDate).format("YYYY-MM-DD"));
            formData.append("url", values.bioPage);
            // formData.append("area", values.location.value?.area_id);
            // formData.append("city", values.location.value?.city_id);
            // formData.append("StateMaster", values.location.value?.state_id);
            // formData.append("CountryMaster", values.location.value?.country_id);
            formData.append("Image", values.creativeFile);
            formData.append("message", values.Message);
            formData.append("address", values.address);
            // formData.append("keyword", values.);
            // console.table([...formData])
            setLoading(true)
            await BoostMarkrtingForm(formData, (success) => {
                if (success.data.code === 200 && success.data.status === "success") {
                    toast.success("Campaign Created Successfully", "success")
                    setLoading(false)
                }
                if (success.data.code === 400 && success.data.status === "failed") {
                    toast.error("Please try Again", "error")
                    setLoading(false)
                }
                action.resetForm();
                formik.resetForm();
                mainImage.current.value = '';
                autocompleteRef.current.value = '';
                // autocompleteRef.current.reset();
            });
        },
    });

    function handleCreative(e) {
        let fileSize = e.target.files[0].size;
        if (fileSize <= 5250000) {
            const file = e.target.files[0];
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                formik.setFieldValue("creativeFile", file);
            };
        } else {
        }
    }

    let propertyUrl = () => {
        AgentMarketingUrl(success => {
            setMarketingUrls(success)
        })
    }

    useEffect(() => {
        propertyUrl()
    }, [])


    // useEffect(() => {
    //     let timerOut = setTimeout(() => {
    //         getLocations(podcastLocationSearch, (success) => {
    //             let newarr = success.area.map((item) => {
    //                 return {
    //                     label: `${item.area_name +
    //                         ", " +
    //                         item.city_master.city_name +
    //                         ", " +
    //                         item.city_master.state_master.state_name +
    //                         ", " +
    //                         item.city_master.state_master.country_master.country_name
    //                         }`,
    //                     value: {
    //                         area_id: item.id,
    //                         city_id: item.city_master.id,
    //                         state_id: item.city_master.state_master.id,
    //                         country_id: item.city_master.state_master.country_master.id,
    //                     },
    //                 };
    //             });
    //             let newarr1 = success.city.map((item) => {
    //                 return {
    //                     label: `${item.city_name +
    //                         "," +
    //                         item.state_master.state_name +
    //                         "," +
    //                         item.state_master.country_master.country_name
    //                         }`,
    //                     value: {
    //                         city_id: item.id,
    //                         state_id: item.state_master.id,
    //                         country_id: item.state_master.country_master.id,
    //                     },
    //                 };
    //             });
    //             let newarr2 = success.state.map((item) => {
    //                 return {
    //                     label: `${item.state_name + "," + item.country_master.country_name}`,
    //                     value: {
    //                         state_id: item.id,
    //                         country_id: item.country_master.id,
    //                     },
    //                 };
    //             });
    //             setOptionsList([...newarr, ...newarr1, ...newarr2]);
    //         });
    //     }, 1000)
    //     return () => clearTimeout(timerOut)
    // }, [podcastLocationSearch])

    return (
        <>
            {loading ? <Loader /> : null}
            <div className='marketing-top-sec d-flex flex-column'>
                {props.backbtn &&
                    <div className="table-btn  text-end">
                        <Button variant="outline-primary" className='long-btn'
                            onClick={() => Navigate(-1)}
                        >Back</Button>
                    </div>
                }
                <div className='sms_marketing_form'>
                    <div className='card_border pad_cards'>
                        <h6 className='pb-2'>Campaign Name</h6>

                        <Row className="input_section">
                            <Col sm={12} className="marketing-row">
                                <div className="d-flex main_input ">
                                    <div className="input_name grow-1   input_namepad">
                                        Enter Campaign Name
                                    </div>
                                    <div className="input-sec">
                                        <input type="text" className="form-control border-0 pb-0"
                                            name="campaignName"
                                            onChange={formik.handleChange}
                                            value={formik.values.campaignName}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row className="input_section">
                            <p className=' mb-3'>Schedule</p>

                            {/* <Col sm={6} className='marketing-row'>
                                <div className="d-flex main_input ">
                                    <div className="input_name grow-1   input_namepad">
                                        End Date<span className="red-star" >*</span>
                                    </div>
                                    <div className="input-sec">
                                        <input type="date" className="form-control border-0 pb-0"
                                            name="endDate"
                                            onChange={formik.handleChange}
                                            value={formik.values.endDate}
                                        />
                                    </div>

                                </div>
                                {formik.errors.endDate && formik.touched.endDate ? (
                                    <div className='validation-text'>{formik.errors.endDate}</div>
                                ) : null}
                            </Col> */}

                            <Col sm={6} className="marketing-row">
                                <div className="d-flex main_input ">
                                    <div className="input_name grow-1   input_namepad">
                                        Start Date<span className="red-star" >*</span>
                                    </div>
                                    <div className="input-sec">
                                        <DatePicker showIcon dateFormat="MM/dd/yyyy"
                                            minDate={new Date()}
                                            selected={formik.values.startDate}
                                            onChange={(date) => { formik.setFieldValue("startDate", date) }}
                                            placeholderText="MM/DD/YYYY"
                                        />
                                    </div>
                                </div>
                                {formik.errors.startDate && formik.touched.startDate ? (
                                    <div className='validation-text'>{formik.errors.startDate}</div>
                                ) : null}
                            </Col>

                            <Col sm={6} className='marketing-row'>
                                <div className="d-flex main_input ">
                                    <div className="input_name grow-1   input_namepad">
                                        End Date<span className="red-star" >*</span>
                                    </div>
                                    <div className="input-sec">
                                        <DatePicker showIcon dateFormat="MM/dd/yyyy"
                                            minDate={new Date()}
                                            selected={formik.values.endDate}
                                            onChange={(date) => { formik.setFieldValue("endDate", date) }}
                                            placeholderText="MM/DD/YYYY"
                                        />

                                    </div>
                                </div>
                                {formik.errors.endDate && formik.touched.endDate ? (
                                    <div className='validation-text'>{formik.errors.endDate}</div>
                                ) : null}
                            </Col>

                            <Col sm={12} className='marketing-row'>
                                <div className="d-flex main_input ">
                                    <div className="input_name grow-1   input_namepad">
                                        Select a Listing Or Bio Page<span className="red-star" >*</span>
                                    </div>
                                    <div className="input-sec">
                                        <select aria-label="Default select example" className=" ps-2 w-100"
                                            onChange={(e) => { formik.setFieldValue("bioPage", `${Hosturl}${e.target.value}`); }}
                                        >
                                            <option className=" d-none">Select Page</option>
                                            <option className="" value={`agentprofile/${agentSlug}`}>My Public Website</option>
                                            {marketingUrls?.map((url) =>
                                                <option key={`offers${url.id}`} value={`property/${url.slug}`}
                                                >{url.slug}</option>
                                            )}
                                        </select>
                                    </div>

                                </div>
                                {formik.errors.bioPage && formik.touched.bioPage ? (
                                    <div className='validation-text'>{formik.errors.bioPage}</div>
                                ) : null}

                            </Col>

                            <Col sm={12} className='marketing-row'>
                                <div className="d-flex main_input ">
                                    <div className="input_name grow-1   input_namepad">
                                        Location
                                    </div>
                                    <div className="input-sec">
                                        <input type="text" className="form-control border-0 pb-0" placeholder='Type in any town or city location locally or globally you would like to target your campaign'
                                            name='address' value={formik.values.address} onChange={formik.handleChange}
                                        />
                                        {/* auto location  */}
                                        {/* <Autocomplete className='hide-downBtn  w-100 area-autocomplete d-flex ps-2'
                                            // disablePortal
                                            disableClearable={true}
                                            // id="combo-box-demo"
                                            options={optionsList}
                                            onChange={(event, value) => {
                                                console.log("value", value)
                                                formik.setFieldValue("location", value)
                                            }

                                            }
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            sx={{ width: 1000 }}
                                            renderInput={(params) => (
                                                <TextField
                                                    ref={autocompleteRef}

                                                    {...params}
                                                    onChange={(e) => {
                                                        // handlefindlocation(e);
                                                        setPodcasrLocationSearch(e.target.value)
                                                        // console.log("podcastSearch", e.target.value);
                                                    }}
                                                    variant="standard"
                                                />
                                            )}
                                        /> */}
                                    </div>
                                </div>
                            </Col>

                            <Col sm={12} className="pb-2 mb-1 marketing-row">
                                <span className='upload_text'>Upload Creative</span>
                            </Col>

                            <Col sm={12} className='marketing-row'>
                                <div className="d-flex flex-column file-input_sec "  >
                                    {/* <input type="file" name="myImage" id="Firstimg" className='img-upload'
                                        title="Choose a video please"
                                        ref={mainImage}
                                        multiple accept="image/*"
                                        onChange={(e) => {
                                            handleCreative(e)
                                        }}
                                    /> */}

                                    <CustumeFileInput
                                        ref={mainImage}
                                        id="Creative"
                                        multiple={true}
                                        accept="image/*"
                                        onChange={(e) => { handleCreative(e) }}
                                    />
                                </div>
                            </Col>

                            <Col sm={12} className="text_area marketing-row">
                                <div className="d-flex main_input mb-3">
                                    <div className="input_name grow-1   input_namepad">
                                        Message
                                    </div>
                                    <div className="input-sec ps-3">
                                        <textarea id="" cols="40" className="form-control border-0 p-0 "
                                            name="Message"
                                            onChange={formik.handleChange}
                                            value={formik.values.Message}
                                        ></textarea>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <div className=" d-flex justify-content-end marketing-form-btn">
                            <Button variant="primary" className='center' onClick={formik.handleSubmit}>Start Now</Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MarketingForm
