import { useRef } from "react";

export const useEndlessPagination = (fetchCallback = () => {}) => {
  const nextRef = useRef(null);
  const previousRef = useRef(null);

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;

    const bottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;

    if (bottom) {
      fetchCallback();
    }
  }

  return {
    nextRef,
    previousRef,
    handleScroll,
  }
}