import React, { useState } from 'react';
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { IconButton, Box, Typography } from '@mui/material';
import "../documents.scss";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { Modal } from "react-bootstrap";
import { changeDocTitle } from '../server';
import { toast } from "react-toastify";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Formik } from 'formik';
import { styles } from './styles';
import { useDispatch } from 'react-redux';
import { documentActions } from '@bus/document/actions';

const DocumentItem = ({ document, handleCheck, isChecked, showActions = true }) => {
  const [title, setTitle] = useState(document.filename);
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const handleShow = () => {
    dispatch(documentActions.changeShowStatus({
      ids: [document.id],
      show: !document.shared,
    }));
  };

  const handleDelete = () => {
    dispatch(documentActions.deleteSelectedDocuments({ ids: [document.id] }));
    toast.success('Deleted Successfully');
  };
  const handleChangeStatus = () => {
    dispatch(documentActions.setDefaultDocument({
      ids: [document.id],
      status: !document.is_default,
    }));
  };

  return (
    <div
      className="d-flex flex-column"
      style={{ margin: "auto", marginTop: "0.5rem", paddingTop: "0.2rem" }}
    >
      <div>
        <Box sx={styles.root}>
          <Box display={'flex'} alignItems={'center'} gap={'10px'}>
            <input
              type={'checkbox'}
              name={'docs'}
              value={document.id}
              id={document.id}
              onChange={handleCheck}
              checked={isChecked}
            />
            <Typography>{document.filename}</Typography>
          </Box>
          {
            showActions
              ? (
                <Box sx={styles.actionWrapper}>
                  <IconButton onClick={handleChangeStatus}>
                    {document.is_default ? (
                      <CheckCircleIcon />
                    ) : (
                      <UnpublishedIcon />
                    )}
                  </IconButton>
                  <IconButton onClick={handleShow}>
                    {document.shared ? (
                      <RemoveRedEyeIcon />
                    ) : (
                      <VisibilityOffIcon />
                    )}
                  </IconButton>
                  <IconButton onClick={() => setShowModal(true)}>
                    <DriveFileRenameOutlineIcon />
                  </IconButton>
                  <IconButton onClick={handleDelete}>
                    <DeleteForeverIcon />
                  </IconButton>
                </Box>
              )
              : null
          }
        </Box>
        <div>
          <Modal
            className="dashboard-filter-popup"
            size="lg"
            centered
            show={showModal}
            onHide={() => setShowModal(false)}
            aria-labelledby="contained-modal-title-vcenter"
          >
            <Modal.Header className=" pb-0" closeButton>
              {" "}
            </Modal.Header>
            <Modal.Body>
              <Formik>
                <div className="dashborad-filter-body d-flex flex-column gap-4">
                  <div className="price-sec  filter-row ">
                    <div className="d-flex flex-row"></div>
                  </div>
                  <div className="price-sec  filter-row ">
                    <div className="d-flex justify-content-between">
                      <div className="validation-text text-capitalize">
                        Change Document Title
                      </div>
                    </div>
                    <div className="price-input-box justify-content-between">
                      <div
                        className="enter-input inner-shadow center flex-grow-1"
                        style={{ maxWidth: "none" }}
                      >
                        <input
                          type="textarea"
                          id=""
                          placeholder="Write Here..."
                          name="filtername"
                          onChange={(e) => {
                            setTitle(e.target.value);
                          }}
                          value={title}
                        />
                      </div>
                      <div
                        className="d-flex justify-content-end "
                        style={{ marginTop: "12px" }}
                      >
                      </div>
                    </div>
                  </div>

                  <div className="filter-btn-grp d-flex gap-4 justify-content-end">
                    <button
                      className="btn"
                      style={{ width: "135px", borderRadius: "20px" }}
                      onClick={() => setShowModal(false)}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn"
                      style={{ width: "135px", borderRadius: "20px" }}
                      onClick={() => {
                        changeDocTitle(document.id, title, (success) => {
                          try {
                            if (success.code === 200) {
                              dispatch(documentActions.updateDocumentTitle({
                                id: document.id,
                                title,
                              }));
                              setShowModal(false);
                              toast.success("Successfully updated title");
                            }
                          } catch (error) {
                            toast.success("There is an error");
                          }
                        });
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </Formik>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default DocumentItem;
