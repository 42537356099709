import { getToken } from "../../../appVariable/variable";
import axiosInstance from '../../../REST/axiosInstance';


export function LandloardProfileSetting(formData, success) {
    let token = getToken()
    axiosInstance.put(`api/user/LandloardProfileSetting/`, formData,
        { headers: { 'Authorization': `Bearer ${token?.idToken}` } })
        .then((response) => {
            if (response.data.code === 200 && response.data.status === "success") {
                success(response.data.data);
            }
        })
        .catch((error) => {
            // console.log(error);
        });
}


export const getAgentLandloardProfile = async (paramObj) => {
    try {
        let token = getToken()
        const response = await axiosInstance.get(`api/user/LandloardProfileSetting/${Boolean(paramObj) ? paramObj : `?id=${token?.userId}`}`, { headers: { 'Authorization': `Bearer ${token?.idToken}` } });
        return response.data.data;
    } catch (error) {
        throw error;
        console.error('Error fetching zip codes:', error);
    }
}



export const Allzipcode = async () => {
    try {
        let token = getToken()
        const response = await axiosInstance.get(`api/master/zipcode/`, { headers: { 'Authorization': `Bearer ${token?.idToken}` } });
        return response.data.data;
    } catch (error) {
        throw error;
        console.error('Error fetching zip codes:', error);
    }

};

export const AllStates = async () => {
    try {
        let token = getToken()
        const response = await axiosInstance.get(`api/master/state/`, { headers: { 'Authorization': `Bearer ${token?.idToken}` } });
        return response.data.data;
    } catch (error) {
        console.error('Error fetching zip codes:', error);
        throw error;
    }
};

export const AllCity = async () => {
    try {
        let token = getToken()
        const response = await axiosInstance.get(`api/master/city/`, { headers: { 'Authorization': `Bearer ${token?.idToken}` } });
        return response.data.data;
    } catch (error) {
        console.error('Error fetching zip codes:', error);
        throw error;
    }
};