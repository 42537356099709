import { combineReducers } from '@reduxjs/toolkit';
import searchReducer from "./../components/pages/homepage/components/hero/HeroSlice";
import PropertydetailSlice from "../components/pages/dashboard/propperty-datails/PropertySlice";
import currentListingDetailSlice from "../components/pages/agentbio/AgentBioSlice";
import PersonalInfoSlice from "../components/pages/profile/components/personal/PersonalinfoSlice";
import FilterDataSlice from "../components/pages/homepage/components/hero/FilterSlice";
import FilterOptionSlice from "../components/pages/homepage/components/hero/FilterOptionSlice";
import virtualOfficeSlice from "../components/pages/agentVirtualOffice/AgentOfficeSlice";
import DashboardSlice from "../components/pages/dashboard/DashboardSlice";
import GuestVirtualOfficeSlice from "../components/pages/guestVirtualOffice/GuestVirtualOfficeSlice";
import GuestVirtualOfficeDocsignSlice from "../components/pages/guestVirtualOffice/GuestVirtualOfficeDocsignSlice";
import search2Slice from '../bus/search2/slice';
import uiSlice from '../bus/ui/slice';
import dashboardSlice from '../bus/dashboard/slice';
import landingSlice from '../bus/landing/slice';
import authSlice from '../bus/auth/slice';
import advertiseSlice from '../bus/advertise/slice';
import documentSlice from '../bus/document/slice';
import neighborhoodSlice from '../bus/neighborhood/slice';
import salesPersonSlice from '../bus/salesPerson/slice';
import { MessageSlice } from "./slices/message.slice";

export const rootReducer = combineReducers({
  [MessageSlice.name]: MessageSlice.reducer,
  search: searchReducer,
  propertyDetailInStore: PropertydetailSlice,
  currentListingDetailSlice: currentListingDetailSlice,
  PersonalInfoInStore: PersonalInfoSlice,
  FilterDataInStore: FilterDataSlice,
  FilterOptionsInStore: FilterOptionSlice,
  virtualOfficeInStore: virtualOfficeSlice,
  DashboardSliceInStore: DashboardSlice,
  GuestVirtualOfficeSliceInStore: GuestVirtualOfficeSlice,
  GuestVirtualOfficeDocsignInStore: GuestVirtualOfficeDocsignSlice,
  search2: search2Slice.reducer,
  ui: uiSlice.reducer,
  dashboard: dashboardSlice.reducer,
  landing: landingSlice.reducer,
  auth: authSlice.reducer,
  advertise: advertiseSlice.reducer,
  document: documentSlice.reducer,
  neighborhood: neighborhoodSlice.reducer,
  salesPerson: salesPersonSlice.reducer,
});