import { Col, Row } from "react-bootstrap";
import icons from "../dashboard/components/sidebar/images/SidebarIcon";

const CustumerCard = (props) => {
  return (
    <div className="card_border border border-primary justify-content-center px-2 py-3 pointer">
      <Row className=" mb-4 text-center justify-content-center">
        <Col md="6">
          {" "}
          <img
            src={
              props?.teamMember?.user_image?.profile_image != null
                ? props?.teamMember?.user_image?.profile_image
                : icons.profile
            }
            alt=""
          />
        </Col>
      </Row>
      <div className="text_content">
        <div className="d-flex justify-content-between ">
          <div className="label">
            <p>Name</p>
            <p>:</p>
          </div>
          <div className="info">
            <p>
              {props?.teamMember?.custom_obj?.name
                ? props?.teamMember?.custom_obj?.name
                : "----"}
            </p>
          </div>
        </div>
        <div className="d-flex justify-content-between ">
          <div className="label">
            <p>Address</p>
            <p>:</p>
          </div>
          <div className="info">
            <p>
              {props?.teamMember?.custom_obj?.current_address
                ? props?.teamMember?.custom_obj?.current_address
                : "----"}
            </p>
          </div>
        </div>
        <div className="d-flex justify-content-between ">
          <div className="label">
            <p>Contact</p>
            <p>:</p>
          </div>
          <div className="info">
            <p>
              {props?.teamMember?.custom_obj?.contact_no_1
                ? props?.teamMember?.custom_obj?.contact_no_1
                : "----"}{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustumerCard;
