import { createSelector } from 'reselect';

const neighborhoodSelector = (state: any) => state.neighborhood;

export const getIsFetched = createSelector([neighborhoodSelector], ({ isFetched }) => {
  return isFetched;
});

export const getIsCitiesFetched = createSelector([neighborhoodSelector], ({ isCitiesFetched }) => {
  return isCitiesFetched;
});

export const getCities = createSelector([neighborhoodSelector], ({ cities }) => {
  return cities;
});

export const getNeighborhoodTowns = createSelector([neighborhoodSelector], ({ neighborhoodTowns }) => {
  return neighborhoodTowns;
});
