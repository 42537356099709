import { useMemo } from 'react'
import "./navbar.scss"
// import logo from "./image/logosvg.svg"
import logo from "./image/bardlogos.svg"
import { useNavigate } from "react-router";
import { getToken } from "../../../../../appVariable/variable"
import { Box, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import { useState } from 'react';
import { removeUserDetails } from '../../../../../REST/api';
import { authActions } from '@bus/auth/actions';
import { useDispatch } from 'react-redux';
import { getInitials, ProfileAvatar } from "@components/shared/profile-avatar";
export { logo }
// const settings = [{ title: 'Dashboard', url: 'Dashboard' },];

const Navbar = (props) => {
    const dispatch = useDispatch();
    let userlogin = getToken()
    const navigate = useNavigate();
    const [anchorElUser, setAnchorElUser] = useState(null);
    const profileInstance = useMemo(() => {
        const fullName = `${userlogin?.profile.first_name} ${userlogin?.profile.last_name}`.trim();
        return {
            fullName,
            initials: getInitials(fullName),
            image: userlogin?.profile.profile_image
        }
    }, [userlogin]);

    const settings = [
        { title: userlogin?.user_info.username, url: '' },
        // userlogin?.user_info.agent_profile && { title: 'Dashboard', url: 'Dashboard' },
        { title: 'Dashboard', url: 'Dashboard' },
        { title: 'My Profile', url: 'profileSettings' },
    ];
    function userLogout() {
        dispatch(authActions.isAuthenticatedFalse());
        removeUserDetails();
        localStorage.removeItem("add_on");
        localStorage.removeItem("sidebar");
        localStorage.removeItem("residentailCityId");
        localStorage.removeItem("FilterSearchSchema");
        navigate("/");
        props.setUpdatehero(!props.updatehero)
    }

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };
    const handleCloseUserMenu = (url) => {
        setAnchorElUser(null);
        if (url === 'Dashboard') {
            switch (userlogin.user_info.usertypeobj) {
                case 0: {
                    break;
                }
                case 1: {
                    navigate("/dashboard");
                    break;
                }
                case 2: {
                    navigate("/agent-dashboard/");
                    break;
                }
                case 3: {
                    navigate("/landLoadDashboard/add-property");
                    break;
                }
                default: ;

            }
        }
        if (url === 'profileSettings') {
            switch (userlogin.user_info.usertypeobj) {
                case 0: {
                    break;
                }
                case 1: {
                    navigate("/dashboard/profile-setting");
                    break;
                }
                case 2: {
                    navigate("/agent-dashboard/profile-settings");
                    break;
                }
                case 3: {
                    break;
                }
                default: ;

            }
        }

    };
    return (
        <>
            <div className="navbar w-100 d-flex justify-content-lg-between span-to-top">
                <div className="logo pointer" onClick={() => { navigate('/') }}><img src={logo} alt="" /></div>
                <div className=' d-flex gap-4 center'>
                    {userlogin?.idToken ?
                        <div className="sign-in"><button variant="primary"
                            onClick={() => { userLogout(); navigate('/') }}
                        >Logout</button></div>
                        :
                        <div className="sign-in"><button variant="primary"
                            onClick={() => navigate('/login')}
                        >Sign in / Register</button></div>
                    }

                    {userlogin &&
                        <Box sx={{ flexGrow: 0 }}>
                            <Tooltip title="My Profile">
                                <ProfileAvatar
                                  className='pointer'
                                  profileInstance={profileInstance}
                                  width={40}
                                  height={40}
                                  onClick={handleOpenUserMenu}
                                />
                            </Tooltip>
                            <Menu sx={{ mt: '45px' }} id="menu-appbar"
                                anchorEl={anchorElUser} keepMounted
                                anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
                                transformOrigin={{ vertical: 'top', horizontal: 'right', }}
                                open={Boolean(anchorElUser)} onClose={handleCloseUserMenu}
                            >
                                {settings.map((setting, index) => (
                                    <MenuItem key={`profileMenu${index}`} onClick={() => { handleCloseUserMenu(setting.url) }}>
                                        <Typography textAlign="center">{setting.title}</Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>}
                </div>
            </div>
        </>
    )
}

export default Navbar
