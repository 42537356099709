import { ProfileAvatar } from "@components/shared/profile-avatar";
import { FC, ReactNode } from "react";
import { useParticipantAttributes } from "@components/pages/Message/hooks/useParticipant";
import { format } from "date-fns";

export type MsgIncomingProps = {
  author: string;
  message: string | ReactNode;
  date: Date;
  attributes: Record<string, any>;
  showProfileAvatar?: boolean;
  showDate?: boolean;
}

const MsgIncoming: FC<MsgIncomingProps> = (props) => {
  const attribute = useParticipantAttributes(props.author);
  const { showProfileAvatar = true, showDate = true } = props;

  return (
    <>
      <div className="incoming-msg d-flex ">
        <div className="chat-profile" style={{ opacity: showProfileAvatar ? 1 : 0 }}>
          <ProfileAvatar width={50} height={50} profileInstance={attribute} />
        </div>
        <div className="msg-text position-relative">
          <p>{props.message}</p>
          {
            showDate
              ? (
                <div className="msg-time position-absolute start-0">
                  <p>{ format(props.date, 'hh.mm a') }</p>
                </div>
              )
              : null
          }
        </div>
      </div>
    </>
  )
}

export default MsgIncoming
