import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { styles } from './styles';
import { Box } from '@mui/material';
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import {
  ShowFullDocument
} from '@components/pages/neighbourhoodspecialist/component/NeighborhoodSpecialist/componants/ShowFullDocument';

type UploadFileProps = {
  document: string | File | null;
  name: string;
  addDocument: (file: File) => void;
  itemId: number;
};

export const UploadFile: React.FC<UploadFileProps> = ({ document, name, addDocument, itemId }) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [imageUrl, setImageUrl] = useState<string>('');
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (document instanceof File) {
      const fileUrl = URL.createObjectURL(document);
      setImageUrl(fileUrl);

      return () => {
        URL.revokeObjectURL(fileUrl);
      };
    } else if (typeof document === 'string') {
      setImageUrl(document);
    } else {
      setImageUrl('');
    }
  }, [document]);

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const selectedFiles = Array.from(e.target.files);
      addDocument(selectedFiles[0]);

      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleOpen = () => {
    setShow(true);
  };

  return (
    <Box sx={styles.file}>
      {document ? (
        <Box sx={styles.imageWrapper} onClick={handleOpen}>
          <Box width={'50px'} height={'50px'} component={'img'} alt={''} src={imageUrl} />
        </Box>
      ) : (
        <>
          <input
            ref={fileInputRef}
            accept={'image/*'}
            id={`upload-${name}-photo-${itemId}`}
            name={name}
            type={'file'}
            style={{ display: 'none' }}
            onChange={handleImageChange}
            data-testid={'change-user-information-photo'}
          />
          <label htmlFor={`upload-${name}-photo-${itemId}`} className={'pointer'}>
            <Box sx={styles.iconWrapper}>
              <SaveAltOutlinedIcon fontSize={'medium'} htmlColor={'#000'} />
            </Box>
          </label>
        </>
      )}
      <ShowFullDocument show={show} handleClose={handleClose} url={imageUrl} />
    </Box>
  );
};
