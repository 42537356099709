import { toast } from "react-toastify";
import {updateFilterData} from "./FilterSlice";
import {updateFilterOptions} from "./FilterOptionSlice";
import axiosInstance from '../../../../../REST/axiosInstance';

export async function getTypeOfProperties(success) {
  await axiosInstance
    .get(`api/property/propertylistingtypeview/`)
    .then((resp) => {
      if (resp.data.code === 200 && resp.data.status === "success") {
        success(resp.data.data);
      }
    })
    .catch((err) => {
      console.log(err.message);
    });
}

export function getLocations(val, success) {
  let tempObj = {};
  tempObj["location"] = val;
  axiosInstance
    .post(`api/master/searchforhome/`, tempObj)
    .then((resp) => {
      if (resp.data.code === 200 && resp.data.status === "success") {
        success(resp.data.data);
      } else {
        success(resp.data.message);
      }
    })
    .catch((error) => {
      console.log(error.message);
    });
}
export function getCategories(id, success) {
  let tempObj = {};
  tempObj["Property_listing_type_id"] = id;
  axiosInstance
    .post(`api/property/propertylisting_typeview/`, tempObj)
    .then((resp) => {
      if (resp.data.code === 200 && resp.data.status === "success") {
        success(resp.data.data);
      }
    })
    .catch((err) => {
      console.log(err);
    });
}
export function getTypesBasedOnCategory(id, success) {
  let tempObj = {};
  tempObj["Property_listing_type_id"] = id;
  axiosInstance
    .post(
      `api/property/propertymaicategorytypehomeview/`,
      tempObj
    )
    .then((resp) => {
      if (resp.data.code === 200 && resp.data.status === "success") {
        success(resp.data.data);
      }
    })
    .catch((err) => {
      console.log(err);
    });
}
export function getTypesBasedOnTypeLeisure(id, success, dispatch) {
  let tempObj = {};
  tempObj["Property_main_category_id"] = id;
  axiosInstance
    .post(
      `api/property/propertysubcategorytypehomeview/`,
      tempObj
    )
    .then((resp) => {
      success(resp);
    })
    .catch((err) => {
      console.log(1);
      dispatch(updateFilterData({ Sub_category: null }));
      dispatch(updateFilterOptions({ SubTypeOptions: [] }));
      console.log(err.response.status);
    });
}
export function saveSearch(tempObj, success) {
  axiosInstance
    .post(`api/property/filtersavesearch/`, tempObj)
    .then((resp) => {
      if (resp.data.code === 200 && resp.data.status === "success") {
        success(resp.data.data);
      }
    })
    .catch((err) => {});
}

export function ApplySearch(tempObj, success) {
  axiosInstance
    .post(`api/property/search/`, tempObj)
    .then((resp) => {
      success(resp.data.results);
    })
    .catch((err) => {
      toast.error("No Property Availble for this search");
      console.log(err);
    });
}
