import { createSelector } from 'reselect';

const uiSelector = (state: any) => state.ui;

export const getIsLandingPageScrolled = createSelector([uiSelector], ({isLandingPageScrolled}) => {
  return isLandingPageScrolled;
});
export const getIsSidebarOpen = createSelector([uiSelector], ({isSidebarOpen}) => {
  return isSidebarOpen;
});
export const getNeighborhoodTownLimit = createSelector([uiSelector], ({neighborhoodTownLimit}) => {
  return neighborhoodTownLimit;
});
