import * as effects from 'redux-saga/effects';

import { sendAdvertiseRequest } from './workers';
import { advertiseActions } from '@bus/advertise/actions';

function* watchSendAdvertiseRequest() {
  yield effects.takeEvery(advertiseActions.sendAdvertiseRequest.type, sendAdvertiseRequest);
}
export function* watchAdvertise() {
  yield effects.all([
    effects.call(watchSendAdvertiseRequest),
  ]);
}
