import * as React from 'react';
import { Field, FormRenderProps } from 'react-final-form';

import { Box, Button } from '@mui/material';

import { schema } from './schema';
import { styles } from './styles';
import { RenderCategoryField } from './fields/RenderCategoryField';
import { RenderAirRightsField } from './fields/RenderAirRightsField';
import { RenderAmenitiesField } from './fields/RenderAmenitiesField';
import { RenderBathroomsField } from './fields/RenderBathroomsField';
import { RenderBedroomsField } from './fields/RenderBedroomsField';
import { RenderCapRateMinField } from './fields/RenderCapRateMinField';
import { RenderCapRateMaxField } from './fields/RenderCapRateMaxField';
import { RenderClassTypeField } from './fields/RenderClassTypeField';
import { RenderFeeField } from './fields/RenderFeeField';
import { RenderLeaseTypeField } from './fields/RenderLeaseTypeField';
import { RenderOccupancyMinField } from './fields/RenderOccupancyMinField';
import { RenderOccupancyMaxField } from './fields/RenderOccupancyMaxField';
import { RenderPriceMinField } from './fields/RenderPriceMinField';
import { RenderPriceMaxField } from './fields/RenderPriceMaxField';
import { RenderPropertyConditionField } from './fields/RenderPropertyConditionField';
import { RenderRoomField } from './fields/RenderRoomField';
import { RenderSqftMinField } from './fields/RenderSqftMinField';
import { RenderSqftMaxField } from './fields/RenderSqftMaxField';
import { RenderSubCategoryField } from './fields/RenderSubCategoryField';
import { RenderTypeField } from './fields/RenderTypeField';
import { RenderUnitsField } from './fields/RenderUnitsField';
import { RenderMonthlyCostField } from './fields/RenderMonthlyCostField';
import { getToken } from '../../../appVariable/variable';
import { showSaveSearchPopup } from '@components/pages/homepage/components/hero/HeroSlice';
import { useDispatch } from 'react-redux';

const PopUpFilterForm = (props: FormRenderProps) => {
  const token = getToken();
  const userId = token?.userId;
  const dispatch = useDispatch();

  const clearSearch = () => {
    props.form.restart();
  };
  const saveSearchPopComm = () => {
    const { category, property_listing, type } = props.values;

    if (!category || !property_listing || !type) {
      props.form.blur('category');
      props.form.blur('type');
      return;
    }
    dispatch(showSaveSearchPopup(true));
  };

  const formatNumber = (value: any) => {
    if (typeof value === 'number') {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return value;
  };

  const parseNumber = (value: any) => {
    if (typeof value === 'string' && value.trim() !== '') {
      const numberValue = Number(value.replace(/,/g, ''));
      return isNaN(numberValue) ? null : numberValue;
    }
    return null;
  };

  return (
    <form noValidate onSubmit={props.handleSubmit}>
      <Box width={'100%'} display={'flex'} flexDirection={'column'}>
        <div className="dashborad-filter-body d-flex flex-column gap-4">

          <Field
            name={`category`}
            component={RenderCategoryField}
            parse={(value) => {
              return +value
            }} />

          <Field
            name={`type`}
            component={RenderTypeField}
            parse={(value) => {
              return +value
            }} />

          {props.values.property_listing === 2 && (
            <Field name={`Sub_category`} component={RenderSubCategoryField}/>
          )}

          {(props.values.category === 1 || props.values.category === 3) && (
            <Field
              name={`fee`}
              component={RenderFeeField}
              parse={(value) => {
                return +value
              }} />
          )}

          <div className="unit-qty d-flex gap-3 justify-content-between">
            {props.values.property_listing === 1 && (
              <Field
                name={`Bedrooms`}
                component={RenderBedroomsField}
                parse={(value) => {
                  return +value
                }} />
            )}

            {props.values.property_listing === 1 && (
              <Field
                name={`Bathrooms`}
                component={RenderBathroomsField}
                parse={(value) => {
                  return +value
                }}/>
            )}

            {props.values.property_listing === 2 && (
              <Field
                name={`units`}
                component={RenderUnitsField}
                parse={(value) => {
                  return +value
                }}/>
            )}

            {props.values.property_listing === 2 && (
              <Field
                name={`room`}
                component={RenderRoomField}
                parse={(value) => {
                  return +value
                }}/>
            )}

            <div className="unit-box filter-row">
              <p className="filter-heading">Sqft</p>
              <div className="d-flex gap-4">
                <Field
                  name={`Squft_min`}
                  component={RenderSqftMinField}
                  parse={(value) => {
                    return +value
                  }} />
                <Field
                  name={`Squft_max`}
                  component={RenderSqftMaxField}
                  parse={(value) => {
                    return +value
                  }} />
              </div>
            </div>
          </div>

          <div className="price-sec  filter-row ">
            <p className="filter-heading">Price</p>
            {(props.values.category === 3 || props.values.category === 4) && (
              <Field
                name={`cost_type`}
                component={RenderMonthlyCostField}
                parse={(value) => {
                  return +value
                }} />
            )}

            <div className="price-input-box d-flex justify-content-between">
              <Field
                name={`pricemin`}
                component={RenderPriceMinField}
                format={formatNumber}
                parse={parseNumber} />
              <Field
                name={`pricemax`}
                component={RenderPriceMaxField}
                format={formatNumber}
                parse={parseNumber} />
            </div>
          </div>

          {props.values.category === 4 && (
            <div className="unit-qty d-flex gap-3 justify-content-between">
              <div className="unit-box filter-row">
                <p className="filter-heading">Cap Rate</p>
                <div className="d-flex gap-4">
                  <Field
                    name={`capRate_min`}
                    component={RenderCapRateMinField}
                    parse={(value) => {
                      return +value
                    }} />
                  <Field
                    name={`capRate_max`}
                    component={RenderCapRateMaxField}
                    parse={(value) => {
                      return +value
                    }} />
                </div>
              </div>
              <div className="unit-box filter-row">
                <p className="filter-heading">Occupancy</p>
                <div className="d-flex gap-4">
                  <Field
                    name={`occupancy_min`}
                    component={RenderOccupancyMinField}
                    parse={(value) => {
                      return +value
                    }} />
                  <Field
                    name={`occupancy_max`}
                    component={RenderOccupancyMaxField}
                    parse={(value) => {
                      return +value
                    }} />
                </div>
              </div>
            </div>
          )}

          {props.values.property_listing === 2 && (
            <>
              <Field
                name={`lease_type`}
                component={RenderLeaseTypeField}
                parse={(value) => {
                  return +value
                }}/>
              <div className={'select-property-type filter-row'}>
                <div className="d-flex gap-3 justify-content-between">
                  <p className="filter-heading">Property Condition & Class</p>
                </div>
                <div className="filter-radio d-flex gap-3">
                  <Field
                    name={`property_condition`}
                    component={RenderPropertyConditionField}
                    parse={(value) => {
                      return +value
                    }}/>
                  <Field
                    name={`class_type`}
                    component={RenderClassTypeField}
                    parse={(value) => {
                      return +value
                    }}/>
                </div>
              </div>
              <div className={'select-property-type filter-row'}>
                <div className="d-flex gap-3 justify-content-between">
                  <p className="filter-heading">Available Air Rights</p>
                </div>
                <Field type={'checkbox'} name={`Available_Air_Rights`} component={RenderAirRightsField}/>
              </div>
            </>
          )}

          {props.values.property_listing === 1 && (
            <Field name={`Amenities_filter`} component={RenderAmenitiesField}/>
          )}

          <div className="filter-btn-grp d-flex gap-4 justify-content-end">
            {userId ? (
              <Button sx={styles.button} onClick={clearSearch}>
                Clear Search
              </Button>
            ) : null}
            {userId ? (
              <Button sx={styles.button} onClick={saveSearchPopComm}>
                Save Search
              </Button>
            ) : null}
            <Button sx={styles.button} type={'submit'} role={'submit'}>
              Apply
            </Button>
          </div>
        </div>
      </Box>
    </form>
  );
};

export default PopUpFilterForm;

export { schema, PopUpFilterForm };
