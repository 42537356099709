import React, { createElement, FC, useMemo } from "react";
import { getToken } from "../../../../appVariable/variable";
import MsgIncoming, {MsgIncomingProps} from "@components/pages/Message/components/MsgIncoming";
import MsgOutgoing, {MsgOutgoingProps} from "@components/pages/Message/components/MsgOutgoing";
import { Message } from "../../../../store/slices/message.slice";
import { EmptyMessagePropertyCard, MessagePropertyCard } from "@components/pages/Message/components/MessagePropertyCard";
import { getInitials } from "@components/shared/profile-avatar";
import { usePropertyDetails } from "@components/pages/Message/hooks/usePropertyDetails";
import { UserDocument } from "@components/pages/Message/components/DocumentsPicker";
import { MessageFile } from "@components/pages/Message/components/MessageFile";
import { Box } from "@mui/material";

type MessageBoxProps = {
	message: Message;
}

export const MessageBox: FC<MessageBoxProps> = ({ message }) => {
	const propertyDetails = message.attributes?.propertyDetails || {};
	const media: UserDocument[] = message.attributes?.media || [];
	const token = getToken();
	const details = usePropertyDetails(message.attributes);

	const previewBody = useMemo(() => {
		const noDetails = !Object.keys(propertyDetails).length;
		if (noDetails) return null;
		if (!details) return <EmptyMessagePropertyCard />;

		const fullName = `${details.user_profile.first_name || ''} ${details.user_profile.last_name || ''}`.trim();
		const image = details.user_profile.profile_image || undefined;

		return (
			<MessagePropertyCard
				slug={details.slug}
				mainImage={details.property_main_image}
				listingAmount={details.property_listing_amount}
				city={details.property_city?.city_name}
				address={details.property_address_1}
				bedrooms={
					details.property_listing_type?.property_listing_name === "Residential"
						? details.Bedrooms
						: null
				}
				profileInstance={{
					fullName,
					initials: getInitials(fullName),
					image
				}}
			/>
		)
	}, [propertyDetails, details]);

	const isOutgoing = message.author === token.user_info.identity;
	const messageType = isOutgoing ? MsgOutgoing : MsgIncoming;
	const messageProps = {
		attributes: message.attributes,
		date: message.dateCreated!,
		author: message.author || '',
	}

	return (
		<>
			<Box sx={{ marginLeft: isOutgoing ? 'auto' : undefined, padding: '0 62px' }}>{ previewBody }</Box>
			{
				media.map((doc) => createElement(
					messageType as FC<MsgOutgoingProps | MsgIncomingProps>,
					{
						...messageProps,
						key: doc.id,
						message: <MessageFile doc={doc} isIncoming={!isOutgoing} />,
						showProfileAvatar: false,
						showDate: false,
					})
				)
			}
			{
				createElement(messageType as FC<MsgOutgoingProps|MsgIncomingProps>, {
					...messageProps,
					message: message.body,
				})
			}
		</>
	)
}