import { FC } from "react";
import { Form, Modal, Table } from 'react-bootstrap';
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { IconButton } from "@mui/material";

type ChatModalProps = {
	show: boolean;
	onClick: () => void;
}

const deleteicon = require("../image/delete.svg") as string;
const tdata = [1, 2, 3]

export const ChatModal: FC<ChatModalProps> = ({ show, onClick }) => {
	return (
		<div className="chat-modal">
			<Modal
				className='chat-modal-main'
				show={show}
				onHide={onClick}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header className='chat-modal-header p-0 ps-3' closeButton>
					<p >Invite Family & Friends</p>
				</Modal.Header>
				<Modal.Body>
					<div className='chat-modal-body'>
						<div>
							<div className='forgotmodal_body d-flex gap-5 '>
								<div className="d-flex main_input flex-grow-1 align-items-center">
									<div className="input_name grow-1 text-end input_namepad w-auto">
										Phone Number<span className="red-star">*</span>
									</div>
									<div className="input-sec">
										<Form.Select aria-label="Default select example">
											<option> </option>
											<option value="1">9988776655</option>
											<option value="2">9988776655</option>
											<option value="3">9988776655</option>
										</Form.Select>
									</div>
								</div>
								<div className="text-end mb-3">
									<button type="submit" className="submit_btn">Invite</button>
								</div>
							</div>
							<div className="chat-table">
								<Table>
									<thead>
										<tr>
											<th>Phone Number</th>
											<th className='text-center'>Invitation Request</th>
											<th>Account Permission</th>
											<th className='text-center'>Remove</th>
										</tr>
									</thead>
									<tbody>
										{
											tdata.map((t) =>
												<tr key={`tablerow${t}`}>
													<td>9988776655</td>
													{/* <td className=' text-center '><CheckCircleIcon style={{ color:'green' }}/>  </td> */}
													<td className=' text-center '><WarningAmberIcon style={{ color: '#4E62B1' }} />  </td>
													<td>
														<Form.Select aria-label="Is Decision Maker">
															<option>Is Decision Maker</option>
															<option value="1">One</option>
															<option value="2">Two</option>
															<option value="3">Three</option>
														</Form.Select>
													</td>
													<td className=' text-center pointer'>
														<IconButton>
															<img src={deleteicon} />
														</IconButton>
													</td>
												</tr>
											)
										}
									</tbody>
								</Table>
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</div>
	)
}