import Button from "react-bootstrap/Button";
import "./listingCard.scss";
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { cleanFilterData, updateFilterData } from '../../../hero/FilterSlice';

const ListingCard = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userInfo = JSON.parse(localStorage.getItem('locationDetails'));

  const handleClick = () => {
    const isCategory = props.data.title.toLowerCase() !== 'no fee' &&
      props.data.title.toLowerCase() !== 'just listed';
    dispatch(cleanFilterData());
    dispatch(
      updateFilterData({
        fee: props.data.title.toLowerCase() !== 'no fee',
        property_listing: props.data.property_listing_type,
        category: isCategory ? props.data.listing_type : null,
        type: typeof props.data.id === 'number' ? props.data.id : null,
        pricemin: props.data.min_price ? props.data.min_price : null,
        just_listed: props.data.title.toLowerCase() === 'just listed',
        nearest_cities: true,
        latitude: userInfo?.latitude,
        longitude: userInfo?.longitude,
        pet_friendly: props.data.title.toLowerCase() === 'pet friendly',
      })
    );
    navigate('dashboard/quik-view');
  };

  return (
    <div className="listing-Card d-flex flex-column justify-content-center gap-2 align-items-center">
      <div className="card-img">
        <img src={props.data.img} alt="" />
      </div>
      <p className="card-title text-uppercase">{props.data.display_title ?? props.data.title}</p>
      <Button variant="outline-primary" onClick={handleClick}>
        <b>{props.data.data}</b> Properties
      </Button>
    </div>
  );
};

export default ListingCard;
