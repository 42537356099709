import { createSlice } from "@reduxjs/toolkit";

export type MessageInitialState = {
	selectedConversation: string | null;
	conversations: Conversation[];
	conversationAttributes: ConversationAttribute[];
	messages: Message[];
	participantTyping: ParticipantTyping[];
	lastMessages: { conversation_sid: string, message: Message }[];
}
export type Conversation = {
	sid: string;
	lastMessageCreated: number;
	lastReadMessageIndex: number;
	participants: ConversationAttribute['participants'];
}
export type Message = {
	index: number;
	sid: string;
	body: string | null;
	author: string | null;
	dateCreated: Date | null;
	dateUpdated: Date | null;
	conversation_sid: string;
	attributes: Record<string, any>;
}
export type ConversationAttribute = {
	conversation_sid: string;
	participants: { identity: string; profile_image: string | null; first_name: string; last_name: string }[]
}
export type ParticipantTyping = {
	conversation_sid: string;
	identity: string;
}

const initialState: MessageInitialState = {
	selectedConversation: null,
	conversations: [],
	conversationAttributes: [],
	messages: [],
	participantTyping: [],
	lastMessages: [],
}

export const MessageSlice = createSlice({
	name: 'message',
	initialState,
	reducers: {
		setConversations: (state, { payload }: { payload: Conversation[] }) => {
			state.conversations = payload;
		},
		setConversationsAndAttributes: (
			state,
			{ payload } : {
				payload: { conversations: Conversation[], attributes: ConversationAttribute[] }
			}) => {
			state.conversationAttributes = payload.attributes;
			state.conversations = payload.conversations;
		},
		setSelectedConversation: (state, { payload }: { payload: string }) => {
			state.selectedConversation = payload;
		},
		setMessagesAndLastReadMessageIndex: (state, { payload }: { payload: Message[] }) => {
			state.messages = payload;
			const [lastMessage] = [...payload].reverse();
			if (lastMessage) {
				state.conversations = state.conversations.map((conversation) =>
					conversation.sid === lastMessage.conversation_sid
						? { ...conversation, lastReadMessageIndex: lastMessage.index }
						: conversation
				)
			}
		},
		prependMessages: (state, { payload }: {payload: Message[]}) => {
			state.messages = [...payload, ...state.messages];
		},
		addOrUpdateConversation: (state, { payload }: { payload: Conversation }) => {
			let conversations = state.conversations;
			const conversationIndex = conversations.findIndex(({ sid }) => sid === payload.sid);

			// add the conversation to conversations if it is new
			if (conversationIndex < 0) {
				conversations = [...conversations, payload];
			} else {
				conversations[conversationIndex] = payload;
			}

			state.conversations = [...conversations];
		},
		setConversationAttributes: (state, { payload }: { payload: ConversationAttribute[] }) => {
			state.conversationAttributes = payload;
		},
		updateConversationAttribute: (state, { payload }: { payload: ConversationAttribute }) => {
			let conversationAttributes = state.conversationAttributes;

			if (conversationAttributes.findIndex(({ conversation_sid }) => conversation_sid === payload.conversation_sid) < 0) {
				conversationAttributes = [...conversationAttributes, payload];
			}

			state.conversationAttributes = conversationAttributes.map((attribute) => attribute.conversation_sid === payload.conversation_sid ? payload : attribute);
		},
		setParticipantTyping: (state, { payload }: { payload: ParticipantTyping }) => {
			const alreadyTyping = state.participantTyping.find(({ conversation_sid, identity }) => {
				return conversation_sid === payload.conversation_sid && identity === payload.identity;
			});
			if (!alreadyTyping) {
				state.participantTyping = [...state.participantTyping, payload];
			}
		},
		removeParticipantTyping: (state, { payload }: { payload: ParticipantTyping }) => {
			state.participantTyping = state.participantTyping.filter(({ conversation_sid, identity }) => {
				return !(conversation_sid === payload.conversation_sid && identity === payload.identity);
			})
		},
		addOrUpdateLastMessage: (state, { payload }: { payload: { conversation_sid: string, message: Message } }) => {
			// Add or update conversation last message
			const lastMessageIndex = state.lastMessages.findIndex(({ conversation_sid }) => conversation_sid === payload.conversation_sid);
			if (lastMessageIndex >= 0) {
				state.lastMessages[lastMessageIndex] = payload;
			} else {
				state.lastMessages = [...state.lastMessages, payload];
			}
			// add message to list of open chat
			if (state.selectedConversation === payload.conversation_sid) {
				const exists = state.messages.find((message) => message.sid === payload.message.sid);
				if (!exists) {
					state.messages = [...state.messages, payload.message];
				}
			}
		}
	},
});

export const {
	setSelectedConversation,
	setMessagesAndLastReadMessageIndex,
	addOrUpdateConversation,
	prependMessages,
	updateConversationAttribute,
	setParticipantTyping,
	removeParticipantTyping,
	setConversationsAndAttributes,
	addOrUpdateLastMessage,
} = MessageSlice.actions;