import React, { useState, useEffect } from "react";
import "../documents.scss";
import DocumentGroups from "./DocumentItem";
import DocumentItemSigned from "./DocumentItemSigned";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ViewerWrapper from "./pdfViewer/ViewerWrapper";
import { Worker } from "@react-pdf-viewer/core";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ShareModal from "../components/shareModal/ShareModal";
import Loader from "../../Loader/Loader";
import { changeOrderDoc } from "../server";
import { useDispatch, useSelector } from 'react-redux';
import { documentActions } from '@bus/document/actions';
import { getCustomers, getDocuments, getSignedDocuments } from '@bus/document/selectors';
import Box from '@mui/material/Box';
const DocumentTab = ({ handleOnDragEnd, docs, doubleClickDoc, handleCheck, selectedDocuments, selectedTab }) => {
  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      {docs.map((doc, index) => (
        <Droppable
          key={doc.id}
          droppableId={`droppable${doc.id}`}
          index={index}
        >
          {(provided) => {
            return (
              <div
                onClick={(e) => {
                  doubleClickDoc(e, index, "origin");
                }}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                <Draggable
                  isDragDisabled={index !== 0}
                  draggableId={`draggable-${doc.id}`}
                  index={index}
                >
                  {(provided, snapshot) => {
                    let transform =
                      provided.draggableProps.style.transform;

                    if (snapshot.isDragging && transform) {
                      transform = transform.replace(
                        /\(.+\,/,
                        "(0,"
                      );
                    }

                    const style = {
                      ...provided.draggableProps.style,
                      transform,
                    };
                    return (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={style}
                      >
                        {selectedTab === 'marksign' ? (
                          <DocumentItemSigned
                            document={doc}
                            handleCheck={handleCheck}
                            isChecked={selectedDocuments.includes(doc.id)}
                          />
                        ) : (
                          <DocumentGroups
                            document={doc}
                            handleCheck={handleCheck}
                            isChecked={selectedDocuments.includes(doc.id)}
                          />
                        )}
                      </div>
                    );
                  }}
                </Draggable>
                {provided.placeholder}
              </div>
            );
          }}
        </Droppable>
      ))}
    </DragDropContext>
  );
};

export default DocumentTab;
