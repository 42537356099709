import React from 'react';
import { FieldRenderProps } from 'react-final-form';

import { Box, TextField } from '@mui/material';

import { getFieldError } from '@core/FinalForm/getFieldError';
import { styles } from '@components/forms/SubmitRequestForAdvertising/styles';
import Typography from '@mui/material/Typography';
import { updateFilterData } from '@components/pages/homepage/components/hero/FilterSlice';

export const RenderSqftMinField: React.FC<FieldRenderProps<number>> = ({
  input,
  meta,
}) => {
  return (
    <div className="update-unit inner-shadow center justify-content-evenly">
      <input
        type="number"
        id=""
        placeholder="Sqft Min"
        {...input}
      />
    </div>
  );
};
