import React, { useMemo, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useGoogleLogin } from "@react-oauth/google";
import FacebookLogin from "@greatsumini/react-facebook-login";
import moment from "moment/moment";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import { useNavigate } from "react-router";
import "./Login.scss";
import Loader from "../Loader/Loader";
import { login } from "./server";
import { faceBookId } from "../../../appVariable/variable";
import Modal from "react-bootstrap/Modal";
import { AiOutlineClose } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import { handleAlert } from '../../../helpers/handleAlert';
import { setUserDetails } from '../../../REST/api';
import axiosInstance from '../../../REST/axiosInstance';
import { useDispatch } from 'react-redux';
import { authActions } from '@bus/auth/actions';
import { updateFilterData } from '@components/pages/homepage/components/hero/FilterSlice';

// validation code
const validate = (values) => {
  const errors = {};
  if (!values.password) {
    errors.password = "Required*";
  } else if (values.password.length < 8) {
    errors.password = "Password must be 8 character or more";
  }

  if (!values.email) {
    errors.email = "Required*";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }

  return errors;
};

export default function Login() {
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [typeOfUser, setTypeOfUser] = useState(0);
  const [show, setShow] = useState(false);
  const [socilaUserDetail, setSocilUserDetail] = useState({});
  let [loading, setLoading] = useState(false);
  const userInfo = JSON.parse(localStorage.getItem('locationDetails'));
  const dispatch = useDispatch();
  const extraData = useMemo(() => {
    if (!userInfo) return {}
    return {
      action_type: "True",
      date_time: moment(new Date()).format("YYYY-MM-DD HH:MM:ss"),
      mac_address: "00:16:3E:5F:FB:1A",
      ip_address: userInfo.ip,
      latitude: userInfo.latitude,
      location: `${userInfo.city} ${userInfo.country_name}`,
      longitude: userInfo.longitude,
      IPv4: userInfo.ip,
      country_code: userInfo.country_code,
    };
  }, [userInfo]);

  const navigate = useNavigate();

  const handleClose = () => setShow(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      remember_me: false,
    },

    // validationSchema: Yup.object({
    //   email: Yup.string()
    //     .email("Invalid email address format")
    //     .required("Email is required"),
    //   password: Yup.string()
    //   .required("Password is required"),
    // }),
    validate,

    onSubmit: (values) => {
      setLoading(!loading);
      login(
        dispatch,
        values.email,
        values.password,
        values.remember_me,
        extraData,
        (success) => {
          setLoading(loading);
          if (success !== "user Not Found") {
            if (success.user_info.usertypeobj === 1) {
              dispatch(updateFilterData({
                property_listing: success.user_info.type_of_listing ? 2 : 1,
                // type_of_listing -> 1 then property_listing in filter === 2
                // type_of_listing -> 0 then property_listing in filter === 1
              }));
              navigate("/dashboard");
            } else {
              if (
                success.user_info.usertypeobj === 2 &&
                success.user_info.agentsubscription === false
              ) {
                navigate("/agent-dashboard/profile-settings"); //  navigate("/subscription")
              }
              if (
                success.user_info.usertypeobj === 2 &&
                success.user_info.agentsubscription === true &&
                success.user_info.billing === false
              ) {
                navigate("/agent-dashboard/profile-settings"); //  navigate("/subscription")
              }
              if (
                success.user_info.usertypeobj === 2 &&
                success.user_info.agentsubscription === true &&
                success.user_info.billing === true &&
                success.user_info.agent_profile === false
              ) {
                navigate("/agent-dashboard/profile-settings");
              }
              if (
                success.user_info.usertypeobj === 2 &&
                success.user_info.agentsubscription === true &&
                success.user_info.billing === true &&
                success.user_info.agent_profile === true
              ) {
                navigate("/agent-dashboard/add-property");
              }
            }
            if (
              success.user_info.usertypeobj === 3 ||
              success.user_info.usertypeobj === 4 ||
              success.user_info.usertypeobj === 5
            ) {
              navigate("/landLoadDashboard/add-property");
            }
            // if (success.user_info.usertypeobj === 3) {
            //   navigate("/landLoadDashboard/add-property")
            // }
          } else {
            toast.error("Invalid Credential");
          }
        },
        (error) => {
          setLoading(false);
          handleAlert(error.message, 'Oops', 'error');
        }
      );
    },
  });

  // social login localStorage start
  const socialLoginSaveTokenInLocalStorage = (tokenDetails) => {
    try {
      let currentDate = new Date();
      tokenDetails.expireDate = currentDate;
      tokenDetails.expireDate = new Date(
        new Date().getTime() + tokenDetails.expiresIn * 1000
      );
      setUserDetails(tokenDetails);
    } catch (err) {
      console.log("err", err);
    }
  };
  // social login localstorage end

  // get user data from facebook
  const getUserDataFromFacebook = (e) => {
    axiosInstance
      .get(
        `https://graph.facebook.com/${e.userID}?fields=id,name,email,picture&access_token=${e.accessToken}`
      )
      .then((res) => {
        if (res.status === 200) {
          userDetails(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // get user data from facebook end

  // google login on Click start
  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => {
      axiosInstance
        .get(`https://www.googleapis.com/oauth2/v3/userinfo`, {
          headers: {
            Authorization: `Bearer ${codeResponse.access_token}`,
          },
        })
        .then((resp) => {
          let data = {};
          setSocilUserDetail({
            email: resp.data.email,
            name: resp.data.name,
          });
          data["email"] = resp.data.email;
          data["name"] = resp.data.name;
          // setShow(true)
          userDetails(data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onError: (error) => console.log("1", error),
    // flow: 'auth-code',
  });

  // social media user detail logic
  const userDetails = (data) => {
    // props.handleUserName(data?.name)
    if (data.email) {
      let tempObj = { ...data, user_log: extraData };
      // tempObj["email"] = data?.email
      // tempObj["name"] = data?.name
      axiosInstance
        .post(`api/user/SocialMedia_Login_Signup_Api/`, tempObj)
        .then((res) => {
          // if(res.data.data.user_info.agentsubscription === false){
          //   setShow(true)
          // }
          let details = res.data.data;

          // this logic for first time user login and pop-up show for select type
          if (details.popup === true) {
            setShow(true);
          }
          // this logic for first time user login and pop-up show for select type end

          // this logic for second time user login and pop-up not show and redirect as per condition
          if (res.data.code == 200 && res.data.status == "success") {
            let tempObj = {};
            tempObj["userId"] = res.data.data.user_info.id;
            tempObj["user_info"] = res.data.data.user_info;
            tempObj["profile"] = res.data.data.profile;
            tempObj["expiresIn"] = "96000";
            tempObj["idToken"] = res.data.data.token.access;
            tempObj["refreshToken"] = res.data.data.token.refresh;
            tempObj["accessToken"] = res.data.data.token.access;
            socialLoginSaveTokenInLocalStorage(tempObj);
            let add_on = { add_on: res.data.data.user_info.add_on };
            localStorage.setItem("add_on", JSON.stringify(add_on));

            let sidebar = { sidebar: res.data.data.user_info.agent_profile };
            localStorage.setItem("sidebar", JSON.stringify(sidebar));
            let details = res.data.data;
            // navigate("/")

            if (details.user_info.usertypeobj == 1) {
              navigate("/dashboard");
            }
            if (
              details.user_info.usertypeobj === 2 &&
              details.user_info.agentsubscription === false
            ) {
              navigate("/agent-dashboard/profile-settings"); // navigate("/subscription")
            }
            if (
              details.user_info.usertypeobj === 2 &&
              details.user_info.agentsubscription === true &&
              details.user_info.billing == false
            ) {
              navigate("/agent-dashboard/profile-settings"); // navigate("/subscription")
            }
            if (
              details.user_info.usertypeobj === 2 &&
              details.user_info.agentsubscription === true &&
              details.user_info.billing == true &&
              details.user_info.agent_profile == false
            ) {
              navigate("/agent-dashboard/profile-settings");
            }
            if (
              details.user_info.usertypeobj === 2 &&
              details.user_info.agentsubscription === true &&
              details.user_info.billing == true &&
              details.user_info.agent_profile == true
            ) {
              navigate("/agent-dashboard/add-property");
            }
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  // social media user detail logic end

  // social login on pop-up continue clicks tart
  const userDetailsContinue = () => {
    // props.handleUserName(data?.name)
    if (socilaUserDetail.email) {
      let tempObj = {
        ...socilaUserDetail,
        user_log: extraData,
        user_type: typeOfUser,
      };
      // tempObj["email"] = data?.email
      // tempObj["name"] = data?.name
      axiosInstance
        .post(`api/user/SocialMedia_Login_Signup_Api/`, tempObj)
        .then((res) => {
          // this logic for second time user login and pop-up not show and redirect as per condition
          if (res.data.code === 200 && res.data.status === "success") {
            let tempObj = {};
            tempObj["userId"] = res.data.data.user_info.id;
            tempObj["user_info"] = res.data.data.user_info;
            tempObj["profile"] = res.data.data.profile;
            tempObj["expiresIn"] = "96000";
            tempObj["idToken"] = res.data.data.token.access;
            tempObj["refreshToken"] = res.data.data.token.refresh;
            tempObj["accessToken"] = res.data.data.token.access;
            socialLoginSaveTokenInLocalStorage(tempObj);
            let add_on = { add_on: res.data.data.user_info.add_on };
            localStorage.setItem("add_on", JSON.stringify(add_on));

            let sidebar = { sidebar: res.data.data.user_info.agent_profile };
            localStorage.setItem("sidebar", JSON.stringify(sidebar));
            let details = res.data.data;
            // navigate("/")
            if (details.user_info.usertypeobj == 1) {
              navigate("/dashboard");
            }
            if (
              details.user_info.usertypeobj === 2 &&
              details.user_info.agentsubscription === false
            ) {
              navigate("/agent-dashboard/profile-settings"); // navigate("/subscription")
            }
            if (
              details.user_info.usertypeobj === 2 &&
              details.user_info.agentsubscription === true &&
              details.user_info.billing == false
            ) {
              navigate("/agent-dashboard/profile-settings"); // navigate("/subscription")
            }
            if (
              details.user_info.usertypeobj === 2 &&
              details.user_info.agentsubscription === true &&
              details.user_info.billing == true &&
              details.user_info.agent_profile == false
            ) {
              navigate("/agent-dashboard/profile-settings");
            }
            if (
              details.user_info.usertypeobj === 2 &&
              details.user_info.agentsubscription === true &&
              details.user_info.billing == true &&
              details.user_info.agent_profile == true
            ) {
              navigate("/agent-dashboard/add-property");
            }
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };
  // social login on pop-up continue click end

  const submitonEnter = (e) => {
    if (e.charCode === 13) {
      formik.handleSubmit();
    }
  };

  return (
    <div>
      {loading ? <Loader /> : null}
      {/* <Navbar /> */}
      <div className="loginpage_body">
        <div className="login_body">
          <div className="login_body_left">
            <img
              src="vertical_logo.png"
              alt="Afisado Logo"
              className="login-logo"
            />
          </div>
          <div className="login_body_right">
            <div className="loginpage_form">
              <div className="loginpage_social_login_button">
                {/* faceBook login */}
                <FacebookLogin
                  appId={faceBookId}
                  onClick={() => {}}
                  callback={(e) => {
                    getUserDataFromFacebook(e);
                  }}
                  render={(renderProps) => (
                    <button
                      className="loginpage_fb_login"
                      onClick={renderProps.onClick}
                    >
                      <i
                        className="fa fa-facebook-square"
                        aria-hidden="true"
                        style={{
                          color: "#4568F1",
                          fontSize: "1.8rem",
                          width: "35px",
                        }}
                      ></i>
                      <span>Log In with Facebook</span>
                    </button>
                  )}
                />
                <button
                  className="loginpage_google_login mt-3"
                  onClick={googleLogin}
                >
                  <i
                    className="fa fa-google fa-fw"
                    style={{ color: "#4568F1", fontSize: "1.8rem" }}
                  ></i>
                  <span>Log In with Google</span>
                </button>
              </div>
              <div
                className="loginpage_inputs mt-2"
                style={{ maxWidth: "500px", margin: "auto" }}
              >
                <div className="input-container">
                  <span>
                    Email address{" "}
                    <span style={{ fontSize: 12, margin: 0, color: "#CFF4FF" }}>
                      (Industry professionals use your business gmail)
                    </span>
                  </span>
                  <input
                    className="input-field"
                    type="text"
                    placeholder="Email"
                    name="email"
                    autoComplete="off"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    onBlur={formik.handleBlur}
                  />
                </div>
                {formik.touched.email && formik.errors.email ? (
                  <div className="validation-text log-validation">
                    {formik.errors.email}
                  </div>
                ) : null}

                <div className="input-container">
                  <span>Password</span>
                  <input
                    onKeyPress={(e) => {
                      submitonEnter(e);
                    }}
                    className="input-field"
                    type={isRevealPwd ? "text" : "password"}
                    placeholder="Password"
                    name="password"
                    autoComplete="off"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                  />
                </div>
                {formik.touched.password && formik.errors.password ? (
                  <div className="validation-text log-validation">
                    {formik.errors.password}
                  </div>
                ) : null}
              </div>
              <div className="loginpage_help_button">
                <div className="d-flex w-100 gap-2">
                  <input type="checkbox" name="remember_me" id="remember_me" onChange={formik.handleChange} />
                  <label htmlFor="remember_me" className="pointer">
                    Remember Me
                  </label>
                </div>
                <div
                  className="loginpage_forgot_password pointer"
                  onClick={() => {
                    navigate('/forget-password');
                  }}
                  style={{
                    color: '#CFF4FF',
                    width: '100%',
                    textAlign: 'right',
                    padding: '0px 15px',
                  }}
                >
                  Forgot Password
                </div>
              </div>

              <div className="loginpage_disclaimer">
                <span className="loginpage_disclaimer-text">
                  By signing in you agree to AFISADO’s{' '}
                  <NavLink
                    className="text-decoration-none"
                    to="/Terms&Conditions"
                  >
                    terms of use
                  </NavLink>{" "}
                  and{" "}
                  <NavLink className="text-decoration-none" to="/PrivacyPolicy">
                    privacy policy
                  </NavLink>
                  .
                </span>
              </div>

              <div className="loginpage_signin">
                <button
                  className="loginpage_signin_btn"
                  type="submit"
                  onClick={formik.handleSubmit}
                >
                  <span className="loginpage_signin_btn_text">Signin</span>
                </button>
              </div>

              <div className="loginpage_signup_help mb-1 mt-4">
                <span className="loginpage_signup_text">
                  Not Registered Yet ?
                  <span
                    className="loginpage_signup_link pointer"
                    onClick={() => {
                      navigate("/signup");
                    }}
                  >
                    {" "}
                    Sign up here
                  </span>
                </span>
              </div>
              <ToastContainer />
            </div>
          </div>
        </div>
        {/* type of user start */}
        <div>
          <Modal show={show} onHide={handleClose} size="lg" centered>
            <Modal.Header className=" justify-content-end">
              <AiOutlineClose
                size={28}
                onClick={() => {
                  setTypeOfUser(0);
                  handleClose();
                }}
              />
            </Modal.Header>
            <Modal.Body>
              <div>
                <div className="signupmodal">
                  <div className="signupmodal_container">
                    <input
                      type="radio"
                      className="userradio"
                      name="userType"
                      id="firstUser"
                    />

                    <label
                      htmlFor="firstUser"
                      className="signupmodal_box1 user-type"
                      onClick={() => {
                        setTypeOfUser(1);
                      }}
                    >
                      <div className="signupmodal_box1_shape1">
                        <div className="signupmodal_box1_subshape1">
                          <div className="signupmodal_box1_subshape_img"></div>
                        </div>
                      </div>
                      <div className="signupmodal_box1_text">
                        <span>Customer (Amazing You)</span>
                      </div>
                    </label>

                    <input
                      type="radio"
                      className="userradio"
                      name="userType"
                      id="secondUser"
                    />
                    <label
                      htmlFor="secondUser"
                      className="signupmodal_box2 user-type"
                      onClick={() => {
                        setTypeOfUser(2);
                      }}
                    >
                      <div className="signupmodal_box2_shape1">
                        <div className="signupmodal_box2_subshape1">
                          <div className="signupmodal_box2_subshape_img"></div>
                        </div>
                      </div>
                      <div className="signupmodal_box2_text">
                        <span>Broker or Agent</span>
                      </div>
                    </label>

                    <input
                      type="radio"
                      className="userradio"
                      name="userType"
                      id="thirdUser"
                    />
                    <label
                      htmlFor="thirdUser"
                      className="signupmodal_box3 user-type"
                      onClick={() => {
                        setTypeOfUser(3);
                      }}
                    >
                      <div className="signupmodal_box3_shape1">
                        <div className="signupmodal_box3_subshape1">
                          <div className="signupmodal_box3_subshape_img"></div>
                        </div>
                      </div>
                      <div className="signupmodal_box3_text">
                        <span>Property Manager or Landlord</span>
                      </div>
                    </label>

                    <input
                      type="radio"
                      className="userradio"
                      name="userType"
                      id="fourthUser"
                    />
                    <label
                      htmlFor="fourthUser"
                      className="signupmodal_box4 user-type"
                      onClick={() => {
                        setTypeOfUser(4);
                      }}
                    >
                      <div className="signupmodal_box4_shape1">
                        <div className="signupmodal_box4_subshape1">
                          <div className="signupmodal_box4_subshape_img"></div>
                        </div>
                      </div>
                      <div className="signupmodal_box1_text">
                        <span>Developer or Property Investor</span>
                      </div>
                    </label>

                    <input
                      type="radio"
                      className="userradio"
                      name="userType"
                      id="fiftUser"
                    />
                    <label
                      htmlFor="fiftUser"
                      className="signupmodal_box5 user-type"
                      onClick={() => {
                        setTypeOfUser(5);
                      }}
                    >
                      <div className="signupmodal_box5_shape1">
                        <div className="signupmodal_box5_subshape1">
                          <div className="signupmodal_box5_subshape_img"></div>
                        </div>
                      </div>
                      <div className="signupmodal_box1_text">
                        <span>FRBHO or FSBHO</span>
                      </div>
                    </label>
                  </div>

                  <div className="signupmodal_action_button">
                    <button
                      className="signupmodal_button"
                      type="submit"
                      onClick={userDetailsContinue}
                      disabled={typeOfUser === 0}
                    >
                      <span className="signupmodal_button_text">Continue</span>
                    </button>
                  </div>
                </div>
              </div>
            </Modal.Body>
            {/* <Modal.Footer></Modal.Footer> */}
          </Modal>{" "}
        </div>
        {/* type of user end */}
      </div>
      {/* <Footer /> */}
    </div>
  );
}
