import React from "react";
import "./Manhatten.scss";
import { useSelector } from 'react-redux';
import { getNeighborhoodTownLimit } from '@bus/ui/selectors';
const CityArea = ({ cityArea, handleSelect, checkedArea, setChecked }) => {
  const neighborhoodTownLimit = useSelector(getNeighborhoodTownLimit);
  const handleChecked = (e, id) => {
    const { checked } = e.target;
    let prev = [...checkedArea];
    let itemIndex = prev.findIndex((item) => item.area.id === id);
    if (itemIndex !== -1) {
      prev.splice(itemIndex, 1);
    } else {
      const newArea = {
        area: cityArea.find((item) => item.id === id),
        doc1: null,
        doc2: null,
        doc3: null,
      };
      prev.push(newArea);
    }
    setChecked([...prev]);
    handleSelect(checked);
  };

  const sortedCityArea = [...cityArea].sort((a, b) => {
    const aChecked = checkedArea.some((item) => item.area.id === a.id);
    const bChecked = checkedArea.some((item) => item.area.id === b.id);
    return aChecked === bChecked ? 0 : aChecked ? -1 : 1;
  });

  return (
    <div className="Manhatten_sectioNS d-flex flex-column gap-4">
      <div className="d-flex scrollS flex-grow-1 flex-wrap gap-3">
        {sortedCityArea.map((area) => (
          <div className="form-check" key={`manhatte${area.id}`}>
            <input
              className={'form-check-input'}
              type={'checkbox'}
              value={area.id}
              id={`${area.area_name}${area.id}`}
              onChange={(e) => handleChecked(e, area.id)}
              checked={checkedArea.some((item) => item.area.id === area.id)}
              disabled={
              (checkedArea.length >= neighborhoodTownLimit && !checkedArea.some((item) => item.area.id === area.id)) ||
                checkedArea.some((item) => item.id && (item.area.id === area.id))}
            />
            <label
              className="form-check-label ms-2 pointer"
              htmlFor={`${area.area_name}${area.id}`}
            >
              {area.area_name}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CityArea;
