import React, { lazy } from 'react';

import { AppLayout } from '@layouts/index';
import { book } from '@routes/book';

import { AppRoute } from '@store/typedefs';
import { commonRoutes } from '@pages/common/routes';
const AdvRegPage = lazy(() => import('@components/pages/advertisement-flow/component/Registration_Page/Adv_Reg_Page'));
const AdvLoginPage = lazy(() => import('@components/pages/advertisement-flow/component/Login_Page/Adv_Login_Page'));
const Login = lazy(() => import('@components/pages/Auth/Login'));
const Signup = lazy(() => import('@components/pages/Auth/Signup'));
const ForgetPass = lazy(() => import('@components/pages/Auth/ForgetPass'));
const ResetPass = lazy(() => import('@components/pages/Auth/ResetPass'));
const TeamLogin = lazy(() => import('@components/pages/Auth/TeamLogin'));
export const publicRoutes: AppRoute<any>[] = [
  {
    path: book.logIn,
    Component: <Login />,
    Layout: AppLayout,
    layoutProps: {},
  },
  {
    path: book.signUp,
    Component: <Signup />,
    Layout: AppLayout,
    layoutProps: {},
  },
  {
    path: book.resetPassword,
    Component: <ResetPass />,
    Layout: AppLayout,
    layoutProps: {},
  },
  {
    path: book.teamLogin,
    Component: <TeamLogin />,
    Layout: AppLayout,
    layoutProps: {},
  },
  {
    path: book.forgetPassword,
    Component: <ForgetPass />,
    Layout: AppLayout,
    layoutProps: {},
  },
  {
    path: book.adSignup,
    Component: <AdvRegPage />,
    Layout: AppLayout,
    layoutProps: {},
  },
  {
    path: book.adLogin,
    Component: <AdvLoginPage />,
    Layout: AppLayout,
    layoutProps: {},
  },
  ...commonRoutes,
];