import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  Search2State,
} from './typedefs';

const initialState: Search2State = {
  isFetching: false,
  leaseType: [],
  isSearch: false,
};

const search2Slice = createSlice({
  name: 'search2',
  initialState,
  reducers: {
    startFetching(state) {
      state.isFetching = true;
    },
    stopFetching(state) {
      state.isFetching = false;
    },
    fillLeaseType(state, action: PayloadAction<any>) {
      state.leaseType = action.payload;
    },
    setIsSearch(state, action: PayloadAction<boolean>) {
      state.isSearch = action.payload;
    },
  },
});

export default search2Slice;
