import * as effects from 'redux-saga/effects';

import { fetchNearestCity, fetchRecentListing, fetchMainCategories, fetchConfig } from './workers';
import { landingActions } from '../actions';

function* watchFetchConfig() {
  yield effects.takeEvery(landingActions.fetchConfig.type, fetchConfig);
}

function* watchFetchMainCategories() {
  yield effects.takeEvery(landingActions.fetchMainCategories.type, fetchMainCategories);
}
function* watchFetchNearestCity() {
  yield effects.takeEvery(landingActions.fetchNearestCity.type, fetchNearestCity);
}
function* watchFetchRecentListing() {
  yield effects.takeEvery(landingActions.fetchRecentListing.type, fetchRecentListing);
}
export function* watchLanding() {
  yield effects.all([
    effects.call(watchFetchNearestCity),
    effects.call(watchFetchRecentListing),
    effects.call(watchFetchMainCategories),
    effects.call(watchFetchConfig),
  ]);
}
