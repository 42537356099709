import { createSelector } from 'reselect';

const search2Selector = (state: any) => state.search2;

export const getIsSearch2Fetching = createSelector([search2Selector], (result) => {
  return result.isFetching;
});

export const getLeaseType = createSelector([search2Selector], ({ leaseType }) => {
  return leaseType;
});

export const getIsSearch = createSelector([search2Selector], ({ isSearch }) => {
  return isSearch;
});
