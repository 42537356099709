import React, { useEffect, useState } from "react";
import "./agentcustomercard.scss";
import CustumerCard from "./CustumerCard";
import { useSelector, useDispatch } from "react-redux";
import { getMemberInfo } from "../agentVirtualOffice/server";
import { memberInfo } from "../agentVirtualOffice/AgentOfficeSlice";
import { useNavigate } from "react-router";

const AgentCustomerCards = () => {
  const [loading, setloading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let singleClickTimer = null;
  const teamList = useSelector(
    (state) => state.virtualOfficeInStore.teamMemberList
  );
  const clickHandler = (event, index) => {
    if (event.detail === 2) {
      clearTimeout(singleClickTimer);
      getMemberInfo(
        index,
        (success) => {
          if (success.data.code === 200) {
            dispatch(memberInfo(success.data.data));
            navigate(`/agent-virtual-office/contact/edit`);
          }
        },
        (error) => {
          console.log(error, "error");
        }
      );
      // setisActive(teamList[0]?.custom_obj?.id);
      // setactiveAgent(teamList[0])
    }

    if (event.detail === 1) {
      singleClickTimer = setTimeout(() => {
        navigate("/agent-virtual-office/contact/" + index);
      }, 400);
    }
  };

  useEffect(() => {
    if (teamList.length > 0) {
      setloading(false);
    }
  }, [teamList]);

  return (
    <>
      {
        <div className=" MainCard_container w-100">
          <div className="filter-search_a w-100">
            {/* <Filterbar className="Fil_bar" /> */}
          </div>

          <div className="mt-4" style={{ color: "#315efb" }}>
            <h3>CUSTOMER INFO </h3>
          </div>
          <div className="card_container mt-4 d-flex justify-content-start flex-wrap hide-scrollbar">
            {teamList.length > 0
              ? teamList.map((teamMember, index) => (
                  <div
                    onClick={(e) => clickHandler(e, teamMember.custom_obj?.id)}
                    key={"teammember" + index}
                  >
                    <CustumerCard teamMember={teamMember} />
                  </div>
                ))
              : null}
          </div>
        </div>
      }
    </>
  );
};

export default AgentCustomerCards;
