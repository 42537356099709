import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import "./shareDocuments.scss";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { InputLabel, Stack, Chip } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import CustomizedHook from "./MultiAutoCompleteInput";
import { getPDFList, updatePDFList } from "../../server";
import { getUserDetails } from '../../../../../REST/api';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const ShareModal = (props) => {
  const [personName, setPersonName] = React.useState([]);
  const [sharedDocs, setSharedDocs] = useState([]);
  const [customers, setCustomers] = useState(props.customers);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  console.log(customers);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleShare = () => {
    let userDetails = JSON.parse(getUserDetails());
    const userId = userDetails.userId;
    const customersEmail = selectedCustomers.map((customer) => customer.email);
    const data = {
      userId: userId,
      share_emails: customersEmail,
      share_doc_id: props.sharedDocs,
    };
    updatePDFList(data, (success) => {
      console.log(success);
    });
    props.setShowShareDialog(false);
  };

  return (
    <div className="add-note-modal-parent">
      <Modal
        show={true}
        centered
        size="lg"
        onHide={() => props.setShowShareDialog(false)}
        dialogClassName="modal-90w add-note-modal"
        aria-labelledby="example-custom-modal-styling-title"
        backdrop={true}
      >
        <Modal.Header className=" pb-0" closeButton>
          <Modal.Title
            className="w-100"
            id="example-custom-modal-styling-title"
          >
            <p className="custom-heading-note text-capitalize">
              Share Document
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="share__modal">
            <div className=" mb-4">
              <div
                className="d-flex pb-1 input__box"
                style={{ borderBottom: "2px solid gray" }}
              >
                <div
                  className="input__box__name pe-3 d-flex align-items-center text-dark"
                  style={{ borderRight: "2px solid gray" }}
                >
                  <p className="text-dark name__text">User Name</p>
                </div>
                <div className=" d-flex flex-grow-1">
                  <CustomizedHook
                    customers={customers}
                    selectedCustomers={setSelectedCustomers}
                  />
                </div>
              </div>
              {/* <div
                className="d-flex pb-1 input__box__doc"
                style={{ borderBottom: "1px solid gray" }}
              >
                <div
                  className=" pe-3 d-flex align-items-center text-dark"
                  style={{ borderRight: "1px solid gray" }}
                >
                  <p className="text-dark name__text">Select Document</p>
                </div>
                <div className=" d-flex flex-grow-1">
                  <FormControl
                    sx={{ ml: 1, width: 500 }}
                    className="multi__select__body"
                  >
                    <Select
                      multiple
                      value={selectedNames}
                      onChange={(e) => setSelectedNames(e.target.value)}
                      input={<OutlinedInput label="Multiple Select" />}
                      renderValue={(selected) => (
                        <Stack gap={1} direction="row" flexWrap="wrap">
                          {selected.map((value) => (
                            <Chip
                              key={value}
                              label={value}
                              onDelete={() =>
                                setSelectedNames(
                                  selectedNames.filter(
                                    (item) => item !== value
                                  )
                                )}
                              deleteIcon={
                                <CancelIcon
                                  onMouseDown={(event) =>
                                    event.stopPropagation()
                                  }
                                />
                              }
                            />
                          ))}
                        </Stack>
                      )}
                    >
                      {names.map((name) => (
                        <MenuItem
                          key={name}
                          value={name}
                          sx={{ justifyContent: "space-between" }}
                        >
                          {name}
                          {selectedNames.includes(name) ? (
                            <CheckIcon color="info" />
                          ) : null}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div> */}
              <div className=" text-end mt-3 media_pading p-0">
                <button className="blue__btn" onClick={handleShare}>
                  Share
                </button>
              </div>
            </div>
          </div>
          <div></div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ShareModal;
