import * as yup from 'yup';

export const schema = yup.object().shape({
  property_listing: yup.number().required('This field can not be empty').nullable(),
  category: yup.number().required('This field can not be empty').nullable(),
  type: yup.number().required('This field can not be empty').nullable(),
  Sub_category: yup.number().nullable(),
  area: yup.number().nullable(),
  city: yup.number().nullable(),
  state: yup.number().nullable(),
  country: yup.number().nullable(),
  pricemin: yup.number()
    .nullable(true) // Убедитесь, что используете true
    .test(
      'is-less',
      'Price minimum should be less than price maximum',
      function(value) {
        const { pricemax } = this.parent;
        return value == null || pricemax == null || value < pricemax;
      }
    ),
  pricemax: yup.number()
    .nullable(true) // Убедитесь, что используете true
    .test(
      'is-greater',
      'Price maximum should be greater than price minimum',
      function(value) {
        const { pricemin } = this.parent;
        return value == null || pricemin == null || value > pricemin;
      }
    ),
  cost_type: yup.number().nullable(),
  filter_obj: yup.boolean().nullable(),
  Bedrooms: yup.number().nullable(),
  Bathrooms: yup.number().nullable(),
  Squft_min: yup.number()
    .nullable()
    .test(
      'is-less-sqft',
      'Sqft minimum should be less than Sqft maximum',
      function(value) {
        const { Squft_max } = this.parent;
        return !Squft_max || !value || value < Squft_max;
      }
    ),
  Squft_max: yup.number()
    .nullable()
    .test(
      'is-greater-sqft',
      'Sqft maximum should be greater than Sqft minimum',
      function(value) {
        const { Squft_min } = this.parent;
        return !Squft_min || !value || value > Squft_min;
      }
    ),
  Amenities_filter: yup.array().of(yup.number().integer('Each item must be an integer').positive('Each item must be a positive number')).default([]),
  units: yup.number().nullable(),
  room: yup.number().nullable(),
  block: yup.number().nullable(),
  lot: yup.number().nullable(),
  Zone: yup.number().nullable(),
  lot_diamensions: yup.number().nullable(),
  building_diamensions: yup.number().nullable(),
  stories: yup.number().nullable(),
  far: yup.number().nullable(),
  Assessment: yup.number().nullable(),
  Annual_Taxes: yup.number().nullable(),
  Available_Air_Rights: yup.boolean().nullable(),
  locationText: yup.string().nullable(),
  fee: yup.number().nullable(),
  lease_type: yup.number().nullable(),
  property_condition: yup.number().nullable(),
  class_type: yup.number().nullable(),
  capRate_min: yup.number().nullable(),
  capRate_max: yup.number().nullable(),
  occupancy_min: yup.number().nullable(),
  occupancy_max: yup.number().nullable(),
  filter_name: yup.string().nullable(),
});
