import React, { FC } from 'react';
import "../dashboard/dashboard.scss";
import "./Support.scss";
import { Modal } from "react-bootstrap";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import moment from 'moment';

interface IDetails {
  id: number;
  ticket_no: string;
  issue_type: string;
  title: string;
  description: string;
  priority: string;
  image: string | null;
  status: boolean;
  is_request: boolean;
  reported_date: null | string;
  comments: string;
  user: number;
}

type TicketDetailsProps = {
  modalShow: boolean;
  handleModalClose: () => void;
  ticketDetails: IDetails;
};

export const TicketDetails: FC<TicketDetailsProps> = ({ modalShow, handleModalClose, ticketDetails }) => {

  return (
    <div className="forgot_modals">
      <Modal
        className="forgot_modals"
        show={modalShow}
        size="lg"
        centered
        ria-labelledby="contained-modal-title-vcenter"
        onHide={handleModalClose}>
        <Modal.Header closeButton className="border-bottom-0 supportmodal_header gdgyg">
          <Modal.Title id="contained-modal-title-vcenter " className="border-0">
            <Box display={'flex'} gap={'15px'}>
              <Typography>Ticket Detail</Typography>
              <Typography>{ticketDetails.ticket_no}</Typography>
            </Box>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="supportmodal_body">
          <Box mb={'10px'} border={'1px solid rgba(0, 0, 0, 0.5)'} borderRadius={'5px'} p={'10px'}>
            <Typography variant={'caption'}>
              Title
            </Typography>
            <Typography>
              {ticketDetails.title}
            </Typography>
          </Box>
          <Box mb={'10px'} border={'1px solid rgba(0, 0, 0, 0.5)'} borderRadius={'5px'} p={'10px'}>
            <Typography variant={'caption'}>
              Description
            </Typography>
            <Typography>
              {ticketDetails.description}
            </Typography>
          </Box>
          <Box mb={'10px'} border={'1px solid rgba(0, 0, 0, 0.5)'} borderRadius={'5px'} p={'10px'}>
            <Typography variant={'caption'}>
              Priority
            </Typography>
            <Typography>
              {ticketDetails.priority}
            </Typography>
          </Box>
          <Box mb={'10px'} border={'1px solid rgba(0, 0, 0, 0.5)'} borderRadius={'5px'} p={'10px'}>
            <Typography variant={'caption'}>
              Issue Type
            </Typography>
            <Typography>
              {ticketDetails.issue_type}
            </Typography>
          </Box>
          {ticketDetails.reported_date && (
            <Box mb={'10px'} border={'1px solid rgba(0, 0, 0, 0.5)'} borderRadius={'5px'} p={'10px'}>
              <Typography variant={'caption'}>
                Reported Date
              </Typography>
              <Typography>
                {moment(ticketDetails.reported_date).format('MM/DD/YYYY')}
              </Typography>
            </Box>
          )}
          <Box mb={'10px'} border={'1px solid rgba(0, 0, 0, 0.5)'} borderRadius={'5px'} p={'10px'}>
            <Typography variant={'caption'}>
              Status
            </Typography>
            <Typography>
              {ticketDetails.status ? 'Complete' : 'Pending'}
            </Typography>
          </Box>
          {ticketDetails.comments && (
            <Box mb={'10px'} border={'1px solid rgba(0, 0, 0, 0.5)'} borderRadius={'5px'} p={'10px'}>
              <Typography variant={'caption'}>
                Comments
              </Typography>
              <Typography>
                {ticketDetails.comments}
              </Typography>
            </Box>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}
