import { getToken } from "../../../../../appVariable/variable";
import axiosInstance from '../../../../../REST/axiosInstance';

export function changePassword(tempObj, success) {
  let token = getToken();
  axiosInstance
    .post(`api/user/changepassword/`, tempObj, {
      headers: {
        Authorization: `Bearer ${token?.idToken}`,
      },
    })
    .then((response) => {
      success(response)
    })
    .catch((error) => {
      console.log(error);
    });
}
export function checkpassword(tempObj, success) {
  let token = getToken();
  axiosInstance
    .post(`api/user/checkpassword/`, tempObj, {
      headers: {
        Authorization: `Bearer ${token?.idToken}`,
      },
    })
    .then((response) => {
      success(response);
    })
    .catch((error) => {
      console.log(error);
    });
}

export function submitProfilePassword(tempObj, success) {
  let token = getToken();
  axiosInstance
    .post(`api/user/profilepassword/`, tempObj, {
      headers: {
        Authorization: `Bearer ${token?.idToken}`,
      },
    })
    .then((response) => {
      if (response.data.code === 200 && response.data.status === "success") {
        console.log(response.data.data);
        success(response.data.data);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

export function getProfilePasswordStatus(success) {
  let token = getToken();
  let userslug = token?.profile.slug;
  axiosInstance.get(`api/user/agentuserprofile/${userslug}`, {
    headers: { Authorization: `Bearer ${token?.idToken}`, },
  })
    .then((response) => {
      if (response.data.code === 200 && response.data.status === "success") { success(response.data.data); }
    })
    .catch((error) => { console.log(error); });
}

export function removePasssword(tempObj, success) {
  let token = getToken();
  axiosInstance.post(`api/user/RemovePropefilePassword/`, tempObj, {
    headers: { Authorization: `Bearer ${token?.idToken}`, },
  })
    .then((response) => {
      success(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

export function ProfileSettingForgetPassword(userVal, success) {
  axiosInstance
    .post(`api/user/ForgetProfilePassword/`, userVal)
    .then((response) => {
      success(response);

    })
    .catch((error) => {
      console.log(error);
    });
}

export const profileSettingResetPassword = (userVal, success) => {
  axiosInstance.post(`api/user/ResetProfilePassword/`, userVal, {}).then((response) => {
    success(response)
  }).catch((error) => {
    console.log(error)
  })

}
