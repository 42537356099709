import "./message.scss"
import { ChatList } from "@components/pages/Message/components/ChatList";
import { ActiveChat } from "@components/pages/Message/components/ActiveChat";

const Message = () => {

  return (
    <>
      <div className="message-box box-shodow d-flex flex-grow-1">
        <ChatList />
        <ActiveChat />
      </div>
    </>
  )
}

export default Message;
