import { getToken } from "../../../../appVariable/variable"
import axiosInstance from '../../../../REST/axiosInstance';

export function changepassword(passwordObj, success) {
    let token = getToken()
  axiosInstance.post(`api/user/changepassword/`, passwordObj, {
        headers: { 'Authorization': `Bearer ${token?.idToken}` }
    })
        .then((response) => {
            success(response)
        })
        .catch((error) => {
            console.log(error);
        });
}