import React, { useEffect } from "react";
import CardSlider from "./components/CardSlider";
import "./recentListing.scss";
import { useDispatch, useSelector } from 'react-redux';
import { landingActions } from '@bus/landing/actions';
import { getResentListing } from '@bus/landing/selectors';

const RecentListing = () => {
  const dispatch = useDispatch();
  const recentListing = useSelector(getResentListing);
  const userInfo = JSON.parse(localStorage.getItem('locationDetails'));

  useEffect(() => {
    if (userInfo) {
      dispatch(landingActions.fetchMainCategories());
      dispatch(landingActions.fetchConfig());
      dispatch(landingActions.fetchRecentListing({
        latitude: userInfo.latitude,
        longitude: userInfo.longitude,
      }));
    }
  }, [userInfo?.latitude, userInfo?.longitude]);

  return (
    <div className="root layout span-to-top pt-2">
      <h3 className="section recent-listing-heading pt-3">RECENT LISTINGS</h3>
      <div className="section">
        <div className=" recent-listing-sec gap-3 d-flex flex-column pb-5">
          <CardSlider heading="Residential" listing={recentListing.residential} />
          <CardSlider heading="Commercial" listing={recentListing.commercial} />
        </div>
      </div>
    </div>
  );
};

export default RecentListing;
