import { getToken } from "../../../../../appVariable/variable";
import axiosInstance from '../../../../../REST/axiosInstance';

export function submitBrokerageDetails(tempObj, success) {
  let token = getToken()
  axiosInstance.post(`api/user/brokerageLic/`, tempObj, {
    headers: {
      'Authorization': `Bearer ${token.idToken}`
    }
  })
    .then((response) => {
      success(response);
    })
    .catch((error) => {
      console.log(error);
    });
}

export function getBrokerageDetails(success) {
  let token = getToken()
  let userSlug = token?.profile.slug;

  axiosInstance.get(`api/user/brokerageLic/${userSlug}`, {
    headers: {
      Authorization: `Bearer ${token?.idToken}`,
    },
  })
    .then((response) => {
      if (response.data.code === 200 && response.data.status === "success") {
        success(response.data.data);
      }
      else {
        console.log(response.data.data)
      }
    })
    .catch((error) => {
      console.log(error);
    });
}