import neighborhoodSlice from './slice';
import { createAction } from '@reduxjs/toolkit';
import prepareActions from '@helpers/prepareActions';

export const neighborhoodActions = {
  ...neighborhoodSlice.actions,
  fetchNeighborhoodTowns: createAction('neighborhood/fetchNeighborhoodTowns'),
  fetchCityAreas: createAction('neighborhood/fetchCityAreas'),
  deleteAreas: createAction('neighborhood/deleteAreas', prepareActions.movePromiseToMeta),
};
