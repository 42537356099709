import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AdvertiseState } from './typedefs';

const initialState: AdvertiseState = {
  isShowThankYou: false,
};

const advertiseSlice = createSlice({
  name: 'advertise',
  initialState,
  reducers: {
    setShowThankYouModal(state, action: PayloadAction<boolean>) {
      state.isShowThankYou = action.payload;
    },
  },
});

export default advertiseSlice;
