import { useSelector } from "react-redux";
import { GetParticipants } from "../../../../store/selectors/message.selector";
import { Participant } from "@twilio/conversations";
import { useMemo } from "react";
import { getInitials } from "@components/shared/profile-avatar";

export const useParticipantAttributes = (participant?: Participant | string) => {
	const participants = useSelector(GetParticipants);

	const attribute = useMemo(() => {
		if (!participant) return { name: 'No participants' }
		const identity = participant instanceof Participant ? participant.identity : participant;
		const attribute = participants.find((participantAttribute) => participantAttribute.identity === identity);
		if (!attribute) return { name: 'Unknown participant' }

		return {
			name: `${attribute.first_name} ${attribute.last_name}`.trim(),
			image: attribute.profile_image
		}
	}, [participant, participants]);

	return {
		fullName: attribute.name,
		initials: getInitials(attribute.name || ''),
		image: attribute.image || undefined
	}
}