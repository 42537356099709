import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import Image from '../../assets/imgone.jpg';

import { LandingState } from './typedefs';

const initialState: LandingState = {
  isFetching: false,
  nearestCommercialCityCity: [],
  nearestResidentialCityCity: [],
  recentListing: {
    residential: [],
    commercial: [],
  },
  mainCategories: [],
  config: null,
  cities: [],
  propertiesType: [],
};

const landingSlice = createSlice({
  name: 'landing',
  initialState,
  reducers: {
    startFetching(state) {
      state.isFetching = true;
    },
    stopFetching(state) {
      state.isFetching = false;
    },
    fillNearestCity(state, action: PayloadAction<any>) {
      const data = action.payload.data.map((item: any) => ({
        ...item,
        img: item.city_image,
        button: item.city_name,
        cityId: item.id
      }));
      state.nearestCommercialCityCity = data.filter((item: any) => item.propertylisting_type === 'Commercial');
      state.nearestResidentialCityCity = data.filter((item: any) => item.propertylisting_type === 'Residential');
    },
    fillRecentListing(state, action: PayloadAction<any>) {
      const noFeeResidentialItem = action.payload.data.no_fee.find((item: any) => item.property_listing_type_id === 1);
      const newestResidentialItem = action.payload.data.newest.find((item: any) => item.property_listing_type_id === 1);
      const newestCommercialItem = action.payload.data.newest.find((item: any) => item.property_listing_type_id === 2);
      const luxuryRentResidentialItem = action.payload.data.luxury_rent.find((item: any) => item.property_listing_type_id === 1 && item.propertylisting_type_id === 1);
      const luxurySalesResidentialItem = action.payload.data.luxury_sales.find((item: any) => item.property_listing_type_id === 1 && item.propertylisting_type_id === 1);
      const petFriendlyResidentialItem = action.payload.data.pet_friendly.find((item: any) => item.property_listing_type_id === 1);
      const noFeeResidential = {
        id: uuidv4(),
        img: Image,
        title: "No Fee",
        listing_type: 1,
        data: noFeeResidentialItem ? noFeeResidentialItem.count : 0,
        property_listing_type: 1,
      };
      const newestResidential = {
        id: uuidv4(),
        img: Image,
        title: "Just Listed",
        listing_type: 1,
        data: newestResidentialItem ? newestResidentialItem.count : 0,
        property_listing_type: 1,
      };
      const newestCommercial = {
        id: uuidv4(),
        img: Image,
        title: "Just Listed",
        listing_type: 3,
        data: newestCommercialItem ? newestCommercialItem.count : 0,
        property_listing_type: 2,
      };
      const luxuryRentResidential = {
        id: uuidv4(),
        img: Image,
        title: "Luxury Rent",
        listing_type: 1,
        data: luxuryRentResidentialItem ? luxuryRentResidentialItem.count : 0,
        property_listing_type: 1,
        min_price: state.config.data.luxury_rent_min_price,
      };
      const luxurySalesResidential = {
        id: uuidv4(),
        img: Image,
        title: "Luxury Sales",
        listing_type: 2,
        data: luxurySalesResidentialItem ? luxurySalesResidentialItem.count : 0,
        property_listing_type: 1,
        min_price: state.config.data.luxury_sales_min_price,
      };
      const petFriendlyResidential = {
        id: uuidv4(),
        img: Image,
        title: "Pet Friendly",
        listing_type: 1,
        data: petFriendlyResidentialItem ? petFriendlyResidentialItem.count : 0,
        property_listing_type: 1,
      };
      const mergedListing = [
        newestResidential,
        newestCommercial,
        petFriendlyResidential,
        noFeeResidential,
        ...state.mainCategories
          .filter((item: any) => item.show_on_recent_listing)
          .map((item: any) => {
            const payloadData = action.payload.data.listing_type.find((payloadItem: any) => payloadItem.property_main_category_id === item.id);

            return {
              ...item,
              img: Image,
              title: `${item.Main_category} - ${item.listing_type_title}`,
              listing_type: item.listing_type,
              data: payloadData ? payloadData.count : 0,
            };
          }),
        luxurySalesResidential,
        luxuryRentResidential];
      state.recentListing = {
        residential: mergedListing.filter((item: any) => item.listing_type === 1 || item.listing_type === 2),
        commercial: mergedListing.filter((item: any) => item.listing_type === 3 || item.listing_type === 4),
      };
    },
    fillMainCategories(state, action: PayloadAction<any>) {
      state.mainCategories = action.payload;
    },
    fillConfig(state, action: PayloadAction<any>) {
      state.config = action.payload;
    },
    fillCities(state, action: PayloadAction<any>) {
      state.cities = action.payload;
    },
    fillPropertiesType(state, action: PayloadAction<any>) {
      state.propertiesType = action.payload;
    },
  },
});

export default landingSlice;
