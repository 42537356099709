import * as effects from 'redux-saga/effects';

import {
  searchPerson,
  fetchSavedAgents,
  deletePerson,
  savePerson,
  loadMorePerson,
  loadMoreSavedPerson,
} from './workers';
import { salesPersonActions } from '@bus/salesPerson/actions';

function* watchDeleteAreas() {
  yield effects.takeEvery(salesPersonActions.searchPerson.type, searchPerson);
}

function* watchFetchSavedAgents() {
  yield effects.takeEvery(salesPersonActions.fetchSavedAgents.type, fetchSavedAgents);
}

function* watchDeletePerson() {
  yield effects.takeEvery(salesPersonActions.deletePerson.type, deletePerson);
}

function* watchSavePerson() {
  yield effects.takeEvery(salesPersonActions.savePerson.type, savePerson);
}

function* watchLoadMorePerson() {
  yield effects.takeEvery(salesPersonActions.loadMorePerson.type, loadMorePerson);
}

function* watchLoadMoreSavedPerson() {
  yield effects.takeEvery(salesPersonActions.loadMoreSavedPerson.type, loadMoreSavedPerson);
}

export function* watchSalesPerson() {
  yield effects.all([
    effects.call(watchDeleteAreas),
    effects.call(watchFetchSavedAgents),
    effects.call(watchDeletePerson),
    effects.call(watchSavePerson),
    effects.call(watchLoadMorePerson),
    effects.call(watchLoadMoreSavedPerson),
  ]);
}
