import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getToken } from '../../../../../appVariable/variable';
import axiosInstance from '../../../../../REST/axiosInstance';

export const getSearch = createAsyncThunk(
  'users/getSearch',
  async (userData) => {
    const response = await axiosInstance.post(`api/property/search/`, userData);
    return response.data;
  }
);

export const getSearchWithUserId = createAsyncThunk(
  'users/getSearchWithUserId',

  async (userData) => {
    let userlogin = getToken();
    let userId = userlogin?.userId;
    const response = await axiosInstance.post(`api/property/search/?user_id=${userId}`, userData);
    return response.data;
  }
);

const getSearchSlice = createSlice({
  name: 'searchget',
  initialState: {
    searchGet: [],
    status: 'idle',
    error: null,
    searchGetWithLogin: [],
    statusLogin: 'idle',
    errorLogin: null,
    category: [{}],
    aminity: [],
    commercialPopupVisible: false,
    residentialPopupVisible: false,
    saveSearchPopupVisible: false,
    removeSave: false,
    comBedRoom: 0,
    comRoom: 0,
    resiBedRoom: 0,
    resiRoom: 0,
    searchShema: {}
  },
  reducers: {
    updateSeacrhSchema: (state, action) => {
      state.searchShema = action.payload;
    },
    updateMyValue: (state, action) => {
      state.category = action.payload;
    },
    updateResiBedRoom: (state, action) => {
      state.resiBedRoom = action.payload;
    },
    updateResiRoom: (state, action) => {
      state.resiRoom = action.payload;
    },
    updateComBedRoom: (state, action) => {
      state.comBedRoom = action.payload;
    },
    updateComRoom: (state, action) => {
      state.comRoom = action.payload;
    },
    updateMyAminity: (state, action) => {
      state.aminity = action.payload;
    },
    showCommercialPopup: (state) => {
      state.commercialPopupVisible = true;
    },
    hideCommercialPopup: (state) => {
      state.commercialPopupVisible = false;
    },
    showResidentialPopup: (state) => {
      state.residentialPopupVisible = true;
    },
    hideResidentialPopup: (state) => {
      state.residentialPopupVisible = false;
    },
    showSaveSearchPopup: (state) => {
      state.saveSearchPopupVisible = true;
    },
    hideSaveSearchPopup: (state) => {
      state.saveSearchPopupVisible = false;
    },
    hideRemoveSave: (state) => {
      state.removeSave = false;
    },
    showRemoveSave: (state, action) => {
      state.removeSave = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSearch.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getSearch.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.searchGet = action.payload;
      })
      .addCase(getSearch.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(getSearchWithUserId.pending, (state) => {
        state.statusLogin = 'loading';
      })
      .addCase(getSearchWithUserId.fulfilled, (state, action) => {
        state.statusLogin = 'succeeded';
        state.searchGetWithLogin = action.payload;
      })
      .addCase(getSearchWithUserId.rejected, (state, action) => {
        state.statusLogin = 'failed';
        state.errorLogin = action.error.message;
      });


  },
  searchData :(state,action)=>{

  }
});

export const { updateMyValue, showCommercialPopup,
  hideCommercialPopup,
  showResidentialPopup,
  hideResidentialPopup,
  showSaveSearchPopup,
  hideSaveSearchPopup,
  hideRemoveSave,
  showRemoveSave,
  updateMyAminity,
  updateResiBedRoom,
  updateResiRoom,
  updateComBedRoom,
  updateComRoom,
  updateSeacrhSchema
} = getSearchSlice.actions;

export default getSearchSlice.reducer