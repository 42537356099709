import React, { useRef } from 'react'
import CommunityCard from './CommunityCard'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const CommunitySlider = (props) => {
  const scrollRef = useRef(null);
  const scroll = (direction) => {
    const { current } = scrollRef;
    if (direction === 'left') {
      current.scrollLeft -= 220;
    } else {
      current.scrollLeft += 220;
    }
  };
  return (
    <div className="community-card-slider-box d-flex flex-column gap-2">
      <p className='listing-subheading'>{props.heading}</p>
      <div className="community-arrow position-relative">
        <div className='community-card-slider d-flex  hide-scrollbar' ref={scrollRef}>
          {props.communities?.map((com, i) =>
            <CommunityCard key={`community${i}`} data={com} />
          )}
          <div className="arrow left-arrow pointer center position-absolute"
            onClick={() => scroll('left')}>
            <ChevronLeftIcon />
          </div>
          <div className="arrow right-arrow pointer center position-absolute"
            onClick={() => scroll('right')}>
            <ChevronRightIcon />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CommunitySlider
