import React, { useEffect, useState } from "react";
import Sidelink from "../dashboard/components/sidebar/Sidelink";
import icons from "../dashboard/components/sidebar/images/SidebarIcon";
import { Form } from "react-bootstrap";
import { SelectOffice, getTeamMemberList } from "./server";
import { useSelector, useDispatch } from "react-redux";
import { getOfficeId, teamList } from "./AgentOfficeSlice";
import { guestOfficeVirtualId } from "../guestVirtualOffice/GuestVirtualOfficeSlice";
import { setLogInOfficeId } from "./AgentOfficeSlice";
import { getUserDetails } from '../../../REST/api';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const OfficeSidebar = (props) => {
  const dispatch = useDispatch();
  const officeId = useSelector((state) => state.virtualOfficeInStore.officeId);
  const LogedInId = useSelector((state) => state.virtualOfficeInStore.tempId);
  const [offices, setoffice] = useState([]);
  let localstorageData = JSON.parse(getUserDetails());
  let userinfo = localstorageData.user_info;
  useEffect(() => {
    getOfficeList();
  }, []);

  useEffect(() => {
    if (userinfo.usertypeobj !== 1) {
      if (officeId) {
        agentList(officeId);
      }
    }
  }, [officeId]);

  const getOfficeList = () => {
    SelectOffice((resp) => {
      if (resp.data.code === 200) {
        setoffice(resp.data.data);
        if (userinfo.usertypeobj === 1) {
          dispatch(guestOfficeVirtualId(LogedInId || resp.data.data[0].id));
        } else {
          dispatch(getOfficeId(LogedInId || resp.data.data[0].id));
        }
      } else {
        setoffice([]);
      }
    });
  };

  const agentList = (id) => {
    getTeamMemberList(
      id,
      (success) => {
        if (success.data.code === 200) {
          dispatch(teamList(success.data.data));
        } else {
          dispatch(teamList([]));
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const handleOfficeId = (e) => {
    if (userinfo.usertypeobj !== 1) {
      dispatch(setLogInOfficeId(parseInt(e.target.value)));
      dispatch(getOfficeId(parseInt(e.target.value)));
      agentList(parseInt(e.target.value));
    } else {
      dispatch(setLogInOfficeId(parseInt(e.target.value)));
      dispatch(guestOfficeVirtualId(parseInt(e.target.value)));
    }
  };
  const setDefaultValue = (e) => {
    const newarray = offices.filter(function (el) {
      return el?.id >= LogedInId;
    });
    return newarray[0]?.id;
  };
  return (
    <>
      {/* <div className={props.pointerEvent ? "sidebar" : "sidebar sidebar-disable"}> */}
      <div className={"sidebar"}>
        <div className="sub-sidebar d-flex flex-column" style={{gap: '50px'}}>
          <div
            className="sidebar-links d-flex flex-column"
            // onClick={() => setSettingoption(false)}
          >
            <Box display={'flex'} px={'14px'} className={'gap-2'} width={'181px'} alignItems={'center'} mx={'0.3rem'}>
              <Box component={'img'} width={'18px'} height={'18px'} src={icons.virtualOffice} alt="" />
              <Typography fontWeight={500} fontSize={'14px'} lineHeight={'14px'} color={'#FFF'}>VIRTUAL OFFICE</Typography>
            </Box>
            <div className="switch-offices ">
              {props.sidebar === true ? (
                <div className="select-offices">
                  <Form.Select
                    size="sm"
                    className="mx-auto"
                    onChange={handleOfficeId}
                    style={{ maxWidth: 150 }}
                    value={setDefaultValue()}
                  >
                    {offices.length > 0
                      ? offices.map((office, index) => {
                          return (
                            <option key={"office" + index} value={office.id}>
                              {office.virtual_office_name}
                            </option>
                          );
                        })
                      : null}
                  </Form.Select>
                </div>
              ) : null}

              {/* <div className="switch-btn mt-2 w-75 mx-auto">
                                <button className=' w-100 border-0'>Switch</button>
                            </div> */}
            </div>

            {props.sidebardata?.map((link, i) => (
              <Sidelink
                icon={link.icon}
                link={link.link}
                title={link.title}
                sidebar={props.sidebar}
                key={`agent${i}`}
              />
            ))}
          </div>

          <div
            className={
              props.settingOption
                ? " position-relative setting-option"
                : " position-relative"
            }
          >
            {/* <div className="setting_Droupdown box-shodow flex-column gap-2 position-absolute" style={{ display: settingoption && result ? 'flex' : "none" }}>
                            <p onClick={() => Gotolink(`/agent-dashboard/profile-settings`)}>Profile Settings</p>
                            <p onClick={() => Gotolink(`/agent-dashboard/plan&billing`)}>Plan & Billing</p>
                        </div> */}
            <div
              className=" pointer"
              // onClick={() => setSettingoption(!settingoption)}
            >
              <Sidelink
                icon={icons.logout}
                link={
                  userinfo.usertypeobj === 1 ? "/dashboard" : "/agent-dashboard"
                }
                title={"Logout"}
                sidebar={props.sidebar}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OfficeSidebar;
