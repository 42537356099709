import axios from 'axios';
import { getRefreshToken, saveAccessToken } from './api';

let isRefreshing = false;
let failedQueue: any = [];

const processQueue = (error: any, token = null) => {
  failedQueue.forEach((prom: any) => {
    if (error) {
      console.error('Processing queue: Error', error);
      prom.reject(error);
    } else {
      console.log('Processing queue: Resolving with token', token);
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    console.error('Interceptor Error:', error.response ? error.response.status : '', error.message);
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      console.log('Handling 401 error');

      if (isRefreshing) {
        console.log('Token refresh in progress, queuing request');
        return new Promise(function(resolve, reject) {
          failedQueue.push({ resolve, reject });
        }).then((token) => {
          console.log('Token refreshed, retrying request');
          originalRequest.headers['Authorization'] = 'Bearer ' + token;
          return axiosInstance(originalRequest);
        }).catch((err) => {
          console.error('Error after token refresh', err);
          return Promise.reject(err);
        });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      const refresh = getRefreshToken();
      if (!refresh) {
        console.error('No refresh token available, requiring login');
        processQueue(new Error('Login required'), null);
        isRefreshing = false;
        return Promise.reject(new Error('Login required'));
      }

      try {
        console.log('Attempting to refresh token');
        const response = await axiosInstance.post('/api/user/refresh-token/', { refresh });
        const { access } = response.data;
        console.log('Token refreshed successfully');
        saveAccessToken(access);
        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${access}`;

        processQueue(null, access);

        isRefreshing = false;
        originalRequest.headers['Authorization'] = `Bearer ${access}`;
        return axiosInstance(originalRequest);
      } catch (error) {
        console.error('Failed to refresh token', error);
        processQueue(error, null);
        isRefreshing = false;
        return Promise.reject(error);
      }
    }

    return Promise.reject(error);
  },
);

export default axiosInstance;
