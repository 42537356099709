import { createSelector } from 'reselect';

const landingSelector = (state: any) => state.landing;

export const getResidentialNearestCity = createSelector([landingSelector], ({ nearestResidentialCityCity }) => {
  return nearestResidentialCityCity;
});

export const getCommercialNearestCity = createSelector([landingSelector], ({ nearestCommercialCityCity }) => {
  return nearestCommercialCityCity;
});

export const getResentListing = createSelector([landingSelector], ({ recentListing }) => {
  return recentListing;
});

export const getPropertiesType = createSelector([landingSelector], ({ propertiesType }) => {
  return propertiesType;
});
