import { getToken } from "../../../../appVariable/variable"
import axiosInstance from '../../../../REST/axiosInstance';


export function getGuestDetails(success) {

    let token = getToken()
    let userId = token?.userId;
    axiosInstance
        .get(`api/user/guest-User-Profile/${userId}`, {
            headers: {
                'Authorization': `Bearer ${token?.idToken}`
            }
        })
        .then((response) => {
            if (response.data.code === 200 && response.data.status === "success") {
                success(response.data.data);
            }
        })
        .catch((error) => {
            console.log(error);
        });
}

export function submitProfileData(formData, success) {
    let token = getToken()
    axiosInstance.put(`api/user/guest-User-Profile/`, formData, {
        headers: { 'Authorization': `Bearer ${token?.idToken}` }
    })
        .then((response) => {
            if (response.data.code === 200 && response.data.status === "success") {
                success(response.data.data);
            }
        })
        .catch((error) => {
            console.log(error);
        });
}

export function getAllstate(success) {
    let token = getToken()
    axiosInstance
        .get(`api/master/state/`, {
            headers: {
                'Authorization': `Bearer ${token?.idToken}`
            }
        })
        .then((response) => {
            if (response.data.code === 200 && response.data.status === "success") {
                success(response.data.data);
            }
        })
        .catch((error) => {
            console.log(error);
        });
}


export async function getAllzipcode(success) {
    let token = getToken()
    await axiosInstance
        .get(`api/master/zipcode/`, {
            headers: { 'Authorization': `Bearer ${token?.idToken}`}
        })
        .then((response) => {
            if (response.data.code === 200 && response.data.status === "success") {
                success(response.data.data);
            }
        })
        .catch((error) => {
            console.log(error);
        });
}

export function getAllcity(success) {
    let token = getToken()
    axiosInstance
        .get(`api/master/city/`, {
            headers: { 'Authorization': `Bearer ${token?.idToken}` }
        })
        .then((response) => {
            if (response.data.code === 200 && response.data.status === "success") {
                success(response.data.data);
            }
        })
        .catch((error) => {
            console.log(error);
        });
}

export function getAllArea(success) {
    let token = getToken()
    axiosInstance
        .get(`api/master/area/`, {
            headers: {
                'Authorization': `Bearer ${token?.idToken}`
            }
        })
        .then((response) => {
            if (response.data.code === 200 && response.data.status === "success") {
                success(response.data.data);
            }
        })
        .catch((error) => {
            console.log(error);
        });
}