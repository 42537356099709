import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from "react-redux";
import {
    // getSearch, getSearchWithUserId, updateMyValue, showCommercialPopup,
    // hideCommercialPopup,
    // showResidentialPopup,
    // hideResidentialPopup,
    // showSaveSearchPopup,
    hideSaveSearchPopup
} from '../../../homepage/components/hero/HeroSlice';
import { getToken } from '../../../../../appVariable/variable';
import { saveSearch } from '../../../homepage/components/hero/server';
import { updateFilterData } from '../../../homepage/components/hero/FilterSlice';
const SaveSearchModel = (props) => {
    const dispatch = useDispatch();
    // const { Filterformik, handleSaveSearch } = props;
    // const getCategoriesVal = useSelector(state => state.search.category);
    // const commercialPopupVisible = useSelector((state) => state.search.commercialPopupVisible);
    const FilterDataInStore = useSelector(state => state.FilterDataInStore);
    const saveSearchPopupVisible = useSelector((state) => state.search.saveSearchPopupVisible);
    const token = getToken()
    const userId = token?.userId;

    const saveSearchfunc = (event) => {
        event.preventDefault();
        if (FilterDataInStore.property_listing && FilterDataInStore.category && FilterDataInStore.type) {
            const blockedKeys = ['locationText', 'Filter_responce', 'filter_obj', 'property_listing', 'Neighborhood'];
            const saveSchema = { ...FilterDataInStore, user_id: userId };
            blockedKeys.forEach((key) => delete saveSchema[key]);
            saveSearch(saveSchema, (success) => {
                dispatch(updateFilterData({ filter_name: "" }));
                dispatch(hideSaveSearchPopup())
            })
        }
    }



    return (
        <div>
            <Modal className='dashboard-filter-popup' size="lg" centered
                show={saveSearchPopupVisible} onHide={() => dispatch(hideSaveSearchPopup())}
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton> </Modal.Header>
                <Modal.Body>
                    <div className="d-flex flex-column gap-4 w-100">
                        <div className="price-sec  filter-row ">
                            <p className='filter-heading'>Name</p>
                            <div className="price-input-box">
                                <div className="enter-input inner-shadow center flex-grow-1" style={{ maxWidth: 'inherit' }} >
                                    <input type="text" id="" placeholder='Name' name="filtername"
                                        onChange={(e) => { dispatch(updateFilterData({ filter_name: e.target.value })); }}
                                    // onChange={(e) => { setSaveNote(e.target.value); }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="filter-btn-grp d-flex gap-4 justify-content-end">
                            <button className='btn' onClick={saveSearchfunc} disabled={!FilterDataInStore.filter_name} >Save Search</button>
                            {/* {userId ? <button className='btn' onClick={saveSearchfunc} >Save Search</button> : ""} */}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default SaveSearchModel
