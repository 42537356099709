import React from 'react';
import { styles } from './styles';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';

type TabTitleProps = {
  cityName: string;
  chosenTowns: number;
};

export const TabTitle: React.FC<TabTitleProps> = ({ cityName, chosenTowns }) => {

  return (
    <Box display={'flex'} alignItems={'center'} gap={'6px'}>
      <Typography>{cityName}</Typography>
      {!!chosenTowns && (
        <Box display={'flex'} sx={styles.chosenTowns}>
          <Typography variant={'caption'} color={'#FFF'} fontWeight={600} lineHeight={1}>{chosenTowns}</Typography>
        </Box>
      )}
    </Box>
  );
};
