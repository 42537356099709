import React from "react";
import { getToken } from "../../../appVariable/variable";
import { useNavigate } from "react-router-dom";
import { IoMdExit } from "react-icons/io";
import { Rating } from "@mui/material";
import { useDispatch } from "react-redux";
import { setLogInOfficeId } from "../agentVirtualOffice/AgentOfficeSlice";
import axiosInstance from '../../../REST/axiosInstance';

const GuestVirtualCard = ({ info, setLoading, getVirtualOffice }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleVirtualLogin = (e) => {
    dispatch(setLogInOfficeId(info?.id));
    navigate(`/guest-virtual-office`);
  };
  const exitVirtualOffice = (id) => {
    setLoading(true);
    let token = getToken();
    let obj = {};
    obj.virtual_id = id;
    obj.user_id = token.userId;
    axiosInstance
      .post(`api/virtual_office/ExitVirtualOffice/`, obj, {
        headers: { Authorization: `Bearer ${token?.idToken}` },
      })
      .then((response) => {
        console.log(response);
        if (response.data.code === 200) {
          getVirtualOffice();
          setLoading(true);
        } else {
          setLoading(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(true);
      });
  };

  return (
    <div className="virtualoffice-card">
      <div className="p-4 left_carD card_border">
        <div className="d-flex justify-content-between align-items-start mb-2">
          <div className="d-flex gap-1 align-items-center">
            <img
              src={`${info.userprofile?.profile_image}`}
              alt=""
              style={{
                width: "60px",
                height: "60px",
                borderRadius: "50%",
                border: "1px solid #4E62B1",
                padding: "5px",
              }}
            />
            <Rating
              className="rating"
              name="simple-controlled"
              value={info.userprofile?.rating}
              readOnly
            />
          </div>
          <div
            className="text-end pointer"
            onClick={() => exitVirtualOffice(info.id)}
          >
            <span style={{ fontSize: "23px", color: "#4E62B1" }}>
              <IoMdExit />
            </span>
          </div>
        </div>

        <div>
          <span className="Virtual_Office pe-2">Virtual Office Name :</span>
          <span className="leo_text">{info.virtual_office_name}</span>
        </div>
        <div>
          <span className="Virtual_Office pe-2">First Name :</span>
          <span className="leo_text">{info.userprofile?.first_name}</span>
        </div>
        <div>
          <span className="Virtual_Office pe-2">Last Name :</span>
          <span className="leo_text">{info.userprofile?.last_name}</span>
        </div>
        <div>
          <span className="Virtual_Office pe-2">Language :</span>
          <span className="leo_text">
            {info.userprofile?.language_name
              ? info.userprofile.language_name
                  .map(({ languages_name }) => languages_name)
                  .join(", ")
              : ""}
          </span>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 d-grid ">
          <button
            className="btn-blockbtn blue__btn btn-block save_btnn"
            onClick={(e) => handleVirtualLogin(e)}
          >
            {" "}
            Log in
          </button>
        </div>
      </div>
    </div>
  );
};

export default GuestVirtualCard;
