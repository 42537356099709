import React, { Suspense, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import PrivateRoutes from './PrivateRoutes';
import PublicRoutes from './PublicRoutes';
import { useDispatch, useSelector } from 'react-redux';
import { getIsAuthenticate, getIsInitialize } from '@bus/auth/selectors';
import { authActions } from '@bus/auth/actions';
import Box from '@mui/material/Box';
import { CircularProgress } from '@mui/material';
import Loader from '@components/pages/Loader/Loader';
import { useGetUserData } from '../hooks/useGetUserData';
import { dashboardActions } from '@bus/dashboard/actions';
import { search2Actions } from '@bus/search2/actions';

const Routers = () => {
  const isInitialised = useSelector(getIsInitialize);
  const isAuthenticated = useSelector(getIsAuthenticate);
  const dispatch = useDispatch()
  useGetUserData();

  useEffect(() => {
    dispatch(authActions.authenticate());
    dispatch(dashboardActions.fetchPropertyCondition());
    dispatch(dashboardActions.fetchClassType());
    dispatch(search2Actions.fetchLeaseType());
  }, [dispatch]);

  return isInitialised ? (
    <Suspense fallback={<Loader />}>
      <Routes>
        {isAuthenticated ? (
          <Route path={`/*`} element={<PrivateRoutes />} />
        ) : (
          <Route path={`/*`} element={<PublicRoutes />} />
        )}
      </Routes>
    </Suspense>
  ) : (
    <Box
      width={'100vw'}
      height={'100vh'}
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}>
      <CircularProgress />
    </Box>
  );
};

export default Routers;
