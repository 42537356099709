import {
  api,
} from '@REST/api';

import * as effects from 'redux-saga/effects';
import {
  DeleteAreaActionPayload,
} from '@bus/neighborhood/typedefs';
import { neighborhoodActions } from '@bus/neighborhood/actions';
import { PayloadActionWithPromiseMeta } from '@core/FinalForm/typedefs';

export function* deleteAreas({
 payload,
 meta: { resolve, reject },
}: PayloadActionWithPromiseMeta<DeleteAreaActionPayload>){
  try {
    yield effects.put(neighborhoodActions.startFetching());
    const response: Response = yield effects.apply(api, api.delete, [
      { endpoint: `api/user/neighborhoodspecialisarea/${payload.id}/` },
    ]);

    if (!response.ok) {
      reject();
      throw new Error('Unhandled error');
    }

    yield effects.put(neighborhoodActions.removeArea({ id: payload.id }));
    resolve();
  } catch (e) {
  } finally {
    yield effects.put(neighborhoodActions.finishFetching());
  }
}
