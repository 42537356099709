import React from 'react';
import { FieldRenderProps } from 'react-final-form';

import { Box, TextField } from '@mui/material';

import { getFieldError } from '@core/FinalForm/getFieldError';
import { styles } from '@components/forms/SubmitRequestForAdvertising/styles';
import Typography from '@mui/material/Typography';

export const RenderPriceMaxField: React.FC<FieldRenderProps<number>> = ({
  input,
  meta,
}) => {
  return (
    <div className="enter-input inner-shadow  center flex-grow-1 ">
      <input
        type="text"
        id=""
        placeholder="Price Max"
        {...input}
      />
    </div>
  );
};
