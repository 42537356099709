import { isToday, format, isYesterday, isThisWeek } from "date-fns";

export const useFormatMessageLastDate = (date: Date | null) => {
	if (!(date instanceof Date)) return 'Invalid date';

	switch (true) {
		case isToday(date): return format(date, 'hh.mm a');
		case isYesterday(date): return `yesterday at ${format(date, 'hh.mm a')}`;
		case isThisWeek(date): return format(date, 'E');
		default: return format(date, 'dd.MM.yyyy');
	}
}
