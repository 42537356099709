import { getToken } from "../../../../../appVariable/variable";
import axiosInstance from '../../../../../REST/axiosInstance';

export function ThirtyMin(tempObj, success) {
    let token = getToken();
  axiosInstance.post(`api/user/min_30_view/`, tempObj, { headers: { Authorization: `Bearer ${token?.idToken}`, }, })
        .then((response) => {
            success(response)
        })
        .catch((error) => {
            console.log(error);
        });
}

export function DeactivateTime(success) {
    let token = getToken();
  axiosInstance.put(`api/user/ActivateDeactivate30Min/${token?.profile.slug}`, { headers: { Authorization: `Bearer ${token?.idToken}`, }, })
        .then((response) => { success(response.data) })
        .catch((error) => { console.log(error); });
}


export function getMinutedata(success) {
    let token = getToken()
    let userSlug = token?.profile.slug;
  axiosInstance.get(`api/user/min_30_view/${userSlug}`, { headers: { 'Authorization': `Bearer ${token?.idToken}` } })
        .then((response) => {
            if (response.data.code === 200 && response.data.status === "success") {
                success(response.data.data);
            } else {
                success(null)
            }
        })
        .catch((error) => {
            console.log(error);
        });
}
