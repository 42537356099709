import React from 'react';
import { FieldRenderProps } from 'react-final-form';

export const RenderEmailField: React.FC<FieldRenderProps<number>> = ({
  input,
  meta,
}) => {
  return (
    <div className="form-group pb-3 mb-1">
      <label htmlFor="" className='lable_text'>Email</label>
      <input type="text" className="form-control mt-1" {...input} />
    </div>
  );
};
