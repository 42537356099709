import ad from "./images/ad.jpg"
import { useNavigate } from 'react-router';

const AdCardGuest = (props) => {
  const { Memberinfo } = props

  return (
    <a href={Memberinfo.link} target={'_blank'} className="ad-cardUser position-relative pointer">
      <img className=' h-100' src={Memberinfo ? `${Memberinfo?.profile_image}` : ad} alt="" />
      <div className="ad-name position-absolute w-100 center">
        <p>{Memberinfo ? `${Memberinfo?.first_name} ${Memberinfo?.last_name}` : ""}</p></div>
      <div className="ad-title position-absolute w-100 center">
        <p>
          ({Memberinfo?.user_type?.user_type === 2 ? "Advertisment" : "User"})
        </p>
      </div>
    </a>
  )
}

export default AdCardGuest
