import React, { lazy } from 'react';

import { AppLayout } from '@layouts/index';
import { book } from '@routes/book';

import { AppRoute, UserRole } from '@store/typedefs';
import { commonRoutes } from '@pages/common/routes';
const AdvertisementHomePage = lazy(() => import('@components/pages/advertisement-flow/Home_Page/AdvertisementHomePage'));
const Documents = lazy(() => import('@components/pages/Documents/Documents'));
const DocumentUpload = lazy(() => import('@components/pages/Documents/components/DocumentUpload'));
const MarketingPlans = lazy(() => import('@components/pages/boost-marketing/components/marketing-plans/MarketingPlans'));
const MarketingFormWithDescription = lazy(() => import('@components/pages/boost-marketing/components/marketing-form/MarketingFormWithDescription'));
const AgentCustomerCards = lazy(() => import('@components/pages/agentCustomerVirtualOffice/AgentCustomerCards'));
const VirtualDocument = lazy(() => import('@components/pages/agentVirtualOffice/components/document/VirtualDocument'));
const Create_ADS_page = lazy(() => import('@components/pages/advertisement-flow/component/ADS_Page/Create_ADS_page'));
const Adv_Invoice_Table = lazy(() => import('@components/pages/advertisement-flow/component/Invois_Page/Adv_Invoice_Table'));
const Adv_Lead_Page = lazy(() => import('@components/pages/advertisement-flow/component/Lead_page/Adv_Lead_Page'));
const AgentVirtualDashboard = lazy(() => import('@components/pages/agentVirtualOffice/components/dashboard/AgentVirtualDashboard'));
const AgentInterestedProperty = lazy(() => import('@components/pages/agentVirtualOffice/components/interested/AgentInterestedProperty'));
const AgentNotInterestedProperty = lazy(() => import('@components/pages/agentVirtualOffice/components/dontlike/AgentNotInterestedProperty'));
const GuestVirtualDashboard = lazy(() => import('@components/pages/dashboard/guest-virtual-dashboard/GuestVirtualDashboard'));
const GuestVirtualOfficeDashboard = lazy(() => import('@components/pages/guestVirtualOffice/components/GuestVirtualOfficeDashboard'));
const GuestVirtualOfficeAgent = lazy(() => import('@components/pages/guestVirtualOffice/components/GuestVirtualOfficeAgentList'));
const GuestVirtualOfficeDocument = lazy(() => import('@components/pages/Documents/components/Sharedocuments'));
const DocumentSign = lazy(() => import('@components/pages/Documents/components/DocumentSign'));
const GuestVirtualOfficeMyMessages = lazy(() => import('@components/pages/guestVirtualOffice/components/GuestVirtualOfficeMyMessages'));
const GuestVirtualOfficeInterested = lazy(() => import('@components/pages/guestVirtualOffice/components/GuestVirtualOfficeInterested'));
const GuestVirtualOfficeNotInterested = lazy(() => import('@components/pages/guestVirtualOffice/components/GuestVirtualOfficeNotInterested'));
const LandLoadDashboard = lazy(() => import('@components/pages/LandloadDashboard/LandLoadDashboard'));
const LandoadSetting = lazy(() => import('@components/pages/LandloadDashboard/components/LandoadSetting'));
const Dashboard = lazy(() => import('@components/pages/dashboard/Dashboard'));
const Message = lazy(() => import('@components/pages/Message/Message'));
const Profile = lazy(() => import('@components/pages/profile/Profile'));
const Reviews = lazy(() => import('@components/pages/reviews/Reviews'));
const SavedListings = lazy(() => import('@components/pages/saved-listings/SavedListings'));
const SaveSearch = lazy(() => import('@components/pages/saveSearch/SaveSearch'));
const Subscription = lazy(() => import('@components/pages/Subscription/Subscription'));
const Support = lazy(() => import('@components/pages/support/Support'));
const VirtualOffice = lazy(() => import('@components/pages/virtual-office/VirtualOffice'));
const BoostMarketing = lazy(() => import('@components/pages/boost-marketing/BoostMarketing'));
const CurrentListing = lazy(() => import('@components/pages/currentListing/CurrentListing'));
const ExpiredListing = lazy(() => import('@components/pages/expiredListing/ExpiredListing'));
const AllSelPerson = lazy(() => import('@components/pages/allSelPerson/AllSelPerson'));
const CommonmarketingForm = lazy(() => import('@components/pages/boost-marketing/components/marketing-form/CommonmarketingForm'));
const SocialAdForm = lazy(() => import('@components/pages/boost-marketing/components/social-media/SocialAdForm'));
const PaidMediaForm = lazy(() => import('@components/pages/boost-marketing/components/paid-media/PaidMediaForm'));
const PersonalInfoPage = lazy(() => import('@components/pages/personal/PersonalInfoPage'));
const AgentDashboard = lazy(() => import('@components/pages/dashboard/agent-dashboard/AgentDashboard'));
const PropertyDetails = lazy(() => import('@components/pages/PropertyDetails/PropertyDetails'));
const MapviewFilter = lazy(() => import('@components/pages/mapview-filter/MapviewFilter'));
const CheckOut = lazy(() => import('@components/pages/check-out-page/CheckOut'));
const Neighbourhoodmains = lazy(() => import('@components/pages/neighbourhoodspecialist/Neighbourhoodmains'));
const OpenListing = lazy(() => import('@components/pages/open-listing/OpenListing'));
const Agentprofile = lazy(() => import('@components/pages/agentbio/Agentprofile'));
const Analytics = lazy(() => import('@components/pages/analytics/Analytics'));
const MapViewCommfilter = lazy(() => import('@components/pages/mapview-filter/components/commertial/MapViewCommfilter'));
const QuikView = lazy(() => import('@components/pages/dashboard/quickView/QuikView'));
const AdvertisingMain = lazy(() => import('@components/Advertising-Marketing-Pages/AdvHome-Pages/AdvertisingMain'));
const AgentAddProperty = lazy(() => import('@components/pages/agent-dash-sales/AgentAddProperty'));
const ProfileSetResetPass = lazy(() => import('@components/pages/profile/components/password/ProfileSetResetPass'));
const EditProperty = lazy(() => import('@components/pages/agent-dash-sales/EditProperty'));
const VirtualContact = lazy(() => import('@components/pages/agentVirtualOffice/components/VirtualContact'));
const EditContact = lazy(() => import('@components/pages/agentVirtualOffice/components/editContact/EditContact'));
const OfficeDashboard = lazy(() => import('@components/pages/agentVirtualOffice/OfficeDashboard'));
const OfficeMapView = lazy(() => import('@components/pages/agentVirtualOffice/components/officeMapView/OfficeMapView'));
const PropertyDetailsPopUp = lazy(() => import('@components/pages/dashboard/propperty-datails/PropertyDetailsPopUp'));
const ShareProperty = lazy(() => import('@components/pages/shareProperty/ShareProperty'));
const MapViewCurrentListing = lazy(() => import('@components/pages/agentbio/current-MapListing/MapViewCurrentListing'));
export const privateRoutes: AppRoute<any>[] = [
  {
    path: book.propertyId,
    Component: <ShareProperty />,
    Layout: AppLayout,
    layoutProps: {},
    allowedRoles: [UserRole.customer, UserRole.agentAndBroker, UserRole.developersPropertyInvestors, UserRole.FRBHosFSBHOs, UserRole.landlordPropertyManagers, UserRole.advertisers],
  },
  {
    path: book.advertising,
    Component: <AdvertisingMain />,
    Layout: AppLayout,
    layoutProps: {},
    allowedRoles: [UserRole.customer, UserRole.agentAndBroker, UserRole.developersPropertyInvestors, UserRole.FRBHosFSBHOs, UserRole.landlordPropertyManagers, UserRole.advertisers],
  },
  {
    path: book.agentProfile,
    Component: <Agentprofile />,
    Layout: AppLayout,
    layoutProps: {},
    allowedRoles: [UserRole.customer, UserRole.agentAndBroker, UserRole.developersPropertyInvestors, UserRole.FRBHosFSBHOs, UserRole.landlordPropertyManagers, UserRole.advertisers],
  },
  {
    path: book.advertisement,
    Component: <AdvertisementHomePage />,
    Layout: AppLayout,
    layoutProps: {},
    allowedRoles: [UserRole.customer, UserRole.agentAndBroker, UserRole.developersPropertyInvestors, UserRole.FRBHosFSBHOs, UserRole.landlordPropertyManagers, UserRole.advertisers],
  },
  {
    path: book.subscription,
    Component: <Subscription />,
    Layout: AppLayout,
    layoutProps: {},
    allowedRoles: [UserRole.customer, UserRole.agentAndBroker, UserRole.developersPropertyInvestors, UserRole.FRBHosFSBHOs, UserRole.landlordPropertyManagers, UserRole.advertisers],
  },
  {
    path: book.checkOut,
    Component: <CheckOut />,
    Layout: AppLayout,
    layoutProps: {},
    allowedRoles: [UserRole.customer, UserRole.agentAndBroker, UserRole.developersPropertyInvestors, UserRole.FRBHosFSBHOs, UserRole.landlordPropertyManagers, UserRole.advertisers],
  },
  {
    path: `${book.dashboard}/*`,
    Component: <Dashboard />,
    Layout: Dashboard,
    layoutProps: {},
    children: [
      {
        path: `${book.dashboardQuickView}`,
        Component: <QuikView />,
        layoutProps: {},
        childAllowedRoles: [UserRole.customer, UserRole.developersPropertyInvestors, UserRole.FRBHosFSBHOs, UserRole.landlordPropertyManagers, UserRole.advertisers],
      },
      {
        path: `${book.dashboardQuickViews}`,
        Component: <QuikView />,
        layoutProps: {},
      },
      {
        path: `${book.dashboardQuickViewId}`,
        Component: <QuikView />,
        layoutProps: {},
      },
      {
        path: `${book.dashboardSavedListings}`,
        Component: <SavedListings />,
        layoutProps: {},
      },
      {
        path: `${book.dashboardExpireListing}`,
        Component: <ExpiredListing />,
        layoutProps: {},
      },
      {
        path: `${book.dashboardSavedSearch}`,
        Component: <SaveSearch />,
        layoutProps: {},
      },
      {
        path: `${book.dashboardMessage}`,
        Component: <Message />,
        layoutProps: {},
      },
      {
        path: `${book.dashboardReviews}`,
        Component: <Reviews />,
        layoutProps: {},
      },
      {
        path: `${book.dashboardSupport}`,
        Component: <Support />,
        layoutProps: {},
      },
      {
        path: `${book.dashboardVirtualOffice}`,
        Component: <VirtualOffice />,
        layoutProps: {},
      },
      {
        path: `${book.dashboardProfileSetting}`,
        Component: <PersonalInfoPage />,
        layoutProps: {},
      },
      {
        path: `${book.dashboardMapviewFilter}`,
        Component: <MapviewFilter />,
        layoutProps: {},
      },
      {
        path: `${book.dashboardMapviewCommercialFilter}`,
        Component: <MapViewCommfilter />,
        layoutProps: {},
      },
      {
        path: `${book.dashboardOpenListing}`,
        Component: <OpenListing />,
        layoutProps: {},
      },
      {
        path: `${book.dashboardCurrentListing}`,
        Component: <CurrentListing />,
        layoutProps: {},
      },
      {
        path: `${book.dashboardAllSalesPerson}`,
        Component: <AllSelPerson />,
        layoutProps: {},
      },
      {
        path: `${book.dashboardPersonalInformation}`,
        Component: <PersonalInfoPage />,
        layoutProps: {},
      },
      {
        path: `${book.dashboardPropertyDetails}`,
        Component: <PropertyDetails />,
        layoutProps: {},
      },
      {
        path: `${book.dashboardPropertyDetailsPopUp}`,
        Component: <PropertyDetailsPopUp />,
        layoutProps: {},
      },
    ],
    allowedRoles: [UserRole.customer],
  },
  {
    path: `${book.agentDashboard}/*`,
    Component: <AgentDashboard />,
    Layout: AgentDashboard,
    layoutProps: {},
    children: [
      {
        path: `${book.agentDashboardAddProperty}`,
        Component: <AgentAddProperty />,
        layoutProps: {},
      },
      {
        path: `${book.agentDashboardCheckOut}`,
        Component: <CheckOut />,
        layoutProps: {},
      },
      {
        path: `${book.agentDashboardProfileSettings}`,
        Component: <Profile />,
        layoutProps: {},
      },
      {
        path: `${book.agentDashboardAgentProfile}`,
        Component: <Agentprofile />,
        layoutProps: {},
      },
      {
        path: `${book.agentDashboardEditProperty}`,
        Component: <EditProperty />,
        layoutProps: {},
      },
      {
        path: `${book.agentDashboardVirtualOffice}`,
        Component: <VirtualOffice />,
        layoutProps: {},
      },
      {
        path: `${book.agentDashboardSavedListings}`,
        Component: <SavedListings />,
        layoutProps: {},
      },
      {
        path: `${book.agentDashboardExpireListing}`,
        Component: <ExpiredListing />,
        layoutProps: {},
      },
      {
        path: `${book.agentDashboardAllSalesPerson}`,
        Component: <AllSelPerson />,
        layoutProps: {},
      },
      {
        path: `${book.agentDashboardAnalytic}`,
        Component: <Analytics />,
        layoutProps: {},
      },
      {
        path: `${book.agentDashboardReview}`,
        Component: <Reviews />,
        layoutProps: {},
      },
      {
        path: `${book.agentDashboardSupport}`,
        Component: <Support />,
        layoutProps: {},
      },
      {
        path: `${book.agentDashboardProfileSettingsResetPassword}`,
        Component: <ProfileSetResetPass />,
        layoutProps: {},
      },
      {
        path: `${book.agentDashboardPlanAndBilling}`,
        Component: <Neighbourhoodmains />,
        layoutProps: {},
      },
      {
        path: `${book.agentDashboardMessage}`,
        Component: <Message />,
        layoutProps: {},
      },
      {
        path: `${book.agentDashboardDocument}`,
        Component: <Documents />,
        layoutProps: {},
      },
      {
        path: `${book.agentDashboardDocumentUpload}`,
        Component: <DocumentUpload />,
        layoutProps: {},
      },
      {
        path: `${book.agentDashboardNeighbourhood}`,
        Component: <Neighbourhoodmains />,
        layoutProps: {},
      },
      {
        path: `${book.agentDashboardBoostMarketing}`,
        Component: <BoostMarketing />,
        layoutProps: {},
      },
      {
        path: `${book.agentDashboardBoostMarketingPlans}`,
        Component: <MarketingPlans />,
        layoutProps: {},
      },
      {
        path: `${book.agentDashboardMarketingForm}`,
        Component: <CommonmarketingForm />,
        layoutProps: {},
      },
      {
        path: `${book.agentDashboardBoostMarketingForm}`,
        Component: <MarketingFormWithDescription />,
        layoutProps: {},
      },
      {
        path: `${book.agentDashboardBoostMarketingSocialMediaForm}`,
        Component: <SocialAdForm />,
        layoutProps: {},
      },
      {
        path: `${book.agentDashboardBoostMarketingPaidMediaForm}`,
        Component: <PaidMediaForm />,
        layoutProps: {},
      },
      {
        path: `${book.agentDashboardCurrentListing}`,
        Component: <CurrentListing />,
        layoutProps: {},
      },
      {
        path: `${book.agentDashboardCurrentListingMapview}`,
        Component: <MapViewCurrentListing />,
        layoutProps: {},
      },
      {
        path: `${book.agentDashboardMapviewFilter}`,
        Component: <MapviewFilter />,
        layoutProps: {},
      },
      {
        path: `${book.agentDashboardMapviewCommercialFilter}`,
        Component: <MapViewCommfilter />,
        layoutProps: {},
      },
    ],
    allowedRoles: [UserRole.agentAndBroker],
  },
  {
    path: `${book.agentVirtualOffice}/*`,
    Component: <OfficeDashboard />,
    Layout: OfficeDashboard,
    layoutProps: {},
    children: [
      {
        path: `${book.agentVirtualOfficeContact}`,
        Component: <VirtualContact />,
        layoutProps: {},
      },
      {
        path: `${book.agentVirtualOfficeContactId}`,
        Component: <VirtualContact />,
        layoutProps: {},
      },
      {
        path: `${book.agentVirtualOfficeContactEdit}`,
        Component: <EditContact />,
        layoutProps: {},
      },
      {
        path: `${book.agentVirtualOfficeMapView}`,
        Component: <OfficeMapView />,
        layoutProps: {},
      },
      {
        path: `${book.agentVirtualOfficeCustomerInfo}`,
        Component: <AgentCustomerCards />,
        layoutProps: {},
      },
      {
        path: `${book.agentVirtualOfficeMessage}`,
        Component: <Message />,
        layoutProps: {},
      },
      {
        path: `${book.agentVirtualOfficeDocument}`,
        Component: <VirtualDocument />,
        layoutProps: {},
      },
      {
        path: `${book.agentVirtualOfficeDocumentUpload}`,
        Component: <DocumentUpload />,
        layoutProps: {},
      },
      {
        path: `${book.agentVirtualOfficeCreateADSPage}`,
        Component: <Create_ADS_page />,
        layoutProps: {},
      },
      {
        path: `${book.agentVirtualOfficeAdvInvoiceTable}`,
        Component: <Adv_Invoice_Table />,
        layoutProps: {},
      },
      {
        path: `${book.agentVirtualOfficeAdvLeadPage}`,
        Component: <Adv_Lead_Page />,
        layoutProps: {},
      },
      {
        path: `${book.agentVirtualOfficeDashboard}`,
        Component: <AgentVirtualDashboard />,
        layoutProps: {},
      },
      {
        path: `${book.agentVirtualOfficeInterestedProperty}`,
        Component: <AgentInterestedProperty />,
        layoutProps: {},
      },
      {
        path: `${book.agentVirtualOfficeDislikeProperty}`,
        Component: <AgentNotInterestedProperty />,
        layoutProps: {},
      },
    ],
    allowedRoles: [UserRole.agentAndBroker],
  },
  {
    path: `${book.guestVirtualOffice}/*`,
    Component: <GuestVirtualDashboard />,
    Layout: GuestVirtualDashboard,
    layoutProps: {},
    children: [
      {
        path: `${book.guestVirtualOfficeHome}`,
        Component: <GuestVirtualOfficeDashboard />,
        layoutProps: {},
      },
      {
        path: `${book.guestVirtualOfficeDashboard}`,
        Component: <GuestVirtualOfficeDashboard />,
        layoutProps: {},
      },
      {
        path: `${book.guestVirtualOfficeAgents}`,
        Component: <GuestVirtualOfficeAgent />,
        layoutProps: {},
      },
      {
        path: `${book.guestVirtualOfficeDocument}`,
        Component: <GuestVirtualOfficeDocument />,
        layoutProps: {},
      },
      {
        path: `${book.guestVirtualOfficeDocumentSign}`,
        Component: <DocumentSign />,
        layoutProps: {},
      },
      {
        path: `${book.guestVirtualOfficeMyMessage}`,
        Component: <GuestVirtualOfficeMyMessages />,
        layoutProps: {},
      },
      {
        path: `${book.guestVirtualOfficeInterestedProperty}`,
        Component: <GuestVirtualOfficeInterested />,
        layoutProps: {},
      },
      {
        path: `${book.guestVirtualOfficeDislikeProperty}`,
        Component: <GuestVirtualOfficeNotInterested />,
        layoutProps: {},
      },
      {
        path: `${book.guestVirtualOfficeAgentsDetails}`,
        Component: <Agentprofile />,
        layoutProps: {},
      },
    ],
    allowedRoles: [UserRole.customer],
  },
  {
    path: `${book.landLoadDashboard}/*`,
    Component: <LandLoadDashboard />,
    Layout: LandLoadDashboard,
    layoutProps: {},
    children: [
      {
        path: `${book.landLoadDashboardAddProperty}`,
        Component: <AgentAddProperty />,
        layoutProps: {},
      },
      {
        path: `${book.landLoadDashboardEditProperty}`,
        Component: <EditProperty />,
        layoutProps: {},
      },
      {
        path: `${book.landLoadDashboardCurrentListing}`,
        Component: <CurrentListing />,
        layoutProps: {},
      },
      {
        path: `${book.landLoadDashboardCurrentListingMapview}`,
        Component: <MapViewCurrentListing />,
        layoutProps: {},
      },
      {
        path: `${book.landLoadDashboardExpireListing}`,
        Component: <ExpiredListing />,
        layoutProps: {},
      },
      {
        path: `${book.landLoadDashboardMessage}`,
        Component: <Message />,
        layoutProps: {},
      },
      {
        path: `${book.landLoadDashboardBoostMarketing}`,
        Component: <BoostMarketing />,
        layoutProps: {},
      },
      {
        path: `${book.landLoadDashboardBoostMarketingPlans}`,
        Component: <MarketingPlans />,
        layoutProps: {},
      },
      {
        path: `${book.landLoadDashboardMarketingForm}`,
        Component: <CommonmarketingForm />,
        layoutProps: {},
      },
      {
        path: `${book.landLoadDashboardBoostMarketingForm}`,
        Component: <MarketingFormWithDescription />,
        layoutProps: {},
      },
      {
        path: `${book.landLoadDashboardBoostMarketingSocialMediaForm}`,
        Component: <SocialAdForm />,
        layoutProps: {},
      },
      {
        path: `${book.landLoadDashboardBoostMarketingPaidMediaForm}`,
        Component: <PaidMediaForm />,
        layoutProps: {},
      },
      {
        path: `${book.landLoadDashboardVirtualOffice}`,
        Component: <VirtualOffice />,
        layoutProps: {},
      },
      {
        path: `${book.landLoadDashboardAnalytic}`,
        Component: <Analytics />,
        layoutProps: {},
      },
      {
        path: `${book.landLoadDashboardReview}`,
        Component: <Reviews />,
        layoutProps: {},
      },
      {
        path: `${book.landLoadDashboardSupport}`,
        Component: <Support />,
        layoutProps: {},
      },
      {
        path: `${book.landLoadDashboardProfileSetting}`,
        Component: <LandoadSetting />,
        layoutProps: {},
      },
      {
        path: `${book.landLoadDashboardProfileSettingsResetPassword}`,
        Component: <ProfileSetResetPass />,
        layoutProps: {},
      },
      {
        path: `${book.landLoadDashboardPlanAndBilling}`,
        Component: <Neighbourhoodmains />,
        layoutProps: {},
      },
      {
        path: `${book.landLoadDashboardMapviewFilter}`,
        Component: <MapviewFilter />,
        layoutProps: {},
      },
      {
        path: `${book.landLoadDashboardMapviewCommercialFilter}`,
        Component: <MapViewCommfilter />,
        layoutProps: {},
      },
    ],
    allowedRoles: [UserRole.developersPropertyInvestors, UserRole.FRBHosFSBHOs, UserRole.landlordPropertyManagers, UserRole.advertisers],
  },
  ...commonRoutes,
];