import * as yup from 'yup';

export const schema = yup.object().shape({
  first_name: yup.string().required('This field can not be empty'),
  last_name: yup.string().required('This field can not be empty'),
  phone: yup.string().min(10, 'minimum 10 digits').required('This field can not be empty').nullable(),
  email: yup.string().required('This field can not be empty').email(),
  note: yup.string().required('This field can not be empty').nullable(),
  // INJECT
});
