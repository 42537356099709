export const styles = {
  root: {},
  backdrop: {
    background: 'rgba(0, 0, 0, 0.3)',
  },
  drawerPaper: {
    background: '#315efb',
    borderRadius: '0px 35px 0px 44px',
    '& .one-link': {
      gap: '14px',
      '& a': {
        textDecoration: 'none',
        color: '#FFF',
        textTransform: 'uppercase',
        fontFamily: "Poppins",
        fontStyle: 'normal',
        alignItems: 'center',
        fontSize: '14px',
        lineHeight: '14px',
        paddingLeft: '14px',
        paddingRight: '14px',
        display: 'block',
        position: 'relative',
      },
      '& .active-link': {
        fontWeight: 500,
      },
      '& .active-link::after': {
        content: '""',
        position: 'absolute',
        left: '-3px',
        top: '-2px',
        width: '3px',
        height: '19px',
        background: '#ffffff',
        boxShadow: '2px 1px 4px #ffffff',
        borderRadius: '0px 2px 2px 0px',
      },
      '& .link-icon': {
        width: '18px',
        height: '18px',
      },
    },
    '& .switch-office': {
      '& .switch-btn': {
        '& button': {
          fontSize: '16px',
          lineHeight: '12px',
          color: '#FFF',
          padding: '8px 0px',
          borderRadius: '4px',
        },
      },
      '& select': {
        color: '#000',
      },
    },
  },
  wrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '22px',
  },
  titleWrapper: {
    gap: '14px',
    paddingLeft: '14px',
    paddingRight: '14px',
    position: 'relative',
    cursor: 'pointer',
  },
  active: {
    '& .MuiTypography-root': {
      fontWeight: 500,
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      width: '3px',
      height: '19px',
      background: '#ffffff',
      boxShadow: '2px 1px 4px #ffffff',
      borderRadius: '0px 2px 2px 0px',
    },
  },
  title: {
    textDecoration: 'none',
    color: '#FFF',
    textTransform: 'uppercase',
    fontFamily: "Poppins",
    fontStyle: 'normal',
    alignItems: 'center',
    fontSize: '14px',
    lineHeight: '14px',
  },
};
