import { getToken } from "../../../appVariable/variable";
import { getUserDetails } from '../../../REST/api';
import axiosInstance from '../../../REST/axiosInstance';

export async function uploadPDF(formData, progress, success) {
  return new Promise((resolve, reject) => {
    let token = getToken();
    axiosInstance
      .post(`api/property/DocumentsAPI/`, formData, {
        headers: { Authorization: `Bearer ${token?.idToken}` },
        onUploadProgress: (ProgressEvent) => {
          progress((ProgressEvent.loaded / ProgressEvent.total) * 100);
        },
      })
      .then((response) => {
        resolve(response.data);
        success(response.data);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
}
export function docSign(id, pdf, success) {
  let token = getToken();
  const data = { doc_id: id, file: pdf };
  axiosInstance
    .post(`api/property/SignDocumentAPI/`, data, {
      headers: { Authorization: `Bearer ${token?.idToken}` },
    })
    .then((response) => {
      success(response.data);
    })
    .catch((error) => {
      console.error(error);
    });
}

export function changeDocTitle(id, title, success) {
  let token = getToken();
  const data = { doc_id: id, title: title };
  axiosInstance
    .put(`api/property/DocTitleRenameAPI/`, data, {
      headers: { Authorization: `Bearer ${token?.idToken}` },
    })
    .then((response) => {
      success(response.data);
    })
    .catch((error) => {
      console.error(error);
    });
}

export function changeOrderDoc(updatedDoc, success) {
  let token = getToken();
  axiosInstance
    .put(`api/property/DocumentOrderAPI/`, updatedDoc, {
      headers: { Authorization: `Bearer ${token?.idToken}` },
    })
    .then((response) => {
      success(response.data);
    })
    .catch((error) => {
      console.error(error);
    });
}

export function getPDFList(success) {
  let token = getToken();
  axiosInstance
    .get(
      `api/property/DocumentsAPI/`,
      {
        headers: { Authorization: `Bearer ${token?.idToken}` },
      }
    )
    .then((response) => {
      if (response.data.code === 200 && response.data.status === "success") {
        success(response.data.data);
      }
    })
    .catch((error) => {
      console.error(error);
    });
}

export function updatePDFList(data, success) {
  let token = getToken();
  axiosInstance
    .put(`api/property/DocumentsAPI/`, data, {
      headers: { Authorization: `Bearer ${token?.idToken}` },
    })
    .then((response) => {
      if (response.data.code == 200 && response.data.status == "success") {
        success(response);
      }
    })
    .catch((error) => {
      console.error(error);
    });
}

export function getPDFForCustomer(virtualofficeid, success) {
  let token = getToken();
  let userType = token.user_info.usertypeobj;
  let userDetails = JSON.parse(getUserDetails());
  const userId = userDetails.userId;
  const data = { user_id: userId, virtualteam_id: virtualofficeid };
  axiosInstance
    .get(`api/property/ShareDocumentAPI/`, {
      headers: { Authorization: `Bearer ${token?.idToken}` },
    })
    .then((response) => {
      if (response.data.code === 200 && response.data.status === "success") {
        success(response.data.data);
      }
    })
    .catch((error) => {
      console.error(error);
    });
}
