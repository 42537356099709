import { FC, FormEventHandler, useEffect, useRef, useState } from "react";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import { Button } from "react-bootstrap";
import EmojiPicker from "emoji-picker-react";
import { useTwilioConversation } from "@components/pages/Message/hooks/useTwilioConversation";
import { useSelector } from "react-redux";
import { GetSelectedConversation } from "../../../../store/selectors/message.selector";
import { getToken } from "../../../../appVariable/variable";
import { UserRole } from "../../../../store/typedefs";
import { DocumentsPicker, UserDocument } from "@components/pages/Message/components/DocumentsPicker";
import { AttachedDocs } from "@components/pages/Message/components/AttachedDocs";

type ActiveChatInputProps = {}

export const ActiveChatInput: FC<ActiveChatInputProps> = () => {
	const {user_info} = getToken();
	const triggerEmojiRef = useRef<HTMLParagraphElement>(null);
	const selectedConversation = useSelector(GetSelectedConversation);
	const conversation = useTwilioConversation(selectedConversation);
	const messageRef = useRef<HTMLInputElement>(null);
	const [showPicker, setShowPicker] = useState(false);
	const [showDocumentsPicker, setShowDocumentsPicker] = useState(false);
	const [attachedDocs, setAttachedDocs] = useState<UserDocument[]>([]);

	useEffect(() => {
		const listener = (e: MouseEvent) => {
			const node = e.target as Node;
			const isTriggerClicked = triggerEmojiRef.current?.contains(node);
			const isEmojiPickerClicked = document.querySelector('.emoji-picker-div .epr-main')?.contains(node);
			if (!isTriggerClicked && !isEmojiPickerClicked) {
				setShowPicker(false);
			}
		}
		window.document.addEventListener('click', listener);

		return () => {
			window.document.removeEventListener('click', listener);
		}
	}, []);

	const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
		e.preventDefault();
		const message = messageRef.current?.value.trim();
		if (!message) return;
		const attributes: Record<string, any> = {}
		if (attachedDocs.length) {
			attributes['media'] = attachedDocs.map(({ id, file, filename }) => ({ id, file, filename }));
		}
		await conversation?.sendMessage(message, attributes);
		messageRef.current!.value = '';
		setAttachedDocs([]);
	}

	const handleEmojiClick = (event: any) => {
		messageRef.current!.value += event.emoji;
		setShowPicker(false);
	};

	const handleTyping = async () => {
		await conversation?.typing();
	}

	const handleAttach = (docs: UserDocument[]) => {
		setAttachedDocs(docs);
		setShowDocumentsPicker(false);
	}

	return (
		<div className="send-msg">
			<div className="message-sec">
				<form onSubmit={handleSubmit} className="message-box d-flex justify-content-between ">
					<input onChange={handleTyping} ref={messageRef} type="text" className="trans-input" name="" id=""
								 placeholder="Add your message"/>
					<div className="d-flex file-select">
						{
							user_info?.usertypeobj === UserRole.agentAndBroker
								? (
									<span onClick={() => setShowDocumentsPicker(true)} className='msgfile pointer'>
										<AttachFileIcon/>
									</span>
								)
								: null
						}
						<p ref={triggerEmojiRef} className=' pointer' onClick={() => setShowPicker(val => !val)}>
							<SentimentSatisfiedAltIcon/>
						</p>
						<Button type='submit' variant="primary" className='send-button pointer'>Send</Button>
					</div>
				</form>
				<AttachedDocs
					docs={attachedDocs}
					onClick={() => setShowDocumentsPicker(true)}
				/>
			</div>
			<DocumentsPicker
				open={showDocumentsPicker}
				onHide={() => setShowDocumentsPicker(false)}
				onAttach={handleAttach}
				attachedIds={attachedDocs.map(({id}) => id)}
			/>
			{showPicker
				? (
					<div className='emoji-picker-div position-absolute'>
						<EmojiPicker onEmojiClick={handleEmojiClick}/>
					</div>
				) : null
			}
		</div>
	)
}