import React, { useState } from "react";
import "./virtualOfficePropertyModal.scss";
import { AiFillDislike, AiFillLike } from "react-icons/ai";
import { getToken } from "../../../../appVariable/variable";
import { toast } from "react-toastify";
import {
  interestedProperty,
  notinterestedProperty,
} from "../../guestVirtualOffice/server";
import axiosInstance from '../../../../REST/axiosInstance';
const AddNoteAndPropertyLikeDislike = (props) => {
  const [text, settext] = useState("");
  console.log("props", props);
  const virtualofficeid = props.info?.virtualOfficeId;
  const propertyId = props.info?.propertyDetailInfo?.propertDetails?.id;
  console.log("virtualofficeid", virtualofficeid);
  console.log("propertyId", propertyId);
  // const guestOfficeId = useSelector(
  //   (state) => state.GuestVirtualOfficeSliceInStore.guestOfficeId
  // );
  // const propertyId = useSelector(
  //   (state) => state.propertyDetailInStore.PropertyDetailInfo
  // );
  const notinterested = (e) => {
    let token = getToken();
    let obj = {};
    obj.user_id = token.userId;
    obj.propertyid = propertyId;
    obj.dislike = 1;
    obj.virtual_ofice = virtualofficeid;
    notinterestedProperty(
      obj,
      (resp) => {
        if (resp.data.code === 200) {
          toast.info("Setted into Not Interest", "success");
        } else {
          toast.error("something went wrong", "error");
        }
      },
      (err) => {
        toast.error("something went wrong", "error");
      }
    );
  };

  const interested = (e) => {
    let token = getToken();
    let obj = {};
    obj.user_id = token.userId;
    obj.propertyid = propertyId;
    obj.like = 1;
    obj.virtual_ofice = virtualofficeid;
    interestedProperty(
      obj,
      (resp) => {
        if (resp.data.code === 200) {
          // getNotInterestedProperties();
          toast.success("Thanks for showing interest", "success");
        } else {
          toast.error("something went wrong", "error");
        }
      },
      (err) => {
        toast.error("something went wrong", "error");
      }
    );
  };
  const sendNote = async () => {
    let obj = {};
    let token = getToken();
    obj.user_id = token.userId;
    obj.note = text;
    obj.virtual_ofice = virtualofficeid;
    obj.propertyid = propertyId;
    console.log("obj", obj);
    try {
      const response = await axiosInstance.post(
        `api/virtual_office/NotePropertyVirtualOfiice/`,
        obj,
        {
          headers: { Authorization: `Bearer ${token?.idToken}` },
        }
      );
      return toast.success("Successfully Submitted");
    } catch (error) {
      console.error("Error fetching data:", error);
      return toast.error("Failed to Submit Note");
    }
  };

  return (
    <div>
      <div className="info-section mt-4">
        <div className="d-flex gap-3">
          <button
            className="actions-btns like"
            style={{ borderRadius: "20px" }}
            onClick={(e) => interested(e)}
          >
            {" "}
            <span style={{ color: "#2FC40B" }}>
              <AiFillLike />
            </span>{" "}
            I'm Interested
          </button>
          <button
            className="actions-btns notlike"
            style={{ borderRadius: "20px" }}
            onClick={(e) => notinterested(e)}
          >
            <span style={{ color: "red" }}>
              <AiFillDislike />
            </span>{" "}
            Don't Like
          </button>
        </div>
        <div className="add-note-section">
          <div className=" mb-4">
            <div
              className="d-flex pb-1"
              style={{ borderBottom: "2px solid rgb(84 103 129 / 45%)" }}
            >
              <div
                className=" pe-3 d-flex align-items-center text-dark"
                style={{ borderRight: "1px solid gray" }}
              >
                <p className="text-dark">
                  Add Note<span className="red-star">*</span>
                </p>
              </div>
              <div className=" d-flex flex-grow-1">
                <textarea
                  type="text"
                  className="form-control border-0 pb-0"
                  name="oldPswrd"
                  onChange={(e) => settext(e.target.value)}
                  // value={formik.values.oldPswrd}
                />
                {/* <div style={{ cursor: "pointer" }} onClick={() => setSetshowpass(prevState => !prevState)}>
                                        {setshowpass ? <VisibilityOffOutlinedIcon /> : <RemoveRedEyeOutlinedIcon />}
                                    </div> */}
              </div>
            </div>
            <div className=" text-end mt-3 media_pading p-0">
              <button
                className="blue__btn"
                onClick={() => sendNote()}
                style={{ borderRadius: "20px" }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNoteAndPropertyLikeDislike;
