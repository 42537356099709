import { createSlice } from "@reduxjs/toolkit";
import { getToken } from "../../../../../appVariable/variable";
let userlogin = getToken();

export const FilterDataSlice = createSlice({
  name: "filterpayload",
  initialState: {
    property_listing: userlogin ? userlogin?.user_info?.type_of_listing : null,
    category: null,
    type: null,
    Sub_category: null,
    area: null,
    city: null,
    state: null,
    country: null,
    pricemin: null,
    pricemax: null,
    cost_type: 0,
    filter_obj: false,
    Bedrooms: null,
    Bathrooms: null,
    Squft_min: null,
    Squft_max: null,
    amenity_list: [],
    Amenities_filter: [],
    units: null,
    room: null,
    block: null,
    lot: null,
    Zone: null,
    lot_diamensions: null,
    building_diamensions: null,
    stories: null,
    far: null,
    Assessment: null,
    Annual_Taxes: null,
    Available_Air_Rights: null,
    locationText: "",
    Filter_responce: [],
    Neighborhood: [],
    fee: null,
    lease_type: 0,
    property_condition: 0,
    class_type: 0,
    capRate_min: null,
    capRate_max: null,
    occupancy_min: null,
    occupancy_max: null,
    filter_name: "", //save note of save search popup
  },
  reducers: {
    updateFilterData: (state, action) => {
      return { ...state, ...action.payload };
    },
    cleanFilterData: () => {
      return {
        property_listing: userlogin ? userlogin?.user_info?.type_of_listing : null,
        category: null,
        type: null,
        Sub_category: null,
        area: null,
        city: null,
        state: null,
        country: null,
        pricemin: null,
        pricemax: null,
        cost_type: 0,
        filter_obj: false,
        Bedrooms: null,
        Bathrooms: null,
        Squft_min: null,
        Squft_max: null,
        amenity_list: [],
        Amenities_filter: [],
        units: null,
        room: null,
        block: null,
        lot: null,
        Zone: null,
        lot_diamensions: null,
        building_diamensions: null,
        stories: null,
        far: null,
        Assessment: null,
        Annual_Taxes: null,
        Available_Air_Rights: null,
        locationText: "",
        Filter_responce: [],
        Neighborhood: [],
        fee: null,
        lease_type: 0,
        property_condition: 0,
        class_type: 0,
        capRate_min: null,
        capRate_max: null,
        occupancy_min: null,
        occupancy_max: null,
        filter_name: "", //save note of save search popup
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateFilterData, cleanFilterData } = FilterDataSlice.actions;

export default FilterDataSlice.reducer;
