import React, { useEffect, useRef, useState } from 'react';
import {
  Circle,
  LayerGroup,
  LayersControl,
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMap,
} from 'react-leaflet';
import PropertyCard from "../../../dashboard/components/mainSection/PropertyCard";
import "./mapView.scss";
import { getAllpropertyInfo } from "../../../dashboard/components/mainSection/server";
import PropertyDetailsPopUp from "../../../dashboard/propperty-datails/PropertyDetailsPopUp";
import { useDispatch, useSelector } from "react-redux";
import { updatePropertyDatail } from "../../../dashboard/propperty-datails/PropertySlice";
import "leaflet/dist/leaflet.css";
import MarkerClusterGroup from "react-leaflet-cluster";
import { Icon } from "leaflet";

function CenterMap({ center }) {
  const map = useMap();
  useEffect(() => {
    map.flyTo(center, map.getZoom(), {
      animate: true,
      duration: 2.0
    });
  }, [center, map]);

  return null;
}
const Mapview = (props) => {
  const mapRef = useRef();
  //marker icons
  const commertialmarkerIcon = new Icon({
    iconUrl: require("./Image/reslocation.png"),
    iconSize: [40, 40],
  });
  const residentialmarkerIcon = new Icon({
    iconUrl: require("./Image/comlocation.png"),
    iconSize: [40, 40],
  });
  const dispatch = useDispatch();
  const { latitude, longitude, allProperty } = props;
  const propertyDetailInStore = useSelector((state) => state.propertyDetailInStore);
  const findValidCenter = (properties, lat, lng) => {
    const defaultCoords = [40.7128, -74.006]; // New York City coordinates
    if (properties && properties.length) {
      const validProperty = properties.find((p) => p.latitude !== null && p.longitude !== null);
      if (validProperty) {
        return [validProperty.latitude, validProperty.longitude];
      }
    }
    if (lat && lng) {
      return [lat, lng];
    }
    return defaultCoords;
  };

  const [center, setCenter] = useState(() => findValidCenter(allProperty, latitude, longitude));
  const [propertyDetailInfo, setPropertyDetailInfo] = useState({});
  const customIcon = new Icon({
    iconUrl: require("./Image/marker-icon.png"),
    iconSize: [38, 38], // size of icon
  });
  useEffect(() => {
    setCenter(findValidCenter(allProperty, latitude, longitude));
  }, [latitude, longitude, allProperty]);

  let propertDetails = async (id) => {
    dispatch(
      updatePropertyDatail({
        propertyId: id,
        setshowPropertyPopup: true,
      })
    );
    await getAllpropertyInfo(id, (success) => {
      setPropertyDetailInfo(success);
    });
  };

  return (
    <>
      <div className="map-view-container h-100">
        <MapContainer
          ref={mapRef}
          center={center}
          zoom={allProperty !== undefined ? 16 : 14}
          scrollWheelZoom={true}
          className="mapcontent h-100"
          style={{ width: "100%", minHeight: "400px" }}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />

          <CenterMap center={center} />

          {/* <LayersControl position="topright"> */}
          <LayersControl.Overlay name="Marker with popup">
            <Marker position={center}>
              <Popup>
                A pretty CSS3 popup. <br /> Easily customizable.{" "}
              </Popup>
            </Marker>
          </LayersControl.Overlay>
          {!props?.marker && (
            <LayerGroup>
              <Circle
                center={center}
                pathOptions={{ color: "green", fillColor: "green" }}
                radius={100}
              />
            </LayerGroup>
          )}
          {latitude && longitude && (
            <Marker
              position={[
                latitude ? latitude : 21.1458, //latitude
                longitude ? longitude : 79.088158, //longitude
              ]}
              icon={customIcon}
            >
              <Popup>
                <button className="btn-close" aria-label="Close"></button>
                <PropertyCard
                  agentListingData={props?.propertyData?.propertDetails}
                  handelOpenPopUp={propertDetails}
                />
              </Popup>
            </Marker>
          )}
          <MarkerClusterGroup chunkedLoading>
            {allProperty !== undefined ? (
              allProperty?.map((park, id) => {
                if (park.latitude != null && park.longitude != null) {
                  return (
                    <Marker
                      key={`park${id}`}
                      icon={
                        park.property_listing_type?.property_listing_name === 'Residential'
                          ? residentialmarkerIcon
                          : commertialmarkerIcon
                      }
                      position={[park?.latitude, park?.longitude]}>
                      <Popup>
                        <PropertyCard
                          agentListingData={park}
                          handelOpenPopUp={propertDetails}
                        />
                      </Popup>
                    </Marker>
                  );
                }

                return null;
              })
            ) : (
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution="Map data © OpenStreetMap contributors"
              />
            )}
          </MarkerClusterGroup>
        </MapContainer>
      </div>

      {/* property detail pop up  */}
      {propertyDetailInStore.setshowPropertyPopup &&
        Object.keys(propertyDetailInfo).length > 0 && (
          <PropertyDetailsPopUp
            propertyDetailInfo={propertyDetailInfo} // passing info about property
            showLocationButton={true}
          />
        )}
    </>
  );
};

export default Mapview;
