import React, { useEffect, useState } from 'react'
import "./personalInfo.scss"
import { getToken } from "../../../../appVariable/variable"
import uplaod from "./image/upload.svg"
import { useFormik } from 'formik'
import Select from "react-select";
import swal from 'sweetalert'
import { getAllstate, getAllzipcode, getGuestDetails, submitProfileData } from './Server'
import * as Yup from "yup";
import ProfileEdit from '../../profile/components/personal/ProfileEdit'
import { updatePersonalInfo } from "../../profile/components/personal/PersonalinfoSlice";
import { useDispatch } from "react-redux";
import { ProfileAvatar } from "../../../shared/profile-avatar";
import { setUserDetails } from '../../../../REST/api';

const PersonalInfo = (props) => {
    let token = getToken()
    const dispatch = useDispatch();
    let userId = token?.userId;
    const [previewProfileImg, setPreviewProfileImg] = useState(null);
    // const [searchQuery, setSearchQuery] = useState("")
    // const [userstateid, setUserstateid] = useState();
    const [optionsList, setOptionsList] = useState([]);
    const [guestdetails, setGuestdetails] = useState([]);
    const [userstate, setUserstate] = useState({});
    const [zipcodeid, setZipcodeid] = useState();
    const [allZipcodes, setAllZipcodes] = useState();
    const [propfileEditModal, setPropfileEditModal] = useState(false)


    const formik = useFormik({
        initialValues: {
            firstName: "", HomeAddress: "",
            lastName: "", state: "",
            cell_number: "", zipCode: "",
            zipcodeid: "", profileImg: "",
        },
        validationSchema: Yup.object({
            firstName: Yup.string().required("*first Name required").matches("^[a-zA-Z]*$", 'Numbers & spaces not allowed').nullable().max(20, "Must be 20 characters or less"),
            lastName: Yup.string().required("*Last Name required").matches("^[a-zA-Z]*$", 'Numbers & spaces not allowed').nullable().max(20, "Must be 20 characters or less"),
            HomeAddress: Yup.string().required("*Home address required"),
            cell_number: Yup.string().min(10, "Enter correct Number").max(10, "Enter correct Number").required("*Phone Number required").nullable(),
            // state: Yup.string().required("*state required"),
            // zipcodeid : Yup.string().required("Fill this field").nullable(),
            // zipCode: Yup.number().required("*zip code required"),
        }),
        onSubmit: (values) => {
            let formData = new FormData();
            formData.append("profile_image", formik.values.profileImg);
            formData.append("first_name", formik.values.firstName);
            formData.append("last_name", formik.values.lastName);
            formData.append("home_address", formik.values.HomeAddress);
            formData.append("state_id", userstate.id);
            formData.append("cell_number", formik.values.cell_number);
            formData.append("zip_code_id", zipcodeid.label);
            formData.append("user_id", userId);
            submitProfileData(formData, async (success) => {
                const fetchUserProfile = () => {
                    return new Promise((resolve) => {
                        getGuestDetails((success) => resolve(success));
                    });
                }
                const profile = await fetchUserProfile();
                const profileImg = profile.profile_image;
                let updateuserDetails = token
                updateuserDetails.profile.first_name = values.firstName
                updateuserDetails.profile.slug = `${token.userId}-${values.firstName}-${values.lastName}`
                updateuserDetails.profile.profile_image = profileImg;
                setUserDetails(updateuserDetails);
                dispatch(updatePersonalInfo({ firstName: values.firstName, lastName: values.lastName, profile_image: profileImg }));
                props.ChangeTab("two")
            });
            // formData.append("state_id", formik.values.state);
            // formData.append("zip_code_id", formik.values.zipCode);
        },
    });

    function handleUploadProfileImg(e) {
        let fileSize = e.target.files[0]?.size;
        if (fileSize <= 5250000) {
            const file = e.target.files[0];
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                setPropfileEditModal(true)
                setPreviewProfileImg(fileReader.result);
                formik.setFieldValue("profileImg", file);
            };
        } else { swal("", "Image size must be under 5mb", "warning"); }
    }

    useEffect(() => {
        getGuestDetails((success) => {
            setGuestdetails(success);
            // const resp = success
            if (success.profile_image !== null) { setPreviewProfileImg(`${success?.profile_image}`); }
            formik.setFieldValue("firstName", success.first_name);
            formik.setFieldValue("lastName", success.last_name);
            formik.setFieldValue("state", success.state.state_name);
            formik.setFieldValue("cell_number", success.cell_number);
            formik.setFieldValue("HomeAddress", success.address_line_1);
            // formik.setFieldValue("zipCode", success.zip_code.Zipcode);
            // setUserstate({...userstate, label: success.state.state_name, id: success.state.id, value: success.state.state_name})
            // const obj ={ label: guestdetails.state.state_name , id: guestdetails.state.id, value: guestdetails.state.state_name};
            // console.log("obj",obj)
            // setUserstate(obj)
        });
    }, []);

    useEffect(() => {
        let obj = { label: guestdetails?.state?.state_name, id: guestdetails?.state?.id, value: guestdetails?.state?.state_name };
        setUserstate(obj);
        let zipobj = { label: guestdetails?.zip_code }
        if (guestdetails?.zip_code?.Zipcode !== null) {
            setZipcodeid(zipobj)
        } else { setZipcodeid("") }
    }, [guestdetails])
    useEffect(() => {
        getAllstate((success) => {
            let newarr = success.map((item) => { return { label: `${item.state_name}`, id: item.id, }; });
            setOptionsList([...newarr]);
        })
        getAllzipcode((success) => {
            let newarr = success.map((item) => { return { label: `${item.Zipcode}`, id: item.id, }; });
            setAllZipcodes([...newarr]);
        })
    }, [])
    return (
        <>
            <div className='main_personal_sec'>
                <div className='profile_ptext pb-3 mb-1'> Profile Picture </div>
                <div className='text-center pb-3 preview_profile_pic mx-auto '>
                    {
                        !previewProfileImg ?
                            <ProfileAvatar height={80} width={80} sx={{ marginLeft: 'auto', marginRight: 'auto' }} /> :
                            <img src={previewProfileImg} alt="" className='profile_pic' />
                    }
                </div>
                <div className='uplada_btn center m-auto pointer'>
                    <img src={uplaod} alt="" className='upload_img ' name='ProfileImg' />
                    <label htmlFor="se" className='ps-2'>Profile Picture</label>
                    <input type="file" id='se' className='opacity-0' style={{ width: "0px" }}
                        onChange={(e) => { handleUploadProfileImg(e); }}
                    />
                </div>
                <div><p> {guestdetails.personal_bio}</p> </div>
                {/* <div><p> Disclaimer: This is a safe place we do not sell your information to anyone. You have the option to communicate wtih vendors you have given permission, Communicating can take place here and by email and text.</p> </div> */}

                <div className="row">
                    <div className='col-md-6 pe-3'>
                        <div className="d-flex main_input">
                            <div className="input_name grow-1 text-end">
                                First Name<span className="red-star" >*</span>
                            </div>
                            <div className="input-sec d-flex">
                                <input type="text" className="form-control border-0 pb-0"
                                    name="firstName"
                                    onChange={formik.handleChange}
                                    value={formik.values.firstName}
                                />
                                {formik.touched.firstName && formik.errors.firstName ? (
                                    <div className='validation-text'>{formik.errors.firstName}</div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6 ps-3'>
                        <div className="d-flex main_input">
                            <div className="input_name grow-1 text-end">
                                Home Address<span className="red-star" >*</span>
                            </div>
                            <div className="input-sec d-flex">
                                <input type="text" className="form-control border-0 pb-0"
                                    name="HomeAddress"
                                    onChange={formik.handleChange}
                                    value={formik.values.HomeAddress}
                                />
                                {formik.touched.HomeAddress && formik.errors.HomeAddress ? (
                                    <div className='validation-text'>{formik.errors.HomeAddress}</div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6 pe-3'>
                        <div className="d-flex main_input">
                            <div className="input_name grow-1 text-end">Last Name<span className="red-star" >*</span> </div>
                            <div className="input-sec d-flex">
                                <input type="text" className="form-control border-0 pb-0"
                                    name="lastName"
                                    onChange={formik.handleChange}
                                    value={formik.values.lastName}
                                />
                                {formik.touched.lastName && formik.errors.lastName ? (
                                    <div className='validation-text'>{formik.errors.lastName}</div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6 ps-3'>
                        <div className="d-flex main_input">
                            <div className="input_name grow-1 text-end">
                                State<span className="red-star" >*</span>
                            </div>

                            <div className="input-sec d-flex">
                                {/* <input type="text" className="form-control border-0 pb-0"
                                name="state" onChange={formik.handleChange} value={formik.values.state}/>
                            {formik.errors.state ? (
                                <div className='validation-text'>{formik.errors.state}</div>
                            ) : null} */}

                                <Select options={optionsList} className='custum-react-select select-wrapper w-100 ps-0'
                                    classNamePrefix="select"
                                    value={[{ label: userstate?.label, id: userstate?.id }]}
                                    onChange={(e) => { formik.setFieldValue("state", e.id); setUserstate(e) }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6 pe-3'>
                        <div className="d-flex main_input">
                            <div className="input_name grow-1 text-end">
                                Phone Number<span className="red-star" >*</span>
                            </div>
                            <div className="input-sec d-flex">
                                <input type="number" className="form-control border-0 pb-0"
                                    name="cell_number"
                                    onChange={formik.handleChange}
                                    value={formik.values.cell_number}
                                />
                                {formik.touched.cell_number && formik.errors.cell_number ? (
                                    <div className='validation-text'>{formik.errors.cell_number}</div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6 ps-3'>
                        <div className="d-flex main_input">
                            <div className="input_name grow-1 text-end">
                                Zipcode<span className="red-star" >*</span>
                            </div>
                            <div className="input-sec d-flex">

                                <Select options={allZipcodes} className='custum-react-select select-wrapper w-100 ps-0'
                                    classNamePrefix="select"
                                    value={[{ label: zipcodeid?.label, id: zipcodeid?.id }]}
                                    onChange={(e) => { setZipcodeid(e) }}
                                />
                                {/* {formik.touched.zipcodeid && formik.errors.zipcodeid ? (
                                <div className='validation-text'>{formik.errors.zipcodeid}</div>
                            ) : null} */}
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-12 text-end pt-1'>
                        <button className='save_btN text-capitalize' type="submit" onClick={formik.handleSubmit}>submit</button>
                    </div>
                </div>
            </div>

            {/* profile image adjucement section */}
            <ProfileEdit
                formik={formik}
                src={previewProfileImg}
                setPreview={setPreviewProfileImg}
                modalOpen={propfileEditModal}
                setModalOpen={setPropfileEditModal}
            />
        </>
    )
}

export default PersonalInfo
