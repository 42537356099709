import React, { useEffect, useState } from "react";
import { Col,  Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import "./PlanAndBilling.scss";
import tooltrip from "./images/tooltrip.svg"
import { deleteAgentAccount, GetUserBilling, SuspendAgentAccount } from "./server";
import { getToken } from "../../../../../appVariable/variable";
import { useNavigate } from "react-router-dom";
import { removeUserDetails } from '../../../../../REST/api';
import { authActions } from '@bus/auth/actions';
import { useDispatch } from 'react-redux';


const PlanAndBilling = () => {
    let token = getToken()
    let userId = token?.userId;
    const [modalShow, setModalShow] = useState(false);
    const [suspendModal, setSuspendModal] = useState(false);
    const [billingInfo, setBillingInfo] = useState()
    const [deletetext, setDeletetext] = useState(false)
    const [suspendtext, setSuspendtext] = useState("")
    const navigate = useNavigate();
    const dispatch = useDispatch();


    let DeleteAccount = async () => {
        await deleteAgentAccount(userId, (success) => { console.log(success) });
        dispatch(authActions.isAuthenticatedFalse());
        removeUserDetails();
        localStorage.removeItem("add_on");
        localStorage.removeItem("sidebar");
        navigate("/");
    }

    let SuspendAccount = async () => {
        await SuspendAgentAccount((success) => { console.log(success) });
        GetUserBilling((success) => { setBillingInfo(success); })
        setSuspendModal(false)
        setSuspendtext("")
    }

    useEffect(() => {
        GetUserBilling((success) => { setBillingInfo(success); })
    }, [])
    return (
        <>
            <div className="plain_and_billing_section">
                <div className="plan_sec">
                    <div>
                        <p className="text-end bill-content">Billing Cycle : {billingInfo?.[0].plan_choices}</p>
                        <div className="main_content row">
                            <div className="col-lg-12">
                                {/* Current Active Plan  */}
                                <div className="row justify-content-between Current_Active_text">
                                    <div className="col-md-6 d-flex justify-content-between left_side ps-0">
                                        <p className="">Current Active Plan </p>
                                        <span>:</span>
                                    </div>
                                    <div className="col-md-6 pe-0">
                                        <p className="text-end">{billingInfo?.[0].plan_id.Name}</p>
                                    </div>
                                </div>

                                {/* Neighborhood Specialist  */}
                                <div className="row Neighborhood_padtop ">
                                    <div className="col-md-6 d-flex justify-content-between left_side ps-0">
                                        <p>Neighborhood Specialist </p>
                                        <span>:</span>
                                    </div>
                                    <div className="col-md-6 pe-0">
                                        <p className="text-end">
                                            <button className="blue__btn Under_btn">Under Review </button>
                                        </p>
                                    </div>
                                    <div className="border-bottom"></div>
                                </div>
                            </div>
                            <div className="row Last_Payment_block ">

                                <div className="col-lg-6  left_side ps-0">
                                    <div className="row justify-content-between ">
                                        <div className="col-md-6 d-flex justify-content-between">
                                            <p>Last Payment </p>
                                            <span>:</span>
                                        </div>
                                        <div className="col-md-6 text-end">
                                            <button className="payment_status paid">PAID</button>
                                        </div>
                                    </div>
                                    <div className="row justify-content-between">
                                        <div className="col-md-6 d-flex justify-content-between">
                                            <p>Total Amount </p>
                                            <span>:</span>
                                        </div>
                                        <div className="col-md-6">
                                            <p className="text-end">
                                                {billingInfo?.[0].plan_choices === "Monthly" ?
                                                    billingInfo?.[0].plan_id.monthly_price :
                                                    billingInfo?.[0].plan_id.yearly_price
                                                }
                                            </p>
                                            {/* <p className="text-end">$75 </p> */}
                                        </div>
                                    </div>
                                    <div className="row justify-content-between">
                                        <div className="col-md-6 d-flex justify-content-between">
                                            <p>Due Date </p>
                                            <span>:</span>
                                        </div>
                                        <div className="col-md-6">
                                            <p className="text-end">
                                                {billingInfo?.[0].approved_date ?
                                                    billingInfo?.[0].approved_date : "-"
                                                }
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 right_side pe-0">
                                    <div className="row justify-content-between">
                                        <div className="col-md-6 d-flex justify-content-between">
                                            <p>Upcoming Payment </p>
                                            <span>:</span>
                                        </div>
                                        <div className="col-md-6 text-end">
                                            <button className="payment_status upcoming">UPCOMING </button>
                                        </div>
                                    </div>
                                    <div className="row justify-content-between">
                                        <div className="col-md-6 d-flex justify-content-between">
                                            <p>Total Amount </p>
                                            <span>:</span>
                                        </div>
                                        <div className="col-md-6">
                                            <p className="text-end">{
                                                billingInfo?.[0].status ?
                                                    billingInfo?.[0].plan_choices === "Monthly" ?
                                                        billingInfo?.[0].plan_id.monthly_price :
                                                        billingInfo?.[0].plan_id.yearly_price
                                                    :
                                                    "-"
                                            }</p>
                                            {/* <p className="text-end">$75 </p> */}
                                        </div>
                                    </div>
                                    <div className="row justify-content-between">
                                        <div className="col-md-6 d-flex justify-content-between">
                                            <p>Due Date </p>
                                            <span>:</span>
                                        </div>
                                        <div className="col-md-6">
                                            <p className="text-end">
                                                {billingInfo?.[0].status ?
                                                    billingInfo?.[0].next_billing_date : "-"
                                                }
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* buttons  */}
                        <Row className="two_btns">
                            <Col sm={6} className="d-grid left_side ps-0">
                                <button className="btn-block btns_border upgrad_btn">Upgrad Plan</button>
                            </Col>
                            <Col sm={6} className="right_side d-grid pe-0">
                                <button className="btn-block btns_border upcoming_btn">Upcoming Plan</button>
                            </Col>
                        </Row>
                        <div className='pasword_sectext suspend_margine pb-3 mb-2'>
                            Suspend & Delete Account
                        </div>
                        <div className="row main_del_btn">
                            <div className="col-sm-6 text-center center ps-0">
                                <button type="" className="save__btn suspend_btn" onClick={() => setSuspendModal(true)} >Suspend</button>
                                <div className='position-relative suspend'>
                                    <img src={tooltrip} alt="tooltrip" />
                                    <div className="suspend-tooltip position-absolute">
                                        <div className="tip"></div>
                                        <p>Account suspend for one yr but at 6 month all listings and saved searches are purged from account.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 text-center center pe-0">
                                <button type="" className="Delete__btn Delete_btn" onClick={() => setModalShow(true)}>Delete</button>
                                <div className='position-relative delete_trip'>
                                    <img src={tooltrip} alt="tooltrip" className='img_hover' />
                                    <div className="suspend-tooltip position-absolute">
                                        <div className="tip"></div>
                                        <p>You can delete your AFISADO account at any time. What you should know beforehand, and what will happen when your account is deleted. When you delete your AFISADO account, you will immediately lose all access to the account, all saved information, and communication with listing providers will be deleted.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* start suspend modal start*/}
            <div className="SecuRITY_modals">
                <Modal className="SecuRITY_modals"
                    show={suspendModal}
                    onHide={() => setSuspendModal(false)}
                    size="md"
                    ria-labelledby="contained-modal-title-vcenter"
                    centered >
                    <Modal.Header closeButton className="border-bottom-0 security_body gdgyg">
                        <Modal.Title id="contained-modal-title-vcenter " className="border-0">
                            <div className="forgot_title ps-2">
                                Suspend Account
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="px-4 pt-0 security_body">
                        <div className="forgot_small_text pb-2">
                            Confirm you want to suspend this account. Kindly Enter "<span>SUSPEND</span> " below.
                        </div>

                        <div className=' p-2' style={{ border: '1px solid ' }}>
                            <input type="text" name="text" id="" className=" w-100 "
                                onChange={(e) => { setSuspendtext(e.target.value === "SUSPEND"); }}
                            />
                        </div>
                        <div className="text-end mt-2">
                            <button type="submit" className="suspend_btn"
                                onClick={() => SuspendAccount()}
                                disabled={!suspendtext}
                            >Suspend My Account</button>
                        </div>
                    </Modal.Body>

                </Modal>
            </div>
            {/* end suspend modal */}

            {/*start Delete Account modal */}
            <div className="SecuRITY_modals">
                <Modal className="SecuRITY_modals"
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    size="md"
                    ria-labelledby="contained-modal-title-vcenter"
                    centered >
                    <Modal.Header closeButton className="border-bottom-0 security_body gdgyg">
                        <Modal.Title id="contained-modal-title-vcenter " className="border-0">
                            <div className="forgot_title ps-2">
                                Delete Account
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="px-4 pt-0 security_body">
                        <div className="forgot_small_text pb-2">
                            Confirm you want to delete this account. Kindly Enter " <span className='red_texT'>DELETE</span> " below.
                        </div>

                        <div className=' p-2' style={{ border: '1px solid ' }}>
                            <input type="text" name="text" id="" className=" w-100 "
                                onChange={(e) => { setDeletetext(e.target.value === "DELETE"); console.log(deletetext) }}
                            />
                        </div>
                        <div className="text-end mt-3">
                            <button type="submit" className="submit_btn"
                                onClick={() => DeleteAccount()}
                                disabled={!deletetext}
                            >Delete</button>
                        </div>
                    </Modal.Body>

                </Modal>
            </div>
            {/*end Delete Account modal */}
        </>
    )
}

export default PlanAndBilling
