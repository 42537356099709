import * as effects from 'redux-saga/effects';

import {
  authenticate
} from './workers';
import { authActions } from '@bus/auth/actions';

function* watchAuthenticate() {
  yield effects.takeEvery(authActions.authenticate.type, authenticate);
}
export function* watchAuth() {
  yield effects.all([
    effects.call(watchAuthenticate),
  ]);
}
