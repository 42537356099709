import { createContext, ReactNode, useEffect, useRef, useState } from "react";
import { Client, Message } from "@twilio/conversations";
import { getToken } from "../appVariable/variable";
import axiosInstance from "@REST/axiosInstance";
import { useDispatch } from "react-redux";
import {
	addOrUpdateConversation,
	updateConversationAttribute,
	setParticipantTyping,
	removeParticipantTyping, 
	setConversationsAndAttributes, 
	addOrUpdateLastMessage,
} from "../store/slices/message.slice";
import {
	transformTwilioMessage,
	transformTwilioConversation
} from "../store/transformers/message.transformer";

type TwilioContextValue = Client | null;

export const getTwilioToken = () => {
	const token = getToken();

	return axiosInstance
		.get('/api/user/get-twilio-token/', {
			headers: { 'Authorization': `Bearer ${token?.idToken}` },
		})
		.then((res) => res.data.data);
}

const fetchConversationById = (conversation_sid: string) => {
	const token = getToken();
	return axiosInstance
		.get(`/api/user/twilio-conversations/${conversation_sid}/`, {
			headers: { 'Authorization': `Bearer ${token?.idToken}` },
		})
		.then((res) => res.data.data);
}

export const getConversationsAttributes = () => {
	const token = getToken();

	return axiosInstance
		.get('/api/user/twilio-conversations/', {
			headers: { 'Authorization': `Bearer ${token?.idToken}` },
		})
		.then((res) => res.data.data)
		.catch((e) => {
			console.log(e);
			return [];
		});
}

export const TwilioContext = createContext<TwilioContextValue>(null);

export const TwilioContextProvider = ({ children }: { children: ReactNode }) => {
	const dispatch = useDispatch();
	const isLoading = useRef(false);
	const [client, setClient] = useState<Client | null>(null);

	useEffect(() => {
		const token = localStorage.getItem('chatToken');
		token ? initClient(token) : refreshToken();
		return () => {
			setClient(null)
		};
	}, []);

	const handleMessageAdded = async (message: Message) => {
		console.log('handleMessageAdded: ', message);
		const conversation = message.conversation;

		try {
			const conversationAttribute = await fetchConversationById(conversation.sid);
			dispatch(
				updateConversationAttribute(
					conversationAttribute
				)
			)
		} catch (e) { console.log(e); }

		dispatch(
			addOrUpdateLastMessage({
				conversation_sid: conversation.sid,
				message: transformTwilioMessage(message),
			})
		);

		dispatch(
			addOrUpdateConversation(
				transformTwilioConversation(message.conversation)
			)
		);
	}

	const initClient = async (token: string) => {
		if (client) return;
		const twilioClient = new Client(token);
		setClient(twilioClient);

		try {
			const paginator = await twilioClient.getSubscribedConversations();
			const attributes = await getConversationsAttributes();
			const conversations = paginator.items.map(transformTwilioConversation);

			dispatch(
				setConversationsAndAttributes({
					attributes,
					conversations,
				})
			);
		} catch (e) { console.log(e) }

		twilioClient.addListener('messageAdded', handleMessageAdded);
		twilioClient.addListener("tokenAboutToExpire", refreshToken);
		twilioClient.addListener("tokenExpired", refreshToken);
	}

	const refreshToken = async () => {
		if (isLoading.current) return;

		isLoading.current = true;

		try {
			const token = await getTwilioToken();
			localStorage.setItem('chatToken', token);
			await initClient(token);
		} catch (e) { console.log(e) }

		isLoading.current = false;
	}

	return (
		<TwilioContext.Provider value={client}>
			{ children }
		</TwilioContext.Provider>
	)
}
