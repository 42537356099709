import { getToken } from "../../../appVariable/variable";
import axiosInstance from '../../../REST/axiosInstance';

export function AddnewCard(cardinfo, success) {
  let token = getToken();
  axiosInstance
    .post(`api/user/card_create/`, cardinfo, {
      headers: { Authorization: `Bearer ${token?.idToken}` },
    })
    .then((response) => {
      success(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
}

export function billCheckout(billingobj, success) {
  let token = getToken();
  axiosInstance
    .put(`api/user/billing/`, billingobj, {
      headers: { Authorization: `Bearer ${token?.idToken}` },
    })
    .then((response) => {
      success(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
}

export function billAmmount(billamt, success) {
  let token = getToken();
  axiosInstance
    .post(
      `api/user/agentapprovedsubscriptionplanchoices/`,
      billamt,
      {
        headers: { Authorization: `Bearer ${token?.idToken}` },
      }
    )
    .then((response) => {
      if (response.data.code === 200 && response.data.status === "success") {
        success(response.data.data);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

export function getuserCards(success) {
  let token = getToken();
  let userId = token?.userId;
  axiosInstance
    .get(`api/user/card_create/${userId}`, {
      headers: {
        Authorization: `Bearer ${token?.idToken}`,
      },
    })
    .then((response) => {
      if (response.data.code === 200 && response.data.status === "success") {
        success(response.data.data);
      } else {
        success(null);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function deletecard(cardid, success) {
  let token = getToken();
  await axiosInstance
    .delete(`api/user/card_create/${cardid}`, {
      headers: { Authorization: `Bearer ${token?.accessToken}` },
    })
    .then((response) => {
      if (response.data.code === 200 && response.data.status === "success") {
        success(response.data.data);
      } else {
        success(null);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}
