import React from 'react'
import "./selpersonCard.scss"
import cardImg from "./images/Gentleman.jpg"
import { Rating } from '@mui/material';
import { useNavigate } from 'react-router';
import { book } from '@routes/book';
const SelpersonCard = ({ info, onclick, name }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    info.slug && navigate(`${book.agentsId}/${info.slug}`);
  };

  return (
    <div className='selPersonCardWidth'>
      <div className='card card_border h-100'>
        <div className="card-body p-2">
          <label className="checkbox pointer" >
            <input
              type="checkbox"
              className="custum-checkbox checkbox__input pointer"
              onChange={onclick}
              id={info?.id}
              checked={name === 'delete' ? !info.saved : info.saved}
              name={name} />
            <span className="checkbox__inner pointer" />
          </label>
          <div className='card_center'>
            <div className='pb-3 img_pad_bottom text-center'>
              <img src={info?.profile_image ? `${info?.profile_image}` : cardImg} alt="" />
            </div>
            <p className='text-center pointer' onClick={handleClick}>
              {`${info?.first_name} ${info?.last_name}`}
            </p>
            <Rating name="read-only" value={info?.rating} readOnly />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SelpersonCard