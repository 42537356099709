import advertiseSlice from './slice';
import { createAction } from '@reduxjs/toolkit';
import prepareActions from '@helpers/prepareActions';

export const advertiseActions = {
  ...advertiseSlice.actions,
  sendAdvertiseRequest: createAction('auth/sendAdvertiseRequest',
    prepareActions.movePromiseToMeta,
  ),
};
