import React from 'react';

import { Form } from 'react-final-form';
import { useDispatch } from 'react-redux';

import { asyncValidate } from './asyncValidate';

const FinalForm = ({
  initialValues = {},
  component: Component,
  onSubmit,
  sagaAction,
  schema,
  keepDirtyOnReinitialize = false,
  extraProps,
  onSubmitSuccess,
}) => {
  const validate = asyncValidate(schema);

  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    return new Promise((resolve, reject) => {
      sagaAction && dispatch(sagaAction({ values, resolve, reject }));
      onSubmit?.({ values, resolve, reject });
    })
      .then((data) => {
        onSubmitSuccess?.(data);
      })
      .catch();
  };

  return (
    <Form
      keepDirtyOnReinitialize={keepDirtyOnReinitialize}
      initialValues={initialValues}
      validate={validate}
      onSubmit={handleSubmit}
      render={(props) => (
        <Component {...(props)} {...extraProps} />
      )}
    />
  );
};

export default FinalForm;
