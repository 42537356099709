import { Theme } from '@mui/material/styles';

export const styles = {
  inputWrapper: {
    borderBottom: '0.676897px solid #546781',
    display: 'flex',
    paddingBottom: '10px',
    position: 'relative',
  },
  label: {
    width: '115px',
    height: 'max-content',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '8px',
    borderRight: '0.676897px solid #546781',
    '& p': {
      fontSize: '12px',
    },
  },
  input: {
    '& .MuiFormLabel-root': {
      transform: 'translate(10px, 10px) scale(1)',
      fontSize: '12px',
      '&.Mui-focused': {
        color: 'rgba(0, 0, 0, 0.6)',
        transform: 'translate(-70px, 10px) scale(1)',
      },
      '&.MuiFormLabel-filled': {
        color: 'rgba(0, 0, 0, 0.6)',
        transform: 'translate(-70px, 10px) scale(1)',
      },
    },
    '& .MuiInputBase-root': {
      '&.MuiOutlinedInput-root': {
        paddingTop: '2px',
      },
      '&.MuiInputBase-multiline': {
        paddingBottom: 0,
      },
      '& input': {
        padding: '4px 10px',
      },
      '& textarea': {
        mt: '4px',
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
      borderRadius: 0,
    },
  },
  button: {
    height: '38px',
    border: '2px solid #FFF',
    borderRadius: '20px',
    fontFamily: 'Poppins',
    fontSize: '12px',
    lineHeight: '12px',
    background: '#315EFB',
    padding: '10px 30px',
    boxShadow: '13px 16.5px 15.49px -10.86px rgba(0, 0, 0, 0.2)',
    '&:hover': {
      background: '#315EFB',
    },
  },
  error: {
    position: 'absolute',
    bottom: 0,
    left: '113px',
    color: '#d32f2f'
  },
  valueLength: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
};
