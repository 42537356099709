export const styles = {
  chosenTowns: {
    width: '15px',
    height: '15px',
    borderRadius: '5px',
    padding: '3px',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    background: '#333',
    flexShrink: 0,
  },
};
