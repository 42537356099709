export const styles = {
  documentWrapper: {
    border: '1px solid #000',
    borderRadius: '5px',
    padding: '5px',
  },
  button: {
    fontSize: '12px',
    lineHeight: '12px',
    color: '#315EFB',
    padding: '10px 20px',
    background: 'linear-gradient(157.69deg, rgba(255, 255, 255, 0.1) 6.02%, rgba(255, 255, 255, 0) 95.12%)',
    border: '2px solid #315EFB',
    boxShadow: '-5.54px 7.03px 22.15px -11.7388px rgba(34, 34, 34, 0.35)',
    borderRadius: '20px',
    '&:hover': {
      color: 'white',
      background: '#315EFB',
      border: '2px solid white',
      boxShadow: '-5.54px 7.03px 22.15px -11.7388px rgba(34, 34, 34, 0.35)',
    },
  },
  removeButton: {
    fontSize: '12px',
    lineHeight: '12px',
    color: '#FF3838',
    padding: '10px 20px',
    background: 'linear-gradient(157.69deg, rgba(255, 255, 255, 0.1) 6.02%, rgba(255, 255, 255, 0) 95.12%)',
    border: '2px solid #FF3838',
    boxShadow: '-5.54px 7.03px 22.15px -11.7388px rgba(34, 34, 34, 0.35)',
    borderRadius: '20px',
    '&:hover': {
      color: 'white',
      background: '#FF3838',
      border: '2px solid white',
      boxShadow: '-5.54px 7.03px 22.15px -11.7388px rgba(34, 34, 34, 0.35)',
    },
  },
};
