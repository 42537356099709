import { FC } from "react";
import { Chip } from "@mui/material";

type ParticipantTypingProps = {
	participant: { fullName: string } | null;
}

export const ParticipantTyping: FC<ParticipantTypingProps> = ({ participant }) => {
	if (!participant) return null;

	return (
		<Chip
			label={`${participant.fullName} typing ...`}
			size="small"
			sx={{ maxWidth: '100%', width: 'max-content', margin: '0 auto' }}
		/>
	)
}