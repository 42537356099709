import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { publicRoutes } from '@pages/public/routes';
import { v4 as uuidv4 } from 'uuid';
import { AppLayout } from '@layouts/AppLayout';
import { book } from '@routes/book';
import { ProtectedRoute } from '@routes/ProtectedRoute';

const PublicRoutes = () => {
  return (
    <Routes>
      {publicRoutes.map(({ path, Component, Layout, layoutProps, children, allowedRoles }) => (
        <Route path={path} element={<Layout {...layoutProps} />} key={path}>
          {children && children.length > 0 && (
            <Route index element={<Navigate to={children[0].path} replace />} />
          )}
          {children?.map((childRoute) => (
            <Route
              path={childRoute.path}
              element={childRoute.Component}
              key={childRoute.path} />
          ))}
          <Route
            path={path}
            element={Component} />
        </Route>
      ))}
      <Route element={<AppLayout/>}>
        <Route path={'/*'} element={<Navigate replace to={book.home} />} />
      </Route>
    </Routes>
  );
};

export default PublicRoutes;
