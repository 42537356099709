import { getToken } from "../../../appVariable/variable";
import axiosInstance from '../../../REST/axiosInstance';

export function getFullAddress(address, success) {
  let tempObj = {}
  tempObj['location'] = address;
  let token = getToken();
  axiosInstance
    .post(`api/master/AreaSearch/`, tempObj, {
      headers: {
        Authorization: `Bearer ${token?.idToken}`,
      },
    })
    .then((response) => {
      if (response.data.code === 200 && response.data.status === "success") {
        success(response.data.data);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function getuserSubscription(success) {
  let token = getToken()
  let userId = token?.userId;
  let tempObj = { "user_id": userId }
  await axiosInstance
    .post(`api/user/agentdashboardwithlistingtype/`, tempObj, {
      headers: {
        Authorization: `Bearer ${token?.idToken}`,
      },
    })
    .then((response) => {
      if (response.data.code === 200 && response.data.status === "success") {
        success(response.data.data);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function getRadioButtons(radioObj, success) {
  let token = getToken()
  await axiosInstance.post(`api/property/propertymaicategorytypehomeview/`, radioObj, {
      headers: { Authorization: `Bearer ${token?.idToken}`,},
    })
    .then((response) => {
      if (response.data.code === 200 && response.data.status === "success") {
        success(response.data.data);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

export function getTeammerberProfile(success) {
  let token = getToken()
  let agentSlug = token?.profile.slug;
  axiosInstance.get(`api/property/teammerberprofile/${agentSlug}`, {
      headers: { Authorization: `Bearer ${token?.idToken}`,},
    })
    .then((response) => {
      if (response.data.code === 200 && response.data.status === "success") {
        success(response.data.data);
      }
      else {
      }
    })
    .catch((error) => {
    });
}
export function getNblSpecial(city_id, success) {
  const userInfo = JSON.parse(localStorage.getItem('locationDetails'));

  axiosInstance.get(`api/property/GetNeibourhoodBasedOnLocation/?city=${city_id}&latitude=${userInfo.latitude}&longitude=${userInfo.longitude}`)
    .then((response) => {
      if (response.data.code === 200 && response.data.status === "success") {
        success(response.data.data);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}