import React from 'react';
import { FieldRenderProps, useField } from 'react-final-form';

import { Box, TextField } from '@mui/material';

import { getFieldError } from '@core/FinalForm/getFieldError';
import { styles } from '@components/forms/SubmitRequestForAdvertising/styles';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { getPropertyConditions } from '@bus/dashboard/selectors';

export const RenderPropertyConditionField: React.FC<FieldRenderProps<number>> = ({
  input: {value, onChange, ...input},
  meta,
}) => {
  const propertyConditions = useSelector(getPropertyConditions);
  const {
    input: { value: category },
  } = useField('category');

  return (
    <>
      {propertyConditions.filter((item: any) => {
        if (category === 3) {
          return item.property_listing_type === category;
        }
        return true;
      }).map((item: any) => (
        <div key={item.id} className="d-flex gap-2">
          <input
            type={'radio'}
            className={'Prop'}
            id={`property-${item.id}`}
            value={item.id}
            checked={value === item.id}
            onChange={onChange}
            {...input}
          />
          <label htmlFor={`property-${item.id}`} className="pointer">
            {item.title}
          </label>
        </div>
      ))}
    </>
  );
};
