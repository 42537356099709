import React, { useEffect, useState } from "react";
import "./../../agentVirtualOffice/components/virtualOffice.scss";
import Mapview from "../../PropertyDetails/componants/map-view/Mapview";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../../Loader/Loader";
import { FaBed } from "react-icons/fa";
import { getToken } from "../../../../appVariable/variable";
import { TextField, Autocomplete } from "@mui/material";
import {
  getProperties,
  interestedProperty,
  notinterestedProperty,
  sendAgentNote,
} from "../server";
import search from "./noteModal/images/search.svg";
import filter from "./noteModal/images/filter.svg";
import NoteModal from "./noteModal/NoteModal";
import {
  ApplySearch,
  getLocations,
} from "../../homepage/components/hero/server";
import { updateFilterData } from "../../homepage/components/hero/FilterSlice";
import { useNavigate } from "react-router-dom";
import ResidentialMapFilter from "../../mapview-filter/components/ResidentialMapFilter";
import ResidentialBuyMapFilter from "../../mapview-filter/components/ResidentialBuyMapFilter";
import CommertialMapFilter from "../../mapview-filter/components/CommertialMapFilter";
import CommertialBuyMapFilter from "../../mapview-filter/components/CommertialBuyMapFilter";
import { postSaveListing, postFilteredData } from "../server";
import PopUpFilter from "../../dashboard/components/filter-popup/PopUpFilter";
import { updatePropertyDatail } from "../../dashboard/propperty-datails/PropertySlice";
import { getAllpropertyInfo } from "../../dashboard/components/mainSection/server";
import VirtualOfficePropertyModal from "../../virtual-office/propertyModal/VirtualOfficePropertyModal";
import Switch from "@mui/material/Switch";
import { alpha, styled } from "@mui/material/styles";
import PropertySavePopUp from "../../dashboard/components/mainSection/PropertySavePopUp";

const PinkSwitch = styled(Switch)(({ theme }) => ({
  ".MuiSwitch-track": {
    backgroundColor: "#315EFB",
    opacity: "1",
    height: "120%",
    transform: "translate(0px, -1px)",
  },
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#315EFB",
    "&:hover": {
      backgroundColor: alpha("#315EFB", theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#315EFB",
    height: "120%",
    transform: "translate(0px, -1px)",
  },
  "& .MuiSwitch-thumb": {
    color: "white",
    border: "1px solid rgba(88, 86, 86, 1)",
  },
}));
const label = { inputProps: { "aria-label": "Switch demo" } };

const GuestVirtualOfficeDashboard = () => {
  // const navigate = useNavigate();
  // const credential = getToken();
  const dispatch = useDispatch();
  const [listing, setlisting] = useState([]);
  const [loading, setloading] = useState(false);
  const [selectedProperty, setselectedProperty] = useState([]);
  const [shownote, setshownote] = useState(false);
  const [currentproperty, setcurrentproperty] = useState({});
  const [viewValue, setView] = useState(false);
  const virtualofficeid = useSelector(
    (state) => state.GuestVirtualOfficeSliceInStore.guestOfficeId
  );
  const [optionsList, setOptionsList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [showError, setshowError] = useState(false);
  const [filteredListing, setFilteredListing] = useState([]);
  const FilterDataInStore = useSelector((state) => state.FilterDataInStore);
  const [showFilter, setShowFilter] = useState(false);
  const [ShowProperty, setShowProperty] = useState(false);
  const [property, setproperty] = useState({});
  const [propertyDetailInfo, setPropertyDetailInfo] = useState({
    propertyImg: [],
  });
  const propertyDetailInStore = useSelector(
    (state) => state.propertyDetailInStore
  );

  const credential = getToken();
  const currentUserType = credential?.user_info?.type_of_listing;

  const handlefilter = (listingGroup) => {
    try {
      postFilteredData(listingGroup, (success) => {
        setFilteredListing(success.data.data.property);
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleSave = (index) => {
    postSaveListing(index, (success) => {});
  };
  useEffect(() => {
    if (virtualofficeid) {
      setloading(true);
      setshowError(false);
      getcurrentListing();
      setFilteredListing(filteredListing);
    }
  }, [virtualofficeid]);

  useEffect(() => {
    let timerOut = setTimeout(() => {
      getLocations(searchQuery, (success) => {
        let newarr = success.area.map((item) => {
          return {
            label: `${
              item.area_name +
              ", " +
              item.city_master?.city_name +
              ", " +
              item.city_master.state_master?.state_name +
              "," +
              item.city_master.state_master.country_master?.country_name
            }`,
            value: {
              area_id: item.id,
              city_id: item.city_master.id,
              state_id: item.city_master.state_master.id,
              country_id: item.city_master.state_master.country_master.id,
            },
          };
        });
        let newarr1 = success.city.map((item) => {
          return {
            label: `${
              item?.city_name +
              "," +
              item?.state_master?.state_name +
              "," +
              item?.state_master.country_master.country_name
            }`,
            value: {
              city_id: item?.id,
              state_id: item?.state_master.id,
              country_id: item?.state_master.country_master.id,
            },
          };
        });
        let newarr2 = success.state.map((item) => {
          return {
            label: `${
              item?.state_name + "," + item?.country_master.country_name
            }`,
            value: {
              state_id: item?.id,
              country_id: item?.country_master.id,
            },
          };
        });
        setOptionsList([...newarr, ...newarr1, ...newarr2]);
      });
    }, 1000);
    return () => clearTimeout(timerOut);
  }, [searchQuery]);

  const locationOnChange = (e) => {
    dispatch(
      updateFilterData({
        locationText: e?.label,
        area: e?.value?.area_id ? e?.value?.area_id : null,
        city: e?.value?.city_id,
        state: e?.value?.state_id,
        country: e?.value?.country_id,
      })
    );
  };
  const propertDetails = async (id) => {
    dispatch(
      updatePropertyDatail({ propertyId: id, setshowPropertyPopup: true })
    );
    setloading(true);
    await getAllpropertyInfo(id, (success) => {
      dispatch(updatePropertyDatail({ PropertyDetailInfo: success }));
      setPropertyDetailInfo(success);
      setloading(false);
    });
  };
  let SearchByLoaction = () => {
    if (
      FilterDataInStore.property_listing &&
      FilterDataInStore.category &&
      FilterDataInStore.city &&
      FilterDataInStore.state
    ) {
      const blockedKeys = [
        "locationText",
        "Filter_responce",
        "filter_name",
        "Neighborhood",
      ];
      const searchSchema = { ...FilterDataInStore };
      blockedKeys.forEach((key) => delete searchSchema[key]);
      ApplySearch(searchSchema, (success) => {
        dispatch(
          updateFilterData({
            Filter_responce: success,
            // Neighborhood: success.Neighborhood,
          })
        );
        setlisting(FilterDataInStore.Filter_responce);
      });
    }
  };

  const getcurrentListing = () => {
    getProperties(
      virtualofficeid,
      (resp) => {
        if (resp.data.code == 200) {
          setlisting(resp.data.data);
          setlisting((state) => {
            return state;
          });
          setFilteredListing(resp.data.data);
        } else if (resp.data.data === "Data Not Found") {
          setlisting([]);
          setlisting((state) => {
            return state;
          });
          setFilteredListing([]);
          setshowError(true);
        } else {
          setlisting([]);
          setlisting((state) => {
            return state;
          });
          setFilteredListing([]);
        }
        setloading(false);
      },
      (error) => {
        setlisting([]);
        setlisting((state) => {
          return state;
        });
        setFilteredListing([]);

        setloading(false);
      }
    );
    // currentListingProperty((success) => {
    //   if (success.length > 0) {
    //     setloading(false);
    //     setlisting(success);
    //   } else {
    //     setlisting([]);
    //   }
    // })
  };

  const sendPropertyInVirtualOffice = (props, type) => {
    // const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    // let obj = {};
    // if (type == "singletype") {
    //     obj.propertyid = [props];
    // } else {
    //     obj.propertyid = props;
    // }
    // obj.virtual_id = parseInt(virtualofficeid);
    // obj.user_id = userDetails.userId;
    // if (type == "singletype") {
    //     sendPorperty(obj, (response) => {
    //         if (response.data.code == 200) {
    //             toast.success("Property send Successfully", "success")
    //         } else {
    //             toast.error("please select virtual office", "error")
    //         }
    //     }, (error) => {
    //     })
    // } else if (type == "alltype" && props.length > 0) {
    //     sendPorperty(obj, (response) => {
    //         if (response.data.code == 200) {
    //             toast.success("Property send Successfully", "success")
    //         } else {
    //             toast.error("please select virtual office", "error")
    //         }
    //     }, (error) => {
    //     })
    // }
    // else {
    //     toast.error("please select property", "error")
    // }
  };

  const chekckedProperty = (e, property) => {
    if (e.target.checked === true) {
      let selected = [...selectedProperty];
      selected.push(property.id);
      setselectedProperty(selected);
    } else {
      let selected = [...selectedProperty];
      let index = selected.indexOf(property.id);
      selected.splice(index, 1);
      setselectedProperty(selected);
    }
  };

  const selectAllProperty = (e) => {
    if (e.target.checked) {
      if (listing?.length > 0) {
        let propertiesId = listing.map((property) => property.id);
        setselectedProperty(propertiesId);
      }
    } else {
      setselectedProperty([]);
    }
  };

  const interested = (prop) => {
    setloading(true);
    let token = getToken();
    let obj = {};
    obj.user_id = token.userId;
    obj.propertyid = prop.id;
    obj.like = 1;
    obj.virtual_ofice = virtualofficeid;

    interestedProperty(
      obj,
      (resp) => {
        if (resp.data.code == 200) {
          getcurrentListing();
          setloading(false);
          // toast.success("Thanks for showing interest", "success")
        } else {
          // toast.error("something went wrong", "error")
        }
      },
      (err) => {
        // toast.error("something went wrong", "error")
      }
    );
  };
  const notinterested = (prop) => {
    setloading(true);
    let token = getToken();
    let obj = {};
    obj.user_id = token.userId;
    obj.propertyid = prop.id;
    obj.dislike = 1;
    obj.virtual_ofice = virtualofficeid;

    notinterestedProperty(
      obj,
      (resp) => {
        if (resp.data.code == 200) {
          getcurrentListing();
          setloading(false);
          // toast.success("Thanks for showing interest", "success")
        } else {
          // toast.error("something went wrong", "error")
        }
      },
      (err) => {
        // toast.error("something went wrong", "error")
      }
    );
  };

  const sendNote = (text) => {
    let token = getToken();
    let obj = {};
    obj.user_id = token.userId;
    obj.propertyid = currentproperty.id;
    obj.note = text;
    obj.virtual_ofice = virtualofficeid;
    sendAgentNote(
      obj,
      (resp) => {
        if (resp.data.code == 200) {
          setshownote(false);
          getcurrentListing();
          setloading(false);
          toast.success("Successfully Submitted", "success");
        } else {
          // toast.error("something went wrong", "error")
        }
      },
      (err) => {
        toast.error("something went wrong", "error");
      }
    );
  };

  return (
    <>
      {loading ? <Loader /> : null}

      <div className="agent-virtual-office-dashboard flex-grow-1">
        <div className=" MainCard_container w-100">
          <div className="filter-search_a w-100">
            <div
              className="filter-sec"
              style={{ justifyContent: "space-between", display: "flex" }}
            >
              <div>
                <div className="search-box d-flex flex-grow-1">
                  <div className="search d-flex align-items-center  flex-grow-1">
                    <Autocomplete
                      className="hide-downBtn"
                      id="combo-box-demo"
                      disablePortal
                      options={optionsList}
                      // value={getCategoriesVal[0]?.location?.label}
                      onChange={(e, value) => {
                        locationOnChange(value);
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value?.id
                      }
                      style={{
                        minWidth: "250px",
                        width: "100%",
                        zIndex: 999,
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          onChange={(e) => {
                            setSearchQuery(e.target.value);
                          }}
                        />
                      )}
                    />
                    <div
                      className="search-icon pointer"
                      onClick={() => SearchByLoaction()}
                    >
                      {" "}
                      <img src={search} alt="" />
                    </div>
                  </div>
                  {/* <div
                    className="filter-icon pointer d-flex align-items-center justify-content-center"
                    onClick={() => setShowFilter(true)}
                  >
                    {" "}
                    <img src={filter} alt="" />
                  </div> */}
                </div>
              </div>
              <div className="quik-map center gap-3">
                <p>Quick View</p>
                <div className="pt-0">
                  <PinkSwitch
                    {...label}
                    onChange={(e) => {
                      setView(!viewValue);
                    }}
                    style={{ color: "#315EFB" }}
                  />
                </div>
                <p>Map View</p>
              </div>
            </div>
          </div>
        </div>
        {showError ? (
          <h6 className="mt-5">No data found</h6>
        ) : (
          <div className="d-flex mt-5 flex-grow-1">
            <div className="property-listing-group">
              <div
                className="left-section scrollS"
                style={{
                  flexDirection: viewValue === false ? "column" : "column",
                  maxHeight: viewValue === false && "75vh",
                  height: "100%",
                }}
              >
                {listing?.length > 0 && viewValue === false ? (
                  listing.map((prop) => {
                    return (
                      <div className="property-virtual-card ">
                        <img
                          src={prop.property_main_image}
                          alt=""
                          className="property-main-image"
                        />
                        {/* <div className="select-property">
                                                <label class="container">
                                                    <input type="checkbox" onChange={(e) => chekckedProperty(e, prop)} checked={selectedProperty.includes(prop.id) ? true : false} />
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div> */}

                        <div
                          className="details"
                          role="button"
                          onClick={() => {
                            setShowProperty(true);
                            setproperty(prop);
                            propertDetails(prop.slug);
                          }}
                        >
                          <div className="details-left">
                            <div style={{ display: "flex", gap: "10px" }}>
                              <h5 className="amount mt-1">
                                ${prop.property_listing_amount}{" "}
                              </h5>
                              <h5>
                                {" "}
                                <FaBed />{" "}
                                <span
                                  className="area-name"
                                  style={{ textWrap: "nowrap" }}
                                >
                                  {prop.Bedrooms} BR
                                </span>
                              </h5>
                            </div>
                            <p
                              className="area-name"
                              style={{
                                textOverflow: "ellipsis",
                                width: "100px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                              }}
                            >
                              {prop.property_area.area_name}
                            </p>
                            <p
                              className="area-name"
                              style={{
                                textOverflow: "ellipsis",
                                width: "100px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                              }}
                            >
                              {prop.property_address_1}
                            </p>
                          </div>
                          <div className="details-right">
                            <div className="profile-image-parent">
                              <img
                                src={prop?.user_profile?.profile_image}
                                alt=""
                                className="profile-image"
                              />
                            </div>
                            {/* <button className='profile-image-button' style={{opacity:"10"}}>1 year</button> */}
                          </div>
                        </div>
                        <div>
                          <div className="action-buttons">
                            <button
                              className="buttons"
                              onClick={() => interested(prop)}
                            >
                              Interested
                            </button>
                            <button
                              className="buttons"
                              onClick={() => notinterested(prop)}
                            >
                              Don't Like
                            </button>
                            {/* <button className={prop.likedislike == true ? "buttons like" : "buttons"} onClick={() => interested(prop)} >Interested</button>
                                                <button className={prop.likedislike == false ? "buttons dislike" : "buttons "} onClick={() => notinterested(prop)} >Don't Like</button> */}
                          </div>
                          <div>
                            {prop.note == null ? (
                              <button
                                className="add-note w-100"
                                onClick={() => {
                                  setshownote(true);
                                  setcurrentproperty(prop);
                                }}
                              >
                                {" "}
                                Add Note
                              </button>
                            ) : (
                              <button
                                className="add-note w-100"
                                onClick={() => {
                                  setshownote(true);
                                  setcurrentproperty(prop);
                                }}
                              >
                                {" "}
                                Add Note
                              </button>
                            )}
                          </div>
                          {/* <div style={{ marginTop: "5px" }}>
                            <button
                              className="add-note w-100"
                              onClick={(e) => {
                                handleSave(prop.id);
                              }}
                            >
                              {" "}
                              Save
                            </button>
                          </div> */}
                        </div>
                      </div>
                    );
                  })
                ) : loading || viewValue === false ? null : currentUserType ===
                  0 ? (
                  <ResidentialMapFilter
                    groupByFilter={handlefilter}
                    currentUserType={currentUserType}
                  />
                ) : (
                  <CommertialMapFilter
                    groupByFilter={handlefilter}
                    currentUserType={currentUserType}
                  />
                )}
              </div>
              {/* <div className='listing-footer'>
                            <div>
                                <input type="checkbox" name="" id="" onChange={(e) => selectAllProperty(e)} />
                                <label htmlFor="">Select All</label>
                            </div>
                            <div className='send-review'>
                                <button className='send-btn' onClick={() => sendPropertyInVirtualOffice(selectedProperty, "alltype")}>Send to review</button>
                            </div>
                        </div> */}
            </div>
            {loading === false ? (
              filteredListing?.length > 0 ? (
                <div
                  className="right-section flex-grow-1"
                  style={{ border: "1px solid #315EFB", borderRadius: "4px" }}
                >
                  <Mapview allProperty={filteredListing} />
                </div>
              ) : (
                <div
                  className="right-section flex-grow-1"
                  style={{ border: "1px solid #315EFB", borderRadius: "4px" }}
                >
                  <Mapview />
                </div>
              )
            ) : null}
          </div>
        )}
      </div>
      {ShowProperty &&
      propertyDetailInfo?.propertDetails?.property_address_1 !== undefined &&
      !loading &&
      !propertyDetailInStore.setSavePropertyPopup ? (
        <VirtualOfficePropertyModal
          setShowProperty={setShowProperty}
          ShowProperty={ShowProperty}
          propertyDetailInfo={propertyDetailInfo}
          setPropertyDetailInfo={setPropertyDetailInfo}
          virtualOfficeId={virtualofficeid}
        />
      ) : null}
      {propertyDetailInStore.setSavePropertyPopup && (
        <PropertySavePopUp setDetailShowProperty={setShowProperty} />
      )}

      {shownote ? (
        <NoteModal
          sendNote={sendNote}
          setshownote={setshownote}
          data={currentproperty}
        />
      ) : null}
      {showFilter && <PopUpFilter setShowFilter={setShowFilter} />}
    </>
  );
};

export default GuestVirtualOfficeDashboard;
