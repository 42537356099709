import axiosInstance from '../../../REST/axiosInstance';
import { authActions } from '@bus/auth/actions';
import { cleanFilterData } from '@components/pages/homepage/components/hero/FilterSlice';

export function signUp(email, password, type, success, error) {
  let tempObj = {};
  tempObj["username"] = email;
  tempObj["password"] = password;
  tempObj["user_type"] = type;
  axiosInstance
    .post(`api/user/register/`, tempObj)
    .then((resp) => {
      // if (resp.data.code == 200 && resp.data.status == success) {
      //   success(resp.data.mesg);
      // }

      success(resp);
    })
    .catch((err) => {
      error(err);
    });
}


export function saveTokenInStorage(tokenDetails) {
  const remember_me = JSON.parse(localStorage.getItem('remember_me') || 'false');
  try {
    tokenDetails.expireDate = new Date();
    tokenDetails.expireDate = new Date(
      new Date().getTime() + tokenDetails.expiresIn * 1000
    );
    if (remember_me) {
      localStorage.setItem("userDetails", JSON.stringify(tokenDetails));
    } else {
      sessionStorage.setItem("userDetails", JSON.stringify(tokenDetails));
    }
  }
  catch (err) {
    console.log("err", err)
  }
}
export function logout(time) {
  setTimeout(() => {
    alert("timeout")
    const remember_me = JSON.parse(localStorage.getItem('remember_me') || 'false');
    if (remember_me) {
      localStorage.removeItem('userDetails');
    }
    sessionStorage.removeItem('userDetails');
  }, time)

}
export function login(dispatch, email, password, remember_me, extraData, success, errorD) {
  let tempObj = {};
  tempObj["username"] = email;
  tempObj["password"] = password;
  tempObj["user_log"] = {
    ...extraData,
    mac_address: "00:16:3E:5F:FB:1A",
  };

  axiosInstance
    .post(`api/user/login/`, tempObj)
    .then((response) => {
      if (response.data.code === 200 && response.data.status === "success") {
        dispatch(authActions.isAuthenticatedTrue());
        dispatch(cleanFilterData());
        let tempObj = {};
        tempObj["userId"] = response.data.data.user_info.id;
        tempObj["user_info"] = response.data.data.user_info;
        tempObj["profile"] = response.data.data.profile;
        tempObj["expiresIn"] = "96000";
        tempObj["idToken"] = response.data.data.token.access;
        tempObj["refreshToken"] = response.data.data.token.refresh;
        tempObj["accessToken"] = response.data.data.token.access;
        localStorage.setItem("remember_me", JSON.stringify(remember_me));
        saveTokenInStorage(tempObj);

        let add_on = { add_on: response.data.data.user_info.add_on }
        localStorage.setItem("add_on", JSON.stringify(add_on));

        let sidebar = { sidebar: response.data.data.user_info.agent_profile }
        localStorage.setItem("sidebar", JSON.stringify(sidebar));

        logout(tempObj.expiresIn * 1000,);
        success(response.data.data);
      }
      else (
        success(response.data.data)
      )
    })
    .catch((err) => {
      errorD(err);
    });
}

export function getGeolocationData(success) {
  axiosInstance.get("https://geolocation-db.com/json/").then((resp) => {
    success(resp.data);
  });
}
export function getTeamName({ token, uid }) {
  return axiosInstance
    .get(`/api/property/get-team-invitation-name?uid=${uid}&token=${token}`)
    .then((response) => response.data)
}

export function declineRequest(data = {}) {
  return axiosInstance
    .post('/api/property/team-decline-invite/', data)
}

export function ForgetPassword(userVal, success) {
  axiosInstance
      .post(`api/user/forgetpassword/`, userVal)
      .then((response) => {
        success(response);
      })
      .catch((error) => {
          console.log(error);
      });
}

export function restPassword(userVal, success) {
  axiosInstance
      .post(`api/user/reset-password/`, userVal)
      .then((response) => {
        success(response);
      })
      .catch((error) => {
          console.log(error);
      });
}


