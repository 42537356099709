import { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import "./PropertyImages.scss";
import ProperytCard from "../../../../PropertyDetails/componants/properyt-card/ProperytCard";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { getToken } from "../../../../../../appVariable/variable";
import IamgeShow from "../property-images/IamgeShow";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useDispatch, useSelector } from "react-redux";
import { updatePropertyDatail } from "../../PropertySlice";
import { removeDefaultPropertyShow } from "../../../components/mainSection/server";
import { toast } from "react-toastify";
import { updateFilterData } from "../../../../homepage/components/hero/FilterSlice";
import SelpersonCard from "../../../../allSelPerson/components/SelpersonCard";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import BrokerTeam from "./BrokerTeam";
import { Typography, Box } from '@mui/material';
import { useContactNow } from "../../../../../../hooks/useContactNow";

const PropertyImages = (props) => {
  const { propertDetails, setPropertyDetailInfo, handleChangeTown } = props;
  const [selectedIds, setSelectedIds] = useState([]);
  // const propertyDetailInStore = useSelector(state => state.propertyDetailInStore);
  const FilterDataInStore = useSelector((state) => state.FilterDataInStore);
  let token = getToken();
  let userId = token?.userId;
  const scrollRef = useRef(null);
  const TeamscrollRef = useRef(null);
  const [mainImg, setMainImg] = useState(
    propertDetails.propertDetails?.property_main_image
  );
  const [showImgPopup, setShowImgPopup] = useState(false);
  const [proppertyAllImages, setProppertyAllImages] = useState([]);
  // const [isPropertySave, setIsPropertySave] = useState(false)
  const [isPropertySave, setIsPropertySave] = useState(
    propertDetails.propertDetails?.guest_users_save_listing
  );
  const dispatch = useDispatch();
  const [contactModal, setContactModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const messageRef = useRef();
  const handleContactNow = useContactNow(selectedIds, props.propertDetails);

  const isBuy = propertDetails.propertDetails.propertylisting_type.user_listing_type === 'Buy';
  const handleSelectAll = (e) => {
    const mainBrokerIdentity = propertDetails.propertDetails?.user_profile?.identity;
    const allMembers = propertDetails?.propertyTeamMember.map((member) => member.identity);
    e.target.checked === true
      ? setSelectedIds([mainBrokerIdentity, ...allMembers])
      : setSelectedIds([]);
  };
  const handleContact = (e) => {
    setContactModal(!contactModal);
    setContactModal((state) => {
      return state;
    });
    contactModal === true ? setShowModal(false) : setShowModal(true);
  };

  const handlemessageSent = async () => {
    await handleContactNow(
      messageRef.current.value
    );

    messageRef.current.value = '';
    setSelectedIds([]);

    handleContact(false);
    toast.success("Successfully sent message");
  }

  const handleSaveProperty = () => {
    dispatch(
      updatePropertyDatail({
        propertyId: propertDetails?.propertDetails?.id,
        setshowPropertyPopup: false, // close property datail pop up
        setSavePropertyPopup: true, //open property  save pop up
      })
    );
  };

  const handelRemoveProperty = (e) => {
    setIsPropertySave(false);
    removeDefaultPropertyShow(propertDetails?.propertDetails?.id, (success) => {
      if (success.code === 200) {
        setPropertyDetailInfo((prevState) => ({
          ...prevState,
          propertDetails: {
            ...prevState.propertDetails,
            guest_users_save_listing: false,
          },
        }));
        toast.success(success.data);
        updadateProprtyListInStore(false);
      }
    });
  };
  const handleClose = () => {
    setShowModal(false);
  };
  const updadateProprtyListInStore = (value) => {
    let updatedData = FilterDataInStore.Filter_responce.map((property) =>
      property.id === propertDetails?.propertDetails?.id
        ? { ...property, guest_users_save_listing: value }
        : property
    );
    dispatch(updateFilterData({ Filter_responce: updatedData }));
  };

  useEffect(() => {
    setMainImg(propertDetails.propertDetails?.property_main_image);
    let CombineAllImg = () => {
      const mainImageUrl = `${propertDetails.propertDetails?.property_main_image}`; //  main image URL
      // console.log("proppertyAllImages", mainImageUrl)
      const subImageUrls = propertDetails?.propertyImg?.map(
        (subImage) => `${subImage.property_image}`
      ); //  the sub-image URL
      // console.log("proppertyAllImages", subImageUrls)
      const allImageUrls = [mainImageUrl, ...subImageUrls]; // combine main image URL & the sub-image URL
      // console.log("proppertyAllImages", allImageUrls)
      setProppertyAllImages(allImageUrls);
    };
    CombineAllImg();
    propertDetails.propertDetails?.guest_users_save_listing
      ? setIsPropertySave(true)
      : setIsPropertySave(false);
  }, [props]);

  const scroll = (direction) => {
    const { current } = scrollRef;
    if (direction === "left") {
      current.scrollLeft -= 150;
    } else {
      current.scrollLeft += 150;
    }
  };

  const Teamscroll = (direction) => {
    const { current } = TeamscrollRef;
    if (direction === "left") {
      current.scrollLeft -= 150;
    } else {
      current.scrollLeft += 150;
    }
  };

  return (
    <>
      <Row className="property-top m-0">
        <Col xs={6}>
          <Box className="property-Img h-100">
            <Box
              className="property-main-img h-100 skeleton pointer"
              onClick={() => {
                setShowImgPopup(true);
              }}>
              <img
                alt=""
                srcSet=""
                className="w-100 h-100"
                src={`${mainImg}`}
              />
            </Box>
          </Box>
        </Col>

        <Col xs={6}>
          <Box className="prop-text-info ">
            <Box display={'flex'} alignItems={'center'} gap={'6px'}>
              <Typography
                fontSize={'16px'}
                lineHeight={'12px'}
                color={'#315efb'}
                sx={{cursor: 'pointer'}}
                onClick={() => handleChangeTown?.(propertDetails?.propertDetails?.property_area.id)}>
                {propertDetails?.propertDetails?.property_area.area_name} |
              </Typography>
              {propertDetails?.propertDetails?.is_property_exclusive && (
                <Typography fontSize={'16px'} lineHeight={'7px'} color={'#585656'}>
                  {propertDetails?.propertDetails?.property_address_1}
                </Typography>
              )}
            </Box>

            <Box my={'14px'} display={'flex'} justifyContent={'space-between'} gap={'16px'}>
              <Box display={'flex'} gap={'14px'} width={'100%'} flexDirection={'column'}>
                <Box display={'flex'} flexDirection={'column'} gap={'11px'}>
                  <Typography color={'rgba(88, 86, 86, 1)'} fontWeight={500} fontSize={'18px'} lineHeight={'18px'}>
                    ${propertDetails?.propertDetails?.property_listing_amount}{isBuy ? '' : '/Month'}
                  </Typography>
                  <Typography color={'rgba(49, 94, 251, 1)'} fontWeight={400} fontSize={'12px'} lineHeight={'10px'}>
                    {isBuy ? 'Get - Preapproval' : '-'}
                  </Typography>
                </Box>
                <Box display={'flex'} flexDirection={'column'} gap={'11px'}>
                  <Typography color={'rgba(88, 86, 86, 1)'} fontWeight={300} fontSize={'12px'} lineHeight={'12px'}>
                    Square Feet
                  </Typography>
                  <Typography color={'rgba(88, 86, 86, 1)'} fontWeight={500} fontSize={'14px'} lineHeight={'18px'}>
                    {propertDetails.propertDetails.Square_sqft}
                  </Typography>
                </Box>
              </Box>
              <Box display={'flex'} gap={'14px'} width={'100%'} flexDirection={'column'}>
                <Box display={'flex'} flexDirection={'column'} gap={'11px'}>
                  <Typography color={'rgba(88, 86, 86, 1)'} fontWeight={300} fontSize={'14px'} lineHeight={'12px'}>
                    Bedrooms
                  </Typography>
                  <Typography color={'rgba(88, 86, 86, 1)'} fontWeight={500} fontSize={'18px'} lineHeight={'14px'}>
                    {propertDetails.propertDetails.Bedrooms}
                  </Typography>
                </Box>
                <Box display={'flex'} flexDirection={'column'} gap={'11px'}>
                  <Typography color={'rgba(88, 86, 86, 1)'} fontWeight={300} fontSize={'14px'} lineHeight={'12px'}>
                    Fee
                  </Typography>
                  <Typography color={'rgba(88, 86, 86, 1)'} fontWeight={500} fontSize={'18px'} lineHeight={'14px'}>
                    {propertDetails?.propertDetails?.fees || 'No Fee'}
                  </Typography>
                </Box>
              </Box>
              <Box display={'flex'} gap={'14px'} width={'100%'} flexDirection={'column'}>
                <Box display={'flex'} flexDirection={'column'} gap={'11px'}>
                  <Typography color={'rgba(88, 86, 86, 1)'} fontWeight={300} fontSize={'14px'} lineHeight={'12px'}>
                    Bathrooms
                  </Typography>
                  <Typography color={'rgba(88, 86, 86, 1)'} fontWeight={500} fontSize={'18px'} lineHeight={'14px'}>
                    {propertDetails.propertDetails.Bathrooms}
                  </Typography>
                </Box>
                {!isBuy && (
                  <Box display={'flex'} flexDirection={'column'} gap={'11px'}>
                    <Typography color={'rgba(88, 86, 86, 1)'} fontWeight={300} fontSize={'14px'} lineHeight={'12px'}>
                      Offer
                    </Typography>
                    <Typography color={'rgba(88, 86, 86, 1)'} fontWeight={500} fontSize={'18px'} lineHeight={'14px'}>
                      {propertDetails?.propertDetails?.property_offer || '-'}
                    </Typography>
                  </Box>
                )}
              </Box>
              {!isBuy && (
                <Box display={'flex'} gap={'14px'} width={'100%'} flexDirection={'column'}>
                  <Box display={'flex'} flexDirection={'column'} gap={'11px'}>
                    <Typography color={'rgba(88, 86, 86, 1)'} fontWeight={300} fontSize={'14px'} lineHeight={'12px'}>
                      Terms
                    </Typography>
                    <Typography color={'rgba(88, 86, 86, 1)'} fontWeight={500} fontSize={'18px'} lineHeight={'14px'}>
                      {propertDetails?.propertDetails?.property_terms || '-'}
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>

            <Box className=" position-relative property-Img TeamscrollRef">
              <Box
                style={{ left: "-15px" }}
                className="arrow left-arrow pointer center position-absolute "
                onClick={() => Teamscroll("left")}
              >
                {" "}
                <ChevronLeftIcon />{" "}
              </Box>
              <Box
                className="team-member mt-2 d-flex gap-2 hide-scrollbar pb-3"
                ref={TeamscrollRef}
              >
                <ProperytCard
                  MemberDetail={propertDetails.propertDetails?.user_profile}
                />
                {propertDetails?.propertyTeamMember?.map((MemberDetail, i) => (
                  <ProperytCard
                    key={`propertyMember${i}`}
                    MemberDetail={MemberDetail}
                  />
                ))}
              </Box>
              <Box
                style={{ right: "-5px" }}
                className="arrow right-arrow pointer center position-absolute"
                onClick={() => Teamscroll("right")}
              >
                {" "}
                <ChevronRightIcon />{" "}
              </Box>
            </Box>
          </Box>
        </Col>
      </Row>

      <Row className="property-top mt-1 m-0 position-relative">
        <Col xs={6}>
          <Box className=" h-100 d-flex flex-column gap-3 justify-content-center">
            {propertDetails?.propertyImg?.length > 0 && (
              <Box className="property-Img position-relative">
                <Box
                  className="property-sub-img d-flex gap-4 position-relative hide-scrollbar "
                  ref={scrollRef}
                >
                  {propertDetails?.propertyImg?.map((SubImg, i) => (
                    <Box
                      className="sub-img pointer skeleton"
                      key={`subimg${i}`}
                      onClick={() => setMainImg(SubImg?.property_image)}
                    >
                      <img
                        srcSet=""
                        className=" w-100 h-100"
                        alt=""
                        src={`${SubImg?.property_image}`}
                      />
                    </Box>
                  ))}
                </Box>

                <Box
                  style={{ left: "-10px" }}
                  className="arrow left-arrow pointer center position-absolute "
                  onClick={() => scroll("left")}
                >
                  <ChevronLeftIcon />
                </Box>

                <Box
                  style={{ right: "-10px" }}
                  className="arrow right-arrow pointer center position-absolute"
                  onClick={() => scroll("right")}
                >
                  <ChevronRightIcon />
                </Box>
              </Box>
            )}

            {userId ? (
              isPropertySave ? (
                //  if user login and property is save show remove listing option
                <Typography
                  className="save pointer"
                  onClick={(e) => {
                    handelRemoveProperty(e);
                  }}
                >
                  <FavoriteIcon style={{ color: "red" }} /> Remove Listing
                </Typography>
              ) : (
                //  if user login and property is not  save show save listing option
                <Typography
                  className="save pointer"
                  onClick={(e) => handleSaveProperty(e)}
                >
                  <FavoriteBorderIcon /> Save Listing
                </Typography>
              )
            ) : (
              //  if user not login & click on save show login pop up
              <Typography
                className="save pointer"
                onClick={(e) => {
                  alert("Hello! to save this property please login!!");
                }}
              >
                <FavoriteBorderIcon /> Save Listing
              </Typography>
            )}
          </Box>
        </Col>

        <Col xs={6} className=" d-flex flex-column gap-3">
          {/* {propertDetails?.propertyTeamMember?.length > 0 && */}
          <Box>
            <button
              className=" w-100 blue__btn"
              onClick={(e) => handleContact(e)}
            >
              Contact Now
            </button>{" "}
          </Box>
          {/* } */}
          {propertDetails.propertDetails?.property_listing_type
            ?.property_listing_name === "Commercial" ? (
            <Box className=" text-end ">
              <button
                className="blue__btn"
                style={{ marginBottom: "10px" }}
              >
                OM/Fl Plan
              </button>
            </Box>
          ) : (
            <Box className=" text-end ">
              <button
                className="blue__btn"
                style={{ marginBottom: "10px" }}
              >
                Floor Plan
              </button>
            </Box>
          )}
        </Col>
      </Row>
      {contactModal === true ? (
        <Modal
          className=""
          show={showModal}
          center
          size="xl"
          ria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={handleClose}
        >
          <Modal.Header
            closeButton
            className="border-bottom-0 contact_modal_header"
          >
            <Modal.Title
              id="contained-modal-title-vcenter "
              className="border-0"
            >
              <Box className="forgot_title">
                <Typography>Contact Now </Typography>
              </Box>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="contact_body">
            <Box
              className="d-flex flex-wrap gap-4 scrollS"
              style={{ overflowX: "scroll" }}
            >
              {
                <BrokerTeam
                  key={`BrokerTeam`}
                  MemberDetail={propertDetails.propertDetails?.user_profile}
                  selectedIds={selectedIds}
                  setSelectedIds={setSelectedIds}
                />
              }
              {propertDetails?.propertyTeamMember?.map((MemberDetail, i) => (
                <BrokerTeam
                  key={`BrokerTeam${i}`}
                  MemberDetail={MemberDetail}
                  MainBroker={propertDetails.propertDetails?.user_profile}
                  selectedIds={selectedIds}
                  setSelectedIds={setSelectedIds}
                />
              ))}
            </Box>

            <Box className="select_chakebox">
              <Box className="form-check center justify-content-start ps-0">
                <input
                  className=""
                  type="checkbox"
                  value=""
                  id="flexCheck"
                  onChange={(e) => {
                    handleSelectAll(e);
                  }}
                />
                <label className="form-check-label ps-2" htmlFor="flexCheck">
                  Select All
                </label>
              </Box>
            </Box>

            <Box className="message_input">
              <Box className="input-group ">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Message (Eg: I would like to see this place) "
                  ref={messageRef}
                />
              </Box>
            </Box>
          </Modal.Body>
          <Box className="border-0 pt-0 contact_footER">
            <Box className="d-flex  justify-content-between">
              <Box>
                <Button
                  className="Submit_btn blue__btn"
                  onClick={(e) => {
                    handlemessageSent(e);
                  }}
                >
                  {" "}
                  Submit
                </Button>
              </Box>
              <Box>
                <button
                  //   onClick={() => setModalShow(false)}
                  className="cancel_btn gray__btn"
                  onClick={(e) => setContactModal(false)}
                >
                  Cancel
                </button>
              </Box>
            </Box>
          </Box>
        </Modal>
      ) : (
        <Modal
          className=""
          //   show={modalShow}
          //   onHide={() => setModalShow(false)}
          show={false}
          center
          size="xl"
          ria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header
            closeButton
            className="border-bottom-0 contact_modal_header"
          >
            <Modal.Title
              id="contained-modal-title-vcenter "
              className="border-0"
            >
              <Box className="forgot_title">
                <Typography>Contact Now </Typography>
              </Box>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="contact_body">
            <Box className="d-flex flex-wrap gap-4">
              <SelpersonCard />
              <SelpersonCard />
              <SelpersonCard />
              <SelpersonCard />
              <SelpersonCard />
            </Box>

            <Box className="select_chakebox">
              <Box className="form-check center justify-content-start ps-0">
                <input className="" type="checkbox" value="" id="flexCheck" />
                <label className="form-check-label ps-2" htmlFor="flexCheck">
                  Select All
                </label>
              </Box>
            </Box>

            <Box className="message_input">
              <Box className="input-group ">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Message (Eg: I would like to see this place) "
                />
              </Box>
            </Box>
          </Modal.Body>
          <Box className="border-0 pt-0 contact_footER">
            <Box className="d-flex  justify-content-between">
              <Box>
                <Button className="Submit_btn blue__btn"> Submit</Button>
              </Box>
              <Box>
                <button
                  //   onClick={() => setModalShow(false)}
                  className="cancel_btn gray__btn"
                >
                  Cancel
                </button>
              </Box>
            </Box>
          </Box>
        </Modal>
      )}
      {/* property images full screen popup */}
      {showImgPopup && (
        <IamgeShow
          setShowImgPopup={setShowImgPopup}
          proppertyAllImages={proppertyAllImages}
        />
      )}
    </>
  );
};

export default PropertyImages;
