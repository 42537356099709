import React from 'react';
import { FieldRenderProps } from 'react-final-form';

import { Box, TextField } from '@mui/material';

import { getFieldError } from '@core/FinalForm/getFieldError';
import { styles } from '@components/forms/SubmitRequestForAdvertising/styles';
import Typography from '@mui/material/Typography';
import { updateFilterData } from '@components/pages/homepage/components/hero/FilterSlice';

export const RenderCapRateMaxField: React.FC<FieldRenderProps<number>> = ({
  input: {onChange, ...input},
  meta,
}) => {
  return (
    <div className="update-unit inner-shadow center justify-content-evenly">
      <input
        type="number"
        id=""
        min="0"
        max="100"
        placeholder="Cap Rate Max"
        onChange={(e) => {
          const inputValue = e.target.value;
          if (inputValue === '') {
            onChange('');
          } else {
            const value = Math.max(0, Math.min(100, Number(inputValue)));
            onChange(value);
          }
        }}
        {...input}
      />
    </div>
  );
};
