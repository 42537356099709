import { IconButton, TextField } from '@mui/material';
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import swal from "sweetalert";
import {
  EditListedProperty,
  GetProperty,
  getTearmsOffers,
  getagentaminities,
} from "./components/server";
import { getToken } from "../../../appVariable/variable";
import PetModal from "./components/PetModal";
import MinModal from "./components/MinModal";
import ImageModal from "./components/ImageModal";
import {
  getFullAddress,
  getRadioButtons,
  getTeammerberProfile,
  getuserSubscription,
} from "./server";
import { getAllzipcode } from "../personal/personalInformation/Server";
import moment from "moment";
import Loader from "../Loader/Loader";
import { toast } from "react-toastify";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import "./AgentSaleRental.scss";
import Advertise from "../dashboard/components/advertise/Advertise";
import { blockKeywords } from "../profile/components/personal/Personal";
import CustumeFileInput from "./components/custumeFileInput/CustumeFileInput";
import { commercialTypes, handleAmountChange, residentialTypes } from './CommonForm';
import OPenHouseTimeModal from "./components/open-house-time/OPenHouseTimeModal";
import AddressFeild from "./components/Address-feild/AddressFeild";
import { updateTotalListing } from "../dashboard/DashboardSlice";
import { useDispatch } from "react-redux";
import { styles } from './styles';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Box from '@mui/material/Box';
import { v4 as uuidv4 } from 'uuid';
import { setUserDetails } from '@REST/api';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';

// let ConvertDateFormat = (listate) => {
//     const dateString = listate.toString();
//     // const dateString = '06-15-23';
//     const parts = dateString.split('-');

//     // Parse the date parts
//     const year = parseInt(parts[2], 10) + 2000;
//     const month = parseInt(parts[0], 10) - 1;
//     const day = parseInt(parts[1], 10);

//     // Create a new Date object
//     const date = new Date(year, month, day);

//     // Format the date to the desired format
//     const formattedDate = date.toString();
//     return formattedDate

// }

const EditProperty = (props) => {
  let token = getToken();
  // let userId = token?.userId;
  // const listingdataref = useRef();
  // const listDate = useRef();
  // const listingEndDate = useRef();
  const dispatch = useDispatch();
  const mainImage = useRef();
  const floreImage = useRef();
  const [types, setTypes] = useState([]);
  const [imgfile, setImgfile] = useState(0);
  const [pdffile, setPdffile] = useState(0);
  const [images, setImages] = useState([]);
  const [imagemodalpopup, setImagemodalpopup] = useState(false);
  const [address, setAddress] = useState("");
  const [area, setArea] = useState("-");
  const [city, setCity] = useState("-");
  const [state, setState] = useState("-");
  const [areaId, setAreaId] = useState();
  const [cityId, setCityId] = useState();
  const [stateId, setStateId] = useState();
  const [countryId, setCountryId] = useState();
  const [addressOptions, setAddressOptions] = useState([]);
  const [displayAutocomplete, setDisplayAutocomplete] = useState(true);
  const [allZipcodes, setAllZipcodes] = useState();
  const [zipcodeid, setZipcodeid] = useState();
  const [allaminities, setAllaminities] = useState([]);
  const [termsOffers, setTermsOffers] = useState({});
  const [petModalValue, setPetModalValue] = useState(false);
  const [minModalValue, setMinModalValue] = useState(false);
  const [nofeeBlock, setNofeeBlock] = useState(true);
  const [petModalResponce, setPetModalResponce] = useState([]);
  const [minModalResponce, setMinModalResponce] = useState(null);
  const [radiobuttns, setRadiobuttns] = useState(null);
  const [propertyInfo, setPropertyInfo] = useState(null);
  const [residentialListing, setResidentialListing] = useState(false);
  const [commertialListing, setCommertialListing] = useState(false);
  const [defaultZipcode, setDefaultZipcode] = useState({});
  const navigate = useNavigate();
  const [teamMember, setTeamMember] = useState();
  const [noFee, setNoFee] = useState();
  const [selectedTeamMember, setSelectedTeamMember] = useState([]);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState();
  const [propertyAmount, setPropertyAmount] = useState();
  let [loading, setLoading] = useState(false);
  const [leisureSelect, setLeisureSelect] = useState(false);
  const [openHouseModal, setOpenHouseModal] = useState(false);
  const [propertyListingTypeId, setPropertyListingTypeId] = useState();
  const [openHouseData, setOpenHouseData] = useState({
    sunday_open_house: [],
    monday_open_house: [],
    tuesday_open_house: [],
    wednesday_open_house: [],
    thursday_open_house: [],
    friday_open_house: [],
    saturday_open_house: [],
  });
  const context = useOutletContext();
  const { id } = useParams();

  const fileselect = (event, position) => {
    if (position === "img") {
      setImgfile(event.target.files.length);
      setImages(Object.values(event.target.files));
    } else {
      setPdffile(event.target.files.length);
    }
  };
  useEffect(() => {
    let timerOut = setTimeout(() => {
      getFullAddress(address, (success) => {
        let newArr1 = success[0]?.map((item) => {
          return {
            label: `${item?.area_name}, ${item?.city_master.city_name}, ${item?.city_master.state_master.state_name}`,
            area: item?.area_name,
            area_id: item?.id,
            city: item?.city_master.city_name,
            city_id: item?.city_master.id,
            state: item?.city_master.state_master.state_name,
            state_id: item?.city_master.state_master.id,
            country: item?.city_master.state_master.country_master.country_name,
            country_id: item?.city_master.state_master.country_master.id,
          };
        });
        //for-city
        let newArr2 = success[1]?.map((item) => {
          return {
            label: `${item?.city_name}, ${item?.state_master.state_name} `,
            area: "- ",
            area_id: null,
            city: item?.city_name,
            city_id: item?.id,
            state: item?.state_master.state_name,
            state_id: item?.state_master.id,
          };
        });
        setAddressOptions([...newArr1, ...newArr2]);
      });
    }, 1000);
    return () => clearTimeout(timerOut);
  }, [address]);

  const TeammerberProfile = () => {
    getTeammerberProfile((success) => {
      let info = success?.map((person) => {
        return {
          id: person.id,
          profile: person.profile_image,
          name: `${person.first_name} ${person.last_name}`,
          rating: person.rating,
        };
      });
      setTeamMember(info);
    });
  };

  const getSubscritionType = async () => {
    await getuserSubscription((success) => {
      setTypes(success[0]);
    });
  };

  const handleaddclick = () => {
    formik.setFieldValue("otherCommertialInputs", [
      ...formik.values.otherCommertialInputs,
      { space: "", size: "", term: "", rate: "", type: "" },
    ]);
  };
  const handleremove = (currentSlot) => {
    let filterSlot = formik.values.otherCommertialInputs.filter(
      (x) =>
        x.space !== currentSlot.space &&
        x.size !== currentSlot.size &&
        x.term !== currentSlot.term &&
        x.rate !== currentSlot.rate &&
        x.type !== currentSlot.type
    );
    formik.setFieldValue(
      "otherCommertialInputs",
      filterSlot.length
        ? filterSlot
        : [{ space: "", size: "", term: "", rate: "", type: "" }]
    );
  };

  useEffect(() => {
    getSubscritionType();
    TeammerberProfile();
    getagentaminities((success) => {
      setAllaminities(success);
    });

    getTearmsOffers((success) => {
      setTermsOffers(success);
    });

    GetProperty(id, (success) => {
      if (success.data.code === 200 && success.data.status === "success") {
        setPropertyInfo(success.data.data);
        let data = success.data.data;
        setOpenHouseData({
          sunday_open_house: data.open_house_property?.Sunday,
          monday_open_house: data.open_house_property?.Monday,
          tuesday_open_house: data.open_house_property?.Tuesday,
          wednesday_open_house: data.open_house_property?.Wednesday,
          thursday_open_house: data.open_house_property?.Thursday,
          friday_open_house: data.open_house_property?.Friday,
          saturday_open_house: data.open_house_property?.Saturday,
        });
        formik.setFieldValue(
          "property_listing_type",
          data?.propertylisting_type.id
        );
        setPropertyListingTypeId(data?.propertylisting_type.id);
        let radioObj = {
          Property_listing_type_id: data?.propertylisting_type.id,
        };
        getRadioButtons(radioObj, (success) => {
          setRadiobuttns(success);
        });

        if (
          data?.property_listing_type.property_listing_name === "Residential"
        ) {
          setResidentialListing(true);
        }
        if (
          data?.property_listing_type.property_listing_name === "Commercial"
        ) {
          setCommertialListing(true);
        }

        //seting team members in property
        setSelectedTeamMember(data?.property_teammember);
        formik.setFieldValue('openHouseDate', data?.open_house_property);

        formik.setFieldValue("address", data?.property_address_1);
        formik.setFieldValue("appartment", data?.Apt);
        formik.setFieldValue("listingAmount", data?.property_listing_amount);
        setPropertyAmount(data?.property_listing_amount);
        formik.setFieldValue(
          "listingType",
          data?.is_property_exclusive ? "exclusive" : "open"
        );
        formik.setFieldValue("Title", data?.property_title);
        formik.setFieldValue("message", data?.propert_description);
        formik.setFieldValue("fee", data?.fees);
        formik.setFieldValue(
          "listingDate",
          data?.start_date ? moment(data?.start_date).toDate() : ''
        );
        formik.setFieldValue(
          "endDate",
          data?.end_date
            ? moment(
                data?.end_date
              ).toDate()
            : ""
        );
        formik.setFieldValue("listDate", moment(data?.created_date).format("YYYY-MM-DD"));
        formik.setFieldValue("listTime", data?.created_time.slice(0, 5));

        formik.setFieldValue(
          "mainCategory",
          `${data?.property_main_category.id}`
        );
        const strs = Array.from(data?.property_amenities, (x) => `${x}`);
        formik.setFieldValue("amenities", strs);
        formik.setFieldValue("zipcode", data?.property_zip);
        formik.setFieldValue("otherCommertialInputs", data?.property_space?.length
          ? data?.property_space
          : [{ space: "", size: "", term: "", rate: "", type: "" }]);
        formik.setFieldValue("Open_House", data?.open_house);

        getAllzipcode((success) => {
          let newarr = success.map((item) => {
            return { label: `${item.Zipcode}`, id: item.id };
          });
          setAllZipcodes([...newarr]);
          let filterZipcode = newarr?.filter(
            (item) => item.label === data?.property_zip
          );
          setDefaultZipcode(filterZipcode[0]);
        });

        setArea(data?.property_area?.area_name);
        setCity(data?.property_city?.city_name);
        setState(data?.property_state?.state_name);
        setAreaId(data?.property_area?.id ? data?.property_area?.id : null);
        setCityId(data?.property_city?.id ? data?.property_city?.id : null);
        setStateId(data?.property_state?.id ? data?.property_state?.id : null);
        // setCountryId(data?.country_id);
        setDisplayAutocomplete(false);
        formik.setFieldValue(
          "areaId",
          data?.property_area?.id ? data?.property_area?.id : null
        );
        formik.setFieldValue(
          "cityId",
          data?.property_city?.id ? data?.property_city?.id : null
        );
        formik.setFieldValue(
          "stateId",
          data?.property_state?.id ? data?.property_state?.id : null
        );
        // formik.setFieldValue("countryId", data?.country_id);

        //residential input

        formik.setFieldValue("Bedrooms", data?.Bedrooms);
        formik.setFieldValue("Bathrooms", data?.Bathrooms);
        formik.setFieldValue("SquareSqft", data?.Square_sqft);
        formik.setFieldValue("ExteriorSqft", data?.Exterior_Sqft);
        formik.setFieldValue("MaintenanceFee", data?.Maintence_fee);
        formik.setFieldValue("RealEstateTax", data?.Real_Estate_Tax);
        formik.setFieldValue("Financing", data?.Financing);
        formik.setFieldValue("MinimumDown", data?.Minimum_Down);
        formik.setFieldValue("Units", data?.Units);
        formik.setFieldValue("Rooms", data?.Rooms);
        formik.setFieldValue("BathRooms", data?.Bathrooms);
        formik.setFieldValue("Block", data?.Block);
        formik.setFieldValue("Lot", data?.Lot);
        formik.setFieldValue("Zone", data?.Zone);
        formik.setFieldValue("BuildingSqft", data?.Building_Sqft);
        formik.setFieldValue("LotDimensions", data?.Lot_Dimensions);
        formik.setFieldValue("BuildingDimensions", data?.Building_Dimension);
        formik.setFieldValue("Stories", data?.Stories);
        formik.setFieldValue("FAR", data?.FAR);
        formik.setFieldValue("Assessment", data?.Assessment);
        formik.setFieldValue("AnnualTaxes", data?.Annual_Taxes);
        formik.setFieldValue("AvailableAirRights", data?.Available_Air_Rights);
        formik.setFieldValue("property_condition", data?.property_condition);
        formik.setFieldValue("lease_type", data?.lease_type);
        formik.setFieldValue("cap_rate", data?.cap_rate);
        formik.setFieldValue("class_type", data?.class_type);
        formik.setFieldValue("occupancy", data?.occupancy);
        formik.setFieldValue("min_30_shows", data?.min_30_shows ? 1 : 0);

        //show  30 minute show propery data
        let tempObj = {
          sunday: data?.property_30_min.Sunday,
          monday: data?.property_30_min.Monday,
          tuesday: data?.property_30_min.Tuesday,
          wednesday: data?.property_30_min.Wednesday,
          thrusday: data?.property_30_min.Thursday,
          friday: data?.property_30_min.Friday,
          saturday: data?.property_30_min.Saturday,
        };
        setSelectedTimeSlot(tempObj);
        // setMinModalResponce(tempObj)
      }
      // if (success.data.code == 400 && success.data.status == "success") {
      //     success(success.data.data);
      // }
    });
  }, []);

  const handleChange = (e) => {
    const { checked, name } = e.target;
    if (checked) {
      formik.setFieldValue("amenities", [...formik.values.amenities, name]);
    } else {
      formik.setFieldValue(
        "amenities",
        formik.values.amenities.filter((v) => v !== name)
      );
    }
  };

  const minDay = (date = new Date(), count = 1) => {
    date.setDate(date.getDate() - count);

    return date;
  }

  const formik = useFormik({
    initialValues: {
      address: "",
      appartment: "",
      zipcodeid: zipcodeid,
      zipcode: "",
      areaId: areaId,
      cityId: cityId,
      stateId: stateId,
      countryId: countryId,
      term: "",
      offer: "",
      listingDate: "",
      endDate: "",
      listingType: "",
      noFees: false,
      fee: "",
      BuyerAgency: "",
      SellerAgency: "",
      petNotSure: "",
      petFriendly: "",
      // Open_House: false,
      min_30_shows: 0,
      air_rights: 0,
      class: null,
      propertyImage: [],
      propertymainimage: null,
      propertymainfloarplan: null,
      propertyPdf: [],
      mainCategory: "",
      Bedrooms: 0,
      Bathrooms: 0,
      SquareSqft: 0,
      ExteriorSqft: 0,
      MaintenanceFee: 0,
      RealEstateTax: 0,
      Financing: 0,
      MinimumDown: 0,
      Units: 0,
      Rooms: 0,
      BathRooms: 0,
      Block: 0,
      Lot: 0,
      Zone: 0,
      SquareFt: 0,
      BuildingSqft: 0,
      LotDimensions: 0,
      BuildingDimensions: 0,
      Stories: 0,
      FAR: 0,
      Assessment: 0,
      AnnualTaxes: 0,
      AvailableAirRights: 0,
      property_condition: 0,
      lease_type: 0,
      cap_rate: 0,
      occupancy: 0,
      otherCommertialInputs: [
        { space: "", size: "", term: "", rate: "", type: "" },
      ],
      openHouseDate: [
        { id: uuidv4(), start_date: "", end_date: "", },
      ],
      amenities: [],
      Title: "",
      message: "",
      listingAmount: "",
      listDate: "",
      listTime: "",
      propertysubcategoryid: 0,
      property_listing_type: 0,
    },
    validationSchema: Yup.object({
      listingType: Yup.string().required("*required"),
      Title: Yup.string().required("*required"),
      listingAmount: Yup.string().required("required"),
      mainCategory: Yup.string().required("*required"),
      listingDate: Yup.date()
        .min(minDay(), 'Listing date must be after or equal to today')
        .test("four-digits", "Invalid Date", function (value) {
          if (!value) {
            return true; // Let Yup handle empty values
          }
          const year = new Date(value).getFullYear();
          return year.toString().length === 4;
        })
        .required("*required"),
      endDate: Yup.date()
        .min(Yup.ref("listingDate"), "End date can't be before Start date")
        .test("four-digits", "Invalid Date", function (value) {
          if (!value) {
            return true; // Let Yup handle empty values
          }
          const year = new Date(value).getFullYear();
          return year.toString().length === 4;
        }),
      // .required("*required"),
      listDate:
        props.residentialUser &&
        Yup.date()
          .test("four-digits", "Invalid Date", function (value) {
            if (!value) {
              return true; // Let Yup handle empty values
            }
            const year = new Date(value).getFullYear();
            return year.toString().length === 4;
          })
          .required("*required"),

      address: Yup.string().required("*required"),
      listTime: props.residentialUser && Yup.string().required("*required"),
      // areaId: Yup.string().required("*required"),
      zipcode: Yup.string().required("*required"),
      areaId: Yup.number().required("*required").nullable(),
      cityId: Yup.number().required("*required").nullable(),
      stateId: Yup.number().required("*required").nullable(),
      propertysubcategoryid:
        leisureSelect && Yup.string().required("*required"),
      fee: nofeeBlock && Yup.string().required("*required").nullable(),

      // otherCommertialInputs: Yup.array(
      //     Yup.object({
      //         space: Yup.string()
      //             .required('start time needed'),
      //         size: Yup.string()
      //             .required('End time  needed'),
      //         term: Yup.string()
      //             .required('End time  needed'),
      //         rete: Yup.string()
      //             .required('End time  needed'),
      //         type: Yup.string()
      //             .required('End time  needed'),
      //     })
      // )
    }),
    onSubmit: (values, action) => {
      let formData = new FormData();
      formData.append("propertytitle", formik.values.Title);
      formData.append(
        "propertydescription",
        formik.values.message.replace(blockKeywords, "")
      );
      formik.values.propertymainimage &&
        formik.values.propertymainimage.forEach((image) => {
          formData.append("propertymainimage", image);
        });

      formik.values.propertymainfloarplan &&
        formData.append(
          "propertymainfloarplan",
          formik.values.propertymainfloarplan
        );
      formik.values.propertysubcategoryid && formData.append(
        "propertysubcategoryid",
        formik.values.propertysubcategoryid
      );
      formData.append("propertymaincategoryid", formik.values.mainCategory);
      formData.append("property_address_1", formik.values.address);
      formData.append("propertycity", formik.values.cityId);
      formData.append("propertystate", formik.values.stateId);
      formData.append("propertyarea", formik.values.areaId);
      formData.append("property_zip", formik.values.zipcode);
      formData.append("property_terms", formik.values.term);
      formData.append("property_offer", formik.values.offer);
      formData.append("is_property_fee", !formik.values.noFees ? 1 : 0);
      formData.append("property_listing_amount", formik.values.listingAmount);
      formData.append("appartment", formik.values.appartment);
      formData.append(
        "start_date",
        moment(formik.values.listingDate).format("YYYY-MM-DD")
      );
      formData.append(
        "end_date",
        moment(formik.values.endDate).format("YYYY-MM-DD") === "Invalid date"
          ? ""
          : moment(formik.values.endDate).format("YYYY-MM-DD")
      );
      formData.append(
        "is_property_exclusive",
        formik.values.listingType === "exclusive" ? 1 : 0
      );
      formData.append("property_pet_friendly", formik.values.petFriendly);
      formData.append("min_30_shows", formik.values.min_30_shows);
      formData.append("air_rights", formik.values.air_rights);
      formData.append("class_type", formik.values.class ?? '');
      !formik.values.noFees && formData.append("fees", formik.values.fee);
      !formik.values.noFees &&
        formData.append("SellerAgency", formik.values.SellerAgency);
      !formik.values.noFees &&
        formData.append("BuyerAgency", formik.values.BuyerAgency);
      formData.append("Bedrooms", formik.values.Bedrooms);
      formData.append("Bathrooms", formik.values.Bathrooms);
      formData.append("Square_sqft", formik.values.SquareSqft);
      formData.append("Exterior_Sqft", formik.values.ExteriorSqft);
      formData.append("Maintence_fee", formik.values.MaintenanceFee);
      formData.append("Real_Estate_Tax", formik.values.RealEstateTax);
      formData.append("Financing", formik.values.Financing);
      formData.append("Minimum_Down", formik.values.MinimumDown);
      formData.append("Units", formik.values.Units);
      formData.append("Rooms", formik.values.Rooms);
      formData.append("Block", formik.values.Block);
      formData.append("Lot", formik.values.Lot);
      formData.append("Zone", formik.values.Zone);
      formData.append("Building_Sqft", formik.values.BuildingSqft);
      formData.append("Lot_Dimensions", formik.values.LotDimensions);
      formData.append("Building_Dimension", formik.values.BuildingDimensions);
      formData.append("Stories", formik.values.Stories);
      formData.append("FAR", formik.values.FAR);
      formData.append("Assessment", formik.values.Assessment);
      formData.append("Annual_Taxes", formik.values.AnnualTaxes);
      formData.append("Available_Air_Rights", formik.values.AvailableAirRights);
      formData.append("property_condition", formik.values.property_condition ?? '');
      formData.append("lease_type", formik.values.lease_type ?? '');
      formData.append("cap_rate", formik.values.cap_rate);
      formData.append("occupancy", formik.values.occupancy);
      let aminityarray = formik.values.amenities.map(Number);
      formData.append("aminity", JSON.stringify(aminityarray));
      formData.append("No_sure_Pet_allowed", formik.values.petNotSure ? 1 : 0);
      formData.append(
        "propertylisting_type",
        formik.values.property_listing_type
      );
      formData.append(
        "created_date",
        moment(formik.values.listDate).format("YYYY-MM-DD")
      );
      formData.append("created_time", formik.values.listTime);
      formData.append(
        "pets",
        minModalResponce ? JSON.stringify(petModalResponce) : JSON.stringify([])
      );
      formData.append(
        "Space_avaliable",
        JSON.stringify(formik.values.otherCommertialInputs)
      );
      formData.append(
        "open_house_property",
        JSON.stringify(formik.values.openHouseDate.map((item) => {
          if (typeof item.id === 'string') {
            return {
              start_date: item.start_date,
              end_date: item.end_date,
            };
          } else {
            return item;
          }
        }))
      );
      formData.append("agentuserprofileid", JSON.stringify(selectedTeamMember));
      setLoading(true);
      EditListedProperty(formData, propertyInfo.id, (success) => {
        if (success.data.code === 200 && success.data.status === "success") {
          toast.success("Property Updated Successfully", "success");
          setLoading(false);
          navigate("/agent-dashboard/current-listing");
          action.resetForm();
          ResetCommonForm();
          formik.resetForm();
        }
        if (success.data.code === 400 && success.data.status === "failed") {
          toast.error("Something Went Wrong");
          setLoading(false);
        }
      },
        () => {
        toast.warning("Something Went Wrong");
        setLoading(false);
      });
    },
  });

  let ResetCommonForm = () => {
    if (mainImage.current) {
      mainImage.current.value = "";
    }
    if (floreImage.current) {
      floreImage.current.value = "";
    }
    const activeElement = document.getElementsByClassName("userInputNumber");
    Array.prototype.forEach.call(activeElement, function (slide, index) {
      slide.value = "";
    });
    setDisplayAutocomplete(true);
    setMinModalResponce(null);
    setPetModalResponce([]);
  };

  function handleUploadBannerImg(e) {
    let fileSize = e.target.files[0]?.size;
    if (fileSize <= 5250000) {
      const files = e.target.files;
      let formData = new FormData();
      const fileResponsesArray = [];
      let ImgObj = {};
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        fileResponsesArray.push(files[i]);
        formData.append("images", file);
        ImgObj[i] = file;
      }
      formik.setFieldValue("propertymainimage", fileResponsesArray);
    } else {
      swal("", "Image size must be under 5mb", "warning");
    }
  }

  function handleUploadPdf(e) {
    let fileSize = e.target.files[0]?.size;
    if (fileSize <= 5250000) {
      const file = e.target.files[0];
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        formik.setFieldValue("propertymainfloarplan", file);
      };
    } else {
      swal("", "Image size must be under 5mb", "warning");
    }
  }

  let checkLeisure = (e) => {
    if (e.target.id === "Leisure") {
      setLeisureSelect(true);
    } else {
      setLeisureSelect(false);
    }
  };

  let FormatAmount = (e) => {
    const result = handleAmountChange(e.target.value);
    setPropertyAmount(result);
    const AmtWithoutComma = result.replace(/,/g, "");
    formik.setFieldValue("listingAmount", AmtWithoutComma);
  };

  const radioButton = (e, id, type) => {
    let iscommercial;
    if (e) {
      iscommercial = type.includes("Commercial");
    }
    setPropertyListingTypeId(id);
    let radioObj = { Property_listing_type_id: id };
    getRadioButtons(radioObj, (success) => {
      setRadiobuttns(success);
    });

    if (iscommercial) {
      setResidentialListing(false);
      setCommertialListing(true);
    } else {
      setResidentialListing(true);
      setCommertialListing(false);
    }
    if (type === "Rental") {
      setNoFee(true);
    } else {
      setNoFee(false);
    }
  };

  const addOpenHouse = (id) => {
    const openHouseList = [...formik.values.openHouseDate];

    const newOpenHouseItem = { id: uuidv4(), start_date: "", end_date: "", };
    openHouseList.push(newOpenHouseItem);

    formik.setFieldValue('openHouseDate', openHouseList);
  };

  const removeOpenHouse = (id) => {
    const openHouseList = formik.values.openHouseDate.filter((item) => item.id !== id);

    formik.setFieldValue('openHouseDate', openHouseList);
  };

  const handleStartDateChange = (date, index) => {
    const openHouseList = [...formik.values.openHouseDate];

    if (date) {
      openHouseList[index] = {
        ...openHouseList[index],
        start_date: date.format('YYYY-MM-DDTHH:mm:ss'),
      };

      formik.setFieldValue('openHouseDate', openHouseList);
    }

  };

  const handleEndDateChange = (date, index) => {
    const openHouseList = [...formik.values.openHouseDate];

    if (date) {
      openHouseList[index] = {
        ...openHouseList[index],
        end_date: date.format('YYYY-MM-DDTHH:mm:ss'),
      };

      formik.setFieldValue('openHouseDate', openHouseList);
    }
  };

  const getMinDateForNextItem = (index) => {
    if (index === 0 || formik.values.openHouseDate.length === 1) return new Date();

    const previousItemDate = new Date(formik.values.openHouseDate[index - 1].listDate);
    return new Date(previousItemDate.setDate(previousItemDate.getDate() + 1));
  };

  const handleListingDateChange = (date) => {
    if (date) {
      formik.setFieldValue("listingDate", date.format('YYYY-MM-DDTHH:mm:ss'));
    }
  };

  const handleListingEndDateChange = (date) => {
    if (date) {
      formik.setFieldValue("endDate", date.format('YYYY-MM-DDTHH:mm:ss'));
    }
  };

  return (
    <>
      {loading ? <Loader /> : null}
      <div className="agent-rental w-100 agent-main d-flex flex-grow-1 gap-4">
        <div className="agent-main flex-grow-1 hide-scrollbar">
          <div className="radio-btn-bar d-flex gap-3 align-items-center mb-1">
            {types?.map((type, i) => (
                <div
                  className="d-flex align-items-center gap-2 "
                  key={`radio${i}`}
                >
                  <input
                    type="radio"
                    name="type"
                    id={`${type.listing_type}`}
                    className="listing-category"
                    value={type.id}
                    onChange={(e) => {
                      formik.resetForm();
                      setPropertyAmount('');
                      formik.setFieldValue(
                        'property_listing_type',
                        e.target.value,
                      );
                      radioButton(e, type.id, type.listing_type);
                    }}
                    checked={`${type.id}` === `${propertyListingTypeId}`}
                  />
                  <label
                    htmlFor={`${type.listing_type}`}
                    className="label-text pointer"
                  >
                    {type.listing_type}
                  </label>
                </div>
              ))}
          </div>

          <form className=" d-flex flex-grow-1 ">
            <div className="d-flex flex-grow-1 flex-column gap-4">
              {/* uppper-sectio */}
              <div className="rent-inner-section sale-middle-sec d-flex flex-column">
                <div className="upper-section d-flex justify-content-between gap-2">
                  <div className="upper-section-left d-flex flex-column gap-2 flex-grow-1">
                    <div className=" d-flex justify-content-between">
                      <div className="d-flex align-items-center w-75 inputbox ">
                        <p className={`formtext inputLable`}>
                          Address<span>*</span>
                        </p>
                        <input
                          type="text"
                          id=""
                          placeholder=""
                          required
                          className=" flex-grow-1"
                          name="address"
                          onChange={formik.handleChange}
                          value={formik.values.address}
                        />
                        {formik.errors.address && formik.touched.address ? (
                          <div className="validation-text">
                            {formik.errors.address}
                          </div>
                        ) : null}
                      </div>
                      <div
                        className={`d-flex align-items-center inputbox`}
                        style={{ width: "63px" }}
                      >
                        <input
                          type="number"
                          className="w-100 formtext"
                          id=""
                          placeholder="Apt#"
                          name="appartment"
                          onChange={formik.handleChange}
                          value={formik.values.appartment}
                          required
                        />
                      </div>
                    </div>

                    <div className=" position-relative pt-2">
                      <AddressFeild formik={formik} />
                    </div>

                    {/* condtional row */}
                    {/* {!displayAutocomplete ?
                                            <>
                                                <div className=" position-relative pt-2">
                                                    <Row className="second-row mx-0 align-items-center">
                                                        <Col sm className="second-row-in  me-2">
                                                            <div className="d-flex align-items-center inputbox ">
                                                                <p className={`formtext inputLable`}>Town</p>
                                                                <p className="w-75 formtext">{area}</p>
                                                            </div>
                                                        </Col>
                                                        <Col sm className="second-row-in me-2">
                                                            <div className="d-flex align-items-center inputbox ">
                                                                <p className={`formtext inputLable`}>City</p>
                                                                <p className="w-75 formtext">{city}</p>
                                                            </div>
                                                        </Col>
                                                        <Col sm className="second-row-in me-2">
                                                            <div className="d-flex align-items-center inputbox ">
                                                                <p className={`formtext inputLable`}>State</p>
                                                                <p className="w-75 formtext">{state}</p>
                                                            </div>
                                                        </Col>
                                                        <Col sm className="second-row-in">
                                                            <div className="d-flex align-items-center inputbox ">
                                                                <p className={`formtext inputLable`}>Zipcode</p>
                                                                <Select className=' w-100 ps-0 custum-react-select select-wrapper'
                                                                    options={allZipcodes}
                                                                    value={[{ label: defaultZipcode?.label, value: defaultZipcode?.value },]}
                                                                    classNamePrefix="select"
                                                                    // value={[{ label: zipcodeid?.label, id: zipcodeid?.id }]}
                                                                    onChange={(e) => {
                                                                        setDefaultZipcode(e)
                                                                        setZipcodeid(e);
                                                                        formik.setFieldValue("zipcodeid", e.id);
                                                                        formik.setFieldValue("zipcode", e.label);
                                                                    }}
                                                                />
                                                            </div>
                                                            {formik.errors.zipcode && formik.touched.zipcode ? (
                                                                <div className='validation-text'>{formik.errors.zipcode}</div>
                                                            ) : null}
                                                        </Col>
                                                    </Row>
                                                    <span className="pointer position-absolute "
                                                        style={{ left: '-12px', top: '8px' }}
                                                        onClick={() => setDisplayAutocomplete(!displayAutocomplete)}><EditIcon style={{ width: '15px', height: '15px' }} /></span>
                                                </div>
                                            </> :
                                            <div className=" d-flex">
                                                <div className="d-flex align-items-center w-100 inputbox ">
                                                    <p className={`formtext inputLable`}>Address<span>*</span></p>
                                                    <Autocomplete
                                                        className="hide-downBtn  w-100 area-autocomplete"
                                                        disablePortal
                                                        placeholder="hhghrtg"
                                                        id="combo-box-demo"
                                                        options={addressOptions}
                                                        onChange={(event, value) => {
                                                            setArea(value?.area);
                                                            setCity(value?.city);
                                                            setState(value?.state);
                                                            setAreaId(value?.area_id);
                                                            setCityId(value?.city_id);
                                                            setStateId(value?.state_id);
                                                            setCountryId(value?.country_id);
                                                            setDisplayAutocomplete(false);
                                                            formik.setFieldValue("areaId", value?.area_id);
                                                            formik.setFieldValue("cityId", value?.city_id);
                                                            formik.setFieldValue("stateId", value?.state_id);
                                                            formik.setFieldValue("countryId", value?.country_id);
                                                        }}
                                                        isOptionEqualToValue={(option, value) =>
                                                            option.id === value.id
                                                        }
                                                        renderInput={(params) => (
                                                            <TextField  {...params}
                                                                onChange={(e) => { setAddress(e.target.value);}}
                                                                variant="standard"
                                                            />
                                                        )}
                                                    />
                                                    {formik.errors.areaId && formik.touched.areaId ? (
                                                        <div className='validation-text'>{formik.errors.areaId}</div>
                                                    ) : null}
                                                </div>

                                            </div>
                                        } */}

                    <Row className="second-row mx-0 justify-content-end">
                      {/* <Col sm className="second-row-in me-2">
                                                <div className="d-flex align-items-center inputbox ">
                                                    <input type="text" id="" className="calenderIcon bg-transparent border-0 w-100"
                                                        ref={listingdataref}
                                                        placeholder="Listing Date"
                                                        onFocus={() => (listingdataref.current.type = "date")}
                                                        onBlur={() => (listingdataref.current.type = "text")}
                                                        name="listingDate"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.listingDate}
                                                        required />
                                                </div>
                                                {formik.errors.listingDate && formik.touched.listingDate ? (
                                                    <div className='validation-text'>{formik.errors.listingDate}</div>
                                                ) : null}

                                            </Col>

                                            {formik.values.listingType === "open" ?
                                                null
                                                :
                                                <Col sm className="second-row-in   me-2">
                                                    <div className="d-flex align-items-center inputbox ">
                                                        <input type="text" id="" placeholder="End Date" className="calenderIcon bg-transparent border-0 w-100" required
                                                            ref={listingEndDate}
                                                            onFocus={() => (listingEndDate.current.type = "date")}
                                                            onBlur={() => (listingEndDate.current.type = "text")}
                                                            name="endDate"
                                                            onChange={formik.handleChange}
                                                            value={formik.values.endDate}
                                                        />
                                                    </div>
                                                    {formik.errors.endDate && formik.touched.endDate ? (
                                                        <div className='validation-text'>{formik.errors.endDate}</div>
                                                    ) : null}
                                                </Col>
                                            } */}

                      <Col sm className="second-row-in me-2">
                        <DatePicker
                          sx={styles.picker}
                          label={'Listing Date*'}
                          inputFormat="MM/DD/YYYY HH:mm"
                          value={formik.values.listingDate ? dayjs(formik.values.listingDate) : null}
                          onChange={(date) => handleListingDateChange(date)}
                          renderInput={(params) => <TextField {...params} placeholder={'End Date'} />}
                          minDate={dayjs(new Date())}
                        />
                        {formik.errors.listingDate &&
                        formik.touched.listingDate ? (
                          <div className="validation-text">
                            {formik.errors.listingDate}
                          </div>
                        ) : null}
                      </Col>

                      {formik.values.listingType === "open" ? null : (
                        <Col sm className="second-row-in me-2">
                          <DatePicker
                            sx={styles.picker}
                            label={'End Date'}
                            inputFormat="MM/DD/YYYY HH:mm"
                            value={formik.values.endDate ? dayjs(formik.values.endDate) : null}
                            onChange={(date) => handleListingEndDateChange(date)}
                            renderInput={(params) => <TextField {...params} placeholder={'End Date'} />}
                            minDate={dayjs(formik.values.listingDate || new Date())}
                          />
                          {formik.errors.endDate && formik.touched.endDate ? (
                            <div className="validation-text">
                              {formik.errors.endDate}
                            </div>
                          ) : null}
                        </Col>
                      )}

                      <Col
                        sm
                        className="second-row-in align-self-end d-flex flex-column gap-2 h-100 type-radio"
                      >
                        <div className="d-flex justify-content-between">
                          <div className="d-flex align-items-center gap-1">
                            <input
                              type="radio"
                              name="type"
                              id="Exclusive"
                              value="exclusive"
                              onChange={(e) => {
                                formik.setFieldValue(
                                  "listingType",
                                  e.target.value
                                );
                              }}
                              checked={
                                formik.values.listingType === "exclusive"
                              }
                            />
                            <label
                              htmlFor="Exclusive"
                              className="formtext pointer"
                            >
                              Exclusive
                            </label>
                          </div>
                          <div className="d-flex align-items-center gap-1">
                            <input
                              type="radio"
                              name="type"
                              id="Open"
                              value="open"
                              onChange={(e) => {
                                formik.setFieldValue(
                                  "listingType",
                                  e.target.value
                                );
                              }}
                              checked={ formik.values.listingType === "open" }
                            />
                            <label htmlFor="Open" className="formtext pointer">
                              Open
                            </label>
                          </div>
                        </div>
                        {formik.errors.listingType &&
                        formik.touched.listingType ? (
                          <div className="validation-text">
                            {formik.errors.listingType}
                          </div>
                        ) : null}
                      </Col>
                    </Row>

                    {/* <Row style={{ minHeight: '24px' }}>
                                            {noFee && <Col xs={12} md={4} className="d-flex align-self-end">
                                                <div className="d-flex align-items-center gap-1">
                                                    <input type="checkbox" name="noFee" id="fee"
                                                        onChange={(e) => { formik.setFieldValue("noFees", e.target.checked); setNofeeBlock(!e.target.checked) }}
                                                    />
                                                    <label htmlFor="fee" className="formtext pointer">
                                                        No Fees
                                                    </label>
                                                </div>
                                            </Col>}
                                            {nofeeBlock && <Col xs={6} md={8}>
                                                <>
                                                    <div className="d-flex align-items-center inputbox ">
                                                        <input type="number" placeholder="Fees*" id="" className=" flex-grow-1"
                                                            name="fee"
                                                            onChange={formik.handleChange}
                                                            value={formik.values.fee}
                                                        />
                                                        {formik.errors.fee && formik.touched.fee ? (
                                                            <div className='validation-text'>{formik.errors.fee}</div>
                                                        ) : null}
                                                    </div>
                                                </>
                                            </Col>}
                                        </Row> */}

                    <Row style={{ minHeight: "24px" }}>
                      {props.noFee && (
                        <Col xs={3}>
                          <div className="d-flex align-items-center gap-1 h-100">
                            <input
                              type="checkbox"
                              name="noFee"
                              id="fee"
                              onChange={(e) => {
                                formik.setFieldValue(
                                  "noFees",
                                  e.target.checked
                                );
                                setNofeeBlock(!e.target.checked);
                              }}
                            />
                            <label htmlFor="fee" className="formtext pointer">
                              No Fees
                            </label>
                          </div>
                        </Col>
                      )}
                      {nofeeBlock && (
                        <Col xs={props.noFee ? 3 : 4}>
                          <div className="d-flex align-items-center inputbox ">
                            <input
                              type="number"
                              placeholder="Fees*"
                              id=""
                              className=" flex-grow-1"
                              name="fee"
                              onChange={formik.handleChange}
                              value={formik.values.fee}
                            />
                          </div>
                          {formik.errors.fee && formik.touched.fee ? (
                            <div className="validation-text">
                              {formik.errors.fee}
                            </div>
                          ) : null}
                        </Col>
                      )}
                      {nofeeBlock && (
                        <Col xs={props.noFee ? 3 : 4}>
                          <div className="d-flex align-items-center inputbox ">
                            <input
                              type="number"
                              placeholder="Seller Agency"
                              id=""
                              className=" flex-grow-1"
                              name="SellerAgency"
                              onChange={formik.handleChange}
                              value={formik.values.SellerAgency}
                            />
                          </div>
                        </Col>
                      )}
                      {nofeeBlock && (
                        <Col xs={props.noFee ? 3 : 4}>
                          <div className="d-flex align-items-center inputbox ">
                            <input
                              type="number"
                              placeholder="Buyer Agency"
                              id=""
                              className=" flex-grow-1"
                              name="BuyerAgency"
                              onChange={formik.handleChange}
                              value={formik.values.BuyerAgency}
                            />
                          </div>
                        </Col>
                      )}
                    </Row>

                    {/* show tearms and offers for listing_type 1 & 3 */}
                    {(radiobuttns?.[0]?.listing_type == "1" ||
                      radiobuttns?.[0]?.listing_type == "3") && (
                      <Row>
                        <Col>
                          <div className="d-flex align-items-center inputbox pb-0 ">
                            <select
                              aria-label="Default select example"
                              className=" w-100"
                              onChange={(e) => {
                                formik.setFieldValue("term", e.target.value);
                              }}
                            >
                              <option className=" d-none">Terms</option>
                              {termsOffers?.Terms?.map((term, i) => (
                                <option key={`terms${i}`} value={term.terms}>
                                  {term.terms}
                                </option>
                              ))}
                            </select>
                          </div>
                        </Col>
                        <Col>
                          <div className="d-flex align-items-center inputbox pb-0">
                            <select
                              aria-label="Default select example"
                              className=" w-100"
                              onChange={(e) => {
                                formik.setFieldValue("offer", e.target.value);
                              }}
                            >
                              <option className=" d-none">Offer</option>
                              {termsOffers?.Offer?.map((offer, i) => (
                                <option key={`offers${i}`} value={offer.offer}>
                                  {offer.offer}
                                </option>
                              ))}
                            </select>
                          </div>
                        </Col>
                      </Row>
                    )}

                    <div className="d-flex justify-content-between gap-2">
                      <div className="radio-btn-bar d-flex gap-3 align-items-center">
                        {residentialListing && (
                          <>
                            <div className="d-flex align-items-center gap-1">
                              <input
                                type="radio"
                                name="more_types"
                                id={'sure'}
                                onChange={(e) => {
                                  formik.setFieldValue('petNotSure', true);
                                  formik.setFieldValue('PetFriendly', 0);
                                  formik.setFieldValue('min_30_shows', 0);
                                }}
                                checked={formik.values?.petNotSure}
                              />
                              <label htmlFor={'sure'} className="label-text pointer">
                                Not Sure Pets Allowed
                              </label>
                            </div>
                            <div className="d-flex align-items-center gap-1">
                              <input
                                type="radio"
                                name="more_types"
                                id="PetFriendly"
                                onChange={(e) => {
                                  formik.setFieldValue('petNotSure', false);
                                  formik.setFieldValue('PetFriendly', 1);
                                  formik.setFieldValue('min_30_shows', 0);
                                }}
                                style={{ borderRadius: '50%' }}
                                checked={formik.values?.PetFriendly === 1}
                              />
                              <label
                                htmlFor="PetFriendly"
                                className="formtext pointer"
                              >
                                Pet Friendly
                              </label>
                            </div>
                          </>
                        )}

                        <div className="d-flex align-items-center gap-1">
                          <input
                            type="radio"
                            name="more_types"
                            id="minute"
                            onChange={(e) => {
                              formik.setFieldValue('petNotSure', false);
                              formik.setFieldValue('PetFriendly', 0);
                              formik.setFieldValue('min_30_shows', 1);
                            }}
                            style={{ borderRadius: '50%' }}
                            checked={formik.values?.min_30_shows === 1}
                          />
                          <label htmlFor="minute" className="formtext pointer">
                            30 Minute Showing
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="upper-divider d-none d-md-block"></div>
                  <div
                    className="d-flex flex-column flex-grow-1 gap-2"
                    style={{ maxWidth: "45%" }}
                  >
                    <div className="d-flex align-items-center inputbox ">
                      <p className="formtext inputLable">
                        Listing Amount<span>*</span>
                      </p>
                      <div className=" flex-grow-1 d-flex">
                        <input
                          type="text"
                          className=" w-100"
                          id=""
                          placeholder=""
                          required
                          name="listingAmount"
                          onChange={(e) => {
                            FormatAmount(e);
                          }}
                          value={propertyAmount}
                        />
                        {formik.errors.listingAmount &&
                        formik.touched.listingAmount ? (
                          <div className="validation-text">
                            {formik.errors.listingAmount}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    {residentialListing && (
                      <>
                        <p className="formtext">Open House</p>
                        {formik.values.openHouseDate.map((item, index) => (
                          <Row key={item.id}>
                            <Col>
                              <Box display={'flex'} gap={2}>
                                <DesktopDateTimePicker
                                  sx={styles.picker}
                                  label={'Start Date'}
                                  inputFormat="MM/DD/YYYY HH:mm"
                                  value={item.start_date ? dayjs(item.start_date) : null}
                                  onChange={(date) => handleStartDateChange(date, index)}
                                  renderInput={(params) => {
                                    return <TextField {...params} placeholder={'Start Date'} />;
                                  }}
                                  minDate={dayjs(getMinDateForNextItem(index))}
                                  slotProps={{
                                    textField: {
                                      helperText: !!formik.errors.openHouseDate && !!formik.errors.openHouseDate[index]?.start_date ? formik.errors.openHouseDate[index]?.start_date : "",
                                      error: !!formik.errors.openHouseDate && !!formik.errors.openHouseDate[index]?.start_date,
                                    },
                                  }}
                                />
                                <DesktopDateTimePicker
                                  sx={styles.picker}
                                  label={'End Date'}
                                  inputFormat="MM/DD/YYYY HH:mm"
                                  value={item.end_date ? dayjs(item.end_date) : null}
                                  onChange={(date) => handleEndDateChange(date, index)}
                                  renderInput={(params) => <TextField {...params} placeholder={'End Date'} />}
                                  disabled={!item.start_date}
                                  minDateTime={dayjs(item.start_date)}
                                  slotProps={{
                                    textField: {
                                      helperText: !!formik.errors.openHouseDate && !!formik.errors.openHouseDate[index]?.end_date ? formik.errors.openHouseDate[index]?.end_date : "",
                                      error: !!formik.errors.openHouseDate && !!formik.errors.openHouseDate[index]?.end_date,
                                    },
                                  }}
                                />
                              </Box>
                            </Col>
                            <div className="add-remove-btn d-flex align-items-end">
                              {formik.values.openHouseDate.length - 1 === index && formik.values.openHouseDate.length < 5 ? (
                                <IconButton
                                  sx={{ padding: 0 }}
                                  className="pointer add-btn"
                                  onClick={() => addOpenHouse(item.id)}
                                  disabled={!item.start_date || !item.end_date}
                                >
                                  <AddCircleOutlineIcon />
                                </IconButton>
                              ) : (
                                <IconButton
                                  sx={{ padding: 0 }}
                                  className="pointer remove-btn"
                                  onClick={() => removeOpenHouse(item.id)}
                                >
                                  <RemoveCircleOutlineIcon />
                                </IconButton>
                              )}
                            </div>
                          </Row>
                        ))}
                      </>
                    )}

                    {commertialListing && (
                      <p className=" fw-bold">Space Available</p>
                    )}
                    {commertialListing &&
                      (formik.values?.otherCommertialInputs).map(
                        (time, index) => (
                          <div key={`commertiaIm${index}`}>
                            <Row>
                              <Col>
                                <div className="d-flex align-items-center inputbox ">
                                  <input
                                    type="text"
                                    id=""
                                    className="calenderIcon bg-transparent border-0 w-100"
                                    placeholder="Space"
                                    name={`otherCommertialInputs.${index}.space`}
                                    onChange={formik.handleChange}
                                    value={
                                      formik.values.otherCommertialInputs[index]
                                        ?.space
                                    }
                                  />
                                  {formik.errors.commertialSpace &&
                                  formik.touched.commertialSpace ? (
                                    <div className="validation-text">
                                      {formik.errors.commertialSpace}
                                    </div>
                                  ) : null}
                                </div>
                              </Col>
                              <Col>
                                <div className="d-flex align-items-center inputbox ">
                                  <input
                                    type="number"
                                    id=""
                                    className="calenderIcon bg-transparent border-0 w-100"
                                    placeholder="Size"
                                    name={`otherCommertialInputs.${index}.size`}
                                    onChange={formik.handleChange}
                                    value={
                                      formik.values.otherCommertialInputs[index]
                                        ?.size
                                    }
                                  />
                                  {formik.errors.commertialSize &&
                                  formik.touched.commertialSize ? (
                                    <div className="validation-text">
                                      {formik.errors.commertialSize}
                                    </div>
                                  ) : null}
                                </div>
                              </Col>
                              <Col>
                                <div className="d-flex align-items-center inputbox ">
                                  <input
                                    type="text"
                                    id=""
                                    className="calenderIcon bg-transparent border-0 w-100"
                                    placeholder="Term"
                                    name={`otherCommertialInputs.${index}.term`}
                                    onChange={formik.handleChange}
                                    value={
                                      formik.values.otherCommertialInputs[index]
                                        ?.term
                                    }
                                  />
                                  {formik.errors.commertialTerm &&
                                  formik.touched.commertialTerm ? (
                                    <div className="validation-text">
                                      {formik.errors.commertialTerm}
                                    </div>
                                  ) : null}
                                </div>
                              </Col>
                              <Col>
                                <div className="d-flex align-items-center inputbox ">
                                  <input
                                    type="number"
                                    id=""
                                    className="calenderIcon bg-transparent border-0 w-100"
                                    placeholder="Rate"
                                    name={`otherCommertialInputs.${index}.rate`}
                                    onChange={formik.handleChange}
                                    // value={formik.values.otherCommertialInputs[index]?.rete}
                                    value={
                                      formik.values.otherCommertialInputs[index]
                                        ?.rate
                                    }
                                  />
                                  {formik.errors.commertialRate &&
                                  formik.touched.commertialRate ? (
                                    <div className="validation-text">
                                      {formik.errors.commertialRate}
                                    </div>
                                  ) : null}
                                </div>
                              </Col>
                              <Col>
                                <div className="d-flex align-items-center inputbox ">
                                  <input
                                    type="text"
                                    id=""
                                    className="calenderIcon bg-transparent border-0 w-100"
                                    placeholder="Type"
                                    name={`otherCommertialInputs.${index}.type`}
                                    onChange={formik.handleChange}
                                    value={
                                      formik.values.otherCommertialInputs[index]
                                        ?.type
                                    }
                                  />
                                  {formik.errors.commertialType &&
                                  formik.touched.commertialType ? (
                                    <div className="validation-text">
                                      {formik.errors.commertialType}
                                    </div>
                                  ) : null}
                                </div>
                              </Col>
                              <div className="add-remove-btn d-flex align-items-end">
                                {formik.values.otherCommertialInputs.length -
                                  1 ===
                                index ? (
                                  <AddCircleOutlineIcon
                                    className="pointer add-btn"
                                    onClick={() => {
                                      handleaddclick();
                                    }}
                                  />
                                ) : (
                                  <RemoveCircleOutlineIcon
                                    className="pointer remove-btn"
                                    onClick={() => {
                                      handleremove(
                                        formik.values.otherCommertialInputs[
                                          index
                                        ]
                                      );
                                    }}
                                  />
                                )}
                              </div>
                            </Row>
                          </div>
                        )
                      )}
                  </div>
                </div>

                <div className=" bottom-sec">
                  <div className="d-flex flex-column bottom-box gap-2">
                    {/* file upload-sec */}
                    <div className=" d-flex flex-wrap  gap-3 justify-content-between">
                      <div className="d-flex flex-column file-input ">
                        <div className="d-flex justify-content-between">
                          <p className="formtext inputLable border-0">
                            File Pictures<span>*</span>
                          </p>
                          <p
                            className="file-number pointer"
                            onClick={() => setImagemodalpopup(true)}
                          >
                            {`${imgfile} Image Upload`}
                          </p>
                        </div>
                        <CustumeFileInput
                          id="MainImgEdit"
                          multiple={true}
                          accept="image/*"
                          onChange={(e) => {
                            fileselect(e, "img");
                            handleUploadBannerImg(e);
                          }}
                        />
                        {formik.errors.propertymainimage ? (
                          <div className="validation-text">
                            {formik.errors.propertymainimage}
                          </div>
                        ) : null}
                      </div>

                      <div className="d-flex flex-column file-input ">
                        <div className="d-flex justify-content-between">
                          <p className="formtext">
                            Upload floor plans or OM PDF only
                          </p>
                          {/* <p className="file-number pointer">{`${pdffile} pdf Upload`}</p> */}
                        </div>
                        <CustumeFileInput
                          id="floorPlanEdit"
                          multiple={false}
                          accept="application/pdf"
                          onChange={(e) => {
                            handleUploadPdf(e);
                          }}
                        />
                        {formik.errors.propertymainfloarplan ? (
                          <div className="validation-text">
                            {formik.errors.propertymainfloarplan}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    {/* Radio buttons group*/}
                    <div className="d-flex flex-wrap radio-group justify-content-center justify-content-md-between mt-1">
                      {radiobuttns?.map((radiobtn, index) => (
                        <div
                          className="d-flex align-items-center"
                          style={{ gap: "7px" }}
                          key={index}
                        >
                          <input
                            type="radio"
                            name="mainCategory"
                            id={radiobtn.Main_category}
                            value={radiobtn.id}
                            onChange={formik.handleChange}
                            checked={
                              formik.values.mainCategory == `${radiobtn.id}`
                            }
                          />
                          <label
                            htmlFor={radiobtn.Main_category}
                            className="formtext pointer"
                          >
                            {radiobtn.Main_category}
                          </label>
                        </div>
                      ))}
                    </div>

                    {leisureSelect && (
                      <Form.Select
                        aria-label="Default select example"
                        className="Leisure-subcat border-1"
                        style={{ maxWidth: "150px" }}
                        onChange={(e) => {
                          formik.setFieldValue(
                            "propertysubcategoryid",
                            e.target.value
                          );
                        }}
                      >
                        <option className=" d-none">Sub Type</option>
                        <option value="0">Industrial</option>
                        <option value="1">Specialty</option>
                      </Form.Select>
                    )}
                    {formik.errors.mainCategory &&
                    formik.touched.mainCategory ? (
                      <div className="validation-text ">
                        {formik.errors.mainCategory}
                      </div>
                    ) : null}

                    {residentialListing && (
                      <div className="d-flex flex-wrap  btn-grp-gap ">
                        {residentialTypes.filter((item) => {
                          if (propertyListingTypeId === 1) {
                            return item.isRental;
                          }
                          return true;
                        }).map((singlebtn, index) => {
                          return (
                            <input
                              type="number"
                              key={`input${index}`}
                              className="userInputNumber"
                              placeholder={singlebtn.title}
                              name={singlebtn.name}
                              onChange={formik.handleChange}
                              value={!!formik.values[singlebtn.name] ? formik.values[singlebtn.name] : ''}
                            />
                          );
                        })}
                      </div>
                    )}

                    {commertialListing && (
                      <div className="d-flex flex-wrap  btn-grp-gap ">
                        {commercialTypes.filter((item) => {
                          if (propertyListingTypeId === 3) {
                            return item.isLeasing;
                          }
                          return true;
                        }).map((singlebtn, index) => (
                          <input
                            type="number"
                            key={`commertialInput${index}`}
                            className="userInputNumber"
                            placeholder={singlebtn.title}
                            name={singlebtn.name}
                            onChange={formik.handleChange}
                            value={!!formik.values[singlebtn.name] ? formik.values[singlebtn.name] : ''}
                          />
                        ))}
                      </div>
                    )}

                    {/* {Amenities Buttons} */}
                    {residentialListing && (
                      <div className=" ">
                        <p
                          className="formtext mb-2"
                          style={{ fontWeight: '500', marginBottom: '6px' }}
                        >
                          Amenities
                        </p>
                        <div className="Amenities d-flex  flex-wrap ">
                          {allaminities?.map((aminiti, i) => (
                            <div
                              className="select-aminity position-relative"
                              key={`aminity${i}`}
                            >
                              <input
                                type="checkbox"
                                className="Prop aminity-input visually-hidden"
                                id={`filteraminity${i}`}
                                name={`${aminiti.id}`}
                                checked={formik.values.amenities.includes(
                                  `${aminiti.id}`
                                )}
                                onChange={handleChange}
                              />
                              <label
                                htmlFor={`filteraminity${i}`}
                                className="pointer aminity"
                              >
                                {aminiti.amenities_name}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Bottom-sectio */}
              <div
                className={`d-flex flex-column rent-inner-section d-flex flex-column mb-3`}
              >
                <p
                  className="formtext"
                  style={{ fontWeight: "500", marginBottom: "6px" }}
                >
                  Description
                </p>

                <div className="d-flex align-items-end flex-wrap gap-3 descBox ">
                  <div
                    className="d-flex  flex-column flex-grow-1"
                    style={{ gap: "12px" }}
                  >
                    <div className="d-flex align-items-center  inputbox">
                      <p
                        className="formtext inputLable"
                        style={{ width: "9%" }}
                      >
                        Title<span>*</span>
                      </p>
                      <input
                        type="text"
                        id=""
                        placeholder=""
                        required
                        className="w-100"
                        name="Title"
                        onChange={formik.handleChange}
                        value={formik.values.Title.replace(blockKeywords, "")}
                      />
                      {formik.errors.Title && formik.touched.Title ? (
                        <div className="validation-text">
                          {formik.errors.Title}
                        </div>
                      ) : null}
                    </div>

                    <div className="d-flex inputbox">
                      <p
                        className="formtext inputLable"
                        style={{ width: "9%" }}
                      >
                        Message
                      </p>
                      <textarea
                        className="formtext bg-transparent border-0 w-100 "
                        placeholder="Lorem ipsum dolor sit amet consectetur adipisicing elit. Odit quae assumenda officiis ab error officia."
                        required
                        name="message"
                        cols="30"
                        rows="4"
                        onChange={formik.handleChange}
                        value={formik.values.message.replace(blockKeywords, "")}
                      />
                    </div>
                  </div>
                  <Button
                    style={{ borderRadius: "20px" }}
                    onClick={formik.handleSubmit}
                  >
                    Submit
                  </Button>
                  {/* <Button onClick={formik.handleSubmit} disabled={!props.licenseApprove}>Submit</Button> */}
                </div>
              </div>
            </div>
          </form>
        </div>

        <Advertise
          title="TEAM"
          teamMember={teamMember}
          setSelectedTeamMember={setSelectedTeamMember}
          selectedTeamMember={selectedTeamMember}
        />
      </div>

      {imagemodalpopup && (
        <ImageModal
          modalShow={imagemodalpopup}
          setModalShow={setImagemodalpopup}
          images={images}
          formik={formik}
        />
      )}
      {minModalValue && (
        <MinModal
          minModalValue={minModalValue}
          setMinModalValue={setMinModalValue}
          setMinModalResponce={setMinModalResponce}
          selectedTimeSlot={selectedTimeSlot}
        />
      )}
      <PetModal
        setPetModalValue={setPetModalValue}
        petModalValue={petModalValue}
        setPetModalResponce={setPetModalResponce}
      />
      {openHouseModal && (
        <OPenHouseTimeModal
          openHouseData={openHouseData}
          setOpenHouseData={setOpenHouseData}
          openHouseModal={openHouseModal}
          setOpenHouseModal={setOpenHouseModal}
        />
      )}
    </>
  );
};

export default EditProperty;
