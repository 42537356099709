import React from 'react';
import { styles } from './styles';
import { Box, IconButton, Dialog } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import useAspectRatio from '../../../../../../../hooks/useAspectRatio';

type ShowFullDocumentProps = {
  show: boolean;
  handleClose: () => void;
  url: string;
};

export const ShowFullDocument: React.FC<ShowFullDocumentProps> = ({ show, handleClose, url }) => {
  const [aspectRatio] = useAspectRatio(url);

  return (
     <Dialog
       sx={styles.root}
       open={show}
       disableEscapeKeyDown={!handleClose}
       PaperProps={{
         sx: styles.imageWrapperSwap(aspectRatio as number),
       }}
       onClose={handleClose}>
         <Box
           component={'img'}
           src={url}
           borderRadius={'10px'}
         />
         <IconButton
           sx={styles.closeButton}
           onClick={handleClose}
           color={'primary'}>
           <CloseIcon
             fontSize={'small'}
             htmlColor={'#000'} />
         </IconButton>
     </Dialog>
  );
};
