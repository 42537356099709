import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import "./ForgetPass.scss";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import Validation from './Validation';
import { restPassword } from './server';
import Loader from '../Loader/Loader';
import { AuthPasswordLayout } from "../../../layouts";
import Box from '@mui/material/Box';

const ResetPass = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState("");
    const [confpassword, setConfPassword] = useState("")
    const [error, setError] = useState("");
    const [confPassError, setConfPassError] = useState("")
    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [isRevealConfirmPwd, setIsRevealConfirmPwd] = useState(false)

    const urlString = window.location.href

    const paramString = urlString.split('?')[1];

    let queryString = new URLSearchParams(paramString);
    const urlUid = queryString.get("uid");
    const urlToken = queryString.get("token");

    const handleConfirmPassword = () => {
        let upperCase = /^(?=.*[A-Z])/;
        let Lowercase = /^(?=.*[a-z])/;
        let specialChar = /^(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])/;
        let digitRegex = /(?=.*?[0-9])/;

        if (password === "") {
            setError("please Enter Password ");
            return;
        } else {
            setError(" ");
        }
        if (password.length < 8) {
            setError("please Enter Password mim 8 char");
            return;
        } else {
            setError(" ");
        }
        if (!upperCase.test(password)) {
            setError("please Enter One Uppercase Value");
            return;
        } else {
            setError(" ");
        }
        if (!Lowercase.test(password)) {
            setError("please Enter One Lowercase Value");
            return;
        } else {
            setError(" ");
        }
        if (!specialChar.test(password)) {
            setError("please Enter One SpecialChar Value");
            return;
        } else {
            setError(" ");
        }
        if (!digitRegex.test(password)) {
            setError("please Enter One Char in number Value");
            return;
        } else {
            setError(" ");
        }

        let userVal = {
            password: password,
            password2: confpassword,
            token: urlToken,
            uid: urlUid,
        }


        if (confpassword !== password) {
            setConfPassError("Please Match The Password")
        } else {
            setConfPassError("");
            restPassword(userVal, (success) => {
                setLoading(true)
                if (success.data.code === 200 && success.data.status === "success") {
                    toast.success('Now you can Sign In to your account', "success");
                    setLoading(false)
                    navigate("/login");
                }
                if (success.data.code === 400 && success.data.status === "failed") {
                    setLoading(false)
                    toast.warning(success.data.data, "warning");
                }
                setLoading(!loading)

            })
        }
    };
    return (
        <div>
            {loading ? <Loader /> : null}
            <AuthPasswordLayout
                title='Reset Password'
            >
                <div className="loginpage_inputs" style={{ maxWidth: "500px", margin: "auto" }}>
                    <div className="input-container">
                        <span>
                            Password{" "}
                            <span
                                style={{
                                    fontSize: 12,
                                    margin: 0,
                                    color: "#CFF4FF",
                                }}
                            >
                                (Set new password)
                            </span>
                        </span>
                        <Box width={'100%'} position={'relative'}>
                            <input
                              className="input-field"
                              style={{
                                  paddingRight: '35px',
                              }}
                              type={isRevealPwd ? "text" : "password"}
                              placeholder="Password"
                              name="password"
                              autoComplete="off"
                              onChange={(e) => setPassword(e.target.value)}
                              value={password}
                            />
                            {/* view icon of password */}
                            <div
                              style={{
                                  cursor: 'pointer',
                                  display: 'flex',
                                  position: 'absolute',
                                  right: '5px',
                                  top: '50%',
                                  transform: 'translateY(-50%)',
                              }}
                              onClick={() => setIsRevealPwd(prevState => !prevState)}>
                                {isRevealPwd ? <VisibilityOffOutlinedIcon /> : <RemoveRedEyeOutlinedIcon />}
                            </div>
                        </Box>
                    </div>
                    {error ? (
                      <div className='validation-text log-validation'>{error}</div>
                    ) : null}
                    {/* validation code start */}
                    <Validation formikPass={password} />
                    {/* validation code End */}
                    <div className="input-container">
                        <span>Confirm password</span>
                        <Box width={'100%'} position={'relative'}>
                            <input
                              className="input-field"
                              style={{
                                  paddingRight: '35px',
                              }}
                              type={isRevealConfirmPwd ? "text" : "password"}
                              placeholder="Confirm Password"
                              name="confirmpassword"
                              autoComplete="off"
                              onChange={(e) => setConfPassword(e.target.value)}
                              value={confpassword}
                            />
                            {/* view icon of password */}
                            <div
                              style={{
                                cursor: 'pointer',
                                display: 'flex',
                                position: 'absolute',
                                right: '5px',
                                top: '50%',
                                transform: 'translateY(-50%)',
                              }}
                              onClick={() => setIsRevealConfirmPwd(prevState => !prevState)}>
                                {isRevealConfirmPwd ? <VisibilityOffOutlinedIcon /> : <RemoveRedEyeOutlinedIcon />}
                            </div>
                        </Box>
                    </div>
                    {confPassError ? (
                      <div className='validation-text log-validation'>{confPassError}</div>
                    ) : null}
                </div>

                <div className="loginpage_help_button justify-content-center mb-3 mx-auto">
                    <div className="loginpage_signin">
                        <button className="loginpage_signin_btn" type="submit"
                                onClick={handleConfirmPassword}
                        >
                            <span className="loginpage_signin_btn_text">Reset</span>
                        </button>
                    </div>
                </div>
            </AuthPasswordLayout>
        </div>
    )
}

export default ResetPass
