import { Fragment, useMemo } from "react";
import { Message } from "../../../../store/slices/message.slice";
import { Chip } from "@mui/material";
import { MessageBox } from "@components/pages/Message/components/MessageBox";
import { format, isThisWeek, isToday, isYesterday } from "date-fns";

const getGroupName = (date: Date) => {
	switch (true) {
		case isToday(date): return 'Today';
		case isYesterday(date): return 'Yesterday';
		case isThisWeek(date): return format(date, 'E');
		default: return format(date, 'dd.MM.yyyy');
	}
}

export const useMessageGroupList = (messages: Message[]) => {
	return useMemo(() => {
		const groups: Record<string, Message[]> = {};

		for (const message of messages) {
			if (!message.dateCreated) continue;
			const groupName = getGroupName(message.dateCreated);
			const group = groups[groupName];
			!group?.length
				? groups[groupName] = [message]
				: groups[groupName] = [...group, message];
		}

		return (
			<>
				{
					Object.keys(groups).map(
						(groupName, index) =>
							<Fragment key={`${groupName}-${index}`}>
								<Chip
									label={groupName}
									size="small"
									sx={{ maxWidth: '100%', width: 'max-content', margin: '0 auto' }}
								/>
								{
									groups[groupName].map((message) =>
										<MessageBox key={message.sid} message={message} />
									)
								}
							</Fragment>
					)
				}
			</>
		)
	}, [messages]);
}