import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FillSalesPersonActionPayload, FillSavedAgentsActionPayload, SalesPersonState } from './typedefs';

const initialState: SalesPersonState = {
  isFetched: false,
  isFetchedMore: false,
  savedPersons: null,
  salesPerson: null,
};

const salesPersonSlice = createSlice({
  name: 'salesPerson',
  initialState,
  reducers: {
    startFetching(state) {
      state.isFetched = true;
    },
    stopFetching(state) {
      state.isFetched = false;
    },
    startFetchingMore(state) {
      state.isFetchedMore = true;
    },
    stopFetchingMore(state) {
      state.isFetchedMore = false;
    },
    fillSavedPersons(state, action: PayloadAction<FillSavedAgentsActionPayload>) {
      state.savedPersons = action.payload;
    },
    selectSavedPerson(state, action: PayloadAction<any>) {
      if (state.savedPersons) {
        state.savedPersons = {
          ...state.savedPersons,
          results: state.savedPersons.results.map((item) => {
            if (item.id === action.payload.id) {
              return { ...item, saved: !action.payload.checked };
            }
            return item;
          }),
        }
      }
    },
    updateSavedPerson(state, action: PayloadAction<{ ids: number[] }>) {
      if (state.savedPersons) {
        state.savedPersons = {
          ...state.savedPersons,
          results: state.savedPersons.results.filter((item) => {
            return !action.payload.ids.includes(item.id);
          }),
        }
      }
    },
    pushMoreSavedPerson(state, action: PayloadAction<FillSalesPersonActionPayload>) {
      state.savedPersons = {
        ...action.payload,
        results: [...state.savedPersons!.results, ...action.payload.results],
      };
    },
    fillSalesPerson(state, action: PayloadAction<FillSalesPersonActionPayload>) {
      state.salesPerson = action.payload;
    },
    removeSalesPerson(state, action: PayloadAction<{ ids: number[] }>) {
      if (state.salesPerson) {
        state.salesPerson = {
          ...state.salesPerson,
          results: state.salesPerson.results.map((item) => {
            if (action.payload.ids.includes(item.id)) {
              return {
                ...item,
                saved: false,
              };
            }
            return item;
          }),
        }
      }
    },
    addSalesPerson(state, action: PayloadAction<{ ids: number[] }>) {
      if (state.salesPerson) {
        state.salesPerson = {
          ...state.salesPerson,
          results: state.salesPerson.results.map((item) => {
            if (action.payload.ids.includes(item.id)) {
              return {
                ...item,
                saved: true,
              };
            }
            return item;
          }),
        }
      }
    },
    selectSalesPerson(state, action: PayloadAction<any>) {
      if (state.salesPerson) {
        state.salesPerson = {
          ...state.salesPerson,
          results: state.salesPerson.results.map((item) => {
            if (item.id === action.payload.id) {
              return { ...item, saved: action.payload.checked };
            }
            return item;
          }),
        }
      }
    },
    pushMoreSalesPerson(state, action: PayloadAction<FillSalesPersonActionPayload>) {
      state.salesPerson = {
        ...action.payload,
        results: [...state.salesPerson!.results, ...action.payload.results],
      };
    },
  },
});

export default salesPersonSlice;
