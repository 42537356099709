export const privateBook = {
  propertyId: '/property/:id',
  advertising: '/advertising',
  agentProfile: '/Agentprofile',
  advertisement: '/advertisement',
  subscription: '/subscription',
  checkOut: '/check-out',
  /////////////////////////
  dashboard: '/dashboard',
  dashboardQuickView: 'quik-view',
  dashboardQuickViews: 'quik-views',
  dashboardQuickViewId: 'quik-view/:id',
  dashboardSavedListings: 'saved-listings',
  dashboardExpireListing: 'expire-listing',
  dashboardSavedSearch: 'saved-search',
  dashboardMessage: 'message',
  dashboardReviews: 'reviews',
  dashboardSupport: 'support',
  dashboardVirtualOffice: 'virtual-office',
  dashboardProfileSetting: 'profile-setting',
  dashboardMapviewFilter: 'mapview-filter',
  dashboardMapviewCommercialFilter: 'mapview-commertial-filter',
  dashboardOpenListing: 'open-listing',
  dashboardCurrentListing: 'current-listing',
  dashboardAllSalesPerson: 'all-salesperson',
  dashboardPersonalInformation: 'personal-information',
  dashboardPropertyDetails: 'property-details',
  dashboardPropertyDetailsPopUp: 'PropertyDetailsPopUp',
  /////////////////////////
  agentDashboard: '/agent-dashboard',
  agentDashboardAddProperty: 'add-property',
  agentDashboardCheckOut: 'check-out',
  agentDashboardProfileSettings: 'profile-settings',
  agentDashboardAgentProfile: 'Agentprofile',
  agentDashboardEditProperty: 'edit-property/:id',
  agentDashboardVirtualOffice: 'virtual-office',
  agentDashboardSavedListings: 'saved-listings',
  agentDashboardExpireListing: 'expire-listing',
  agentDashboardAllSalesPerson: 'all-salesperson',
  agentDashboardAnalytic: 'analytic',
  agentDashboardReview: 'review',
  agentDashboardSupport: 'support',
  agentDashboardProfileSettingsResetPassword: 'profile-settings/reset-password',
  agentDashboardPlanAndBilling: 'plan&billing',
  agentDashboardMessage: 'message',
  agentDashboardDocument: 'document',
  agentDashboardDocumentUpload: 'document/upload',
  agentDashboardNeighbourhood: 'neighbourhood',
  agentDashboardBoostMarketing: 'boost-marketing',
  agentDashboardBoostMarketingPlans: 'boost-marketing/plans/:id',
  agentDashboardMarketingForm: 'marketing-form/:id',
  agentDashboardBoostMarketingForm: 'boost-marketing-form/:id',
  agentDashboardBoostMarketingSocialMediaForm: 'boost-marketing/social-media/form/:id',
  agentDashboardBoostMarketingPaidMediaForm: 'boost-marketing/paid-media/form/:id',
  agentDashboardCurrentListing: 'current-listing',
  agentDashboardCurrentListingMapview: 'current-listing-mapview',
  agentDashboardMapviewFilter: 'mapview-filter',
  agentDashboardMapviewCommercialFilter: 'mapview-commertial-filter',
  //////////////////////////
  agentVirtualOffice: '/agent-virtual-office',
  agentVirtualOfficeContact: 'contact',
  agentVirtualOfficeContactId: 'contact/:id',
  agentVirtualOfficeContactEdit: 'contact/edit',
  agentVirtualOfficeMapView: 'map-view',
  agentVirtualOfficeCustomerInfo: 'customer-info',
  agentVirtualOfficeMessage: 'message',
  agentVirtualOfficeDocument: 'document',
  agentVirtualOfficeDocumentUpload: 'document/upload',
  agentVirtualOfficeCreateADSPage: 'Create_ADS_page',
  agentVirtualOfficeAdvInvoiceTable: 'Adv_Invoice_Table',
  agentVirtualOfficeAdvLeadPage: 'Adv_Lead_Page',
  agentVirtualOfficeDashboard: 'dashboard',
  agentVirtualOfficeInterestedProperty: 'interested-property',
  agentVirtualOfficeDislikeProperty: 'dislike-property',
  //////////////////////////
  guestVirtualOffice: '/guest-virtual-office',
  guestVirtualOfficeHome: 'home',
  guestVirtualOfficeDashboard: 'dashboard',
  guestVirtualOfficeAgents: 'agents',
  guestVirtualOfficeDocument: 'document',
  guestVirtualOfficeDocumentSign: 'document/sign',
  guestVirtualOfficeMyMessage: 'my-message',
  guestVirtualOfficeInterestedProperty: 'interested-property',
  guestVirtualOfficeDislikeProperty: 'dislike-property',
  guestVirtualOfficeAgentsDetails: 'agents/details/:id',
  //////////////////////////
  landLoadDashboard: '/LandLoadDashboard',
  landLoadDashboardAddProperty: 'add-property',
  landLoadDashboardEditProperty: 'edit-property/:id',
  landLoadDashboardCurrentListing: 'current-listing',
  landLoadDashboardCurrentListingMapview: 'current-listing-mapview',
  landLoadDashboardExpireListing: 'expire-listing',
  landLoadDashboardMessage: 'message',
  landLoadDashboardBoostMarketing: 'boost-marketing',
  landLoadDashboardBoostMarketingPlans: 'boost-marketing/plans/:id',
  landLoadDashboardMarketingForm: 'marketing-form/:id',
  landLoadDashboardBoostMarketingForm: 'boost-marketing-form/:id',
  landLoadDashboardBoostMarketingSocialMediaForm: 'boost-marketing/social-media/form/:id',
  landLoadDashboardBoostMarketingPaidMediaForm: 'boost-marketing/paid-media/form/:id',
  landLoadDashboardVirtualOffice: 'virtual-office',
  landLoadDashboardAnalytic: 'analytic',
  landLoadDashboardReview: 'review',
  landLoadDashboardSupport: 'support',
  landLoadDashboardProfileSetting: 'profile-setting',
  landLoadDashboardProfileSettingsResetPassword: 'profile-settings/reset-password',
  landLoadDashboardPlanAndBilling: 'plan&billing',
  landLoadDashboardMapviewFilter: 'mapview-filter',
  landLoadDashboardMapviewCommercialFilter: 'mapview-commertial-filter',
};
