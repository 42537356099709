import React from 'react';
import { Box, Grid} from '@mui/material';
import Typography from '@mui/material/Typography';
import {
  PropertyFile
} from '@components/pages/neighbourhoodspecialist/component/NeighborhoodSpecialist/componants/PropertyFile';
import { INeighborhoodTown } from '@bus/neighborhood/typedefs';

type PropertyFilesProps = {
  checked: INeighborhoodTown[];
  handleChangeCheckedArea: any;
  setLoading: any;
};

export const PropertyFiles: React.FC<PropertyFilesProps> = ({ checked, handleChangeCheckedArea, setLoading }) => {
  return (
    <Box py={'20px'}>
      {!!checked.length && (
        <>
          <Typography className="upload_minimun_text text-capitalize">
            Upload 3 property file for each Area to get approval
          </Typography>
          <Box className="link-conatiner d-flex flex-column">
            <Grid container padding={'5px'}>
              <Grid item xs={4}>
                <Typography>Town</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography>Documents</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography>Status</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography>Action</Typography>
              </Grid>
            </Grid>
            <Box display={'flex'} flexDirection={'column'} gap={'5px'}>
              {checked.map((item: any) => (
                <PropertyFile
                  key={item.area.id}
                  item={item}
                  cityName={item.area.area_name}
                  status={item.status}
                  handleChangeCheckedArea={handleChangeCheckedArea}
                  setLoading={setLoading}
                />
              ))}
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};
