export const publicBook = {
  logIn: '/login',
  signUp: '/signup',
  resetPassword: '/reset-password',
  teamLogin: '/team-login',
  forgetPassword: '/forget-password',
  adSignup: '/ad-signup',
  adLogin: '/ad-login',

};
