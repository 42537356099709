export const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: '#fdfdfd',
    border: '1px solid #315efb',
    borderRadius: '4px',
    padding: '10px',
    transition: 'transform 0.3s ease-in-out',
    '&:focus, &:hover': {
      boxShadow: '0 0.5em 0.5em -0.4em',
      transform: 'translateY(-0.25em)',
    },
  },
  actionWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    '& .tab-content': {
      height: '100%',
    },
  },
};
