import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getAgentInfo } from "../server";
import icons from "../../dashboard/components/sidebar/images/SidebarIcon";
import { useNavigate } from "react-router-dom";
import { currentListingPropertyCount } from "../../currentListing/server";
import {
  expireListingPropertyCount,
  totalListingcount,
} from "../../expiredListing/server";
import { getAgentDetails } from "../../profile/components/personal/server";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Agentbioabout from "../../agentbio/Components/Agentbioabout";
import AgentCurrentlisting from "../../agentbio/Components/AgentCurrentlisting";
import AgentCloseListing from "../../agentbio/Components/AgentCloseListing";
import AgentTeams from "../../agentbio/Components/AgentTeams";
import Loader from "../../Loader/Loader";
import PropertyDetailsPopUp from "../../dashboard/propperty-datails/PropertyDetailsPopUp";

const GuestVirtualOfficeAgent = () => {
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
  const [agent, setAgent] = useState(null); // Initialize agent state as null
  const virtualofficeid = useSelector(
    (state) => state.GuestVirtualOfficeSliceInStore.guestOfficeId
  );
  const propertyDetailInStore = useSelector(
    (state) => state.propertyDetailInStore
  );
  const [propertyDetailInfo, setPropertyDetailInfo] = useState({});
  const [currentListCount, setCurrentListCount] = useState([]);
  const [expireListingCount, setExpireListingCont] = useState([]);
  const [totalListingCounts, setTotalLiastingCounts] = useState([]);
  const [getAgentDetail, setGetAgentDetail] = useState(null);
  const [coverImg, setCoverImg] = useState(icons.coverphoto);
  const [profileImg, setProfileImg] = useState(icons.profile);

  const agentInfo = () => {
    getAgentInfo(
      virtualofficeid,
      (success) => {
        if (success.data.code === 200) {
          setAgent(success.data.data);
        } else {
          setAgent(null); // Reset agent state to null
        }
      },
      (error) => {
        if (error) {
          setAgent(null); // Reset agent state to null
        }
      }
    );
  };
  useEffect(() => {
    setloading(true);
    agentInfo();
    setTotalLiastingCounts(null);
    setCurrentListCount(null);
    setExpireListingCont(null);
    setGetAgentDetail([]);
  }, [virtualofficeid]);

  // useEffect(() => {
  //   agentInfo();
  // });

  useEffect(() => {
    setloading(true);
    let paramObj = agent?.userprofile?.slug;
    console.log("paramObj", paramObj);
    if (paramObj) {
      currentListingPropertyCount(paramObj, (success) =>
        setCurrentListCount(success)
      );
      expireListingPropertyCount(paramObj, (success) =>
        setExpireListingCont(success)
      );
      totalListingcount(paramObj, (success) => setTotalLiastingCounts(success));
      getAgentDetails(paramObj, (success) => {
        setGetAgentDetail(success);
        setCoverImg(
          success[0]?.cover_image
            ? `${success[0]?.cover_image}`
            : icons.coverphoto
        );
        setProfileImg(
          success[0]?.profile_image
            ? `${success[0]?.profile_image}`
            : icons.profile
        );
        setloading(false);
      });
    }
  }, [agent]);

  return (
    <div className="agentbiomain w-100 container">
      {loading === true ? <Loader /> : null}
      {agent?.userprofile?.slug !== undefined && loading === false && (
        <main className=" pb-3 h-100 ">
          {console.log("getAgentDetail", getAgentDetail)}
          <div
            className=" h-100 hide-scrollbar agentbiomain-container "
            style={{ maxHeight: "80vh" }}
          >
            {/* <span className="agentaboutbio">Work Bio</span> */}
            {/* {!agentNameFromUrl && (
            <div className="table-btn mb-1">
              <Button
                variant="outline-primary"
                className="long-btn"
                onClick={() => Navigate(-1)}
              >
                Back
              </Button>
            </div>
          )} */}
            <section className="mb-5">
              <div className="bg-image rounded-bottom shadow-1-strong img-fluid headimg">
                <img
                  src={coverImg}
                  alt=""
                  srcSet=""
                  style={{ height: "100%" }}
                />
                <div className="d-flex justify-content-center">
                  <img
                    className="shadow-3-strong position-absolute profile img-fluid"
                    src={profileImg}
                    alt=""
                  />
                </div>
              </div>
            </section>

            <div className="d-flex justify-content-between agen-profile-listing mt-4">
              <div className="profile-name d-flex flex-column gap-2">
                <h6 className=" text-capitalize">
                  {getAgentDetail[0] !== undefined
                    ? `${getAgentDetail[0]?.first_name} ${getAgentDetail[0]?.last_name}`
                    : null}
                </h6>
                {getAgentDetail && <p>{getAgentDetail[5]?.lic_Type}</p>}
              </div>
              <div className="listing d-flex">
                {/* <div className=" pointer fw-bold">
                <CopyToClipboard
                  text={`${Hosturl}agents/${paramObj}`}
                  onCopy={() => {
                    setLinkCopied(true);
                  }}
                >
                  <Tooltip
                    title={linkCopied ? "Link Copied" : "Share your profile"}
                    placement="top"
                  >
                    <ShareIcon />
                  </Tooltip>
                </CopyToClipboard>
              </div> */}

                <div className="listing-info center flex-column ">
                  <p>Total Listing</p>
                  {totalListingCounts?.data?.code === 200 ? (
                    <p className="listing-info-price">
                      {totalListingCounts.data.data}
                    </p>
                  ) : (
                    <p className="listing-info-price">. . .</p>
                  )}
                </div>
                <div className="listing-info center flex-column ">
                  <p>Current Listing</p>
                  {!currentListCount || currentListCount?.length === 0 ? (
                    <p className="listing-info-price">. . .</p>
                  ) : (
                    <p className="listing-info-price">{currentListCount}</p>
                  )}
                </div>
                <div className="listing-info center flex-column ">
                  <p>Closed Listing</p>
                  {expireListingCount?.data?.code === 200 ? (
                    <p className="listing-info-price">
                      {expireListingCount.data.data}
                    </p>
                  ) : (
                    <p className="listing-info-price">. . .</p>
                  )}
                </div>
              </div>
            </div>

            <section className="agentbio_tabs">
              <Tabs
                defaultActiveKey="About"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                <Tab
                  eventKey="About"
                  title="About"
                  className="navbar_agentbio_tabs"
                >
                  <Agentbioabout getAgentDetails={getAgentDetail} />
                </Tab>
                {getAgentDetail[0]?.team_name && (
                  <Tab eventKey="Team" title="Team">
                    <AgentTeams getAgentDetails={getAgentDetail} />
                  </Tab>
                )}
                <Tab eventKey="Current Listing" title="Current Listing">
                  <AgentCurrentlisting
                    listing={getAgentDetail[7]}
                    onOpen={(info) => setPropertyDetailInfo(info)}
                  />
                </Tab>
                <Tab eventKey="Expired Listing" title="Expired Listing">
                  <AgentCloseListing
                    listing={getAgentDetail[8]}
                    onOpen={(info) => setPropertyDetailInfo(info)}
                  />
                </Tab>
              </Tabs>
            </section>
          </div>
        </main>
      )}

      {/* property detail pop up  */}
      {propertyDetailInStore.setshowPropertyPopup &&
        Object.keys(propertyDetailInfo).length > 0 && (
          <PropertyDetailsPopUp
            propertyDetailInfo={propertyDetailInfo} // passing info about property
          />
        )}
    </div>
  );
};

export default GuestVirtualOfficeAgent;
