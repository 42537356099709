import { FC } from "react";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Button } from "react-bootstrap";

// @ts-ignore
import bed from "../../dashboard/components/mainSection/images/bed.svg";
import { priceFormat } from "@components/pages/dashboard/components/mainSection/PropertyCard";
import { ProfileAvatar } from "@components/shared/profile-avatar";

type MessagePropertyCardProps = {
	mainImage?: string;
	listingAmount: number | null;
	city: string;
	address: string;
	bedrooms: string | null;
	slug: string;
	profileInstance?: {
		fullName: string;
		image?: string;
		initials: string;
	}
}

export const EmptyMessagePropertyCard: FC = () => {
	return (
		<Box
			className={'property-card d-flex flex-column justify-content-between pointer'}
			sx={{ minHeight: '150px' }}
		>
		</Box>
	)
}

export const MessagePropertyCard: FC<MessagePropertyCardProps> = (props) => {
	const handleClick = () => {
		window.open('/property/' + props.slug, '_blank');
	}

	return (
		<Box
			className={'property-card d-flex flex-column justify-content-between pointer'}
			onClick={handleClick}
		>
			<Box className={"property-img position-relative"}>
				<img src={props.mainImage} alt="Main Image" />
			</Box>
			<Box className={'property-detail d-flex justify-content-between align-items-center'}>
				<Box className={'property-detail-left d-flex flex-column'}>
					<Box className={'d-flex align-items-center gap-1'}>
						<Typography component={'p'} className="price">
							${priceFormat(props.listingAmount)}
						</Typography>
						<Box className={'card-divider'} />
						<Box>
							<img className="bed-img d-flex" src={bed} alt="Bed Icon" />
						</Box>
						{
							props.bedrooms
								? (
									<p className="obr text-uppercase">{`${props.bedrooms}br`}</p>
								)
								: null
						}
					</Box>
					<Box>
						<Typography component={'p'} className="mb-1">
							{props.city}
						</Typography>
						<Typography component={'p'}>
							{props.address}
						</Typography>
					</Box>
				</Box>
				<Box className="property-detail-right center flex-column gap-2">
					<Box className="center">
						<ProfileAvatar
							width={17}
							height={17}
							profileInstance={props.profileInstance}
						/>
					</Box>
					<Button
						variant="outline-primary"
						style={{ borderRadius: "20px" }}
					>
						1 Year
					</Button>
				</Box>
			</Box>
		</Box>
	)
}