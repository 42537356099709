import React, { useEffect, useState } from 'react';
import PropertyInfoComponent from "./component/PropertyInfoComponent";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { updatePropertyDatail } from "./PropertySlice";
import { getLocations } from '@components/pages/homepage/components/hero/server';
import { updateFilterData } from '@components/pages/homepage/components/hero/FilterSlice';
import { search2Actions } from '@bus/search2/actions';
import { toast } from 'react-toastify';
import { book } from '@routes/book';
import { useNavigate } from 'react-router';

const PropertyDetailsPopUp = (props) => {
  const {
    propertyDetailInfo,
    setPropertyDetailInfo,
    showLocationButton,
    showTravelview,
  } = props;
  const [modalShow, setModalShow] = useState(true);
  const [optionsList, setOptionsList] = useState([]);
  const dispatch = useDispatch();
  const FilterDataInStore = useSelector((state) => state.FilterDataInStore);
  const navigate = useNavigate();

  const hidepopup = () => {
    dispatch(
      updatePropertyDatail({
        setshowPropertyPopup: false,
        setSavePropertyPopup: false,
        propertyId: "",
        PropertyDetailInfo: {},
      })
    );
    setModalShow(false);
  };

  useEffect(() => {
    let timerOut = setTimeout(() => {
      getLocations('', (success) => {
        let newarr = success.area.map((item) => {
          return {
            label: `${
              item.area_name +
              ", " +
              item.city_master.city_name +
              ", " +
              item.city_master.state_master.state_name +
              "," +
              item.city_master.state_master.country_master.country_name
            }`,
            value: {
              area_id: item.id,
              city_id: item.city_master.id,
              state_id: item.city_master.state_master.id,
              country_id: item.city_master.state_master.country_master.id,
            },
          };
        });
        let newarr1 = success.city.map((item) => {
          return {
            label: `${
              item.city_name +
              "," +
              item.state_master.state_name +
              "," +
              item.state_master.country_master.country_name
            }`,
            value: {
              city_id: item.id,
              state_id: item.state_master.id,
              country_id: item.state_master.country_master.id,
            },
          };
        });
        let newarr2 = success.state.map((item) => {
          return {
            label: `${
              item.state_name + "," + item.country_master.country_name
            }`,
            value: {
              state_id: item.id,
              country_id: item.country_master.id,
            },
          };
        });
        setOptionsList([...newarr, ...newarr1, ...newarr2]);
      });
    }, 1000);
    return () => clearTimeout(timerOut);
  }, []);

  const locationOnChange = (e, isSearch) => {
    const location = {
      locationText: e?.label,
      area: e?.value?.area_id,
      city: e?.value?.city_id,
      state: e?.value?.state_id,
      country: e?.value?.country_id,
    };
    dispatch(
      updateFilterData(location)
    );
    if (isSearch) {
      handleSearch({ ...FilterDataInStore, ...location }, !location.area ? `${book.dashboard}/${book.dashboardQuickView}` : `${book.dashboard}/${book.dashboardPropertyDetails}`);
    }
  };

  const handleSearch = (searchSchema, url) => {
    new Promise((resolve, reject) => {
      dispatch(
        search2Actions.applySearch({
          values: searchSchema,
          resolve,
          reject,
        }),
      );
    })
      .then((data) => {
        dispatch(updateFilterData({
          ...searchSchema,
          Filter_responce: data.results,
        }));
        if (url) {
          navigate(url);
        }
      })
      .catch(() => {
        toast.error("No Property Available for this search");
      });
  };

  const handleChangeTown = (id) => {
    const selectedTown = optionsList.find((item) => id === item.value.area_id);

    selectedTown && locationOnChange(selectedTown, true);
  };

  return (
    <Modal
      // dialogClassName="modal-90w" aria-labelledby="example-custom-modal-styling-title"
      size="lg"
      aria-labelledby="example-modal-sizes-title-lg"
      show={modalShow}
      onHide={() => hidepopup()}
    >
      <Modal.Header closeButton>
        <p className="proprty-head">Property Details</p>
      </Modal.Header>
      <Modal.Body>
        <PropertyInfoComponent
          showTravelView={showTravelview}
          propertyDetailInfo={propertyDetailInfo}
          setPropertyDetailInfo={setPropertyDetailInfo}
          showLocationButton={showLocationButton}
          handleChangeTown={handleChangeTown}
        />
      </Modal.Body>
    </Modal>
  );
};

export default PropertyDetailsPopUp;
