import { call } from 'redux-saga/effects';

import {
  api,
} from '@REST/api';

import * as effects from 'redux-saga/effects';
import { PayloadActionWithPromiseMeta } from '@core/FinalForm/typedefs';

export function* sendAdvertiseRequest({
  payload,
  meta: { resolve, reject },
}: PayloadActionWithPromiseMeta<any>) {
  try {
    const body: string = yield call(JSON.stringify, payload);
    const response: Response = yield effects.apply(api, api.post, [
      { endpoint: `api/advertisement/requests/`, body, unsafe: true },
    ]);

    if (!response.ok) {
      reject();
      throw new Error('Unhandled error');
    }

    resolve();
  } catch (e) {
  } finally {
  }
}
