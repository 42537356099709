import * as effects from 'redux-saga/effects';

import { fetchClassType, fetchPropertyCondition } from './workers';
import { dashboardActions } from '../actions';

function* watchFetchClassType() {
  yield effects.takeEvery(dashboardActions.fetchClassType.type, fetchClassType);
}

function* watchFetchPropertyCondition() {
  yield effects.takeEvery(dashboardActions.fetchPropertyCondition.type, fetchPropertyCondition);
}
export function* watchDashboard() {
  yield effects.all([
    effects.call(watchFetchPropertyCondition),
    effects.call(watchFetchClassType),
  ]);
}
