import React from 'react';
import { FieldRenderProps } from 'react-final-form';

import { Box, MenuItem, TextField } from '@mui/material';

import { getFieldError } from '@core/FinalForm/getFieldError';
import { styles } from '@components/forms/SubmitRequestForAdvertising/styles';
import Typography from '@mui/material/Typography';
import { CustumSelect } from '@components/pages/dashboard/components/filter-popup/PopUpFilter';
import { updateFilterData } from '@components/pages/homepage/components/hero/FilterSlice';
import { useSelector } from 'react-redux';

export const RenderSubCategoryField: React.FC<FieldRenderProps<string>> = ({
  input,
  meta,
}) => {
  const FilterOptionsInStore = useSelector((state: any) => state.FilterOptionsInStore);

  return (
    <div className="select-property-type filter-row">
      <p className="filter-heading">Sub Type</p>
      <div className="select-box inner-shadow">
        <CustumSelect
          className="hide-downBtn hero-select"
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="subtype"
          {...input}>
          {FilterOptionsInStore?.SubTypeOptions.length > 0 ? (
            FilterOptionsInStore?.SubTypeOptions?.map(
              (item: any, index: number) => (
                <MenuItem key={`l${index}`} value={item.id}>
                  {item.label}{" "}
                </MenuItem>
              )
            )
          ) : (
            <MenuItem value={""}>No Option</MenuItem>
          )}
        </CustumSelect>
      </div>
    </div>
  );
};
