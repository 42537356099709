import { Theme } from '@mui/material/styles';

export const styles = {
  root: (theme: Theme) => ({
    background: 'rgba(142, 202, 226, 0.7)',
    padding: '22px 31px',
  }),
  buttons: (theme: Theme) => ({
  }),
  wrapper: (theme: Theme) => ({
    borderRadius: '5px',
  }),
  button: (theme: Theme) => ({
    color: '#FFF',
    position: 'absolute',
    right: 0,
    bottom: 0,
    transform: 'translate(0%, 0%)',
    height: '100%',
    width: '100%',
    zIndex: 1,
    maxWidth: '203px',
  }),
  scrolledButton: (theme: Theme) => ({
    position: 'fixed',
    right: '10px',
    bottom: '10px',
    transform: 'translate(0, 0)',
    height: '61px',
    width: '61px',
    zIndex: 100,
  }),
};
