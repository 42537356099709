import "./KeyFeature.scss";
import CheckIcon from "@mui/icons-material/Check";
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import moment from 'moment';
const KeyFeature = (props) => {
  const { propertDetails } = props;

  return (
    <div className="property-feature mt-3">
      <div style={{ marginTop: "20px" }}>
        <p className="MustSee">{propertDetails.propertDetails.property_title}</p>
      </div>
      <Box mt={'24px'}>
        <Grid container>
          <Grid item xs={6}>
            <Typography
              color={'rgba(49, 94, 251, 1)'}
              fontSize={'18px'}
              fontWeight={400}>
              {propertDetails.propertDetails.propertylisting_type.user_listing_type} Features
            </Typography>
            <Box mt={'25px'} display={'flex'} alignItems={'center'}>
              <Typography maxWidth={'130px'} width={'100%'} color={'rgba(88, 86, 86, 1)'} fontSize={'14px'} fontWeight={300}>Available:</Typography>
              <Typography color={'rgba(88, 86, 86, 1)'} fontSize={'14px'} fontWeight={300}>
                {propertDetails.SalesFeatures?.Available
                  ? propertDetails.SalesFeatures?.Available
                  : "--"}
              </Typography>
            </Box>
            <Box mt={'16px'} display={'flex'} alignItems={'center'}>
              <Typography maxWidth={'130px'} width={'100%'} color={'rgba(88, 86, 86, 1)'} fontSize={'14px'} fontWeight={300}>Type:</Typography>
              <Typography color={'rgba(88, 86, 86, 1)'} fontSize={'14px'} fontWeight={300}>
                {propertDetails.SalesFeatures?.Type
                  ? propertDetails.SalesFeatures?.Type
                  : "--"}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Typography
              color={'rgba(49, 94, 251, 1)'}
              fontSize={'18px'}
              fontWeight={400}>
              Open House Showing
            </Typography>
            <Box mt={'25px'} width={'100%'}>
              {!!propertDetails.propertDetails.open_house_property.length ? (
                <>
                  <Box mb={'19px'} display={'flex'} alignItems={'center'}>
                    <Box display={'flex'} width={'100%'}>
                      <Typography
                        color={'rgba(49, 94, 251, 1)'}
                        fontSize={'16px'}
                        fontWeight={400}>
                        Start Date
                      </Typography>
                    </Box>
                    <Box display={'flex'} width={'100%'}>
                      <Typography
                        color={'rgba(49, 94, 251, 1)'}
                        fontSize={'16px'}
                        fontWeight={400}>
                        End Date
                      </Typography>
                    </Box>
                  </Box>
                  {propertDetails.propertDetails.open_house_property.map((item) => (
                    <Box mb={'21px'} key={item.id} display={'flex'} alignItems={'center'}>
                      <Box display={'flex'} width={'100%'}>
                        <Typography color={'rgba(88, 86, 86, 1)'} fontSize={'14px'} fontWeight={300}>
                          {item.start_date ? moment.utc(item.start_date).format('MM/DD/YYYY HH:mm A') : '-'}
                        </Typography>
                      </Box>
                      <Box display={'flex'} width={'100%'}>
                        <Typography color={'rgba(88, 86, 86, 1)'} fontSize={'14px'} fontWeight={300}>
                          {item.end_date ? moment.utc(item.end_date).format('MM/DD/YYYY HH:mm A') : '-'}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </>
              ) : (
                <Typography color={'rgba(88, 86, 86, 1)'} fontSize={'14px'} fontWeight={300}>-</Typography>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box mt={'24px'}>
        <Typography
          color={'rgba(49, 94, 251, 1)'}
          fontSize={'16px'}
          fontWeight={400}>
          Amenities
        </Typography>
        {!!propertDetails.propertDetails.amenity_list.length ? (
          <Grid container rowSpacing={'14px'}>
            {propertDetails.propertDetails.amenity_list.map((amenity) => (
              <Grid item key={amenity.id} xs={12} sm={6} md={4} lg={3}>
                <Box display={'flex'} alignItems={'center'} gap={'5px'}>
                  <CheckIcon sx={{fontSize: '10px'}} htmlColor={'rgba(49, 94, 251, 1)'} />
                  <Typography color={'rgba(88, 86, 86, 1)'} fontSize={'14px'} fontWeight={300}>{amenity.amenities_value}</Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Typography color={'rgba(88, 86, 86, 1)'} fontSize={'14px'} fontWeight={300}>-</Typography>
        )}
      </Box>
      <Box mt={'24px'}>
        <Typography
          color={'rgba(49, 94, 251, 1)'}
          fontSize={'16px'}
          fontWeight={400}>
          Description
        </Typography>
        <Typography
          color={'rgba(88, 86, 86, 1)'}
          fontSize={'14px'}
          fontWeight={300}>
          {propertDetails.propertDetails.propert_description || '-'}
        </Typography>
      </Box>
    </div>
  );
};

export default KeyFeature;
