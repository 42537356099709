import React, { useEffect } from "react";
// import Mapview from '../PropertyDetails/componants/map-view/Mapview';
// import FilterForm from './components/FilterForm';
// import filter from "./images/filter.svg";
import FilterResultMap from "./components/FilterResultMap";
import ResidentialMapFilter from "./components/ResidentialMapFilter";
import search from "./images/search.svg";
import { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Switch from "@mui/material/Switch";
import { alpha, styled } from "@mui/material/styles";
import { postFilteredData } from "../guestVirtualOffice/server";
import Mapview from "../PropertyDetails/componants/map-view/Mapview";
import CommertialMapFilter from "./components/CommertialMapFilter";
import { getToken } from "../../../appVariable/variable";
import { getAccounttype } from '@components/pages/personal/typeOfAccount/server';
import { updateFilterData } from '@components/pages/homepage/components/hero/FilterSlice';
import { Autocomplete, IconButton, TextField } from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import { search2Actions } from '@bus/search2/actions';
import { toast } from 'react-toastify';
import { getTypesBasedOnCategory } from '@components/pages/homepage/components/hero/server';

const PinkSwitch = styled(Switch)(({ theme }) => ({
  ".MuiSwitch-track": {
    backgroundColor: "#315EFB",
    opacity: "1",
    height: "120%",
    transform: "translate(0px, -1px)",
  },
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#315EFB",
    "&:hover": {
      backgroundColor: alpha("#315EFB", theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#315EFB",
    height: "120%",
    transform: "translate(0px, -1px)",
  },
  "& .MuiSwitch-thumb": {
    color: "white",
    border: "1px solid rgba(88, 86, 86, 1)",
  },
}));
const label = { inputProps: { "aria-label": "Switch demo" } };

const MapviewFilter = (props) => {
  const [listing, setlisting] = useState([]);
  const [filteredListing, setFilteredListing] = useState([]);
  const FilterDataInStore = useSelector((state) => state.FilterDataInStore);
  const dispatch = useDispatch();
  const filter = useSelector((state) => state.FilterDataInStore);
  const [value, setValue] = useState('');
  const handlefilter = (listingGroup) => {
    console.log(filter);
    new Promise((resolve, reject) => {
      dispatch(
        search2Actions.applySearch({
          values: filter,
          resolve,
          reject,
        }),
      );
    })
      .then((data) => {
        dispatch(
          updateFilterData({
            Filter_responce: data.results,
          })
        );
        setFilteredListing(data.results);
      })
      .catch(() => {
        toast.error("No Property Available for this search");
      });
  };

  useEffect(() => {
    setlisting(FilterDataInStore.Filter_responce);
    setFilteredListing(FilterDataInStore.Filter_responce);
  }, []);
  const handleChangeType = (event) => {
    const newCategory = +event.target.value;
    dispatch(updateFilterData({ category: newCategory }));

    getTypesBasedOnCategory(
      newCategory,
      (success) => {
        dispatch(updateFilterData({ type: success[0]?.id }));
        props.handleSearch({ ...filter, category: newCategory, type: success[0]?.id });
      },
    );
  };
  const locationOnChange = (e) => {
    dispatch(
      updateFilterData({
        locationText: e?.label,
        area: e?.value?.area_id ? e?.value?.area_id : null,
        city: e?.value?.city_id,
        state: e?.value?.state_id,
        country: e?.value?.country_id,
      })
    );

    setValue(e);
  };

  return (
    <div className=" d-flex flex-grow-1 flex-column gap-4 h-100">
      <div className="filter-sec  d-flex justify-content-between">
        {/* chech box */}
        <div className="d-flex flex-row gap-3" style={{ width: "450px" }}>
          <div className="buy-rent gap-3 d-flex align-items-center">
            {props.buyRentFilter?.map((type, i) => (
              <div className="d-flex" key={`filetr${i}`}>
                <input
                  type="radio"
                  name="PropType"
                  className="Prop pointer"
                  id={type.user_listing_type}
                  value={type.id}
                  onChange={handleChangeType}
                  checked={filter.category === type.id}
                />
                <label htmlFor={type.user_listing_type} className="pointer">
                  {type.user_listing_type}
                </label>
              </div>
            ))}
          </div>

          {/* Search box */}
          <div className="search-box d-flex flex-grow-1 align-items-center">
            <div className="search d-flex">
              <Autocomplete
                className="hide-downBtn"
                id="combo-box-demo"
                disablePortal
                options={props.optionsList}
                style={{ minWidth: "250px", width: "100%", zIndex: 9999 }}
                value={value}
                onChange={(e, value) => {
                  locationOnChange(value);
                }}
                isOptionEqualToValue={(option, value) =>
                  option.id === value.id
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    placeholder={'Search Town or City'}
                    onChange={(e) => {
                      props.setSearchQuery(e.target.value);
                    }}
                  />
                )}
              />
            </div>
          </div>
        </div>

        {/* quik-view map-view */}
        <div className="quik-map center">
          <p>Quick View</p>
          <div className=" pt-0 gap-1">
            <PinkSwitch
              {...label}
              onChange={(e) => {
                props.setViewsetting(e.target.checked);
              }}
              checked={props.viewsetting}
              style={{ color: "#315EFB" }}
            />
          </div>

          <p>Map View</p>
        </div>
      </div>

      <div className="w-100 d-flex mt-2 pb-3" style={{ height: 'calc(100% - 10rem)' }}>
        {props.typeofAccount === 1 ? (
          <ResidentialMapFilter
            groupByFilter={handlefilter}
            buyRentFilter={props.buyRentFilter}
          />
        ) : (
          <CommertialMapFilter
            groupByFilter={handlefilter}
            buyRentFilter={props.buyRentFilter}
          />
        )}
        <div className=" flex-grow-1" style={{ marginRight: "5px" }}>
          {filteredListing.length > 0 ? (
            <Mapview onclick={handlefilter} allProperty={filteredListing} />
          ) : (
            <Mapview />
          )}
        </div>
      </div>
    </div>
  );
};

export default MapviewFilter;
