import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Topbar from "./components/topbar/Topbar";
import "./dashboard.scss";
import icons from "./components/sidebar/images/SidebarIcon";
import { getToken } from "../../../appVariable/variable";
import { useDispatch } from "react-redux";
import { localDataStore } from "./DashboardSlice";
import { getUserDetails } from '../../../REST/api';
import { SidebarWrapper } from '@components/pages/dashboard/components/SidebarWrapper';

const Dashboard = () => {
  const dispatch = useDispatch();
  let userlogin = getToken();
  let userId = userlogin?.userId;
  const [sidebar, setsidebar] = useState(true);
  const [theme, settheme] = useState(true);
  const [isLogin, setIsLogin] = useState(true);
  const [userType, setuserType] = useState(false);

  const [sidebardata, setsidebardata] = useState([
    { icon: icons.Dashboard, link: "quik-views", title: "Dashboard" },
    { icon: icons.list, link: "saved-listings", title: "Saved Listings" },
    { icon: icons.search, link: "saved-search", title: "Saved Search" },
    { icon: icons.message, link: "message", title: "MESSAGE" },
    {
      icon: icons.mortage,
      link: "mortage-payment",
      title: "Mortgage & Payment",
    },
    // { icon: icons.mortage, link: 'payment-claculator', title: 'Payment calculator', },
    { icon: icons.allsale, link: "all-salesperson", title: "salesperson" },
    { icon: icons.Reviews, link: "reviews", title: "Review" },
    { icon: icons.support, link: "support", title: "Support" },
    // { icon: icons.office, link: 'virtual-office', title: 'VIRTUAL OFFICE', },
  ]);

  useEffect(() => {
    if (!userId) {
      setIsLogin(false);
    } else {
      setIsLogin(true);
    }
  }, []);

  useEffect(() => {
    let arr = [...sidebardata];
    let localdata = JSON.parse(getUserDetails());
    dispatch(localDataStore(localdata?.user_info));
    if (
      localdata?.user_info?.usertypeobj === 1 &&
      localdata?.user_info?.virtual_office === true
    ) {
      arr.splice(5, 0, {
        icon: icons.office,
        link: "virtual-office",
        title: "VIRTUAL OFFICE",
      });
      setsidebardata(arr);
      setuserType(true);
    }
  }, []);

  return (
    <>
      <div className="main-dashboard">
        <div
          className={`dashboard-inner ${theme ? "white-theme" : "blue-theme"}`}
        >
          <Topbar
            sidebar={sidebar}
            setsidebar={setsidebar}
            theme={theme}
            settheme={settheme}
          />
          <div className="dashboard-middle d-flex h-100">
            <SidebarWrapper
              sidebar={sidebar}
              sidebardata={sidebardata}
              settingOption={false}
              pointerEvent={isLogin} />
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
