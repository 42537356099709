import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
// import property from "./images/property.jpg"
import Modal from "react-bootstrap/Modal";
import bed from "./images/bed.svg";
import pro from "./images/pro.jpg";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { getToken } from "../../../../../appVariable/variable";
import {
  // getDefaultPropertyShow,
  removeDefaultPropertyShow,
  saveDefaultPropertyShow,
} from "./server";
import { toast } from "react-toastify";
// import ThirtyMinVisit from './popups/ThirtyMinVisit';
import { useDispatch, useSelector } from "react-redux";
import { updateFilterData } from "../../../homepage/components/hero/FilterSlice";
import MinuteButton from "./popups/MinuteButton";
import { Rating } from "@mui/material";
import { MAX_NOTE_CHARACTERS } from "../../../saved-listings/SavedListings";
import "./main.scss";
export const priceFormat = (num) => {
  return Math.abs(num) > 999999999
    ? Math.sign(num) * (Math.abs(num) / 1000000000).toFixed(1) + "B"
    : Math.abs(num) > 999999
    ? Math.sign(num) * (Math.abs(num) / 1000000).toFixed(1) + "M"
    : Math.abs(num) > 999
    ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
    : Math.sign(num) * Math.abs(num);
};

const PropertyCard = (props) => {
  const dispatch = useDispatch();
  let token = getToken();
  const userType = token?.user_info?.usertypeobj;
  let userId = token?.userId;
  const {
    agentListingData,
    handelOpenPopUp,
    selectedProperty,
  } = props;
  const [isPropertySave, setIsPropertySave] = useState(false);
  // const [propertyId, setPropertyId] = useState([]);
  const [savePopup, setSavePopup] = useState(false);
  const [rating, setRating] = useState(0);
  const [noteValue, setNoteValue] = useState("");
  const [noteError, setNoteError] = useState("");
  const FilterDataInStore = useSelector((state) => state.FilterDataInStore);
  // const [showThirtyMinPopup, setShowThirtyMinPopup] = useState(false)
  // const handleClickRating = (value) => { setRating(value); };
  let remainingCharacters = MAX_NOTE_CHARACTERS - noteValue.length;

  useEffect(() => {
    // setPropertyId([agentListingData?.id])
    agentListingData?.guest_users_save_listing
      ? setIsPropertySave(true)
      : setIsPropertySave(false);
  }, [props]);
  const showSavePopUp = (e) => {
    e.stopPropagation();
    if (!userId) {
      // Navigate("/login");
      alert("please login");
    } else {
      setSavePopup(true);
    }
  };
  const handelSaveProperty = (e) => {
    e.stopPropagation();
    let savepayload = {
      user_id: userId,
      property_details_id: [agentListingData?.id],
      notes: noteValue,
      rating: rating,
    };
    // if (noteValue === "") {
    //   setNoteError("Please enter save note");
    // } else {
    saveDefaultPropertyShow(savepayload, (success) => {
      if (success.code === 200) {
        toast.success(success.data);
        setIsPropertySave(true);
        setSavePopup(false);
        setNoteValue("");
        setRating(0);
        updadateProprtyListInStore(true);
      }
    });
    // }
  };

  const handelRemoveProperty = (e) => {
    e.stopPropagation();
    setIsPropertySave(false);
    removeDefaultPropertyShow(agentListingData.id, (success) => {
      if (success.code === 200) {
        toast.success(success.data);
        updadateProprtyListInStore(false);
      }
    });
  };

  const updadateProprtyListInStore = (value) => {
    let updatedData = FilterDataInStore.Filter_responce.map((property) =>
      property.id === agentListingData.id
        ? { ...property, guest_users_save_listing: value }
        : property
    );
    dispatch(updateFilterData({ Filter_responce: updatedData }));
  };

  return (
    <>
      <div style={{
        border: `1px solid ${selectedProperty ? '#315efb' : 'transparent'}`
      }}>
        <div
          className={`property-card d-flex flex-column justify-content-between pointer ${
            props.agentListingData?.seen_property_listing ? "seen" : ""
          }`}
          // key={key}
          onClick={() => handelOpenPopUp(agentListingData.slug)}
        >
          <div className="property-img position-relative">
            <img
              src={`${agentListingData?.property_main_image}`}
              alt=""
              style={{ opacity: props.isClosed && "0.5" }}
            />
            {/* if agent user login dont show save button */}
            {!(userType === 2) ? (
              isPropertySave ? (
                <div
                  className="card-remove-btn center  position-absolute pointer"
                  onClick={handelRemoveProperty}
                >
                  <FavoriteBorderIcon /> <p>Remove</p>
                </div>
              ) : (
                <div
                  className="card-save-btn center  position-absolute pointer"
                  onClick={showSavePopUp}
                >
                  <FavoriteBorderIcon />
                  <p>save</p>
                </div>
              )
            ) : null}

            {/* if property close then not show 30 min button */}
            {/* {agentListingData?.is_property_open && <MinuteButton timeInfo={agentListingData} />} */}
          </div>

          <div className="property-detail d-flex justify-content-between align-items-center">
            <div className="property-detail-left d-flex flex-column">
              <div className="d-flex align-items-center gap-1">
                <p className="price">
                  ${priceFormat(agentListingData?.property_listing_amount)}
                </p>
                <div className="card-divider"></div>
                {/* bed&obr */}
                <div>
                  <img className="bed-img d-flex" src={bed} alt="" />
                </div>
                {agentListingData?.property_listing_type
                  ?.property_listing_name === "Residential" ? (
                  <p className="obr text-uppercase">{`${agentListingData?.Bedrooms}br`}</p>
                ) : null}
              </div>
              <div>
                <p className=" mb-1">
                  {agentListingData?.property_city?.city_name}
                </p>
                <p>{agentListingData?.property_address_1}</p>
              </div>
            </div>
            <div className="property-detail-right center flex-column gap-2">
              <div className="center">
                {/* {console.log("user_img", agentListingData)} */}
                <img
                  src={
                    agentListingData?.user_profile
                      ? `${agentListingData?.user_profile?.profile_image}`
                      : pro
                  }
                  alt=""
                />
              </div>
              <Button
                variant="outline-primary"
                style={{ borderRadius: "20px" }}
              >
                1 Year
              </Button>
            </div>
          </div>
        </div>

        {/* save notes and rating pop-up  start*/}
        <div>
          <Modal
            className="dashboard-filter-popup"
            size="lg"
            centered
            show={savePopup}
            onHide={() => setSavePopup(false)}
            aria-labelledby="contained-modal-title-vcenter"
          >
            <Modal.Header className=" pb-0" closeButton>
              {" "}
            </Modal.Header>
            <Modal.Body>
              <formik>
                <div className="dashborad-filter-body d-flex flex-column gap-4">
                  <div className="price-sec  filter-row ">
                    <div className="d-flex flex-row">
                      <div className="filter-heading">Note & Rate Listing:</div>
                      <div style={{ marginLeft: "10px" }}>
                        <Rating
                          name="simple-controlled"
                          value={rating}
                          onChange={(event, newValue) => {
                            setRating(newValue);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="price-sec  filter-row ">
                    <div className="d-flex justify-content-between">
                      {/* {noteError && (
                      <div className="validation-text text-capitalize">
                        {noteError}
                      </div>
                    )} */}
                    </div>
                    <div className="price-input-box justify-content-between">
                      <div
                        className="enter-input inner-shadow center flex-grow-1"
                        style={{ maxWidth: "none" }}
                      >
                        <input
                          type="textarea"
                          id=""
                          placeholder="Name"
                          name="filtername"
                          onChange={(e) => {
                            setNoteValue(
                              e.target.value.slice(0, MAX_NOTE_CHARACTERS)
                            );
                            setNoteError("");
                          }}
                          value={noteValue}
                        />
                      </div>
                      <div
                        className="d-flex justify-content-end "
                        style={{ marginTop: "12px" }}
                      >
                        <p className=" text-end">
                          Remaining characters : {remainingCharacters}/
                          {MAX_NOTE_CHARACTERS}{" "}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="filter-btn-grp d-flex gap-4 justify-content-end">
                    <button
                      className="btn"
                      style={{ width: "135px", borderRadius: "20px" }}
                      onClick={() => setSavePopup(false)}
                    >
                      Cancel
                    </button>
                    {userId ? (
                      <button
                        className="btn"
                        style={{ width: "135px", borderRadius: "20px" }}
                        onClick={handelSaveProperty}
                      >
                        Submit
                      </button>
                    ) : null}
                  </div>
                </div>
              </formik>
            </Modal.Body>
          </Modal>
        </div>

        {/* thirty min modal */}
        {/* {showThirtyMinPopup && <ThirtyMinVisit setShowThirtyMinPopup={setShowThirtyMinPopup} />} */}
      </div>
    </>
  );
};

export default PropertyCard;
