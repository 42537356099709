import axiosInstance from '../../../../../../REST/axiosInstance';


export function getamminity(success) {
    axiosInstance
        .get(`api/property/AminitiesView/`)
        .then((response) => {
            if (response.data.code === 200 && response.data.status === "success") {
                success(response.data.data);
            }
        })
        .catch((error) => {
            console.log(error);
        });
}