import {
  api,
} from '@REST/api';

import * as effects from 'redux-saga/effects';
import { documentActions } from '@bus/document/actions';
import { PayloadAction } from '@reduxjs/toolkit';
import { ShareDocumentsActionPayload } from '@bus/document/typedefs';

export function* shareDocuments({
  payload,
}: PayloadAction<ShareDocumentsActionPayload>) {
  try {
    yield effects.put(documentActions.startFetching());
    const body: string = yield effects.call(JSON.stringify, payload);
    const response: Response = yield effects.apply(api, api.post, [
      { endpoint: `api/property/documents/bulk-share/`, body },
    ]);

    if (!response.ok) {
      throw new Error('Unhandled error');
    }

  } catch (e) {
  } finally {
    yield effects.put(documentActions.stopFetching());
  }
}
