import React, { useEffect, useState } from 'react';
import { FieldRenderProps } from 'react-final-form';

import { Box, TextField } from '@mui/material';

import { getFieldError } from '@core/FinalForm/getFieldError';
import { styles } from '@components/forms/SubmitRequestForAdvertising/styles';
import Typography from '@mui/material/Typography';

export const RenderNoteField: React.FC<FieldRenderProps<string>> = ({
  input,
  meta,
}) => {
  const [valueLength, setValueLength] = useState(input.value.length);

  useEffect(() => {
    setValueLength(input.value.length);
  }, [input.value]);

  return (
    <>
      <Box sx={styles.inputWrapper} my={'10px'} width={'100%'}>
        <Box sx={styles.label}>
          <Typography>Note</Typography>
        </Box>
        <TextField
          sx={styles.input}
          multiline
          required
          fullWidth
          inputProps={{
            maxLength: 180,
          }}
          {...input}
        />
        {!!getFieldError(meta) && (
          <Box sx={styles.error}>{getFieldError(meta)}</Box>
        )}
      </Box>
      <Box sx={styles.valueLength}>
        <Typography>
          {valueLength}/180 character
        </Typography>
      </Box>
    </>
  );
};
