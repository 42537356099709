import React, { useEffect, useState } from 'react';
import { Form } from "react-bootstrap";
import "./residentialfilter.scss";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import NativeSelect from "@mui/material/NativeSelect";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { useDispatch, useSelector } from 'react-redux';
import { getagentaminities } from "../../agent-dash-sales/components/server";
import { getTearmsOffers } from "../../agent-dash-sales/components/server";
import { getRadioButtons } from "../../agent-dash-sales/server";
import { updateFilterData } from '@components/pages/homepage/components/hero/FilterSlice';

let Allaminity = [
  "Penthouse",
  "Triplex",
  "Duplex",
  "Doorman",
  "Storage",
  "Elevator",
  "Parking",
  "Laundry",
];

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  "&:not(:last-child)": {
    display: "none",
  },
  "&:before": {
    display: "none",
  },
  "&:after": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(
  ({ theme }) => ({
    flexDirection: "row-reverse",
    height: "14px",
    minHeight: "unset",
    color: "#315efb",
    padding: "0px 8px 0px 0px",
    ".MuiAccordionSummary-content": {
      display: "flex",
      justifyContent: "end",
    },
  })
);

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const ResidentialMapFilter = ({
  groupByFilter,
  virtualOfficeId,
  buyRentFilter,
}) => {
  const virtualofficeid = useSelector(
    (state) => state.GuestVirtualOfficeSliceInStore.guestOfficeId
  );
  const filter = useSelector((state) => state.FilterDataInStore);
  const [bedrooms, setBedrooms] = useState(1);
  const [bathrooms, setBathrooms] = useState(1);
  const [Type, setType] = useState(null);
  const [minSqft, setminSqft] = useState(null);
  const [maxSqft, setmaxSqft] = useState(null);
  const [minPrice, setminPrice] = useState(null);
  const [maxPrice, setmaxPrice] = useState(null);
  const [allaminities, setAllaminities] = useState([]);
  const [allradios, setAllRadios] = useState([]);
  const [loading, setLoading] = useState(false);
  const [Amenities, setAmenities] = useState([]);
  const [feeType, setfeeType] = useState(null);
  const [category, setCategory] = useState(0);
  const [expanded, setExpanded] = useState("panel1");
  const dispatch = useDispatch();

  useEffect(() => {
    setCategory(filter.category);
    setAmenities(filter.Amenities_filter);
    setmaxPrice(filter.pricemax);
    setminPrice(filter.pricemin);
    setminSqft(filter.Squft_min);
    setmaxSqft(filter.Squft_max);
    setBathrooms(filter.Bathrooms ?? 1);
    setBedrooms(filter.Bedrooms ?? 1);
    setType(filter.type ?? 1);
  }, [filter]);
  const handleCategory = (event) => {
    const newCategory = +event.target.value;
    setCategory(event.target.value);
    dispatch(updateFilterData({ category: newCategory }));
  };

  useEffect(() => {
    setLoading(true);
    getagentaminities((success) => {
      setAllaminities(success);
    });
  }, []);

  useEffect(() => {
    if (!!category) {
      setLoading(true);
      const radioObj = { Property_listing_type_id: category };
      getRadioButtons(radioObj, (success) => {
        setAllRadios(success);
        setType(success[0].id);
        dispatch(updateFilterData({ type: success[0].id }));
      });
    }
  }, [category]);
  const handleAminity = (e, id) => {
    const tempAminity = [...Amenities];
    const index = tempAminity.indexOf(id);
    e.target.checked
      ? tempAminity.push(id)
      : index > -1 && tempAminity.splice(index, 1);

    setAmenities(tempAminity);
    setAmenities((state) => {
      return state;
    });
    dispatch(updateFilterData({ Amenities_filter: tempAminity }));
  };

  const handleSelectChange = (event) => {
    const fee = +event.target.value;
    setfeeType(!!fee);
  };

  const sendFormdata = () => {
    const filterGroup = {
      is_fee: category === 1 ? feeType : false,
      propertylisting_type_id: category,
      property_main_category_id: Type,
      bedrooms: bedrooms,
      bathrooms: bathrooms,
      min_sqft: minSqft,
      max_sqft: maxSqft,
      min_price: minPrice,
      max_price: maxPrice,
      amenities: Amenities,
      virtualoffice_id: virtualOfficeId
        ? virtualOfficeId
        : virtualofficeid,
    };
    groupByFilter(filterGroup);
  };

  const handleRadioChange = (event) => {
    const newType = +event.target.value;
    setType(newType);
    dispatch(updateFilterData({ type: newType }));
  };

  const handleViewMoreChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
      <div
        className="d-flex flex-column filter-body scrollS h-100 mh-100"
        style={{ width: "220px", height: "100%" }}
      >
        <div className="filter-by filter-form-row">
          <p className="filter-heading">Filter By :</p>
        </div>

        <div className="filter-form d-flex flex-column">
          <div className="type filter-form-row">
            <p className="small-heading">Category</p>
            <FormControl
              sx={{ m: 1, minWidth: 100, maxHeight: 50 }}
              size="small"
            >
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={category}
                className="select-category"
                onChange={(e) => handleCategory(e)}>
                {buyRentFilter?.map((type) => (
                  <MenuItem value={type.id} key={type.id}>
                    {type.user_listing_type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="type filter-form-row">
            {category === 1 && (
              <>
                <p className="small-heading">Fee Type</p>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="Fee"
                    name="radio-buttons-group"
                    onChange={(e) => {
                      handleSelectChange(e);
                    }}
                  >
                    <FormControlLabel
                      value={1}
                      control={<Radio />}
                      label="Fee"
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 14,
                        },
                        "& .MuiTypography-root": {
                          fontSize: 14,
                        },
                      }}
                    />
                    <FormControlLabel
                      value={0}
                      control={<Radio />}
                      label="NoFee"
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 14,
                        },
                        "& .MuiTypography-root": {
                          fontSize: 14,
                        },
                      }}
                    />
                  </RadioGroup>
                </FormControl>
              </>
            )}
          </div>

          <div className="aminity-type filter-form-row  ">
            <p className="small-heading">Type</p>
            <div className="checkbox-column d-flex flex-row flex-wrap">
              {allradios.map((type) => {
                return (
                  <div className="aminity-radio" key={`radio${type.id}`}>
                    <input
                      type="radio"
                      id={`type-${type.id}`}
                      name={'type-radios'}
                      value={type.id}
                      checked={Type === type.id}
                      onChange={handleRadioChange} />
                    <label htmlFor={`type-${type.id}`} className="aminity-radio-label">
                      {type.Main_category}
                    </label>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="bedroom-bathroom">
            <div className=" small-in-box-div">
              <div className="filter-form-row ">
                <p className="small-heading">Bedrooms</p>
                <div className="beadroom small-in-box d-flex justify-content-around">
                  <p
                    onClick={() => setBedrooms(bedrooms + 1)}
                    className="pointer"
                  >
                    +
                  </p>
                  <p>{bedrooms}</p>
                  <p
                    onClick={() => setBedrooms(bedrooms - 1)}
                    className="pointer"
                  >
                    -
                  </p>
                </div>
              </div>
              <div className="filter-form-row">
                <p className="small-heading">Bathrooms</p>
                <div className="beadroom small-in-box d-flex justify-content-around">
                  <p
                    onClick={() => setBathrooms(bathrooms + 1)}
                    className="pointer"
                  >
                    +
                  </p>
                  <p>{bathrooms}</p>
                  <p
                    onClick={() => setBathrooms(bathrooms - 1)}
                    className="pointer"
                  >
                    -
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="sqft-price filter-form-row">
            <p className="small-heading">Sqft</p>
            <div className=" d-flex small-in-box-div">
              <div className="input-sec small-in-box">
                <input
                  type="text"
                  placeholder="Sqft Min"
                  value={minSqft}
                  onChange={(e) => {
                    setminSqft(Number(e.target.value));
                  }}
                />
              </div>
              <div className="input-sec small-in-box">
                <input
                  type="text"
                  placeholder="Sqft Max"
                  value={maxSqft}
                  onChange={(e) => {
                    setmaxSqft(Number(e.target.value));
                  }}
                />
              </div>
            </div>
          </div>
          <div className="sqft-price  filter-form-row">
            <p className="small-heading">Price</p>
            <div className=" d-flex small-in-box-div">
              <div className="input-sec small-in-box">
                <input
                  type="text"
                  placeholder="Price Min"
                  value={minPrice}
                  onChange={(e) => {
                    setminPrice(Number(e.target.value));
                  }}
                />
              </div>
              <div className="input-sec small-in-box">
                <input
                  type="text"
                  placeholder="Price Max"
                  value={maxPrice}
                  onChange={(e) => {
                    setmaxPrice(Number(e.target.value));
                  }}
                />
              </div>
            </div>
          </div>

          <div className="amenity-filter filter-form-row">
            <p className="small-heading">Amenities</p>
            <div className="amenity-box">
              <div className="Amenities d-flex flex-wrap ">
                {allaminities.map((aminiti, i) => (
                  <div
                    className="select-aminity position-relative"
                    key={`aminity${i}`}
                  >
                    <input
                      type="checkbox"
                      name="aminity"
                      className="Prop aminity-input"
                      id={`filteraminity${i}`}
                      value={aminiti.amenities_name}
                      checked={Amenities.includes(aminiti.id)}
                      onChange={(e) => handleAminity(e, aminiti.id)}
                    />
                    <label
                      htmlFor={`filteraminity${i}`}
                      className="pointer aminity"
                    >
                      {aminiti.amenities_name}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div
            className="buttons pointer blue__btn adfasdfsad"
            onClick={(e) => sendFormdata()}
            style={{ margin: "auto" }}>
            Update
          </div>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleViewMoreChange("panel1")}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <p>View More</p>
            </AccordionSummary>
            <AccordionDetails>
              <div>this is content for view more of residential</div>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </>
  );
};

export default ResidentialMapFilter;
