import React, { useState } from 'react';
import { toast } from 'react-toastify';
import "./ForgetPass.scss";
import { useFormik } from "formik";
import { ForgetPassword } from './server';
import Loader from '../Loader/Loader';
import { AuthPasswordLayout } from "../../../layouts";

// validation code
const validate = (values) => {
    const errors = {};
    if (!values.email) {
        errors.email = 'Required*';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address';
    }

    return errors;
};

const ForgetPass = () => {
    const [loading, setLoading] = useState(false);

    // on form submit
    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
            confirmpassword: "",
        },
        validate,
        onSubmit: (values) => {
            let userVal = { email: values.email }
            ForgetPassword(userVal, (success) => {
                setLoading(true)
                if (success.data.code == 200 && success.data.status == "success") {
                    toast.success("Your Email is Verify, Please Check your mail", success.data.data, "success");
                    setLoading(false)
                }
                if (success.data.code == 400 && success.data.status == "failed") {
                    setLoading(false)
                    toast.warning("Please Enter Registered Mail Id", success.data.data, "warning");
                }
            })
            setLoading(!loading)
        },
    });

    return (
        <>
            {loading ? <Loader /> : null}
            <AuthPasswordLayout
                title='Forgot Password'
                subtitle='Lost your password? Please enter your username or email address. You will receive a link to create a new password via email.'
            >
                <div className="loginpage_inputs">
                    <div className="input-container">
                        <input className="input-field" type="email" placeholder="Email" name="email"
                               autoComplete="off"
                               onChange={formik.handleChange}
                               value={formik.values.email}
                               onBlur={formik.handleBlur}
                        />
                    </div>
                    {/* validation message */}
                    {formik.touched.email && formik.errors.email ? (<div className='validation-text log-validation'>{formik.errors.email}</div>) : null}
                </div>
                <div className="loginpage_help_button justify-content-center mb-3 mx-auto">
                    <div className="loginpage_signin">
                        <button
                          className="loginpage_signin_btn"
                          style={{ padding: "27px", width: "100%" }}
                          type="submit"
                          onClick={formik.handleSubmit}
                        >
                            <span className="loginpage_signin_btn_text">Reset Password</span>
                        </button>
                    </div>
                </div>
            </AuthPasswordLayout>
        </>
    )
}

export default ForgetPass
