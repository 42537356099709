import { useMemo } from 'react'
import { Rating } from '@mui/material'
import { getInitials, ProfileAvatar } from "../../../../shared/profile-avatar";

const AdCard = (props) => {
    const { Memberinfo } = props;
    const profileInstance = useMemo(() => {
      let image = null;
      if (Memberinfo.profile) {
        image = Memberinfo.profile.includes('http') ? Memberinfo.profile : `${Memberinfo.profile}`;
      }
      return {
        fullName: Memberinfo.name,
        initials: getInitials(Memberinfo.name),
        image
      }
    }, [Memberinfo]);

    return (
        <div className="ad-card position-relative center gap-2">
            <div className="ad-card-img">
                <ProfileAvatar width={45} height={45} profileInstance={profileInstance} />
            </div>
            <div className=' center flex-column rating-div'>
                <p className='member_name text-center text-capitalize'>{Memberinfo.name}</p>
                <Rating name="read-only" className='rating' value={Memberinfo.rating} readOnly />
            </div>
        </div>
    )
}

export default AdCard
