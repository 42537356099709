import * as React from 'react';
import { Field, FormRenderProps } from 'react-final-form';

import { Box, Button, Grid } from '@mui/material';

import { schema } from './schema';
import { styles } from './styles';
import { RenderLanguageField } from './fields/RenderLanguageField';
import { RenderEmailField } from './fields/RenderEmailField';
import { RenderFullNameField } from './fields/RenderFullNameField';
import { RenderStarRatingField } from './fields/RenderStarRatingField';
import { RenderZipcodeField } from './fields/RenderZipcodeField';

const SalesPersonFilterForm = (props: FormRenderProps) => {
  return (
    <form noValidate onSubmit={props.handleSubmit}>
      <Box width={'100%'} display={'flex'} flexDirection={'column'}>
          <Grid container columnSpacing={'20px'}>
            <Grid item xs={6}>
              <Field name={`search`} component={RenderFullNameField} />
            </Grid>
            <Grid item xs={6}>
              <Field name={`email`} component={RenderEmailField} />
            </Grid>
            <Grid item xs={6}>
              <Field name={`zipcode`} component={RenderZipcodeField} />
            </Grid>
            <Grid item xs={6} />
            <Grid item xs={6}>
              <Field
                name={`language`}
                component={RenderLanguageField}
                parse={(value) => {
                  return [value]
                }} />
            </Grid>
            <Grid item xs={6}>
              <Field name={`start_rating`} component={RenderStarRatingField} />
            </Grid>
          </Grid>
          <Button sx={styles.button} type={'submit'} role={'submit'}>
            Apply
          </Button>
      </Box>
    </form>
  );
};

export default SalesPersonFilterForm;

export { schema, SalesPersonFilterForm };
