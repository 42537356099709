import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  ChangeShowStatusActionPayload,
  DocumentState,
  FillDocumentActionPayload,
  FillDocumentsActionPayload, SetDefaultDocumentActionPayload,
} from './typedefs';

const initialState: DocumentState = {
  isFetching: false,
  documents: null,
  document: null,
  customers: null,
};

const documentSlice = createSlice({
  name: 'document',
  initialState,
  reducers: {
    startFetching(state) {
      state.isFetching = true;
    },
    stopFetching(state) {
      state.isFetching = false;
    },
    fillDocuments(state, action: PayloadAction<FillDocumentsActionPayload>) {
      state.documents = action.payload;
    },
    pushMoreDocuments(state, action: PayloadAction<FillDocumentsActionPayload>) {
      state.documents = {
        ...action.payload,
        results: [...state.documents!.results, ...action.payload.results],
      };
    },
    fillDocument(state, action: PayloadAction<FillDocumentActionPayload>) {
      state.document = action.payload;
    },
    updateDocuments(state, action: PayloadAction<ChangeShowStatusActionPayload>) {
      if (state.documents) {
        state.documents = {
          ...state.documents,
          results: state.documents.results.map((item) => {
            if (action.payload.ids.some((id) => id === item.id)) {
              return {
                ...item,
                shared: action.payload.show,
              }
            }
            return item;
          }),
        }
      }
    },
    updateDefaultStatus(state, action: PayloadAction<SetDefaultDocumentActionPayload>) {
      if (state.documents) {
        state.documents = {
          ...state.documents,
          results: state.documents.results.map((item) => {
            if (action.payload.ids.some((id) => id === item.id)) {
              return {
                ...item,
                is_default: action.payload.status,
              }
            }
            return item;
          }),
        }
      }
    },
    removeSelectedDocuments(state, action: PayloadAction<{ ids: number[] }>) {
      if (state.documents) {
        state.documents = {
          ...state.documents,
          results: state.documents.results.filter((item) => !action.payload.ids.some((id) => id === item.id)),
        }
      }
    },
    fillCustomers(state, action: PayloadAction<unknown>) {
      state.customers = action.payload
    },
    removeDocument(state, action: PayloadAction<number>) {
      if (state.documents) {
        state.documents = {
          ...state.documents,
          results: state.documents.results.filter((item) => item.id !== action.payload),
        }
      }
    },
    updateDocumentTitle(state, action: PayloadAction<{ id: number, title: string }>) {
      if (state.documents) {
        state.documents = {
          ...state.documents,
          results: state.documents.results.map((item) => {
            if (item.id === action.payload.id) {
              return {
                ...item,
                filename: action.payload.title,
              }
            }
            return item;
          }),
        }
      }
    },
  },
});

export default documentSlice;
