import { getToken } from "../../../appVariable/variable";
import axiosInstance from '../../../REST/axiosInstance';

export function saveloadCityAreaView(formData, success) {
    let token = getToken()
    axiosInstance.post(`api/user/neighborhoodspecialisareasaved/`, formData, {
        headers: { 'Authorization': `Bearer ${token?.idToken}` }
    })
        .then((response) => {
            if (response.data.code === 200 && response.data.status === "success") {
                success(response.data.data);
            }
        })
        .catch((error) => { console.log(error); });
}

export function updateCityAreaView(id, formData, success) {
    let token = getToken()
    axiosInstance.patch(`api/user/neighborhoodspecialisareasaved/${id}/docs/`, formData, {
        headers: { 'Authorization': `Bearer ${token?.idToken}` }
    })
        .then((response) => {
            if (response.data.code === 200 && response.data.status === "success") {
                success(response.data.data);
            }
        })
        .catch((error) => { console.log(error); });
}


export function BillingHistory(success) {
    let token = getToken()
    let userId = token?.userId;
    axiosInstance
        .get(`api/user/billing/${userId}`, {
            headers: {
                'Authorization': `Bearer ${token?.idToken}`
            }
        })
        .then((response) => {
            if (response.data.code === 200 && response.data.status === "success") {
                success(response.data.data);
            }
        })
        .catch((error) => {
            console.log(error);
        });
}

export function DayStarWatch(watchobj, success) {
    let token = getToken()
    axiosInstance.post(`api/property/daywise/`, watchobj, {
        headers: { 'Authorization': `Bearer ${token?.idToken}` }
    })
        .then((response) => {
            success(response)
        })
        .catch((error) => { console.log(error); });
}

export function WeekStarWatch(watchobj, success) {
    let token = getToken()
    axiosInstance.post(`api/property/weekwise/`, watchobj, {
        headers: { 'Authorization': `Bearer ${token?.idToken}` }
    })
        .then((response) => {
            success(response)
        })
        .catch((error) => { console.log(error); });
}

export function MonthStarWatch(watchobj, success) {
    let token = getToken()
    axiosInstance.post(`api/property/monthwise/`, watchobj, {
        headers: { 'Authorization': `Bearer ${token?.idToken}` }
    })
        .then((response) => {
            success(response)
        })
        .catch((error) => { console.log(error); });
}

export function CustumStarWatch(watchobj, success) {
    let token = getToken()
    axiosInstance.post(`api/property/coustomdate/`, watchobj, {
        headers: { 'Authorization': `Bearer ${token?.idToken}` }
    })
        .then((response) => {
            success(response)
        })
        .catch((error) => { console.log(error); });
}
