import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DashboardState } from './typedefs';

const initialState: DashboardState = {
  isFetching: false,
  propertyCondition: [],
  classTypes: [],
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    startFetching(state) {
      state.isFetching = true;
    },
    stopFetching(state) {
      state.isFetching = false;
    },
    fillPropertyCondition(state, action: PayloadAction<any>) {
      state.propertyCondition = action.payload;
    },
    fillClass(state, action: PayloadAction<any>) {
      state.classTypes = action.payload;
    },
  },
});

export default dashboardSlice;
