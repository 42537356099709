import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { privateRoutes } from '@pages/private/routes';
import NotFound from '@components/pages/NotFound/NotFound';
import { getToken } from '../appVariable/variable';
import { ProtectedRoute } from '@routes/ProtectedRoute';
import { TwilioContextProvider } from "../twilio";

const PrivateRoutes = () => {
  const { user_info } = getToken();

  return (
    <TwilioContextProvider>
      <Routes>
        {privateRoutes.map(({ path, Component, Layout, layoutProps, children, allowedRoles }) => (
          <Route path={path} element={<Layout {...layoutProps} />} key={path}>
            {children && children.length > 0 && (
              <Route index element={<Navigate to={children[0].path} replace />} />
            )}
            {children?.map((childRoute) => (
              <Route
                path={childRoute.path}
                element={
                  <ProtectedRoute
                    component={childRoute.Component}
                    userRole={user_info?.usertypeobj}
                    allowedRoles={childRoute.childAllowedRoles || allowedRoles} />
                }
                key={childRoute.path} />
            ))}
            <Route
              path={path}
              element={
                <ProtectedRoute
                  component={Component}
                  userRole={user_info?.usertypeobj}
                  allowedRoles={allowedRoles} />
              } />
          </Route>
        ))}
        <Route path={'/*'} element={<NotFound />} />
      </Routes>
    </TwilioContextProvider>
  );
};

export default PrivateRoutes;
