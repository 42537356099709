import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import swal from "sweetalert";
import {
  AddProperty,
  getTearmsOffers,
  getagentaminities, GetLeaseTypes, GetPropertyConditions,
} from "./components/server";
import { getToken } from "../../../appVariable/variable";
import ImageModal from "./components/ImageModal";
import moment from "moment";
import Loader from "../Loader/Loader";
import { toast } from "react-toastify";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { useNavigate } from "react-router-dom";
import { blockKeywords } from "../profile/components/personal/Personal";
import "react-datepicker/dist/react-datepicker.css";
import CustumeFileInput from "./components/custumeFileInput/CustumeFileInput";
import OPenHouseTimeModal from "./components/open-house-time/OPenHouseTimeModal";
import AddressFeild from "./components/Address-feild/AddressFeild";
import { useDispatch, useSelector } from 'react-redux';
import { updateTotalListing } from "../dashboard/DashboardSlice";
import Typography from '@mui/material/Typography';
import { IconButton, TextField } from '@mui/material';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import Box from '@mui/material/Box';
import { styles } from './styles';
import { v4 as uuidv4 } from 'uuid';
import { setUserDetails } from '../../../REST/api';
import { getClassTypes } from '../../../bus/dashboard/selectors';
import { AsyncSelectField } from "@components/pages/agent-dash-sales/components/AsyncSelectField/AsyncSelectField";

export const residentialTypes = [
  { name: "Bedrooms", title: "Bedrooms", isRental: true },
  { name: "Bathrooms", title: "Bathrooms", isRental: true },
  { name: "SquareSqft", title: "Square feet", isRental: true },
  { name: "ExteriorSqft", title: "Exterior Sqft", isRental: false },
  { name: "MaintenanceFee", title: "Maintenance Fee", isRental: false },
  { name: "RealEstateTax", title: "RealEstate Tax", isRental: false },
  { name: "Financing", title: "Financing", isRental: false },
  { name: "MinimumDown", title: "Minimum Down", isRental: false },
];
export const commercialTypes = [
  { name: "Units", title: "Units", isLeasing: true, extraProps: { type: "number", min: 0 } },
  { name: "Rooms", title: "Rooms", isLeasing: true, extraProps: { type: "number", min: 0 } },
  { name: "Stories", title: "Stories", isLeasing: true, extraProps: { type: "number", min: 0 } },
  { name: "Block", title: "Block", isLeasing: true, extraProps: { type: "number", min: 0 } },
  { name: "Lot", title: "Lot", isLeasing: true, extraProps: { type: "number", min: 0 } },
  { name: "Zone", title: "Zone", isLeasing: true, extraProps: { type: "number", min: 0 } },
  { name: "SquareFt", title: "Square Ft", isLeasing: true, extraProps: { type: "number" } },
  { name: "BuildingSqft", title: "Building Sqft", isLeasing: true, extraProps: { type: "number" } },
  { name: "LotDimensions", title: "Lot Dimensions", isLeasing: true, extraProps: { type: "number" } },
  { name: "FAR", title: "FAR", isLeasing: true, extraProps: { type: "number" } },
  { name: "Assessment", title: "Assessment", isLeasing: true, extraProps: { type: "number" } },
  { name: "AnnualTaxes", title: "Annual Taxes", isLeasing: true, extraProps: { type: "number" } },
  { name: "property_condition", title: "Property Condition", isLeasing: true, fetchAsyncOptions: GetPropertyConditions },
  { name: "lease_type", title: "Lease Type", alternativeTitle: { title: 'Investment of Lease Type', showIfListingType: '4' }, isLeasing: true, fetchAsyncOptions: GetLeaseTypes },
  { name: "cap_rate", title: "Cap Rate", isLeasing: false, extraProps: { type: "number", max: 100 } },
  { name: "occupancy", title: "Occupancy", isLeasing: true, extraProps: { type: "number", max: 100 } },
];

const minDay = (date = new Date(), count = 1) => {
  date.setDate(date.getDate() - count);

  return date;
}

export const handleAmountChange = (value) => {
  //remove character from value
  let amount = value.replace(/[^0-9.,]/g, "");
  // Remove non-digit characters  and comma from the input
  const cleanedValue = amount.toString().replace(/,/g, "");
  // Split the value into whole and decimal parts
  const [wholePart, decimalPart] = cleanedValue.split(".");
  const decimalPt = /\./.test(cleanedValue);
  // Format the whole part by adding commas
  const formattedWholePart = wholePart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  // Combine the whole and decimal parts
  let formattedValue = formattedWholePart;
  if (decimalPt) {
    if (decimalPart) {
      formattedValue += "." + decimalPart.slice(0, 2);
    } else {
      formattedValue += ".";
    }
  }
  // Update the state with the formatted value
  return formattedValue;
};

const CommonForm = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let token = getToken();
  let userId = token?.userId;
  const petchck = useRef();
  const mainImage = useRef();
  const floreImage = useRef();
  const zipSelect = useRef();
  const [imgfile, setImgfile] = useState(0);
  const [images, setImages] = useState([]);
  const [imagemodalpopup, setImagemodalpopup] = useState(false);

  const classTypes = useSelector(getClassTypes);

  const currentURL = window.location.href;
  const url = new URL(currentURL);
  const path = url.pathname;
  const segments = path.split("/"); // Split the path by slashes
  let currentUser = "";
  if (segments.length > 1) {
    currentUser = segments[1];
  }
  const [allaminities, setAllaminities] = useState([]);
  const [termsOffers, setTermsOffers] = useState({});
  const [nofeeBlock, setNofeeBlock] = useState(true);
  let [loading, setLoading] = useState(false);
  const [leisureSelect, setLeisureSelect] = useState(false);
  const [commertialInputes, setCommertialInputes] = useState(1);
  const [propertyAmount, setPropertyAmount] = useState();
  const [openHouseModal, setOpenHouseModal] = useState(false);
  const [openHouseData, setOpenHouseData] = useState({
    sunday_open_house: [],
    monday_open_house: [],
    tuesday_open_house: [],
    wednesday_open_house: [],
    thursday_open_house: [],
    friday_open_house: [],
    saturday_open_house: [],
  });

  const fileselect = (event, position) => {
    if (position === "img") {
      setImgfile(event.target.files.length);
      setImages(Object.values(event.target.files));
    } else {
      // setPdffile(event.target.files.length);
    }
  };

  useEffect(() => {
    setLoading(true);
    getagentaminities((success) => {
      setAllaminities(
        success
          .filter(a => a.is_active)
          .sort((a, b) => a.position - b.position)
      );
    });
    getTearmsOffers((success) => {
      setTermsOffers({
        Offer: success.Offer.sort((a, b) => a.position - b.position),
        Terms: success.Terms.sort((a, b) => a.position - b.position),
      });
      setLoading(false);
    });
  }, []);

  const handleChange = (e) => {
    const { checked, name } = e.target;
    if (checked) {
      formik.setFieldValue("amenities", [...formik.values.amenities, name]);
    } else {
      formik.setFieldValue(
        "amenities",
        formik.values.amenities.filter((v) => v !== name)
      );
    }
  };
  const formik = useFormik({
    initialValues: {
      address: "",
      appartment: "",
      zipcodeid: "",
      zipcode: "",
      areaId: null,
      cityId: null,
      stateId: null,
      countryId: null,
      term: "",
      offer: "",
      listingDate: "",
      endDate: "",
      listingType: "",
      noFees: 0,
      fee: "",
      BuyerAgency: "",
      SellerAgency: "",
      petNotSure: "",
      petFriendly: 0,
      min_30_shows: 0,
      air_rights: 0,
      class: '',
      propertyImage: [],
      propertymainimage: null,
      propertymainfloarplan: null,
      propertyPdf: [],
      mainCategory: "",
      Bedrooms: 0,
      Bathrooms: 0,
      SquareSqft: 0,
      ExteriorSqft: 0,
      MaintenanceFee: 0,
      RealEstateTax: 0,
      Financing: 0,
      MinimumDown: 0,
      Units: 0,
      Rooms: 0,
      Stories: 0,
      Block: 0,
      Lot: 0,
      Zone: 0,
      SquareFt: 0,
      BuildingSqft: 0,
      LotDimensions: 0,
      FAR: 0,
      Assessment: 0,
      AnnualTaxes: 0,
      cap_rate: 0,
      occupancy: 0,
      BathRooms: 0,
      BuildingDimensions: 0,
      AvailableAirRights: 0,
      property_condition: '',
      lease_type: '',
      otherCommertialInputs: [
        { space: "", size: "", term: "", rate: "", type: "" },
      ],
      openHouseDate: [
        { id: uuidv4(), start_date: "", end_date: "", },
      ],
      amenities: [],
      Title: "",
      message: "",
      listingAmount: "",
      perSqft: "",
      listDate: "",
      listTime: "",
      propertysubcategoryid: null,
    },
    validationSchema: Yup.object({
      listingType: Yup.string().required("*required"),
      Title: Yup.string().required("*required"),
      // if type is residential then bedroom bathroom requiire
      Bedrooms:
        props.residentialUser &&
        Yup.number()
          .min(0, "Bedrooms not be negative")
          .required("Bedrooms Required"),
      Bathrooms:
        props.residentialUser &&
        Yup.number()
          .min(0, "Bathrooms not be negative")
          .required("Bathrooms Required"),

      // if type is Commertial then Units Rooms requiire
      Units:
        !props.residentialUser &&
        Yup.number().min(0, "Units not be negative").required("Units Required"),
      Stories:
        !props.residentialUser &&
        Yup.number().min(0, "Stories not be negative").required("Stories Required"),
      Rooms:
        !props.residentialUser &&
        Yup.number().min(0, "Rooms not be negative").required("Rooms Required"),
      cap_rate:
        !props.residentialUser &&
        Yup.number().min(0, "Cap Rate not be negative").max(100, "Cap Rate cannot be more than 100"),
      occupancy:
        !props.residentialUser &&
        Yup.number().min(0, "Occupancy not be negative").max(100, "Occupancy cannot be more than 100"),
      propertymainimage: Yup.mixed().required("Upload Image").nullable(),
      // propertymainfloarplan: Yup.mixed().required("Upload File").nullable(),
      listingAmount: Yup.string().required("required"),
      mainCategory: Yup.string().required("*required"),
      listingDate: Yup.date()
        .min(minDay(), 'Listing date must be after or equal to today')
        .test("four-digits", "Invalid Date", function (value) {
          if (!value) {
            return true; // Let Yup handle empty values
          }
          const year = new Date(value).getFullYear();
          return year.toString().length === 4;
        })
        .required("*required"),
      endDate: Yup.date()
        .min(Yup.ref("listingDate"), "End date can't be before Listing date")
        .test("four-digits", "Invalid Date", function (value) {
          if (!value) {
            return true; // Let Yup handle empty values
          }
          const year = new Date(value).getFullYear();
          return year.toString().length === 4;
        }),
      // .required("*required"),

      // endDate: !(formik.values.listingType === "open") && Yup.date().required("*required"),

      listDate:
        props.residentialUser &&
        Yup.date().test("four-digits", "Invalid Date", function (value) {
          if (!value) {
            return true; // Let Yup handle empty values
          }
          const year = new Date(value).getFullYear();
          return year.toString().length === 4;
        }),
      // .required("*required"),
      address: Yup.string().required("*required"),
      // listTime: props.residentialUser && Yup.string().required("*required"),
      // areaId: Yup.string().required("*required"),
      zipcode: Yup.string().required("*required"),
      areaId: Yup.number().required("*required").nullable(),
      cityId: Yup.number().required("*required").nullable(),
      stateId: Yup.number().required("*required").nullable(),
      propertysubcategoryid:
        leisureSelect && Yup.string().required("*required"),
      fee: nofeeBlock && Yup.string().required("*required").nullable(),
      SquareSqft: props.residentialUser &&
        Yup.number()
          .min(0, "Square Feet not be negative")
          .required("Square Feet Required"),
      message: Yup.string().required("*required"),
      openHouseDate: props.residentialUser && Yup.array().of(
        Yup.object().shape({
          start_date: Yup.date().min(minDay(), 'Start date must be after or equal to today').required("Required"),
          end_date: Yup.date().min(minDay(), 'End date must be after or equal to today').required("Required"),
        })
      ).required(),
      // BuyerAgency: nofeeBlock && Yup.string().required("*required").nullable(),
      // SellerAgency: nofeeBlock && Yup.string().required("*required").nullable(),

      // otherCommertialInputs: Yup.array(
      //     Yup.object({
      //         space: Yup.string()
      //             .required('start time needed'),
      //         size: Yup.string()
      //             .required('End time  needed'),
      //         term: Yup.string()
      //             .required('End time  needed'),
      //         rate: Yup.string()
      //             .required('End time  needed'),
      //         type: Yup.string()
      //             .required('End time  needed'),
      //     })
      // )
    }),
    onSubmit: (values, action) => {
      setLoading(true);
      let formData = new FormData();
      formData.append("userid", userId);
      formData.append("propertytitle", formik.values.Title);
      formData.append("propertydescription", formik.values.message);
      formik.values.propertymainimage.forEach((image) => {
        formData.append("propertymainimage", image);
      });
      formik.values.propertymainfloarplan && formData.append(
        "propertymainfloarplan",
        formik.values.propertymainfloarplan
      );
      formik.values.propertysubcategoryid && formData.append(
        "propertysubcategoryid",
        formik.values.propertysubcategoryid
      );
      formData.append("propertymaincategoryid", formik.values.mainCategory);
      formData.append("property_address_1", formik.values.address);
      formData.append("propertycity", formik.values.cityId);
      formData.append("propertystate", formik.values.stateId);
      formData.append("propertyarea", formik.values.areaId);
      formData.append("property_zip", formik.values.zipcode);
      formData.append("property_terms", formik.values.term);
      formData.append("property_offer", formik.values.offer);
      formData.append("is_property_fee", !formik.values.noFees ? 1 : 0);
      formData.append("property_listing_amount", formik.values.listingAmount);
      formData.append("appartment", formik.values.appartment);
      formData.append(
        "start_date",
        moment(formik.values.listingDate).format("YYYY-MM-DD")
      );
      formData.append(
        "end_date",
        moment(formik.values.endDate).format("YYYY-MM-DD") === "Invalid date"
          ? ""
          : moment(formik.values.endDate).format("YYYY-MM-DD")
      );
      formData.append(
        "is_property_exclusive",
        formik.values.listingType === "exclusive" ? 1 : 0
      );
      formData.append("property_pet_friendly", formik.values.petFriendly);
      formData.append("min_30_shows", formik.values.min_30_shows);
      formData.append("air_rights", formik.values.air_rights);
      formData.append("class_type", formik.values.class);
      !formik.values.noFees && formData.append("fees", formik.values.fee);
      !formik.values.noFees &&
        formData.append("SellerAgency", formik.values.SellerAgency);
      !formik.values.noFees &&
        formData.append("BuyerAgency", formik.values.BuyerAgency);
      formData.append("Bedrooms", formik.values.Bedrooms);
      formData.append("Bathrooms", formik.values.Bathrooms);
      formData.append("Exterior_Sqft", formik.values.ExteriorSqft);
      formData.append("Maintence_fee", formik.values.MaintenanceFee);
      formData.append("Real_Estate_Tax", formik.values.RealEstateTax);
      formData.append("Financing", formik.values.Financing);
      formData.append("Minimum_Down", formik.values.MinimumDown);
      formData.append("Units", formik.values.Units);
      formData.append("Rooms", formik.values.Rooms);
      formData.append("Stories", formik.values.Stories);
      formData.append("Block", formik.values.Block);
      formData.append("Lot", formik.values.Lot);
      formData.append("Zone", formik.values.Zone);
      formData.append("SquareFt", formik.values.Zone);
      formData.append("Building_Sqft", formik.values.BuildingSqft);
      formData.append("Lot_Dimensions", formik.values.LotDimensions);
      formData.append("Building_Dimension", formik.values.BuildingDimensions);
      formData.append("FAR", formik.values.FAR);
      formData.append("Assessment", formik.values.Assessment);
      formData.append("Annual_Taxes", formik.values.AnnualTaxes);

      formData.append("Available_Air_Rights", formik.values.AvailableAirRights);
      formData.append("property_condition", formik.values.property_condition);
      formData.append("lease_type", formik.values.lease_type);
      formData.append("cap_rate", formik.values.cap_rate);
      formData.append("occupancy", formik.values.occupancy);
      formData.append("Square_sqft", formik.values.SquareSqft);
      let aminityarray = formik.values.amenities.map(Number);
      formData.append("aminity", JSON.stringify(aminityarray));
      formData.append("No_sure_Pet_allowed", formik.values.petNotSure ? 1 : 0);
      // confusing parameters: property_listing_type means propertylisting_type and vice versa
      formData.append("propertylisting_type", props.propertyListingTypeId);
      formData.append("property_listing_type", props.residentialUser ? 1 : 2);
      formData.append("property_cost_per_sq", formik.values.perSqft);
      formData.append(
        "Space_avaliable",
        JSON.stringify(formik.values.otherCommertialInputs)
      );
      formData.append(
        "open_house_property",
        JSON.stringify(
          formik.values.openHouseDate
            .filter(item => !!item.start_date && !!item.end_date)
            .map((item) => ({start_date: item.start_date, end_date: item.end_date}))
        )
      );
      formData.append(
        "agentuserprofileid",
        JSON.stringify(props.selectedTeamMember)
      );

      AddProperty(formData, (success) => {
        if (success.data.code === 200 && success.data.status === "success") {
          toast.success("Property add Successfully", "success");
          setLoading(false);
          token.profile.listing_count = success.data.data[1].count;
          setUserDetails(token);
          // navigate("/agent-dashboard/current-listing")
          navigate(`/${currentUser}/current-listing`);
          dispatch(updateTotalListing(success.data.data[1]?.count));
          // context.setAgentTotaListing(success.data.data[1]?.count);
          action.resetForm();
          ResetCommonForm();
          formik.resetForm();
        }
        if (success.data.code === 400 && success.data.status === "failed") {
          toast.error("Something Went Wrong");
          setLoading(false);
        }
      },
      () => {
        toast.warning("Something Went Wrong");
        setLoading(false);
      });
    },
  });

  let ResetCommonForm = () => {
    // setDisplayAutocomplete(true)
    mainImage.current.value = "";
    floreImage.current.value = "";
    zipSelect.current.clearValue();
    petchck.current.checked = false;
    const activeElement = document.getElementsByClassName("userInputNumber");
    Array.prototype.forEach.call(activeElement, function (slide, index) {
      slide.value = "";
    });
    // setPetModalValue(false)
    // setDisplayAutocomplete(true)
    // setMinModalResponce(null)
    // setPetModalResponce([])
    props.setSelectedTeamMember([]);
  };

  function handleUploadBannerImg(e) {
    let fileSize = e.target.files[0]?.size;
    if (fileSize <= 5250000) {
      const files = e.target.files;
      let formData = new FormData();

      const fileResponsesArray = [];
      let ImgObj = {};
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        fileResponsesArray.push(files[i]);
        formData.append("images", file);
        ImgObj[i] = file;
      }
      formik.setFieldValue("propertymainimage", fileResponsesArray);
    } else {
      swal("", "Image size must be under 5mb", "warning");
    }
  }

  function handleUploadPdf(e) {
    let fileSize = e.target.files[0]?.size;
    if (fileSize <= 5250000) {
      const file = e.target.files[0];
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        formik.setFieldValue("propertymainfloarplan", file);
      };
    } else {
      swal("", "Image size must be under 5mb", "warning");
    }
  }

  let checkLeisure = (e) => {
    if (e.target.id === "Leisure") {
      setLeisureSelect(true);
    } else {
      setLeisureSelect(false);
    }
  };

  let FormatAmount = (e) => {
    const result = handleAmountChange(e.target.value);
    setPropertyAmount(result);
    const AmtWithoutComma = result.replace(/,/g, "");
    formik.setFieldValue("listingAmount", AmtWithoutComma);
  };

  const MAX_Title_CHARACTERS = 60;
  let remainingCharacters = MAX_Title_CHARACTERS - formik.values.Title.length;

  const handleChangeClass = (event) => {
    const value = +event.target.value;
    formik.setFieldValue('class', value);
  };

  useEffect(() => {
    if (props.propertyListingTypeId) {
      formik.resetForm();
      setPropertyAmount('');
    }
  }, [props.propertyListingTypeId]);

  const addOpenHouse = () => {
    const openHouseList = [...formik.values.openHouseDate];

    const newOpenHouseItem = { id: uuidv4(), start_date: "", end_date: "", };
    openHouseList.push(newOpenHouseItem);

    formik.setFieldValue('openHouseDate', openHouseList);
  };

  const removeOpenHouse = (id) => {
    const openHouseList = formik.values.openHouseDate.filter((item) => item.id !== id);

    formik.setFieldValue('openHouseDate', openHouseList);
  };

  const getMinDateForNextItem = (index) => {
    if (index === 0 || formik.values.openHouseDate.length === 1) return new Date();

    const previousItemDate = new Date(formik.values.openHouseDate[index - 1].end_date);
    return new Date(previousItemDate.setDate(previousItemDate.getDate() + 1));
  };

  const handleStartDateChange = (date, index) => {
    const openHouseList = [...formik.values.openHouseDate];

    if (date) {
      openHouseList[index] = {
        ...openHouseList[index],
        start_date: date.format('YYYY-MM-DDTHH:mm:ss'),
      };

      formik.setFieldValue('openHouseDate', openHouseList);
    }

  };

  const handleEndDateChange = (date, index) => {
    const openHouseList = [...formik.values.openHouseDate];

    if (date) {
      openHouseList[index] = {
        ...openHouseList[index],
        end_date: date.format('YYYY-MM-DDTHH:mm:ss'),
      };

      formik.setFieldValue('openHouseDate', openHouseList);
    }
  };

  const handleListingDateChange = (date) => {
    if (date) {
      formik.setFieldValue("listingDate", date.format('YYYY-MM-DDTHH:mm:ss'));
    }
  };

  const handleListingEndDateChange = (date) => {
    if (date) {
      formik.setFieldValue("endDate", date.format('YYYY-MM-DDTHH:mm:ss'));
    }
  };

  return (
    <>
      {loading ? <Loader /> : null}
      <form className=" d-flex flex-grow-1 ">
        <div className="d-flex flex-grow-1 flex-column gap-4">
          {/* uppper-sectio */}
          <div className="rent-inner-section sale-middle-sec d-flex flex-column">
            <div className="upper-section d-flex justify-content-between gap-2">
              <div className="upper-section-left d-flex flex-column gap-2 flex-grow-1">
                <div className=" d-flex justify-content-between">
                  <div className="d-flex align-items-center w-75 inputbox ">
                    <p className={`formtext inputLable`}>
                      Address<span>*</span>
                    </p>
                    <input
                      type="text"
                      id=""
                      placeholder=""
                      required
                      className=" flex-grow-1"
                      name="address"
                      onChange={formik.handleChange}
                      value={formik.values.address}
                    />
                    {formik.errors.address && formik.touched.address ? (
                      <div className="validation-text">
                        {formik.errors.address}
                      </div>
                    ) : null}
                  </div>
                  <div
                    className={`d-flex align-items-center inputbox`}
                    style={{ width: "63px" }}
                  >
                    <input
                      type="text"
                      className="w-100 formtext"
                      id=""
                      placeholder="Apt#"
                      name="appartment"
                      onChange={formik.handleChange}
                      value={formik.values.appartment}
                      required
                    />
                  </div>
                </div>

                <div className=" position-relative pt-2">
                  {" "}
                  <AddressFeild formik={formik} />{" "}
                </div>
                {/* <div className=" position-relative pt-2">
                                    <Row className="second-row mx-0 align-items-center">
                                        <Col sm className="second-row-in me-2">
                                            <div className="d-flex align-items-center inputbox ">
                                                <p className={`formtext inputLable`}>State</p>
                                                <Select options={allStateList} className=' w-100 ps-0 custum-react-select select-wrapper'
                                                    classNamePrefix="select"
                                                    onChange={(e) => {
                                                        GetCityByState(e.id)
                                                        formik.setFieldValue("stateId", e.id);

                                                    }}
                                                />
                                            </div>
                                        </Col>
                                        <Col sm className="second-row-in me-2">
                                            <div className="d-flex align-items-center inputbox ">
                                                <p className={`formtext inputLable`}>city</p>
                                                <Select options={allCityList} className=' w-100 ps-0 custum-react-select select-wrapper'
                                                    classNamePrefix="select"
                                                    onChange={(e) => {
                                                        GetAreaByCity(e.id)
                                                        formik.setFieldValue("cityId", e.id);
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                        <Col sm className="second-row-in  me-2">
                                            <div className="d-flex align-items-center inputbox ">
                                                <p className={`formtext inputLable`}>Area</p>
                                                <Select options={allAreaList} className=' w-100 ps-0 custum-react-select select-wrapper'
                                                    classNamePrefix="select"
                                                    onChange={(e) => {
                                                        formik.setFieldValue("areaId", e.id);
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                        <Col sm className="second-row-in">
                                            <div className="d-flex align-items-center inputbox ">
                                                <p className={`formtext inputLable`}>Zipcode</p>
                                                <Select options={allZipcodes} className=' w-100 ps-0 custum-react-select select-wrapper'
                                                    ref={zipSelect}
                                                    classNamePrefix="select"
                                                    value={[{ label: zipcodeid?.label, id: zipcodeid?.id }]}
                                                    onChange={(e) => {
                                                        setZipcodeid(e);
                                                        formik.setFieldValue("zipcodeid", e.id);
                                                        formik.setFieldValue("zipcode", e.label);
                                                    }}
                                                />
                                            </div>
                                            {formik.errors.zipcode && formik.touched.zipcode ? (
                                                <div className='validation-text'>{formik.errors.zipcode}</div>
                                            ) : null}
                                        </Col>
                                    </Row>
                                </div> */}

                {/* condtional row */}
                {/* {!displayAutocomplete ?
                                    <>
                                        <div className=" position-relative pt-2">
                                            <Row className="second-row mx-0 align-items-center">
                                                <Col sm className="second-row-in  me-2">
                                                    <div className="d-flex align-items-center inputbox ">
                                                        <p className={`formtext inputLable`}>Area</p>
                                                        <p className="w-75 formtext">{area}</p>
                                                    </div>
                                                </Col>
                                                <Col sm className="second-row-in me-2">
                                                    <div className="d-flex align-items-center inputbox ">
                                                        <p className={`formtext inputLable`}>Town</p>
                                                        <p className="w-75 formtext">{city}</p>
                                                    </div>
                                                </Col>
                                                <Col sm className="second-row-in me-2">
                                                    <div className="d-flex align-items-center inputbox ">
                                                        <p className={`formtext inputLable`}>State</p>
                                                        <p className="w-75 formtext">{state}</p>
                                                    </div>
                                                </Col>
                                                <Col sm className="second-row-in">
                                                    <div className="d-flex align-items-center inputbox ">
                                                        <p className={`formtext inputLable`}>Zipcode</p>
                                                        <Select options={allZipcodes} className=' w-100 ps-0 custum-react-select select-wrapper'
                                                            ref={zipSelect}
                                                            classNamePrefix="select"
                                                            value={[{ label: zipcodeid?.label, id: zipcodeid?.id }]}
                                                            onChange={(e) => {
                                                                setZipcodeid(e);
                                                                formik.setFieldValue("zipcodeid", e.id);
                                                                formik.setFieldValue("zipcode", e.label);
                                                            }}
                                                        />
                                                    </div>
                                                    {formik.errors.zipcode && formik.touched.zipcode ? (
                                                        <div className='validation-text'>{formik.errors.zipcode}</div>
                                                    ) : null}
                                                </Col>
                                            </Row>
                                            <span className="pointer position-absolute "
                                                style={{ left: '-12px', top: '8px' }}
                                                onClick={() => setDisplayAutocomplete(!displayAutocomplete)}><EditIcon style={{ width: '15px', height: '15px' }} /></span>
                                        </div>
                                    </> :
                                    <div className=" d-flex">
                                        <div className="d-flex align-items-center w-100 inputbox ">
                                            <p className={`formtext inputLable`}>Address<span>*</span></p>
                                            <Autocomplete
                                                className="hide-downBtn  w-100 area-autocomplete"
                                                disablePortal
                                                placeholder="hhghrtg"
                                                id="combo-box-demo"
                                                options={addressOptions}
                                                onChange={(event, value) => {
                                                    setArea(value?.area);
                                                    setCity(value?.city);
                                                    setState(value?.state);
                                                    setAreaId(value?.area_id);
                                                    setCityId(value?.city_id);
                                                    setStateId(value?.state_id);
                                                    setCountryId(value?.country_id);
                                                    setDisplayAutocomplete(false);
                                                    formik.setFieldValue("areaId", value?.area_id);
                                                    formik.setFieldValue("cityId", value?.city_id);
                                                    formik.setFieldValue("stateId", value?.state_id);
                                                    formik.setFieldValue("countryId", value?.country_id);
                                                    // formik.setFieldValue("location",value)
                                                }}
                                                isOptionEqualToValue={(option, value) =>
                                                    option.id === value.id
                                                }
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        onChange={(e) => { setAddress(e.target.value); }}
                                                        variant="standard"
                                                    />
                                                )}
                                            />
                                            {formik.errors.areaId && formik.touched.areaId ? (
                                                <div className='validation-text'>{formik.errors.areaId}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                } */}

                {/* zipcode  Select style*/}
                {/* styles={{
                                    control: (provided, state) => ({
                                        ...provided,
                                        boxShadow: "none",
                                        border: "none",
                                    }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: state.isFocused && "lightgray",
                                        color: state.isFocused && "black",
                                        backgroundColor: 'red'

                                    }),
                                }}
                                classNamePrefix="custom-select" */}

                {/* <Row className="second-row mx-0 justify-content-end">
                                    <Col sm className="second-row-in me-2">
                                        <div className="d-flex align-items-center inputbox ">
                                            <input type="text" id="" className="calenderIcon bg-transparent border-0 w-100"
                                                ref={listingdataref}
                                                placeholder="Listing Date"
                                                onFocus={() => { (listingdataref.current.type = "date"); formik.setFieldTouched("listingDate", true) }}
                                                onBlur={() => { (listingdataref.current.type = "text"); formik.setFieldTouched("listingDate", true) }}
                                                // pattern="\d{2}\/\d{2}\/\d{4}"
                                                name="listingDate"
                                                onChange={formik.handleChange}
                                                value={formik.values.listingDate}
                                                required />
                                        </div>
                                        {formik.errors.listingDate && formik.touched.listingDate ? (
                                            <div className='validation-text'>{formik.errors.listingDate}</div>
                                        ) : null}

                                    </Col>

                                    {formik.values.listingType === "open" ?
                                        null
                                        :
                                        <Col sm className="second-row-in   me-2">
                                            <div className="d-flex align-items-center inputbox ">
                                                <input type="text" id="" placeholder="End Date" className="calenderIcon bg-transparent border-0 w-100" required
                                                    ref={listingEndDate}
                                                    onFocus={() => (listingEndDate.current.type = "date")}
                                                    onBlur={() => (listingEndDate.current.type = "text")}
                                                    name="endDate"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.endDate}
                                                />
                                            </div>
                                            {formik.errors.endDate && formik.touched.endDate ? (
                                                <div className='validation-text'>{formik.errors.endDate}</div>
                                            ) : null}
                                        </Col>

                                    }

                                    <Col sm className="second-row-in align-self-end d-flex flex-column gap-2 h-100 type-radio">
                                        <div className="d-flex justify-content-between">
                                            <div className="d-flex align-items-center gap-1">
                                                <input type="radio" name="type" id="Exclusive" value="exclusive"
                                                    onChange={(e) => { formik.setFieldValue("listingType", e.target.value) }}
                                                    checked={formik.values?.listingType === 'exclusive'}
                                                />
                                                <label htmlFor="Exclusive" className="formtext pointer">
                                                    Exclusive
                                                </label>
                                            </div>
                                            <div className="d-flex align-items-center gap-1">
                                                <input type="radio" name="type" id="Open" value="open"
                                                    onChange={(e) => { formik.setFieldValue("listingType", e.target.value) }}
                                                    checked={formik.values?.listingType === 'open'}
                                                />
                                                <label htmlFor="Open" className="formtext pointer">
                                                    Open
                                                </label>
                                            </div>
                                        </div>
                                        {formik.errors.listingType && formik.touched.listingType ? (
                                            <div className='validation-text'>{formik.errors.listingType}</div>
                                        ) : null}
                                    </Col>
                                </Row> */}

                <Row className="second-row mx-0 justify-content-start flex-row">
                  <Col
                    sm
                    className="second-row-in align-self-end d-flex flex-column gap-1 h-100 type-radio justify-content-center me-2"
                  >
                    <div className="d-flex justify-content-between">
                      <div className="d-flex align-items-center gap-1">
                        <input
                          type="radio"
                          name="type"
                          id="Exclusive"
                          value="exclusive"
                          onChange={(e) => {
                            formik.setFieldValue("listingType", e.target.value);
                          }}
                          checked={formik.values?.listingType === "exclusive"}
                        />
                        <label htmlFor="Exclusive" className="formtext pointer">
                          Exclusive
                        </label>
                      </div>
                      <div className="d-flex align-items-center gap-1">
                        <input
                          type="radio"
                          name="type"
                          id="Open"
                          value="open"
                          onChange={(e) => {
                            formik.setFieldValue("listingType", e.target.value);
                          }}
                          checked={formik.values?.listingType === "open"}
                        />
                        <label htmlFor="Open" className="formtext pointer">
                          Open
                        </label>
                      </div>
                    </div>
                    {formik.errors.listingType && formik.touched.listingType ? (
                      <div className="validation-text">
                        {formik.errors.listingType}
                      </div>
                    ) : null}
                  </Col>
                  <Col sm className="second-row-in me-2" style={{ maxWidth: "200px" }}>
                    <DatePicker
                      sx={styles.picker}
                      label={'Listing Date*'}
                      inputFormat="MM/DD/YYYY HH:mm"
                      value={formik.values.listingDate ? dayjs(formik.values.listingDate) : null}
                      onChange={(date) => handleListingDateChange(date)}
                      renderInput={(params) => <TextField {...params} placeholder={'End Date'} />}
                      minDate={dayjs(new Date())}
                    />
                    {formik.errors.listingDate && formik.touched.listingDate ? (
                      <div className="validation-text">
                        {formik.errors.listingDate}
                      </div>
                    ) : null}
                  </Col>

                  {formik.values.listingType === "open" ? null : (
                    <Col sm className="second-row-in">
                      <DatePicker
                        sx={styles.picker}
                        label={'End Date'}
                        inputFormat="MM/DD/YYYY HH:mm"
                        value={formik.values.endDate ? dayjs(formik.values.endDate) : null}
                        onChange={(date) => handleListingEndDateChange(date)}
                        renderInput={(params) => <TextField {...params} placeholder={'End Date'} />}
                        minDate={dayjs(formik.values.listingDate || new Date())}
                      />
                      {formik.errors.endDate && formik.touched.endDate ? (
                        <div className="validation-text">
                          {formik.errors.endDate}
                        </div>
                      ) : null}
                    </Col>
                  )}
                </Row>

                <Row style={{ minHeight: "24px" }}>
                  {props.noFee && (
                    <Col xs={3}>
                      <div className="d-flex align-items-center gap-1 h-100">
                        <input
                          type="checkbox"
                          name="noFee"
                          id="fee"
                          onChange={(e) => {
                            formik.setFieldValue("noFees", e.target.checked);
                            setNofeeBlock(!e.target.checked);
                          }}
                        />
                        <label htmlFor="fee" className="formtext pointer">
                          No Fee
                        </label>
                      </div>
                    </Col>
                  )}
                  {nofeeBlock && (
                    <Col xs={props.noFee ? 3 : 4}>
                      <div className="d-flex align-items-center inputbox ">
                        <input
                          placeholder="Fee"
                          id=""
                          className=" flex-grow-1"
                          name="fee"
                          onChange={formik.handleChange}
                          value={formik.values.fee}
                        />
                      </div>
                      {formik.errors.fee && formik.touched.fee ? (
                        <div className="validation-text">
                          {formik.errors.fee}
                        </div>
                      ) : null}
                    </Col>
                  )}
                  {nofeeBlock && (
                    <Col xs={props.noFee ? 3 : 4}>
                      <div className="d-flex align-items-center inputbox ">
                        <input
                          type="number"
                          placeholder="Seller Agency"
                          id=""
                          className=" flex-grow-1"
                          name="SellerAgency"
                          onChange={formik.handleChange}
                          value={formik.values.SellerAgency}
                        />
                        {/* {formik.errors.fee && formik.touched.fee ? (
                                                        <div className='validation-text'>{formik.errors.fee}</div>
                                                    ) : null} */}
                      </div>
                    </Col>
                  )}
                  {nofeeBlock && (
                    <Col xs={props.noFee ? 3 : 4}>
                      <div className="d-flex align-items-center inputbox ">
                        <input
                          type="number"
                          placeholder="Buyer Agency"
                          id=""
                          className=" flex-grow-1"
                          name="BuyerAgency"
                          onChange={formik.handleChange}
                          value={formik.values.BuyerAgency}
                        />
                        {/* {formik.errors.fee && formik.touched.fee ? (
                                                        <div className='validation-text'>{formik.errors.fee}</div>
                                                    ) : null} */}
                      </div>
                    </Col>
                  )}
                </Row>

                {/* show tearms and offers for listing_type 1 & 3 */}
                {/* (props.radiobuttns[0]?.listing_type == "1" ||
                  props.radiobuttns[0]?.listing_type == "3") */}
                {(props.radiobuttns[0]?.listing_type == "1" ||
                  props.radiobuttns[0]?.listing_type == "3") && (
                  <Row>
                    <Col>
                      <div className="d-flex align-items-center inputbox pb-0 ">
                        <select
                          aria-label="Default select example"
                          className=" w-100"
                          onChange={(e) => {
                            formik.setFieldValue("term", e.target.value);
                          }}
                        >
                          <option className=" d-none">Terms</option>
                          {termsOffers?.Terms?.map((term, i) => (
                            <option key={`terms${i}`} value={term.terms}>
                              {term.terms}
                            </option>
                          ))}
                        </select>
                      </div>
                    </Col>
                    <Col>
                      <div className="d-flex align-items-center inputbox pb-0">
                        <select
                          aria-label="Default select example"
                          className=" w-100"
                          onChange={(e) => {
                            formik.setFieldValue("offer", e.target.value);
                          }}
                        >
                          <option className=" d-none">Offer</option>
                          {termsOffers?.Offer?.map((offer, i) => (
                            <option key={`offers${i}`} value={offer.offer}>
                              {offer.offer}
                            </option>
                          ))}
                        </select>
                      </div>
                    </Col>
                  </Row>
                )}

                <div className="d-flex justify-content-between gap-2">
                  <div className="radio-btn-bar d-flex gap-3 align-items-center">
                    {/*-------------------------*/}
                    {props.residentialUser && (
                      <>
                        <div className="d-flex align-items-center gap-1">
                          <input
                            type="radio"
                            name="more_types"
                            id={'sure'}
                            onChange={(e) => {
                              formik.setFieldValue('petNotSure', true);
                              formik.setFieldValue('PetFriendly', 0);
                            }}
                            checked={formik.values?.petNotSure}
                          />
                          <label htmlFor={'sure'} className="label-text pointer">
                            Not Sure Pets Allowed
                          </label>
                        </div>
                        <div className="d-flex align-items-center gap-1">
                          <input
                            type="radio"
                            name="more_types"
                            id="PetFriendly"
                            ref={petchck}
                            onChange={(e) => {
                              formik.setFieldValue('petNotSure', false);
                              formik.setFieldValue('PetFriendly', 1);
                            }}
                            style={{ borderRadius: '50%' }}
                            checked={formik.values?.PetFriendly === 1}
                          />
                          <label
                            htmlFor="PetFriendly"
                            className="formtext pointer"
                          >
                            Pet Friendly
                          </label>
                        </div>
                      </>
                    )}
                    <div className="d-flex align-items-center gap-1">
                      <input
                        type="checkbox"
                        name="more_types"
                        id="minute"
                        onChange={(e) => {
                          formik.setFieldValue(
                            'min_30_shows',
                            e.target.checked ? 1 : 0,
                          );
                        }}
                        style={{ borderRadius: '50%' }}
                      />
                      <label htmlFor="minute" className="formtext pointer">
                        30 Minute Showing
                      </label>
                    </div>
                  </div>
                  {props.commercialUser && (
                    <div className="radio-btn-bar d-flex gap-3 align-items-center">
                      <Typography fontWeight={400} fontSize={'12px'}>Class</Typography>
                      {classTypes.map((item) => (
                        <div key={item.id} className="d-flex align-items-center gap-1">
                          <input
                            type="radio"
                            name={'class-type'}
                            id={`class-${item.id}`}
                            className="listing-category"
                            value={item.id}
                            onChange={handleChangeClass}
                          />
                          <label htmlFor={`class-${item.id}`} className="label-text pointer">
                            {item.title}
                          </label>
                        </div>
                      ))}
                    </div>
                  )}
                  {props.propertyListingTypeId === 4 && (
                    <div className="d-flex align-items-center gap-1">
                      <input
                        type="checkbox"
                        name="air-rights"
                        id="air-rights"
                        onChange={(e) => {
                          formik.setFieldValue(
                            'air_rights',
                            e.target.checked ? 1 : 0,
                          );
                        }}
                        style={{ borderRadius: '50%' }}
                      />
                      <label htmlFor="air-rights" className="formtext pointer">
                        Air Rights
                      </label>
                    </div>
                  )}
                </div>
              </div>
              <div className="upper-divider d-none d-md-block"></div>
              <div
                className="d-flex flex-column flex-grow-1 gap-2"
                style={{ maxWidth: '45%' }}
              >
                <div className='row'>
                  <div className="d-flex align-items-center inputbox col">
                    <p className="formtext inputLable">
                      Listing Amount<span>*</span>
                    </p>
                    <div className=" flex-grow-1 d-flex">
                      <input
                        type="text"
                        className=" w-100"
                        id=""
                        placeholder=""
                        required
                        pattern="[0-9]*"
                        step="any"
                        name="listingAmount"
                        onChange={(e) => {
                          FormatAmount(e);
                        }}
                        value={propertyAmount}
                      />
                      {formik.errors.listingAmount &&
                      formik.touched.listingAmount ? (
                        <div className="validation-text">
                          {formik.errors.listingAmount}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  {
                    props.radiobuttns[0]?.listing_type == "3"
                      ? (
                        <>
                          <div className="d-flex align-items-center inputbox col">
                            <p className="formtext inputLable">
                              Per Sqft.
                            </p>
                            <div className=" flex-grow-1 d-flex">
                              <input
                                placeholder=""
                                id=""
                                className=" flex-grow-1"
                                name="perSqft"
                                pattern="[0-9]*"
                                step="any"
                                onChange={formik.handleChange}
                                value={formik.values.perSqft}
                              />
                            </div>
                          </div>
                          {
                            formik.errors.perSqft && formik.touched.perSqft ? (
                              <div className="validation-text">
                                {formik.errors.perSqft}
                              </div>
                            ) : null
                          }
                        </>
                      ) : null
                  }
                </div>
                {props.residentialUser && (
                  <>
                    <p className="formtext">Open House</p>
                    {formik.values.openHouseDate.map((item, index) => (
                      <Row key={item.id}>
                        <Col>
                          <Box display={'flex'} gap={2}>
                            <DesktopDateTimePicker
                              sx={styles.picker}
                              label={'Start Date'}
                              inputFormat="MM/DD/YYYY HH:mm"
                              value={item.start_date ? dayjs(item.start_date) : null}
                              onChange={(date) => handleStartDateChange(date, index)}
                              renderInput={(params) => {
                                return <TextField {...params} placeholder={'Start Date'} />;
                              }}
                              minDate={dayjs(getMinDateForNextItem(index))}
                              slotProps={{
                                textField: {
                                  helperText: !!formik.errors.openHouseDate && !!formik.errors.openHouseDate[index]?.start_date ? formik.errors.openHouseDate[index]?.start_date : "",
                                  error: !!formik.errors.openHouseDate && !!formik.errors.openHouseDate[index]?.start_date,
                                },
                              }}
                            />
                            <DesktopDateTimePicker
                              sx={styles.picker}
                              label={'End Date'}
                              inputFormat="MM/DD/YYYY HH:mm"
                              value={item.end_date ? dayjs(item.end_date) : null}
                              onChange={(date) => handleEndDateChange(date, index)}
                              renderInput={(params) => <TextField {...params} placeholder={'End Date'} />}
                              disabled={!item.start_date}
                              minDateTime={dayjs(item.start_date)}
                              slotProps={{
                                textField: {
                                  helperText: !!formik.errors.openHouseDate && !!formik.errors.openHouseDate[index]?.end_date ? formik.errors.openHouseDate[index]?.end_date : "",
                                  error: !!formik.errors.openHouseDate && !!formik.errors.openHouseDate[index]?.end_date,
                                },
                              }}
                            />
                          </Box>
                        </Col>
                        <div className="add-remove-btn d-flex align-items-center">
                          {formik.values.openHouseDate.length - 1 === index && formik.values.openHouseDate.length < 5 ? (
                            <IconButton
                              sx={{ padding: 0 }}
                              className="pointer add-btn"
                              onClick={addOpenHouse}
                              disabled={!item.start_date || !item.end_date}
                            >
                              <AddCircleOutlineIcon />
                            </IconButton>
                          ) : (
                            <IconButton
                              sx={{ padding: 0 }}
                              className="pointer remove-btn"
                              onClick={() => removeOpenHouse(item.id)}
                            >
                              <RemoveCircleOutlineIcon />
                            </IconButton>
                          )}
                        </div>
                      </Row>
                    ))}
                  </>
                )}

                {props.commercialUser && (
                  <p className=" fw-bold">Space Available</p>
                )}
                {props.commercialUser &&
                  Array.from(Array(commertialInputes)).map((time, index) => (
                    <div key={`commertiaIm${index}`}>
                      <Row>
                        <Col>
                          <div className="d-flex align-items-center inputbox ">
                            <input
                              type="text"
                              id=""
                              className="calenderIcon bg-transparent border-0 w-100"
                              placeholder="Space"
                              name={`otherCommertialInputs.${index}.space`}
                              onChange={formik.handleChange}
                            />
                            {formik.errors.commertialSpace &&
                            formik.touched.commertialSpace ? (
                              <div className="validation-text">
                                {formik.errors.commertialSpace}
                              </div>
                            ) : null}
                          </div>
                        </Col>
                        <Col>
                          <div className="d-flex align-items-center inputbox ">
                            <input
                              type="number"
                              id=""
                              className="calenderIcon bg-transparent border-0 w-100"
                              placeholder="Size"
                              name={`otherCommertialInputs.${index}.size`}
                              onChange={formik.handleChange}
                            />
                            {formik.errors.commertialSize &&
                            formik.touched.commertialSize ? (
                              <div className="validation-text">
                                {formik.errors.commertialSize}
                              </div>
                            ) : null}
                          </div>
                        </Col>
                        <Col>
                          <div className="d-flex align-items-center inputbox ">
                            <input
                              type="text"
                              id=""
                              className="calenderIcon bg-transparent border-0 w-100"
                              placeholder="Term"
                              name={`otherCommertialInputs.${index}.term`}
                              onChange={formik.handleChange}
                            />
                            {formik.errors.commertialTerm &&
                            formik.touched.commertialTerm ? (
                              <div className="validation-text">
                                {formik.errors.commertialTerm}
                              </div>
                            ) : null}
                          </div>
                        </Col>
                        <Col>
                          <div className="d-flex align-items-center inputbox ">
                            <input
                              type="number"
                              id=""
                              className="calenderIcon bg-transparent border-0 w-100"
                              placeholder="Rate"
                              name={`otherCommertialInputs.${index}.rate`}
                              onChange={formik.handleChange}
                            />
                            {formik.errors.commertialRate &&
                            formik.touched.commertialRate ? (
                              <div className="validation-text">
                                {formik.errors.commertialRate}
                              </div>
                            ) : null}
                          </div>
                        </Col>
                        <Col>
                          <div className="d-flex align-items-center inputbox ">
                            <input
                              type="text"
                              id=""
                              className="calenderIcon bg-transparent border-0 w-100"
                              placeholder="Type"
                              name={`otherCommertialInputs.${index}.type`}
                              onChange={formik.handleChange}
                            />
                            {formik.errors.commertialType &&
                            formik.touched.commertialType ? (
                              <div className="validation-text">
                                {formik.errors.commertialType}
                              </div>
                            ) : null}
                          </div>
                        </Col>
                        <div className="add-remove-btn d-flex align-items-end">
                          {commertialInputes - 1 === index ? (
                            <AddCircleOutlineIcon
                              className="pointer add-btn"
                              onClick={() =>
                                setCommertialInputes((pre) => pre + 1)
                              }
                            />
                          ) : (
                            <RemoveCircleOutlineIcon
                              className="pointer remove-btn"
                              onClick={() =>
                                setCommertialInputes((pre) => pre - 1)
                              }
                            />
                          )}
                        </div>
                      </Row>
                    </div>
                  ))}
              </div>
            </div>

            <div className=" bottom-sec">
              <div className="d-flex flex-column bottom-box gap-2">
                {/* file upload-sec */}
                {/* <div className=" d-flex flex-wrap  gap-3 justify-content-between">
                                    <div className="d-flex flex-column file-input ">
                                        <div className="d-flex justify-content-between">
                                            <p className="formtext">File Pictures</p>
                                            <p className="file-number pointer" onClick={() => setImagemodalpopup(true)} >
                                                {`${imgfile} Image Upload`}
                                            </p>
                                        </div>
                                        <input type="file" name="propertymainimage" id="Firstimg" className="img-upload" multiple accept="image/*"
                                            ref={mainImage}
                                            onChange={(e) => {
                                                fileselect(e, "img");
                                                handleUploadBannerImg(e)
                                            }}
                                        />
                                        {formik.errors.propertymainimage ?
                                            (<div className='validation-text'>{formik.errors.propertymainimage}</div>) : null}

                                    </div>

                                    <div className="d-flex flex-column file-input ">
                                        <div className="d-flex justify-content-between">
                                            <p className="formtext">Upload FL Plans (pdf) Or Upload OM</p>
                                            <p className="file-number pointer">{`${pdffile} pdf Upload`}</p>
                                        </div>
                                        <input type="file" name="myImage" id="Firstimg" className="img-upload"
                                            multiple={false}
                                            ref={floreImage}
                                            accept="application/pdf"
                                            onChange={(e) => { fileselect(e, "pdf"); handleUploadPdf(e) }}
                                        />
                                        {formik.errors.propertymainfloarplan ?
                                            (<div className='validation-text'>{formik.errors.propertymainfloarplan}</div>) : null}
                                    </div>
                                </div> */}

                <div className=" d-flex flex-wrap  gap-3 justify-content-between">
                  <div className="d-flex flex-column file-input ">
                    <div className="d-flex justify-content-between">
                      <p className="formtext inputLable border-0">
                        File Pictures<span>*</span>
                      </p>
                      <p
                        className="file-number pointer"
                        onClick={() => setImagemodalpopup(true)}
                      >
                        {`${imgfile} Image Upload`}
                      </p>
                    </div>
                    <CustumeFileInput
                      id="MainImg"
                      multiple={true}
                      accept="image/*"
                      onChange={(e) => {
                        fileselect(e, "img");
                        handleUploadBannerImg(e);
                      }}
                    />
                    {formik.errors.propertymainimage ? (
                      <div className="validation-text">
                        {formik.errors.propertymainimage}
                      </div>
                    ) : null}
                  </div>

                  <div className="d-flex flex-column file-input ">
                    <div className="d-flex justify-content-between">
                      <p className="formtext">
                        Upload FL Plans { props.commercialUser ? 'or Offering Memorandum' : null } (pdf)
                      </p>
                      {/* <p className="file-number pointer">{`${pdffile} pdf Upload`}</p> */}
                    </div>
                    <CustumeFileInput
                      id="floorPlan"
                      multiple={false}
                      accept="application/pdf"
                      onChange={(e) => {
                        handleUploadPdf(e); //;fileselect(e, "img");
                      }}
                    />
                    {formik.errors.propertymainfloarplan ? (
                      <div className="validation-text">
                        {formik.errors.propertymainfloarplan}
                      </div>
                    ) : null}
                  </div>
                </div>

                {/* Radio buttons group*/}
                <div className="d-flex flex-wrap radio-group justify-content-center justify-content-md-between mt-1">
                  {props.radiobuttns?.map((radiobtn, index) => (
                    <div
                      className="d-flex align-items-center"
                      style={{ gap: "7px" }}
                      key={index}
                    >
                      <input
                        type="radio"
                        name="mainCategory"
                        id={radiobtn.Main_category}
                        value={radiobtn.id}
                        onChange={formik.handleChange}
                        onClick={(e) => {
                          checkLeisure(e);
                        }}
                        checked={formik.values?.mainCategory == radiobtn.id}
                      />
                      <label
                        htmlFor={radiobtn.Main_category}
                        className="formtext pointer"
                      >
                        {radiobtn.Main_category}
                      </label>
                    </div>
                  ))}
                </div>

                {leisureSelect && (
                  <Form.Select
                    aria-label="Default select example"
                    className="Leisure-subcat border-1"
                    style={{ maxWidth: "150px" }}
                    onChange={(e) => {
                      formik.setFieldValue(
                        "propertysubcategoryid",
                        e.target.value
                      );
                    }}
                  >
                    <option className=" d-none">Sub Type</option>
                    <option value="10">Industrial</option>
                    <option value="20">Specialty</option>
                  </Form.Select>
                )}
                {formik.errors.mainCategory && formik.touched.mainCategory ? (
                  <div className="validation-text ">
                    {formik.errors.mainCategory}
                  </div>
                ) : null}

                {/* input Group  */}
                {props.residentialUser && (
                  <div className="d-flex flex-wrap  btn-grp-gap ">
                    {residentialTypes.filter((item) => {
                      if (props.propertyListingTypeId === 1) {
                        return item.isRental;
                      }
                      return true;
                    }).map((singlebtn, index) => (
                      <input
                        type="number"
                        key={`input${index}`}
                        className="userInputNumber"
                        placeholder={singlebtn.title}
                        name={singlebtn.name}
                        onChange={formik.handleChange}
                        value={formik.values.name}
                      />
                    ))}
                  </div>
                )}

                {props.commercialUser && (
                  <div className="d-flex flex-wrap  btn-grp-gap ">
                    {commercialTypes.filter((item) => {
                      if (props.propertyListingTypeId === 3) {
                        return item.isLeasing;
                      }
                      return true;
                    }).map((singlebtn, index) => (
                      <>
                        {
                          !!singlebtn.fetchAsyncOptions
                            ? (
                              <AsyncSelectField
                                key={`commertialInput${index}`}
                                title={
                                  props.radiobuttns[0]?.listing_type == singlebtn.alternativeTitle?.showIfListingType
                                    ? singlebtn.alternativeTitle?.title : singlebtn.title
                                }
                                name={singlebtn.name}
                                onChange={formik.handleChange}
                                value={formik.values.name}
                                fetchAsyncOptions={singlebtn.fetchAsyncOptions}
                              />
                            )
                            : <input
                                {...singlebtn.extraProps}
                                key={`commertialInput${index}`}
                                className="userInputNumber"
                                placeholder={singlebtn.title}
                                // ref={userInputNumber}
                                name={singlebtn.name}
                                onChange={formik.handleChange}
                                value={formik.values.name}
                              />
                        }
                      </>
                    ))}
                  </div>
                )}

                <div className="d-flex gap-3">
                  {formik.errors.Bedrooms && formik.touched.Bedrooms ? (
                    <div className="validation-text">
                      {formik.errors.Bedrooms}
                    </div>
                  ) : null}
                  {formik.errors.Bathrooms && formik.touched.Bathrooms ? (
                    <div className="validation-text">
                      {formik.errors.Bathrooms}
                    </div>
                  ) : null}
                  {formik.errors.SquareSqft && formik.touched.SquareSqft ? (
                    <div className="validation-text">
                      {formik.errors.SquareSqft}
                    </div>
                  ) : null}
                  {formik.errors.Units || formik.touched.Units ? (
                    <div className="validation-text">{formik.errors.Units}</div>
                  ) : null}
                  {formik.errors.Rooms || formik.touched.Rooms ? (
                    <div className="validation-text">{formik.errors.Rooms}</div>
                  ) : null}
                  {formik.errors.cap_rate || formik.touched.cap_rate ? (
                    <div className="validation-text">{formik.errors.cap_rate}</div>
                  ) : null}
                  {formik.errors.occupancy || formik.touched.occupancy ? (
                    <div className="validation-text">{formik.errors.occupancy}</div>
                  ) : null}
                </div>

                {/* {Amenities Buttons} */}
                {props.residentialUser && (
                  <div className=" ">
                    <p
                      className="formtext mb-2"
                      style={{ fontWeight: "500", marginBottom: "6px" }}
                    >
                      Amenities
                    </p>
                    <div className="Amenities d-flex  flex-wrap ">
                      {allaminities?.map((aminiti, i) => (
                        <div
                          className="select-aminity position-relative"
                          key={`aminity${i}`}
                        >
                          <input
                            type="checkbox"
                            className="Prop aminity-input visually-hidden"
                            id={`filteraminity${i}`}
                            name={`${aminiti.id}`}
                            checked={formik.values.amenities.includes(
                              `${aminiti.id}`
                            )}
                            onChange={handleChange}
                          />
                          <label
                            htmlFor={`filteraminity${i}`}
                            className="pointer aminity"
                          >
                            {aminiti.amenities_name}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Bottom-sectio */}
          <div
            className={`d-flex flex-column rent-inner-section d-flex flex-column mb-3`}
          >
            <p className="formtext fw-bold ">Description</p>
            <div className="d-flex align-items-end flex-wrap gap-3 descBox ">
              <div
                className="d-flex  flex-column flex-grow-1"
                style={{ gap: "12px" }}
              >
                <div className="d-flex align-items-center  inputbox">
                  <p className="formtext inputLable" style={{ width: "9%" }}>
                    Title<span>*</span>
                  </p>
                  <input
                    type="text"
                    id=""
                    placeholder=""
                    required
                    className="w-100"
                    name="Title"
                    onChange={formik.handleChange}
                    value={formik.values.Title.replace(blockKeywords, "").slice(
                      0,
                      MAX_Title_CHARACTERS - 1
                    )}
                  />
                  {formik.errors.Title && formik.touched.Title ? (
                    <div className="validation-text">{formik.errors.Title}</div>
                  ) : null}
                </div>
                <p className=" text-end">
                  Remaining characters : {remainingCharacters}/
                  {MAX_Title_CHARACTERS}
                </p>

                <div className="d-flex inputbox">
                  <p className="formtext inputLable" style={{ width: "9%" }}>
                    Message<span>*</span>
                  </p>
                  <textarea
                    className="formtext bg-transparent border-0 w-100 "
                    placeholder="Lorem ipsum dolor sit amet consectetur adipisicing elit. Odit quae assumenda officiis ab error officia."
                    required
                    name="message"
                    onChange={formik.handleChange}
                    value={formik.values.message.replace(blockKeywords, "")}
                    cols="30"
                    rows="4"
                  />
                  {formik.errors.message && formik.touched.message ? (
                    <div className="validation-text">{formik.errors.message}</div>
                  ) : null}
                </div>
              </div>
              <Button
                style={{ borderRadius: "20px" }}
                onClick={formik.handleSubmit}
              >
                Submit
              </Button>
              {/* <Button onClick={formik.handleSubmit} disabled={!props.licenseApprove}>Submit</Button> */}
            </div>
          </div>
        </div>
      </form>

      {imagemodalpopup && (
        <ImageModal
          modalShow={imagemodalpopup}
          setModalShow={setImagemodalpopup}
          images={images}
          formik={formik}
        />
      )}
      {/* <MinModal minModalValue={minModalValue} setMinModalValue={setMinModalValue} setMinModalResponce={setMinModalResponce} />
            <PetModal setPetModalValue={setPetModalValue} petModalValue={petModalValue} setPetModalResponce={setPetModalResponce} /> */}
      <OPenHouseTimeModal
        openHouseData={openHouseData}
        setOpenHouseData={setOpenHouseData}
        openHouseModal={openHouseModal}
        setOpenHouseModal={setOpenHouseModal}
      />
    </>
  );
};

export default CommonForm;
