// import { getDefaultPropertyShow } from "../mainSection/server";
import AdCard from "./AdCard";
import AdCardGuest from "./AdCardGuest";
import "./advertise.scss";
import banner from "./images/banner.jpg";
import { getToken } from "../../../../../appVariable/variable";
import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useMemo } from 'react';
import { AdvertiseModal } from '@components/pages/dashboard/components/advertise/AdvertiseModal';
import { getIsShowThankYou } from '@bus/advertise/selectors';
import { ThankYouModal } from '@components/pages/dashboard/components/advertise/ThankYouModal';
import { advertiseActions } from '@bus/advertise/actions';


const Advertise = (props) => {
    let userlogin = getToken()
    const { hideListIfEmpty = false} = props;
    const [showAdvertiseModal, setShowAdvertiseModal] = useState(false);
    const FilterDataInStore = useSelector(state => state.FilterDataInStore);
    const isShowThankYou = useSelector(getIsShowThankYou);
    const showAddFeed = useMemo(() => {
        const list = userlogin?.user_info.usertypeobj === 2 ? props.teamMember : FilterDataInStore?.Neighborhood;
        if (hideListIfEmpty) return !!list?.length;
        return true;
    }, [hideListIfEmpty, userlogin?.user_info, FilterDataInStore?.Neighborhood, props.teamMember])
    const dispatch = useDispatch();

    const handleChange = (e) => {
        const { checked, id } = e.target;
        let onlyid = parseInt(id.substring(6));
        let ids = props.selectedTeamMember
        if (checked) {
            ids = [...ids, onlyid]
        } else {
            ids = (props.selectedTeamMember.filter((v) => v !== onlyid));
        }
        console.log(ids)
        props.setSelectedTeamMember(ids)
    };

    const handleOpenAdvertise = () => {
        setShowAdvertiseModal(true);
    };

    const handleCloseAdvertise = () => {
        setShowAdvertiseModal(false);
    };

    const handleCloseThankYou = () => {
        dispatch(advertiseActions.setShowThankYouModal(false));
    };

    return (
      <>
          <div className="advertise d-flex flex-column flex-grow-1">
              <div className="banner" onClick={handleOpenAdvertise}>
                  <img src={banner} alt="" />
              </div>
              {
                  showAddFeed
                    ? (
                      <div className="ad-feed d-flex flex-column">
                          <p className=' text-center text-uppercase'>{props.title}</p>
                          <div className="ad-feed-container d-flex flex-wrap justify-content-between pb-3">
                              {
                                  userlogin?.user_info.usertypeobj === 2 ?
                                    (
                                      props.teamMember?.map((Memberinfo, index) =>
                                        <div className="d-flex align-items-center  select-member" style={{ gap: "7px" }} key={`usercards${index}`} >
                                            <input type="checkbox" name="cardId"
                                                   id={`member${Memberinfo.id}`}
                                              // value={card.id}
                                                   onChange={handleChange}
                                                   checked={props.selectedTeamMember.includes(Memberinfo.id)}
                                                   hidden
                                            />
                                            <label htmlFor={`member${Memberinfo.id}`} className="formtext ">
                                                <AdCard key={`adcard${index + 1}`} Memberinfo={Memberinfo} />
                                            </label>
                                        </div>
                                      )
                                    )
                                    :
                                    FilterDataInStore?.Neighborhood?.map((memberinfo, i) => (
                                      <AdCardGuest key={i} Memberinfo={memberinfo} />
                                    ))
                              }
                          </div>
                      </div>
                    )
                    : null
              }
          </div>
          <AdvertiseModal showAdvertiseModal={showAdvertiseModal} handleClose={handleCloseAdvertise} />
          <ThankYouModal show={isShowThankYou} handleClose={handleCloseThankYou} />
      </>
    )
}

export default Advertise;
