import React, { useEffect, useState } from 'react';
import { getToken } from "../../../../../appVariable/variable";
import "./community.scss";
import CommunitySlider from "./components/CommunitySlider";
import { useDispatch, useSelector } from 'react-redux';
import { landingActions } from '@bus/landing/actions';
import { getCommercialNearestCity, getResidentialNearestCity } from '@bus/landing/selectors';
import { getLocations } from '@components/pages/homepage/components/hero/server';

const Community = () => {
  const token = getToken();
  const dispatch = useDispatch();
  const userType = token?.user_info?.usertypeobj;
  const commercialNearestCity = useSelector(getCommercialNearestCity);
  const residentialNearestCity = useSelector(getResidentialNearestCity);
  const userInfo = JSON.parse(localStorage.getItem('locationDetails'));

  useEffect(() => {
    if (userInfo) {
      dispatch(landingActions.fetchNearestCity({
        latitude: userInfo.latitude,
        longitude: userInfo.longitude,
      }));
    }
  }, [userInfo?.latitude, userInfo?.longitude]);

  useEffect(() => {
    if (residentialNearestCity?.length) {
      localStorage.setItem("residentailCityId", (userType === 1 ? JSON.stringify(residentialNearestCity[0]?.cityId) : null))
    }
  }, [residentialNearestCity]);

  return (
    <div className=" layout span-to-top">
      <div className="section community-section d-flex flex-column">
        <h4>COMMUNITIES</h4>
        <div className="community-inner d-flex flex-column">
          <CommunitySlider
            heading="Some of our Best Residentail properties available"
            communities={residentialNearestCity}
          />
          <CommunitySlider
            heading="Some of our Best Commercial properties available"
            communities={commercialNearestCity}
          />
        </div>
      </div>
    </div>
  );
};

export default Community;
