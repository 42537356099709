import * as effects from 'redux-saga/effects';
import { api } from '../../../../REST/api';

import { search2Actions } from '../../actions';
import { PayloadActionWithPromiseMeta } from "../../../../core/FinalForm/typedefs";

export function* applySearch({
   payload,
   meta: { resolve, reject },
 }: PayloadActionWithPromiseMeta<any>) {
  try {
    yield effects.put(search2Actions.startFetching());
    const body: string = yield effects.call(JSON.stringify, payload);
    const response: Response = yield effects.apply(api, api.post, [
      { endpoint: `api/property/search/`, body, unsafe: true },
    ]);

    if (!response.ok) {
      reject();
    }

    const data: unknown = yield effects.call([
      response,
      'json',
    ]);

    resolve(data);
  } catch (e) {
  } finally {
    yield effects.put(search2Actions.stopFetching());
  }
}
