import * as effects from 'redux-saga/effects';

import { search2Actions } from '../actions';
import {
  applySearch,
  fetchLeaseType,
} from './workers';

function* watchApplySearch() {
  yield effects.takeEvery(search2Actions.applySearch.type, applySearch);
}

function* watchFetchLeaseType() {
  yield effects.takeEvery(search2Actions.fetchLeaseType.type, fetchLeaseType);
}
export function* watchSearch2() {
  yield effects.all([
    effects.call(watchApplySearch),
    effects.call(watchFetchLeaseType),
  ]);
}
