import { getToken } from "../../../appVariable/variable";
import axiosInstance from '../../../REST/axiosInstance';

export function expireListingProperty({
  currentType,
  query,
  url,
}, success) {
    let token = getToken()
    let userId = token?.userId;
    url = url || `api/property/ExpiredListingProperty/${userId}`;
    const dateobj = {
      propertylisting_type: currentType,
      query,
    };
  axiosInstance.get(url, {
        params: dateobj,
        headers: { 'Authorization': `Bearer ${token?.idToken}` }
    })
        .then((response) => {
          success(response.data);
        })
        .catch((error) => { console.log(error); });
};

export function agentProfileExpireListingProperty(paramObj, success) {
    let token = getToken()
  axiosInstance.get(`api/property/closelisting/${paramObj}`, {
        headers: { 'Authorization': `Bearer ${token?.idToken}` }
    })
        .then((response) => {
            if (response.data.code === 200 && response.data.status === "success") {
                success(response.data.data);
            }
        })
        .catch((error) => { console.log(error); });
}

export function expireListingPropertyCount(paramObj, success) {
    let token = getToken()
  axiosInstance.get(`api/property/closelistingcount/${paramObj}`, {
        headers: { 'Authorization': `Bearer ${token?.idToken}` }
    })
        .then((response) => {
            if (response.data.code === 200 && response.data.status === "success") {
                success(response);
            }
        })
        .catch((error) => { console.log(error); });
}

export function GetAllCityAndLanguaage(paramObj, success) {
  axiosInstance.get(`api/user/Teamemeberlanguageandcity/${paramObj}`)
        .then((response) => {
            if (response.data.code === 200 && response.data.status === "success") { success(response.data.data); }
        })
        .catch((error) => { console.log(error); });
}

export const totalListingcount = (paramObj, success) => {
    let token = getToken();
  axiosInstance.get(`api/property/totallistingcount/${paramObj}`, {
        header: { 'Authorization': `Bearer ${token?.idToken}` }
    })
        .then((response) => {
            if (response.data.code === 200 && response.data.status === "success") {
                success(response);
            }
        }).catch((error) => { console.log(error); });
}

export function closeExpireListingProperty(accountobj, success) {
    let token = getToken()
  axiosInstance.put(`api/property/ispropertyopen/`, accountobj, {
        headers: { 'Authorization': `Bearer ${token?.idToken}` }
    })
        .then((response) => {
            if (response.data.code === 200 && response.data.status === "success") {
                success(response);
            }
        })
        .catch((error) => { console.log(error); });
}
export async function deleteExpireListingProperty(accountobj, success) {
    let token = getToken()
    await axiosInstance.post(`api/property/deleteproperty/`, accountobj, {
        headers: { 'Authorization': `Bearer ${token?.idToken}` }
    })
        .then((response) => {
            if (response.data.code === 200 && response.data.status === "success") {
                success(response);
            }
        })
        .catch((error) => { console.log(error); });
}