import React from 'react';
import { FieldRenderProps } from 'react-final-form';

import { Box, MenuItem, TextField } from '@mui/material';

import { getFieldError } from '@core/FinalForm/getFieldError';
import { styles } from '@components/forms/PopUpFilterForm/styles';
import Typography from '@mui/material/Typography';
import { CustumSelect } from '@components/pages/dashboard/components/filter-popup/PopUpFilter';
import { updateFilterData } from '@components/pages/homepage/components/hero/FilterSlice';
import { useDispatch, useSelector } from 'react-redux';
import { logo } from '@components/pages/homepage/components/navbar/Navbar';

export const RenderCategoryField: React.FC<FieldRenderProps<number>> = ({
  input: {onChange, ...input},
  meta,
}) => {
  const FilterOptionsInStore = useSelector((state: any) => state.FilterOptionsInStore);
  const dispatch = useDispatch();

  const handleChange = (event: any) => {
    onChange(event.target.value);
    dispatch(updateFilterData({ category: +event.target.value }));
  };

  return (
    <div className="select-property-type filter-row">
      <div className="d-flex gap-3">
        <p className={"filter-heading"}>Category</p>
        {!!getFieldError(meta) && (
          <Box sx={styles.error}>{getFieldError(meta)}</Box>
        )}
      </div>
      <div className="select-box inner-shadow">
        <CustumSelect
          className="hide-downBtn hero-select"
          labelId="demo-simple-select-label"
          id="demo-simple-select2"
          label="Category"
          onChange={handleChange}
          {...input}>
          {FilterOptionsInStore.categotyOptions.length > 0 ? (
            FilterOptionsInStore.categotyOptions?.map(
              (item: any, index: number) => (
                <MenuItem key={`category${index}`} value={item.id}>
                  {" "}
                  {item.label}{" "}
                </MenuItem>
              )
            )
          ) : (
            <MenuItem value={""}>No Option</MenuItem>
          )}
        </CustumSelect>
      </div>
    </div>
  );
};
