import React, { useEffect } from "react";
import { useState } from "react";
import MapviewFilter from "../../mapview-filter/MapviewFilter";
import Advertise from "../components/advertise/Advertise";
import Main from "../components/mainSection/Main";
import { getNblSpecial } from "../../agent-dash-sales/server";
import { useDispatch, useSelector } from 'react-redux';
import { GetCityId } from '@store/selectors';
import {
  getCategories, getLocations,
  getTypeOfProperties,
  getTypesBasedOnCategory,
} from '@components/pages/homepage/components/hero/server';
import { getAccounttype } from '@components/pages/personal/typeOfAccount/server';
import { useNavigate, useParams } from 'react-router-dom';
import { getSaveSearchFilterPropertyDetail } from '@components/pages/saveSearch/Server';
import { updateFilterData } from '@components/pages/homepage/components/hero/FilterSlice';
import { search2Actions } from '@bus/search2/actions';
import { toast } from 'react-toastify';
import { getToken } from '../../../../appVariable/variable';
import { getIsSearch } from '@bus/search2/selectors';

const QuikView = () => {
  const [viewsetting, setViewsetting] = useState(false);
  const [nblSpecial, setNblSpecial] = useState([])
  const cityId = useSelector(GetCityId);

  const TeammerberProfile = (id) => {
    getNblSpecial(id, (success) => {
      setNblSpecial(success.neighbourhood);
      dispatch(updateFilterData({ Neighborhood: success.neighbourhood }));
    });
  };

  useEffect(() => {
    TeammerberProfile(cityId);
  }, []);

  const filter = useSelector((state) => state.FilterDataInStore);
  const token = getToken();
  const userId = token?.userId;
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [typeofAccount, setTypeofAccount] = useState(0);
  const [showSetAccPopup, setshowSetAccPopup] = useState(false);
  const [loading, setloading] = useState(false);
  const [allProperties, setAllProperties] = useState([]);
  const [buyRentFilter, setbuyRentFilter] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [optionsList, setOptionsList] = useState([]);
  const isSearch = useSelector(getIsSearch);

  let ListofProperties = async () => {
    const blockedKeys = [
      "locationText",
      "Filter_responce",
      "filter_name",
      "Neighborhood",
    ];
    const searchSchema = { ...filter };
    blockedKeys.forEach((key) => delete searchSchema[key]);

    if (params.id) {
      await fetchDataSaveSearch();

      return;
    }

    if (!filter.property_listing || !filter.category) {
      await getTypeOfProperties((success) => {
        const index = typeofAccount - 1 || 0;
        fetchDefaultPropertyData(success[index].id)
          .then()
          .catch();
      });
    } else {
      handleSearch(filter);
      CheckFilterValue(filter.property_listing);
    }
  };

  async function getPropertiesType() {
    let typeOfuser = token?.user_info?.usertypeobj;
    let type_of_listing = token?.user_info?.type_of_listing;
    if (type_of_listing === null && typeOfuser === 1) {
      setshowSetAccPopup(true);
    }
  }

  useEffect(() => {
    setloading(true);
    getPropertiesType();
    if (!isSearch && typeofAccount) {
      ListofProperties();
    }
    if (isSearch && filter.property_listing) {
      CheckFilterValue(filter.property_listing);
    }
  }, [typeofAccount]);

  useEffect(() => {
    getAccounttype((success) => {
        setTypeofAccount(success.type_allowed + 1)
      },
      (error) => {
        setTypeofAccount(1);
        console.log(error)
      });
  }, []);

  useEffect(() => {
    let timerOut = setTimeout(() => {
      getLocations(searchQuery, (success) => {
        let newarr = success.area.map((item) => {
          return {
            label: `${
              item.area_name +
              ", " +
              item.city_master?.city_name +
              ", " +
              item.city_master.state_master?.state_name +
              "," +
              item.city_master.state_master.country_master?.country_name
            }`,
            value: {
              area_id: item.id,
              city_id: item.city_master.id,
              state_id: item.city_master.state_master.id,
              country_id: item.city_master.state_master.country_master.id,
            },
          };
        });
        let newarr1 = success.city.map((item) => {
          return {
            label: `${
              item?.city_name +
              "," +
              item?.state_master?.state_name +
              "," +
              item?.state_master.country_master.country_name
            }`,
            value: {
              city_id: item?.id,
              state_id: item?.state_master.id,
              country_id: item?.state_master.country_master.id,
            },
          };
        });
        let newarr2 = success.state.map((item) => {
          return {
            label: `${
              item?.state_name + "," + item?.country_master.country_name
            }`,
            value: {
              state_id: item?.id,
              country_id: item?.country_master.id,
            },
          };
        });
        setOptionsList([...newarr, ...newarr1, ...newarr2]);
      });
      setloading(false);
    }, 1000);

    return () => clearTimeout(timerOut);
  }, [searchQuery]);
  const fetchDataSaveSearch = () => {
    let id = params.id;
    CheckFilterValue(token?.user_info?.usertypeobj);
    getSaveSearchFilterPropertyDetail(id, (success) => {
      if (success.data.code === 400 || success.data.status === "failed") {
        dispatch(updateFilterData({ Filter_responce: [] }));
        setAllProperties([]);
      } else if (success.data.code === 200) {
        dispatch(
          updateFilterData({ Filter_responce: success.data.data.property })
        );
        setAllProperties(success.data.data.property);
      }
    });
  };

  const fetchDefaultPropertyData = async (property_type) => {
    let tempObj = { ...filter };
    CheckFilterValue(property_type);
    tempObj["user_id"] = userId;
    tempObj["property_listing"] = property_type;
    dispatch(
      updateFilterData({ property_listing: property_type, user_id: userId })
    );
    await new Promise((resolve, reject) => {
      getCategories(
        property_type,
        (success) => {
          tempObj["category"] = success[0]?.id;
          dispatch(updateFilterData({ category: success[0]?.id }));
          resolve(success);
        },
        reject
      );
    });

    await new Promise((resolve, reject) => {
      getTypesBasedOnCategory(
        tempObj["category"],
        (success) => {
          tempObj["type"] = success[0]?.id;
          dispatch(updateFilterData({ type: success[0]?.id }));
          resolve(success);
        },
        reject
      );
    });

    if (tempObj.property_listing && tempObj.category && tempObj.type) {
      const blockedKeys = [
        "locationText",
        "Filter_responce",
        "filter_name",
        "Neighborhood",
      ];
      const searchSchema = { ...tempObj };
      blockedKeys.forEach((key) => delete searchSchema[key]);
      handleSearch(searchSchema);
    }
  };

  const handleSearch = (searchSchema, url) => {
    new Promise((resolve, reject) => {
      dispatch(
        search2Actions.applySearch({
          values: searchSchema,
          resolve,
          reject,
        }),
      );
    })
      .then((data) => {
        dispatch(
          updateFilterData({
            Filter_responce: data.results,
          })
        );
        if (url) {
          navigate(url);
        }
      })
      .catch(() => {
        toast.error("No Property Availble for this search");
      });

    if (searchSchema.city) {
      TeammerberProfile(searchSchema.city)
    }
  };

  const CheckFilterValue = (id) => {
    getCategories(id, (success) => {
      dispatch(search2Actions.setIsSearch(false));
      setbuyRentFilter(success);
    });
  };

  return (
    <div className="flex-grow-1">
      {viewsetting ? (
        <div className="flex-grow-1 h-100">
          <MapviewFilter
            setViewsetting={setViewsetting}
            viewsetting={viewsetting}
            handleSearch={handleSearch}
            allProperties={allProperties}
            setAllProperties={setAllProperties}
            showSetAccPopup={showSetAccPopup}
            setshowSetAccPopup={setshowSetAccPopup}
            loading={loading}
            setloading={setloading}
            buyRentFilter={buyRentFilter}
            setSearchQuery={setSearchQuery}
            optionsList={optionsList}
            typeofAccount={typeofAccount}/>
        </div>
      ) : (
        <div className="d-flex gap-2" style={{ height: 'calc(100% - 7rem)' }}>
          <Main
            setViewsetting={setViewsetting}
            viewsetting={viewsetting}
            handleSearch={handleSearch}
            allProperties={allProperties}
            setAllProperties={setAllProperties}
            showSetAccPopup={showSetAccPopup}
            setshowSetAccPopup={setshowSetAccPopup}
            loading={loading}
            setloading={setloading}
            buyRentFilter={buyRentFilter}
            setSearchQuery={setSearchQuery}
            optionsList={optionsList} />
          <Advertise
            title="NEIGHBORHOOD SPECIALIST"
            nblSpecial={nblSpecial}
            userType="guest" />
        </div>
      )}
    </div>
  );
};

export default QuikView;
