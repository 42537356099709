import { createSelector } from "@reduxjs/toolkit";
import { getInitials } from "@components/shared/profile-avatar";

export const PersonalInfo = createSelector((state) => state.PersonalInfoInStore, (result) => result);
export const GetPersonalFirstName = createSelector(PersonalInfo, ({ firstName }) => (firstName || 'Afisado'));
export const GetPersonalLastName = createSelector(PersonalInfo, ({ lastName }) => (lastName || ''));
export const GetPersonalFullName = createSelector(
  [GetPersonalFirstName, GetPersonalLastName],
  (firstName, lastName) => `${firstName} ${lastName}`.trim()
);
export const GetPersonalInitials = createSelector(GetPersonalFullName, (fullName) => getInitials(fullName));
export const GetPersonalProfileImage = createSelector(PersonalInfo, ({ profile_image }) => {
  if (!profile_image) return;
  return `${profile_image}`;
});