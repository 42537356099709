import React from "react";
import icons from "../../dashboard/components/sidebar/images/SidebarIcon";
import { useParams } from "react-router-dom";
const ContactCard = (props) => {
  const { info, id, setActiveContact } = props;
  const params = useParams();
  return (
    <>
      <div
        className={
          props.isActive
            ? "current-contact contact-details contact-card d-flex  align-items-center pointer "
            : "contact-details contact-card d-flex  align-items-center pointer"
        }
        onClick={() => props.setisActive(id)}
      >
        <div className="prof-img">
          <img
            alt=""
            srcSet=""
            className=" h-100"
            src={
              props?.userImage?.profile_image
                ? `${props?.userImage?.profile_image}`
                : icons.profile
            }
          />
        </div>
        <div className="contact-name">
          <h6 className=" pb-1">{info?.custom_obj?.name}</h6>
          <p>{info?.custom_obj?.current_address}</p>
        </div>
      </div>
    </>
  );
};

export default ContactCard;
