import { getToken } from "../../../appVariable/variable";
import axiosInstance from '../../../REST/axiosInstance';

export async function addsupportticket(formData, success) {

    let token = getToken()
    await axiosInstance.post(`api/user/supportticketview/`, formData, {
        headers: { 'Authorization': `Bearer ${token?.idToken}` }
    })
        .then((response) => {
            if (response.data.code === 200 && response.data.status === "success") {
                success(response.data.data);
            }
        })
        .catch((error) => {
            console.log(error);
        });
}

export async function getSupportticket(success) {
    let token = getToken()
    let userId = token?.userId;
    await axiosInstance.get(`api/user/supportticketview/${userId}`, {
        headers: {
            'Authorization': `Bearer ${token?.idToken}`
        }
    })
        .then((response) => {
            if (response.data.code === 200 && response.data.status === "success") {
                console.log(response.data.data);
                success(response.data.data);
            } else {
                success(null)
            }
        })
        .catch((error) => {
            console.log(error);
        });
}

export async function deleteSupportticket(deleteid, success) {
    let token = getToken()
    await axiosInstance.delete(`api/user/supportticketview/${deleteid}`,
        { headers: { 'Authorization': `Bearer ${token?.accessToken}` }, })
        .then((response) => {
            if (response.data.code === 200 && response.data.status === "success") {
                console.log(response.data.data);
                success(response.data.data);
            }
        })
        .catch((error) => { console.log(error); });
}

export function GetIssueType(success) {
  axiosInstance.get(`api/user/AddIssuePriorityType/`)
        .then((response) => {
            if (response.data.code === 200 && response.data.status === "success") {
                success(response.data.data);
            }
        })
        .catch((error) => { console.log(error); });
}