import React from 'react'
import "./properytCard.scss"
import { Rating } from '@mui/material';
import { getInitials, ProfileAvatar } from "@components/shared/profile-avatar";

const ProperytCard = (props) => {
    const { MemberDetail } = props
    const fullName = `${MemberDetail?.first_name} ${MemberDetail?.last_name}`.trim();

    return (
        <div className="d-flex align-items-center p-2 property-team-member justify-content-evenly flex-column" >
            <div className='prof-img'>
                <ProfileAvatar
                  width={37}
                  height={37}
                  profileInstance={
                    { fullName, image: MemberDetail?.profile_image, initials: getInitials(fullName) }
                  }
                />
            </div>
            <p className="name text-capitalize text-center">
                {`${MemberDetail?.first_name} ${MemberDetail?.last_name}`}
            </p>
            <div className='user_type'><p className=' text-center'>Advertisement</p></div>
            <p className='license_title text-center'>{MemberDetail?.agent_broker_license_title}</p>
            <div className='Star_icon'>
                <Rating name="read-only" value={MemberDetail?.rating ? MemberDetail?.rating : 0} readOnly />
            </div>
        </div>
    )
}

export default ProperytCard
