import React, { ElementType } from 'react';

export type AppRoute<P> = {
  path: string;
  Component: React.ReactElement;
  Layout: React.FC<P>;
  layoutProps: P;
  forRole?: string;
  title?: string;
  exact?: boolean;
  allowedRoles?: UserRole[];
  children?: {
    path: string;
    Component: React.ReactElement;
    layoutProps: P;
    childAllowedRoles?: UserRole[];
  }[];
};

export enum UserRole {
  customer = 1,
  agentAndBroker = 2,
  developersPropertyInvestors = 3,
  FRBHosFSBHOs = 4,
  landlordPropertyManagers = 5,
  advertisers = 6,
}
