import Modal from 'react-bootstrap/Modal';
import { AiOutlineClose } from 'react-icons/ai';
import React from 'react';
import { IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import FinalForm from '@core/FinalForm';
import { SubmitRequestForAdvertising, schema } from '@components/forms/SubmitRequestForAdvertising';
import { useDispatch } from 'react-redux';
import { advertiseActions } from '@bus/advertise/actions';

export const AdvertiseModal = ({ showAdvertiseModal, handleClose }) => {
  const dispatch = useDispatch();

  const handleSuccess = () => {
    handleClose();
    dispatch(advertiseActions.setShowThankYouModal(true));
  };

  return (
    <Modal show={showAdvertiseModal} onHide={handleClose} size={'lg'} centered>
      <Modal.Header className={'justify-content-end'}>
        <IconButton onClick={handleClose}>
          <AiOutlineClose size={28} />
        </IconButton>
      </Modal.Header>
      <Modal.Body className={'py-0'}>
        <Box>
          <FinalForm
            initialValues={{}}
            component={SubmitRequestForAdvertising}
            schema={schema}
            sagaAction={advertiseActions.sendAdvertiseRequest}
            onSubmitSuccess={handleSuccess}
          />
        </Box>
      </Modal.Body>
    </Modal>
  );
};