import React, { useEffect, useState } from "react";
import filter from "../../pages/dashboard/components/mainSection/images/filter.svg";
import search from "../../pages/dashboard/components/mainSection/images/search.svg";
import { useDispatch, useSelector } from "react-redux";
import "./PropertyDetails.scss";
import PropertyCard from "../dashboard/components/mainSection/PropertyCard";
import AdCardGuest from "../dashboard/components/advertise/AdCardGuest";
import {
  getAllpropertyInfo,
} from "../dashboard/components/mainSection/server";
import PropertySavePopUp from "../dashboard/components/mainSection/PropertySavePopUp";
import PopUpFilter from "../dashboard/components/filter-popup/PopUpFilter";
import {
  getCategories,
  getLocations, getTypeOfProperties, getTypesBasedOnCategory,
} from '../homepage/components/hero/server';
import SaveSearchModel from "../dashboard/components/filter-popup/SaveSearchModel";
import { Autocomplete, TextField } from "@mui/material";
import PropertyInfoComponent from "../dashboard/propperty-datails/component/PropertyInfoComponent";
import { updatePropertyDatail } from "../dashboard/propperty-datails/PropertySlice";
import { updateFilterData } from "../homepage/components/hero/FilterSlice";
import { useNavigate } from "react-router-dom";
import { getAccounttype } from '@components/pages/personal/typeOfAccount/server';
import { search2Actions } from '@bus/search2/actions';
import { toast } from 'react-toastify';
import { getNblSpecial } from '@components/pages/agent-dash-sales/server';
import { GetCityId } from '../../../store/selectors';
import Typography from '@mui/material/Typography';
import Loader from '@components/pages/Loader/Loader';
import { book } from '@routes/book';

const PropertyDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const FilterDataInStore = useSelector((state) => state.FilterDataInStore);
  const saveSearchPopupVisible = useSelector(
    (state) => state.search.saveSearchPopupVisible
  );
  const [optionsList, setOptionsList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [propertyDetailInfo, setPropertyDetailInfo] = useState(null);
  const propertyDetailInStore = useSelector(
    (state) => state.propertyDetailInStore
  );
  const [allProperties, setAllProperties] = useState([]);
  const [buyRentFilter, setbuyRentFilter] = useState([]);
  const [checkBoxActiveFiletr, setCheckBoxActiveFiletr] = useState({});
  const [typeofAccount, setTypeofAccount] = useState(0);
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAccounttype((success) => {
        setTypeofAccount(success.type_allowed + 1)
      },
      (error) => {
        console.log(error)
      });
  }, []);

  useEffect(() => {
    let timerOut = setTimeout(() => {
      getLocations(searchQuery, (success) => {
        let newarr = success.area.map((item) => {
          return {
            label: `${
              item.area_name +
              ", " +
              item.city_master.city_name +
              ", " +
              item.city_master.state_master.state_name +
              "," +
              item.city_master.state_master.country_master.country_name
            }`,
            value: {
              area_id: item.id,
              city_id: item.city_master.id,
              state_id: item.city_master.state_master.id,
              country_id: item.city_master.state_master.country_master.id,
            },
          };
        });
        let newarr1 = success.city.map((item) => {
          return {
            label: `${
              item.city_name +
              "," +
              item.state_master.state_name +
              "," +
              item.state_master.country_master.country_name
            }`,
            value: {
              city_id: item.id,
              state_id: item.state_master.id,
              country_id: item.state_master.country_master.id,
            },
          };
        });
        let newarr2 = success.state.map((item) => {
          return {
            label: `${
              item.state_name + "," + item.country_master.country_name
            }`,
            value: {
              state_id: item.id,
              country_id: item.country_master.id,
            },
          };
        });
        setOptionsList([...newarr, ...newarr1, ...newarr2]);
      });
    }, 1000);
    return () => clearTimeout(timerOut);
  }, [searchQuery]);

  const locationOnChange = (e, isSearch) => {
    const location = {
      locationText: e?.label,
      area: e?.value?.area_id,
      city: e?.value?.city_id,
      state: e?.value?.state_id,
      country: e?.value?.country_id,
    };
    dispatch(
      updateFilterData(location)
    );
    if (isSearch) {
      handleSearch({ ...FilterDataInStore, ...location });
    }
    setValue(e);
  };

  useEffect(() => {
    if (optionsList.length) {
      const address = optionsList.find((item) => item.value.area_id === FilterDataInStore.area);

      setValue(address ?? '');
    }
  }, [optionsList.length]);

  let SearchByLoaction = () => {
    if (FilterDataInStore.property_listing && FilterDataInStore.category) {
      const blockedKeys = [
        "locationText",
        "Filter_responce",
        "filter_name",
        "Neighborhood",
      ];
      const searchSchema = { ...FilterDataInStore };
      blockedKeys.forEach((key) => delete searchSchema[key]);
      handleSearch(searchSchema, !searchSchema.area ? `${book.dashboard}/${book.dashboardQuickView}` : '');
    }
  };

  let ListofProperties = async () => {
    const blockedKeys = [
      "locationText",
      "Filter_responce",
      "filter_name",
      "Neighborhood",
    ];
    const searchSchema = { ...FilterDataInStore };
    blockedKeys.forEach((key) => delete searchSchema[key]);

    if (!FilterDataInStore.property_listing || !FilterDataInStore.category) {
      await getTypeOfProperties((success) => {
        const index = typeofAccount - 1 || 0;
        fetchDefaultPropertyData(success[index].id)
          .then(() => setLoading(false))
          .catch(() => setLoading(false));
      });
    } else {
      handleSearch(FilterDataInStore);
      CheckFilterValue(FilterDataInStore.property_listing);
    }
  };

  const fetchDefaultPropertyData = async (property_type) => {
    let tempObj = { ...FilterDataInStore };
    CheckFilterValue(property_type);
    tempObj["property_listing"] = property_type;
    dispatch(
      updateFilterData({ property_listing: property_type })
    );
    await new Promise((resolve, reject) => {
      getCategories(
        property_type,
        (success) => {
          tempObj["category"] = success[0]?.id;
          dispatch(updateFilterData({ category: success[0]?.id }));
          resolve(success);
        },
        reject
      );
    });

    await new Promise((resolve, reject) => {
      getTypesBasedOnCategory(
        tempObj["category"],
        (success) => {
          tempObj["type"] = success[0]?.id;
          dispatch(updateFilterData({ type: success[0]?.id }));
          resolve(success);
        },
        reject
      );
    });

    if (tempObj.property_listing && tempObj.category && tempObj.type) {
      const blockedKeys = [
        "locationText",
        "Filter_responce",
        "filter_name",
        "Neighborhood",
      ];
      const searchSchema = { ...tempObj };
      blockedKeys.forEach((key) => delete searchSchema[key]);
      handleSearch(searchSchema);
    }
  };

  useEffect(() => {
    setLoading(true);
    if (typeofAccount && !FilterDataInStore.Filter_responce.length) {
      ListofProperties();
    }
    if (FilterDataInStore.Filter_responce.length) {
      propertDetails(FilterDataInStore.Filter_responce[0]?.slug);
      CheckFilterValue(FilterDataInStore?.property_listing);
      setAllProperties(FilterDataInStore.Filter_responce);
    }
  }, [showFilter, typeofAccount]);

  const CheckFilterValue = (id) => {
    getCategories(id, (success) => {
      setbuyRentFilter(success);
      setLoading(false)
    });
  };

  let propertDetails = async (id) => {
    setLoading(true);
    dispatch(
      updatePropertyDatail({ propertyId: id, setshowPropertyPopup: true })
    );
    await getAllpropertyInfo(id, (success) => {
      setPropertyDetailInfo(success);
      setLoading(false);
    });
  };

  const handleChangeType = (event) => {
    setLoading(true);
    const newCategory = +event.target.value;
    dispatch(updateFilterData({ category: newCategory }));
    getTypesBasedOnCategory(
      newCategory,
      (success) => {
        dispatch(updateFilterData({ type: success[0]?.id }));
        handleSearch({ ...FilterDataInStore, category: newCategory, type: success[0]?.id });
      },
    );
  };

  const handleSearch = (searchSchema, url) => {
    new Promise((resolve, reject) => {
      dispatch(
        search2Actions.applySearch({
          values: searchSchema,
          resolve,
          reject,
        }),
      );
    })
      .then((data) => {
        propertDetails(data.results?.[0]?.slug ?? 0);
        dispatch(updateFilterData(searchSchema));
        setAllProperties(data);
        dispatch(
          updateFilterData({
            Filter_responce: data.results,
          })
        );
        CheckFilterValue(searchSchema?.property_listing);
        setLoading(false);
        if (url) {
          navigate(url);
        }
      })
      .catch(() => {
        setLoading(false);
        toast.error("No Property Available for this search");
      });
  };

  const cityId = useSelector(GetCityId);

  const TeammerberProfile = (id) => {
    getNblSpecial(id, (success) => {
      dispatch(updateFilterData({ Neighborhood: success.neighbourhood }));
    });
  };

  useEffect(() => {
    if (!!propertyDetailInfo?.propertDetails.property_city.id || cityId) {
      TeammerberProfile(propertyDetailInfo?.propertDetails.property_city.id || cityId);
    }
  }, [propertyDetailInfo?.propertDetails.property_city.id]);

  const handleChangeTown = (id) => {
    const selectedTown = optionsList.find((item) => id === item.value.area_id);

    selectedTown && locationOnChange(selectedTown, true);
  };

  return (
    <>
      {loading ? <Loader /> : null}
      <div className="main-sec d-flex flex-column flex-grow-1">
        <div className="filter-search">
          {/* <p>Filter-search</p> */}
          <div className="filter-sec  d-flex " style={{ gap: "16px" }}>
            {/* CheckBox Buy Sell */}
            <div className="buy-rent gap-3 d-flex align-items-center">
              {buyRentFilter?.map((type, i) => (
                <div className="d-flex" key={`filetr${i}`}>
                  <input
                    type="radio"
                    name="PropType"
                    className="Prop pointer"
                    id={type.user_listing_type}
                    value={type.id}
                    onChange={handleChangeType}
                    checked={FilterDataInStore.category === type.id}
                  />
                  <label htmlFor={type.user_listing_type} className="pointer">
                    {type.user_listing_type}
                  </label>
                </div>
              ))}
            </div>

            {/* Search box */}
            <div className="search-box d-flex flex-grow-1">
              <div className="search d-flex align-items-center justify-content-between flex-grow-1">
                <Autocomplete
                  className="hide-downBtn"
                  disablePortal
                  id="combo-box-demo"
                  options={optionsList}
                  value={value}
                  onChange={(e, value) => {
                    locationOnChange(value);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option?.id === value?.id
                  }
                  style={{ minWidth: "250px", width: "100%", zIndex: "5" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      onChange={(e) => {
                        setSearchQuery(e.target.value);
                      }}
                      // label="selected"
                      variant="standard"
                    />
                  )}
                />
                <div
                  className="search-icon pointer"
                  onClick={() => SearchByLoaction()}
                >
                  <img src={search} alt="" />
                </div>
              </div>
              <div
                className="filter-icon pointer d-flex align-items-center justify-content-center"
                onClick={() => setShowFilter(true)}
              >
                {" "}
                <img src={filter} alt="filterIcon" />
              </div>
            </div>
            {/* quik-view map-view */}
          </div>
        </div>

        <div className="main-second-sec d-flex gap-3" style={{ maxHeight: 'initial', height: 'calc(100% - 11rem)' }}>
          <div
            className={`propertys-card ${
              FilterDataInStore.Filter_responce.length ? "d-flex" : "d-none"
            }`} style={{maxWidth: '221px'}}>
            {propertyDetailInfo?.propertDetails && (
              <Typography fontWeight={400} fontSize={'16px'} lineHeight={'20px'} color={'rgba(49, 94, 251, 1)'} textTransform={'uppercase'}>
                {`${propertyDetailInfo.propertDetails.property_area.area_name} ${propertyDetailInfo.propertDetails.property_city.city_name} ${propertyDetailInfo.propertDetails.property_state.state_name}`}
              </Typography>
            )}
            <div className="card-sec hide-scrollbar ">
              {FilterDataInStore &&
                FilterDataInStore.Filter_responce?.map((data, i) => (
                  <PropertyCard
                    key={`property${i}`}
                    agentListingData={data}
                    handelOpenPopUp={propertDetails}
                    selectedProperty={propertyDetailInfo?.propertDetails.id === data.id}
                  />
                ))}
            </div>
          </div>

          <div className="property-detail-sec flex-grow-1 position-relative hide-scrollbar">
            {propertyDetailInfo ? (
              <PropertyInfoComponent
                propertyDetailInfo={propertyDetailInfo}
                setPropertyDetailInfo={setPropertyDetailInfo}
                handleChangeTown={handleChangeTown}
              />
            ) : (
              <Typography>No listings found</Typography>
            )}
          </div>

          <div className="nbls-sec">
            <p className="text-center">Neighborhood Specialist</p>
            <div className="nbls-card">
              {FilterDataInStore?.Neighborhood?.map((memberinfo, i) => (
                <AdCardGuest key={`neiber${i}`} Memberinfo={memberinfo} />
              ))}
            </div>
          </div>
        </div>
      </div>

      {showFilter && <PopUpFilter setShowFilter={setShowFilter} />}
      {saveSearchPopupVisible && <SaveSearchModel />}

      {/* property save pop up  */}
      {propertyDetailInStore.setSavePropertyPopup && <PropertySavePopUp />}
    </>
  );
};

export default PropertyDetails;
