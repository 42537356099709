import * as effects from 'redux-saga/effects';

import {
  fetchDocuments,
  fetchDocument,
  setDefaultDocument,
  deleteSelectedDocuments,
  changeShowStatus,
  shareDocuments,
  fetchCustomers,
  loadMoreDocuments,
} from './workers';
import { documentActions } from '@bus/document/actions';

function* watchFetchDocuments() {
  yield effects.takeEvery(documentActions.fetchDocuments.type, fetchDocuments);
}

function* watchFetchDocument() {
  yield effects.takeEvery(documentActions.fetchDocument.type, fetchDocument);
}

function* watchSetDefaultDocument() {
  yield effects.takeEvery(documentActions.setDefaultDocument.type, setDefaultDocument);
}

function* watchDeleteSelectedDocuments() {
  yield effects.takeEvery(documentActions.deleteSelectedDocuments.type, deleteSelectedDocuments);
}

function* watchChangeShowStatus() {
  yield effects.takeEvery(documentActions.changeShowStatus.type, changeShowStatus);
}

function* watchShareDocuments() {
  yield effects.takeEvery(documentActions.shareDocuments.type, shareDocuments);
}

function* watchFetchCustomers() {
  yield effects.takeEvery(documentActions.fetchCustomers.type, fetchCustomers);
}

function* watchLoadMoreDocuments() {
  yield effects.takeEvery(documentActions.loadMoreDocuments.type, loadMoreDocuments);
}
export function* watchDocument() {
  yield effects.all([
    effects.call(watchFetchDocuments),
    effects.call(watchFetchDocument),
    effects.call(watchSetDefaultDocument),
    effects.call(watchDeleteSelectedDocuments),
    effects.call(watchChangeShowStatus),
    effects.call(watchShareDocuments),
    effects.call(watchFetchCustomers),
    effects.call(watchLoadMoreDocuments),
  ]);
}
