import React, { lazy } from 'react';

import { AppLayout } from '@layouts/index';
import { book } from '@routes/book';

import { AppRoute, UserRole } from '@store/typedefs';

const Terms = lazy(() => import('@components/pages/term&policy/Terms'));
const Agentprofile = lazy(() => import('@components/pages/agentbio/Agentprofile'));
const CheckOutPop = lazy(() => import('@components/pages/advertisement-flow/component/CheckOut_Page/checkOut_Pop'));
const Homepage = lazy(() => import('@components/pages/homepage/Homepage'));
const Policy = lazy(() => import('@components/pages/term&policy/Policy'));
const Dashboard = lazy(() => import('@components/pages/dashboard/Dashboard'));
const QuikView = lazy(() => import('@components/pages/dashboard/quickView/QuikView'));
const PropertyDetails = lazy(() => import('@components/pages/PropertyDetails/PropertyDetails'));
const PropertyDetailsPopUp = lazy(() => import('@components/pages/dashboard/propperty-datails/PropertyDetailsPopUp'));

export const commonRoutes: AppRoute<any>[] = [
  {
    path: book.home,
    Component: <Homepage />,
    Layout: AppLayout,
    layoutProps: {},
    allowedRoles: [UserRole.customer, UserRole.agentAndBroker, UserRole.developersPropertyInvestors, UserRole.FRBHosFSBHOs, UserRole.landlordPropertyManagers, UserRole.advertisers],
  },
  {
    path: book.termsAndConditions,
    Component: <Terms />,
    Layout: AppLayout,
    layoutProps: {},
    allowedRoles: [UserRole.customer, UserRole.agentAndBroker, UserRole.developersPropertyInvestors, UserRole.FRBHosFSBHOs, UserRole.landlordPropertyManagers, UserRole.advertisers],
  },
  {
    path: book.privacyPolicy,
    Component: <Policy />,
    Layout: AppLayout,
    layoutProps: {},
    allowedRoles: [UserRole.customer, UserRole.agentAndBroker, UserRole.developersPropertyInvestors, UserRole.FRBHosFSBHOs, UserRole.landlordPropertyManagers, UserRole.advertisers],
  },
  {
    path: book.checkOutPop,
    Component: <CheckOutPop />,
    Layout: AppLayout,
    layoutProps: {},
    allowedRoles: [UserRole.customer, UserRole.agentAndBroker, UserRole.developersPropertyInvestors, UserRole.FRBHosFSBHOs, UserRole.landlordPropertyManagers, UserRole.advertisers],
  },
  {
    path: `${book.agentsId}/:id`,
    Component: <Agentprofile />,
    Layout: AppLayout,
    layoutProps: {},
    allowedRoles: [UserRole.customer, UserRole.agentAndBroker, UserRole.developersPropertyInvestors, UserRole.FRBHosFSBHOs, UserRole.landlordPropertyManagers, UserRole.advertisers],
  },
  {
    path: `${book.dashboard}/*`,
    Component: <Dashboard />,
    Layout: Dashboard,
    layoutProps: {},
    children: [
      {
        path: `${book.dashboardQuickView}`,
        Component: <QuikView />,
        layoutProps: {},
      },
      {
        path: `${book.dashboardPropertyDetails}`,
        Component: <PropertyDetails />,
        layoutProps: {},
      },
      {
        path: `${book.dashboardPropertyDetailsPopUp}`,
        Component: <PropertyDetailsPopUp />,
        layoutProps: {},
      },
    ],
    allowedRoles: [UserRole.customer],
  },
];