import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import "./petModal.scss"
import { getPet } from './server';

const PetModal = (props) => {
    const { setPetModalValue, petModalValue, setPetModalResponce } = props
    const [petlist, setPetlist] = useState()
    const [checkedItems, setCheckedItems] = useState([]);
    function getPetData() { getPet((success) => { setPetlist(success); }); }

    const handleChange = (e) => {
        const { name, checked, id } = e.target;
        if (name === "allSelect") {
            let tempUser = petlist.map((user) => {
                return { ...user, isChecked: checked };
            });
            setPetlist(tempUser);

            if (checked) {
                let setallCheck = []
                for (let pet of petlist) {
                    setallCheck = [...setallCheck, `${pet.id}`];
                }
                setCheckedItems(setallCheck)
            } else {
                setCheckedItems([])
            }
        } else {
            let tempUser = petlist.map((user) =>
                user.Pet_Name === name ? { ...user, isChecked: checked } : user
            );
            setPetlist(tempUser);
        }

        if (name !== "allSelect") {
            let petid = id.charAt(id.length - 1)
            let setcheckItems = []
            if (checked) {
                setcheckItems = ([...checkedItems, petid]);
                // setcheckItems = ([...checkedItems, name]);
            } else {
                setcheckItems = (checkedItems.filter((v) => v !== petid));
                // setcheckItems = (checkedItems.filter((v) => v !== name));
            }
            setCheckedItems(setcheckItems)
            // console.log("object", Boolean(setcheckItems.length))
        }

    };

    let submitPet = () => {
        if (Boolean(checkedItems.length)) {
            setPetModalResponce(checkedItems)
            setPetModalValue(false)
        }
    }
    useEffect(() => { getPetData(); }, []);
    return (
        <>
            <Modal show={petModalValue} size="lg" centered
                onHide={() => setPetModalValue(false)}
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton >
                    <Modal.Title id="contained-modal-title-vcenter"><p>Pet Friendly</p></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className=' d-flex align-items-center gap-2 mb-2'>
                        <input type="checkbox" name="allSelect" id="pet"
                            onChange={handleChange}
                            checked={!petlist?.some((pet) => pet?.isChecked !== true)}
                        />
                        <label htmlFor="pet">Select All</label>
                    </div>

                    {/* pet category container */}
                    <div className="d-flex justify-content-center justify-content-md-between gap-3 flex-wrap">

                        {petlist?.map((pet, i) =>
                            <div className='position-relative singleCat ' key={i} >
                                <input type="checkbox" name={pet.Pet_Name} id={`pets${pet.id}`} className='categories'
                                    checked={pet?.isChecked || false}
                                    onChange={handleChange}
                                />
                                <label htmlFor={`pets${pet.id}`} className='category-label'>
                                    <div className='mx-auto text'>
                                        <div className='position-relative d-flex justify-content-center align-items-center  singleBOx  '>
                                            <div className="petImg">
                                                <img src={`${pet.Pet_Image}`} alt="" srcSet="" />
                                            </div>
                                        </div>
                                        <p className='text-center text-uppercase'>{pet.Pet_Name}</p>
                                    </div>
                                </label>
                            </div>
                        )}
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <button onClick={submitPet} >Save</button>
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default PetModal
