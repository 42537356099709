import * as effects from 'redux-saga/effects';

import { fetchNeighborhoodTowns, fetchCityAreas, deleteAreas } from './workers';
import { neighborhoodActions } from '@bus/neighborhood/actions';

function* watchFetchNeighborhoodTowns() {
  yield effects.takeEvery(neighborhoodActions.fetchNeighborhoodTowns.type, fetchNeighborhoodTowns);
}

function* watchFetchCityAreas() {
  yield effects.takeEvery(neighborhoodActions.fetchCityAreas.type, fetchCityAreas);
}

function* watchDeleteAreas() {
  yield effects.takeEvery(neighborhoodActions.deleteAreas.type, deleteAreas);
}

export function* watchNeighborhood() {
  yield effects.all([
    effects.call(watchFetchNeighborhoodTowns),
    effects.call(watchFetchCityAreas),
    effects.call(watchDeleteAreas),
  ]);
}
