// import link from "./images/link.svg"
// import share from "./images/share.svg"
// import listingimg from "./images/listing-img.png"
// import { handleCopySlug } from '../currentListing/CurrentListing';
import React, { useState, useEffect } from "react";
import "./expiredListing.scss";
import Table from "react-bootstrap/Table";
import EditIcon from "@mui/icons-material/Edit";
import {
  closeExpireListingProperty,
  deleteExpireListingProperty,
  expireListingProperty,
} from "./server";
import { Hosturl, getToken } from "../../../appVariable/variable";
import Loader from "../Loader/Loader";
import { getuserSubscription } from "../agent-dash-sales/server";
import { Link } from "react-router-dom";
import { currentListingPropertyExpire } from "../currentListing/server";
import { SpeedDial, SpeedDialAction, Tooltip } from "@mui/material";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import IosShareIcon from "@mui/icons-material/IosShare";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import moment from "moment";
import CopyToClipboard from "react-copy-to-clipboard";
import Switch from "@mui/material/Switch";
import { alpha, styled } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import { updateTotalListing } from "../dashboard/DashboardSlice";
import { totalListingcount } from "./server";
import { useEndlessPagination } from "../../../hooks/useEndlessPagination";
import { getTypeOfProperties } from '@components/pages/homepage/components/hero/server';

const PinkSwitch = styled(Switch)(({ theme }) => ({
  ".MuiSwitch-track": {
    backgroundColor: "#315EFB",
    opacity: "1",
    height: "120%",
    transform: "translate(0px, -1px)",
  },
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#315EFB",
    "&:hover": {
      backgroundColor: alpha("#315EFB", theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#315EFB",
    height: "120%",
    transform: "translate(0px, -1px)",
  },
  "& .MuiSwitch-thumb": {
    color: "white",
    border: "1px solid rgba(88, 86, 86, 1)",
  },
}));
const label = { inputProps: { "aria-label": "Switch demo" } };
const ExpiredListing = () => {
  let currentDate = new Date();
  const dispatch = useDispatch();
  const [copiedIndex, setCopiedIndex] = useState(null);
  const [expireList, setExpireList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userType, setUserType] = useState(null);
  const [viewType, setViewType] = useState(false);
  const [commercialList, setCommercialList] = useState([]);
  const [residentialList, setResidentialList] = useState([]);
  const [commselectedId, setCommselectedId] = useState([]);
  const [ressselectedId, setRessselectedId] = useState([]);
  const [selectedId, setSelectedId] = useState([]);
  const [currentList, setCurrentList] = useState([]);
  const [currentType, setCurrentType] = useState(0);
  const [types, setTypes] = useState(null);
  const token = getToken();
  const agentSlug = token?.profile.slug;
  const paramObj = agentSlug;
  const currentURL = window.location.href;
  const url = new URL(currentURL);
  const path = url.pathname;
  const segments = path.split("/"); // Split the path by slashes
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };
  let currentUser = "";
  if (segments.length > 1) {
    currentUser = segments[1];
  }
  const { nextRef, previousRef, handleScroll } = useEndlessPagination(
    () => {
      if (nextRef.current) {
        setLoading(true);
        getexpireListing(nextRef.current);
      }
    }
  );

  useEffect(() => {
    setLoading(true);
    !!currentType && getexpireListing();
    getuserSubscription((success) => {
      setUserType(success[1].Subscription === 2);
      setLoading(false);
    });
  }, [currentType]);

  const getexpireListing = (url = undefined, query = undefined) => {
    expireListingProperty({ currentType, query, url }, (success) => {
      setExpireList(success);
      let respData = success;
      let ResProperty = [];
      let CommProperty = [];
      nextRef.current = success.next;
      previousRef.current = success.previous;
      respData?.results.forEach((resp) => {
        if (resp?.property_listing_type?.id === 1) {
          ResProperty.push(resp);
        }
        if (resp?.property_listing_type?.id === 2) {
          CommProperty.push(resp);
        }
      });
      setResidentialList((prev) => url ? [...prev, ...ResProperty] : ResProperty);
      setCommercialList((prev) => url ? [...prev, ...CommProperty] : CommProperty);
      setLoading(false);
      setRessselectedId([]);
      setCommselectedId([]);
    });
  };

  const handleChecked = (e, index) => {
    const { name, id, checked } = e.target;
    if (name === "selectAll") {
      if (checked) {
        let setallCheck = [];
        for (let list of expireList) {
          setallCheck = [...setallCheck, list.id];
        }

        setSelectedId(setallCheck);
      } else {
        setSelectedId([]);
      }
    } else {
      let setcheckItems = [];
      if (checked) {
        setcheckItems = [...selectedId, parseInt(id)];
      } else {
        setcheckItems = selectedId.filter((v) => v !== parseInt(id));
      }
      setSelectedId(setcheckItems);
    }

    if (name === "ResselectAll") {
      if (checked) {
        let setallCheck = [];
        for (let list of residentialList) {
          setallCheck = [...setallCheck, list.id];
        }
        setRessselectedId(setallCheck);
      } else {
        setRessselectedId([]);
      }
    }

    if (name === "Resonecheck") {
      let setcheckItems = [];
      if (checked) {
        setcheckItems = [...ressselectedId, parseInt(id)];
      } else {
        setcheckItems = ressselectedId.filter((v) => v !== parseInt(id));
      }
      setRessselectedId(setcheckItems);
    }

    if (name === "CommselectAll") {
      if (checked) {
        let setallCheck = [];
        for (let list of commercialList) {
          setallCheck = [...setallCheck, list.id];
        }
        setCommselectedId(setallCheck);
      } else {
        setCommselectedId([]);
      }
    }

    if (name === "Commonecheck") {
      let setcheckItems = [];
      if (checked) {
        setcheckItems = [...commselectedId, parseInt(id)];
      } else {
        setcheckItems = commselectedId.filter((v) => v !== parseInt(id));
      }
      setCommselectedId(setcheckItems);
    }
  };

  const ReactiveProperty = async () => {
    let userDetails = getToken();
    const data = {
      userid: userDetails.userId,
      current_date: moment(currentDate).format("YYYY-MM-DD"),
    };
    // if (userType) {
    if (viewType) {
      // passing ress selcetd id
      data["propertyid"] = ressselectedId;
    } else {
      // passing commertial selcetd id
      data["propertyid"] = commselectedId;
    }
    // } else {
    //   if (commercialList.length > 0) {
    //     // passing commertial selcetd id
    //     data["propertyid"] = commselectedId;
    //   }
    //   if (residentialList.length > 0) {
    //     // passing ress selcetd id
    //     data["propertyid"] = ressselectedId;
    //   }
    // }
    console.log(data["propertyid"]);
    if (data.propertyid.length > 0) {
      setLoading(true);
      await currentListingPropertyExpire(data, (success) => {
        // setChecked([])
        setLoading(false);
      });
      getexpireListing();
    }
  };
  const handleSearch = (e) => {
    const initialQuery = e.target.value.toLowerCase();
    getexpireListing(undefined, initialQuery);
  };
  const closeExpireClick = async () => {
    let userDetails = getToken();
    const data = { userid: userDetails.userId };
    // if (userType) {
    if (viewType) {
      // passing ress selcetd id
      data["propertyid"] = ressselectedId;
    } else {
      // passing commertial selcetd id
      data["propertyid"] = commselectedId;
    }
    // } else {
    //   if (commercialList.length > 0) {
    //     // passing commertial selcetd id
    //     data["propertyid"] = commselectedId;
    //   }
    //   if (residentialList.length > 0) {
    //     // passing ress selcetd id
    //     data["propertyid"] = ressselectedId;
    //   }
    // }
    if (data.propertyid.length > 0) {
      setLoading(true);
      await closeExpireListingProperty(data, (success) => {
        // setChecked([])
        totalListingcount(paramObj, (success) => {
          dispatch(updateTotalListing(success.data.data));
        });
        setLoading(false);
      });
      getexpireListing();
    }
  };

  const deleteExpireClick = async () => {
    let userDetails = getToken();
    const data = { userid: userDetails.userId };
    // if (userType) {
    if (viewType) {
      // passing ress selcetd id
      data["propertyid"] = ressselectedId;
    } else {
      // passing commertial selcetd id
      data["propertyid"] = commselectedId;
    }
    // } else {
    //   if (commercialList.length > 0) {
    //     // passing commertial selcetd id
    //     data["propertyid"] = commselectedId;
    //   }
    //   if (residentialList.length > 0) {
    //     // passing ress selcetd id
    //     data["propertyid"] = ressselectedId;
    //   }
    // }
    if (data.propertyid.length > 0) {
      setLoading(true);
      await deleteExpireListingProperty(data, (success) => {
        totalListingcount(paramObj, (success) => {
          dispatch(updateTotalListing(success.data.data));
        });
      });
      getexpireListing();
    }
  };

  useEffect(() => {
    getTypeOfProperties((success) => {
      setTypes(success);
      setCurrentType(viewType ? success[0].id : success[1].id);
    }).then().catch();
  }, []);

  useEffect(() => {
    if (types) {
      setCurrentType(viewType ? types[0].id : types[1].id);
    }
  }, [viewType]);

  return (
    <>
      {loading ? <Loader /> : null}
      <div className="expireD_listing_page">
        <div className="curren_text pb-4">Expired Listing</div>
        <div className="d-flex justify-content-between">
          <div className="center">
            <button
              className="blue__btn center longer_btN"
              onClick={() => {
                ReactiveProperty();
              }}
            >
              <div className="">Reactivate</div>
            </button>
            <button
              className="blue__btn center longer_btN"
              onClick={() => {
                closeExpireClick();
              }}
            >
              <div className="">Close</div>
            </button>
            <button
              className="red__btn center remove_btn me-4"
              onClick={() => {
                deleteExpireClick();
              }}
            >
              <div className=""> Delete</div>
            </button>
          </div>

          <div className=" d-flex align-items-center gap-3">
            {/* {userType && ( */}
            <div className="quik-map center gap-2">
              <p>Commercial Listing</p>
              <div className="pt-0"></div>
              <PinkSwitch
                {...label}
                onChange={(e) => {
                  setViewType(e.target.checked);
                }}
                checked={viewType}
                style={{ color: "#315EFB" }}
              />
              <p>Residential Listing</p>
            </div>
            {/* )} */}
            <div className="listing-filter">
              <input
                type="text"
                name=""
                id=""
                placeholder="ex: 5000"
                onChange={(e) => handleSearch(e)}
              />
            </div>
          </div>
        </div>

        <div
          className="scrollS"
          style={{
            overflowY: "scroll",
            overflowX: "hidden",
            maxHeight: "70vh",
            padding: "10px",
          }}
        >
          {/* {userType ? ( */}
          {viewType ? (
            <Table
              className="table-borderless"
              style={{ marginLeft: "3px", marginRight: "8px" }}
            >
              <thead>
                <tr>
                  <th className=" checkTh">
                    <input
                      type="checkbox"
                      name="ResselectAll"
                      value=""
                      id="all"
                      onChange={(e) => handleChecked(e, 1)}
                      checked={
                        ressselectedId.length
                          ? ressselectedId.length === residentialList.length
                          : false
                      }
                    />
                  </th>
                  <th>Address</th>
                  <th>Town </th>
                  <th>BedRoom </th>
                  <th>Bathroom </th>
                  <th>Price</th>
                  <th>Category</th>
                  <th>Contract</th>
                  <th>List Date</th>
                  <th>Expire Date</th>
                  <th>Image</th>
                  <th>Share</th>
                  <th>Edit</th>
                </tr>
              </thead>

              <tbody
                className="hide-scrollbar pt-1"
                onScroll={handleScroll}
              >
                {residentialList.length > 0
                  ? residentialList
                      .map((data, i) => {
                        return (
                          <tr className="align-middle table_row_border" key={i}>
                            <td className=" checkTh">
                              <input
                                type="checkbox"
                                name="Resonecheck"
                                value=""
                                className="mt-2"
                                id={data.id}
                                onChange={(e) => handleChecked(e, data.id)}
                                checked={ressselectedId.includes(data.id)}
                              />
                            </td>
                            <td className="address_width">
                              {data.property_address_1}
                            </td>
                            <td className="table_body_text">
                              {data.property_city.city_name}
                            </td>
                            <td className="table_body_text">{data.Bedrooms}</td>
                            <td className="table_body_text">
                              {data.Bathrooms}
                            </td>
                            <td className="table_body_text">
                              {data.property_listing_amount.toLocaleString(
                                "en-US"
                              )}
                            </td>
                            <td className="table_body_text">
                              {data.propertylisting_type.user_listing_type}
                            </td>
                            <td className="table_body_text">
                              {data.is_property_exclusive
                                ? "Exclusive"
                                : "Open"}
                            </td>
                            {/* <td className='table_body_text'>{data.created_date}</td> */}
                            <td className="table_body_text">
                              {data.start_date
                                ? new Date(
                                    data.start_date
                                      .toLocaleString()
                                      ?.toString()
                                  ).toLocaleDateString("en-US", options)
                                : "-"}
                            </td>
                            <td className="table_body_text">
                              {data.end_date
                                ? new Date(
                                    data.end_date
                                      .toLocaleString()
                                      ?.toString()
                                  ).toLocaleDateString("en-US", options)
                                : "-"}
                            </td>
                            <td>
                              <img
                                src={`${data.property_main_image}`}
                                alt=""
                              />
                            </td>
                            <td className=" d-flex align-items-center gap-3">
                              <div className="copy-link pointer">
                                <CopyToClipboard
                                  text={`${Hosturl}property/${data.slug}`}
                                  onCopy={() => {
                                    setCopiedIndex(i);
                                  }}
                                >
                                  <Tooltip
                                    title={
                                      copiedIndex === i ? "Copied" : "Copy"
                                    }
                                    placement="top"
                                  >
                                    <InsertLinkIcon />
                                  </Tooltip>
                                </CopyToClipboard>
                              </div>

                              <SpeedDial
                                className="share-proprty-btn"
                                direction="right"
                                ariaLabel="SpeedDial basic example"
                                icon={<IosShareIcon />}
                              >
                                <SpeedDialAction
                                  icon={
                                    <LinkedinShareButton
                                      url={`${Hosturl}property/${data.slug}`}
                                      title="Afisado"
                                      summary=""
                                      source="Afisado.com"
                                    >
                                      <LinkedinIcon size={30} round={true} />
                                    </LinkedinShareButton>
                                  }
                                  tooltipTitle={`Linkedin`}
                                />
                                <SpeedDialAction
                                  icon={
                                    <WhatsappShareButton
                                      url={`${Hosturl}property/${data.slug}`}
                                      title="Afisado"
                                      summary=""
                                      source="Afisado.com"
                                    >
                                      {" "}
                                      <WhatsappIcon size={30} round={true} />
                                    </WhatsappShareButton>
                                  }
                                  tooltipTitle={`Whatsapp`}
                                />
                                <SpeedDialAction
                                  icon={
                                    <TwitterShareButton
                                      url={`${Hosturl}property/${data.slug}`}
                                      title="Afisado"
                                      summary=""
                                      source="Afisado.com"
                                    >
                                      {" "}
                                      <TwitterIcon size={30} round={true} />
                                    </TwitterShareButton>
                                  }
                                  tooltipTitle={`Twitter`}
                                />
                                <SpeedDialAction
                                  icon={
                                    <FacebookShareButton
                                      url={`${Hosturl}property/${data.slug}`}
                                      title="Afisado"
                                      summary=""
                                      source="Afisado.com"
                                    >
                                      {" "}
                                      <FacebookIcon size={30} round={true} />
                                    </FacebookShareButton>
                                  }
                                  tooltipTitle={`Facebook`}
                                />
                              </SpeedDial>
                            </td>
                            <td>
                              <Link
                                to={`/${currentUser}/edit-property/${data.slug}`}
                              >
                                <EditIcon className="edit-listing" />
                              </Link>
                            </td>
                          </tr>
                        );
                      })
                  : null}
              </tbody>
            </Table>
          ) : (
            <Table className="table-borderless ">
              <thead>
                <tr>
                  <th className=" checkTh">
                    <input
                      type="checkbox"
                      name="CommselectAll"
                      value=""
                      id="all"
                      onChange={(e) => handleChecked(e, 1)}
                      checked={
                        commselectedId.length
                          ? commselectedId.length === commercialList.length
                          : false
                      }
                    />
                  </th>
                  <th>Address</th>
                  <th>Town</th>
                  <th>Type</th>
                  <th>Sub-Type</th>
                  <th>Price</th>
                  <th>Category</th>
                  <th>Contract</th>
                  <th>List Date</th>
                  <th>Expire Date</th>
                  <th>Image</th>
                  <th>Share</th>
                  <th>Edit</th>
                </tr>
              </thead>

              <tbody className="hide-scrollbar pt-1">
                {commercialList.length > 0
                  ? commercialList
                      .map((data, i) => {
                        return (
                          <tr className="align-middle table_row_border" key={i}>
                            <td className=" checkTh">
                              <input
                                type="checkbox"
                                name="Commonecheck"
                                value=""
                                className="mt-2"
                                id={data.id}
                                onChange={(e) => handleChecked(e, data.id)}
                                checked={commselectedId.includes(data.id)}
                              />
                            </td>
                            <td className="address_width">
                              {data.property_address_1}
                            </td>
                            <td className="table_body_text">
                              {data.property_city.city_name}
                            </td>
                            <td className="table_body_text">
                              {data.property_main_category.Main_category}
                            </td>
                            <td className="table_body_text"></td>
                            <td className="table_body_text">
                              {data.property_listing_amount.toLocaleString(
                                "en-US"
                              )}
                            </td>
                            {/* <td className="table_body_text">{data.Units}</td>
                              <td className="table_body_text">{data.Rooms}</td> */}
                            <td className="table_body_text">
                              {data.propertylisting_type.user_listing_type}
                            </td>
                            <td className="table_body_text">
                              {data.is_property_exclusive
                                ? "Exclusive"
                                : "Open"}
                            </td>

                            <td className="table_body_text">
                              {data.start_date
                                ? new Date(
                                    data.start_date
                                      .toLocaleString()
                                      ?.toString()
                                  ).toLocaleDateString("en-US", options)
                                : "-"}
                            </td>
                            <td className="table_body_text">
                              {data.end_date
                                ? new Date(
                                    data.end_date
                                      .toLocaleString()
                                      ?.toString()
                                  ).toLocaleDateString("en-US", options)
                                : "-"}
                            </td>
                            <td>
                              <img
                                src={`${data.property_main_image}`}
                                alt=""
                              />
                            </td>
                            <td className=" d-flex align-items-center gap-3">
                              <div className="copy-link pointer">
                                <CopyToClipboard
                                  text={`${Hosturl}property/${data.slug}`}
                                  onCopy={() => {
                                    setCopiedIndex(i);
                                  }}
                                >
                                  <Tooltip
                                    title={
                                      copiedIndex === i ? "Copied" : "Copy"
                                    }
                                    placement="top"
                                  >
                                    <InsertLinkIcon />
                                  </Tooltip>
                                </CopyToClipboard>
                              </div>

                              <SpeedDial
                                className="share-proprty-btn"
                                direction="right"
                                ariaLabel="SpeedDial basic example"
                                icon={<IosShareIcon />}
                              >
                                <SpeedDialAction
                                  icon={
                                    <LinkedinShareButton
                                      url={`${Hosturl}property/${data.slug}`}
                                      title="Afisado"
                                      summary=""
                                      source="Afisado.com"
                                    >
                                      <LinkedinIcon size={30} round={true} />
                                    </LinkedinShareButton>
                                  }
                                  tooltipTitle={`Linkedin`}
                                />
                                <SpeedDialAction
                                  icon={
                                    <WhatsappShareButton
                                      url={`${Hosturl}property/${data.slug}`}
                                      title="Afisado"
                                      summary=""
                                      source="Afisado.com"
                                    >
                                      {" "}
                                      <WhatsappIcon size={30} round={true} />
                                    </WhatsappShareButton>
                                  }
                                  tooltipTitle={`Whatsapp`}
                                />
                                <SpeedDialAction
                                  icon={
                                    <TwitterShareButton
                                      url={`${Hosturl}property/${data.slug}`}
                                      title="Afisado"
                                      summary=""
                                      source="Afisado.com"
                                    >
                                      {" "}
                                      <TwitterIcon size={30} round={true} />
                                    </TwitterShareButton>
                                  }
                                  tooltipTitle={`Twitter`}
                                />
                                <SpeedDialAction
                                  icon={
                                    <FacebookShareButton
                                      url={`${Hosturl}property/${data.slug}`}
                                      title="Afisado"
                                      summary=""
                                      source="Afisado.com"
                                    >
                                      {" "}
                                      <FacebookIcon size={30} round={true} />
                                    </FacebookShareButton>
                                  }
                                  tooltipTitle={`Facebook`}
                                />
                              </SpeedDial>
                            </td>
                            <td>
                              <Link
                                to={`/${currentUser}/edit-property/${data.slug}`}
                              >
                                <EditIcon className="edit-listing" />
                              </Link>
                            </td>
                          </tr>
                        );
                      })
                  : null}
              </tbody>
            </Table>
          )}
        </div>
      </div>
    </>
  );
};

export default ExpiredListing;
