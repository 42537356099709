export const styles = {
  root: {
    zIndex: 10005,
    '& .MuiDialog-paper': {
      background: 'none',
      overflow: 'unset',
    },
    '& .MuiPaper-root': {
      overflowX: 'hidden',
      margin: '20px',
      maxWidth: 'initial',
      backgroundImage: 'none',
    },
  },
  imageWrapperSwap: (aspectRatio: number) => ({
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: '50%',
    left: '50%',
    aspectRatio,
    maxWidth: '90svw',
    maxHeight: '90svh',
    transform: 'translate(-50%,-50%)',
    zIndex: 10003,
  }),
  closeButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    background: 'rgba(255, 255, 255, 0.5)',
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.7)',
    },
  },
};
