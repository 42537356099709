import React, { useState, useEffect } from "react";
import "../documents.scss";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ViewerWrapper from "./pdfViewer/ViewerWrapper";
import { Worker } from "@react-pdf-viewer/core";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ShareModal from "../components/shareModal/ShareModal";
import Loader from "../../Loader/Loader";
import { changeOrderDoc } from "../server";
import { useDispatch, useSelector } from 'react-redux';
import { documentActions } from '@bus/document/actions';
import { getCustomers, getDocuments, getDocumentsNextUrl, getIsDocumentsFetching } from '@bus/document/selectors';
import Box from '@mui/material/Box';
import DocumentTab from '@components/pages/Documents/components/DocumentTab';
import { styles } from './styles';

const Documentlist = () => {
  const [selectedTab, setSelectedTab] = useState("home");
  const [selectedDocId, setSelectedDocId] = useState();
  const [docs, setDocs] = useState([]);
  const [showShareDialog, setShowShareDialog] = React.useState(false);
  const [sharedDocs, setSharedDocs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const documents = useSelector(getDocuments);
  const customers = useSelector(getCustomers);
  const isFetching = useSelector(getIsDocumentsFetching);
  const nextUrl = useSelector(getDocumentsNextUrl);

  useEffect(() => {
    setDocs(documents);

    const shared = documents
      .filter((document) => document.shared === true)
      .map((document) => document.id);
    setSharedDocs(shared);
  }, [documents]);

  useEffect(() => {
    dispatch(documentActions.fetchDocuments({
      signed: selectedTab === 'marksign',
    }));
    setSelectedDocuments([]);
  }, [selectedTab]);

  useEffect(() => {
    dispatch(documentActions.fetchCustomers());
  }, []);

  const doubleClickDoc = (event, id, docType) => {
    if (event.detail === 2) {
      setSelectedDocId(id);
      setShowModal(true);
    }
  };
  const handleClose = () => {
    setShowModal(false);
  };
  const handleOnDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    if (
      !result.destination ||
      result.destination.index === result.source.index
    ) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }
    const items = Array.from(docs);
    const [reorderedItem] = items.splice(source.index, 1);
    items.splice(destination.index, 0, reorderedItem);
    setDocs(items);
    setDocs((state) => {
      return state;
    });
    changeOrderDoc(items, (success) => {
      console.log(success);
    });
  };
  const handleUpload = (event) => {
    navigate("upload");
  };
  const handleShare = (event) => {
    setShowShareDialog(true);
  };
  const handleCheck = (event) => {
    const { name, id, checked } = event.target;

    if (name === 'selectAll') {
      if (checked) {
        setSelectedDocuments(docs.map((item) => item.id));

        return;
      }

      setSelectedDocuments([]);

      return;
    }

    if (checked) {
      setSelectedDocuments([...selectedDocuments, +id]);

      return;
    }
    setSelectedDocuments((prev) => prev.filter((prevId) => prevId !== +id));
  };
  const handleBulkRemove = () => {
    dispatch(documentActions.deleteSelectedDocuments({ ids: selectedDocuments }));
    setSelectedDocuments([]);
  };
  const handleBulkChangeDefaultStatus = () => {
    const item = docs.find((item) => item.id === selectedDocuments[0]);
    dispatch(documentActions.setDefaultDocument({
      ids: selectedDocuments,
      status: !item.is_default,
    }));
    setSelectedDocuments([]);
  };
  const handleBulkChangeShareStatus = () => {
    const item = docs.find((item) => item.id === selectedDocuments[0]);
    dispatch(documentActions.changeShowStatus({
      ids: selectedDocuments,
      show: !item.shared,
    }));
    setSelectedDocuments([]);
  };
  const handleScroll = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target;
    const bottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;

    if (bottom) {
      !isFetching &&
      nextUrl &&
      dispatch(
        documentActions.loadMoreDocuments({
          url: nextUrl,
        }),
      );
    }
  };

  return (
    <>
      <div style={{ height: '100%' }}>
        {loading === true ? <Loader /> : null}
        <div className="main_selperson_tab" style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}>
          <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} gap={'10px'} mb={'20px'}>
            <Box display={'flex'} alignItems={'center'} gap={'16px'}>
              {!!docs.length && (
                <Box display={'flex'} alignItems={'center'} gap={'5px'}>
                  <input
                    type={'checkbox'}
                    name={'selectAll'}
                    value=""
                    id={'all'}
                    onChange={handleCheck}
                    checked={selectedDocuments.length === docs.length}
                  />
                  <label htmlFor={'all'} className={'pointer'}>
                    Select All
                  </label>
                </Box>
              )}
              <Button
                className={'blue__btn'}
                variant={'outlined'}
                size={'middle'}
                disabled={!selectedDocuments.length}
                style={{
                  borderRadius: '20px',
                  minWidth: '64px',
                  padding: '10px 20px 10px 20px',
                  fontSize: '0.8rem',
                }}
                onClick={handleBulkRemove}>
                Delete
              </Button>
              <Button
                className={'blue__btn'}
                variant={'outlined'}
                size={'middle'}
                disabled={!selectedDocuments.length}
                style={{
                  borderRadius: '20px',
                  minWidth: '64px',
                  padding: '10px 20px 10px 20px',
                  fontSize: '0.8rem',
                }}
                onClick={handleBulkChangeDefaultStatus}>
                Change Default Status
              </Button>
              <Button
                className={'blue__btn'}
                variant={'outlined'}
                size={'middle'}
                disabled={!selectedDocuments.length}
                style={{
                  borderRadius: '20px',
                  minWidth: '64px',
                  padding: '10px 20px 10px 20px',
                  fontSize: '0.8rem',
                }}
                onClick={handleBulkChangeShareStatus}>
                Change Share Status
              </Button>
            </Box>
            <Box display={'flex'} alignItems={'center'} gap={'10px'}>
              <Button
                className="blue__btn"
                variant="outlined"
                size="middle"
                style={{
                  marginRight: '20px',
                  borderRadius: '20px',
                  minWidth: '64px',
                  padding: '10px 20px 10px 20px',
                  fontSize: '0.8rem',
                }}
                onClick={handleShare}
              >
                Share
              </Button>
              <Button
                className="blue__btn"
                variant="outlined"
                size={{}}
                onClick={handleUpload}
              >
                Upload
              </Button>
            </Box>
          </Box>
          <Box sx={styles.wrapper}>
            <Tabs
              defaultActiveKey="home"
              activeKey={selectedTab}
              id="justify-tab-example"
              className="mb-3 flex-row"
              style={{ justifyContent: "flex-start" }}
              onSelect={(key) => {
                setDocs([]);
                setSelectedTab(key);
              }}>
              <Tab
                eventKey="home"
                title="Documentation"
                className="scrollS"
                style={{
                  height: 'calc(100% - 9rem)',
                  padding: '0 10px 10px 0',
                  overflowY: 'auto',
                  scrollBehavior: 'initial',
                }}
                onScroll={handleScroll}>
                <div style={{ margin: "auto", position: "relative", }}>
                  <DocumentTab
                    handleOnDragEnd={handleOnDragEnd}
                    docs={docs}
                    doubleClickDoc={doubleClickDoc}
                    handleCheck={handleCheck}
                    selectedDocuments={selectedDocuments}
                    selectedTab={selectedTab}
                  />
                </div>
              </Tab>
              <Tab
                eventKey="marksign"
                title="Signed Documents"
                className="scrollS"
                style={{
                  height: 'calc(100% - 9rem)',
                  padding: '0 10px 10px 0',
                  overflowY: 'auto',
                  scrollBehavior: 'initial',
                }}
                onScroll={handleScroll}>
                <div style={{ margin: "auto" }}>
                  <DocumentTab
                    handleOnDragEnd={handleOnDragEnd}
                    docs={docs}
                    doubleClickDoc={doubleClickDoc}
                    handleCheck={handleCheck}
                    selectedDocuments={selectedDocuments}
                    selectedTab={selectedTab}
                  />
                </div>
              </Tab>
            </Tabs>
          </Box>
        </div>
      </div>
      {showShareDialog ? (
        <ShareModal
          setShowShareDialog={setShowShareDialog}
          customers={customers}
          sharedDocs={sharedDocs}
        />
      ) : null}
      {showModal ? (
        <Modal
          className=""
          show={showModal}
          center
          size="xl"
          ria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={handleClose}
        >
          <Modal.Header
            closeButton
            className="border-bottom-0 contact_modal_header"
          >
            <Modal.Title
              id="contained-modal-title-vcenter "
              className="border-0"
            >
              <div className="forgot_title">
                <p>PDF View</p>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="contact_body">
            <div style={{ margin: "auto" }}>
              <div
                style={{
                  margin: "auto",
                  overflow: "hidden",
                  height: "70vh",
                  borderRadius: "20px",
                }}
              >
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                  {(() => {
                    const fileUrl = docs[selectedDocId]?.file;
                    return <ViewerWrapper fileUrl={fileUrl} />;
                  })()}
                </Worker>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      ) : null}
    </>
  );
};

export default Documentlist;
