import landingSlice from './slice';
import { createAction } from '@reduxjs/toolkit';
import { fetchRecentListing } from './saga/workers/fetchRecentListing';
import { fetchMainCategories } from './saga/workers';
import { getEndpointParams } from '../../helpers/formating';

export const landingActions = {
  ...landingSlice.actions,
  fetchNearestCity: createAction('profile/fetchNearestCity',
    (values) => {
      return {
        payload: {
          query: getEndpointParams(
            values,
            new URLSearchParams(),
          ),
        },
      };
    }),
  fetchRecentListing: createAction('profile/fetchRecentListing',
    (values) => {
      return {
        payload: {
          query: getEndpointParams(
            values,
            new URLSearchParams(),
          ),
        },
      };
    }),
  fetchMainCategories: createAction('profile/fetchMainCategories'),
  fetchConfig: createAction('profile/fetchConfig'),
};
