import { FC } from "react";
import { ProfileAvatar } from "@components/shared/profile-avatar";
import { UserRole } from "../../../../store/typedefs";
import { IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { getToken } from "../../../../appVariable/variable";
import { useChatAttributes } from "@components/pages/Message/hooks/useChatAttributes";
import { useSelector } from "react-redux";
import { GetSelectedConversation } from "../../../../store/selectors/message.selector";

type MessageListHeaderProps = {
	onClick: () => void
}

export const ActiveChatHeader: FC<MessageListHeaderProps> = ({ onClick }) => {
	const { user_info } = getToken();
	const conversation = useSelector(GetSelectedConversation);
	const chatAttributes = useChatAttributes(conversation!);

	return (
		<div className="chat-section-header d-flex justify-content-between align-items-center">
			<div className="d-flex align-items-center gap-4">
				<div className="chat-profile">
					<ProfileAvatar
						width={50}
						height={50}
						profileInstance={chatAttributes.avatar}
					/>
				</div>
				<div className="prrson-name">
					<p>{ chatAttributes.name }</p>
				</div>
			</div>
			{
				user_info?.usertypeobj === UserRole.customer
					? (
						<div className="more-option">
							<IconButton onClick={onClick}>
								<MoreVertIcon />
							</IconButton>
						</div>
					)
					: null
			}
		</div>
	)
}