import { useEffect, useState } from "react";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import Validation from "./Validation";
import { Passcheck } from "../profile/components/password/Password";
import { toast } from "react-toastify";
import { declineRequest, getGeolocationData, getTeamName } from "./server";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";
import axiosInstance from '../../../REST/axiosInstance';
import { AuthPasswordLayout } from "../../../layouts";

const getSearchParams = (keys = '' || []) => {
  if (typeof keys === 'string') keys = [keys];
  if (!Array.isArray(keys)) throw 'Keys arg should be string or array';
  const urlString = window.location.href;
  const paramString = urlString.split("?")[1];
  const queryString = new URLSearchParams(paramString);

  return keys.reduce((result, key) => ({ ...result, [key]: queryString.get(key) }), {});
}

const TeamLogin = () => {
  const [teamName, setTeamName] = useState('');
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [password, setPassword] = useState("");
  const [extraData, setExtraData] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  let onsubmit = () => {
    let date = new Date();
    let userlog = {
      action_type: "True",
      date_time: moment(date).format("YYYY-MM-DD HH:MM:ss"),
      ip_address: extraData.IPv4,
      mac_address: "00-14-7B-EE-19-F8",
      latitude: extraData.latitude,
      longitude: extraData.longitude,
      location: `${extraData.city} ${extraData.country_name}`,
    };

    let data = {
      ...getSearchParams(['uid', 'token']),
      password: password,
    };
    let tempObj = { ...data, user_log: userlog };
    setLoading(true);
    axiosInstance
      .post(`api/property/agentregister/`, tempObj)
      .then((response) => {
        if (response.data.code === 200 && response.data.status === "success") {
          console.log(response.data.data);
          // toast.success("Property add Successfully", "success")
          let tempObj = {};
          tempObj["userId"] = response.data.data.user_info.id;
          tempObj["user_info"] = response.data.data.user_info;
          tempObj["profile"] = response.data.data.profile;
          tempObj["expiresIn"] = "96000";
          tempObj["idToken"] = response.data.data.token.access;
          tempObj["refreshToken"] = response.data.data.token.refresh;
          tempObj["accessToken"] = response.data.data.token.access;
          socialLoginSaveTokenInLocalStorage(tempObj);
          let add_on = { add_on: response.data.data.user_info.add_on };
          localStorage.setItem("add_on", JSON.stringify(add_on));

          let sidebar = { sidebar: response.data.data.user_info.agent_profile };
          localStorage.setItem("sidebar", JSON.stringify(sidebar));
          let details = response.data.data;
          // navigate("/")
          setLoading(false);

          if (details.user_info.usertypeobj === 1) {
            navigate("/");
          }
          if (
            details.user_info.usertypeobj === 2 &&
            details.user_info.agentsubscription === false
          ) {
            navigate("/subscription");
            console.log("/subscription");
          }
          if (
            details.user_info.usertypeobj === 2 &&
            details.user_info.agentsubscription === true &&
            details.user_info.billing === false
          ) {
            navigate("/subscription");
            console.log("/subscription");
          }
          if (
            details.user_info.usertypeobj === 2 &&
            details.user_info.agentsubscription === true &&
            details.user_info.billing === true &&
            details.user_info.agent_profile === false
          ) {
            navigate("/agent-dashboard/profile-settings");
            console.log("/agent-dashboard/profile-settings");
          }
          if (
            details.user_info.usertypeobj === 2 &&
            details.user_info.agentsubscription === true &&
            details.user_info.billing === true &&
            details.user_info.agent_profile === true
          ) {
            navigate("/agent-dashboard/add-property");
            console.log("/agent-dashboard/add-property");
          }
        }
        if (response.data.code === 400 && response.data.status === "failed") {
          toast.error("User already registered ");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const socialLoginSaveTokenInLocalStorage = (tokenDetails) => {
    try {
      console.log("save");
      let currentDate = new Date();
      tokenDetails.expireDate = currentDate;
      tokenDetails.expireDate = new Date(
        new Date().getTime() + tokenDetails.expiresIn * 1000
      );
      sessionStorage.setItem("userDetails", JSON.stringify(tokenDetails));
    } catch (err) {
      console.log("err", err);
    }
  };

  function getLoginExtraData() {
    getGeolocationData((success) => {
      console.log("geoData", success);
      setExtraData(success);
    });
    getTeamName(
      getSearchParams(['uid', 'token'])
    )
    .then((data) => setTeamName(data.data.team_name))
    .catch((error) => console.log(error));
  }

  useEffect(() => {
    getLoginExtraData();
  }, []);

  const handleDeclineRequest = () => {
    declineRequest(
      getSearchParams(['uid', 'token'])
    )
    .then(() => navigate("/login"))
    .catch((error) => console.log(error));
  }

  return (
    <>
      {loading ? <Loader /> : null}
      <AuthPasswordLayout
        title={`Join Our ${teamName || 'Team'}`}
        subtitle={'Register with Your Invite Code and Password!'}
        text={
          <span className="loginpage_signup_text">
            or
            <span
              className="loginpage_signup_link pointer"
              onClick={handleDeclineRequest}
            >
              {' decline the request'}
            </span>
          </span>
        }
      >
        <div
          className="loginpage_inputs"
          style={{ maxWidth: "500px", margin: "auto" }}
        >
          <div className="input-container">
            <input
              className="input-field"
              placeholder="Password"
              name="password"
              type={isRevealPwd ? "text" : "password"}
              autoComplete="off"
              onChange={(e) => setPassword(e.target.value)}
              // // onBlur={formik.handleBlur}
              value={password}
            />

            {/* view icon of password */}
            <div
              style={{ cursor: "pointer" }}
              onClick={() => setIsRevealPwd((prevState) => !prevState)}
            >
              {isRevealPwd ? (
                <VisibilityOffOutlinedIcon />
              ) : (
                <RemoveRedEyeOutlinedIcon />
              )}
            </div>
          </div>
          <Validation formikPass={password} />
        </div>
        <div className="loginpage_help_button justify-content-center mb-3 mx-auto">
          <div className="loginpage_signin">
            <button
              className="loginpage_signin_btn"
              type="submit"
              onClick={() => onsubmit()}
              disabled={!Passcheck.test(password)}
            >
              <span className="loginpage_signin_btn_text">Register</span>
            </button>
          </div>
        </div>
      </AuthPasswordLayout>
    </>
  );
};

export default TeamLogin;
