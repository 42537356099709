import React, { useEffect, useState } from 'react'
import "./NeighborhoodSpecialist.scss";
import { Tab, Tabs } from 'react-bootstrap';
import CityArea from './componants/CityArea/CityArea';
import {
  PropertyFiles
} from '@components/pages/neighbourhoodspecialist/component/NeighborhoodSpecialist/componants/PropertyFiles';
import {
  TabTitle
} from '@components/pages/neighbourhoodspecialist/component/NeighborhoodSpecialist/componants/TabTitle';
import { useDispatch, useSelector } from 'react-redux';
import { neighborhoodActions } from '@bus/neighborhood/actions';
import { getCities, getIsCitiesFetched, getIsFetched, getNeighborhoodTowns } from '@bus/neighborhood/selectors';
import Loader from '@components/pages/Loader/Loader';
const NeighborhoodSpecialist = (props) => {
  const [checkedArea, setCheckedArea] = useState([]);
  const dispatch = useDispatch();
  const isFetched = useSelector(getIsCitiesFetched);
  const cities = useSelector(getCities);
  const neighborhoodTowns = useSelector(getNeighborhoodTowns);
  const isFetching = useSelector(getIsFetched);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setCheckedArea(neighborhoodTowns);
  }, [neighborhoodTowns.length]);

  useEffect(() => {
    dispatch(neighborhoodActions.fetchCityAreas());
  }, []);

  useEffect(() => {
    if (isFetched) {
      dispatch(neighborhoodActions.fetchNeighborhoodTowns());
    }
  }, [isFetched]);

  const handleSelect = (cityId, check) => {
    dispatch(neighborhoodActions.updateCities({ cityId, check }));
  };

  const handleChangeCheckedArea = (area) => {
    dispatch(neighborhoodActions.updateNeighborhoodTowns(area));
  };

  return (
    <>
      {(loading || isFetching) ? <Loader /> : null}
      <div className="Neighborhood_Specialist_section w-100">
        <p className="select_towns">Choose up to 6 towns, where you have recently closed a property, and can provide 3 commission transaction reports. Approval takes upto 48 hours after submission.</p>
        <Tabs
          defaultActiveKey={cities[0]?.city_name}
          id="uncontrolled-tab-example"
          className="mb-3 justify-content-between mt-3">
            {cities.map((city, i) => {
              return (
                <Tab
                  key={i}
                  eventKey={city.city_name}
                  title={
                    <TabTitle
                      cityName={city.city_name}
                      chosenTowns={city.chosenTowns}
                    />
                  }>
                  <CityArea
                    cityArea={city.area}
                    handleSelect={(check) => handleSelect(city.id, check)}
                    checkedArea={checkedArea}
                    setChecked={setCheckedArea}
                  />
                </Tab>
              )
            })}
        </Tabs>
        <PropertyFiles
          checked={checkedArea}
          handleChangeCheckedArea={handleChangeCheckedArea}
          setLoading={setLoading}/>
      </div>
    </>
  )
}

export default NeighborhoodSpecialist
