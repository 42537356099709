import { FC, useEffect, useState } from "react";
import { Button, Modal, Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getDocuments } from "@bus/document/selectors";
import DocumentItem from "@components/pages/Documents/components/DocumentItem";
import { documentActions } from "@bus/document/actions";
import { Box } from "@mui/material";
import documents from "@components/pages/Documents/Documents";

type DocumentsPickerProps = {
	open?: boolean;
	onHide?: () => void;
	onAttach: (docs: UserDocument[]) => void;
	attachedIds: number[];
}

export type UserDocument = {
	id: number;
	file: string;
	filename: string;
}

export const DocumentsPicker: FC<DocumentsPickerProps> = ({ open, onHide, onAttach, attachedIds }) => {
	const dispatch = useDispatch();
	const [selectedDocuments, setSelectedDocuments] = useState<number[]>([]);
	const [selectedTab, setSelectedTab] = useState<string>("home");
	const documents: UserDocument[] = useSelector(getDocuments);

	useEffect(() => {
		dispatch(documentActions.fetchDocuments({
			signed: false,
		}));
		setSelectedDocuments([]);
	}, [selectedTab]);

	useEffect(() => {
		setSelectedDocuments(attachedIds);
	}, [attachedIds]);

	const handleCheck = (event: any) => {
		const { name, id, checked } = event.target;

		if (name === 'selectAll') {
			if (checked) {
				setSelectedDocuments(documents.map((item) => item.id));

				return;
			}

			setSelectedDocuments([]);

			return;
		}

		if (checked) {
			setSelectedDocuments([...selectedDocuments, +id]);

			return;
		}
		setSelectedDocuments((prev) => prev.filter((prevId) => prevId !== +id));
	};

	const handleShare = () => {
		onAttach(
			documents.filter(({ id }) => selectedDocuments.includes(id))
		);
	}

	return (
		<Modal show={open} onHide={onHide} size="xl" centered>
			<Modal.Header closeButton>
				<Box display={'flex'} alignItems={'center'} gap={'10px'}>
					<Button
						className="blue__btn"
						variant="outlined"
						size="sm"
						style={{
							marginRight: '20px',
							borderRadius: '20px',
							minWidth: '64px',
							padding: '10px 20px 10px 20px',
							fontSize: '0.8rem',
						}}
						onClick={handleShare}
					>
						Attach documents
					</Button>
					<Button
						className="blue__btn"
						variant="outlined"
						size="sm"
						style={{
							marginRight: '20px',
							borderRadius: '20px',
							minWidth: '64px',
							padding: '10px 20px 10px 20px',
							fontSize: '0.8rem',
						}}
						onClick={() => onAttach([])}
					>
						Reset
					</Button>
				</Box>
			</Modal.Header>
			<Modal.Body>
				<Tabs
					defaultActiveKey="home"
					activeKey={selectedTab}
					className="mb-3 flex-row"
					id="justify-tab-example"
					style={{ justifyContent: "flex-start" }}
					onSelect={(key) => {
						setSelectedTab(key!);
					}}
				>
					<Tab
						eventKey="home"
						title="Documentation"
						className="scrollS"
						style={{
							height: 'calc(100% - 9rem)',
							padding: '0 10px 10px 0',
							overflowY: 'auto',
							scrollBehavior: 'initial',
						}}
					>
						<div style={{ margin: "auto", position: "relative", }}>
							{
								documents.map((document: any, index: any) => (
									<DocumentItem
										key={'doc-' + index}
										document={document}
										isChecked={selectedDocuments.includes(document.id)}
										handleCheck={handleCheck}
										showActions={false}
									/>
								))
							}
						</div>
					</Tab>
					<Tab
						eventKey="pdfView"
						title="PFD View"
						className="scrollS"
						style={{
							height: 'calc(100% - 9rem)',
							padding: '0 10px 10px 0',
							overflowY: 'auto',
							scrollBehavior: 'initial',
						}}
					/>
				</Tabs>
			</Modal.Body>
		</Modal>
	)
}