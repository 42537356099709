import React from 'react';
import { FieldRenderProps } from 'react-final-form';

import { Box, TextField } from '@mui/material';

import { getFieldError } from '@core/FinalForm/getFieldError';
import { styles } from '@components/forms/SubmitRequestForAdvertising/styles';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { getLeaseType } from '@bus/search2/selectors';

export const RenderLeaseTypeField: React.FC<FieldRenderProps<number>> = ({
  input: {value, onChange, ...input},
  meta,
}) => {
  const leaseType = useSelector(getLeaseType);

  return (
    <div className={'select-property-type filter-row'}>
      <div className="d-flex gap-3 justify-content-between">
        <p className="filter-heading">Lease Type</p>
      </div>
      <div className="filter-radio d-flex gap-3">
        {leaseType.map((type: any) => (
          <div key={type.id} className="d-flex gap-2">
            <input
              type={'radio'}
              className={'Prop'}
              id={`lease-type-${type.id}`}
              value={type.id}
              checked={value === type.id}
              onChange={onChange}
              {...input}
            />
            <label htmlFor={`lease-type-${type.id}`} className="pointer">
              {type.title}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};
