import {
  api,
} from '@REST/api';

import * as effects from 'redux-saga/effects';
import { salesPersonActions } from '@bus/salesPerson/actions';
import { PayloadActionWithPromiseMeta } from '@core/FinalForm/typedefs';
import { getToken } from '../../../../appVariable/variable';
import { FillSalesPersonActionPayload, SearchPersonActionPayload } from '@bus/salesPerson/typedefs';

export function* searchPerson({
 payload,
 meta: { resolve, reject },
}: PayloadActionWithPromiseMeta<SearchPersonActionPayload>){
  try {
    const token = getToken();
    yield effects.put(salesPersonActions.startFetching());
    const body: string = yield effects.call(JSON.stringify, { ...payload, user: token.userId });
    const response: Response = yield effects.apply(api, api.post, [
      { endpoint: `api/user/SalesPersonSearch/`, body },
    ]);

    if (!response.ok) {
      reject();
      throw new Error('Unhandled error');
    }

    const data: FillSalesPersonActionPayload = yield effects.call([
      response,
      'json',
    ]);

    yield effects.put(salesPersonActions.fillSalesPerson(data));
    resolve();
  } catch (e) {
  } finally {
    yield effects.put(salesPersonActions.stopFetching());
  }
}
