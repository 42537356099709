import React from 'react';
import { FieldRenderProps } from 'react-final-form';

export const RenderMonthlyCostField: React.FC<FieldRenderProps<number>> = ({
  input: {value, onChange, ...input},
}) => {
  return (
    <div className="filter-radio d-flex gap-3">
      <div className="d-flex gap-2">
        <input
          type="radio"
          className="Prop"
          id="monthly-price"
          value={1}
          checked={value === 1}
          onChange={onChange}
          {...input}
        />
        <label htmlFor="monthly-price" className="pointer">
          Total Monthly Cost
        </label>
      </div>
      <div className="d-flex gap-2">
        <input
          type="radio"
          className="Prop"
          id="sqft-price"
          value={2}
          checked={value === 2}
          onChange={onChange}
          {...input}
        />
        <label htmlFor="sqft-price" className="pointer">
          Cost Per Sqft
        </label>
      </div>
    </div>
  );
};
