import React, { useState, useEffect } from "react";
import "./commertialMapFilter.scss";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Switch from "@mui/material/Switch";
import { alpha, styled } from "@mui/material/styles";
import { getRadioButtons } from "../../agent-dash-sales/server";
import { useDispatch, useSelector } from 'react-redux';
import { updateFilterData } from '@components/pages/homepage/components/hero/FilterSlice';
import { getTypesBasedOnTypeLeisure } from '@components/pages/homepage/components/hero/server';
import { updateFilterOptions } from '@components/pages/homepage/components/hero/FilterOptionSlice';
import { getLeaseType } from '@bus/search2/selectors';
import { getClassTypes, getPropertyConditions } from '@bus/dashboard/selectors';

const PinkSwitch = styled(Switch)(({ theme }) => ({
  ".MuiSwitch-track": {
    backgroundColor: "#315EFB",
    opacity: "1",
    height: "120%",
    transform: "translate(0px, -1px)",
  },
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#315EFB",
    "&:hover": {
      backgroundColor: alpha("#315EFB", theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#315EFB",
    height: "120%",
    transform: "translate(0px, -1px)",
  },
  "& .MuiSwitch-thumb": {
    color: "white",
    border: "1px solid rgba(88, 86, 86, 1)",
  },
}));

const label = { inputProps: { "aria-label": "Switch demo" } };
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  "&:not(:last-child)": {
    display: "none",
  },
  "&:before": {
    display: "none",
  },
  "&:after": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(
  ({ theme }) => ({
    flexDirection: "row-reverse",
    height: "14px",
    minHeight: "unset",
    color: "#315efb",
    padding: "0px 8px 0px 0px",
    ".MuiAccordionSummary-content": {
      display: "flex",
      justifyContent: "end",
    },
  })
);

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const CommertialMapFilter = ({ groupByFilter, virtualOfficeId, buyRentFilter }) => {
  const dispatch = useDispatch();
  const filter = useSelector((state) => state.FilterDataInStore);
  const leaseTypeList = useSelector(getLeaseType);
  const propertyConditionsList = useSelector(getPropertyConditions);
  const classTypesList = useSelector(getClassTypes);
  const FilterOptionsInStore = useSelector((state) => state.FilterOptionsInStore);
  const [unit, setUnit] = useState(1);
  const [room, setRoom] = useState(1);
  const [Type, setType] = useState(null);
  const [minSqft, setminSqft] = useState(null);
  const [maxSqft, setmaxSqft] = useState(null);
  const [minPrice, setminPrice] = useState(null);
  const [maxPrice, setmaxPrice] = useState(null);
  const [allradios, setAllRadios] = useState([]);
  const [category, setCategory] = useState(0);
  const [subType, setSubType] = useState(0);
  const [priceType, setPriceType] = useState("Total");
  const [expanded, setExpanded] = useState("panel1");
  const [leaseType, setLeaseType] = useState(0);
  const [propertyCondition, setPropertyCondition] = useState(0);
  const [classType, setClassType] = useState(0);
  const [minCap, setMinCap] = useState(null);
  const [maxCap, setMaxCap] = useState(null);
  const [minOccup, setMinOccup] = useState(null);
  const [maxOccup, setMaxOccup] = useState(null);
  const [airRights, setAirRights] = useState("");
  const [viewsetting, setViewsetting] = useState(false);
  const virtualofficeid = useSelector(
    (state) => state.GuestVirtualOfficeSliceInStore.guestOfficeId
  );

  useEffect(() => {
    setCategory(filter.category);
    setmaxPrice(filter.pricemax);
    setminPrice(filter.pricemin);
    setminSqft(filter.Squft_min);
    setmaxSqft(filter.Squft_max);
    setType(filter.type ?? 1);
    setUnit(filter.units ?? 1);
    setRoom(filter.room ?? 1);
    setSubType(filter.Sub_category);
    setMaxOccup(filter.occupancy_max);
    setMinOccup(filter.occupancy_min);
    setMaxCap(filter.capRate_max);
    setMinCap(filter.capRate_min);
    setClassType(filter.class_type ?? 0);
    setPropertyCondition(filter.property_condition ?? 0);
    setLeaseType(filter.lease_type ?? 0);
  }, [filter]);

  const handleCategory = (event) => {
    const newCategory = +event.target.value;
    setCategory(event.target.value);
    dispatch(updateFilterData({ category: newCategory }));
  };
  const handleLeaseType = (event) => {
    const newType = +event.target.value;
    setLeaseType(newType);
    dispatch(updateFilterData({ lease_type: newType }));
  };
  const handleSubType = (event) => {
    const newType = +event.target.value;
    setSubType(newType);
    dispatch(updateFilterData({ Sub_category: newType }));
  };
  const handlePropertyCondition = (event) => {
    const newType = +event.target.value;
    setPropertyCondition(newType);
    dispatch(updateFilterData({ property_condition: newType }));
  };
  const handleClassType = (event) => {
    const newType = +event.target.value;
    setClassType(newType);
    dispatch(updateFilterData({ class_type: newType }));
  };
  const handlePriceType = (event) => {
    setPriceType(event.target.value);
  };
  const handleUnits = (newCount) => {
    dispatch(updateFilterData({ units: newCount }));
  };
  const handleRoom = (newCount) => {
    dispatch(updateFilterData({ room: newCount }));
  };

  useEffect(() => {
    if (!!category) {
      const radioObj = { Property_listing_type_id: category };
      getRadioButtons(radioObj, (success) => {
        setAllRadios(success);
        setType(success[0].id);
        dispatch(updateFilterData({ type: success[0].id }));
        subTypeValue(success[0].id);
      });
    }
  }, [category]);
  const handleRadioChange = (event) => {
    const newType = +event.target.value;
    setType(newType);
    dispatch(updateFilterData({ type: newType }));
    subTypeValue(newType);
  };

  const sendFormdata = () => {
    const filterGroup = {
      propertylisting_type_id: category,
      property_main_category_id: Type,
      units: unit,
      rooms: room,
      property_sub_category_id: subType === 0 ? null : subType,
      priceType: priceType,
      minCap: minCap,
      maxCap: maxCap,
      minOccup: minOccup,
      maxOccup: maxOccup,
      min_sqft: minSqft,
      max_sqft: maxSqft,
      min_price: minPrice,
      max_price: maxPrice,
      available_air_right: airRights === "y",
      leaseType: leaseType,
      propertyCondition: propertyCondition,
      classType: classType,
      is_this_week: viewsetting,
      virtualoffice_id: virtualOfficeId ? virtualOfficeId : virtualofficeid,
    };
    groupByFilter(filterGroup);
  };

  const handleViewMoreChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const subTypeValue = (id) => {
    getTypesBasedOnTypeLeisure(Number(id), (sucess) => {
      if (sucess.data.code === 200) {
        let newarr = sucess?.data?.data?.map((item) => {
          return { label: `${item.property_sub_category_Name}`, id: item.id };
        });
        dispatch(updateFilterOptions({ SubTypeOptions: newarr }));
      } else {
        dispatch(updateFilterData({ Sub_category: null }));
        dispatch(updateFilterOptions({ SubTypeOptions: [] }));
      }
    }, dispatch);
  };

  return (
    <>
      <div
        className=" d-flex flex-column filter-body scrollS h-100 mh-100"
        style={{ width: "220px", height: "100%" }}
      >
        <div className="filter-by filter-form-row">
          <p className="filter-heading">Filter By :</p>
        </div>

        <div className="filter-form d-flex flex-column">
          <div className="type filter-form-row">
            <p className="small-heading">Category</p>
            <FormControl
              sx={{ m: 1, minWidth: 100, maxHeight: 50 }}
              size="small"
            >
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={category}
                className="select-category"
                onChange={(e) => handleCategory(e)}>
                {buyRentFilter?.map((type) => (
                  <MenuItem value={type.id} key={type.id}>
                    {type.user_listing_type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className="aminity-type filter-form-row  ">
            <p className="small-heading">Type</p>
            <div className="checkbox-column d-flex flex-row flex-wrap">
              {allradios.map((type) => {
                return (
                  <div className="aminity-radio" key={`radio${type.id}`}>
                    <input
                      type="radio"
                      id={`type-${type.id}`}
                      name={'type-radios'}
                      value={type.id}
                      checked={Type === type.id}
                      onChange={handleRadioChange} />
                    <label htmlFor={`type-${type.id}`} className="aminity-radio-label">
                      {type.Main_category}
                    </label>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="type filter-form-row">
            <FormControl
              sx={{ m: 1, minWidth: 100, maxHeight: 50, marginLeft: "0px" }}
              size="small"
            >
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={subType}
                className="select-category"
                onChange={(e) => handleSubType(e)}
                placeholder="Sub Type">
                {FilterOptionsInStore?.SubTypeOptions.length > 0 ? (
                  FilterOptionsInStore?.SubTypeOptions?.map(
                    (item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.label}
                      </MenuItem>
                    )
                  )
                ) : (
                  <MenuItem value={""}>No Option</MenuItem>
                )}
              </Select>
            </FormControl>
          </div>

          <div className="bedroom-bathroom">
            <div className=" small-in-box-div">
              <div className="filter-form-row ">
                <p className="small-heading">Unit</p>
                <div className="beadroom small-in-box d-flex justify-content-around">
                  <p onClick={() => {
                    const newCount = unit + 1;
                    setUnit(newCount);
                    handleUnits(newCount);
                  }} className="pointer">
                    +
                  </p>
                  <p>{unit}</p>
                  <p onClick={() => {
                    const newCount = unit - 1;
                    setUnit(newCount);
                    handleUnits(newCount);
                  }} className="pointer">
                    -
                  </p>
                </div>
              </div>
              <div className="filter-form-row">
                <p className="small-heading">Room</p>
                <div className="beadroom small-in-box d-flex justify-content-around">
                  <p onClick={() => {
                    const newCount = room + 1;
                    setRoom(newCount);
                    handleRoom(newCount);
                  }} className="pointer">
                    +
                  </p>
                  <p>{room}</p>
                  <p onClick={() => {
                    const newCount = room - 1
                    setRoom(newCount);
                    handleRoom(newCount);
                  }} className="pointer">
                    -
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="sqft-price filter-form-row">
            <p className="small-heading">Sqft</p>
            <div className=" d-flex small-in-box-div">
              <div className="input-sec small-in-box">
                <input
                  type="number"
                  placeholder="Sqft Min"
                  onChange={(e) => {
                    setminSqft(Number(e.target.value));
                    dispatch(updateFilterData({ Squft_min: Number(e.target.value) }));
                  }}
                />
              </div>
              <div className="input-sec small-in-box">
                <input
                  type="number"
                  placeholder="Sqft Max"
                  onChange={(e) => {
                    setmaxSqft(Number(e.target.value));
                    dispatch(updateFilterData({ Squft_max: Number(e.target.value) }));
                  }}
                />
              </div>
            </div>
          </div>
          {/* price section */}
          <div className="sqft-price  filter-form-row">
            <p className="small-heading">Price</p>
            <div className=" d-flex small-in-box-div">
              <div className="filter-radio d-flex gap-1">
                {category === 10 ? (
                  <>
                    <div className="d-flex gap-1">
                      <input
                        type="radio"
                        name="PriceType"
                        className="Prop"
                        id="monthly-price"
                        value="1"
                        onClick={(e) => {
                          handlePriceType(e);
                        }}
                      />
                      <label
                        htmlFor="monthly-price"
                        className="price-type-text"
                      >
                        Total Monthly Cost
                      </label>
                    </div>
                    <div className="d-flex gap-1">
                      <input
                        type="radio"
                        name="PriceType"
                        className="Prop"
                        id="sqft-price"
                        value="2"
                        onClick={(e) => {
                          handlePriceType(e);
                        }}
                      />
                      <label htmlFor="sqft-price" className="price-type-text">
                        Cost Per Sqft
                      </label>
                    </div>
                  </>
                ) : (
                  <div className="d-flex gap-1">
                    <input
                      type="radio"
                      name="CostPerSqft"
                      className="Prop"
                      id="sqft-price"
                      value="2"
                      onClick={(e) => {
                        handlePriceType(e);
                      }}
                    />
                    <label htmlFor="sqft-price" className="price-type-text">
                      + Cost Per Sqft (if provided)
                    </label>
                  </div>
                )}
              </div>
            </div>
            <div className=" d-flex small-in-box-div">
              <div className="input-sec small-in-box">
                <input
                  type="number"
                  placeholder="Price Min"
                  onChange={(e) => {
                    setminPrice(Number(e.target.value));
                    dispatch(updateFilterData({ pricemin: Number(e.target.value) }));
                  }}
                />
              </div>
              <div className="input-sec small-in-box">
                <input
                  type="number"
                  placeholder="Price Max"
                  onChange={(e) => {
                    setmaxPrice(Number(e.target.value));
                    dispatch(updateFilterData({ pricemax: Number(e.target.value) }));
                  }}
                />
              </div>
            </div>
          </div>
          {category === 10 ? null : (
            <>
              <div className="sqft-price  filter-form-row flex-row gap-4">
                <div className="sqft-price filter-form-row">
                  <p className="small-heading">Cap Rate</p>
                  <div className=" d-flex small-in-box-div">
                    <div className="input-sec small-in-box-cap">
                      <input
                        type="number"
                        placeholder="0%"
                        onChange={(e) => {
                          setMinCap(Number(e.target.value));
                          dispatch(updateFilterData({ capRate_min: Number(e.target.value) }));
                        }}
                      />
                    </div>
                    <div className="input-sec small-in-box-cap">
                      <input
                        type="number"
                        placeholder="100%"
                        onChange={(e) => {
                          setMaxCap(Number(e.target.value));
                          dispatch(updateFilterData({ capRate_max: Number(e.target.value) }));
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="sqft-price filter-form-row">
                  <p className="small-heading">Occupancy</p>
                  <div className=" d-flex small-in-box-div">
                    <div className="input-sec small-in-box-cap">
                      <input
                        type="number"
                        placeholder="0%"
                        onChange={(e) => {
                          setMinOccup(Number(e.target.value));
                          dispatch(updateFilterData({ occupancy_min: Number(e.target.value) }));
                        }}
                      />
                    </div>
                    <div className="input-sec small-in-box-cap">
                      <input
                        type="number"
                        placeholder="100%"
                        onChange={(e) => {
                          setMaxOccup(Number(e.target.value));
                          dispatch(updateFilterData({ occupancy_max: Number(e.target.value) }));
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {/* lease type section */}
          <div className="sqft-price  filter-form-row">
            <p className="small-heading">
              {category === 3 ? "Lease Type" : "Investment of Lease Type"}
            </p>
            <div className=" d-flex small-in-box-div">
              <div className="filter-radio d-flex gap-1 flex-wrap">
                {leaseTypeList.map((type) => (
                  <div key={type.id} className="d-flex gap-1">
                    <input
                      type={'radio'}
                      className={'Prop'}
                      id={`lease-type-${type.id}`}
                      value={type.id}
                      name={'lease-type'}
                      checked={leaseType === type.id}
                      onChange={handleLeaseType}
                    />
                    <label htmlFor={`lease-type-${type.id}`} className="price-type-text">
                      {type.title}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* property condition section */}
          <div className="sqft-price  filter-form-row">
            <p className="small-heading">Property Conditions&Class</p>
            <div className=" d-flex small-in-box-div">
              <div className="filter-radio d-flex gap-1 flex-wrap">
                {propertyConditionsList.filter((item) => {
                  if (category === 3) {
                    return item.property_listing_type === category;
                  }
                  return true;
                }).map((item) => (
                  <div key={item.id} className="d-flex gap-1">
                    <input
                      type={'radio'}
                      className={'Prop'}
                      id={`property-${item.id}`}
                      value={item.id}
                      name={'property-condition'}
                      checked={propertyCondition === item.id}
                      onChange={handlePropertyCondition}
                    />
                    <label htmlFor={`property-${item.id}`} className="price-type-text">
                      {item.title}
                    </label>
                  </div>
                ))}
                {classTypesList.map((item) => (
                  <div key={item.id} className="d-flex gap-1">
                    <input
                      type={'radio'}
                      className={'Prop'}
                      id={`class-${item.id}`}
                      name={'class-type'}
                      value={item.id}
                      checked={classType === item.id}
                      onChange={handleClassType}
                    />
                    <label htmlFor={`class-${item.id}`} className="price-type-text">
                      {item.title}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* available air rights section */}
          <div className="sqft-price  filter-form-row d-flex flex-row">
            <p className="small-heading">Available Air Rights</p>
            <input
              type="text"
              placeholder="Y/N"
              className="air-right-input"
              onChange={(e) => {
                setAirRights(e.target.value);
                dispatch(updateFilterData({ Available_Air_Rights: e.target.value === "y" }));
              }}
              maxLength="1"
            />
          </div>
          {/* new this week */}
          <div className="sqft-price  filter-form-row d-flex flex-row justify-content-between">
            <div className="small-heading new-week">New This Week</div>
            <div className="d-flex justify-content-end">
              <PinkSwitch
                {...label}
                onChange={(e) => {
                  setViewsetting(e.target.checked);
                  setViewsetting((state) => {
                    return state;
                  });
                }}
                checked={viewsetting}
                style={{ color: "#315EFB" }}
              />
            </div>
          </div>
          <div
            className="buttons pointer blue__btn adfasdfsad"
            onClick={(e) => sendFormdata()}
            style={{ margin: "auto" }}
          >
            {" "}
            Update{" "}
          </div>

          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleViewMoreChange("panel1")}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <p>View More</p>
            </AccordionSummary>
            <AccordionDetails>
              <div>this is content for view more</div>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </>
  );
};

export default CommertialMapFilter;
