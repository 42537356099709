import React, { useEffect, useState } from "react";
import "./../../../guestVirtualOffice/guestVirtualOffice.scss";
import { GrDislike } from "react-icons/gr";
import { FaBed } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Tooltip } from "@mui/material";
import {
  deleteInterested,
  getInterestedCustomers,
} from "../../../guestVirtualOffice/server";
import { notinterestedProperty } from "../../server";
import { getToken } from "../../../../../appVariable/variable";
import Loader from "../../../Loader/Loader";
import Filterbar from "../editContact/Filterbar";
import PropertyDetailsPopUp from "../../../dashboard/propperty-datails/PropertyDetailsPopUp";
import { useDispatch } from "react-redux";
import { updatePropertyDatail } from "../../../dashboard/propperty-datails/PropertySlice";
import { getAllpropertyInfo } from "../../../dashboard/components/mainSection/server";
import PropertySavePopUp from "../../../dashboard/components/mainSection/PropertySavePopUp";
import PopUpFilter from "../../../dashboard/components/filter-popup/PopUpFilter";

const AgentInterestedProperty = () => {
  const [loading, setloading] = useState(false);
  const [properties, setproperties] = useState([]);
  const dispatch = useDispatch();
  const virtualofficeid = useSelector(
    (state) => state.virtualOfficeInStore.officeId
  );
  const [propertyDetailInfo, setPropertyDetailInfo] = useState({
    propertyImg: [],
  });
  const propertyDetailInStore = useSelector(
    (state) => state.propertyDetailInStore
  );
  const [showFilter, setShowFilter] = useState(false);
  useEffect(() => {
    setloading(true);
    if (virtualofficeid) {
      getInterestedProperties();
    }
  }, [virtualofficeid]);

  const getInterestedProperties = () => {
    getInterestedCustomers(
      virtualofficeid,
      (success) => {
        if (success.data.code === 200) {
          setproperties(success.data.data);
          setloading(false);
        } else {
          setproperties([]);
          setloading(false);
          // toast.error("something went wrong", "error")
        }
      },
      (error) => {
        console.log(error);
        setproperties([]);
        setloading(false);
        // toast.error("something went wrong", "error")
      }
    );
  };
  let propertDetails = async (id) => {
    setloading(true);
    dispatch(
      updatePropertyDatail({ propertyId: id, setshowPropertyPopup: true })
    );
    await getAllpropertyInfo(id, (success) => {
      dispatch(updatePropertyDatail({ PropertyDetailInfo: success }));
      setPropertyDetailInfo(success);
      setPropertyDetailInfo((state) => {
        return state;
      });
      setloading(false);
    });
  };
  const deleteInterestedProperty = (prop) => {
    setloading(true);
    deleteInterested(
      prop.id,
      (resp) => {
        setloading(false);
        if (resp.data.code == 200) {
          getInterestedProperties();
          // toast.success("delete successfully", "success")
        } else {
          // toast.error("something went wrong", "error")
        }
      },
      (err) => {
        // toast.error("something went wrong", "error")
      }
    );
  };

  const notinterested = (prop) => {
    setloading(true);
    let token = getToken();
    let obj = {};
    obj.user_id = token.userId;
    obj.propertyid = prop.propertyid?.id;
    obj.dislike = 1;
    obj.virtual_ofice = virtualofficeid;
    notinterestedProperty(
      obj,
      (resp) => {
        setloading(false);
        if (resp.data.code == 200) {
          getInterestedProperties();
          // toast.success("Thanks for showing interest", "success")
        } else {
          // toast.error("something went wrong", "error")
        }
      },
      (err) => {
        // toast.error("something went wrong", "error")
      }
    );
  };

  return (
    <>
      {loading ? <Loader /> : null}
      <div className="agent-virtual-office-dashboard flex-grow-1">
        <div className=" MainCard_container w-100">
          <div className="filter-search_a w-100">
            <Filterbar
              className="Fil_bar"
              setShowFilter={setShowFilter}
              setfilterView={true}
            />
          </div>
        </div>
        <div className="interested-properties gap-4 mt-5">
          <p>INTERESTED PROPERTIES</p>
        </div>
        <div className="hide-scrollbar card-parent">
          {properties.length > 0 ? (
            properties.map((property, index) => {
              return (
                <div className="guest-virtual-card" key={"property" + index}>
                  <div
                    className="d-flex flex-wrap mt-3 pointer"
                    onClick={() => propertDetails(property?.propertyid?.slug)}
                  >
                    <div className="guest-property-virtual-card">
                      <img
                        src={property.propertyid?.property_main_image}
                        alt=""
                        className="property-main-image"
                      />
                      <div className="details">
                        <div className="details-left">
                          <div style={{ display: "flex", gap: "10px" }}>
                            <h5 className="amount mt-1">
                              ${property.propertyid?.property_listing_amount}{" "}
                            </h5>
                            <h5>
                              {" "}
                              <FaBed />{" "}
                              <span className="area-name">
                                {property.propertyid?.Bedrooms} BR
                              </span>
                            </h5>
                          </div>
                          <p className="area-name">
                            {property.propertyid?.property_address_1}
                          </p>
                          <p className="area-name">
                            {property.propertyid?.property_area.area_name}
                          </p>
                        </div>
                        <div className="details-right mt-1">
                          <div className="profile-image-parent">
                            <img
                              src={property.propertyid?.user_profile.profile_image}
                              alt=""
                              className="profile-image"
                            />
                          </div>
                          <button
                            className="profile-image-button"
                            style={{ opacity: "10" }}
                          >
                            1 year
                          </button>
                        </div>
                      </div>
                      {/* <div className="action-buttons">
                <button className="buttons" >Interested</button>
                <button className="buttons" >Don't Like</button>
              </div> */}
                      {/* <button className='add-note w-100'> Add Note</button> */}
                    </div>
                  </div>
                  <div className="likedislike">
                    <button
                      className="buttons"
                      onClick={() => notinterested(property)}
                    >
                      <GrDislike style={{ color: "#FF3838" }} /> Don't Like
                    </button>
                    <button
                      className="buttons"
                      onClick={() => deleteInterestedProperty(property)}
                    >
                      Delete
                    </button>
                  </div>
                  <div className="note pt-1">
                    {property.note != null && property.note.length > 25 ? (
                      <Tooltip
                        title={
                          <h6>
                            {property.note != null ? property.note : null}
                          </h6>
                        }
                        placement="top"
                      >
                        <span style={{ color: "#315EFB" }}>Note : </span>{" "}
                        <span>
                          {property.note != null
                            ? property.note.slice(0, 25) + "..."
                            : null}
                        </span>
                      </Tooltip>
                    ) : (
                      <span>
                        <span style={{ color: "#315EFB" }}>Note:</span>{" "}
                        <span>{property.note}</span>
                      </span>
                    )}
                  </div>
                </div>
              );
            })
          ) : (
            <h4 className="mt-5">No data found</h4>
          )}
        </div>
        {propertyDetailInStore.setshowPropertyPopup &&
          !loading &&
          propertyDetailInfo?.propertDetails?.property_address_1 !==
            undefined && (
            <PropertyDetailsPopUp
              setPropertyDetailInfo={setPropertyDetailInfo}
              propertyDetailInfo={propertyDetailInfo} // passing info about
              showTravelview={true}
            />
          )}
        {propertyDetailInStore.setSavePropertyPopup && <PropertySavePopUp />}
        {showFilter && <PopUpFilter setShowFilter={setShowFilter} />}
      </div>
    </>
  );
};

export default AgentInterestedProperty;
