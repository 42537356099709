import { Conversation } from "@store/slices/message.slice";
import { getToken } from "../../../../appVariable/variable";
import { useMemo } from "react";
import { getInitials } from "@components/shared/profile-avatar";
import { useConversationParticipants } from "@components/pages/Message/hooks/useConversationParticipants";

export const useChatAttributes = (conversation: Conversation) => {
	const token = getToken();
	const [participant] = useConversationParticipants(conversation, token.user_info.identity);

	const name = useMemo(() => {
		if (!participant) return 'No participants';
		return `${participant.first_name} ${participant.last_name}`.trim() || 'Unknown participant';
	}, [participant]);

	return {
		name,
		avatar: {
			fullName: name,
			initials: getInitials(name),
			image: participant?.profile_image || undefined,
		}
	}
}