import React, { useEffect, useState } from 'react';
import { FieldRenderProps } from 'react-final-form';

import { Box, TextField } from '@mui/material';

import { getFieldError } from '@core/FinalForm/getFieldError';
import { styles } from '@components/forms/SubmitRequestForAdvertising/styles';
import Typography from '@mui/material/Typography';
import { getamminity } from '@components/pages/dashboard/components/filter-popup/server/server';
import { getTypeOfProperties } from '@components/pages/homepage/components/hero/server';
import { updateFilterData } from '@components/pages/homepage/components/hero/FilterSlice';
import { getToken } from '../../../../appVariable/variable';

export const RenderAmenitiesField: React.FC<FieldRenderProps<number[]>> = ({
  input,
  meta,
}) => {
  const [allAmenities, setAllAmenities] = useState([]);

  useEffect(() => {
    getamminity((success: any) => {
      setAllAmenities(success);
    });
  }, []);

  const handleAmenityChange = (amenityId: number, checked: boolean) => {
    const newValue = [...input.value];
    if (checked) {
      if (!newValue.includes(amenityId)) {
        newValue.push(amenityId);
      }
    } else {
      const index = newValue.indexOf(amenityId);
      if (index !== -1) {
        newValue.splice(index, 1);
      }
    }
    input.onChange(newValue);
  };

  return (
    <div className="filter-row">
      <p className="filter-heading">Amenities</p>
      <div className="Amenities d-flex  flex-wrap ">
        {allAmenities?.map((amenity: any, i) => (
          <div
            className="select-aminity position-relative"
            key={`amenity${i}`}
          >
            <input
              type="checkbox"
              className="Prop aminity-input visually-hidden"
              id={`filter-amenity${i}`}
              name={`${amenity.id}`}
              onChange={(e) => handleAmenityChange(amenity.id, e.target.checked)}
              checked={input.value.includes(amenity.id)}
            />
            <label
              htmlFor={`filter-amenity${i}`}
              className="pointer aminity"
            >
              {amenity.amenities_name}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};
