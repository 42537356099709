import React, { useState } from 'react'
import './sellPerson.scss'
import filter from "./images/filter.svg";
import search from "./images/search.svg";
import Modal from 'react-bootstrap/Modal';
import { CircularProgress } from '@mui/material';
import { getToken } from '../../../../appVariable/variable';
import FinalForm from '@core/FinalForm';
import { SalesPersonFilterForm, schema } from '@components/forms/SalesPersonFilterForm';
import { salesPersonActions } from '@bus/salesPerson/actions';
import { useDispatch } from 'react-redux';

const SelPerson = () => {
  const token = getToken()
  const [modalShow, setModalShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const handleSearch = (e) => {
    e.preventDefault();
    setLoading(true);
    new Promise((resolve, reject) => {
      dispatch(
        salesPersonActions.searchPerson({
          values: {
            user: token.userId,
            search: e.target[0].value,
            email: null,
            zipcode: null,
            start_rating: null,
            language: null,
          },
          resolve,
          reject,
        }),
      );
    })
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }
  const handleSuccess = () => {
    setModalShow(false);
  };

  return (
    <>
      <div className='sel_person_section flex-grow-1 '>
        <div className="search-box d-flex flex-grow-1 justify-content-between">
          <form className="search d-flex align-items-center justify-content-between flex-grow-1" onSubmit={handleSearch} >
            <input type="text" className="trans-input" placeholder="Search Town or City" name="searchQuary" id="" autoComplete='off' />
            <button type='submit' className="search-icon pointer border-0 bg-transparent d-flex" >
              {loading
                ? <CircularProgress className=' h-auto' style={{ width: "20px" }} />
                : <img src={search} alt="" />
              }
            </button>
          </form>
          <div onClick={() => setModalShow(true)} className="filter-icon pointer center"><img src={filter} alt="" /> </div>
        </div>
      </div>

      <div className="forgot_modals">
        <Modal
          className="forgot_modals"
          show={modalShow}
          onHide={() => setModalShow(false)}
          size="lg"
          ria-labelledby="contained-modal-title-vcenter"
          centered>
          <Modal.Header closeButton className="border-bottom-0 forgotmodal_body pb-0" />
          <Modal.Body className="px-4 pt-0 forgotmodal_body">
            <div className='form_selperson'>
              <div className="card card_borders border-0">
                <div className="card-body">
                  <FinalForm
                    initialValues={{}}
                    component={SalesPersonFilterForm}
                    sagaAction={salesPersonActions.searchPerson}
                    schema={schema}
                    onSubmitSuccess={handleSuccess}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default SelPerson;
