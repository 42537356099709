import React from "react";

export const AuthLayout = ({ className = '', children }) => {
  return (
    <div className={`loginpage_body ${className}`}>
      <div className="login_body">
        <div className="login_body_left">
          <img
            src="/vertical_logo.png"
            alt="Afisado Logo"
            className="login-logo"
          />
        </div>
        <div className="login_body_right">
          <div className="loginpage_form">
            { children }
          </div>
        </div>
      </div>
    </div>
  )
}
