import salesPersonSlice from './slice';
import { createAction } from '@reduxjs/toolkit';
import prepareActions from '@helpers/prepareActions';

export const salesPersonActions = {
  ...salesPersonSlice.actions,
  searchPerson: createAction('salesPerson/searchPerson', prepareActions.movePromiseToMeta),
  fetchSavedAgents: createAction('salesPerson/fetchSavedAgents'),
  deletePerson: createAction('salesPerson/deletePerson', prepareActions.movePromiseToMeta),
  savePerson: createAction('salesPerson/savePerson', prepareActions.movePromiseToMeta),
  loadMorePerson: createAction('salesPerson/loadMorePerson',
    ({ url }) => ({
      payload: { url },
    }),
  ),
  loadMoreSavedPerson: createAction('salesPerson/loadMoreSavedPerson',
    ({ url }) => ({
      payload: { url },
    }),
  ),
};
