import React from 'react';
import { styles } from './styles';
import { Box, Button } from '@mui/material';
import { toast } from 'react-toastify';
import Typography from '@mui/material/Typography';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import { neighborhoodActions } from '@bus/neighborhood/actions';

type ConfirmRemoveProps = {
  show: boolean;
  handleClose: () => void;
  id: number
};

export const ConfirmRemove: React.FC<ConfirmRemoveProps> = ({ show, handleClose, id }) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    new Promise((resolve, reject) => {
      dispatch(
        neighborhoodActions.deleteAreas({
          values: { id },
          resolve,
          reject,
        }),
      );
    })
      .then(() => {
        handleClose();
        toast.success('Area removed successfully');
      })
      .catch();
  };

  return (
    <Modal show={show} onHide={handleClose} size={'sm'} centered>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Box>
          <Typography textAlign={'center'}>Are you sure?</Typography>
          <Box display={'flex'} alignItems={'center'} gap={'10px'} mt={'20px'}>
            <Button sx={styles.button} fullWidth onClick={handleClick}>Yes</Button>
            <Button sx={styles.removeButton} fullWidth onClick={handleClose}>No</Button>
          </Box>
        </Box>
      </Modal.Body>
    </Modal>
  );
};
