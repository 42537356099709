//state type____________________________________
export type NeighborhoodState = {
  isFetched: boolean;
  isCitiesFetched: boolean;
  neighborhoodTowns: INeighborhoodTown[];
  cities: ICity[];
};

//payload types_________________________________
export type FillNeighborhoodTownsActionPayload = INeighborhoodTown[];
export type UpdateNeighborhoodTownsActionPayload = INeighborhoodTown;
export type SaveAreaActionPayload = INeighborhoodTown;
export type FillCityAreasActionPayload = ICity[];
export type DeleteAreaActionPayload = {
  id: number;
};

//common types__________________________________
export interface INeighborhoodTown {
  id: number;
  area: IArea;
  doc1: null | string;
  doc2: null | string;
  doc3: null | string;
  status: number;
}
export interface ICity {
  city_image: string;
  city_name: string;
  geo_point: string;
  id: number;
  is_active: boolean;
  latitude: number;
  longitude: number;
  position: number;
  state_master: number;
  area: IArea[];
  chosenTowns: number;
}
export interface IArea {
  area_name: string;
  city_master: number;
  geo_point: string;
  id: number;
  is_active: boolean;
  latitude: number;
  longitude: number;
  position: number;
  prefered_name: string;
}

export const DocumentStatus = {
  1: 'Pending',
  2: 'Approved',
  3: 'Rejected'
} as const;
