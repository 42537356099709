import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  FillCityAreasActionPayload,
  FillNeighborhoodTownsActionPayload,
  NeighborhoodState,
  UpdateNeighborhoodTownsActionPayload,
} from './typedefs';

const initialState: NeighborhoodState = {
  isFetched: false,
  isCitiesFetched: false,
  neighborhoodTowns: [],
  cities: [],
};

const neighborhoodSlice = createSlice({
  name: 'neighborhood',
  initialState,
  reducers: {
    startFetching(state) {
      state.isFetched = true;
    },
    finishFetching(state) {
      state.isFetched = false;
    },
    setCitiesFetched(state, action: PayloadAction<boolean>) {
      state.isCitiesFetched = action.payload;
    },
    fillNeighborhoodTowns(state, action: PayloadAction<FillNeighborhoodTownsActionPayload>) {
      state.neighborhoodTowns = action.payload;
      state.cities = state.cities.map(cityItem => {
        let count = 0;
        cityItem.area.forEach((areaItem) => {
          if (action.payload.some((item) => item.area.id === areaItem.id)) {
            count++;
          }
        });
        return {
          ...cityItem,
          chosenTowns: count
        };
      });
    },
    updateNeighborhoodTowns(state, action: PayloadAction<UpdateNeighborhoodTownsActionPayload>) {
      state.neighborhoodTowns = [...state.neighborhoodTowns, action.payload];
    },
    removeArea(state, action: PayloadAction<{ id: number }>) {
      state.neighborhoodTowns = state.neighborhoodTowns.filter((item) => item.id !== action.payload.id);
      state.cities = state.cities.map(cityItem => {
        let count = 0;
        cityItem.area.forEach((areaItem) => {
          if (state.neighborhoodTowns.some((item) => item.area.id === areaItem.id)) {
            count++;
          }
        });
        return {
          ...cityItem,
          chosenTowns: count
        };
      });
    },
    fillCities(state, action: PayloadAction<FillCityAreasActionPayload>) {
      state.cities = action.payload.map(city => ({
        ...city,
        chosenTowns: 0,
      }));
    },
    updateCities(state, action: PayloadAction<{ cityId: number, check: boolean }>) {
      state.cities = state.cities.map((city) => {
        if (city.id === action.payload.cityId) {
          return {
            ...city,
            chosenTowns: action.payload.check ? city.chosenTowns + 1 : city.chosenTowns - 1,
          };
        }

        return city;
      });
    },
  },
});

export default neighborhoodSlice;
