import { FC, useState } from "react";
import { ActiveChatHeader } from "@components/pages/Message/components/ActiveChatHeader";
import { ActiveChatBody } from "@components/pages/Message/components/ActiveChatBody";
import { ActiveChatInput } from "@components/pages/Message/components/ActiveChatInput";
import { ChatModal } from "@components/pages/Message/components/ChatModal";
import { useSelector } from "react-redux";
import { GetSelectedConversation } from "../../../../store/selectors/message.selector";

export const ActiveChat: FC = () => {
	const selectedConversation = useSelector(GetSelectedConversation);
	const [modalShow, setModalShow] = useState(false);

	if (!selectedConversation) return (
		<div className="chat-section flex-grow-1 position-relative">
			<div className="chat-main-box d-flex flex-column justify-content-center align-items-center hide-scrollbar">
				<span>Choose who you would like to write to</span>
			</div>
		</div>
	)

	return (
		<>
			<div className="chat-section flex-grow-1 position-relative">
				<ActiveChatHeader onClick={() => setModalShow(true)} />
				<ActiveChatBody />
				<ActiveChatInput />
			</div>
			<ChatModal
				show={modalShow}
				onClick={() => setModalShow(false)}
			/>
		</>
	)
}