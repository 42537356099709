import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Topbar from "../components/topbar/Topbar";
import icons from "../components/sidebar/images/SidebarIcon";
import { getToken, sidebarValue } from "../../../../appVariable/variable";
import { useEffect } from "react";
import { totalListingcount } from "../../expiredListing/server";
import { updateTotalListing } from "../DashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import { SidebarWrapper } from '@components/pages/dashboard/components/SidebarWrapper';

const sidebardata = [
  { icon: icons.Dashboard, link: "add-property", title: "Create Listing" },
  { icon: icons.current, link: "current-listing", title: "CURRENT LISTING" },
  { icon: icons.expire, link: "expire-listing", title: "EXPIRED LISTING" },
  { icon: icons.message, link: "message", title: "MESSAGE" },
  { icon: icons.boost, link: "boost-marketing", title: "BOOST MARKETING" },
  { icon: icons.office, link: "virtual-office", title: "VIRTUAL OFFICE" },
  { icon: icons.documents, link: "document", title: "Document" },
  { icon: icons.analytics, link: "analytic", title: "ANALYTIC" },
  { icon: icons.Reviews, link: "review", title: "Review" },
  { icon: icons.support, link: "support", title: "Support" },
];

const AgentDashboard = () => {
  const dispatch = useDispatch();
  const totalListing = useSelector(
    (state) => state.DashboardSliceInStore.totalListing
  );
  const [sidebar, setsidebar] = useState(true);
  const [theme, settheme] = useState(true);
  const [sidebarStatus, setSidebarStatus] = useState(false);
  const [updatesidebar, setUpdatesidebar] = useState(true);
  let token = getToken();
  let agentSlug = token?.profile.slug;
  let paramObj = agentSlug;

  useEffect(() => {
    totalListingcount(paramObj, (success) => {
      dispatch(updateTotalListing(success.data.data));
    });
    setSidebarStatus(true);
  }, [updatesidebar, totalListing]);

  return (
    <div className="main-dashboard">
      <div
        className={`dashboard-inner ${theme ? "white-theme" : "blue-theme"}`}
      >
        <Topbar
          sidebar={sidebar}
          setsidebar={setsidebar}
          theme={theme}
          settheme={settheme}
          totalListing={50}
          agentTotaListing={totalListing}
        />
        <div className="dashboard-middle d-flex h-100">
          <SidebarWrapper
            sidebardata={sidebardata}
            pointerEvent={sidebarStatus}
            settingOption={true}
            sidebar={sidebar} />
          <Outlet
            context={{
              updatesidebar: updatesidebar,
              setUpdatesidebar: setUpdatesidebar,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default AgentDashboard;
