export const styles = {
  file: {},
  imageWrapper: {
    width:'50px',
    height:'50px',
    cursor: 'pointer',
    borderRadius: '10px',
    overflow: 'hidden',
  },
  iconWrapper: {
    width: '50px',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all 0.3s ease-in-out',
    background: 'none',
    borderRadius: '10px',
    '&:hover': {
      background: 'rgba(198, 225, 255, 0.8)',
    },
  },
};
