// import React from 'react'
import "./password.scss";
// import Button from 'react-bootstrap/Button';
import Modal from "react-bootstrap/Modal";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
// import close from "./images/close.svg";
import { useFormik } from "formik";
import {
  changePassword,
  getProfilePasswordStatus,
  ProfileSettingForgetPassword,
  removePasssword,
  submitProfilePassword,
} from "./server";
import swal from "sweetalert";
import { getToken } from "../../../../../appVariable/variable";
import * as Yup from "yup";
import Validation from "../../../Auth/Validation";
import Loader from "../../../Loader/Loader";
import { emailRegex } from "../teams/Teams";
import { toast } from "react-toastify";
// import ChangePassword from '../changepassword/ChangePassword'
import ConfirmPassword from "./ConfirmPassword";
import { setUserDetails } from '../../../../../REST/api';
export const Passcheck =
  /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/;

const Passwordsec = (props) => {
  // const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [displayProfileInput, setDisplayProfileInput] = useState(true);
  const [displayCreateAdminInputSection, setdisplayCreateAdminInputSection] =
    useState(false);
  const [profilePassword, setProfilePassword] = useState("");
  const [isRevealOldPwd, setIsRevealOldPwd] = useState(false);
  const [isRevealNewPwd, setIsRevealNewPwd] = useState(false);
  const [isRevealConfPwd, setIsRevealConfPwd] = useState(false);
  const [isRevealProfilePaswd, setIsRevealProfilePaswd] = useState(false);
  const [profilePassEmail, setProfilePassEmail] = useState("");
  const [profilePassEmailError, setProfilePassEmailError] = useState("");
  const [updatePass, setUpdatePass] = useState(true);
  const [loading, setLoading] = useState(false);
  const [confirmPassModal, setConfirmPassModal] = useState(false);
  const [backendPass, setBackendPass] = useState("");

  // onclick event for submit
  const formik = useFormik({
    initialValues: { oldPswrd: "", newPswrd: "", confirmPswrd: "" },
    validationSchema: Yup.object({
      oldPswrd: Yup.string()
        .matches(Passcheck, "Enter valid Password")
        .required("old Password Required")
        .nullable(),
      newPswrd: Yup.string()
        .min(8, "Password must be at 8 char long")
        .matches(/^(?=.*[A-Z])/, "please Enter One Uppercase Value")
        .nullable()
        .matches(/^(?=.*[a-z])/, "please Enter One LowerCase Value")
        .nullable()
        .matches(
          /^(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>?/])/,
          "Please Enter One SpecialChar Value"
        )
        .nullable()
        .matches(/(?=.*?[0-9])/, "Please Enter One Char Number")
        .nullable()
        .required("Password is mendatory"),
      confirmPswrd: Yup.string()
        .oneOf([Yup.ref("newPswrd")], "Passwords does not match")
        .nullable()
        .required("Confirm Password is mendatory"),
    }),
    onSubmit: (values) => {
      let userDetails = getToken();
      let tempObj = {};
      tempObj["user_id"] = userDetails.userId;
      tempObj["old_password"] = formik.values.oldPswrd;
      tempObj["password1"] = formik.values.newPswrd;
      tempObj["password2"] = formik.values.confirmPswrd;
      if (formik.values.newPswrd === formik.values.confirmPswrd) {
        setLoading(true);
        changePassword(tempObj, (success) => {
          if (success.data.code === 200 && success.data.status === "success") {
            console.log(success.data.data);
            swal("", success.data.data, "success");
            setLoading(false);
          }
          if (success.data.code === 400 && success.data.status === "failed") {
            console.log(success.data.data);
            swal("", success.data.data, "error");
            setLoading(false);
          }
          //   console.log(success);
          // navigate("/agent-dashboard/Agentprofile")
          // swal("", success, "success");
          // setLoading(false)
          // props.ChangeTab("seven")
        });
      } else {
        swal("", "Passwords did not matched", "error");
      }
    },
  });

  function sumbitProfilePassword() {
    let tempObj = {};
    let userDetails = getToken();
    tempObj["user_id"] = userDetails.userId;
    tempObj["Create_Admin_Password"] = true;
    tempObj["profile_password"] = profilePassword;
    console.log(tempObj);
    setLoading(true);
    submitProfilePassword(tempObj, (success) => {
      //   console.log(success);
      // swal("", success, "success");
      setLoading(false);
      toast.success(success, "success");
      userDetails.profile.is_profilepassword = true;
      setUserDetails(userDetails);
      setUpdatePass(!updatePass);
      // props.ChangeTab("seven")
    });
  }

  function removeProfilePassword() {
    let userDetails = getToken();
    let tempObj = {
      user_id: userDetails.userId,
      profile_password: backendPass,
    };
    setLoading(true);
    removePasssword(tempObj, (success) => {
      if (success.code === 200 && success.status === "success") {
        setLoading(false);
        setConfirmPassModal(false);
        setBackendPass("");
        toast.success(success.data, "success");
        userDetails.profile.is_profilepassword = false;
        setUserDetails(userDetails);
        setProfilePassword("");
        setUpdatePass(!updatePass);
      }
      if (success.code === 400 && success.status === "failed") {
        setLoading(false);
        toast.error(success.data);
      }
    });
  }

  function getData() {
    getProfilePasswordStatus((success) => {
      //   console.log("Profilepswrd", success[0].is_profilepassword);
      setdisplayCreateAdminInputSection(success[0].is_profilepassword);
      setDisplayProfileInput(!success[0].is_profilepassword);
      let userDetails = getToken();
      if (userDetails?.profile.is_profilepassword) {
        setdisplayCreateAdminInputSection(
          userDetails?.profile.is_profilepassword
        );
        setDisplayProfileInput(!userDetails?.profile.is_profilepassword);
      } else {
        console.log(" deueud ");
      }
    });
  }
  useEffect(() => {
    getData();
  }, [updatePass]);

  useEffect(() => {}, [displayCreateAdminInputSection]);

  // email verification for forget password

  const handelEmailVerification = () => {
    const userVal = {
      email: profilePassEmail,
    };

    if (profilePassEmail !== "") {
      setLoading(true);
      ProfileSettingForgetPassword(userVal, (success) => {
        console.log(success);
        if (success.data.code == 200 && success.data.status == "success") {
          toast.success(success.data.data, "success");
          setLoading(false);
          setProfilePassEmail("");
          setProfilePassEmailError("");
          // Navigate('/profile-settings/reset-password')
        }
        if (success.data.code == 400 && success.data.status == "failed") {
          console.log(success.data.data);
          setLoading(false);
          setProfilePassEmailError(
            "User with this Email id is not found",
            success.data.data,
            "error"
          );
        }
      });
    } else {
      setProfilePassEmailError("*Plese Enter Email-Id");
    }
  };
  return (
    <>
      {loading ? <Loader /> : null}
      <div>
        <div className="password_pagpading">
          <div className="row old-pass">
            <div className="col-md-12 Passwordsec_text">
              Password & security
            </div>
            <div className="col-md-12">
              <div className="d-flex main_input ">
                <div className="input_name grow-1 text-end input_namepad">
                  Old Password
                  <span className="red-star">*</span>
                </div>
                <div className="input-sec">
                  <input
                    type={isRevealOldPwd ? "text" : "password"}
                    className="form-control border-0 pb-0"
                    name="oldPswrd"
                    onChange={formik.handleChange}
                    value={formik.values.oldPswrd}
                  />
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => setIsRevealOldPwd((prevState) => !prevState)}
                  >
                    {isRevealOldPwd ? (
                      <VisibilityOffOutlinedIcon />
                    ) : (
                      <RemoveRedEyeOutlinedIcon />
                    )}
                  </div>
                </div>
              </div>
              {formik.touched.oldPswrd && formik.errors.oldPswrd ? (
                <div className="validation-text">{formik.errors.oldPswrd}</div>
              ) : null}
            </div>

            <div className="col-md-12">
              <div className="d-flex main_input">
                <div className="input_name grow-1 text-end input_namepad">
                  New Password <span className="red-star">*</span>
                </div>
                <div className="input-sec">
                  <input
                    type={isRevealNewPwd ? "text" : "password"}
                    className="form-control border-0 pb-0"
                    name="newPswrd"
                    onChange={formik.handleChange}
                    value={formik.values.newPswrd}
                  />
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => setIsRevealNewPwd((prevState) => !prevState)}
                  >
                    {isRevealNewPwd ? (
                      <VisibilityOffOutlinedIcon />
                    ) : (
                      <RemoveRedEyeOutlinedIcon />
                    )}
                  </div>
                </div>
              </div>
              {formik.touched.newPswrd && formik.errors.newPswrd ? (
                <div className="validation-text">{formik.errors.newPswrd}</div>
              ) : null}
            </div>
            <Validation formikPass={formik.values.newPswrd} />
            <div className="col-md-12">
              <div className="d-flex main_input" style={{ marginTop: "14px" }}>
                <div className="input_name grow-1 text-end input_namepad">
                  Confirm Password<span className="red-star">*</span>
                </div>
                <div className="input-sec">
                  <input
                    type={isRevealConfPwd ? "text" : "password"}
                    className="form-control border-0 pb-0"
                    name="confirmPswrd"
                    onChange={formik.handleChange}
                    value={formik.values.confirmPswrd}
                  />
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      setIsRevealConfPwd((prevState) => !prevState)
                    }
                  >
                    {isRevealConfPwd ? (
                      <VisibilityOffOutlinedIcon />
                    ) : (
                      <RemoveRedEyeOutlinedIcon />
                    )}
                  </div>
                </div>
              </div>
              {formik.touched.confirmPswrd && formik.errors.confirmPswrd ? (
                <div className="validation-text">
                  {formik.errors.confirmPswrd}
                </div>
              ) : null}
            </div>
            <div className="col-md-12 text-end mt-1 ">
              <button
                className="change-passbtnpad change-passbtn change-passborder"
                type="submit"
                onClick={formik.handleSubmit}
              >
                Change Password
              </button>
            </div>
            <div className="col-md-12 input_name pb-4 d-flex">
              <input
                id="adminpass"
                type="checkbox"
                checked={displayCreateAdminInputSection}
                onChange={(e) => {
                  setdisplayCreateAdminInputSection(e.target.checked);
                }}
              />
              <label htmlFor="adminpass" className="ps-1 pointer">
                Create Admin Password
              </label>
            </div>
            {displayCreateAdminInputSection ? (
              <>
                {displayProfileInput ? (
                  <>
                    <div className="col-md-12">
                      <div className="d-flex main_input">
                        <div className="input_name grow-1 text-end input_namepad">
                          Password<span className="red-star">*</span>
                        </div>
                        <div className="input-sec">
                          <input
                            type={isRevealProfilePaswd ? "text" : "password"}
                            className="form-control border-0 pb-0"
                            onChange={(e) => {
                              setProfilePassword(e.target.value);
                            }}
                            value={profilePassword}
                          />
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              setIsRevealProfilePaswd((prevState) => !prevState)
                            }
                          >
                            {isRevealProfilePaswd ? (
                              <VisibilityOffOutlinedIcon />
                            ) : (
                              <RemoveRedEyeOutlinedIcon />
                            )}
                          </div>
                        </div>
                      </div>
                      <p className="validation-text log-validation">
                        {profilePassword.length > 3 &&
                          (Passcheck.test(profilePassword)
                            ? ""
                            : "Please Enter Valid Password")}
                      </p>
                      <Validation formikPass={profilePassword} />
                    </div>
                  </>
                ) : null}

                <div className="d-flex justify-content-end py-4">
                  {!displayProfileInput ? (
                    <div className="">
                      <div className="text-end mb-3">
                        <p
                          className="change-passbtn pointer"
                          onClick={() => setModalShow(true)}
                        >
                          <u>Forgot Password</u>
                        </p>
                      </div>

                      <button
                        className="remove_border px-4 py-2 "
                        onClick={() => {
                          setConfirmPassModal(true);
                          //  removeProfilePassword()
                        }}
                      >
                        Remove Backend Password
                      </button>
                    </div>
                  ) : (
                    <>
                      <button
                        className="change-passbtn change-passborder px-4 py-2"
                        onClick={() => {
                          sumbitProfilePassword();
                        }}
                        disabled={!Passcheck.test(profilePassword)}
                      >
                        Submit
                      </button>
                    </>
                  )}
                </div>
              </>
            ) : null}
          </div>
        </div>
        {/* <ChangePassword /> */}
      </div>
      <div className="forgot_modals">
        <Modal
          className="forgot_modals"
          show={modalShow}
          onHide={() => setModalShow(false)}
          size="lg"
          ria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header
            closeButton
            className="border-bottom-0 forgotmodal_body gdgyg"
          >
            <Modal.Title
              id="contained-modal-title-vcenter "
              className="border-0"
            >
              <div className="forgot_title">Forgot Password</div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="px-5 pt-2 forgotmodal_body">
            <div className="forgot_small_text pb-4">
              Lost your password? Please enter your username or email address.
              You will receive a link to create a new password via email.
            </div>
            <div className="d-flex main_input">
              <div className="input_name grow-1 text-end input_namepad">
                Email<span className="red-star">*</span>
              </div>
              <div className="input-sec">
                <input
                  type="email"
                  className="form-control border-0 pb-0"
                  name="profilePassEmail"
                  autoComplete="off"
                  onChange={(e) => setProfilePassEmail(e.target.value)}
                  value={profilePassEmail}
                />
              </div>
              {profilePassEmailError && profilePassEmailError ? (
                <div className="validation-text log-validation">
                  {profilePassEmailError}
                </div>
              ) : null}
            </div>
            <div className="text-end mb-3">
              <button
                type="submit"
                className="submit_btn"
                onClick={handelEmailVerification}
                disabled={!emailRegex.test(profilePassEmail)}
              >
                Reset Password
              </button>
            </div>
            <div>
              <NavLink to="/" className="text-decoration-none text-center mb-1">
                <p>
                  Already have an account ? Click to <span>Log in</span>{" "}
                  instantly
                </p>
              </NavLink>
            </div>
          </Modal.Body>
        </Modal>
      </div>

      {confirmPassModal && (
        <ConfirmPassword
          setConfirmPassModal={setConfirmPassModal}
          setBackendPass={setBackendPass}
          backendPass={backendPass}
          removeProfilePassword={removeProfilePassword}
        />
      )}
    </>
  );
};

export default Passwordsec;
