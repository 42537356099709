import { useNavigate } from "react-router-dom";
import { AuthLayout } from "./AuthLayout";
import React from "react";

const SignUpText = () => {
  const navigate = useNavigate();

  return (
    <span className="loginpage_signup_text">
      Return to
      <span
        className="loginpage_signup_link pointer"
        onClick={() => navigate("/login")}
      >
        {" "}
        Sign In
      </span>
    </span>
  )
}

export const AuthPasswordLayout = ({ title, subtitle = null, text = <SignUpText />, children }) => {
  return (
    <AuthLayout className={'forget-page'}>
      <div className="loginpage_text">
        <div className="loginpage_text_head">{ title }</div>
        {
          subtitle
            ? (
              <div className="loginpage_text_subhead mt-2">
                <p>{ subtitle }</p>
              </div>
            )
            : null
        }
      </div>
      { children }
      <div className="loginpage_signup_help mb-1 mt-4 justify-content-center">
        { text }
      </div>
    </AuthLayout>
  )
}