import { useContext } from "react";
import { Client, Conversation, Participant } from "@twilio/conversations";
import { TwilioContext } from "./context";

export const useTwilioClient = () => {
  const client = useContext(TwilioContext);

  return {
    client,
    getOrCreateConversationByUniqueName: async (uniqueName: string, attributes = {}): Promise<Conversation> => {
      if (!(client instanceof Client)) throw 'Client not initialized';
      let conversation;

      try {
        conversation = await client.getConversationByUniqueName(uniqueName);
      } catch (_) {
        console.error('Conversation not found');
        conversation = await client.createConversation({
          uniqueName,
          attributes
        })
      }

      return conversation;
    },
    isParticipants: async (conversation: Conversation, identity: string) => {
      if (!(client instanceof Client)) throw 'Client not initialized';
      let participants: Participant[] = [];
      try {
        participants = await conversation.getParticipants();
      } catch (_) {
        console.log('Not participant yet')
      }
      return participants.some((participant) => participant.identity === identity);
    },
  };
}