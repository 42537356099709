import React, { useEffect, useState } from 'react';
import { FieldRenderProps } from 'react-final-form';

import { MenuItem } from '@mui/material';

import { CustumSelect } from '@components/pages/dashboard/components/filter-popup/PopUpFilter';
import { getLanguages } from '@components/pages/profile/components/personal/server';

export const RenderLanguageField: React.FC<FieldRenderProps<number[]>> = ({
  input: {onChange, ...input},
}) => {
  const [language, setLanguage] = useState([]);

  const handleChange = (event: any) => {
    console.log(event.target.value);
    onChange(event.target.value);
  };

  useEffect(() => {
    getLanguages((success: any) => {
      let languageList = success.map((item: any) => {
        return {
          id: item.id,
          label: item.languages_name,
        };
      });
      setLanguage(languageList);
    });
  }, []);

  return (
    <div className="form-group pb-3 mb-1">
      <label htmlFor="" className="lable_text">Language</label>
      <CustumSelect
        className="form_select mt-1"
        aria-label="Default select example"
        onChange={handleChange}
        {...input}>
        {language.length > 0 ? (
          language.map((item: any) => (
            <MenuItem key={`category-${item.id}`} value={item.id}>
              {item.label}
            </MenuItem>
          ))
        ) : (
          <MenuItem value={""}>No Option</MenuItem>
        )}
      </CustumSelect>
    </div>
  );
};
