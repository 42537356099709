import React, { useMemo } from "react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useFormik } from "formik";
import { useEffect } from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { getToken } from "../../../../../appVariable/variable";
import Loader from "../../../Loader/Loader";
import "./liancence.scss";
import { getBrokerageDetails, submitBrokerageDetails } from "./server";
import * as Yup from "yup";
import { setUserDetails } from '../../../../../REST/api';

function Liancence(props) {
  const [licenseTitle, setLicenseTitle] = useState("");
  const [loading, setLoading] = useState(false)
  const [licNumber, setLicNumber] = useState("")
  const [validate, setvalidate] = useState(true);
  const [rejected, setRejected] = useState(false);
  const [confirmationPopup, setConfirmationPopup] = useState(false)
  // const [licType, setLicType] = useState("")
  // const [brokerageName, setBrokerageName] = useState("")
  // const [estateSaleperson, setEstateSaleperson] = useState(false)
  // const [associateBrocker, setAssociateBrocker] = useState(false)
  // const [pricipleBrocker, setPricipleBrocker] = useState(false)
  // const [updatesidebarinLisen, setUpdatesidebarinLisen] = useState("in lisen")
  // const [updatesidebar, setUpdatesidebar] = useState(false)
  // const [licenseReject, setLicenseReject] = useState(null)
  // const [submitAfterconfirm, setSubmitAfterconfirm] = useState(false)
  const verifyState = useMemo(() => {
    let state = "";
    if (validate) state = 'verify-btn__success';
    if (rejected) state = 'verify-btn__rejected';

    return state;
  }, [validate, rejected]);

  const formik = useFormik({
    initialValues: { title: "", brokerageName: "", salePersonLicense: "", },
    validationSchema: Yup.object({
      title: Yup.string().required("Select License Type"),
      brokerageName: Yup.string().required("Enter Brokerage Name"),
      salePersonLicense: Yup.string().required("Enter License Number").nullable(),
    }),
    onSubmit: (values) => {
      let tempObj = {};
      let userDetails = getToken();
      tempObj["user_id"] = userDetails?.userId;
      tempObj["License_title"] = licenseTitle;
      tempObj["Brokerage_Name"] = formik.values.brokerageName;
      tempObj["Salespersons_License"] = formik.values.salePersonLicense;

      submitBrokerageDetails(tempObj, (success) => {
        setLoading(true)
        console.log(success);
        if (success.data.code === 200 && success.data.status === "success") {
          console.log("agenyt liceince added")
          setConfirmationPopup(false)
          toast.success(success.data.data, "success")
          setLoading(false)

          // userDetails.user_info.license_approve = true
          // localStorage.setItem("userDetails", JSON.stringify(userDetails));
          // props.ChangeTab("fourth")
        } else {
          if (success.data.data === "User Is Already Having A license") {
            setConfirmationPopup(false)
            toast.warning(success.data.data);
            setLoading(false)
          } else {
            // swal("", success, "warning");
            setConfirmationPopup(false)
            toast.warning(success.data.data)
            setLoading(false)
          }
        }
        let sidebar = { sidebar: true }
        localStorage.setItem("sidebar", JSON.stringify(sidebar));
        // setUpdatesidebarinLisen(true)
        props.setUpdatesidebar(!props.updatesidebar)
        userDetails.user_info.agent_profile = true
        setUserDetails(userDetails);
      });
    },
  });

  useEffect(() => {
    getBrokerageDetails((success) => {
      props.setShowLicense(success[2].license_number)
      setLicNumber(success[2].license_number)
      formik.setFieldValue("salePersonLicense", success[2].license_number)
      setRejected(success[2].is_rejected)
      setvalidate(success[2].is_validated)
      formik.setFieldValue("title", success[2].lic_Type)
      // setLicenseReject(success[2].is_rejected)
      // setLicType(success[2].lic_Type)
      // setBrokerageName(success[2].brokerage_name)
      formik.setFieldValue("brokerageName", success[2].brokerage_name)

      // if (success[2].lic_Type === "Lic. Real Estate Salesperson") {
      //   setEstateSaleperson(true)
      // }
      // if (success[2].lic_Type === "Lic. Associate Broker") {
      //   setAssociateBrocker(true)
      // }
      // if (success[2].lic_Type === "Lic. Real Estate Principal Broker") {
      //   setPricipleBrocker(true)
      // }
    })
  }, [])

  // let radiobtn = ["Lic. Real Estate Salesperson", "Lic. Associate Broker", "Lic. Real Estate Principal Broker"]
  return (
    <>
      <div>
        {loading ? <Loader /> : null}
        <div>

          <div className="personal_info_pading">
            {/* {(!licType) ?
              <>
                <div className="row">
                  <div className="col-md-4 pe-md-0">
                    <div className="radio_button_text ">
                      Agent Broker License Title<span className="red-star">*</span> :
                    </div>
                  </div>
                  <div className="col-md-4 abg ">
                    <div className="radio_button_text">
                      <input type="radio" name="a" id="btncheck1"
                        value={formik.values.title}
                        className="me-1"
                        onChange={(e) => {
                          setLicenseTitle("Lic. Real Estate Salesperson");
                          formik.setFieldValue("title", "Lic. Real Estate Salesperson")
                        }}
                      // defaultChecked={estateSaleperson}
                      />
                      <label htmlFor="btncheck1">Lic. Real Estate Salesperson </label>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="radio_button_text">
                      <input
                        type="radio"
                        name="a"
                        id="btncheck2"
                        value={formik.values.title}
                        className="form-check-label me-1"
                        onChange={(e) => {
                          setLicenseTitle("Lic. Associate Broker");
                          formik.setFieldValue("title", "Lic. Associate Broker")
                        }}
                      // defaultChecked={false}
                      />
                      <label htmlFor="btncheck2">Lic. Associate Broker</label>
                    </div>
                  </div>
                </div>
                <div className="row ">
                  <div className="col-sm-4"></div>
                  <div className="col-md-4">
                    <div className="radio_button_text">
                      <input type="radio" name="a" id="btncheck3"
                        value={formik.values.title}
                        className="me-1"
                        onChange={(e) => {
                          setLicenseTitle("Lic. Real Estate Principal Broker");
                          formik.setFieldValue("title", "Lic. Real Estate Principal Broker")
                        }}
                      // defaultChecked={false}
                      />
                      <label htmlFor="btncheck3"> Lic. Real Estate Principal Broker </label>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    {formik.errors.title ? (
                      <div className='validation-text'>{formik.errors.title}</div>
                    ) : null}
                  </div>
                </div>

              </>
              :
              <div className="row">
                <div className="col-md-4 pe-md-0">
                  <div className="radio_button_text ">
                    Agent Broker License Title<span className="red-star">*</span> :
                  </div>
                </div>
                <div className="col-md-4 abg ">
                  <div className="radio_button_text">
                    <label htmlFor="btncheck5">{licType}</label>
                  </div>
                </div>
              </div>
            } */}

            <>
              <div className="row">
                <div className="col-md-4 pe-md-0">
                  <div className="radio_button_text ">
                    Agent Broker License Title<span className="red-star">*</span> :
                  </div>
                </div>
                <div className="col-md-4 abg ">
                  <div className="radio_button_text">
                    <input type="radio" name="a" id="btncheck1"
                      value={formik.values.title}
                      className="me-1"
                      onChange={(e) => {
                        setLicenseTitle("Lic. Real Estate Salesperson");
                        formik.setFieldValue("title", "Lic. Real Estate Salesperson")
                      }}
                      checked={formik.values.title == "Lic. Real Estate Salesperson"}
                    />
                    <label htmlFor="btncheck1">Lic. Real Estate Salesperson </label>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="radio_button_text">
                    <input
                      type="radio"
                      name="a"
                      id="btncheck2"
                      value={formik.values.title}
                      className="form-check-label me-1"
                      onChange={(e) => {
                        setLicenseTitle("Lic. Associate Broker");
                        formik.setFieldValue("title", "Lic. Associate Broker")
                      }}
                      checked={formik.values.title == "Lic. Associate Broker"}
                    />
                    <label htmlFor="btncheck2">Lic. Associate Broker</label>
                  </div>
                </div>
              </div>
              <div className="row ">
                <div className="col-sm-4"></div>
                <div className="col-md-4">
                  <div className="radio_button_text">
                    <input type="radio" name="a" id="btncheck3"
                      value={formik.values.title}
                      className="me-1"
                      onChange={(e) => {
                        setLicenseTitle("Lic. Real Estate Principal Broker");
                        formik.setFieldValue("title", "Lic. Real Estate Principal Broker")
                      }}
                      checked={formik.values.title == "Lic. Real Estate Principal Broker"}
                    />
                    <label htmlFor="btncheck3"> Lic. Real Estate Principal Broker </label>
                  </div>
                </div>
                <div className="col-sm-4">
                  {formik.errors.title ? (
                    <div className='validation-text'>{formik.errors.title}</div>
                  ) : null}
                </div>
              </div>

            </>


            <div className="d-flex flex-wrap justify-content-center"></div>
            <div className="row main_inputpading">
              <div className="col-sm-9 d-flex flex-column gap-4">
                <div>
                  <div className="d-flex main_input">
                    <div className="input_name grow-1 text-end">
                      Brokerage Name <span className="red-star">*</span>
                    </div>
                    <div className="input-sec">
                      <input type="text" className="form-control border-0 pb-0"
                        value={formik.values.brokerageName}
                        // value={(brokerageName && !licenseReject) ? brokerageName : formik.values.brokerageName}
                        onChange={(e) => {
                          formik.setFieldValue("brokerageName", e.target.value)
                        }}
                      />
                    </div>
                  </div>
                  {formik.errors.brokerageName ? (
                    <div className='validation-text'>{formik.errors.brokerageName}</div>
                  ) : null}
                </div>

                <div>
                  <div className="d-flex main_input">
                    <div className="input_name grow-1 text-end">
                      License<span className="red-star">*</span>
                    </div>
                    <div className="input-sec">
                      <input type="email" className="form-control border-0 pb-0"
                        value={formik.values.salePersonLicense}
                        // value={(licNumber && !licenseReject) ? licNumber : formik.values.salePersonLicense}
                        onChange={(e) => {
                          formik.setFieldValue("salePersonLicense", e.target.value)
                        }}
                      />
                    </div>
                  </div>
                  {formik.errors.salePersonLicense ? (
                    <div className='validation-text'>{formik.errors.salePersonLicense}</div>
                  ) : null}
                </div>
              </div>
              <div className="col-sm-3 d-flex  justify-content-center pt-5">
                <div className="d-flex flex-column justify-content-center">
                  {/* <button className="btn verify-btn">Verify </button> */}
                  {/* <button className="btn Save-btn"
                  type="submit"
                  onClick={formik.handleSubmit}
                  disabled={!validate}
                >Verify </button> */}


                  {/* <button className="btn verify-btn"
                    type="submit"
                    onClick={formik.handleSubmit}
                    // disabled={!validate}
                    disabled={licNumber}
                  >Verify </button> */}

                  <button className={`btn verify-btn ${verifyState}`}
                    // type="submit"
                    // onClick={formik.handleSubmit}
                    onClick={() => {
                      if (Object.keys(formik.errors).length === 0 && formik.values.salePersonLicense !== "") { setConfirmationPopup(true) }
                    }
                    }
                  // disabled={licNumber && !licenseReject}
                  >Verify </button>
                </div>
              </div>
            </div>
          </div>


        </div>

        {
          licNumber && !validate &&
          <div className="personal_info_pading">
            <div className="radio_button_text " style={{ color: "orange" }}><p className='validation-text'><u>License number {licNumber} is pending for approval</u></p></div>
          </div>
        }
      </div >
      <div className="">
        <Modal show={confirmationPopup} size="lg" centered aria-labelledby="contained-modal-title-vcenter "
          className='nbsp-modal'
          onHide={() => setConfirmationPopup(false)}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body className='security_body' >
            <div className="addon-plan-modal center gap-3 flex-column ">
              <h4 className=" text-center">Confirm your Brokerage Name & Salespersons License</h4>
              {/* <p className=' text-center'>You can apply for Salespersons License only one time!</p> */}
              <div>
                <p className=' licence-confirmPopup'>Brokerage Name - {formik.values.brokerageName}</p>
                <p className=' licence-confirmPopup'>Salespersons License - {formik.values.salePersonLicense}</p>
              </div>


              <div className="d-flex w-100 gap-3 justify-content-center pb-3">
                <button type="submit" className="suspend_btn" style={{ minWidth: '150px' }}
                  // onClick={() => setSubmitAfterconfirm(true)}
                  onClick={formik.handleSubmit}
                >Continue</button>
                <button type="submit" className="suspend_btn" style={{ minWidth: '150px' }}
                  onClick={() => setConfirmationPopup(false)}
                >Go Back</button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

      </div>
      {/* <ToastContainer /> */}
    </>
  );
}

export default Liancence;
