import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./main.scss";
import search from "./images/search.svg";
import Button from "react-bootstrap/Button";
import PropertyCard from "./PropertyCard";
import PopUpFilter from "../filter-popup/PopUpFilter";
import {
  getAllpropertyInfo,
  saveDefaultPropertyShow,
  MessageForAgent,
} from "./server";
import { getToken } from "../../../../../appVariable/variable";
import {
  getLocations,
  getCategories,
  getTypesBasedOnCategory,
  getTypeOfProperties,
} from "../../../homepage/components/hero/server";
import { getSaveSearchFilterPropertyDetail } from "../../../saveSearch/Server";
import { useDispatch, useSelector } from "react-redux";
import PropertySavePopUp from "./PropertySavePopUp";
import SaveSearchModel from "../filter-popup/SaveSearchModel";
import { Autocomplete, IconButton, TextField } from '@mui/material';
import PropertyDetailsPopUp from "../../propperty-datails/PropertyDetailsPopUp";
import { updatePropertyDatail } from "../../propperty-datails/PropertySlice";
import { cleanFilterData, updateFilterData } from '../../../homepage/components/hero/FilterSlice';
import { toast } from "react-toastify";
import SelectGeustAccType from "../select-guestAcc-type/SelectGeustAccType";
import Loader from "../../../Loader/Loader";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import TuneIcon from '@mui/icons-material/Tune';
import { search2Actions } from '@bus/search2/actions';
import { getIsSearch } from '@bus/search2/selectors';
import { getAccounttype } from '@components/pages/personal/typeOfAccount/server';
import { book } from '@routes/book';

const PinkSwitch = styled(Switch)(({ theme }) => ({
  ".MuiSwitch-track": {
    backgroundColor: "#315EFB",
    opacity: "1",
    height: "120%",
    transform: "translate(0px, -1px)",
  },
  ".MuiSwitch-thumb": {
    color: "white",
    border: "1px solid rgba(88, 86, 86, 1)",
  },
  "span.MuiSwitch-track .css-1yjjitx-MuiSwitch-track": {
    opacity: "1",
  },
}));
const label = { inputProps: { "aria-label": "Switch demo" } };
const Main = ({
  setViewsetting,
  viewsetting,
  handleSearch,
  allProperties,
  setAllProperties,
  setshowSetAccPopup,
  showSetAccPopup,
  setloading,
  loading,
  buyRentFilter,
  setSearchQuery,
  optionsList,
}) => {
  const dispatch = useDispatch();
  const containerRef = useRef(null);
  const token = getToken();
  const userId = token?.userId;
  const [showFilter, setShowFilter] = useState(false);
  const FilterDataInStore = useSelector((state) => state.FilterDataInStore);
  const saveSearchPopupVisible = useSelector(
    (state) => state.search.saveSearchPopupVisible
  );
  const propertyDetailInStore = useSelector(
    (state) => state.propertyDetailInStore
  );

  const [propertyDetailInfo, setPropertyDetailInfo] = useState({
    propertyImg: [],
  });
  const [removeUnsaveBtn, setRemoveUnsaveBtn] = useState(false);
  const [selectAllMsg, setSelectAllMsg] = useState(false);
  const [messageForAdmin, setMessageForAdmin] = useState("");
  const filter = useSelector((state) => state.FilterDataInStore);
  const [value, setValue] = useState('');
  const handelAllSaveListingProperty = () => {
    if (userId) {
      let filteredArray = [...FilterDataInStore.Filter_responce];
      let savepayload = {
        user_id: userId,
        notes: null,
        rating: 0,
        property_details_id: filteredArray.map((property) => property.id),
      };
      saveDefaultPropertyShow(savepayload, (success) => {
        if (success.code === 200) {
          const updatedData = FilterDataInStore.Filter_responce.map(
            (property) => {
              return { ...property, guest_users_save_listing: true };
            }
          );
          dispatch(updateFilterData({
            Filter_responce: updatedData,
            Neighborhood: updatedData,
          }));
        }
      });
    } else {
      alert("please login");
    }
  };
  let RemoveUnsave = async () => {
    if (userId) {
      if (removeUnsaveBtn) {
        dispatch(updateFilterData({
          Filter_responce: allProperties,
          Neighborhood: allProperties,
        }));
        setRemoveUnsaveBtn((pre) => !pre);
      } else {
        setRemoveUnsaveBtn((pre) => !pre);
        let allProperty = [...FilterDataInStore.Filter_responce];
        setAllProperties(allProperty);
        const filteredData = allProperty.filter(
          (obj) => obj.guest_users_save_listing === true
        );
        dispatch(updateFilterData({
          Filter_responce: filteredData,
          Neighborhood: filteredData,
        }));
      }
    } else {
      alert("please login");
    }
  };
  let propertDetails = async (id) => {
    setloading(true);
    dispatch(
      updatePropertyDatail({ propertyId: id, setshowPropertyPopup: true })
    );
    await getAllpropertyInfo(id, (success) => {
      dispatch(updatePropertyDatail({ PropertyDetailInfo: success }));
      setPropertyDetailInfo(success);
      setPropertyDetailInfo((state) => {
        return state;
      });
      setloading(false);
    });
  };
  const handleDropdownChange = (event) => {
    const value = event.target.value;
    let filteredArray = [...FilterDataInStore.Filter_responce];
    if (value === "lowToHigh") {
      filteredArray.sort(
        (a, b) => a.property_listing_amount - b.property_listing_amount
      );
    } else if (value === "highToLow") {
      filteredArray.sort(
        (a, b) => b.property_listing_amount - a.property_listing_amount
      );
    }
    dispatch(updateFilterData({
      Filter_responce: filteredArray,
      Neighborhood: filteredArray,
    }));
  };
  const SendSmgToagent = () => {
    if (selectAllMsg) {
      if (messageForAdmin) {
        let filteredArray = [...FilterDataInStore.Filter_responce];
        let obj = {
          Agent_id: filteredArray.map((property) => property.user_profile?.id),
          user_id: 2,
          message: messageForAdmin,
          property_id: filteredArray.map((property) => property.id),
        };
        MessageForAgent(obj, (success) => {
          if (success.code === 200) {
            toast.success(success.data);
            setMessageForAdmin("");
            setSelectAllMsg(false);
          }
        });
      } else {
        toast.warning("Please Enter Mesaage for Broker");
      }
    } else {
      toast.warning("Please Check Select All box");
    }
  };
  const locationOnChange = (e) => {
    dispatch(
      updateFilterData({
        locationText: e?.label,
        area: e?.value?.area_id ? e?.value?.area_id : null,
        city: e?.value?.city_id,
        state: e?.value?.state_id,
        country: e?.value?.country_id,
      })
    );

    setValue(e);
  };
  useEffect(() => {
    if (optionsList.length) {
      const address = optionsList.find((item) => {
        if (filter.area) {
          return item.value.area_id === filter.area;
        }
        if (!filter.area && filter.city) {
          return item.value.city_id === filter.city && !item.value.area_id;
        }
      });

      setValue(address ?? '');
    }
  }, [optionsList.length]);
  let SearchByLoaction = () => {
    if (FilterDataInStore.property_listing) {
      const blockedKeys = [
        "locationText",
        "Filter_responce",
        "filter_name",
        "Neighborhood",
      ];
      const searchSchema = { ...FilterDataInStore };
      blockedKeys.forEach((key) => delete searchSchema[key]);
      handleSearch(searchSchema, !!searchSchema.area ? `${book.dashboard}/${book.dashboardPropertyDetails}` : '');
    }
  };
  const handleChangeType = (event) => {
    const newCategory = +event.target.value;
    dispatch(updateFilterData({ category: newCategory }));

    getTypesBasedOnCategory(
      newCategory,
      (success) => {
        dispatch(updateFilterData({ type: success[0]?.id }));
        handleSearch({ ...filter, category: newCategory, type: success[0]?.id });
      },
    );
  };

  return (
    <>
      {loading ? <Loader /> : null}
      <div
        className="main-sec d-flex flex-column flex-grow-1"
        style={{ position: "relative" }}
      >
        {/* main-top-section */}
        <div className="filter-sec d-flex justify-content-between gap-1">
          {/* chech box */}
          <div className="d-flex flex-row gap-3">
            <div className="buy-rent gap-3 d-flex align-items-center">
              {buyRentFilter?.map((type, i) => (
                <div className="d-flex" key={`filetr${i}`}>
                  <input
                    type="radio"
                    name="PropType"
                    className="Prop pointer"
                    id={type.user_listing_type}
                    value={type.id}
                    onChange={handleChangeType}
                    checked={filter.category === type.id}
                  />
                  <label htmlFor={type.user_listing_type} className="pointer">
                    {type.user_listing_type}
                  </label>
                </div>
              ))}
            </div>

            {/* Search box */}
            <div className="search-box d-flex flex-grow-1 align-items-center">
              <div className="search d-flex">
                <Autocomplete
                  className="hide-downBtn"
                  id="combo-box-demo"
                  disablePortal
                  options={optionsList}
                  style={{ minWidth: "250px", width: "100%", zIndex: 9999 }}
                  value={value}
                  onChange={(e, value) => {
                    locationOnChange(value);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      placeholder={'Search Town or City'}
                      onChange={(e) => {
                        setSearchQuery(e.target.value);
                      }}
                    />
                  )}
                />
                <IconButton
                  className="search-icon pointer"
                  onClick={() => SearchByLoaction()}>
                  <img src={search} alt="" />
                </IconButton>
              </div>
              <div
                className="filter-icon pointer d-flex align-items-center justify-content-center"
                onClick={() => setShowFilter(true)}
              >
                {" "}
                <TuneIcon fontSize={'small'} htmlColor={'#315EFB'} />
              </div>
            </div>
          </div>

          {/* quik-view map-view */}
          <div className="quik-map center gap-1">
            <p>Quick View</p>
            <div className="form-check-switch pt-0">
              <PinkSwitch
                {...label}
                onChange={(e) => {
                  setViewsetting(e.target.checked);
                }}
                checked={viewsetting}
              />
            </div>
            <p>Map View</p>
          </div>
        </div>

        {/* button row */}
        <div className="button-row  d-flex justify-content-between">
          <div className="button-row-left d-flex ">
            <Button
              variant="outline-primary"
              className={`box-shodow text-capitalize ${
                removeUnsaveBtn ? "active" : ""
              }`}
              onClick={() => RemoveUnsave()}
            >
              {removeUnsaveBtn ? "Show All Listing" : "Remove Unsaved Listing"}
            </Button>
            <Button
              variant="outline-primary"
              className="box-shodow "
              onClick={handelAllSaveListingProperty}
            >
              Save All Listing
            </Button>
          </div>
          <div className="sort-by center gap-2">
            <p className=" text-nowrap">Sort by :</p>
            <select
              aria-label="Default select example"
              className="box-shodow "
              onChange={handleDropdownChange}
            >
              <option className=" d-none">Price</option>
              <option value="lowToHigh">Price: Low to High</option>
              <option value="highToLow">Price: High to Low</option>
            </select>
          </div>
        </div>

        <div className="d-flex flex-column gap-2" style={{ height: 'calc(100% - 8rem)' }}>
          {/* feed section */}
          <div
            className="property-feed"
            ref={containerRef}
            style={{
              height: '100%',
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            {FilterDataInStore &&
              FilterDataInStore.Filter_responce?.map((city, id) => (
                <PropertyCard
                  key={`mainpropertyd${id}`}
                  agentListingData={city}
                  handelOpenPopUp={propertDetails}
                />
              ))}
          </div>

          {/* message box */}
          <div className="message-sec buy-rent d-flex flex-column gap-2">
            <div>
              <div className="d-flex " style={{ marginBottom: "10px" }}>
                <input
                  type="checkbox"
                  name="PropType"
                  className="Prop"
                  id="Select"
                  // value="Select"
                  onChange={(e) => {
                    setSelectAllMsg(e.target.checked);
                  }}
                  checked={selectAllMsg}
                />
                <label htmlFor="Select" className="pointer">
                  {" "}
                  Select All{" "}
                </label>
              </div>
              <div className="message-box d-flex justify-content-between ">
                <input
                  type="text"
                  className="trans-input"
                  name=""
                  id=""
                  placeholder="Add your message"
                  onChange={(e) => setMessageForAdmin(e.target.value)}
                  value={messageForAdmin}
                />
                <Button variant="primary" onClick={() => SendSmgToagent()}>
                  Send
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* property detail pop up  */}
      {propertyDetailInStore.setshowPropertyPopup &&
        !loading &&
        propertyDetailInfo?.propertDetails?.property_address_1 !==
          undefined && (
          <PropertyDetailsPopUp
            setPropertyDetailInfo={setPropertyDetailInfo}
            propertyDetailInfo={propertyDetailInfo} // passing info about
            showTravelview={true}
          />
        )}

      {/* property save pop up  */}
      {propertyDetailInStore.setSavePropertyPopup && <PropertySavePopUp />}

      {showFilter && <PopUpFilter setShowFilter={setShowFilter} />}

      {/* <SaveSearchpopup /> */}
      {saveSearchPopupVisible && <SaveSearchModel />}

      {/* when guest user come first time then this popup show to select his acount type */}
      {showSetAccPopup && (
        <SelectGeustAccType setshowSetAccPopup={setshowSetAccPopup} />
      )}
    </>
  );
};

//hello ankush
export default Main;
