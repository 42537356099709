import { useSelector } from "react-redux";
import { GetParticipantTyping } from "../../../../store/selectors/message.selector";
import { Conversation } from "@twilio/conversations";
import { useMemo } from "react";
import { useParticipantAttributes } from "@components/pages/Message/hooks/useParticipant";
import { getToken } from "../../../../appVariable/variable";

export const useParticipantTyping = (conversation: Conversation | null) => {
	const token = getToken();
	const participantTyping = useSelector(GetParticipantTyping);

	const [participantIdentity] = useMemo(() => {
		if (!conversation) return [];
		return participantTyping.filter(
			({ conversation_sid, identity }) =>
				conversation_sid === conversation.sid && identity !== token.user_info.identity
		);
	}, [conversation, participantTyping, token]);
	const attributes = useParticipantAttributes(participantIdentity?.identity)

	return participantIdentity ? attributes : null;
}