const currentURL = window.location.href;
const url = new URL(currentURL);
// it would look like "https://www.example.com" or "http://localhost:3000" depending on the current URL.
export const Hosturl = `${url.protocol}//${url.hostname}${url.port ? `:${url.port}/` : '/'}`;

export const faceBookId = process.env.REACT_APP_FACEBOOK_ID;
export const googleId = process.env.REACT_APP_GOOGLE_ID

export function getToken() {
    const remember_me = JSON.parse(localStorage.getItem('remember_me') || 'false');
    if (remember_me) {
        return JSON.parse(localStorage.getItem('userDetails'));
    }

    return JSON.parse(sessionStorage.getItem('userDetails'));
}
export function getCityId() {
    let localCity = localStorage.getItem('residentailCityId');
    if (localCity === "undefined") {
        return null
    } else {
        let cityId = JSON.parse(localCity);
        return cityId;
    }

}
export function addOn() {
    let add_on = JSON.parse(localStorage.getItem('add_on'));
    return add_on
}

export function sidebarValue() {
    let sidebaractive = JSON.parse(localStorage.getItem('sidebar'));
    return sidebaractive
}


export function updateObject(key, newValue, obj) {
    let newObj = Object.assign({}, obj); // Make new object
    function updateKey(key, newValue, obj) {
        if (typeof obj !== 'object') return; // Basecase
        if (obj[key]) obj[key] = newValue;   // Look for and edit property
        else
            for (let prop in obj) {
                updateKey(key, newValue, obj[prop]); // Go deeper
            }
    }
    updateKey(key, newValue, newObj);
    return newObj;
}
