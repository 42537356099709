import React, { useState } from 'react';
import { styles } from './styles';
import { Box, Button, Grid } from '@mui/material';
import { getToken } from '../../../../../../../appVariable/variable';
import { saveloadCityAreaView, updateCityAreaView } from '@components/pages/neighbourhoodspecialist/server';
import { toast } from 'react-toastify';
import Typography from '@mui/material/Typography';
import {
  UploadFile
} from '@components/pages/neighbourhoodspecialist/component/NeighborhoodSpecialist/componants/UploadFile';
import { DocumentStatus, INeighborhoodTown } from '@bus/neighborhood/typedefs';
import {
  ConfirmRemove
} from '@components/pages/neighbourhoodspecialist/component/NeighborhoodSpecialist/componants/ConfirmRemove';

type PropertyFileProps = {
  item: INeighborhoodTown;
  cityName: string;
  status: number;
  handleChangeCheckedArea: any;
  setLoading: any
};

export const PropertyFile: React.FC<PropertyFileProps> = ({ item, cityName, status, handleChangeCheckedArea, setLoading }) => {
  const [doc1, setDoc1] = useState<File | null>(null);
  const [doc2, setDoc2] = useState<File | null>(null);
  const [doc3, setDoc3] = useState<File | null>(null);
  const [show, setShow] = useState(false);

  const formSave = () => {
    let formData = new FormData();
    let userDetails = getToken();
    formData.append('user', userDetails.userId);
    formData.append('area_id', JSON.stringify(item.area.id));
    doc1 && formData.append('doc1', doc1);
    doc2 && formData.append('doc2', doc2);
    doc3 && formData.append('doc3', doc3);
    setLoading(true);
    if (item.id) {
      updateCityAreaView(item.id, formData, (success: any) => {
        setLoading(false);
        handleChangeCheckedArea(success);
        setDoc1(null);
        setDoc2(null);
        setDoc3(null);
        toast.success('Documents uploaded successfully');
      });
    } else {
      saveloadCityAreaView(formData, (success: any) => {
        setLoading(false);
        handleChangeCheckedArea(success);
        setDoc1(null);
        setDoc2(null);
        setDoc3(null);
        toast.success('Documents uploaded successfully');
      });
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleOpen = () => {
    setShow(true);
  };

  return (
    <Box sx={styles.documentWrapper}>
      <Grid container alignItems={'center'}>
        <Grid item xs={4}>
          <Typography>{cityName}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Box display={'flex'} alignItems={'center'} gap={'10px'}>
            <UploadFile document={item.doc1 || doc1} name={'doc1'} addDocument={setDoc1} itemId={item.area.id} />
            <UploadFile document={item.doc2 || doc2} name={'doc2'} addDocument={setDoc2} itemId={item.area.id} />
            <UploadFile document={item.doc3 || doc3} name={'doc3'} addDocument={setDoc3} itemId={item.area.id} />
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Typography>{DocumentStatus[status as keyof typeof DocumentStatus]}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Box display={'flex'} gap={'5px'}>
            <Button sx={styles.button} type={'submit'} onClick={formSave} disabled={!doc1 || !doc2 || !doc3}>
              Submit
            </Button>
            <Button sx={styles.removeButton} onClick={handleOpen} disabled={!item.id}>
              Remove
            </Button>
          </Box>
        </Grid>
      </Grid>
      <ConfirmRemove show={show} handleClose={handleClose} id={item.id} />
    </Box>
  );
};
