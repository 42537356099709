import { getToken } from "../../../appVariable/variable";
import axiosInstance from '../../../REST/axiosInstance';

export function currentListingProperty({
    currentType,
    query,
    virtualOfficeId,
    url = 'api/property/UnExpiredListingProperty/'
  },
  success
) {
  let token = getToken();
  let dateobj = {
    propertylisting_type: currentType,
    query,
    virtual_office_id: virtualOfficeId,
  };
  axiosInstance
    .get(url, {
      params: dateobj,
      headers: { Authorization: `Bearer ${token?.idToken}` },
    })
    .then((response) => {
      if (response.status === 200) {
        success(response.data);
      } else {
        success({ next: null, previous: null, results: [] });
      }
    })
    .catch((error) => {
      console.log(error);
      success({ next: null, previous: null, results: [] });
    });
}

export async function RefreshCurrentListing(dataobj, success) {
  await axiosInstance
    .post(
      `api/property/ReafreshAPI/`,
      dataobj
    )
    .then((response) => {
      if (response.data.code === 200 && response.data.status === "success") {
        success(response.data);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

export function currentListingPropertyCount(paramObj, success) {
  let token = getToken();
  axiosInstance
    .get(`api/property/currentlistingcount/${paramObj}`, {
      headers: { Authorization: `Bearer ${token?.idToken}` },
    })
    .then((response) => {
      if (response.data.code === 200 && response.data.status === "success") {
        success(response.data.data);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function currentListingPropertyExpire(accountobj, success) {
  let token = getToken();
  await axiosInstance
    .put(`api/property/Update_is_property_expired/`, accountobj, {
      headers: { Authorization: `Bearer ${token?.idToken}` },
    })
    .then((response) => {
      if (response.data.code === 200 && response.data.status === "success") {
        success(response);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function CloseListing(accountobj, success) {
  let token = getToken();
  await axiosInstance
    .put(`api/property/ispropertyopen/`, accountobj, {
      headers: { Authorization: `Bearer ${token?.idToken}` },
    })
    .then((response) => {
      if (response.data.code === 200 && response.data.status === "success") {
        success(response.data);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

export function agentProfileCurrentListingProperty(paramObj, success) {
  let token = getToken();
  axiosInstance
    .get(`api/property/currentlisting/${paramObj}`, {
      headers: { Authorization: `Bearer ${token?.idToken}` },
    })
    .then((response) => {
      if (response.data.code === 200 && response.data.status === "success") {
        success(response.data.data);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

export function closeCurrentListingProperty(accountobj, success) {
  let token = getToken();
  axiosInstance
    .put(`api/property/ispropertyopen/`, accountobj, {
      headers: { Authorization: `Bearer ${token?.idToken}` },
    })
    .then((response) => {
      if (response.data.code === 200 && response.data.status === "success") {
        console.log(response.data.data);
        success(response);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}
export async function deleteCurrentListingProperty(accountobj, success) {
  let token = getToken();
  await axiosInstance
    .post(`api/property/deleteproperty/`, accountobj, {
      headers: { Authorization: `Bearer ${token?.idToken}` },
    })
    .then((response) => {
      if (response.data.code === 200 && response.data.status === "success") {
        console.log(response.data.data);
        success(response);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}
