import { getToken } from "../../../../../appVariable/variable"
import axiosInstance from '../../../../../REST/axiosInstance';
export function getLanguages(success) {
  let token = getToken()
  axiosInstance
    .get(`api/master/loadLanguageView/`, {
      headers: {
        'Authorization': `Bearer ${token?.idToken}`
      }
    })
    .then((response) => {
      if (response.data.code === 200 && response.data.status === "success") {
        success(response.data.data);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

export function getStates(success) {
  let token = getToken()
  axiosInstance
    .get(`api/master/state/`, {
      headers: {
        'Authorization': `Bearer ${token?.idToken}`
      }
    })
    .then((response) => {
      if (response.data.code === 200 && response.data.status === "success") {
        success(response.data.data);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}
export function getTowns(success) {
  let token = getToken()
  axiosInstance.get(`api/master/city/`, {
    headers: { 'Authorization': `Bearer ${token?.idToken}` }
  })
    .then((response) => {
      if (response.data.code === 200 && response.data.status === "success") {
        success(response.data.data);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

export function submitProfileData(formData, success) {
  let token = getToken()
  axiosInstance.put(`api/user/agentuserprofile/`, formData, {
    headers: { 'Authorization': `Bearer ${token?.idToken}` }
  })
    .then((response) => {
      if (response.data.code === 200 && response.data.status === "success") {
        success(response.data.data);
      }
    })
    .catch((error) => { success("error") });
}

export function getAgentDetails(paramObj, success) {
  let token = getToken()
  axiosInstance.get(`api/user/agentuserprofile/${Boolean(paramObj) ? paramObj : `?id=${token?.userId}`}`, {
    headers: { 'Authorization': `Bearer ${token?.idToken}` }
  })
    .then((response) => {
      if (response.data.code === 200 && response.data.status === "success") {
        success(response.data.data);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}
