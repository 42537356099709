import { getToken } from "../../../appVariable/variable";
import { getUserDetails } from '../../../REST/api';
import axiosInstance from '../../../REST/axiosInstance';

export function GetViertualContactInfo(success) {
  let token = getToken();
  axiosInstance
    .get(
      `api/virtual_office/GetVirtualTeamProfile/${token.userId}`,
      {
        headers: { Authorization: `Bearer ${token?.idToken}` },
      }
    )
    .then((response) => {
      success(response);
    })
    .catch((error) => {});
}

export function EditCustomUserProfileById(customerid, data, success) {
  let token = getToken();
  axiosInstance
    .put(
      `api/virtual_office/EditCustomUser/${customerid}`,
      data,
      {
        headers: { Authorization: `Bearer ${token?.idToken}` },
      }
    )
    .then((response) => {
      success(response);
    })
    .catch((error) => {
      console.error(error);
    });
}

export const SelectOffice = (success, error) => {
  let token = getToken();
  let localstorageData = JSON.parse(getUserDetails());
  let userinfo = localstorageData.user_info;
  const URL =
    userinfo.usertypeobj !== 1
      ? "VirtulaOfficenameShow"
      : "VitualOfficeUserName";

  axiosInstance
    .get(`api/virtual_office/${URL}/${token.userId}`, {
      headers: { Authorization: `Bearer ${token?.idToken}` },
    })
    .then((response) => {
      success(response);
    })
    .catch((err) => {
      error(err);
    });
};

export const getTeamMemberList = (id, success, error) => {
  let token = getToken();
  if(id !== undefined){
    axiosInstance
      .get(`api/virtual_office/GetVirtualTeamlist/${id}`, {
        headers: { Authorization: `Bearer ${token?.idToken}` },
      })
      .then((response) => {
        success(response);
      })
      .catch((err) => {
        error(err);
      });
  } else {
    error("can't get teammemberlist Id")
  }
};

export const getMemberInfo = (id, success, error) => {
  let token = getToken();
  if(id !== undefined){
    axiosInstance
      .get(`api/virtual_office/VirtualOfficeTeamProfile/${id}`, {
        headers: { Authorization: `Bearer ${token?.idToken}` },
      })
      .then((response) => {
        success(response);
      })
      .catch((err) => {
        error(err);
      });
  } else {
    error("can't find getMemberInfo id")
  }
};
export const sendPorperty = (data, success, error) => {
  let token = getToken();
  axiosInstance
    .post(`api/virtual_office/VirtualOfficePropertySend/`, data, {
      headers: { Authorization: `Bearer ${token?.idToken}` },
    })
    .then((response) => {
      success(response);
    })
    .catch((err) => {
      error(err);
    });
};

export const showProperty = (data, success, error) => {
  let token = getToken();
  axiosInstance
    .post(`api/virtual_office/ShowPropertyVirtualOffice/`, data, {
      headers: { Authorization: `Bearer ${token?.idToken}` },
    })
    .then((response) => {
      success(response);
    })
    .catch((err) => {
      error(err);
    });
};

export const interestedProperty = (prop, success, error) => {
  let token = getToken();
  axiosInstance
    .post(`api/virtual_office/PropertyInterestinAPI/`, prop, {
      headers: { Authorization: `Bearer ${token?.idToken}` },
    })
    .then((response) => {
      success(response);
    })
    .catch((err) => {
      error(err);
    });
};

export const notinterestedProperty = (prop, success, error) => {
  let token = getToken();
  axiosInstance
    .post(`api/virtual_office/PropertyDislikeinAPI/`, prop, {
      headers: { Authorization: `Bearer ${token?.idToken}` },
    })
    .then((response) => {
      success(response);
    })
    .catch((err) => {
      error(err);
    });
};
