import { FC, useMemo } from "react";
import Typography from "@mui/material/Typography";
import { UserDocument } from "@components/pages/Message/components/DocumentsPicker";

type AttachedDocsProps = {
	docs: UserDocument[];
	maxLength?: number;
	onClick: () => void;
}

export const AttachedDocs: FC<AttachedDocsProps> = ({ docs, maxLength = 40, onClick }) => {
	const text = useMemo(() => {
		const text =  docs.map(({ filename }) => filename).join(', ');
		return text.length <= maxLength ? text : text.slice(0, maxLength) + ' ...';
	}, [docs, maxLength]);

	if (!docs.length) return null;

	return (
		<Typography
			onClick={onClick}
			variant="caption"
			display="block"
			sx={{
				marginTop: '10px',
				marginLeft: '23px',
				color: 'text.secondary',
				cursor: 'pointer'
			}}
		>
			Attached: <span style={{ textDecoration: 'underline' }}>{ text }</span>
		</Typography>
	)
}