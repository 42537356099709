import React from 'react';
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';
export type AppLayoutProps = {};
export const AppLayout: React.FC<AppLayoutProps> = () => {

  return (
    <Box>
      <Outlet />
    </Box>
  );
};
