import React from 'react';
import { FieldRenderProps } from 'react-final-form';

import { Box, TextField } from '@mui/material';

import { getFieldError } from '@core/FinalForm/getFieldError';
import { styles } from '@components/forms/SubmitRequestForAdvertising/styles';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { getClassTypes } from '@bus/dashboard/selectors';

export const RenderClassTypeField: React.FC<FieldRenderProps<number>> = ({
  input: {value, onChange, ...input},
  meta,
}) => {
  const classTypes = useSelector(getClassTypes);

  return (
    <>
      {classTypes.map((item: any) => (
        <div key={item.id} className="d-flex gap-2">
          <input
            type={'radio'}
            className={'Prop'}
            id={`class-${item.id}`}
            value={item.id}
            checked={value === item.id}
            onChange={(event) => onChange(event.target.value)}
            {...input}
          />
          <label htmlFor={`class-${item.id}`} className="pointer">
            {item.title}
          </label>
        </div>
      ))}
    </>
  );
};
