import React, { useEffect, useState } from 'react'
import "./minute.scss"
import * as Yup from "yup";
import { useFormik } from 'formik';
import { getToken } from '../../../../../appVariable/variable';
import { DeactivateTime, getMinutedata, ThirtyMin } from './server';
import { toast } from 'react-toastify';
import Loader from '../../../Loader/Loader';
// import MinuteSlote from './MinuteSlote';
import SlotBook from './SlotBook';
import SelectedSlotModal from './SelectedSlotModal';
import Box from '@mui/material/Box';
import { v4 as uuidv4 } from 'uuid';

export let AllDays = [{ day: "mon", label: "MONDAY" }, { day: "tue", label: "TUESDAY" }, { day: "wed", label: "WEDNESDAY" }, { day: "thu", label: "THURSDAY" }, { day: "fri", label: "FRIDAY" }, { day: "sat", label: "SATURDAY" }, { day: "sun", label: "SUNDAY" }]


const Minute = () => {
    const [loading, setLoading] = useState(false)
    const [currentDay, setCurrentDay] = useState("sun")
    const [sunday, setSunday] = useState(false)
    const [monday, setMonday] = useState(false)
    const [tueday, setTueday] = useState(false)
    const [wedday, setWedday] = useState(false)
    const [thuday, setThuday] = useState(false)
    const [friday, setFriday] = useState(false)
    const [satday, setSatday] = useState(false)
    const [sunDayTime, setSunDayTime] = useState([{ start_time: '', end_time: '' }])
    const [monDayTime, setMonDayTime] = useState([{ start_time: '', end_time: '' }])
    const [tueDayTime, setTueDayTime] = useState([{ start_time: '', end_time: '' }])
    const [wedDayTime, setWedDayTime] = useState([{ start_time: '', end_time: '' }])
    const [thuDayTime, setThuDayTime] = useState([{ start_time: '', end_time: '' }])
    const [friDayTime, setFriDayTime] = useState([{ start_time: '', end_time: '' }])
    const [satDayTime, setSatDayTime] = useState([{ start_time: '', end_time: '' }])
    // const [activeDay, setActiveDay] = useState(sunDayTime)
    const [isActive, setIsActive] = useState(true)
    const [setshowAllTime, setSetshowAllTime] = useState(false)


    function checkEmptyArrays(obj) {
        return Object.values(obj).every(value => Array.isArray(value) && value.length === 0);
    }


    const handlesunday = (e, index) => {
        const { name, value } = e.target;
        // let Hours = (parseInt(value.split(':')[0]));
        // let Minutes = (parseInt(value.split(':')[1]));
        // var startTime = (new Date().setHours(Hours, Minutes, 0));
        // var startTime = new Date().setHours(GetHours(strStartTime), GetMinutes(strStartTime), 0);

        switch (currentDay) {
            case "sun":
            {
                const list = [...sunDayTime];
                list[index][name] = value;
                setSunDayTime(list);
            }
                break;
            case "mon":
            {
                const list = [...monDayTime];
                list[index][name] = value;
                setMonDayTime(list);
            }
                break;
            case "tue":
            {
                const list = [...tueDayTime];
                list[index][name] = value;
                setTueDayTime(list);
            }
                break;
            case "wed":
            {
                const list = [...wedDayTime];
                list[index][name] = value;
                setWedDayTime(list);
            }
                break;
            case "thu":
            {
                const list = [...thuDayTime];
                list[index][name] = value;
                setThuDayTime(list);
            }
                break;
            case "fri":
            {
                const list = [...friDayTime];
                list[index][name] = value;
                setFriDayTime(list);
            }
                break;
            case "sat":
            {
                const list = [...satDayTime];
                list[index][name] = value;
                setSatDayTime(list);
            }
                break;
            default: alert("select day")
        }
    }

    let handleday = (day, index) => {
        setCurrentDay(day)
        const activeElement = document.getElementsByClassName("day-selector");
        Array.prototype.forEach.call(activeElement, function (slide, index) {
            slide.style.backgroundColor = "#FDFDFD";
            slide.style.color = "#585656";
        });
        document.getElementById(`actine${index}`).style.backgroundColor = "#315EFB";
        document.getElementById(`actine${index}`).style.color = "#FFF";

        setSunday(false)
        setMonday(false)
        setTueday(false)
        setWedday(false)
        setThuday(false)
        setFriday(false)
        setSatday(false)

        switch (day) {
            case "sun":
                setSunday(true)
                break;
            case "mon":
                setMonday(true)
                break;
            case "tue":
                setTueday(true)
                break;
            case "wed":
                setWedday(true)
                break;
            case "thu":
                setThuday(true)
                break;
            case "fri":
                setFriday(true)
                break;
            case "sat":
                setSatday(true)
                break;
            default: alert("select day")
        }
    }

    const timeToMinutes = (timeString) => {
        const [time, modifier] = timeString.split(' ');
        let [hours, minutes] = time.split(':').map(Number);

        if (hours === 12) {
            hours = 0;
        }
        if (modifier === 'PM') {
            hours += 12;
        }

        return hours * 60 + minutes;
    };

    let timeValidation = {
        start_time: Yup.string().required('start time required').nullable(),
        end_time: Yup.string()
          .required('End time required')
          .test('timeCheck',
            'End time must be greater than start time',
            function (endTime) {
                const { start_time } = this.parent;

                if (!start_time || !endTime) return true;

                return timeToMinutes(start_time) < timeToMinutes(endTime);
            })
          .nullable()
    }
    // daysInWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const formik = useFormik({
        initialValues: {
            sundaySlot: [],
            mondaySlot: [],
            tuesdaySlot: [],
            wednesdaySlot: [],
            thursdaySlot: [],
            fridaySlot: [],
            saturdaySlot: [],
            // saturdaySlot: [{ start_time: '', end_time: '' }],
        },
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: Yup.object({
            sundaySlot: Yup.array(Yup.object(timeValidation)),
            mondaySlot: Yup.array(Yup.object(timeValidation)),
            tuesdaySlot: Yup.array(Yup.object(timeValidation)),
            wednesdaySlot: Yup.array(Yup.object(timeValidation)),
            thursdaySlot: Yup.array(Yup.object(timeValidation)),
            fridaySlot: Yup.array(Yup.object(timeValidation)),
            saturdaySlot: Yup.array(Yup.object(timeValidation))
            // .min(1, 'You need to provide at least 1 institution')
            //     .max(3, 'You can only provide 3 institution')
        }),
        onSubmit: async (values, action) => {
            let userDetails = getToken();
            let tempObj = {
                user_id: userDetails.userId,
                user_type: userDetails.user_info.usertypeobj,
                sunday: {
                    // is_available: true,
                    is_available: (values.sundaySlot.length >= 1),
                    slot: values.sundaySlot,
                },
                monday: {
                    is_available: (values.mondaySlot.length >= 1),
                    slot: values.mondaySlot.map(({ id, ...rest }) => rest),
                },
                tuesday: {
                    is_available: (values.tuesdaySlot.length >= 1),
                    slot: values.tuesdaySlot.map(({ id, ...rest }) => rest),
                },
                wednesday: {
                    is_available: (values.wednesdaySlot.length >= 1),
                    slot: values.wednesdaySlot.map(({ id, ...rest }) => rest),
                },
                thrusday: {
                    is_available: (values.thursdaySlot.length >= 1),
                    slot: values.thursdaySlot.map(({ id, ...rest }) => rest),
                },
                friday: {
                    is_available: (values.fridaySlot.length >= 1),
                    slot: values.fridaySlot.map(({ id, ...rest }) => rest),
                },
                saturday: {
                    is_available: (values.saturdaySlot.length >= 1),
                    slot: values.saturdaySlot.map(({ id, ...rest }) => rest),
                }
            };
            setLoading(true);
            ThirtyMin(tempObj, (success) => {
                if (success.data.code === 200 && success.data.status === "success") {
                    setLoading(false)
                    toast.success(success.data.data, "success")
                    InitialSlot()
                }
                if (success.data.code === 400 && success.data.status === "failed") {
                    setLoading(false)
                }
            });
        },
    });


    const handleaddclick = (day) => {
        setSunday(false)
        setMonday(false)
        setTueday(false)
        setWedday(false)
        setThuday(false)
        setFriday(false)
        setSatday(false)

        switch (day) {
            case "sun":
                formik.setFieldValue("sundaySlot", [...formik.values.sundaySlot, { id: uuidv4(), start_time: '', end_time: '' }]);
                setSunday(true)
                break;
            case "mon":
                formik.setFieldValue("mondaySlot", [...formik.values.mondaySlot,
                    { id: uuidv4(), start_time: '', end_time: '' }]);
                setMonday(true)
                break;
            case "tue":
                formik.setFieldValue("tuesdaySlot", [...formik.values.tuesdaySlot,
                    { id: uuidv4(), start_time: '', end_time: '' }]);
                setTueday(true)

                break;
            case "wed":
                formik.setFieldValue("wednesdaySlot", [...formik.values.wednesdaySlot,
                    { id: uuidv4(), start_time: '', end_time: '' }]);
                setWedday(true)
                break;
            case "thu":
                formik.setFieldValue("thursdaySlot", [...formik.values.thursdaySlot,
                    { id: uuidv4(), start_time: '', end_time: '' }]);
                setThuday(true)
                break;
            case "fri":
                formik.setFieldValue("fridaySlot", [...formik.values.fridaySlot,
                    { id: uuidv4(), start_time: '', end_time: '' }]);
                setFriday(true)
                break;
            case "sat":
                formik.setFieldValue("saturdaySlot", [...formik.values.saturdaySlot,
                    { id: uuidv4(), start_time: '', end_time: '' }]);
                setSatday(true)
                break;
            default: console.log("object")
        }

    }

    const handleremove = (currentObj, day) => {
        setSunday(false)
        setMonday(false)
        setTueday(false)
        setWedday(false)
        setThuday(false)
        setFriday(false)
        setSatday(false)
        switch (day) {
            case "sun":
            {
                let filterTime = formik.values.sundaySlot.filter((item) => item.id !== currentObj.id)
                formik.setFieldValue("sundaySlot", filterTime.length ? filterTime : []);
                setSunday(true)
            }
                break;
            case "mon":
            {
                let filterTime = formik.values.mondaySlot.filter((item) => item.id !== currentObj.id)
                formik.setFieldValue("mondaySlot", filterTime.length ? filterTime : []);
                setMonday(true)
            }
                break;
            case "tue":
            {
                let filterTime = formik.values.tuesdaySlot.filter((item) => item.id !== currentObj.id)
                formik.setFieldValue("tuesdaySlot", filterTime.length ? filterTime : []);
                setTueday(true)
            }

                break;
            case "wed":
            {
                let filterTime = formik.values.tuesdaySlot.filter((item) => item.id !== currentObj.id)
                formik.setFieldValue("wednesdaySlot", filterTime.length ? filterTime : []);
                setWedday(true)
            }
                break;
            case "thu":
            {
                let filterTime = formik.values.thursdaySlot.filter((item) => item.id !== currentObj.id)
                formik.setFieldValue("thursdaySlot", filterTime.length ? filterTime : []);
                setThuday(true)
            }
                break;
            case "fri":
            {
                let filterTime = formik.values.fridaySlot.filter((item) => item.id !== currentObj.id)
                formik.setFieldValue("fridaySlot", filterTime.length ? filterTime : []);
                setFriday(true)
            }
                break;
            case "sat":
            {
                let filterTime = formik.values.saturdaySlot.filter((item) => item.id !== currentObj.id)
                formik.setFieldValue("saturdaySlot", filterTime.length ? filterTime : []);
                setSatday(true)
            }
                break;
            default: alert("select day")
        }

    }

    let InitialSlot = () => {
        getMinutedata((success) => {
            formik.setFieldValue("sundaySlot", success.Sunday.slot.map((item) => ({ id: uuidv4(), ...item })));
            formik.setFieldValue("mondaySlot", success.Monday.slot.map((item) => ({ id: uuidv4(), ...item })));
            formik.setFieldValue("tuesdaySlot", success.Tuesday.slot.map((item) => ({ id: uuidv4(), ...item })));
            formik.setFieldValue("wednesdaySlot", success.Wednesday.slot.map((item) => ({ id: uuidv4(), ...item })));
            formik.setFieldValue("thursdaySlot", success.Thursday.slot.map((item) => ({ id: uuidv4(), ...item })));
            formik.setFieldValue("fridaySlot", success.Friday.slot.map((item) => ({ id: uuidv4(), ...item })));
            formik.setFieldValue("saturdaySlot", success.Saturday.slot.map((item) => ({ id: uuidv4(), ...item })));
            setIsActive(success.is_active)
        })
    }

    let ToggleActiveDecative = () => {
        setLoading(true)
        DeactivateTime((success) => {
            setLoading(false)
            setIsActive((pre) => !pre)
            toast.success(success.data)
        })
    }

    useEffect(() => {
        InitialSlot()
    }, [])

    return (
      <div className='minute_pagpading'>
          {loading ? <Loader /> : null}
          <div className='row'>
              <div className='col-sm-12 mintit_text'> 30 Minute Showing</div>
              <div className='col-sm-12 pb-4 d-flex  align-items-center gap-2 justify-content-between media_pading p-0'>
                <Box display={'flex'} alignItems={'center'} gap={'10px'}>
                  <button
                    type="button"
                    className={`active_btn ${isActive ? 'activated_btn' : ''}`}
                    disabled={isActive}
                    onClick={() =>
                      ToggleActiveDecative()
                    }>
                    {isActive ? 'Activated' : 'Active'}
                  </button>
                  <button
                    type="button"
                    className={`deactivate_btn ${isActive ? '' : 'deactivated_btn'}`}
                    disabled={!isActive}
                    onClick={() =>
                      ToggleActiveDecative()
                    }>
                    {isActive ? 'Deactivate' : 'Deactivated'}
                  </button>
                </Box>
                <button type="button" className="save_btn "
                        onClick={() => setSetshowAllTime(true)}
                >Your selected time
                </button>
              </div>

            <div className={isActive ? 'pe-auto p-0' : 'pe-none p-0'}>
                  <div className='mainweeks_btn'>
                      {AllDays.map((oneday, index) =>
                        <button type="" className=' pointer day-selector blure-shadow anc' id={`actine${index}`} key={`actine${index}`}
                          // onClick={() => { setCurrentDay(oneday.day); }}
                                onClick={() => { handleday(oneday.day, index) }}
                        > {oneday.label} </button>
                      )}
                  </div>

                  <div className='mainweeks_wrapper'>
                      <>
                          {sunday &&
                            formik.values.sundaySlot.map((time, index) =>
                              <SlotBook
                                handleChange={formik.handleChange}
                                setFieldValue={formik.setFieldValue}
                                key={`sundaySlot${index}`}
                                uniquName={`sundaySlot.${index}`}
                                time={time}
                                last={formik.values.sundaySlot.length - 1 === index}
                                error={formik.errors.sundaySlot?.[index]}
                                handleaddclick={handleaddclick}
                                day="sun"
                                index={index}
                                handlesunday={handlesunday}
                                handleremove={handleremove} />
                            )}

                          {(formik.values.sundaySlot.length === 0 && sunday) && <div className="center">
                              <button className="save_btn" onClick={() => handleaddclick("sun")} >Add time Slot</button>
                          </div>}
                      </>

                      <>
                          {monday &&
                            formik.values.mondaySlot.map((time, index) =>
                              <SlotBook
                                setFieldValue={formik.setFieldValue}
                                handleChange={formik.handleChange}
                                key={`mondaySlot${index}`}
                                uniquName={`mondaySlot.${index}`}
                                time={time}
                                handleaddclick={handleaddclick}
                                day="mon"
                                index={index}
                                last={formik.values.mondaySlot.length - 1 === index}
                                error={formik.errors.mondaySlot?.[index]}
                                handlesunday={handlesunday}
                                handleremove={handleremove} />
                            )}

                          {(formik.values.mondaySlot.length === 0 && monday) && <div className="center">
                              <button className="save_btn" onClick={() => handleaddclick("mon")}>Add time Slot</button>
                          </div>}
                      </>

                      <>
                          {tueday &&
                            formik.values.tuesdaySlot.map((time, index) =>
                              <SlotBook
                                setFieldValue={formik.setFieldValue}
                                handleChange={formik.handleChange}
                                key={`tuesdaySlot${index}`}
                                uniquName={`tuesdaySlot.${index}`}
                                time={time}
                                handleaddclick={handleaddclick}
                                day="tue"
                                last={formik.values.tuesdaySlot.length - 1 === index}
                                error={formik.errors.tuesdaySlot?.[index]}
                                index={index}
                                handlesunday={handlesunday}
                                handleremove={handleremove} />
                            )}

                          {(formik.values.tuesdaySlot.length === 0 && tueday) && <div className="center">
                              <button className="save_btn" onClick={() => handleaddclick("tue")}>Add time Slot</button>
                          </div>}
                      </>

                      <>
                          {wedday &&
                            formik.values.wednesdaySlot.map((time, index) =>
                              <SlotBook
                                setFieldValue={formik.setFieldValue}
                                handleChange={formik.handleChange}
                                key={`wednesdaySlot${index}`}
                                uniquName={`wednesdaySlot.${index}`}
                                time={time}
                                handleaddclick={handleaddclick}
                                day="wed"
                                index={index}
                                last={formik.values.wednesdaySlot.length - 1 === index}
                                error={formik.errors.wednesdaySlot?.[index]}
                                handlesunday={handlesunday}
                                handleremove={handleremove} />
                            )}
                          {(formik.values.wednesdaySlot.length === 0 && wedday) && <div className="center">
                              <button className="save_btn" onClick={() => handleaddclick("wed")}>Add time Slot</button>
                          </div>}
                      </>

                      <>
                          {thuday &&
                            formik.values.thursdaySlot.map((time, index) =>
                              <SlotBook
                                setFieldValue={formik.setFieldValue}
                                handleChange={formik.handleChange}
                                key={`thursdaySlot${index}`}
                                uniquName={`thursdaySlot.${index}`}
                                time={time}
                                handleaddclick={handleaddclick}
                                day="thu"
                                last={formik.values.thursdaySlot.length - 1 === index}
                                error={formik.errors.thursdaySlot?.[index]}
                                index={index}
                                handlesunday={handlesunday}
                                handleremove={handleremove} />
                            )}

                          {(formik.values.thursdaySlot.length === 0 && thuday) && <div className="center">
                              <button className="save_btn" onClick={() => handleaddclick("thu")}>Add time Slot</button>
                          </div>}
                      </>

                      <>
                          {friday &&
                            formik.values.fridaySlot.map((time, index) =>
                              <SlotBook
                                setFieldValue={formik.setFieldValue}
                                handleChange={formik.handleChange}
                                key={`fridaySlot${index}`}
                                uniquName={`fridaySlot.${index}`}
                                time={time}
                                handleaddclick={handleaddclick}
                                day="fri"
                                last={formik.values.fridaySlot.length - 1 === index}
                                error={formik.errors.fridaySlot?.[index]}
                                index={index}
                                handlesunday={handlesunday}
                                handleremove={handleremove} />
                            )}
                          {(formik.values.fridaySlot.length === 0 && friday) && <div className="center">
                              <button className="save_btn" onClick={() => handleaddclick("fri")}>Add time Slot</button>
                          </div>}
                      </>


                      <>
                          {satday &&
                            formik.values.saturdaySlot.map((time, index) =>
                              <SlotBook
                                setFieldValue={formik.setFieldValue}
                                handleChange={formik.handleChange}
                                key={`saturdaySlot${index}`}
                                uniquName={`saturdaySlot.${index}`}
                                time={time}
                                handleaddclick={handleaddclick}
                                day="sat"
                                last={formik.values.saturdaySlot.length - 1 === index}
                                error={formik.errors.saturdaySlot?.[index]}
                                index={index}
                                handlesunday={handlesunday}
                                handleremove={handleremove} />
                            )}
                          {(formik.values.saturdaySlot.length === 0 && satday) && <div className="center">
                              <button className="save_btn" onClick={() => handleaddclick("sat")}>Add time Slot</button>
                          </div>}
                      </>

                      <div className="col-sm-12 d-flex justify-content-end media_pading flex-column align-items-end p-0">

                          {!(Object.keys(formik?.errors)?.length === 0) &&
                            <p className='validation-text text-center text-capitalize'>Error occurs in
                                {Object.keys(formik?.errors).map((key, index) => <span key={index}> {` ${key.replace(/Slot/g, "")} `} </span>)}
                                time zone
                            </p>
                          }

                          <button className="save_btn mt-3" type="submit"
                                  onClick={formik.handleSubmit}
                                  disabled={checkEmptyArrays(formik.values)}
                          > Submit </button>
                      </div>
                  </div>
              </div>
          </div>
          {setshowAllTime && <SelectedSlotModal data={formik.values} setSetshowAllTime={setSetshowAllTime} />}
      </div>
    )
}
export default Minute
