import React, { useEffect, useState } from 'react';
import { FieldRenderProps } from 'react-final-form';

import { Box, TextField } from '@mui/material';

import { getFieldError } from '@core/FinalForm/getFieldError';
import { styles } from '@components/forms/SubmitRequestForAdvertising/styles';
import Typography from '@mui/material/Typography';
import RemoveIcon from '@mui/icons-material/Remove';
import { updateFilterData } from '@components/pages/homepage/components/hero/FilterSlice';
import AddIcon from '@mui/icons-material/Add';

export const RenderBedroomsField: React.FC<FieldRenderProps<number>> = ({
  input,
  meta,
}) => {
  const [resBedroom, setResBedroom] = useState(input.value || 0);

  useEffect(() => {
    setResBedroom(input.value || 0);
  }, [input.value]);

  return (
    <div className="unit-box filter-row">
      <p className="filter-heading">Bedroom</p>
      <div className="update-unit inner-shadow center justify-content-evenly">
        <RemoveIcon
          className="pointer"
          onClick={() => {
            const newCount = resBedroom > 0 ? resBedroom - 1 : 0;
            setResBedroom(newCount);
            input.onChange(newCount);
          }}
        />
        {resBedroom}
        <AddIcon
          className="pointer"
          onClick={() => {
            const newCount = resBedroom + 1;
            setResBedroom(newCount);
            input.onChange(newCount);
          }}
        />
      </div>
    </div>
  );
};
