import { FC } from "react";
import { useSelector } from "react-redux";
import { GetConversations } from "../../../../store/selectors/message.selector";
import { ChatListItem } from "@components/pages/Message/components/ChatListItem";

export const ChatList: FC = () => {
	const conversations = useSelector(GetConversations);

	return (
		<div className="chat-list d-flex flex-column">
			{
				conversations.map((conversation) => (
					<ChatListItem
						key={conversation.sid}
						conversation={conversation}
					/>
				))
			}
		</div>
	)
}