import React, { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import { UserRole } from '@store/typedefs';
import { book } from '@routes/book';

export type ProtectedRouteProps = {
  component: ReactElement;
  userRole: UserRole;
  allowedRoles?: UserRole[];
};

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ component, userRole, allowedRoles }) => {
  const isAllowed = allowedRoles?.includes(userRole);

  return isAllowed ? component : <Navigate to={book.home} replace />;
};
