import React from 'react'
import removeimg from "./images/remove.svg"

import Modal from 'react-bootstrap/Modal';
const AreYouSurePopup = (props) => {
    const { text = 'You want to delete !' } = props;
    // const { surepopup, setSurepopup, setConfirmdelete } = props
    // const [deleteListing, deleteSaveListing] = useState(false);

    return (

        <>
            <div>
                {/*start modal You want to delete the saved listing! */}
                <div className="delete_save_listing">
                    <Modal className="delete_save_listing"
                        show={props.surepopup}
                        onHide={() => props.setSurepopup(false)}
                        size="lg"
                        ria-labelledby="contained-modal-title-vcenter"
                        centered >
                        <Modal.Header closeButton >
                            <Modal.Title id="contained-modal-title-vcenter">
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="px-5 mt-4   save_listing_body">
                            <div className='text-center'><img src={removeimg} alt="" /></div>

                            <h3 className='pb-3 mb-1 text-center'>Are you sure?</h3>
                            <p className='text-center pb-4 mb-1'>{text}</p>
                            <div className="row">
                                <div className="col-sm-6 d-grid">
                                    <button type="submit" className="cancel_btn btn-block"
                                        onClick={() => { props.setSurepopup(false) }}>Cancel</button>
                                </div>
                                <div className="col-sm-6 d-grid">
                                    <button type="submit" className="save__btns btn-block"
                                        onClick={() => { props.confirmdelete(); props.setSurepopup(false) }}
                                    >Yes</button>
                                </div>
                            </div>
                            <div className="text-end mb-3">
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
                {/* end You want to delete the saved listing! */}
            </div>
        </>
    )
}

export default AreYouSurePopup

