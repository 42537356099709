import * as React from "react";
import Paper from "@mui/material/Paper";
import Documentlist from "./components/Documentlist"; // export default function

const Documents = () => {
  return (
    <>
      <Paper
        elevation={3}
        sx={{ width: "100%", marginTop: "3rem", marginBottom: "10rem", height: 'calc(100% - 13rem)', }}
        style={{ backgroundColor: "rgba(238, 242, 256, 1)" }}
      >
        <div
          style={{
            width: "90%",
            margin: "auto",
            marginTop: "3rem",
            height: 'calc(100% - 3rem)',
          }}
        >
          <Documentlist />
        </div>
      </Paper>
    </>
  );
};
export default Documents;
