import { Conversation } from "@store/slices/message.slice";
import { useTwilioClient } from "../../../../twilio";
import { useEffect, useState } from "react";
import { Conversation as TwilioConversation } from "@twilio/conversations";

export const useTwilioConversation = (conversation?: Conversation) => {
	const { client } = useTwilioClient();
	const [twilioConversation, setConversation] = useState<TwilioConversation>();

	useEffect(() => {
		if (conversation) {
			client?.getConversationBySid(conversation.sid).then((conversation) => {
				setConversation(conversation);
			});
		}
	}, [client, conversation]);

	return twilioConversation;
}