import React, { useEffect, useState } from 'react';
import { FieldRenderProps, useField } from 'react-final-form';

import { Box, MenuItem, TextField } from '@mui/material';

import { getFieldError } from '@core/FinalForm/getFieldError';
import { styles } from '@components/forms/PopUpFilterForm/styles';
import Typography from '@mui/material/Typography';
import { CustumSelect } from '@components/pages/dashboard/components/filter-popup/PopUpFilter';
import { updateFilterData } from '@components/pages/homepage/components/hero/FilterSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getTypesBasedOnTypeLeisure } from '@components/pages/homepage/components/hero/server';
import { updateFilterOptions } from '@components/pages/homepage/components/hero/FilterOptionSlice';
import { getRadioButtons } from '@components/pages/agent-dash-sales/server';

export const RenderTypeField: React.FC<FieldRenderProps<number>> = ({
  input: {onChange, ...input},
  meta,
}) => {
  const FilterOptionsInStore = useSelector((state: any) => state.FilterOptionsInStore);
  const dispatch = useDispatch();
  const {
    input: { value: category },
  } = useField('category');

  const subTypeValue = (id: number) => {
    getTypesBasedOnTypeLeisure(Number(id), (sucess: any) => {
      if (sucess.data.code === 200) {
        let newarr = sucess?.data?.data?.map((item: any) => {
          return { label: `${item.property_sub_category_Name}`, id: item.id };
        });
        dispatch(updateFilterOptions({ SubTypeOptions: newarr }));
      } else {
        dispatch(updateFilterData({ Sub_category: null }));
        dispatch(updateFilterOptions({ SubTypeOptions: [] }));
      }
    }, dispatch);
  };

  const handleChange = (event: any) => {
    onChange(event.target.value);
    subTypeValue(+event.target.value);
    dispatch(updateFilterData({ type: +event.target.value }));
  };

  useEffect(() => {
    if (!!category) {
      const radioObj = { Property_listing_type_id: category };
      getRadioButtons(radioObj, (success: any) => {
        const newTypes = success.map((item: any) => ({
          id: item.id,
          label: item.Main_category,
        }))
        dispatch(updateFilterOptions({ typeOptions: newTypes }));
        dispatch(updateFilterData({ type: newTypes[0].id }));
        subTypeValue(newTypes[0].id);
        onChange(newTypes[0].id);
      });
    }
  }, [category]);

  return (
    <div className="select-property-type filter-row">
      <div className="d-flex gap-3">
        <p className="filter-heading">Property Type</p>
        {!!getFieldError(meta) && (
          <Box sx={styles.error}>{getFieldError(meta)}</Box>
        )}
      </div>
      <div className="select-box inner-shadow">
        <CustumSelect
          className="hide-downBtn hero-select"
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="type"
          onChange={handleChange}
          {...input}
        >
          {FilterOptionsInStore.typeOptions.length > 0 ? (
            FilterOptionsInStore.typeOptions?.map((item: any, index: number) => (
              <MenuItem key={`type${index}`} value={item.id}>
                {item?.label}{" "}
              </MenuItem>
            ))
          ) : (
            <MenuItem value={""}>Select Category</MenuItem>
          )}
        </CustumSelect>
      </div>
    </div>
  );
};
