import "./PropertyImages/PropertyImages.scss";
import PropertyImages from "./PropertyImages/PropertyImages";
import Mapview from "../../../PropertyDetails/componants/map-view/Mapview";
import KeyFeature from "./key-feature/KeyFeature";
import TravelCard from "../../../PropertyDetails/componants/TravelCard";
import MortgageCalculaterPop from "../../../PropertyDetails/componants/MortgageCalculaterPop";
import CommercialInformation from '@components/pages/dashboard/propperty-datails/component/CommercialInformation';
import CommercialPropertyImages
  from '@components/pages/dashboard/propperty-datails/component/CommercialPropertyImages/CommercialPropertyImages';
// import Distance from './distance/Distance'

const PropertyInfoComponent = (props) => {
  const { propertyDetailInfo, setPropertyDetailInfo, showTravelView, handleChangeTown } = props;

  return (
    <div className="propperty-component pt-2">
      {propertyDetailInfo?.propertDetails?.property_listing_type
        ?.property_listing_name === 'Residential' ? (
        <PropertyImages
          propertDetails={propertyDetailInfo}
          setPropertyDetailInfo={setPropertyDetailInfo}
          handleChangeTown={handleChangeTown}
        />
      ) : (
        <CommercialPropertyImages
          propertDetails={propertyDetailInfo}
          setPropertyDetailInfo={setPropertyDetailInfo}
          handleChangeTown={handleChangeTown}
        />
      )}
      <div className="prop-loaction mt-4">
        {propertyDetailInfo?.propertDetails?.latitude &&
          propertyDetailInfo?.propertDetails?.longitude && (
            <Mapview
              // if property is open listing then dont show loaction icon
              marker={propertyDetailInfo.propertDetails?.is_property_open}
              propertyData={propertyDetailInfo}
              latitude={propertyDetailInfo?.propertDetails?.latitude}
              longitude={propertyDetailInfo?.propertDetails?.longitude}
            />
          )}
      </div>
      {showTravelView && <TravelCard />}
      {propertyDetailInfo?.propertDetails?.property_listing_type
        ?.property_listing_name === 'Residential' ? (
          <KeyFeature propertDetails={propertyDetailInfo} />
      ) : (
        <CommercialInformation propertDetails={propertyDetailInfo} />
      )}
      {propertyDetailInfo?.propertDetails?.propertylisting_type
        ?.user_listing_type === "Buy" &&
        propertyDetailInfo?.propertDetails?.property_listing_type
          ?.property_listing_name === "Commercial" && (
          <MortgageCalculaterPop propertDetails={propertyDetailInfo} />
        )}

      {/* for residential peroperty */}
      {propertyDetailInfo?.propertDetails?.propertylisting_type
        ?.user_listing_type === "Buy" &&
        propertyDetailInfo?.propertDetails?.property_listing_type
          ?.property_listing_name === "Residential" && (
          <MortgageCalculaterPop propertDetails={propertyDetailInfo} />
        )}
    </div>
  );
};

export default PropertyInfoComponent;
