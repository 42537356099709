import React, { useEffect, useState } from 'react';
import "./dashfilterpop.scss";
import { Select, styled } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { getToken } from "../../../../../appVariable/variable";
import {
  getCategories,
  getTypeOfProperties,
  getTypesBasedOnCategory,
} from "../../../homepage/components/hero/server";
import { updateFilterData } from "../../../homepage/components/hero/FilterSlice";
import { useNavigate } from "react-router-dom";
import { updateFilterOptions } from "../../../homepage/components/hero/FilterOptionSlice";
import { search2Actions } from '@bus/search2/actions';
import { PopUpFilterForm, schema } from '@components/forms/PopUpFilterForm';
import FinalForm from '@core/FinalForm';
import { getAccounttype } from '@components/pages/personal/typeOfAccount/server';

export const CustumSelect = styled(Select)({
  padding: "0px",
  width: "100%",
  boxShadow: "none",
  ".MuiOutlinedInput-notchedOutline": { border: 0, padding: 0 },
  "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
    border: 0,
  },
  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: 0,
  },
  "MuiInputBase-input": { padding: 0 },
});

const PopUpFilter = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const FilterDataInStore = useSelector((state) => state.FilterDataInStore);
  const commercialPopupVisible = useSelector(
    (state) => state.search.commercialPopupVisible
  );
  const token = getToken();
  const userId = token?.userId;
  const [typeofAccount, setTypeofAccount] = useState(0);

  useEffect(() => {
    getAccounttype((success) => {
        setTypeofAccount(success.type_allowed);
      },
      (error) => {
        console.log(error)
      });
  }, []);

  const getTypeDefault = (id) => {
    getTypesBasedOnCategory(id, (success) => {
      let newarr = success.map((item) => {
        return { label: `${item?.Main_category}`, id: item.id };
      });
      dispatch(updateFilterOptions({ typeOptions: newarr }));
    });
  };

  useEffect(() => {
    let FilterSearchSchema = JSON.parse(
      localStorage.getItem("FilterSearchSchema")
    );
    if (userId) {
      getTypeOfProperties((success) => {
        GetPropertyCategory(success[typeofAccount].id);
        dispatch(
          updateFilterData({ property_listing: success[typeofAccount].id })
        );
      });
    } else {
      if (FilterDataInStore.property_listing === null) {
        dispatch(updateFilterData(FilterSearchSchema));
        GetPropertyCategory(FilterSearchSchema?.property_listing);
      } else {
        GetPropertyCategory(FilterDataInStore?.property_listing);
      }
    }
  }, [typeofAccount]);

  let GetPropertyCategory = (property_id) => {
    getCategories(property_id, (success) => {
      let newarr = success.map((item) => {
        return { label: `${item?.listing_type}`, id: item?.id };
      });
      dispatch(updateFilterOptions({ categotyOptions: newarr }));
      getTypeDefault(newarr[0]?.id);
    });
  };

  const handleSubmit = (payload) => {
    dispatch(search2Actions.applySearch(payload));
    dispatch(updateFilterData(payload.values));
  };

  const handleSuccess = (data) => {
    dispatch(
      updateFilterData({
        Filter_responce: data.results,
      })
    );
    navigate("/dashboard/quik-view");
    props.setShowFilter(false);
    dispatch(search2Actions.setIsSearch(true));
  };

  return (
    <div>
      <Modal
        className={`${
          commercialPopupVisible ? "popupssss" : "popup hidden"
        } dashboard-filter-popup`}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={true}
        onHide={() => props.setShowFilter(false)}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <FinalForm
            initialValues={{
              property_listing: FilterDataInStore.property_listing,
              category: FilterDataInStore.category,
              type: FilterDataInStore.type,
              pricemin: FilterDataInStore.pricemin,
              pricemax: FilterDataInStore.pricemax,
            }}
            component={PopUpFilterForm}
            onSubmit={handleSubmit}
            schema={schema}
            onSubmitSuccess={handleSuccess}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PopUpFilter;
