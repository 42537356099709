import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AuthState } from './typedefs';

const initialState: AuthState = {
  isInitialised: false,
  isAuthenticated: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    initialize(state) {
      state.isInitialised = true;
    },
    isAuthenticatedTrue(state) {
      state.isAuthenticated = true;
    },
    isAuthenticatedFalse(state) {
      state.isAuthenticated = false;
    },
  },
});

export default authSlice;
