import { createSlice } from '@reduxjs/toolkit';

export const PersonalInfoSlice = createSlice({
    name: 'personalInfo',
    initialState: {
        userInfo: null,
    },
    reducers: {
        updatePersonalInfo: (state, action) => {
            // state = action.payload
            return { ...state, ...action.payload };
        },
    },
})

// Action creators are generated for each case reducer function
export const { updatePersonalInfo } = PersonalInfoSlice.actions

export default PersonalInfoSlice.reducer
