import { Conversation as TwilioConversation, Message as TwilioMessage, Participant } from "@twilio/conversations";
import { Conversation, Message } from "@store/slices/message.slice";

export const transformTwilioConversation = (conversation: TwilioConversation): Conversation => {
	const participants: Participant[] = [];
	conversation._participants.forEach((participant) => participants.push(participant));

	return {
		sid: conversation.sid,
		lastMessageCreated: conversation.lastMessage?.dateCreated?.getTime() || 0,
		lastReadMessageIndex: conversation.lastReadMessageIndex || 0,
		participants: participants.map(({ identity }) => ({
			identity: identity || '',
			first_name: '',
			last_name: '',
			profile_image: null
		}))
	}
}
export const transformTwilioMessage = (message: TwilioMessage): Message => {
	return {
		index: message.index,
		sid: message.sid,
		body: message.body,
		author: message.author,
		dateCreated: message.dateCreated,
		dateUpdated: message.dateCreated,
		conversation_sid: message.conversation.sid,
		attributes: message.attributes as Record<string, any>,
	}
}