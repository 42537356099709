import React, { useState } from 'react';
import { styles } from './styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { SwipeableDrawer } from '@mui/material';
import Box from '@mui/material/Box';
import icons from '@components/pages/dashboard/components/sidebar/images/SidebarIcon';
import "../sidebar/Sidebar.scss";
import { getIsSidebarOpen } from '@bus/ui/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { uiActions } from '@bus/ui/actions';
import Typography from '@mui/material/Typography';

type MobileSidebarProps = {
  sidebardata: any;
  result: any;
  Gotolink: any;
};

export const MobileSidebar: React.FC<MobileSidebarProps> = ({
  sidebardata,
  result,
  Gotolink,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [settingoption, setSettingoption] = useState(false)
  const isSidebarOpen = useSelector(getIsSidebarOpen);
  const handleOpen = () => {
    dispatch(uiActions.setSidebarOpen(true));
  };
  const handleClose = () => {
    dispatch(uiActions.setSidebarOpen(false));
  }
  const handleClick = (link: string) => {
    handleClose();
    setTimeout(() => {
      navigate(link);
    }, 50);
  };
  const handleClickSettings = () => {
    setSettingoption(!settingoption);
    if (!settingoption && result) {
      return;
    }
    handleClose();
    setTimeout(() => {
      navigate('profile-setting');
    }, 50);
  };

  return (
    <SwipeableDrawer
      anchor={'left'}
      open={isSidebarOpen}
      sx={styles.root}
      ModalProps={{
        BackdropProps: {
          invisible: false,
          sx: styles.backdrop,
        },
      }}
      PaperProps={{
        sx: styles.drawerPaper,
        elevation: 0,
      }}
      onClose={handleClose}
      onOpen={handleOpen}>
      <Box height={'100%'} display={'flex'} flexDirection={'column'} justifyContent={'space-between'} py={'22px'}>
        <Box sx={styles.wrapper}>
          {sidebardata?.map((link: any, i: number) =>
            <Box
              key={`agent${i}`}
              display={'flex'}
              alignItems={'center'}
              onClick={() => handleClick(link.link)}
              sx={[styles.titleWrapper, pathname.includes(link.link) && styles.active]}>
              <Box width={'18px'} height={'18px'} component={'img'} src={link.icon} alt="" />
              <Typography sx={styles.title}>{link.title}</Typography>
            </Box>
          )}
        </Box>
        <Box className={'position-relative'}>
          <Box
            className="setting_Droupdown box-shodow flex-column gap-2 position-absolute"
            style={{ display: settingoption && result ? 'flex' : "none" }}>
            <p
              onClick={() => {
                Gotolink(`/agent-dashboard/profile-settings`);
                dispatch(uiActions.setSidebarOpen(false));
              }}>
              Account Settings
            </p>
            <p
              onClick={() => {
                Gotolink(`/agent-dashboard/plan&billing`);
                dispatch(uiActions.setSidebarOpen(false));
              }}>
              Plan & Billing
            </p>
          </Box>
          <Box
            onClick={handleClickSettings}
            display={'flex'}
            alignItems={'center'}
            sx={[styles.titleWrapper, pathname.includes('/profile-setting') && styles.active]}>
            <Box width={'18px'} height={'18px'} component={'img'} src={icons.settings} alt="" />
            <Typography sx={styles.title}>Settings</Typography>
          </Box>
        </Box>
      </Box>
    </SwipeableDrawer>
  );
};
