import * as React from 'react';
import { Field, FormRenderProps } from 'react-final-form';

import { Box, Button } from '@mui/material';

import { schema } from './schema';
import { styles } from './styles';
import { RenderFirstNameField } from './fields/RenderFirstNameField';
import { RenderLastNameField } from './fields/RenderLastNameField';
import { RenderPhoneNumberField } from './fields/RenderPhoneNumberField';
import { RenderEmailField } from './fields/RenderEmailField';
import { RenderNoteField } from './fields/RenderNoteField';
import Typography from '@mui/material/Typography';

const SubmitRequestForAdvertising = (props: FormRenderProps) => {

  return (
    <form noValidate onSubmit={props.handleSubmit}>
      <Box width={'100%'} display={'flex'} flexDirection={'column'}>
        <Field name={`first_name`} component={RenderFirstNameField}/>
        <Field name={`last_name`} component={RenderLastNameField}/>
        <Field name={`phone`} component={RenderPhoneNumberField}/>
        <Field name={`email`} component={RenderEmailField}/>
        <Field name={`note`} component={RenderNoteField}/>
        <Box my={'40px'} display={'flex'} width={'100%'} justifyContent={'flex-end'}>
          <Button sx={styles.button} type={'submit'} role={'submit'}>
            <Typography variant={'button'} color={'#FFF'}>
              Submit
            </Typography>
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default SubmitRequestForAdvertising;

export { schema, SubmitRequestForAdvertising };
