import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';

export type CommercialInformationProps = {
  propertDetails: any;
};
const CommercialInformation: FC<CommercialInformationProps> = ({ propertDetails }) => {

  return (
    <div className="property-feature mt-3">
      <Box style={{ marginTop: "20px" }}>
        <Typography fontWeight={500} fontSize={'18px'} lineHeight={'20px'} color={'rgba(49, 94, 251, 1)'}>
          {propertDetails.propertDetails.property_title}
        </Typography>
      </Box>
      <Box mt={'24px'}>
        <Box display={'flex'} width={'100%'} gap={'16px'} flexWrap={'wrap'}>
          <Box maxWidth={'225px'} width={'100%'}>
            <Typography
              color={'rgba(49, 94, 251, 1)'}
              fontSize={'18px'}
              fontWeight={400}>
              {propertDetails.propertDetails.propertylisting_type.user_listing_type} Features
            </Typography>
            <Box mt={'24px'} display={'flex'} alignItems={'center'}>
              <Typography maxWidth={'130px'} width={'100%'} color={'rgba(88, 86, 86, 1)'} fontSize={'12px'} lineHeight={'14px'} fontWeight={300}>Available:</Typography>
              <Typography color={'rgba(88, 86, 86, 1)'} fontSize={'12px'} lineHeight={'14px'} fontWeight={300}>
                {propertDetails.SalesFeatures?.Available
                  ? propertDetails.SalesFeatures?.Available
                  : "--"}
              </Typography>
            </Box>
            <Box display={'flex'} alignItems={'center'}>
              <Typography maxWidth={'130px'} width={'100%'} color={'rgba(88, 86, 86, 1)'} fontSize={'12px'} lineHeight={'14px'} fontWeight={300}>Time on AFISADO:</Typography>
              <Typography color={'rgba(88, 86, 86, 1)'} fontSize={'12px'} lineHeight={'14px'} fontWeight={300}>
                {propertDetails.SalesFeatures?.Time_on_AFISADO
                  ? propertDetails.SalesFeatures?.Time_on_AFISADO
                  : "--"}
              </Typography>
            </Box>
          </Box>
          <Box flex={1}>
            <Typography
              color={'rgba(49, 94, 251, 1)'}
              fontSize={'18px'}
              fontWeight={400}>
              Space Available
            </Typography>
            <Box mt={'24px'} width={'100%'}>
              {!!propertDetails.SpaceAvailable ? (
                <>
                  <Box gap={'10px'} mb={'14px'} display={'flex'} alignItems={'center'}>
                    <Box display={'flex'} width={'100%'}>
                      <Typography
                        color={'rgba(49, 94, 251, 1)'}
                        fontSize={'14px'}
                        lineHeight={'12px'}
                        fontWeight={400}>
                        Space
                      </Typography>
                    </Box>
                    <Box display={'flex'} width={'100%'}>
                      <Typography
                        color={'rgba(49, 94, 251, 1)'}
                        fontSize={'14px'}
                        lineHeight={'12px'}
                        fontWeight={400}>
                        Size
                      </Typography>
                    </Box>
                    <Box display={'flex'} width={'100%'}>
                      <Typography
                        color={'rgba(49, 94, 251, 1)'}
                        fontSize={'14px'}
                        lineHeight={'12px'}
                        fontWeight={400}>
                        Term
                      </Typography>
                    </Box>
                    <Box display={'flex'} width={'100%'}>
                      <Typography
                        color={'rgba(49, 94, 251, 1)'}
                        fontSize={'14px'}
                        lineHeight={'12px'}
                        fontWeight={400}>
                        Rate
                      </Typography>
                    </Box>
                    <Box display={'flex'} width={'100%'}>
                      <Typography
                        color={'rgba(49, 94, 251, 1)'}
                        fontSize={'14px'}
                        lineHeight={'12px'}
                        fontWeight={400}>
                        Type
                      </Typography>
                    </Box>
                  </Box>
                  {propertDetails.SpaceAvailable.map((item: any) => (
                    <Box mb={'10px'} gap={'10px'} key={item.id} display={'flex'} alignItems={'center'}>
                      <Box display={'flex'} width={'100%'}>
                        <Typography color={'rgba(88, 86, 86, 1)'} fontSize={'14px'} fontWeight={300}>
                          {item.space ? item.space : '-'}
                        </Typography>
                      </Box>
                      <Box display={'flex'} width={'100%'}>
                        <Typography color={'rgba(88, 86, 86, 1)'} fontSize={'14px'} fontWeight={300}>
                          {item.size ? item.size : '-'}
                        </Typography>
                      </Box>
                      <Box display={'flex'} width={'100%'}>
                        <Typography color={'rgba(88, 86, 86, 1)'} fontSize={'14px'} fontWeight={300}>
                          {item.term ? item.term : '-'}
                        </Typography>
                      </Box>
                      <Box display={'flex'} width={'100%'}>
                        <Typography color={'rgba(88, 86, 86, 1)'} fontSize={'14px'} fontWeight={300}>
                          {item.rate ? item.rate : '-'}
                        </Typography>
                      </Box>
                      <Box display={'flex'} width={'100%'}>
                        <Typography color={'rgba(88, 86, 86, 1)'} fontSize={'14px'} fontWeight={300}>
                          {item.type ? item.type : '-'}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </>
              ) : (
                <Typography color={'rgba(88, 86, 86, 1)'} fontSize={'14px'} fontWeight={300}>-</Typography>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box mt={'24px'}>
        <Typography
          color={'rgba(49, 94, 251, 1)'}
          fontSize={'16px'}
          lineHeight={'14px'}
          fontWeight={400}>
          Property Features
        </Typography>
        <Box mt={'17px'} display={'flex'} width={'100%'} justifyContent={'space-between'} gap={'16px'} flexWrap={'wrap'}>
          <Box display={'flex'} gap={'17px'} flexDirection={'column'}>
            <Box display={'flex'} gap={'5px'} alignItems={'center'}>
              <Typography color={'rgba(88, 86, 86, 1)'} fontSize={'14px'} lineHeight={'12px'} fontWeight={300}>
                Units:
              </Typography>
              <Typography color={'rgba(88, 86, 86, 1)'} fontSize={'14px'} lineHeight={'12px'} fontWeight={300}>
                {propertDetails.propertDetails.Units || '-'}
              </Typography>
            </Box>
            <Box display={'flex'} gap={'5px'} alignItems={'center'}>
              <Typography color={'rgba(88, 86, 86, 1)'} fontSize={'14px'} lineHeight={'12px'} fontWeight={300}>
                Rooms:
              </Typography>
              <Typography color={'rgba(88, 86, 86, 1)'} fontSize={'14px'} lineHeight={'12px'} fontWeight={300}>
                {propertDetails.propertDetails.Rooms || '-'}
              </Typography>
            </Box>
            <Box display={'flex'} gap={'5px'} alignItems={'center'}>
              <Typography color={'rgba(88, 86, 86, 1)'} fontSize={'14px'} lineHeight={'12px'} fontWeight={300}>
                Stories:
              </Typography>
              <Typography color={'rgba(88, 86, 86, 1)'} fontSize={'14px'} lineHeight={'12px'} fontWeight={300}>
                {propertDetails.propertDetails.Stories || '-'}
              </Typography>
            </Box>
            <Box display={'flex'} gap={'5px'} alignItems={'center'}>
              <Typography color={'rgba(88, 86, 86, 1)'} fontSize={'14px'} lineHeight={'12px'} fontWeight={300}>
                Block:
              </Typography>
              <Typography color={'rgba(88, 86, 86, 1)'} fontSize={'14px'} lineHeight={'12px'} fontWeight={300}>
                {propertDetails.propertDetails.Block || '-'}
              </Typography>
            </Box>
            <Box display={'flex'} gap={'5px'} alignItems={'center'}>
              <Typography color={'rgba(88, 86, 86, 1)'} fontSize={'14px'} lineHeight={'12px'} fontWeight={300}>
                Lot:
              </Typography>
              <Typography color={'rgba(88, 86, 86, 1)'} fontSize={'14px'} lineHeight={'12px'} fontWeight={300}>
                {propertDetails.propertDetails.Lot || '-'}
              </Typography>
            </Box>
            <Box display={'flex'} gap={'5px'} alignItems={'center'}>
              <Typography color={'rgba(88, 86, 86, 1)'} fontSize={'14px'} lineHeight={'12px'} fontWeight={300}>
                Zone:
              </Typography>
              <Typography color={'rgba(88, 86, 86, 1)'} fontSize={'14px'} lineHeight={'12px'} fontWeight={300}>
                {propertDetails.propertDetails.Zone || '-'}
              </Typography>
            </Box>
          </Box>
          <Box display={'flex'} gap={'17px'} flexDirection={'column'}>
            <Box display={'flex'} gap={'5px'} alignItems={'center'}>
              <Typography color={'rgba(88, 86, 86, 1)'} fontSize={'14px'} lineHeight={'12px'} fontWeight={300}>
                Sq.Ft:
              </Typography>
              <Typography color={'rgba(88, 86, 86, 1)'} fontSize={'14px'} lineHeight={'12px'} fontWeight={300}>
                {propertDetails.propertDetails.Square_sqft || '-'}
              </Typography>
            </Box>
            <Box display={'flex'} gap={'5px'} alignItems={'center'}>
              <Typography color={'rgba(88, 86, 86, 1)'} fontSize={'14px'} lineHeight={'12px'} fontWeight={300}>
                Available Air Rights:
              </Typography>
              <Typography color={'rgba(88, 86, 86, 1)'} fontSize={'14px'} lineHeight={'12px'} fontWeight={300}>
                {propertDetails.propertDetails.Available_Air_Rights || '-'}
              </Typography>
            </Box>
            <Box display={'flex'} gap={'5px'} alignItems={'center'}>
              <Typography color={'rgba(88, 86, 86, 1)'} fontSize={'14px'} lineHeight={'12px'} fontWeight={300}>
                Building Sq.Ft:
              </Typography>
              <Typography color={'rgba(88, 86, 86, 1)'} fontSize={'14px'} lineHeight={'12px'} fontWeight={300}>
                {propertDetails.propertDetails.Building_Sqft || '-'}
              </Typography>
            </Box>
            <Box display={'flex'} gap={'5px'} alignItems={'center'}>
              <Typography color={'rgba(88, 86, 86, 1)'} fontSize={'14px'} lineHeight={'12px'} fontWeight={300}>
                Building Dimensions:
              </Typography>
              <Typography color={'rgba(88, 86, 86, 1)'} fontSize={'14px'} lineHeight={'12px'} fontWeight={300}>
                {propertDetails.propertDetails.Building_Dimension || '-'}
              </Typography>
            </Box>
            <Box display={'flex'} gap={'5px'} alignItems={'center'}>
              <Typography color={'rgba(88, 86, 86, 1)'} fontSize={'14px'} lineHeight={'12px'} fontWeight={300}>
                Lot Dimensions:
              </Typography>
              <Typography color={'rgba(88, 86, 86, 1)'} fontSize={'14px'} lineHeight={'12px'} fontWeight={300}>
                {propertDetails.propertDetails.Lot_Dimensions || '-'}
              </Typography>
            </Box>
            <Box display={'flex'} gap={'5px'} alignItems={'center'}>
              <Typography color={'rgba(88, 86, 86, 1)'} fontSize={'14px'} lineHeight={'12px'} fontWeight={300}>
                FAR:
              </Typography>
              <Typography color={'rgba(88, 86, 86, 1)'} fontSize={'14px'} lineHeight={'12px'} fontWeight={300}>
                {propertDetails.propertDetails.FAR || '-'}
              </Typography>
            </Box>
          </Box>
          <Box display={'flex'} gap={'17px'} flexDirection={'column'}>
            <Box display={'flex'} gap={'5px'} alignItems={'center'}>
              <Typography color={'rgba(88, 86, 86, 1)'} fontSize={'14px'} lineHeight={'12px'} fontWeight={300}>
                Assessment:
              </Typography>
              <Typography color={'rgba(88, 86, 86, 1)'} fontSize={'14px'} lineHeight={'12px'} fontWeight={300}>
                {propertDetails.propertDetails.Assessment || '-'}
              </Typography>
            </Box>
            <Box display={'flex'} gap={'5px'} alignItems={'center'}>
              <Typography color={'rgba(88, 86, 86, 1)'} fontSize={'14px'} lineHeight={'12px'} fontWeight={300}>
                Annual Taxes:
              </Typography>
              <Typography color={'rgba(88, 86, 86, 1)'} fontSize={'14px'} lineHeight={'12px'} fontWeight={300}>
                {propertDetails.propertDetails.Annual_Taxes || '-'}
              </Typography>
            </Box>
          </Box>
          <Box display={'flex'} gap={'19px'} flexDirection={'column'}>
            <Box display={'flex'} gap={'5px'} alignItems={'center'}>
              <Typography color={'rgba(49, 94, 251, 1)'} fontSize={'16px'} lineHeight={'14px'} fontWeight={400}>
                {propertDetails.propertDetails.propertylisting_type.user_listing_type === 'Lease' ? 'Lease Type:' : 'Investment of Lease Type:'}
              </Typography>
              <Typography color={'rgba(88, 86, 86, 1)'} fontSize={'14px'} lineHeight={'12px'} fontWeight={300}>
                {propertDetails.propertDetails.lease_type ?? '-'}
              </Typography>
            </Box>
            {propertDetails.propertDetails.propertylisting_type.user_listing_type !== 'Lease' && (
              <>
                <Box display={'flex'} gap={'5px'} alignItems={'center'}>
                  <Typography color={'rgba(49, 94, 251, 1)'} fontSize={'16px'} lineHeight={'14px'} fontWeight={400}>
                    Occupancy:
                  </Typography>
                  <Typography color={'rgba(88, 86, 86, 1)'} fontSize={'14px'} lineHeight={'12px'} fontWeight={300}>
                    {propertDetails.propertDetails.occupancy ?? '-'}
                  </Typography>
                </Box>
                <Box display={'flex'} gap={'5px'} alignItems={'center'}>
                  <Typography color={'rgba(49, 94, 251, 1)'} fontSize={'16px'} lineHeight={'14px'} fontWeight={400}>
                    Cap Rate:
                  </Typography>
                  <Typography color={'rgba(88, 86, 86, 1)'} fontSize={'14px'} lineHeight={'12px'} fontWeight={300}>
                    {propertDetails.propertDetails.cap_rate ?? '-'}
                  </Typography>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Box>
      <Box mt={'24px'}>
        <Typography
          color={'rgba(49, 94, 251, 1)'}
          fontSize={'16px'}
          fontWeight={400}>
          Description
        </Typography>
        <Typography
          color={'rgba(88, 86, 86, 1)'}
          fontSize={'14px'}
          fontWeight={300}>
          {propertDetails.propertDetails.propert_description || '-'}
        </Typography>
      </Box>
    </div>
  );
};

export default CommercialInformation;
