import React, { useEffect, useState } from 'react';
import { Modal } from "react-bootstrap";
import "./virtualOfficemain.scss";
import createnew from "./images/createVirtualOfice.svg";
import { Autocomplete, Chip, Stack, TextField } from '@mui/material';
import Loader from "../Loader/Loader";
import { getToken } from "../../../appVariable/variable";
import VirtualCard from "./VirtualCard";
import { useSelector } from "react-redux";
import GuestVirtualCard from "./GuestVirtualCard";
import { getUserDetails } from '@REST/api';
import axiosInstance from '@REST/axiosInstance';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

const VirtualOffice = () => {
  let token = getToken();
  // const [depedancy, setDepedancy] = useState(false)
  const [modalShow, setModalShow] = useState(false);
  const [userEmails, setUserEmails] = useState('');
  const [emails, setEmails] = useState([]);
  const [officeName, setOfficeName] = useState('');
  const [loading, setLoading] = useState(false);
  const [agentVirtualOffice, setAgentVirtualOffice] = useState([]);
  const userDetails = getToken();

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && userEmails) {
      if (!/\S+@\S+\.\S+/.test(userEmails)) {
        alert('Please enter a valid email address.');
        return;
      }
      if (!emails.includes(userEmails)) {
        setEmails([...emails, userEmails]);
        setUserEmails('');
      } else {
        alert('This email is already added.');
      }
      event.preventDefault();
    }
  };
  const handleChange = (event) => {
    setUserEmails(event.target.value);
  };
  const handleDelete = (emailToDelete) => () => {
    setEmails(emails.filter((email) => email !== emailToDelete));
  };

  const AddVirtualOffice = () => {
    const schemaObj = {
      userid: token.userId,
      Virtual_Office_Name: officeName,
      Invite_Member: emails,
    };
    if (schemaObj.Virtual_Office_Name.length > 5 && schemaObj.Invite_Member?.length > 0) {
      setLoading(true);
      axiosInstance
        .post(
          `api/virtual_office/CreateVirtualOffice/`,
          schemaObj,
          {
            headers: {
              Authorization: `Bearer ${token?.idToken}`,
            },
          }
        )
        .then((response) => {
          if (
            response.data.code === 200 &&
            response.data.status === "success"
          ) {
            setLoading(false);
            setModalShow(false);
            // toast.success(response.data.data, "success")
            setUserEmails([]);
            setOfficeName("");
            getVirtualOffice();
          }
          if (response.data.code === 400 && response.data.status === "failed") {
            // toast.error(response.data.data, "error")
            setLoading(false);
            setModalShow(false);
          }
        })
        .catch((data) => {
          toast.warning(data.response.data.data);
          setLoading(false);
        });
    } else {
      if (schemaObj.Invite_Member?.length === 0) {
        toast.warning('Add member`s email');
      }
      if (schemaObj.Virtual_Office_Name.length < 6) {
        toast.warning('Office name should be 5 or more characters');
      }
    }
  };

  const getVirtualOffice = () => {
    let localdata = JSON.parse(getUserDetails());
    let userInfo = localdata.user_info;
    let userTypeUrl =
      userInfo.usertypeobj !== 1
        ? "virtual_office/CreateVirtualOffice"
        : "virtual_office/ShowAgentProfileOnVirtualOffice";

    let userId = token?.userId;
    axiosInstance
      .get(`api/${userTypeUrl}/${userId}`, {
        headers: { Authorization: `Bearer ${token?.idToken}` },
      })
      .then((response) => {
        if (response.data.code === 200 && response.data.status === "success") {
          setAgentVirtualOffice(response.data.data);
          setLoading(false);
        } else {
          setAgentVirtualOffice([]);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const deleteVirtualOffice = (officeId) => {
    setLoading(true);
    axiosInstance
      .delete(
        `api/virtual_office/CreateVirtualOffice/${officeId}`,
        { headers: { Authorization: `Bearer ${token?.accessToken}` } }
      )
      .then((response) => {
        if (response.data.code === 200 && response.data.status === "success") {
          getVirtualOffice();
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    getVirtualOffice();
  }, []);

  return (
    <>
      {loading ? <Loader /> : null}
      <div className="virtual_office_page  hide-scrollbar">
        <div className="virtualCard-container d-flex flex-wrap">
          {userDetails.user_info.usertypeobj === 2
            ? agentVirtualOffice?.map((info) => (
                <VirtualCard
                  key={info.id}
                  info={info}
                  DeleteVirtualOffice={deleteVirtualOffice}
                />
              ))
            : agentVirtualOffice?.map((info) => {
                return (
                  <GuestVirtualCard
                    key={info.id}
                    info={info}
                    getVirtualOffice={getVirtualOffice}
                    setLoading={setLoading}
                  />
                );
              })}

          {userDetails.user_info.usertypeobj === 2 ? (
            <div
              className="virtualoffice-card p-4 card_border left_card center"
              style={{ width: "226px" }}
            >
              <div className="image_center center">
                <div
                  className="center flex-column"
                  onClick={() => setModalShow(true)}
                >
                  <img
                    src={createnew}
                    className="pointer"
                    alt=""
                    style={{ width: "40px" }}
                  />
                  <p className="text-center pt-3">Create New Virtual Office</p>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>

      {/*start modal vertiul office */}
      <div className="virtual_ofice_modal">
        <Modal
          className="virtual_ofice_modal"
          show={modalShow}
          onHide={() => setModalShow(false)}
          size="lg"
          ria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header
            closeButton
            className="border-bottom-0 virtual_modals_header gdgyg"
          >
            <Modal.Title
              id="contained-modal-title-vcenter "
              className="border-0"
            >
              <div className="forgot_title"></div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="virtual_modals_body">
            <div className="">
              <div className="d-flex main_input">
                <div className="input_name grow-1 text-end input_namepad">
                  Office Name<span className="red-star">*</span>
                </div>
                <div className="input-sec">
                  <input
                    type="text"
                    className="form-control border-0 pb-0"
                    value={officeName}
                    onChange={(e) => {
                      setOfficeName(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="d-flex main_input">
                <div className="input_name grow-1 text-end input_namepad align-items-center">
                  Invite Member<span className="red-star">*</span>
                </div>
                <div className="input-sec">
                  <input
                    type="email"
                    className="form-control border-0 pb-0"
                    value={userEmails}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                  />
                  {/*<Autocomplete*/}
                  {/*  multiple*/}
                  {/*  freeSolo={true}*/}
                  {/*  name="showonpages"*/}
                  {/*  value={userEmails}*/}
                  {/*  onChange={(e, newValue) => {*/}
                  {/*    var expression = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;*/}
                  {/*    var regex = new RegExp(expression);*/}
                  {/*    var t =*/}
                  {/*      newValue.length > 0*/}
                  {/*        ? newValue[newValue.length - 1]*/}
                  {/*        : "";*/}
                  {/*    if (t.match(regex)) {*/}
                  {/*      setUserEmails(newValue);*/}
                  {/*    } else {*/}
                  {/*      if (newValue.length === 0) {*/}
                  {/*        setUserEmails([]);*/}
                  {/*      }*/}
                  {/*    }*/}
                  {/*  }}*/}
                  {/*  id="checkboxes-tags-demo"*/}
                  {/*  options={[]}*/}
                  {/*  style={{ width: "100%" }}*/}
                  {/*  renderInput={(params) => (*/}
                  {/*    <TextField*/}
                  {/*      placeholder="Enter Email & Press Enter"*/}
                  {/*      name="showonpages"*/}
                  {/*      sx={{*/}
                  {/*        "& legend": { display: "none" },*/}
                  {/*        "& fieldset": { border: "none" },*/}
                  {/*      }}*/}
                  {/*      {...params}*/}
                  {/*      InputLabelProps={{ shrink: false }}*/}
                  {/*      label=""*/}
                  {/*    />*/}
                  {/*  )}*/}
                  {/*/>*/}
                </div>
              </div>
              <Stack direction="row" spacing={1} mt={2}>
                {emails.map((email) => (
                  <Chip
                    key={uuidv4()}
                    label={email}
                    onDelete={handleDelete(email)}
                    color="primary"
                  />
                ))}
              </Stack>
              <div className="text-end">
                <button
                  type="submit"
                  className="create_btn blue__btn"
                  onClick={() => AddVirtualOffice()}>
                  Create & Send
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default VirtualOffice;
