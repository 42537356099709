import createSagaMiddleware from 'redux-saga';

import { configureStore } from '@reduxjs/toolkit';

import { rootReducer } from './rootReducer';
import { rootSaga } from './rootSaga';

const sagaMiddleware = createSagaMiddleware();

const middleware = [sagaMiddleware];

export const Store = (initialState: any) => {
  const store = configureStore({
    reducer: rootReducer,
    preloadedState: initialState,
    devTools: process.env.NODE_ENV !== 'production',
    middleware,
  });

  sagaMiddleware.run(rootSaga);

  return { store };
};

export const createStore = (initialState = {}) => Store(initialState);
