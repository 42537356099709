import { Theme } from '@mui/material';

export const styles = {
  picker: (theme: Theme) => ({
    width: '100%',
    '& .MuiFormLabel-root': {
      transform: 'translate(0, 4px) scale(1)',
      fontSize: '12px',
      '&.Mui-focused': {
        display: 'none',
      },
      '&.MuiFormLabel-filled': {
        display: 'none',
      },
    },
    '& .MuiInputBase-root': {
      '& input': {
        padding: '4px 0',
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
      borderBottom: '0.5px solid rgba(88, 86, 86, 1)',
      borderRadius: 0,
    },
  }),
};
