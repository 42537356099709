import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { DesktopTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { IconButton, TextField } from '@mui/material';
import React from 'react';
import { styles } from './styles';

const SlotBook = ({ handleChange, uniquName, time, handleaddclick, handleremove, last, day, error, setFieldValue }) => {
    const createTimeValue = (timeString) => {
        if (!timeString) return null;

        let [time, modifier] = timeString.split(' ');
        let [hours, minutes] = time.split(':');

        hours = parseInt(hours, 10);
        minutes = parseInt(minutes, 10);

        if (modifier === 'PM' && hours < 12) hours += 12;
        if (modifier === 'AM' && hours === 12) hours = 0;

        const date = new Date();
        date.setHours(hours, minutes, 0, 0);

        return dayjs(date);
    };

    const handleTimeChange = (data, fieldName) => {
        const formattedTime = dayjs(data).format('hh:mm A');
        setFieldValue(fieldName, formattedTime);
        handleChange(data.format('hh:mm A'))
    };

    return (
        <>
            <div className='row' >
                <div className='col-sm-5 pe-md-3'>
                    <div className="d-flex main_input">
                        <div className="input_name grow-1 text-end input_namepad" />
                        <DesktopTimePicker
                          name={`${uniquName}.start_time`}
                          label={'Start Time'}
                          sx={styles.timePicker}
                          value={time?.start_time ? createTimeValue(time.start_time) : null}
                          onChange={(newValue) => handleTimeChange(newValue, `${uniquName}.start_time`)}
                          renderInput={(params) => <TextField {...params} placeholder={'Start Time'} />}
                        />
                    </div>
                    {error?.start_time && <div className='validation-text text-center'>{error?.start_time}</div>}

                </div>
                <div className='col-sm-5 ps-md-3'>
                    <div className="d-flex main_input">
                        <div className="input_name grow-1 text-end input_namepad" />
                        <DesktopTimePicker
                          name={`${uniquName}.end_time`}
                          label={'End Time'}
                          sx={styles.timePicker}
                          value={time?.end_time ? createTimeValue(time.end_time) : null}
                          onChange={(newValue) => handleTimeChange(newValue, `${uniquName}.end_time`)}
                          renderInput={(params) => <TextField {...params} placeholder={'End Time'} />}
                        />
                    </div>
                    {error?.end_time && <div className='validation-text text-center'>{error?.end_time}</div>}
                </div>
                <div className='col-sm-2 text-end  d-flex justify-content-evenly align-items-center '>
                    {last ? (
                      <IconButton
                        sx={{ padding: 0 }}
                        className="pointer add-btn text-success"
                        onClick={() => handleaddclick(day)}
                        disabled={!time?.start_time || !time?.end_time}>
                          <AddCircleOutlineIcon />
                      </IconButton>
                      ) : (
                        <IconButton
                            sx={{ padding: 0 }}
                            className="pointer remove-btn text-danger"
                            onClick={() => handleremove(time, day)}>
                            <RemoveCircleOutlineIcon />
                        </IconButton>
                      )}
                </div>
            </div>
        </>
    )
}

export default SlotBook