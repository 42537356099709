import React, { useEffect, useState } from 'react';
// import { AiOutlineClose } from "react-icons/ai";
import Community from './components/community/Community'
import Footer from './components/footer/Footer'
import Hero from './components/hero/Hero'
import Navbar from './components/navbar/Navbar'
import RecentListing from './components/recent-listing/RecentListing'
import WhatWedo from './components/whatWeDo/WhatWedo'
import {useDispatch, useSelector} from "react-redux";
import {uiActions} from "../../../bus/ui/actions";
import {getIsLandingPageScrolled} from "../../../bus/ui/selectors";

const Homepage = () => {
  const [updatehero, setUpdatehero] = useState(true)
  const dispatch = useDispatch();
  const isScrolled = useSelector(getIsLandingPageScrolled);

  useEffect(() => {
    const handleScroll = () => {
      const scrollableElement = document.querySelector('.homepage-scroll');

      if (scrollableElement.scrollTop > 100 && !isScrolled) {
        dispatch(uiActions.setLandingPageScrolled(true));
      }

      if (scrollableElement.scrollTop <= 100 && isScrolled) {
        dispatch(uiActions.setLandingPageScrolled(false));
      }
    };

    const scrollableElement = document.querySelector('.homepage-scroll');
    scrollableElement.addEventListener('scroll', handleScroll);

    return () => scrollableElement.removeEventListener('scroll', handleScroll);
  }, [isScrolled]);

  return (
    <>
      <div className="homepage-scroll">
        {/* <Navbar updatehero={updatehero} setUpdatehero={setUpdatehero} /> */}
        <Hero updatehero={updatehero} setUpdatehero={setUpdatehero} />
        <WhatWedo />
        <RecentListing />
        <Community />
        <Footer />
      </div>
      {/* pop-up for selecting property type start */}
      {/* <div>
          <Modal show={show} onHide={handleClose} size="lg" centered>
            <Modal.Header className=" justify-content-end">
              <AiOutlineClose
                size={28}
                onClick={() => {
                  // console.log("clicked");
                  setTypeOfProperty(0);
                  handleClose();
                }}
              />
            </Modal.Header>
            <Modal.Body>
              <div>
                <div className="signupmodal">
                  <div className="signupmodal_container">
                    <input type="radio" className="userradio" name="userType" id="firstUser" />

                    <label htmlFor="firstUser"
                      className="signupmodal_box1 user-type"
                      onClick={() => {
                        setTypeOfProperty(1);
                      }}
                    >
                      <div className="signupmodal_box1_shape1">
                        <div className="signupmodal_box1_subshape1">
                          <div className="signupmodal_box1_subshape_img"></div>
                        </div>
                      </div>
                      <div className="signupmodal_box1_text">
                        <span>Customer (Amazing You)</span>
                      </div>
                    </label>

                    <input type="radio" className="userradio" name="userType" id="secondUser" />
                    <label htmlFor="secondUser"
                      className="signupmodal_box2 user-type"
                      onClick={() => {
                        setTypeOfProperty(2);
                      }}
                    >
                      <div className="signupmodal_box2_shape1">
                        <div className="signupmodal_box2_subshape1">
                          <div className="signupmodal_box2_subshape_img"></div>
                        </div>
                      </div>
                      <div className="signupmodal_box2_text">
                        <span>Broker or Agent</span>
                      </div>
                    </label>

                    <input type="radio" className="userradio" name="userType" id="thirdUser" />
                    <label htmlFor="thirdUser"
                      className="signupmodal_box3 user-type"
                      onClick={() => {
                        setTypeOfProperty(3);
                      }}
                    >
                      <div className="signupmodal_box3_shape1">
                        <div className="signupmodal_box3_subshape1">
                          <div className="signupmodal_box3_subshape_img"></div>
                        </div>
                      </div>
                      <div className="signupmodal_box3_text">
                        <span>Property Manager or Landlord</span>
                      </div>
                    </label>

                    <input type="radio" className="userradio" name="userType" id="fourthUser" />
                    <label htmlFor="fourthUser"
                      className="signupmodal_box4 user-type"
                      onClick={() => {
                        setTypeOfProperty(4);
                      }}
                    >
                      <div className="signupmodal_box4_shape1">
                        <div className="signupmodal_box4_subshape1">
                          <div className="signupmodal_box4_subshape_img"></div>
                        </div>
                      </div>
                      <div className="signupmodal_box1_text">
                        <span>Developer or Property Investor</span>
                      </div>
                    </label>

                    <input type="radio" className="userradio" name="userType" id="fiftUser" />
                    <label htmlFor="fiftUser"
                      className="signupmodal_box5 user-type"
                      onClick={() => {
                        setTypeOfProperty(5);
                      }}
                    >
                      <div className="signupmodal_box5_shape1">
                        <div className="signupmodal_box5_subshape1">
                          <div className="signupmodal_box5_subshape_img"></div>
                        </div>
                      </div>
                      <div className="signupmodal_box1_text">
                        <span>FRBHO or FSBHO</span>
                      </div>
                    </label>
                  </div>

                  <div className="signupmodal_action_button">
                    <button
                      className="signupmodal_button"
                      type="submit"
                    //   onClick={userDetailsContinue}
                      disabled={typeOfProperty === 0}
                    >
                      <span className="signupmodal_button_text">
                        Continue
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>{" "}
        </div> */}
    </>
  )
}

export default Homepage
