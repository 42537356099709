import {
  api,
} from '@REST/api';

import * as effects from 'redux-saga/effects';
import { documentActions } from '@bus/document/actions';
import { PayloadAction } from '@reduxjs/toolkit';
import { FetchDocumentActionPayload, FillDocumentActionPayload } from '@bus/document/typedefs';

export function* fetchDocument({
  payload,
}: PayloadAction<FetchDocumentActionPayload>) {
  try {
    yield effects.put(documentActions.startFetching());
    const response: Response = yield effects.apply(api, api.get, [
      { endpoint: `api/property/documents/${payload.id}/`, unsafe: true },
    ]);

    if (!response.ok) {
      throw new Error('Unhandled error');
    }

    const data: FillDocumentActionPayload = yield effects.call([
      response,
      'json',
    ]);

    yield effects.put(documentActions.fillDocument(data));

  } catch (e) {
  } finally {
    yield effects.put(documentActions.stopFetching());
  }
}
