import { createAction } from '@reduxjs/toolkit';

import search2Slice from './slice';
import prepareActions from "../../helpers/prepareActions";

export const search2Actions = {
  ...search2Slice.actions,
  applySearch: createAction('search2/applySearch', prepareActions.movePromiseToMeta),
  fetchLeaseType: createAction('search2/fetchLeaseType'),
};
