import {
  api,
} from '@REST/api';

import * as effects from 'redux-saga/effects';
import { salesPersonActions } from '@bus/salesPerson/actions';
import { PayloadAction } from '@reduxjs/toolkit';
import { FillSalesPersonActionPayload, LoadMorePersonPayload } from '@bus/salesPerson/typedefs';
import { getToken } from '../../../../appVariable/variable';

export function* loadMorePerson({
  payload,
}: PayloadAction<LoadMorePersonPayload>) {
  try {
    const token = getToken();
    yield effects.put(salesPersonActions.startFetching());
    yield effects.put(salesPersonActions.startFetchingMore());
    const body: string = yield effects.call(JSON.stringify, { user: token.userId });
    const response: Response = yield effects.apply(api, api.post, [
      { endpoint: payload.url, body, direct: true },
    ]);

    if (!response.ok) {
      throw new Error('Unhandled error');
    }

    const data: FillSalesPersonActionPayload = yield effects.call([
      response,
      'json',
    ]);

    yield effects.put(salesPersonActions.pushMoreSalesPerson(data));

  } catch (e) {
  } finally {
    yield effects.put(salesPersonActions.stopFetching());
    yield effects.put(salesPersonActions.stopFetchingMore());
  }
}
