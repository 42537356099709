import React from "react";
import "./personal.scss";
// import bgprogile from "./images/bgprofile.png";
// import prifilepic from "./images/prifilepic.png";
import camera from "./images/camera.svg";
// import edit from "./images/edit.svg";
import { Col, Row } from "react-bootstrap";
import { useFormik } from "formik";
import Select from "react-select";
import { useEffect } from "react";
import { getAgentDetails, getLanguages, getStates, getTowns, submitProfileData } from "./server";
import { useState } from "react";
import swal from "sweetalert";
import { getToken } from "../../../../../appVariable/variable";
import Loader from "../../../Loader/Loader";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { getAllArea, getAllzipcode } from "../../../personal/personalInformation/Server";
// import JoditEditor from "jodit-react";
// import { emailRegex } from "../teams/Teams";
import Editor from "../Editor/Editor";
import { useDispatch } from "react-redux";
import { updatePersonalInfo } from "./PersonalinfoSlice";
import ProfileEdit from "./ProfileEdit";
import icons from "../../../dashboard/components/sidebar/images/SidebarIcon";
// export { bgprogile, prifilepic }
import EditIcon from '@mui/icons-material/Edit';
import { ProfileAvatar } from "../../../../shared/profile-avatar";
import { setUserDetails } from '../../../../../REST/api';

let blockKeywords = /(\.com|\.net|\.org|\dot|gmail|mail|yahoo|hotmail|aol|@|\S+@\S+\.\S+)/g;

export { blockKeywords }



const Personal = (props) => {
  const dispatch = useDispatch();
  let token = getToken()
  let agentSlug = token?.profile.slug;
  const [language, setLanguage] = useState([]);
  const [states, setStates] = useState([]);
  const [towns, setTowns] = useState([]);
  const [previewProfileImg, setPreviewProfileImg] = useState(null);
  const [previewBannerImg, setPreviewBannerImg] = useState(icons.coverphoto);
  const [defaultState, setDefaultState] = useState({});
  const [defaultTown, setDefaultTown] = useState({});
  const [defaultArea, setDefaultArea] = useState({})
  const [defaultZipcode, setDefaultZipcode] = useState({})
  const [defaultLanguages, setDefaultLanguages] = useState([])
  const [loading, setLoading] = useState(false)
  const [allZipcodes, setAllZipcodes] = useState([])
  const [allArea, setAllArea] = useState([])
  const [licenseTitle, setLicenseTitle] = useState()
  const [propfileEditModal, setPropfileEditModal] = useState(false)

  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      nickName: "",
      email: "",
      workNumber: "",
      cellNumber: "",
      bannerImg: "",
      profileImg: "",
      cityId: "",
      stateId: "",
      zipcode: "",
      areaId: "",
      languageSpeak: "",
      personalBio: "",
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required("*first Name required").matches("^[a-zA-Z]*$", 'Number not accepted').nullable().max(20, "Must be 20 characters or less"),
      lastName: Yup.string().required("*Last Name required").matches("^[a-zA-Z]*$", 'Number not accepted').nullable().max(20, "Must be 20 characters or less"),
      // nickName: Yup.string().required("*Nick Name required").matches("^[a-zA-Z]*$", 'Number not accepted').nullable().max(20, "Must be 20 characters or less"),
      // workNumber: Yup.string().required("*Work Number is required").matches(/^[0-9]+$/, 'Please Added Work Number').nullable(),
      // cellNumber: Yup.string().required("*Cell Number is required").matches(/^[0-9]+$/, 'Please Added Cell Number').nullable(),
      // cityId: Yup.string().ensure().required("Topic is required!"),
      // languageSpeak: Yup.array().required("*Fill this field").nullable(),
      cellNumber: Yup.string().min(10, "Enter valid Number").max(10, "Enter valid Number").nullable(),
      workNumber: Yup.string().required("*Cell Number is required").min(10, "Enter valid Number").max(10, "Enter valid Number").nullable(),
      cityId: Yup.string().required("Fill this field").nullable(),
      stateId: Yup.string().required("Fill this field").nullable(),
      zipcode: Yup.string().required("Fill this field").nullable(),
      areaId: Yup.string().required("Fill this field").nullable(),
      personalBio: Yup.string().required("Fill this field"),
      languageSpeak: Yup.array()
        .min(1, "You can't leave this blank.")
        .required("You can't leave this blank.")
        .nullable(),
    }),


    onSubmit: (values) => {
      let formData = new FormData();
      let userDetails = getToken();
      formData.append("user_id", userDetails.userId);
      formData.append("cover_image", formik.values.bannerImg);
      formData.append("profile_image", formik.values.profileImg);
      formData.append("first_name", formik.values.firstName);
      formData.append("last_name", formik.values.lastName);
      formData.append("nickname", formik.values.nickName);
      formData.append("work_number", formik.values.workNumber);
      formData.append("cell_number", formik.values.cellNumber);
      formData.append("work_city_id", formik.values.cityId);
      formData.append("work_state_id", formik.values.stateId);
      formData.append("work_zipcode", formik.values.zipcode);
      formData.append("work_area_id", formik.values.areaId);
      formData.append("language_speak", JSON.stringify(formik.values.languageSpeak));
      formData.append("personal_bio", formik.values.personalBio);
      formData.append("email", formik.values.email);
      formData.append("time_zone", userTimeZone);
      formData.append("slug", `${userDetails.userId}-${values.firstName}-${values.lastName}`);
      setLoading(true)
      submitProfileData(formData, async (success) => {
        if (success === "error") {
          setLoading(false)
          toast.warning("Someting wrong pleasse try Again")
        } else {
          const fetchUserProfile = () => {
            return new Promise((resolve) => {
              getAgentDetails(agentSlug, (success) => resolve(success));
            });
          }
          const profile = await fetchUserProfile();
          // updating local storage
          const profileImg = profile[0].profile_image;
          const bannerImg = profile[0].cover_image || '';
          let updateuserDetails = userDetails
          updateuserDetails.profile.first_name = values.firstName
          updateuserDetails.profile.slug = `${userDetails.userId}-${values.firstName}-${values.lastName}`
          updateuserDetails.profile.profile_image = profileImg;
          setUserDetails(updateuserDetails);
          dispatch(updatePersonalInfo({ firstName: values.firstName, lastName: values.lastName, bannerImg, profile_image: profileImg }));
          props.setShowLicense(true)
          props.ChangeTab("third")
          setLoading(false)
        }
      });
    },
  });

  function getData() {
    getLanguages((success) => {
      let languageList = success.map((item) => { return { value: item.id, label: item.languages_name }; });
      setLanguage(languageList);
    });
    getStates((success) => {
      let stateList = success.map((item) => { return { label: item.state_name, value: item.id }; });
      setStates(stateList);
    });
    getTowns((success) => {
      let townsList = success.map((item) => { return { value: item.id, label: item.city_name }; });
      setTowns(townsList);
    });
  }

  function handleUploadBannerImg(e) {
    let fileSize = e.target.files[0].size;
    if (fileSize <= 5250000) {
      const file = e.target.files[0];
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        setPreviewBannerImg(fileReader.result);
        formik.setFieldValue("bannerImg", file);
      };
    } else {
      swal("", "Image size must be under 5mb", "warning");
    }
  }
  function handleUploadProfileImg(e) {
    if (e.target.files[0]) {
      let fileSize = e.target.files[0].size;
      if (fileSize <= 5250000) {
        const file = e.target.files[0];
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
          setPropfileEditModal(true)
          setPreviewProfileImg(fileReader.result);
          formik.setFieldValue("profileImg", file);
        };
      } else {
        swal("", "Image size must be under 5mb", "warning");
      }
    }
  }
  useEffect(() => {
    getAgentDetails(agentSlug, (success) => {
      // setAgentdetails(success);
      setLicenseTitle(success[5]?.lic_Type)
      formik.setFieldValue("firstName", success[0]?.first_name);
      formik.setFieldValue("lastName", success[0]?.last_name);
      formik.setFieldValue("nickName", success[0]?.nickname);
      // formik.setFieldValue("email",success[0]?.email);
      formik.setFieldValue("workNumber", success[0]?.work_number_1);
      formik.setFieldValue("email", success[1]);
      formik.setFieldValue("cellNumber", success[0]?.cell_number);
      formik.setFieldValue("cityId", success[0]?.citymaster.id);
      formik.setFieldValue("stateId", success[0]?.state.id);
      formik.setFieldValue("areaId", success[0]?.areamaster.id);
      formik.setFieldValue("languageSpeak", success[0]?.languages);
      formik.setFieldValue("personalBio", success[0]?.personal_bio);
      formik.setFieldValue("zipcode", success[0]?.zip_code);
      // formik.setFieldValue("profileImg",success[0]?.profile_image)
      // formik.setFieldValue("bannerImg",success[0]?.cover_image)
      if (success[0]?.profile_image !== null) { setPreviewProfileImg(`${success[0]?.profile_image}`);}
      if (success[0]?.cover_image !== null) { setPreviewBannerImg(`${success[0]?.cover_image}`); }
      let filterState = states?.filter((item) => item.value === success[0]?.state.id);
      setDefaultState(filterState[0]);

      let filterTown = towns?.filter((item) => item.value === success[0].citymaster.id);
      setDefaultTown(filterTown[0]);

      // let filterArea = allArea?.filter(
      //   (item) => item.value == success[0].areamaster.id
      // );
      // setDefaultArea(filterArea[0]);

      // let filterZipcode = allZipcodes?.filter(
      //   (item) => item.label == success[0].zip_code
      // );
      // setDefaultZipcode(filterZipcode[0]);

      getAllArea((myarea) => {
        let newarr = myarea.map((item) => { return { label: item.area_name, value: item.id, }; });
        setAllArea(newarr);
        let filterArea = newarr?.filter((item) => item.value === success[0].areamaster.id);
        setDefaultArea(filterArea[0]);
      })
      getAllzipcode((myzipcode) => {
        let newarr = myzipcode.map((item) => { return { label: `${item.Zipcode}`, value: item.id, }; });
        setAllZipcodes(newarr);
        let filterZipcode = newarr?.filter( (item) => item.label === success[0].zip_code);
        setDefaultZipcode(filterZipcode[0]);
      })

      let newArray = success[0].languages?.map((val) => {
        let a;
        a = language?.filter((item) => item.value === val);
        return a;
      });
      let myArray = [];
      myArray = newArray.flat();
      setDefaultLanguages(myArray)
    });
  }, [towns]);

  useEffect(() => {
    getAllzipcode((success) => {
      let newarr = success.map((item) => { return { label: `${item.Zipcode}`, value: item.id, }; });
      setAllZipcodes([...newarr]);
    })

    getAllArea((success) => {
      const AllArea = success.flat(1)
      let newarr = AllArea.map((item) => { return { label: item.area_name, value: item.id, }; });
      setAllArea([...newarr]);
    })
    getData();
  }, []);



  return (
    <>
      {loading ? <Loader /> : null}
      <div className="personal_page hide-scrollbar">
        <div className="profile_text">Personal</div>
        <div className="position-relative top-0 left-0 personal_bgimage">
          <div className="large_image">
            <img src={previewBannerImg} alt="" className="large_image" />
            {/* large image input upload */}
            <div className="large_image-upload">
              <label htmlFor="bannerImage" className="upload_image">
                <div className="bg_white center">
                  <img src={camera} style={{ width: "17px" }} alt="" />
                </div>
              </label>

              <input id="bannerImage" type="file" name="bannerImage"
                accept="image/png, image/jpeg"
                onChange={(e) => { handleUploadBannerImg(e); }}
              />
            </div>
          </div>
          <div className="small_img">
            {
              !previewProfileImg ?
                <ProfileAvatar height={87} width={87} /> :
                <img src={previewProfileImg} alt="" className="profile_piC" />
            }
            <div className="small_image-upload">
              <label htmlFor="profileimg" className="small_upload_image">
                <div className="bg_white center"><EditIcon style={{ fontSize:"10px" }}/></div>
              </label>
              <input id="profileimg" type="file" name="bannerImage" accept="image/png, image/jpeg"
                onChange={(e) => { handleUploadProfileImg(e); }}
              />
            </div>
          </div>
        </div>
        <div className="position-relative mt-4 pt-3 ms-5 ps-2 personal_texts">
          <p className=" text-capitalize">{formik.values.firstName} {formik.values.lastName}</p>
          <span className="pb-1 mb-3">{licenseTitle}</span>
        </div>

        <Row className="input_section pt-3 mt-2">
          <Col md="6">
            <div className="d-flex main_input">
              <div className="input_name grow-1 text-end">
                First Name<span className="red-star">*</span>
              </div>
              <div className="input-sec">
                <input type="text" className="form-control border-0 pb-0" name="firstName"
                  onChange={formik.handleChange}
                  value={formik.values.firstName}
                />
                {formik.touched.firstName && formik.errors.firstName ? (
                  <div className='validation-text'>{formik.errors.firstName}</div>
                ) : null}
              </div>
            </div>
          </Col>
          <Col md="6">
            <div className="d-flex main_input">
              <div className="input_name grow-1 text-end">
                Last Name<span className="red-star">*</span>
              </div>
              <div className="input-sec">
                <input type="text" className="form-control border-0 pb-0" name="lastName"
                  onChange={formik.handleChange}
                  value={formik.values.lastName}
                />
                {formik.touched.lastName && formik.errors.lastName ? (
                  <div className='validation-text'>{formik.errors.lastName}</div>
                ) : null}
              </div>
            </div>
          </Col>
          <Col md="6">
            <div className="d-flex main_input">
              <div className="input_name grow-1 text-end">
                Nickname
                {/* <span className="red-star">*</span> */}
              </div>
              <div className="input-sec">
                <input type="text" className="form-control border-0 pb-0" name="nickName"
                  onChange={formik.handleChange}
                  value={formik.values.nickName}
                />
                {formik.touched.nickName && formik.errors.nickName ? (
                  <div className='validation-text'>{formik.errors.nickName}</div>
                ) : null}
              </div>
            </div>
          </Col>
          <Col md="6">
            <div className="d-flex main_input">
              <div className="input_name grow-1 text-end">
                Work Email<span className="red-star">*</span>
              </div>
              <div className="input-sec">
                <input type="email" className="form-control border-0 pb-0" name="email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                />
              </div>
            </div>
          </Col>
          <Col md="6">
            <div className="d-flex main_input">
              <div className="input_name grow-1 text-end">
                Work Number
                 <span className="red-star">*</span>
              </div>
              <div className="input-sec">
                <input type="number" className="form-control border-0 pb-0" name="workNumber"
                  onChange={formik.handleChange}
                  value={formik.values.workNumber}
                />
                {formik.touched.workNumber && formik.errors.workNumber ? (
                  <div className='validation-text'>{formik.errors.workNumber}</div>
                ) : null}
              </div>
            </div>
          </Col>
          <Col md="6">
            <div className="d-flex main_input">
              <div className="input_name grow-1 text-end">
                Cell Phone
              </div>
              <div className="input-sec">
                <input type="number" className="form-control border-0 pb-0" name="cellNumber"
                  onChange={formik.handleChange}
                  value={formik.values.cellNumber}
                />
              </div>
            </div>
          </Col>

          <Col md="6" style={{ zIndex: "5" }} >
            <div className="d-flex main_input">
              <div className="input_name grow-1 text-end">
                Town<span className="red-star">*</span>
              </div>
              <div className="input-sec">
                <Select
                  className="custum-react-select select-wrapper"
                  classNamePrefix="select"
                  options={allArea}
                  value={[{ label: defaultArea?.label, value: defaultArea?.value },]}
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      boxShadow: "none",
                      border: "none",
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isFocused && "lightgray",
                      color: state.isFocused && "black",
                    }),
                  }}
                  onChange={(e) => {
                    setDefaultArea(e)// setAreaSelected(e);
                    formik.setFieldValue("areaId", e.value);
                  }}

                />
                {formik.touched.areaId && formik.errors.areaId ? (
                  <div className='validation-text'>{formik.errors.areaId}</div>
                ) : null}
              </div>
            </div>
          </Col>

          <Col md="6" style={{ zIndex: "5" }}>
            <div className="d-flex main_input">
              <div className="input_name grow-1 text-end">
                City <span className="red-star">*</span>
              </div>
              <div className="input-sec">
                {/* <input type="email" className="form-control border-0 pb-0" /> */}
                <Select
                  className="custum-react-select select-wrapper"
                  classNamePrefix="select"
                  options={towns}
                  value={[{ label: defaultTown?.label, value: defaultTown?.value },]}
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      boxShadow: "none",
                      border: "none",
                    }),
                    // menu: (provided, state) => ({
                    //   ...provided,
                    //   border: "none",
                    //   boxShadow: "none"
                    // }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isFocused && "lightgray",
                      color: state.isFocused && "black",
                    }),
                  }}
                  onChange={(e) => {
                    formik.setFieldValue("cityId", e.value);
                    setDefaultTown(e);
                  }}
                />
                {formik.touched.cityId && formik.errors.cityId ? (
                  <div className='validation-text'>{formik.errors.cityId}</div>
                ) : null}
              </div>
            </div>
          </Col>

          <Col md="6" style={{ zIndex: "3" }}>
            <div className="d-flex main_input">
              <div className="input_name grow-1 text-end">
                State<span className="red-star">*</span>
              </div>
              <div className="input-sec">
                {/* <input type="email" className="form-control border-0 pb-0" /> */}
                <Select
                  className="custum-react-select select-wrapper"
                  classNamePrefix="select"
                  options={states}
                  // defaultValue={{  label: "Nagaland",value: 2 }}
                  value={[{ label: defaultState?.label, value: defaultState?.value },]}
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      boxShadow: "none",
                      border: "none",
                    }),
                    // menu: (provided, state) => ({
                    //   ...provided,
                    //   border: "none",
                    //   boxShadow: "none"
                    // }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isFocused && "lightgray",
                      color: state.isFocused && "black",
                    }),
                  }}
                  onChange={(e) => {
                    formik.setFieldValue("stateId", e.value);
                    setDefaultState(e);
                  }}
                />
                {formik.touched.stateId && formik.errors.stateId ? (
                  <div className='validation-text'>{formik.errors.stateId}</div>
                ) : null}
              </div>
            </div>
          </Col>

          <Col md="6" style={{ zIndex: "3" }}>
            <div className="d-flex main_input">
              <div className="input_name grow-1 text-end">
                Zip Code<span className="red-star">*</span>
              </div>
              <div className="input-sec">
                <Select
                  className="custum-react-select select-wrapper"
                  classNamePrefix="select"
                  options={allZipcodes}
                  value={[{ label: defaultZipcode?.label, value: defaultZipcode?.value },]}
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      boxShadow: "none",
                      border: "none",
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isFocused && "lightgray",
                      color: state.isFocused && "black",

                    }),
                  }}
                  onChange={(e) => {// setZipcodeSelected(e)
                    setDefaultZipcode(e)
                    formik.setFieldValue("zipcode", e.label);
                  }}
                />
                {formik.touched.zipcode && formik.errors.zipcode ? (
                  <div className='validation-text'>{formik.errors.zipcode}</div>
                ) : null}
              </div>
            </div>
          </Col>

          <Col md="12" style={{ zIndex: "2" }}>
            <div className="d-flex main_input">
              <div className="input_name grow-1 text-end input_widthonly">
                Languages Spoken<span className="red-star">*</span>
              </div>
              <div className="input-sec">
                <Select className="custum-react-select select-wrapper" classNamePrefix="select"
                  options={language}
                  value={defaultLanguages?.map((item) => item)}
                  isMulti
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      boxShadow: "none",
                      border: "none",
                    }),
                    // menu: (provided, state) => ({
                    //   ...provided,
                    //   border: "none",
                    //   boxShadow: "none"
                    // }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isFocused && "lightgray",
                      color: state.isFocused && "black",
                    }),
                  }}
                  onChange={(e) => {
                    setDefaultLanguages(e)
                    let lang = e?.map((item) => { return item.value; });
                    formik.setFieldValue("languageSpeak", lang);
                  }}
                />
                {formik.touched.languageSpeak && formik.errors.languageSpeak ? (
                  <div className='validation-text'>{formik.errors.languageSpeak}</div>
                ) : null}
              </div>
            </div>
          </Col>

          {/* <Col md="12" style={{ zIndex: "2" }}>
            <div className="d-flex main_input">
              <div className="input_name grow-1 text-end input_widthonly">Time Zone<span className="red-star">*</span></div>
              <div className="input-sec">
                <Select className="custum-react-select select-wrapper" classNamePrefix="select"
                  options={language}  isMulti
                  value={defaultLanguages?.map((item) => item)}
                  styles={{
                    control: (provided, state) => ({ ...provided, boxShadow: "none", border: "none", }),
                    option: (provided, state) => ({ ...provided, backgroundColor: state.isFocused && "lightgray", color: state.isFocused && "black", }),
                  }}
                  onChange={(e) => {
                    setDefaultLanguages(e)
                    let lang = e?.map((item) => { return item.value; });
                    formik.setFieldValue("languageSpeak", lang);
                  }}
                />
                {formik.touched.languageSpeak && formik.errors.languageSpeak ? (
                  <div className='validation-text'>{formik.errors.languageSpeak}</div>
                ) : null}
              </div>
            </div>
          </Col> */}

          <Col md="12" className="profile_bio_text pt-2 mt-1 "> <p className="">Profile Bio</p></Col>

          <Col md="12" className="pt-4 mt-3">
            <div className="d-flex main_input">
              <div className="input_name grow-1 text-end input_widthonly border-end-0 height_zero align-items-start  ">
                Paragraph<span className="red-star">*</span>
              </div>
              <div className="input-sec flex-column h-auto">
                <Editor name="description"
                  onChange={(data) => { formik.setFieldValue("personalBio", data) }}
                  editorLoaded={true}
                  value={formik.values.personalBio.replace(blockKeywords, '')}
                />
                {formik.touched.personalBio && formik.errors.personalBio ? (
                  <div className='validation-text'>{formik.errors.personalBio}</div>
                ) : null}
              </div>
            </div>
          </Col>

          <Col md="12" className="text-end">
            <button className="save_btns" type="submit" onClick={formik.handleSubmit} >
              Submit
            </button>
          </Col>
        </Row>
      </div >

      {/* profile image adjucement section */}
      <ProfileEdit
        formik={formik}
        src={previewProfileImg}
        setPreview={setPreviewProfileImg}
        modalOpen={propfileEditModal}
        setModalOpen={setPropfileEditModal}
      />
    </>
  );
};

export default Personal;
