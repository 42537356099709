import {
  api,
} from '@REST/api';

import * as effects from 'redux-saga/effects';
import { salesPersonActions } from '@bus/salesPerson/actions';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  FillSavedAgentsActionPayload,
  LoadMoreSavedPersonPayload,
} from '@bus/salesPerson/typedefs';

export function* loadMoreSavedPerson({
  payload,
}: PayloadAction<LoadMoreSavedPersonPayload>) {
  try {
    yield effects.put(salesPersonActions.startFetching());
    yield effects.put(salesPersonActions.startFetchingMore());
    const response: Response = yield effects.apply(api, api.get, [
      { endpoint: payload.url, direct: true },
    ]);

    if (!response.ok) {
      throw new Error('Unhandled error');
    }

    const data: FillSavedAgentsActionPayload = yield effects.call([
      response,
      'json',
    ]);

    yield effects.put(salesPersonActions.pushMoreSavedPerson(data));

  } catch (e) {
  } finally {
    yield effects.put(salesPersonActions.stopFetching());
    yield effects.put(salesPersonActions.stopFetchingMore());
  }
}
