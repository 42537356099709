import { createSelector } from 'reselect';

const salesPersonSelector = (state: any) => state.salesPerson;

export const getIsFetched = createSelector([salesPersonSelector], ({ isFetched }) => {
  return isFetched;
});


export const getIsFetchedMore = createSelector([salesPersonSelector], ({ isFetchedMore }) => {
  return isFetchedMore;
});

export const getSalesPerson = createSelector([salesPersonSelector], ({ salesPerson }) => {
  return salesPerson?.results ?? [];
});

export const getSalesPersonNextUrl = createSelector([salesPersonSelector], ({ salesPerson }) => {
  return salesPerson?.next ?? null;
});

export const getSavedPersons = createSelector([salesPersonSelector], ({ savedPersons }) => {
  return savedPersons?.results ?? [];
});

export const getSavedPersonNextUrl = createSelector([salesPersonSelector], ({ savedPersons }) => {
  return savedPersons?.next ?? null;
});
