import { useSelector } from "react-redux";
import { GetSelectedConversationSid } from "../../../../store/selectors/message.selector";
import { useMemo } from "react";
import { Conversation } from "../../../../store/slices/message.slice";

export const useConversationClassNames = (
	{ conversation, lastMessageIndex }:
	{ conversation: Conversation, lastMessageIndex?: unknown }
) => {
	const selectedSid = useSelector(GetSelectedConversationSid);

	return useMemo(() => {
		const isConversationHasUnread = typeof lastMessageIndex == "number" && conversation.lastReadMessageIndex < lastMessageIndex;
		const isConversationSelected = conversation.sid === selectedSid;

		return {
			selected: isConversationSelected ? 'selected' : '',
			unread: !isConversationSelected && isConversationHasUnread ? 'unread' : ''
		}
	}, [lastMessageIndex, conversation, selectedSid]);
}