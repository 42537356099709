import React, { useEffect, useState } from "react";
import WedoCard from "./WedoCard";
import "./whatWedo.scss";
import { getCardDetails, getTitleDetails } from "./server";
import Slider from "react-slick";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Fade } from "react-awesome-reveal";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const WhatWedo = () => {
  const [data, setData] = useState()
  const [cardsData, setCardsData] = useState([])
  // const scrollRef = useRef(null);
  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (<ArrowBackIosIcon {...props} className="slider-arrow" style={{ left: 0}} />);
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (<ArrowForwardIosIcon {...props} className="slider-arrow " style={{ right: 0}} />);
  const Slidersettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 905,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]

  };

  function getTitle() {
    getTitleDetails((success) => { setData(success) });
  }
  function getCardsData() {
    getCardDetails((success) => {
      let listedCardsData = [];
      listedCardsData = success.map((item, index) => {
        return (
          {
            id: item.id,
            icon: `${item.Icon}`,
            title: item.Card_Title,
            description: item.Card_body
          }
        )
      })
      setCardsData(listedCardsData)
    })
  }
  useEffect(() => {
    getTitle();
    getCardsData()
  }, []);

  return (
    <>
      <div className=" what-wedo-sec span-to-top">
        <div className="what-wedo-sec-inner layout section d-flex flex-column position-relative">
          <div className="what-wedo-heading gap-4 d-flex flex-column">
            <h1 className="text-uppercase">{data?.Title}</h1>
            <p>{data?.Subtitle}</p>
          </div>
          <div className="card-container-box position-absolute">
            <Slider {...Slidersettings}>
              {cardsData.map((card, i) =>
                <Fade direction="up" delay={parseInt(i + "00")} key={`wedo${i}`}>
                  <WedoCard key={`wedoCardd${i}`} cardinfo={card} />
                </Fade>
              )}
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhatWedo;
