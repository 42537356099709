import Modal from 'react-bootstrap/Modal';
import { AiOutlineClose } from 'react-icons/ai';
import React from 'react';
import { IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export const ThankYouModal = ({ show, handleClose }) => {

  return (
    <Modal show={show} onHide={handleClose} size={'md'} centered>
      <Modal.Header className={'justify-content-end'}>
        <IconButton onClick={handleClose}>
          <AiOutlineClose size={28} />
        </IconButton>
      </Modal.Header>
      <Modal.Body className={'py-0'}>
        <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} p={'20px'}>
          <CheckCircleIcon sx={{ fontSize: '80px' }} htmlColor={'#315EFB'} />
          <Typography fontSize={'40px'} fontWeight={700}>Thank You!</Typography>
          <Typography textAlign={'center'}>Your advertising details has been successfully submitted. Thanks!</Typography>
        </Box>
      </Modal.Body>
    </Modal>
  );
};