import React from 'react';
import { FieldRenderProps } from 'react-final-form';

import { Box, TextField } from '@mui/material';

import { getFieldError } from '@core/FinalForm/getFieldError';
import { styles } from '@components/forms/SubmitRequestForAdvertising/styles';
import Typography from '@mui/material/Typography';

export const RenderAirRightsField: React.FC<FieldRenderProps<boolean>> = ({
  input,
  meta,
}) => {
  return (
    <div className="filter-radio d-flex gap-3">
      <div className="d-flex gap-2">
        <input
          type="checkbox"
          name="available_air_rights_yes"
          className="Prop"
          id="available_air_rights_yes"
          checked={input.checked}
          onChange={input.onChange}
        />
      </div>
    </div>
  );
};
