import { Conversation as TwilioConversation } from "@twilio/conversations";
import { getToken } from "../../../../appVariable/variable";
import { useEffect, useMemo } from "react";
import { useParticipantTyping } from "@components/pages/Message/hooks/useParticipantTyping";
import { useFormatMessageLastDate } from "@components/pages/Message/hooks/useFormatMessageLastDate";
import { useDispatch, useSelector } from "react-redux";
import { addOrUpdateLastMessage } from "../../../../store/slices/message.slice";
import { transformTwilioMessage } from "../../../../store/transformers/message.transformer";
import { GetLastMessage } from "../../../../store/selectors/message.selector";

export const useLastMessage = (conversation?: TwilioConversation) => {
	const token = getToken();
	const dispatch = useDispatch();
	const message = useSelector(GetLastMessage(conversation?.sid));
	const participantTyping = useParticipantTyping(conversation!);
	const dateCreated = useFormatMessageLastDate(message?.dateCreated || null);
	const body = useMemo(() => {
		if (participantTyping) return `${participantTyping.fullName} typing ...`;
		const prefix = token.user_info.identity === message?.author ? 'You: ' : '';
		return `${prefix} ${message?.body || ''}`.trim();
	}, [participantTyping, message]);

	useEffect(() => {
		conversation?.getMessages(1)
			.then(({ items }) => {
				if (items[0]) {
					dispatch(addOrUpdateLastMessage({
						conversation_sid: items[0].conversation.sid,
						message: transformTwilioMessage(items[0]),
					}))
				}
			})
	}, [conversation]);

	return {
		body,
		dateCreated: message ? dateCreated : null,
		index: message?.index
	};
}