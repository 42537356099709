import React, { useEffect, useState } from 'react';
import { FieldRenderProps } from 'react-final-form';

import { Box, TextField } from '@mui/material';

import { getFieldError } from '@core/FinalForm/getFieldError';
import { styles } from '@components/forms/SubmitRequestForAdvertising/styles';
import Typography from '@mui/material/Typography';
import RemoveIcon from '@mui/icons-material/Remove';
import { updateComBedRoom } from '@components/pages/homepage/components/hero/HeroSlice';
import { updateFilterData } from '@components/pages/homepage/components/hero/FilterSlice';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch, useSelector } from 'react-redux';

export const RenderUnitsField: React.FC<FieldRenderProps<number>> = ({
  input,
  meta,
}) => {
  const getComBedRoom = useSelector((state: any) => state.search.comBedRoom);
  const dispatch = useDispatch();

  return (
    <div className="unit-box filter-row">
      <p className="filter-heading">Units</p>
      <div className="update-unit inner-shadow center justify-content-evenly">
        <RemoveIcon
          className="pointer"
          onClick={() => {
            const newCount = getComBedRoom > 0 ? getComBedRoom - 1 : 0;
            dispatch(updateComBedRoom(newCount));
            input.onChange(newCount);
          }}
        />
        {getComBedRoom}
        <AddIcon
          className="pointer"
          onClick={() => {
            const newCount = getComBedRoom + 1;
            dispatch(updateComBedRoom(newCount));
            input.onChange(newCount);
          }}
        />
      </div>
    </div>
  );
};
