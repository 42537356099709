import React from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { cleanFilterData, updateFilterData } from '../../hero/FilterSlice';
import { getPropertiesType } from '@bus/landing/selectors';
import { getCategories } from '@components/pages/homepage/components/hero/server';

const CommunityCard = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const propertiesType = useSelector(getPropertiesType);
  const userInfo = JSON.parse(localStorage.getItem('locationDetails'));
    const handleClick = () => {
      new Promise((resolve, reject) => {
        getCategories(
          propertiesType.find((item) => item.property_listing_name === props.data.propertylisting_type).id,
          (success) => {
            resolve(success[0]?.id);
          },
          reject
        );
      }).then((data) => {
          dispatch(cleanFilterData());
          dispatch(
            updateFilterData({
              city: props.data.cityId,
              property_listing: propertiesType.find((item) => item.property_listing_name === props.data.propertylisting_type).id,
              category: data,
              latitude: userInfo?.latitude,
              longitude: userInfo?.longitude,
            })
          );
          navigate('dashboard/quik-view');
      })
        .catch();
    };

    return (
        <div className="community-card d-flex flex-column">
            <div className="card-img">
                <img src={props.data.img} alt="" />
            </div>
            <div className="card-bottom text-uppercase pointer center" onClick={handleClick}>
                <p>{props.data.button}</p>
            </div>
        </div>
    )
}

export default CommunityCard
