import React, { useState } from 'react'
import "./Sidebar.scss"
import Sidelink from './Sidelink'
import icons from "./images/SidebarIcon"


const Sidebar = ({
     sidebar,
     sidebardata,
     result,
     Gotolink,
     pointerEvent,
     settingOption,
 }) => {
    const [settingoption, setSettingoption] = useState(false)

    return (
        <div className={pointerEvent ? "sidebar" : "sidebar sidebar-disable"}>
            <div className="sub-sidebar d-flex flex-column" style={{gap: '50px'}}>
                <div className='sidebar-links d-flex flex-column' onClick={() => setSettingoption(false)}>
                    {sidebardata?.map((link, i) =>
                        <Sidelink icon={link.icon} link={link.link} title={link.title}
                            sidebar={sidebar} key={`agent${i}`} />
                    )}
                </div>
                <div className={settingOption ? 'position-relative setting-option' : 'position-relative'}>
                    <div className="setting_Droupdown box-shodow flex-column gap-2 position-absolute" style={{ display: settingoption && result ? 'flex' : "none" }}>
                        <p onClick={() => Gotolink(`/agent-dashboard/profile-settings`)}>Account Settings</p>
                        <p onClick={() => Gotolink(`/agent-dashboard/plan&billing`)}>Plan & Billing</p>
                    </div>
                    <div onClick={() => setSettingoption(!settingoption)} className=" pointer">
                        <Sidelink icon={icons.settings} link={'profile-setting'} title={'Settings'} sidebar={sidebar} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Sidebar
