import { FC, useEffect } from "react";
import {
	Conversation,
	removeParticipantTyping,
	setParticipantTyping,
	setSelectedConversation
} from "../../../../store/slices/message.slice";
import { useDispatch } from "react-redux";
import { ProfileAvatar } from "@components/shared/profile-avatar";
import { useConversationClassNames } from "@components/pages/Message/hooks/useConversationClassNames";
import { useChatAttributes } from "@components/pages/Message/hooks/useChatAttributes";
import { useTwilioConversation} from "@components/pages/Message/hooks/useTwilioConversation";
import { useLastMessage } from "@components/pages/Message/hooks/useLastMessage";

type ChatListItemProps = {
	conversation: Conversation
}

export const ChatListItem: FC<ChatListItemProps> = ({ conversation }) => {
	const dispatch = useDispatch();
	const chatAttributes = useChatAttributes(conversation);
	const twilioConversation = useTwilioConversation(conversation);
	const lastMessage = useLastMessage(twilioConversation);
	const classes = useConversationClassNames({
		conversation,
		lastMessageIndex: lastMessage.index
	});
	const chatPersonClassName = `${classes.selected} ${classes.unread}`.trim();

	useEffect(() => {
		const conversation_sid = conversation.sid;
		twilioConversation?.on('typingStarted', (participant) => {
			dispatch(
				setParticipantTyping({conversation_sid, identity: participant.identity || ''})
			)
		});
		twilioConversation?.on('typingEnded', (participant) =>
			dispatch(
				removeParticipantTyping({ conversation_sid, identity: participant.identity || '' })
			)
		);
	}, [twilioConversation]);

	const handleClick = async () => {
		dispatch(
			setSelectedConversation(conversation.sid),
		);
	}

	return (
		<div
			className={`chat-person d-flex align-items-center position-relative pointer ${chatPersonClassName}`}
			onClick={handleClick}
		>
			<div className="chat-profile">
				<ProfileAvatar
					width={50}
					height={50}
					profileInstance={chatAttributes.avatar}
				/>
			</div>
			<div className="prrson-name">
				<p>{chatAttributes.name}</p>
				<p className='chat-summery mt-2'>{ lastMessage.body }</p>
			</div>
			<div className="message-time position-absolute">
				<p>{ lastMessage.dateCreated }</p>
			</div>
		</div>
	)
}