import {
  api,
} from '@REST/api';

import * as effects from 'redux-saga/effects';
import { salesPersonActions } from '@bus/salesPerson/actions';
import { getToken } from '../../../../appVariable/variable';
import { FillSavedAgentsActionPayload } from '@bus/salesPerson/typedefs';

export function* fetchSavedAgents(){
  try {
    let token = getToken();
    yield effects.put(salesPersonActions.startFetching());
    const response: Response = yield effects.apply(api, api.get, [
      { endpoint: `api/user/SalespersonSavedbyGuest/${token.userId}` },
    ]);

    if (!response.ok) {
      throw new Error('Unhandled error');
    }

    const data: FillSavedAgentsActionPayload = yield effects.call([
      response,
      'json',
    ]);

    yield effects.put(salesPersonActions.fillSavedPersons(data));
  } catch (e) {
  } finally {
    yield effects.put(salesPersonActions.stopFetching());
  }
}
