import React, { useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import "../documents.scss";
import { changeDocTitle } from '../server';
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { styles } from './styles';
import { documentActions } from '@bus/document/actions';
import { useDispatch } from 'react-redux';

const rows = [
  {
    name: "Document 01:",
    Signed: "Signed by ",
  },
];

const DocumentItemSigned = ({ document, handleCheck, isChecked }) => {
  const [title, setTitle] = useState(document.filename);
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };

  const handleDelete = () => {
    dispatch(documentActions.deleteSelectedDocuments({ ids: [document.id] }));
    toast.success('Deleted Successfully');
  };

  return (
    <div
      className="d-flex flex-column"
      style={{ margin: "auto", marginTop: "0.5rem", paddingTop: "0.2rem" }}
    >
      <Box sx={styles.root}>
        <Box display={'flex'} alignItems={'center'} gap={'10px'}>
          <input
            type={'checkbox'}
            name={'docs'}
            value={document.id}
            id={document.id}
            onChange={handleCheck}
            checked={isChecked}
          />
          <Typography>{document.filename}</Typography>
        </Box>
        <Box sx={styles.actionWrapper}>
          <Box mr={'10px'}>
            <Typography>
              {new Date(
                document.updated_at.toLocaleString()?.toString(),
              ).toLocaleDateString('en-US', options)}
            </Typography>
          </Box>
          <Box minWidth={'200px'}>
            <Typography
              sx={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                maxWidth: '180px',
              }}>
              {rows[0].Signed}
              {document?.userprofile?.first_name?.length > 0 ||
              document?.userprofile?.last_name?.length > 0
                ? ` ${
                  document.userprofile.first_name?.length > 0
                    ? document.userprofile.first_name
                    : ''
                }  ${
                  document.userprofile.last_name_name?.length > 0
                    ? document.userprofile.last_name
                    : ''
                }`
                : document.userprofile.email.split('@')[0]}
            </Typography>
          </Box>
          <IconButton onClick={() => setShowModal(true)}>
            <DriveFileRenameOutlineIcon />
          </IconButton>
          <IconButton onClick={handleDelete}>
            <DeleteForeverIcon />
          </IconButton>
        </Box>
      </Box>
      <Modal
        className="dashboard-filter-popup"
        size="lg"
        centered
        show={showModal}
        onHide={() => setShowModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header className=" pb-0" closeButton>
          {" "}
        </Modal.Header>
        <Modal.Body>
          <formik>
            <div className="dashborad-filter-body d-flex flex-column gap-4">
              <div className="price-sec  filter-row ">
                <div className="d-flex flex-row"></div>
              </div>
              <div className="price-sec  filter-row ">
                <div className="d-flex justify-content-between">
                  <div className="validation-text text-capitalize">
                    Change Document Title
                  </div>
                </div>
                <div className="price-input-box justify-content-between">
                  <div
                    className="enter-input inner-shadow center flex-grow-1"
                    style={{ maxWidth: "none" }}
                  >
                    <input
                      type="textarea"
                      id=""
                      placeholder="Write Here..."
                      name="filtername"
                      onChange={(e) => {
                        setTitle(e.target.value);
                      }}
                      value={title}
                    />
                  </div>
                  <div
                    className="d-flex justify-content-end "
                    style={{ marginTop: "12px" }}
                  >
                    {/* <p>{editSchema?.notes?.length}/180charater</p> */}
                  </div>
                </div>
              </div>

              <div className="filter-btn-grp d-flex gap-4 justify-content-end">
                <button
                  className="btn"
                  style={{ width: "135px", borderRadius: "20px" }}
                  onClick={() => setShowModal(false)}
                >
                  Cancel
                </button>
                <button
                  className="btn"
                  style={{ width: "135px", borderRadius: "20px" }}
                  onClick={() => {
                    changeDocTitle(document.id, title, (success) => {
                      try {
                        if (success.code === 200) {
                          dispatch(documentActions.updateDocumentTitle({
                            id: document.id,
                            title,
                          }));
                          setShowModal(false);
                          toast.success("Successfully updated title");
                        }
                      } catch (error) {
                        console.log(error);
                        toast.success("There is an error");
                      }
                    });
                  }}>
                  Submit
                </button>
              </div>
            </div>
          </formik>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default DocumentItemSigned;
