import { useEffect, useMemo, useState } from "react";
import { GetProperty } from "@components/pages/agent-dash-sales/components/server";

export const usePropertyDetails = (attributes: { propertyDetails?: { slug: string } }) => {
	const [details, setDetails] = useState<any>(null);

	const slug = useMemo(() => {
		if (!attributes?.propertyDetails) return null;
		return attributes.propertyDetails?.slug;
	}, [attributes]);

	useEffect(() => {
		if (slug) {
			GetProperty(slug, ({ data }: any) => {
				setDetails(data.data);
			});
		}
	}, [slug]);

	return details;
}