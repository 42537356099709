import React, { useCallback, useEffect, useRef, useState } from 'react';
import "./allSelPerson.scss";
import SelpersonCard from "./components/SelpersonCard";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import SelPerson from "./components/SelPerson";
import { getToken } from "../../../appVariable/variable";
import { useDispatch, useSelector } from 'react-redux';
import { salesPersonActions } from '@bus/salesPerson/actions';
import {
  getIsFetched, getIsFetchedMore,
  getSalesPerson,
  getSalesPersonNextUrl,
  getSavedPersonNextUrl,
  getSavedPersons,
} from '@bus/salesPerson/selectors';

const AllSelPerson = () => {
  const token = getToken();
  const [selectForSave, setSelectForSave] = useState([]);
  const [selectForDelete, setSelectForDelete] = useState([]);
  const [activeTab, setActiveTab] = useState('home');
  const dispatch = useDispatch();
  const searchPersons = useSelector(getSalesPerson);
  const savedAgents = useSelector(getSavedPersons);
  const isFetching = useSelector(getIsFetched);
  const nextUrl = useSelector(getSalesPersonNextUrl);
  const nextSavedUrl = useSelector(getSavedPersonNextUrl);
  const isFetchingMore = useSelector(getIsFetchedMore);
  const containerRef = useRef(null);
  const containerSavedRef = useRef(null);

  const loadMoreData = useCallback(() => {
    if (nextUrl && !isFetching && !isFetchingMore) {
      dispatch(salesPersonActions.loadMorePerson({ url: nextUrl }));
    }
  }, [nextUrl, isFetching, dispatch, isFetchingMore]);

  const loadMoreSavedData = useCallback(() => {
    if (nextSavedUrl && !isFetching && !isFetchingMore) {
      dispatch(salesPersonActions.loadMoreSavedPerson({ url: nextSavedUrl }));
    }
  }, [nextSavedUrl, isFetching, dispatch, isFetchingMore]);

  useEffect(() => {
    if (containerRef.current) {
      const { clientHeight, scrollHeight } = containerRef.current;
      if (scrollHeight <= clientHeight && nextUrl && !isFetching) {
        loadMoreData();
      }
    }
  }, [searchPersons, loadMoreData]);

  useEffect(() => {
    if (activeTab === 'SavePerson' && containerSavedRef.current) {
      const { clientHeight, scrollHeight } = containerSavedRef.current;
      if (scrollHeight <= clientHeight && nextSavedUrl && !isFetching) {
        loadMoreSavedData();
      }
    }
  }, [savedAgents, loadMoreSavedData, activeTab]);

  const selectPerson = useCallback((e) => {
    const { checked, id, name } = e.target;
    if (name === 'save') {
      setSelectForDelete([]);
      dispatch(salesPersonActions.selectSalesPerson({ id: +id, checked, }));
      setSelectForSave((prev) => checked ? [...prev, +id] : prev.filter(v => v !== +id));
    }
    if (name === 'delete') {
      setSelectForSave([]);
      dispatch(salesPersonActions.selectSavedPerson({ id: +id, checked, }));
      setSelectForDelete((prev) => checked ? [...prev, +id] : prev.filter(v => v !== +id));
    }
  }, []);

  const saveSelected = () => {
    if (selectForSave.length > 0) {
      const paramObj = { user_id: token.userId, sales_person_id: selectForSave };
      new Promise((resolve, reject) => {
        dispatch(
          salesPersonActions.savePerson({
            values: paramObj,
            resolve,
            reject,
          }),
        );
      })
        .then(() => {
          setSelectForSave([]);
        })
        .catch();
    } else {
      alert("Please select persons to save");
    }
  };

  const removeSelectedAgents = () => {
    if (selectForDelete.length > 0) {
      const paramObj = { sales_person_id: selectForDelete };
      new Promise((resolve, reject) => {
        dispatch(
          salesPersonActions.deletePerson({
            values: paramObj,
            resolve,
            reject,
          }),
        );
      })
        .then(() => {
          setSelectForDelete([]);
        })
        .catch();
    } else {
      alert("Please select persons to save");
    }
  };

  const saveAllPerson = () => {
    let setallCheck = [];
    for (let per of searchPersons) {
      setallCheck = [...setallCheck, per.id];
    }
    if (setallCheck.length) {
      let paramObj = { sales_person_id: setallCheck };
      new Promise((resolve, reject) => {
        dispatch(
          salesPersonActions.savePerson({
            values: paramObj,
            resolve,
            reject,
          }),
        );
      })
        .then(() => {
          setSelectForSave([]);
        })
        .catch();
    }
  };

  const removeAllAgents = () => {
    const setAllCheck = savedAgents.map((item) => item.id);
    if (setAllCheck.length) {
      const paramObj = { sales_person_id: setAllCheck };
      new Promise((resolve, reject) => {
        dispatch(
          salesPersonActions.deletePerson({
            values: paramObj,
            resolve,
            reject,
          }),
        );
      })
        .then(() => {
          setSelectForDelete([]);
        })
        .catch();
    }
  };

  useEffect(() => {
    dispatch(salesPersonActions.fetchSavedAgents());
  }, []);

  useEffect(() => {
    new Promise((resolve, reject) => {
      dispatch(
        salesPersonActions.searchPerson({
          values: {
            user: token.userId,
            search: null,
            email: null,
            zipcode: null,
            start_rating: null,
            language: null,
          },
          resolve,
          reject,
        }),
      );
    })
      .then()
      .catch();
  }, []);

  const handleScroll = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target;
    const bottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;

    if (bottom) {
      !isFetching &&
      nextUrl &&
      dispatch(
        salesPersonActions.loadMorePerson({
          url: nextUrl,
        }),
      );
    }
  };

  const handleSavedScroll = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target;
    const bottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;

    if (bottom) {
      !isFetching &&
      nextSavedUrl &&
      dispatch(
        salesPersonActions.loadMoreSavedPerson({
          url: nextSavedUrl,
        }),
      );
    }
  };

  const handleSelectTab = (key) => {
    setActiveTab(key);
  };

  return (
    <div className="AllSelPerson_page">
      <div className="main_selperson_tab h-100">
        <Tabs
          defaultActiveKey="home"
          activeKey={activeTab}
          onSelect={handleSelectTab}
          id="justify-tab-example"
          className="mb-3"
          justify>
          <Tab eventKey="home" title="All Salesperson">
            <div className="sel_person_section  d-flex justify-content-between mb-4">
              <SelPerson />
              <div className=" d-flex gap-3">
                <button
                  className="blue__btn all_savebtn"
                  onClick={saveSelected}>
                  Save Selected
                </button>
                <button
                  className="blue__btn all_savebtn"
                  onClick={() => saveAllPerson()}>
                  Save All
                </button>
              </div>
            </div>

            <div
              className="d-flex flex-wrap card_gap scrollS"
              onScroll={handleScroll}
              ref={containerRef}
              style={{ maxHeight: "70vh" }}>
              {searchPersons?.map((person) => (
                <SelpersonCard
                  key={person.id}
                  info={person}
                  onclick={selectPerson}
                  name="save"
                />
              ))}
            </div>
          </Tab>
          <Tab eventKey="SavePerson" title="Saved Salesperson">
            <div className="sel_person_section  d-flex justify-content-between mb-4">
              <div></div>
              <div className=" d-flex gap-3">
                <button
                  className="blue__btn all_savebtn"
                  onClick={removeSelectedAgents}>
                  Delete Selected
                </button>
                <button
                  className="blue__btn all_savebtn"
                  onClick={removeAllAgents}>
                  Delete All
                </button>
              </div>
            </div>
            <div
              className="d-flex flex-wrap card_gap scrollS"
              onScroll={handleSavedScroll}
              ref={containerSavedRef}
              style={{ maxHeight: "70vh" }}>
              {savedAgents?.map((person) => (
                <SelpersonCard
                  key={person.id}
                  info={person}
                  onclick={selectPerson}
                  name="delete"
                />
              ))}
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default AllSelPerson;
