import * as effects from 'redux-saga/effects';
import { api } from '../../../../REST/api';

import { landingActions } from '../../actions';
import { PayloadAction } from '@reduxjs/toolkit';

export function* fetchRecentListing({
  payload,
}: PayloadAction<any>) {
  try {
    yield effects.put(landingActions.startFetching());
    const response: Response = yield effects.apply(api, api.get, [
      {
        endpoint: `api/property/recent-listings/`,
        query: payload.query,
        unsafe: true,
      },
    ]);

    if (!response.ok) {
      throw new Error('Unhandled error');
    }

    const data: unknown = yield effects.call([
      response,
      'json',
    ]);

    yield effects.put(landingActions.fillRecentListing(data));

  } catch (e) {
  } finally {
    yield effects.put(landingActions.stopFetching());
  }
}
