import { FC, useMemo } from "react";
import { UserDocument } from "@components/pages/Message/components/DocumentsPicker";
import { Avatar, Box } from "@mui/material";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import PhotoIcon from '@mui/icons-material/Photo';

type MessageFileProps = {
	doc: UserDocument;
	isIncoming?: boolean;
}

export const MessageFile: FC<MessageFileProps> = ({ doc, isIncoming }) => {
	const fileIcon = useMemo(() => {
		const extension = doc.file.split('.').pop()?.toLowerCase();
		switch (true) {
			case 'pdf' === extension: return <PictureAsPdfIcon />;
			case ['jpg', 'jpeg', 'png', 'gif', 'bmp'].includes(extension || ''): return <PhotoIcon />
			default: return <InsertDriveFileIcon />;
		}
	}, [doc.file]);
	const sx = isIncoming ? { bgcolor: '#fff', color: '#4e62b1' } : { bgcolor: '#4e62b1' };

	return (
		<>
			<Box
				display={'flex'}
				alignItems={'center'}
				gap={'10px'}
				component={'a'}
				href={doc.filename}
				download={doc.file}
				sx={{
					maxWidth: '200px',
					color: 'inherit!important'
				}}
			>
				<Box>
					<Avatar sx={sx}>
						{ fileIcon }
					</Avatar>
				</Box>
				<Box>{ doc.filename }</Box>
			</Box>
		</>
	)
}