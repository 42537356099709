import * as effects from 'redux-saga/effects';
import { api } from '../../../../REST/api';

import { landingActions } from '../../actions';

export function* fetchMainCategories() {
  try {
    yield effects.put(landingActions.startFetching());
    const response: Response = yield effects.apply(api, api.get, [
      { endpoint: `api/property/main-categories/`, unsafe: true },
    ]);

    if (!response.ok) {
      throw new Error('Unhandled error');
    }

    const data: unknown = yield effects.call([
      response,
      'json',
    ]);

    yield effects.put(landingActions.fillMainCategories(data));

  } catch (e) {
  } finally {
    yield effects.put(landingActions.stopFetching());
  }
}
