import React from 'react';
import { FieldRenderProps } from 'react-final-form';

export const RenderFeeField: React.FC<FieldRenderProps<number>> = ({
  input: {value, onChange, ...input},
  meta,
}) => {
  return (
    <div className="filter-radio d-flex gap-3">
      <div className="d-flex gap-2">
        <input
          type="radio"
          className="Prop"
          id="no-fee"
          value={0}
          checked={value === 0}
          onChange={onChange}
          {...input}
        />
        <label htmlFor="no-fee" className="pointer">
          No Fee
        </label>
      </div>
      <div className="d-flex gap-2">
        <input
          type="radio"
          className="Prop"
          id="fee"
          value={1}
          checked={value === 1}
          onChange={onChange}
          {...input}
        />
        <label htmlFor="fee" className="pointer">
          Fee
        </label>
      </div>
    </div>
  );
};
