import { all, call } from 'redux-saga/effects';
import { watchSearch2 } from '@bus/search2/saga/watchers';
import { watchUi } from '@bus/ui/saga/watchers';
import { watchDashboard } from '@bus/dashboard/saga/watchers';
import { watchLanding } from '@bus/landing/saga/watchers';
import { watchAuth } from '@bus/auth/saga/watchers';
import { watchAdvertise } from '@bus/advertise/saga/watchers';
import { watchDocument } from '@bus/document/saga/watchers';
import { watchNeighborhood } from '@bus/neighborhood/saga/watchers';
import { watchSalesPerson } from '@bus/salesPerson/saga/watchers';

export function* rootSaga() {
  yield all([
    call(watchSearch2),
    call(watchUi),
    call(watchDashboard),
    call(watchLanding),
    call(watchAuth),
    call(watchAdvertise),
    call(watchDocument),
    call(watchNeighborhood),
    call(watchSalesPerson),
  ]);
}
