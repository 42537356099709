import { call, put } from 'redux-saga/effects';

import {
  getAccessToken, getUserDetails,
  refreshToken,
  saveAccessToken,
  verifyToken,
} from '@REST/api';

import { authActions } from '../../actions';

export function* authenticate() {
  try {
    const { accessToken } = JSON.parse(getUserDetails() ?? '');
    if (accessToken) {
      const valid: boolean = yield call(verifyToken, accessToken);
      if (!valid) {
        const { access }: { access: string } = yield call(refreshToken);
        if (access) {
          yield call(saveAccessToken, access);
          yield put(authActions.isAuthenticatedTrue());
        } else {
          yield put(authActions.isAuthenticatedFalse());
          throw new Error('Your session is expired.');
        }
      } else {
        yield put(authActions.isAuthenticatedTrue());
      }
    }
  } catch (e) {
  } finally {
    yield put(authActions.initialize());
  }
}
