import { useTwilioClient } from "../twilio";
import { useMemo } from "react";
import { getToken } from "../appVariable/variable";
import axiosInstance from "@REST/axiosInstance";
import { Conversation } from "@twilio/conversations";

const storeConversation = (data: unknown) => {
	const token = getToken();

	return axiosInstance
		.post('/api/user/twilio-conversations/', data,{
			headers: { 'Authorization': `Bearer ${token?.idToken}` },
		});
}

const fetchAgentDefaultDocs = (conversation_sid: string, agent_identity: string) => {
	const token = getToken();

	return axiosInstance.get(
		`/api/user/conversation/get-default-documents?conversation_sid=${conversation_sid}&agent_identity=${agent_identity}`,
		{
			headers: { 'Authorization': `Bearer ${token?.idToken}` },
		});
}

const sendAgentDefaultDocs = async (conversation: Conversation, agentIdentity: string) => {
	let media = [];
	if (!(conversation.attributes as any)?.defaultFilesWereSent) {
		const res = await fetchAgentDefaultDocs(conversation.sid, agentIdentity);
		media = res.data.data.map((media: any) => ({ id: media.id, file: media.file, filename: media.filename }));
		await conversation.updateAttributes({ defaultFilesWereSent: true });
	}

	return media;
}

export const useContactNow = (selectedIds: string[], details: Record<string, any>) => {
	const twilio = useTwilioClient();
	const propertyDetails = useMemo(() => {
		return {
			id: details.propertDetails.id,
			slug: details.propertDetails.slug,
			mainImg: details.propertyMainImg,
		}
	}, [details]);

	const findOrCreateConversationAndAddParticipants = async (userIdentity: string, agentIdentity: string) => {
		const uniqueName = `user_${userIdentity}_agent_${agentIdentity}`;
		const conversation = await twilio.getOrCreateConversationByUniqueName(uniqueName);
		const isParticipant = await twilio.isParticipants(conversation, twilio.client!.user.identity);
		if (!isParticipant) await conversation.join();
		const isAgentParticipant = await twilio.isParticipants(conversation, agentIdentity);
		if (!isAgentParticipant) await conversation.add(agentIdentity);

		return conversation;
	}

	return async (message: string) => {
		if (!twilio.client) throw 'Client not initialized';
		if (!message.trim()) return;
		if (!selectedIds.length) return;

		const token = getToken();

		for (const agentIdentity of selectedIds) {
			const conversation = await findOrCreateConversationAndAddParticipants(
				token.user_info.identity,
				agentIdentity
			);

			await storeConversation({
				conversation_sid: conversation.sid,
				participant_identities: [
					token.user_info.identity,
					agentIdentity
				],
			});

			const media = await sendAgentDefaultDocs(conversation, agentIdentity);
			await conversation.sendMessage(message, { propertyDetails, media });
		}
	}
}