import { createSelector } from 'reselect';

const authSelector = (state: any) => state.auth;

export const getIsInitialize = createSelector([authSelector], ({ isInitialised }) => {
  return isInitialised;
});

export const getIsAuthenticate = createSelector([authSelector], ({ isAuthenticated }) => {
  return isAuthenticated;
});
