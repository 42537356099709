import { createSelector } from 'reselect';

const dashboardSelector = (state: any) => state.dashboard;

export const getPropertyConditions = createSelector([dashboardSelector], ({ propertyCondition }) => {
  return propertyCondition;
});

export const getClassTypes = createSelector([dashboardSelector], ({ classTypes }) => {
  return classTypes;
});
