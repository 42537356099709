import { getToken } from "../../../../appVariable/variable";
import axiosInstance from '../../../../REST/axiosInstance';

export function getagentaminities(success) {
  let token = getToken();
  axiosInstance
    .get(`api/property/AminitiesView/`, {
      headers: { Authorization: `Bearer ${token?.idToken}` },
    })
    .then((response) => {
      if (response.data.code === 200 && response.data.status === "success") {
        success(response.data.data);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

export function getTearmsOffers(success) {
  axiosInstance
    .get(`api/property/GetTermsOffer/`)
    .then((response) => {
      if (response.data.code === 200 && response.data.status === "success") {
        success(response.data.data);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

export function getStateList(success) {
  axiosInstance
    .get(`api/property/StateDashboard/`)
    .then((response) => {
      if (response.data.code === 200 && response.data.status === "success") {
        success(response.data.data);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

export function GetCityList(stateId, success) {
  axiosInstance
    .get(`api/property/CityDependentState/?state=${stateId}`)
    .then((response) => {
      if (response.data.code === 200 && response.data.status === "success") {
        success(response.data.data);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

export function GetAreaList(CityId, success) {
  axiosInstance
    .get(`api/property/AreaDependentCity/?city=${CityId}`)
    .then((response) => {
      if (response.data.code === 200 && response.data.status === "success") {
        success(response.data.data);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

export function getPet(success) {
  let token = getToken();
  axiosInstance
    .get(`api/master/PetViewAPI/`, {
      headers: { Authorization: `Bearer ${token?.idToken}` },
    })
    .then((resp) => {
      if (resp.data.code === 200 && resp.data.status === "success") {
        success(resp.data.data);
      } else {
        success(resp.data.message);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

export function AddProperty(formData, success, error) {
  let token = getToken();
  axiosInstance
    .post(`api/property/propertydetail/`, formData, {
      headers: { Authorization: `Bearer ${token?.accessToken}` },
    })
    .then((response) => {
      success(response);
    })
    .catch((err) => {
      error(err);
    });
}

export function GetProperty(slug, success) {
  let token = getToken();
  axiosInstance
    .get(`api/property/propertydetail/${slug}`, {
      headers: { Authorization: `Bearer ${token?.accessToken}` },
    })
    .then((response) => {
      success(response);
    })
    .catch((error) => {
      console.log(error);
    });
}

export function GetLeaseTypes() {
  const token = getToken();
  return axiosInstance.get('api/property/lease_types/', {
    headers: { Authorization: `Bearer ${token?.accessToken}` },
  });
}


export function GetPropertyConditions() {
  const token = getToken();
  return axiosInstance.get('api/property/property_conditions/', {
    headers: { Authorization: `Bearer ${token?.accessToken}` },
  });
}

export function EditListedProperty(formData, id, success, error) {
  let token = getToken();
  axiosInstance
    .put(`api/property/propertydetail/${id}`, formData, {
      headers: {
        Authorization: `Bearer ${token?.accessToken}`,
      },
    })
    .then((response) => {
      success(response);
    })
    .catch((err) => {
      error(err);
    });
}

export function ZipCodeAddress(zipObj, success) {
  let token = getToken();
  axiosInstance
    .post(`api/property/FindAreaCityState/`, zipObj, {
      headers: { Authorization: `Bearer ${token?.accessToken}` },
    })
    .then((response) => {
      success(response);
    })
    .catch((error) => {
      console.log(error);
    });
}
