import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UiState } from './typedefs';

const initialState: UiState = {
  isLandingPageScrolled: false,
  isSidebarOpen: false,
  neighborhoodTownLimit: 6,
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setLandingPageScrolled(state, action: PayloadAction<boolean>) {
      state.isLandingPageScrolled = action.payload;
    },
    setSidebarOpen(state, action: PayloadAction<boolean>) {
      state.isSidebarOpen = action.payload;
    },
  },
});

export default uiSlice;
