import { useEffect, useState } from "react";

export const AsyncSelectField = ({ title, name, onChange, value, fetchAsyncOptions }) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (fetchAsyncOptions) {
      fetchAsyncOptions().then(({ data }) => setOptions(data));
    }
  }, [fetchAsyncOptions]);

  return (
    <select
      aria-label="Default select example"
      name={name}
      value={value}
      onChange={onChange}
      style={{ border: '1px solid #4E62B1 !important' }}
    >
      <option
        value={undefined}
        selected={value === undefined}
        disabled
      >
        {title}
      </option>
      { options.map(
        (opt, index) => (
          <option
            key={`commertialOption${index}`}
            value={opt.id}
          >
            { opt.title }
          </option>
        ))
      }
    </select>
  );
}