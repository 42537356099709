import { getToken } from "../../../appVariable/variable";
import axiosInstance from '../../../REST/axiosInstance';



export async function getSaveSearchFilter(success) {
  const token = getToken()
  const userId = token?.userId;
  await axiosInstance.get(`api/property/filtersavesearchget/${userId}`)
    .then((resp) => {
      success(resp);
    })
    .catch((err) => {
      console.log(err);
    });
}

export async function getSaveSearchFilterPropertyDetail(id, success) {
  const token = getToken()
  const userId = token?.userId;
  await axiosInstance.get(`api/property/Filter/${id}?user_id=${userId}`, {
    headers: {
      Authorization: `Bearer ${token?.idToken}`,
    },
  })
    .then((resp) => {
      success(resp);
    })
    .catch((err) => {
      console.log(err);
    });
}

export async function DeleteSaveSearch(id, success) {
  await axiosInstance.delete(`api/property/filtersavesearch/${id}`)
    .then((resp) => {
      success(resp.data);
    })
    .catch((err) => { });
}