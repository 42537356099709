import { getToken } from "../../../../../appVariable/variable";
import axiosInstance from '../../../../../REST/axiosInstance';

export async function getDefaultPropertyShow(pagination, success) {
  let token = getToken();
  let userId = token?.userId;
  await axiosInstance
    .get(
      `api/property/GetPropertyBasedOnLocation/?userid=${userId}&page=${pagination}&cityid=${11}`,
      {
        headers: { Authorization: `Bearer ${token?.idToken}` },
      }
    )
    .then((response) => {
      success(response);
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function getDefaultPropertyDetailPopupWithOutLogin(id, success) {
  await axiosInstance
    .get(`api/property/getpropertydetail/?propertyid=${id}`)
    .then((response) => {
      success(response);
    })
    .catch((error) => {
      console.log(error);
    });
}

// getting info about property
export async function getDefaultPropertyDetailPopup(id, success) {
  let token = getToken();
  let userId = token?.userId;
  await axiosInstance
    .get(`api/property/getpropertydetail/?${userId ? `propertyid=${id}&userid=${userId}` : `propertyid=${id}`}`)
    .then((response) => {
      success(response);
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function getDefaultPropertyAmenityDetailPopup(id, success) {
  await axiosInstance
    .get(`api/property/getpropertyamenities/?propertyid=${id}`)
    .then((response) => {
      success(response);
    })
    .catch((error) => {
      console.log(error);
    });
}
export async function getDefaultPropertySpacesDetailPopup(id, success) {
  await axiosInstance
    .get(`api/property/getpropertyspaces/?propertyid=${id}`)
    .then((response) => {
      success(response);
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function getAgentTime(id, success) {
  await axiosInstance
    .get(`api/property/Get30min/?propertyid=${id}`)
    .then((response) => {
      success(response);
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function getDefaultPropertySalesFeatures(id, success) {
  await axiosInstance
    .get(`api/property/GetlistingDate/?propertyid=${id}`)
    .then((response) => {
      success(response);
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function getDefaultPropertyImagesDetailPopup(id, success) {
  await axiosInstance
    .get(`api/property/getpropertyimage/?propertyid=${id}`)
    .then((response) => {
      success(response);
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function getTeamMemberShow(id, success) {
  await axiosInstance
    .get(`api/property/teammembershow/${id}`)
    .then((response) => {
      success(response);
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function getAllpropertyInfo(id, success) {
  let token = getToken();
  let userId = token?.userId;
  try {
    const responses = await Promise.all([
      axiosInstance.get(
        `api/property/getpropertydetail/?${
          userId ? `propertyid=${id}&userid=${userId}` : `propertyid=${id}`}`
      ),
      axiosInstance.get(
        `api/property/getpropertyamenities/?propertyid=${id}`
      ),
      axiosInstance.get(
        `api/property/getpropertyspaces/?propertyid=${id}`
      ),
      axiosInstance.get(`api/property/Get30min/?propertyid=${id}`),
      axiosInstance.get(`api/property/GetlistingDate/?propertyid=${id}`),
      axiosInstance.get(
        `api/property/getpropertyimage/?propertyid=${id}`
      ),
      axiosInstance.get(`api/property/teammembershow/${id}`),
    ]);

    const dataObject = {
      propertDetails: responses[0]?.data.data.property,
      propertyMainImg: responses[0]?.data.data.property?.property_main_image,
      amenities: responses[1]?.data.data.amenities
        ? responses[1]?.data.data.amenities
        : null,
      SpaceAvailable: responses[2]?.data.data.spaces
        ? responses[2]?.data.data.spaces
        : null,
      agentTIme: responses[3]?.data.data ? responses[3]?.data.data : null,
      SalesFeatures: responses[4]?.data.data ? responses[4]?.data.data : null,
      propertyImg: responses[5]?.data?.data?.image
        ? responses[5]?.data?.data?.image
        : [],
      propertyTeamMember:
        responses[6]?.data?.code === 200 ? responses[6]?.data?.data : null,
    };
    success(dataObject);
  } catch (error) {
    success(null);
    console.log(error);
  }
}

export async function getSeenPropertyPopup(payload, success) {
  let token = getToken();
  await axiosInstance
    .post(`api/property/seenpropertyview/`, payload, {
      headers: { Authorization: `Bearer ${token?.idToken}` },
    })
    .then((response) => {
      success(response);
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function contactNowGroupMessage(
  userId,
  selectedIds,
  message,
  success
) {
  let token = getToken();
  const payload = {
    agent_id: selectedIds,
    user_id: userId,
    message: message,
  };
  await axiosInstance
    .post(`api/property/contact_now/`, payload, {
      headers: { Authorization: `Bearer ${token?.idToken}` },
    })
    .then((response) => {
      success(response);
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function saveDefaultPropertyShow(temp, success) {
  let token = getToken();
  await axiosInstance
    .post(`api/property/savealllisting/`, temp, {
      headers: { Authorization: `Bearer ${token?.idToken}` },
    })
    .then((response) => {
      success(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function removeDefaultPropertyShow(temp, success) {
  let token = getToken();
  let userId = token?.userId;
  await axiosInstance
    .delete(
      `api/user/guest_user_save_listing/${temp}?userid=${userId}`,
      {
        headers: { Authorization: `Bearer ${token?.idToken}` },
      }
    )
    .then((response) => {
      success(response.data);
    })
    .catch((error) => {});
}

export async function RemoveAllSaveListing(temp, success) {
  let token = getToken();
  await axiosInstance
    .post(`api/property/deleteallsavelisting/`, temp, {
      headers: { Authorization: `Bearer ${token?.idToken}` },
    })
    .then((response) => {
      success(response.data);
    })
    .catch((error) => {});
}

export async function RemoveNoLongerAvaliable(success) {
  let token = getToken();
  let userId = token?.userId;
  await axiosInstance
    .delete(`api/property/RemoveNoLongerAvaliable/${userId}`)
    .then((response) => {
      success(response.data);
    })
    .catch((error) => {});
}

export async function MessageForAgent(temp, success) {
  await axiosInstance
    .post(`api/property/contact_now/`, temp)
    .then((response) => {
      success(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
}
