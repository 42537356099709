import { createSelector } from 'reselect';

const documentSelector = (state: any) => state.document;

export const getIsDocumentsFetching = createSelector([documentSelector], ({ isFetching }) => {
  return isFetching;
});

export const getDocuments = createSelector([documentSelector], ({ documents }) => {
  return documents?.results ?? [];
});

export const getDocumentsNextUrl = createSelector([documentSelector], ({ documents }) => {
  return documents?.next ?? null;
});

export const getDocument = createSelector([documentSelector], ({ document }) => {
  return document;
});

export const getCustomers = createSelector([documentSelector], ({ customers }) => {
  return customers?.data;
});
