import { Conversation, ConversationAttribute } from "@store/slices/message.slice";
import { useSelector } from "react-redux";
import { GetConversationAttributes } from "../../../../store/selectors/message.selector";
import { useMemo } from "react";

export const useConversationParticipants = (conversation: Conversation, excludeIdentity?: string) => {
	const attributesAll = useSelector(GetConversationAttributes);

	const participantsAttributes = useMemo(() => {
		return attributesAll.find(({ conversation_sid }) =>
			conversation_sid === conversation.sid)?.participants || [];
	}, [attributesAll, conversation]);

	return useMemo(() => {
		return conversation.participants.reduce((participants, participant) => {
			if (participant.identity === excludeIdentity) return participants;
			const participantAttribute = participantsAttributes.find(({ identity }) => identity === participant.identity);
			return [
				...participants,
				participantAttribute ? participantAttribute : participant
			]
		}, [] as ConversationAttribute['participants']);
	}, [conversation, participantsAttributes, excludeIdentity]);
}