import { useEffect } from 'react';
import axiosInstance from '../REST/axiosInstance';

export const useGetUserData = () => {
  const locationDetails = JSON.parse(localStorage.getItem('locationDetails'));
  const currentIpAddress = JSON.parse(localStorage.getItem('currentIpAddress'));
  useEffect(() => {
    const fetchCurrentIp = async () => {
      try {
        const response = await axiosInstance.get('https://api.ipify.org?format=json');
        const ipAddress = response.data.ip;
        localStorage.setItem('currentIpAddress', JSON.stringify(ipAddress));
      } catch (error) {
        console.error(error);
      }
    };
    fetchCurrentIp();
  }, []);
  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const locationResponse = await axiosInstance.get(`https://ipapi.co/${currentIpAddress}/json/`);
        // const locationResponse = await axiosInstance.get(`http://ip-api.com/json/${ipAddress}`);
        localStorage.setItem('locationDetails', JSON.stringify(locationResponse.data));
      } catch (error) {
        console.error(error);
      }
    };
    console.log(currentIpAddress, locationDetails);
    if (currentIpAddress && (locationDetails?.ip !== currentIpAddress || !locationDetails)) {
      fetchLocation();
    }
  }, [locationDetails, currentIpAddress]);
};