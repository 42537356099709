import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import "./virtualOffice.scss";
import ContactCard from "./ContactCard";
import AllDetails from "./AllDetails";
import { useNavigate, useParams } from "react-router-dom";
import { GetViertualContactInfo, getMemberInfo } from "../server";
import { useSelector, useDispatch } from "react-redux";
import { memberInfo } from "./../AgentOfficeSlice";
import Loader from "../../Loader/Loader";

const VirtualContact = () => {
  const params = useParams();
  // const agentInfo = useSelector(
  //   (state) => state.virtualOfficeInStore.memberDetails
  // );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const teamList = useSelector(
    (state) => state.virtualOfficeInStore.teamMemberList
  );

  const [allContact, setAllContact] = useState([]);
  const [activeContact, setActiveContact] = useState({});
  const [isActive, setisActive] = useState(false);
  const [activeAgent, setactiveAgent] = useState({});
  const [loading, setloading] = useState(false);
  let filterParameter = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];

  const changeFilter = (id) => {
    const filterCharacter = document.getElementsByClassName("parameter");
    Array.prototype.forEach.call(filterCharacter, function (character) {
      character.classList.remove("active-para");
    });
    document.getElementById(id).classList.add("active-para");
  };

  // const GetAllContact = () => {
  //     GetViertualContactInfo((success) => {
  //         if (success.data.code === 200 && success.data.status === "success") {
  //             setAllContact(success.data.data)
  //             setActiveContact(success.data.data[0]?.user_email?.[0])
  //         }
  //     })
  // }

  useEffect(() => {
    setloading(true);
    // let ActiveFirstFilter = () => { document.getElementById("filterCharA").classList.add('active-para'); }
    // GetAllContact()
    try {
      if (params.id) {
        memberDetails(params.id);
        setisActive(params.id);
      } else {
        memberDetails(teamList[0]?.custom_obj?.id);
        setisActive(teamList[0]?.custom_obj?.id);
        // setactiveAgent(teamList[0])
      }
    } catch (err) {
      console.log(err);
    }
  }, [teamList]);

  const memberDetails = (id) => {
    getMemberInfo(
      id,
      (success) => {
        if (success.data.code === 200) {
          try {
            dispatch(memberInfo(success.data.data));
            setloading(false);
          } catch (err) {
            console.log(err);
          }
        }
      },
      (error) => {
        console.log(error);
        dispatch(memberInfo([]));
        setloading(false);
      }
    );
  };

  return (
    <>
      {/* {loading ? <Loader /> : null} */}
      <div className="contact-sec flex-grow-1 d-flex flex-column">
        {/* <Filterbar /> */}
        <div className=" d-flex gap-1 mt-4">
          <div className="filter-bychar hide-scrollbar scrollS">
            {filterParameter?.map((para, i) => (
              <div
                id={`filterChar${para}`}
                className=" parameter center pointer"
                key={`${para}${i}`}
                onClick={() => {
                  const elemrnt = document.getElementById(`filterChar${para}`);
                  changeFilter(elemrnt.id);
                }}
              >
                <p className="filter-para">{para}</p>
              </div>
            ))}
          </div>

          <Row className="contact-main-sec w-100 p-0 m-0">
            <Col lg={3} className="tab-left-sec p-0 m-0 h-100">
              {/* <div className='position-relative d-flex pointer'>
                                {activeAgent ?
                                    <div className="contact-details active-contact d-flex  align-items-center">
                                        <div className="prof-img">
                                            <img alt="" srcSet="" className=' h-100'
                                                src={agentInfo?.user_image?.profile_image != null ?
                                                    `${backendURL}${agentInfo?.user_image?.profile_image}` : icons?.profile
                                                }
                                            />
                                        </div>
                                        <div className="name">
                                            <h6 className=' pb-1'>{agentInfo?.name ? agentInfo?.name : null}</h6>
                                            <p>{agentInfo?.name ? agentInfo.name : null}</p>
                                        </div>
                                    </div> : null}
                            </div> */}

              {/* <div className="contact-list mt-3 gap-3 d-flex flex-column scrollS">
                                {allContact?.map((contact, index) =>
                                    <ContactCard
                                        key={`virtualcontact${index}`}
                                        id={contact.id}
                                        info={contact?.user_email?.[0]} setActiveContact={setActiveContact}
                                    />
                                )}
                            </div> */}
              <div className="contact-list mt-3 gap-3 d-flex flex-column scrollS">
                {teamList.length > 0
                  ? teamList.map((contact, index) => {
                      return (
                        <div
                          style={{
                            cursor: "not-allowed",
                          }}
                          className="cursor-na"
                          onClick={() => {
                            setactiveAgent(contact);
                            memberDetails(contact.custom_obj?.id);
                          }}
                          key={`virtualcontactdiv${index}`}
                        >
                          <ContactCard
                            isActive={
                              contact.custom_obj?.id === isActive ? true : false
                            }
                            userImage={contact?.user_image}
                            key={`virtualcontact${index}`}
                            id={contact?.custom_obj?.id}
                            info={contact}
                            setActiveContact={setActiveContact}
                            setisActive={setisActive}
                          />
                        </div>
                      );
                    })
                  : null}
              </div>
            </Col>

            <Col
              lg={9}
              className="tab-right-sec hide-scrollbar p-0 d-flex flex-column position-relative h-100 pb-3"
              style={{ margin: "auto", right: "8px" }}
            >
              <AllDetails />
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default VirtualContact;
