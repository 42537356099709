import React from 'react';
import { FieldRenderProps } from 'react-final-form';

import { Box, TextField } from '@mui/material';

import { getFieldError } from '@core/FinalForm/getFieldError';
import { styles } from '@components/forms/SubmitRequestForAdvertising/styles';
import Typography from '@mui/material/Typography';

export const RenderLastNameField: React.FC<FieldRenderProps<string>> = ({
  input,
  meta,
}) => {
  return (
    <Box sx={styles.inputWrapper} my={'10px'} width={'100%'}>
      <Box sx={styles.label}>
        <Typography>Last Name</Typography>
      </Box>
      <TextField
        sx={styles.input}
        required
        fullWidth
        {...input}
      />
      {!!getFieldError(meta) && (
        <Box sx={styles.error}>{getFieldError(meta)}</Box>
      )}
    </Box>
  );
};
