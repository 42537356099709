import { getToken } from "../../../../../appVariable/variable";
import axiosInstance from '../../../../../REST/axiosInstance';

export function createTeam(tempObj, success) {
  let token = getToken()
  axiosInstance
    .put(`api/user/createteam/`, tempObj, {
      headers: {
        'Authorization': `Bearer ${token?.idToken}`
      }
    })
    .then((response) => {
      if (response.data.code === 200 && response.data.status === "success") {
        console.log(response.data.data);
        success(response.data.data);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}
export async function inviteTeamMember(tempObj, success) {
  let token = getToken()
  await axiosInstance
    .post(`api/property/Invitation_link/`, tempObj, {
      headers: {
        'Authorization': `Bearer ${token?.idToken}`
      }
    })
    .then((response) => {
      success(response)
    })
    .catch((error) => {
      console.log(error);
    });
}
export async function inviteListTeamMember(tempObj, success) {
  let token = getToken()
  await axiosInstance
    .post(`api/property/BluckInvitation_View/`, tempObj, {
      headers: {
        'Authorization': `Bearer ${token?.idToken}`
      }
    })
    .then((response) => {
      success(response)
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function EditTeamMember(id, tempObj, success) {
  let token = getToken()
  await axiosInstance.put(`api/property/UpdateTeamMember/${id}`, tempObj, {
    headers: { 'Authorization': `Bearer ${token?.idToken}` }
  })
    .then((response) => {
      success(response)
    })
    .catch((error) => {
      console.log(error);
    });
}

export function getTeamMember(tempObj, success) {
  let token = getToken()
  axiosInstance
    .post(`api/user/createteam/`, tempObj, {
      headers: {
        'Authorization': `Bearer ${token?.idToken}`
      }
    })
    .then((response) => {
      success(response);
    })
    .catch((error) => {
      console.log(error);
    });
}

export function leaveTeam() {
  const token = getToken()

  return axiosInstance.get('/api/user/leave-team/', {
    headers: {
      'Authorization': `Bearer ${token?.idToken}`
    }
  })
}

export function getTeamDetails(success) {
  let token = getToken()
  let userslug = token?.profile.slug;
  axiosInstance.get(`api/user/agentuserprofile/${userslug}`, {
    headers: { 'Authorization': `Bearer ${token?.idToken}` }
  })
    .then((response) => {
      if (response.data.code === 200 && response.data.status === "success") { success(response.data.data); }
    })
    .catch((error) => {console.log(error);});
}


export async function RemoveTeamMember(deleteid, success) {
  let token = getToken()
  await axiosInstance.delete(`api/property/RemoveTeamMember/${deleteid}`,
    { headers: { 'Authorization': `Bearer ${token?.accessToken}` }, })
    .then((response) => {
      success(response);
    })
    .catch((error) => { console.log(error); });
}


export const getTeamMemberProfile = (paramObj,success) => {
  let token = getToken()
  axiosInstance.get(`api/property/teammerberprofile/${paramObj}`, {
      headers: { 'Authorization': `Bearer ${token?.idToken}`}
    })
    .then((response) => {
      if (response.data.code === 200 && response.data.status === "success") {
        success(response);
      }
    })
    .catch((error) => { console.log(error);});
}

export async function DeleteTeam(success) {
  let token = getToken()
  await axiosInstance.delete(`api/property/RemoveTeam/${token?.userId}`)
    .then((response) => { success(response.data); })
    .catch((error) => { console.log(error); });
}




