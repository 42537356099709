import { getToken } from "../../../../../appVariable/variable";
import axiosInstance from '../../../../../REST/axiosInstance';

export function GetUserBilling(success) {
    let token = getToken()
    let userId = token?.userId;
  axiosInstance.get(`api/user/plan_and_billing/${userId}`, {
        headers: { 'Authorization': `Bearer ${token?.idToken}` }
    })
        .then((response) => {
            if (response.data.code === 200 && response.data.status === "success") {
                success(response.data.data);
            }
        })
        .catch((error) => { console.log(error); });
}

export async function deleteAgentAccount(userid, success) {
    let token = getToken()
    await axiosInstance.delete(`api/user/delete_user_account/${userid}`,
        { headers: { 'Authorization': `Bearer ${token?.accessToken}` }, })
        .then((response) => {
            if (response.data.code === 200 && response.data.status === "success") {
                success(response.data.data);
            }
        })
        .catch((error) => { console.log(error); });
}

export function SuspendAgentAccount(success) {
    let token = getToken()
    let userId = token?.userId;
  axiosInstance.get(`api/user/suspend_user_account/${userId}`, {
        headers: { 'Authorization': `Bearer ${token?.idToken}` }
    })
        .then((response) => {
            if (response.data.code === 200 && response.data.status === "success") {
                success(response.data.data);
            }
        })
        .catch((error) => {
            console.log(error);
        });
}